/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { styled } from '@mui/material';
import theme from '../../../../Shared/Themes/theme';

export const buttonStyles = {
  snackbarContentStyle: {
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderRadius: '1rem',
    boxShadow: '0px 10px 20px 6px rgba(121, 87, 0, 0.10)',
    padding: '0.5rem 1.5rem',
  },
};

export const SnackBar = styled('div')({
  position: 'fixed',
  bottom: '1.5rem',
  left: '30%',
  transform: 'translateX(-50%)',
  minWidth: '15.25rem',
});

export const MessageText = styled('span')(() => ({
  marginLeft: '1rem',
  color: theme.palette.text.primary,
  fontWeight: theme.typography.fontWeightBold,
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h4?.fontSize,
  lineHeight: '1.5rem',
  letterSpacing: '0.009375rem',
}));
