/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import React from 'react';
import { BottomBarContainer, TextField } from './ConversationsPage.Style';
import { Button, CircularProgress } from '@mui/material';
import theme from '../../../../Shared/Themes/theme';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

interface IReplyBarProps {
  replyMsgContent: string;
  setReplyMsgContent: (msg: string) => void;
  isConversationsDataLoading: boolean;
  handleSendButton: () => void;
}

const ReplyBar = ({
  replyMsgContent,
  setReplyMsgContent,
  isConversationsDataLoading,
  handleSendButton,
}: IReplyBarProps) => {
  const { t } = useTranslation(['conversations']);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (event.key === 'Enter' && !event.shiftKey && !isEmpty(replyMsgContent.trim())) {
      event.preventDefault();
      handleSendButton();
    }
  };

  return (
    <BottomBarContainer>
      <TextField
        id="message-reply"
        placeholder={t('conversations:giveYourReplyHere')}
        autoComplete="off"
        value={replyMsgContent}
        sx={{ pointerEvents: isConversationsDataLoading ? 'none' : 'auto' }}
        onChange={(event) => setReplyMsgContent(event.target.value ? event.target.value : '')}
        disabled={isConversationsDataLoading}
        onKeyDown={handleKeyDown}
      />
      <Button
        variant="text"
        disabled={isConversationsDataLoading || isEmpty(replyMsgContent)}
        sx={{
          color: theme.palette.primary.dark,
          textTransform: 'capitalize',
          height: '3.5rem',
          width: '8rem',
          opacity: isEmpty(replyMsgContent) ? 0.5 : 1,
        }}
        onClick={handleSendButton}>
        {isConversationsDataLoading ? (
          <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
        ) : (
          <span>{t('conversations:send')}</span>
        )}
      </Button>
    </BottomBarContainer>
  );
};

export default ReplyBar;
