/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React from 'react';
import { Autocomplete, CircularProgress, IconButton, InputAdornment, TextField } from '@mui/material';
import { JobStatus, UserType } from '../../../../Shared/Constants/App';
import { Icons } from '../../../../Shared/Constants/Icons';
import { buttonStyles } from './AddEditOneTimeJob.Style';
import './AddEditOneTimeJob.css';

const FacilityAutocomplete = ({
  facilityItem,
  index,
  facilityOptions,
  role,
  handleFacilitiesSelectChange,
  isFacilityLoading,
  jobRecord,
  selectedFacilities,
  t,
  theme,
  handleDeleteFacility,
}: any) => {
  const prevSelectedFacility = facilityItem?.facilityId;

  const { textField } = buttonStyles;

  const renderLabel = (role: any, t: any) => (
    <span>
      {role === UserType.Vendor ? `${t('oneTimeJob:location')}` : `${t('oneTimeJob:facility')}`}
      <span className="mandatory-field">*</span>
    </span>
  );

  const renderStartAdornment = (params: any, t: any, Icons: any) => {
    return params.inputProps?.value ? (
      <InputAdornment position="start">
        <img
          src={Icons.SearchIcon}
          alt={t('altTexts:search')}
          style={{
            cursor: 'pointer',
          }}
        />
      </InputAdornment>
    ) : null;
  };

  const renderEndAdornment = (
    params: any,
    index: any,
    isFacilityLoading: any,
    theme: any,
    handleDeleteFacility: any,
    facilityItem: any,
    Icons: any,
    t: any
  ) => {
    return (
      <InputAdornment position="end">
        {index > 0 && (
          <IconButton
            onClick={() => handleDeleteFacility(facilityItem)}
            sx={{
              padding: '0.5rem',
            }}>
            <img src={Icons.DeleteIcon} alt={t('altTexts:delete')} />
          </IconButton>
        )}
        {index === 0 && isFacilityLoading ? (
          <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
        ) : params.inputProps?.value ? (
          <></>
        ) : (
          <img
            src={Icons.SearchIcon}
            alt={t('altTexts:search')}
            style={{
              cursor: 'pointer',
            }}
          />
        )}
      </InputAdornment>
    );
  };

  const getOptionStyle = (alreadySelected: any) => {
    return {
      borderBottom: `0.0625rem solid ${theme.palette.divider}`,
      backgroundColor: alreadySelected ? `${theme.palette.primary.main} ` : `${theme.palette.common.white} `,

      fontWeight: alreadySelected ? `${theme.typography.fontWeightBold} ` : `${theme.typography.fontWeightLight} `,
      '.MuiAutoComplete-popper': {
        '&:hover': {
          backgroundColor: `${theme.palette.secondary.dark} !important`,
        },
      },

      pointerEvents: alreadySelected ? 'none' : 'auto',
    };
  };

  const renderOption = (props: any, option: any, selected: any, selectedFacilities: any, role: any) => {
    const selectedFacilityIds = selectedFacilities?.map((facility: { facilityId: any }) => facility?.facilityId);
    const alreadySelected = selectedFacilityIds.includes(option.facilityId);
    return (
      <div>
        <li {...props} className="autoComplete" style={{ ...getOptionStyle(alreadySelected) }}>
          <div>
            {role === UserType.Customer ? option.buildingName : `${option.jobNumber} - ${option.jobDescription}`}
          </div>
        </li>
      </div>
    );
  };

  return (
    <Autocomplete
      options={facilityOptions}
      getOptionLabel={(option) =>
        role === UserType.Customer ? option.buildingName : `${option.jobNumber} - ${option.jobDescription}`
      }
      value={facilityOptions.find((option: any) => option.facilityId === facilityItem.facilityId) || null}
      onChange={(event, newValue) =>
        handleFacilitiesSelectChange(facilityItem.id, newValue?.facilityId, prevSelectedFacility)
      }
      isOptionEqualToValue={(option: any, value: any) => {
        return option.label === value.label;
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            pointerEvents: isFacilityLoading ? 'none' : 'all',
            ...textField,
            marginBottom: '2rem',
          }}
          label={renderLabel(role, t)}
          InputProps={{
            ...params.InputProps,
            startAdornment: renderStartAdornment(params, t, Icons),
            endAdornment: renderEndAdornment(
              params,
              index,
              isFacilityLoading,
              theme,
              handleDeleteFacility,
              facilityItem,
              Icons,
              t
            ),
          }}
          disabled={jobRecord?.jobStatus === JobStatus.Awarded}
        />
      )}
      renderOption={(props, option, { selected }) => renderOption(props, option, selected, selectedFacilities, role)}
    />
  );
};

export default FacilityAutocomplete;
