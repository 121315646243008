/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, Icon, Title, TitleWrapper } from './PageTitle.styles';

import { Icons } from '../../../Constants/Icons';
import Button, { ButtonType, IconDirection } from '../Buttons/Button';
import { PermissionGate } from '@abrightlab/client-rbac';

import { Role } from '../../../Constants/App';
import AsyncStorageKeys from '../../../Constants/StorageKeys';
import { CircularProgress } from '@mui/material';
import theme from '../../../Themes/theme';

interface IPageTitleProps {
  title: string;
  Loader?: boolean;
  showJobIcon?: boolean;
  showJobNameEditIcon?: boolean;
  showEditIcon?: boolean;
  showDeleteIcon?: boolean;
  onDeleteClick?: () => void;
  addOTJ?: boolean;
  showButton?: boolean;
  showConversationIcon?: boolean;
  onConversationButtonClick?: () => void;
  onEditJobNameButtonClick?: () => void;
}

/**
 * @returns Page Title
 */

const PageTitle = ({
  title,
  Loader,
  showJobIcon,
  showJobNameEditIcon,
  showEditIcon,
  showDeleteIcon,
  onDeleteClick,
  showButton,
  showConversationIcon,
  onConversationButtonClick,
  onEditJobNameButtonClick,
}: IPageTitleProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  function handleCreateJobClick() {
    navigate('/createOneTimeJob');
  }

  return (
    <Container>
      <TitleWrapper>
        <div>
          <Icon
            onClick={() => {
              navigate(-1);
            }}
            src={Icons.LeftArrowIcon}
            alt={t('altTexts:backArrow')}
          />
          {Loader ? (
            <CircularProgress size={24} sx={{ color: theme.palette.primary.dark, marginRight: '0.5rem' }} />
          ) : (
            <Title>{title}</Title>
          )}
          {showJobNameEditIcon && (
            <Icon
              sx={{ width: '1.3rem', height: '1.3rem' }}
              onClick={onEditJobNameButtonClick}
              src={Icons.EditBlack}
              alt={t('altTexts:modify')}
            />
          )}
        </div>
        <div>
          {showJobIcon && <Icon onClick={handleCreateJobClick} src={Icons.CreateJobIcon} alt={t('altTexts:addIcon')} />}
          {showEditIcon && (
            <Link
              to="/createOneTimeJob"
              state={{ jobRecord: location.state?.facilityItem, isJobCreation: false, mediaFileIds: [] }}>
              <img src={Icons.EditBlack} alt={t('altTexts:modify')} />
            </Link>
          )}
          {showConversationIcon && onConversationButtonClick && (
            <Icon onClick={onConversationButtonClick} src={Icons.ConversationIcon} alt={t('altTexts:conversation')} />
          )}
          {showDeleteIcon && onDeleteClick && (
            <Icon src={Icons.DeleteIcon} alt={t('altTexts:delete')} onClick={onDeleteClick} />
          )}
          {/* <Link to="/createOneTimeJob" style={{ textDecoration: 'none' }}>
            <Button
              icon={<img src={addIcon} alt={t('altTexts:addIcon')} />}
              type={ButtonType.Primary}
              label="One time job"
              iconDirection={IconDirection.Right}
            />
          </Link> */}
          {showButton ? (
            <PermissionGate
              roles={[
                Role.AccountHolder,
                Role.Admin,
                Role.Supervisor,
                Role.DistrictManager,
                Role.RegionalManager,
                Role.OTJAdmin,
              ]}
              permissions={['']}
              errorProps={[]}>
              <Link to="/createOneTimeJob" style={{ textDecoration: 'none' }}>
                <Button
                  onClick={() => {
                    localStorage.removeItem(AsyncStorageKeys.currentJob);
                  }}
                  icon={<img src={Icons.PlusWhiteIcon} alt={t('altTexts:addIcon')} />}
                  type={ButtonType.Primary}
                  label={t('homePage:oneTimeJob')}
                  iconDirection={IconDirection.Right}
                />
              </Link>
            </PermissionGate>
          ) : null}
        </div>
      </TitleWrapper>
    </Container>
  );
};

PageTitle.defaultProps = {
  showJobIcon: false,
  showEditIcon: false,
  showDeleteIcon: false,
};

export default PageTitle;
