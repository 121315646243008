/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { styled, Tab } from '@mui/material';
import { keyframes } from '@mui/system';
import { Link } from 'react-router-dom';
import { CenterAlignment } from '../../../../Shared/Components/Layout/styles';
import theme from '../../../../Shared/Themes/theme';

interface IProgressProps {
  progressPercent: number;
}

export const DropdownWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  columnGap: '1.5rem',
  margin: '0 0 2.5rem',
  [theme.breakpoints.down('laptop')]: {
    flexWrap: 'nowrap',
    rowGap: '1.5rem',
  },
}));

export const TabSection = styled('div')(() => ({
  '& .MuiTabs-indicator': {
    height: '0.125rem',
    backgroundColor: theme.palette.primary.main,
  },
}));

export const TabsWrapper = styled('div')(() => ({
  borderBottom: `0.0625rem solid ${theme.palette.divider}`,
}));

export const TabTitle = styled(Tab)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h2.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
  lineHeight: '1.75rem',
  letterSpacing: '0.009375rem',
  textAlign: 'center',
  color: theme.palette.text.secondary,
  textTransform: 'capitalize',
  '&.Mui-selected': {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
  },
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h3.fontSize,
    lineHeight: '1.375rem',
  },
}));

export const FacilityInfoWrapper = styled('div')(() => ({
  borderRadius: '1rem',
  backgroundColor: theme.palette.common.white,
  boxShadow: '0 0.625rem 1.25rem 0.375rem #7957001A',
  padding: '1.5rem',
  margin: '1.875rem 0 1.5rem 0',
}));

export const IconWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const PersonIcon = styled('div')(({ theme }) => ({
  borderRadius: '1.75rem',
  display: 'flex',
  alignItems: 'center',
  '& img': {
    width: '3.5rem',
    height: '3.5rem',
  },
  [theme.breakpoints.down('laptop')]: {
    '& img': {
      width: '2.5rem',
      height: '2.5rem',
    },
  },
  '& > img': {
    borderRadius: '4rem',
  },
}));

export const NameWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: '1rem',
}));

export const PersonName = styled('h3')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h3.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.375rem',
  letterSpacing: '0.009375rem',
  marginBottom: '0.25rem',
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h4.fontSize,
    lineHeight: '1.5rem',
  },
}));

export const FacilityDetails = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: '1.5rem',
  borderBottom: `0.0625rem solid ${theme.palette.divider}`,
}));

export const FacilityDurationWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  rowGap: '0.5rem',
}));

export const FaiclityJobName = styled('h4')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h4?.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
  lineHeight: '1.5rem',
  letterSpacing: '0.009375rem',
  color: theme.palette.text.primary,
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h5?.fontSize,
    lineHeight: '1.375rem',
  },
}));

export const FacilityDuration = styled('h5')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h5.fontSize,
  fontWeight: theme.typography.fontWeightLight,
  lineHeight: '1.375rem',
  letterSpacing: '0.015625rem',
  color: theme.palette.secondary.contrastText,
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h6.fontSize,
    lineHeight: '1rem',
  },
}));

export const FacilityShiftDetails = styled('div')(() => ({}));

export const FacilityShiftList = styled('div')(() => ({
  padding: '1rem 0',
  display: 'flex',
  alignItems: 'center',
  '&:not(:last-child)': {
    borderBottom: `0.0625rem solid ${theme.palette.divider}`,
  },
  '&:last-child': {
    paddingBottom: 0,
  },
}));

export const ShiftTime = styled('h6')(({ theme }) => ({
  borderRadius: '0.375rem',
  backgroundColor: theme.palette.secondary.dark,
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h5.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '1.375rem',
  letterSpacing: '0.015625rem',
  marginRight: '1rem',
  padding: '0.25rem 0.5rem',
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h6.fontSize,
    lineHeight: '1rem',
    letterSpacing: '0.025rem',
    marginRight: '0.5rem',
  },
}));

export const ShiftType = styled('h6')(({ theme }) => ({
  borderRadius: '0.5rem',
  backgroundColor: theme.palette.info.main,
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h5.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '1.375rem',
  letterSpacing: '0.015625rem',
  marginRight: '1.5rem',
  padding: '0.25rem 0.5rem',
  display: 'flex',
  columnGap: '0.5rem',
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h6.fontSize,
    lineHeight: '1rem',
    letterSpacing: '0.025rem',
    marginRight: '1rem',
  },
}));

export const ServicesList = styled('h6')(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h5.fontSize,
  fontWeight: theme.typography.fontWeightLight,
  lineHeight: '1.375rem',
  letterSpacing: '0.015625rem',
  flex: 1,
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h6.fontSize,
    lineHeight: '1.25rem',
    letterSpacing: '0.025rem',
  },
}));

export const VendorInfoWrapper = styled('div')(({ theme }) => ({
  borderRadius: '1rem',
  backgroundColor: theme.palette.common.white,
  boxShadow: '0 0.625rem 1.25rem 0.375rem #7957001A',
  padding: '1.5rem',
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  marginBottom: '2.5rem',
  [theme.breakpoints.down('laptop')]: {
    marginBottom: '2rem',
  },
}));

export const VendorTextStyling = styled('h3')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h5.fontSize,
  fontWeight: theme.typography.fontWeightLight,
  lineHeight: '1.375rem',
  letterSpacing: '0.015625rem',
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h6.fontSize,
    lineHeight: '1rem',
    letterSpacing: '0.025rem',
  },
}));

export const Name = styled(VendorTextStyling)(() => ({}));

export const VendorDetails = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('laptop')]: {
    paddingTop: '1rem',
    columnGap: '1rem',
    flexWrap: 'nowrap',
  },
}));

export const VendorInfo = styled(VendorTextStyling)(({ theme }) => ({
  paddingRight: '1.5rem',
  paddingLeft: '1.5rem',
  display: 'flex',
  // maxWidth: '17.5rem',
  '&:not(:last-child)': {
    borderRight: `0.0625rem solid ${theme.palette.divider}`,
  },
  '&:first-of-type': {
    paddingLeft: 0,
  },
  '&:last-child': {
    paddingRight: 0,
  },
  '& img': {
    width: '1.5rem',
    height: '1.5rem',
    marginRight: '0.5rem',
  },
  [theme.breakpoints.down('laptop')]: {
    padding: 0,
    '&:not(:last-child)': {
      borderRight: `0.0625rem solid ${theme.palette.divider}`,
    },
    '&:first-of-type': {
      padding: 0,
    },
    '&:last-child': {
      padding: 0,
    },
    '& img': {
      width: '1rem',
      height: '1rem',
      marginRight: '0.5rem',
    },
  },
}));

export const PerformanceMetrics = styled('h6')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h2.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.75rem',
  letterSpacing: '0.009375rem',
  margin: '1.5rem 0',
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h3.fontSize,
    lineHeight: '1.375rem',
  },
}));

export const PerformanceMetricsWrapper = styled('div')(() => ({
  borderRadius: '1rem',
  backgroundColor: theme.palette.common.white,
  boxShadow: '0 0.625rem 1.25rem 0.375rem #7957001A',
  padding: '1.5rem',
  '&:not(:last-child)': {
    marginBottom: '1.5rem',
  },
}));

export const DateContainer = styled('div')(() => ({
  paddingBottom: '1.5rem',
  borderBottom: `0.0625rem solid ${theme.palette.divider}`,
  display: 'flex',
  justifyContent: 'space-between',
  '& > div': {
    display: 'flex',
    '& > img': {
      marginRight: '1rem',
    },
  },
}));

export const ChangeDate = styled('h3')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h4.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.5rem',
  letterSpacing: '0.009375rem',
  color: theme.palette.primary.dark,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  columnGap: '0.5rem',
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h5.fontSize,
    lineHeight: '1.375rem',
  },
}));

export const CalendarWrapper = styled('div')(({ theme }) => ({
  '& img': {
    width: '2rem',
    height: '2rem',
    marginRight: '1rem',
  },
  [theme.breakpoints.down('laptop')]: {
    '& img': {
      width: '1.5rem',
      height: '1.5rem',
    },
  },
}));

export const DateWrapper = styled('h1')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: '2rem',
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '2.5rem',
  letterSpacing: '0',
  display: 'flex',
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h3.fontSize,
    lineHeight: '1.375rem',
  },
}));

export const DaysRange = styled('div')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h2.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '2.5rem',
  color: theme.palette.secondary.contrastText,
  paddingLeft: '1.25rem',
}));

export const AssociateDetails = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '1.5rem',
  padding: '1.5rem 0',
  [theme.breakpoints.down('laptop')]: {
    gap: '1rem',
    padding: '1rem 0',
    flexDirection: 'row',
  },
}));

export const AssociateTimingWrapper = styled('div')(() => ({
  borderRadius: '1rem',
  backgroundColor: theme.palette.secondary.main,
  padding: '1rem 0.625rem',
  '& > div': {
    display: 'flex',
  },
}));

export const AssociateDurationDetails = styled('div')(({ theme }) => ({
  '& h5': {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: '1.375rem',
    letterSpacing: '0.015625rem',
  },
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h6.fontSize,
    lineHeight: '1.25rem',
    letterSpacing: '0.025rem',
  },
}));
export const ClockWrapper = styled('div')(() => ({
  padding: '0.3125rem 0 0 0.3125rem',
  '& > img': {
    width: '1.5rem',
    height: '1.5rem',
    marginRight: '0.5rem',
  },
}));

export const Timings = styled('h3')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h2.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.75rem',
  letterSpacing: '0.009375rem',
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h5.fontSize,
    lineHeight: '1.325rem',
    letterSpacing: '0.015625rem',
  },
}));

export const TaskStatusWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '1rem',
  borderRadius: '1rem',
  backgroundColor: theme.palette.secondary.main,
  flex: 1,
  '& h5': {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: '1.375rem',
    letterSpacing: '0.015625rem',
  },
  [theme.breakpoints.down('laptop')]: {
    '& h5': {
      fontSize: theme.typography.h6.fontSize,
      lineHeight: '1.25rem',
      letterSpacing: '0.025rem',
    },
  },
}));

export const StatusWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  '& > h5:first-of-type': {
    paddingRight: '1rem',
    borderRight: `0.0625rem solid ${theme.palette.divider}`,
    marginRight: '1rem',
  },
}));

export const ProgressBarWrapper = styled('div')(() => ({
  display: 'flex',
  columnGap: '1rem',
  alignItems: 'center',
}));

export const ProgressBarContainer = styled('div')(() => ({
  backgroundColor: theme.palette.secondary.customColor5,
  height: '0.5rem',
  borderRadius: '1rem',
  flex: 1,
}));

const spin = keyframes`
  from {
    width: 0;
  }
`;

export const ProgressBar = styled('div')(({ progressPercent }: IProgressProps) => ({
  width: `${progressPercent}%`,
  animation: `${spin} 2s linear`,
  backgroundColor: theme.palette.success.main,
  height: '0.5rem',
  borderRadius: '1rem',
}));

export const CardWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '1.5rem',
  flexWrap: 'nowrap',
  flexDirection: 'row',
  [theme.breakpoints.down('laptop')]: {
    flexDirection: 'column',
  },
}));

export const Card = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: ' 1.125rem 1rem',
  boxSizing: 'border-box',
  borderRadius: '1.125rem 1rem',
  border: `0.0625rem solid ${theme.palette.divider}`,
  display: 'flex',
  flex: '0 1 15.75rem',
  justifyContent: 'space-between',
  alignItems: 'center',
  '& div': {
    display: 'flex',
    columnGap: '0.625rem',
    alignItems: 'center',
  },
  [theme.breakpoints.down('laptop')]: {
    flex: 0,
    padding: ' 0.75rem 0.75rem 0.75rem 1rem',
  },
}));

interface IScoreProps {
  score: number;
}

export const PieChartScore = styled('span')(({ score }: IScoreProps) => ({
  width: '1.5rem',
  height: '1.5rem',
  background: `conic-gradient(${theme.palette.primary.main} ${score}%, 0, ${theme.palette.secondary.dark} 0%)`,
  borderRadius: '50%',
}));

export const CardNav = styled(Link)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  textDecoration: 'none',
  flex: '0 1 15.75rem',
  alignItems: 'center',
  padding: '1rem',
  boxSizing: 'border-box',
  borderRadius: '1rem',
  color: theme.palette.text.secondary,
  '& div': {
    display: 'flex',
    columnGap: '0.625rem',
  },
  [theme.breakpoints.down('laptop')]: {
    flex: 0,
  },
}));

export const IssuesRaised = styled(CardNav)(({ theme }) => ({
  border: 'none',
  backgroundColor: '#FCE8E8',
  [theme.breakpoints.down('laptop')]: {
    padding: ' 0.75rem 1.125rem 0.75rem 1rem',
  },
}));

export const OneTimeJobs = styled(CardNav)(({ theme }) => ({
  border: 'none',
  backgroundColor: theme.palette.info.main,
  [theme.breakpoints.down('laptop')]: {
    padding: ' 0.75rem 1.125rem 0.75rem 1rem',
  },
}));

export const TopCardsContainer = styled('div')(({ theme }) => ({
  gap: '1.5rem',
  width: '100%',
  justifyContent: 'space-between',
  display: 'grid',
  gridTemplateColumns: 'repeat(2,1fr)',
  gridGap: '1rem',
  [theme.breakpoints.down('laptop')]: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
}));

export const BottomCardsContainer = styled('div')(({ theme }) => ({
  gap: '1.5rem',
  width: '100%',
  justifyContent: 'space-between',
  display: 'grid',
  gridTemplateColumns: 'repeat(2,1fr)',
  gridGap: '1rem',
  [theme.breakpoints.down('laptop')]: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
}));

export const TitleText = styled('h4')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h5.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
  lineHeight: '1.375rem',
  whiteSpace: 'nowrap',
  letterSpacing: '0.015625rem',
  paddingLeft: '0.81rem',
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h6.fontSize,
    lineHeight: '1.25rem',
    letterSpacing: '0.025rem',
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
    paddingRight: '3.8125rem',
  },
}));

export const TitleStatus = styled('h4')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h2.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.75rem',
  letterSpacing: '0.009375rem',
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h4.fontSize,
    lineHeight: '1.5rem',
    marginRight: '1rem',
  },
}));

export const SnackBarWrapper = styled('div')(({ theme }) => ({
  '& .MuiSnackbar-root': {
    right: '10.25rem',
    bottom: '1.5rem',
    left: 'unset',
    zIndex: 0,
    [theme.breakpoints.down('laptop')]: {
      right: '2rem',
    },
  },
  '& .MuiSnackbarContent-root': {
    padding: 0,
    flexGrow: 0,
    borderRadius: '1rem',
    backgroundColor: theme.palette.common.white,
  },
  '& .MuiSnackbarContent-action': {
    padding: 0,
  },
}));

export const ProgressLoader = styled(CenterAlignment)(() => ({
  marginTop: '1.25rem',
}));
