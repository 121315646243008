/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { Dialog, styled } from '@mui/material';
import theme from '../../../Themes/theme';

export const buttonStyles = {
  warningButton: {
    background: theme.palette.primary.dark,
    borderRadius: '1.25rem',
    flex: 1,
    display: 'flex',
    padding: '10px 0px',
    margin: '0px',
    textTransform: 'none',
    maxWidth: '100%',
    '&:hover': {
      background: theme.palette.primary.dark,
    },
    '@media (max-width: 64rem)': {
      padding: '0.75rem 2rem',
    },
  },

  warningButtonText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h4?.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    flex: 1,
    lineHeight: '1.5rem',
    letterSpacing: '0.009375rem',
    color: theme.palette.common.white,
    '@media (max-width: 64rem)': {
      fontSize: theme.typography.h5?.fontSize,
      lineHeight: '1.375rem',
      letterSpacing: '0.0125rem',
    },
  },
};

export const WarningMessageWrapper = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    borderRadius: '1.5rem',
    overflowY: 'unset',
    width: '20%',
    padding: '2em',
  },
  '& .MuiDialogTitle-root+.MuiDialogContent-root': {
    paddingTop: '0.5rem',
  },
}));

export const CloseIconStyle = styled('span')(() => ({
  cursor: 'pointer',
  position: 'absolute',
  right: '1.5rem',
  top: '-1.125rem',
}));

export const HeadingText = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h3.fontSize,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.375rem',
  letterSpacing: '0.009375rem',
  color: theme.palette.text.primary,
  marginBottom: '1rem',
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h4.fontSize,
    lineHeight: '1.5rem',
  },
}));

export const ContentText = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h4.fontSize,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightRegular,
  lineHeight: '1.5rem',
  letterSpacing: '0.009375rem',
  color: theme.palette.text.primary,
  marginBottom: '2rem',
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h5.fontSize,
    lineHeight: '1.375rem',
    letterSpacing: '0.015625rem',
  },
}));
