/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import PageTitle from '../../../../../Shared/Components/Common/PageTitle/PageTitle';
import { CustomerContainer } from '../../../../../Shared/Components/Layout/styles';
import { LabelText, NameAndLabel, UserDetailsContainer, ValueText } from './UserDetails.Style';

const UserDetails = () => {
  const { state } = useLocation();
  const [user] = useState({ ...state.rowInfo });

  const { t } = useTranslation(['addUsers']);

  const fields = [
    { label: `${t('addUsers:name')}`, value: user?.username },
    { label: `${t('addUsers:role')}`, value: user?.roles[0]?.roleName },
    { label: `${t('addUsers:emailAddress')}`, value: user?.email || '-' },
    { label: `${t('addUsers:phoneNumber')}`, value: user?.phoneNumber || '-' },
  ];

  return (
    <>
      <PageTitle title={t('addUsers:userDetails')} />
      <CustomerContainer>
        <UserDetailsContainer>
          {fields.map((field, index) => (
            <NameAndLabel key={index}>
              <LabelText>{field.label}</LabelText>
              <ValueText>{field.value}</ValueText>
            </NameAndLabel>
          ))}
        </UserDetailsContainer>
      </CustomerContainer>
    </>
  );
};

export default UserDetails;
