/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { InputBase, MenuItem, styled } from '@mui/material';
// import theme from '../../../Themes/theme';

export const DropdownIcon = styled('div')(() => ({
  '&.MuiSelect-icon': {
    right: '1.25rem',
    top: 'calc(50% - 0.8em)',
  },
}));

export const Options = styled(MenuItem)(({ theme }) => ({
  borderBottom: `0.0625rem solid ${theme.palette.divider}`,
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h3.fontSize,
  fontWeight: theme.typography.fontWeightLight,
  lineHeight: '1.375rem',
  letterSpacing: '0.009375rem',
  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h3.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '1.375rem',
    letterSpacing: '0.009375rem',
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
    [theme.breakpoints.down('laptop')]: {
      fontSize: theme.typography.h5.fontSize,
    },
  },
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h5.fontSize,
  },
}));

export const SelectInput = styled(InputBase)(({ theme }) => ({
  width: '100%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  '& .MuiSelect-select': {
    '&:focus': {
      borderRadius: '1rem',
    },
    borderRadius: '1rem',
    padding: '1.3125rem 1.5rem',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.primary.dark}`,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h3.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '1.375rem',
    letterSpacing: '0.009375rem',
    [theme.breakpoints.down('laptop')]: {
      padding: '0.8125rem 1rem',
      fontSize: theme.typography.h5.fontSize,
      letterSpacing: '0.015625rem',
    },
  },
}));
