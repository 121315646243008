/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { Dialog, styled } from '@mui/material';
import theme from '../../../../Shared/Themes/theme';
const wrapperWidth = 1024 + 80;
const wrapper = 910;

export const buttonStyles = {
  textField: {
    minWidth: '5rem',
    width: '22.5rem',
    marginRight: '1.5rem',
    '& .MuiInputBase-root': {
      paddingRight: '0.75rem !important',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset,&:hover fieldset,&.Mui-focused fieldset': {
        borderWidth: 1,
        borderColor: theme.palette.secondary.light,
        borderRadius: '1rem',
      },
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.text.secondary,
      fontSize: theme.typography.h4?.fontSize,
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightRegular,
      lineHeight: '1.5rem',
      letterSpacing: '0.00375rem',
      '&.Mui-focused': {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.h5?.fontSize,
      },
    },
    '@media (max-width: 64rem)': {
      width: '19.5rem',
      marginRight: '1.25rem',
      '& .MuiInputLabel-root': {
        fontSize: '.875rem',
        lineHeight: '1.375rem',
        letterSpacing: '0.015625rem',
        '&.Mui-focused': {
          fontSize: theme.typography.h6?.fontSize,
        },
      },
    },
  },

  textFieldService: {
    minWidth: '5rem',
    width: '22.5rem',
    marginRight: '1.5rem',
    '& .MuiInputBase-root': {
      paddingRight: '0.5rem !important',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset,&:hover fieldset,&.Mui-focused fieldset': {
        borderWidth: 1,
        borderColor: theme.palette.secondary.light,
        borderRadius: '1rem',
      },
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.text.secondary,
      fontSize: theme.typography.h4?.fontSize,
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightRegular,
      lineHeight: '1.5rem',
      letterSpacing: '0.00375rem',
      '&.Mui-focused': {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.h5?.fontSize,
      },
    },
    '@media (max-width: 64rem)': {
      width: '19.5rem',
      marginRight: '1.25rem',

      '& .MuiInputLabel-root': {
        fontSize: '.875rem',
        lineHeight: '1.375rem',
        letterSpacing: '0.015625rem',
        '&.Mui-focused': {
          fontSize: theme.typography.h6?.fontSize,
        },
      },
    },
  },

  textFieldSearch: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
      },
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.text.disabled,
      fontSize: theme.typography.h4?.fontSize,
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightLight,
      lineHeight: '1.5rem',
      letterSpacing: '0.009375rem',
      '&.Mui-focused': {
        color: theme.palette.text.disabled,
        fontSize: theme.typography.h5?.fontSize,
      },
    },
  },

  headingText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h2?.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '1.75rem',
    letterSpacing: '0.009375rem',
    color: theme.palette.text.primary,
    marginBottom: '2rem',
    '@media (max-width: 64rem)': {
      fontSize: theme.typography.h3?.fontSize,
      lineHeight: '1.375rem',
    },
  },

  facility: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '3rem',
    flexDirection: 'row',
    '@media (max-width: 64rem)': {
      marginBottom: '2rem',
    },
  },

  serviceAndAreaSpecification: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '2.5rem',
    '@media (max-width: 64rem)': {
      marginBottom: '2rem',
    },
  },

  addAnotherService: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '1.5rem',
  },

  addButton: {
    height: '100%',
    width: '22.5rem',
    borderStyle: 'dashed',
    borderColor: theme.palette.primary.dark,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.dark,
    padding: '1rem 2rem',
    marginBottom: '3rem',
    borderRadius: '1rem',
    gap: '0.8125rem',
    '&:hover': {
      borderStyle: 'dashed',
      borderColor: theme.palette.primary.dark,
      backgroundColor: theme.palette.common.white,
    },
    '@media (max-width: 64rem)': {
      width: '19.5rem',
      padding: '0.75rem 1.5rem',
      marginBottom: '2rem',
    },
  },
  addButtonText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h4?.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '1.5rem',
    letterSpacing: '0.009375rem',
    color: theme.palette.primary.dark,
    '@media (max-width: 64rem)': {
      fontSize: theme.typography.h5?.fontSize,
      lineHeight: '1.375rem',
      letterSpacing: '0.0125rem',
    },
  },

  saveExitButton: {
    border: `0.0625rem solid ${theme.palette.primary.dark}`,
    background: theme.palette.common.white,
    borderRadius: '1.25rem',
    textTransform: 'none',
    marginRight: '1.5rem',
    padding: '1.0625rem 2rem',
    '@media (max-width: 64rem)': {
      padding: '0.75rem 2rem',
      marginRight: '1rem',
    },
  },

  createJobButton: {
    background: theme.palette.primary.dark,
    borderRadius: '1.25rem',
    maxWidth: '11.25rem',
    padding: '1.0625rem 2.95rem',
    textTransform: 'none',
    '&:hover': {
      background: theme.palette.primary.dark,
    },
    '@media (max-width: 64rem)': {
      padding: '0.75rem 2rem',
    },
  },

  addTagsButton: {
    fontSize: theme.typography.h4?.fontSize,
    width: '100%',
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '1.5rem',
    letterSpacing: '0.009375rem',
    borderRadius: '1rem',
    backgroundColor: theme.palette.primary.dark,
    padding: '1.0625rem 2rem',
    color: theme.palette.common.white,
    cursor: 'pointer',
    outline: 'none',
    border: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },

  buttonText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h4?.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '1.5rem',
    letterSpacing: '0.009375rem',
    '@media (max-width: 64rem)': {
      fontSize: theme.typography.h5?.fontSize,
      lineHeight: '1.375rem',
      letterSpacing: '0.0125rem',
    },
  },

  divider: {
    border: `00.03125rem solid ${theme.palette.divider}`,
    width: '100%',
    marginBottom: '3rem',
    '@media (max-width: 64rem)': {
      marginBottom: '2rem',
    },
  },

  serviceScheduleContent: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '2.625rem',
  },

  executionType: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3,1fr)',
    '@media (max-width: 64rem)': {
      gridTemplateColumns: 'repeat(2,1fr)',
    },
  },

  RadioType: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3,1fr)',
    '@media (max-width: 64rem)': {
      gridTemplateColumns: 'repeat(1,1fr)',
    },
  },

  radioButton: {
    width: '1.25rem',
    height: '1.25rem',
    color: theme.palette.common.black,
    '&.Mui-checked': {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.primary.main,
      '& + span:last-child': {
        fontWeight: theme.typography.fontWeightBold,
      },
    },
  },

  radioButtonText: {
    fontFamily: theme.typography.fontFamily,
    marginRight: '0 !important',
    marginLeft: '0',
    gap: '1rem',
    '&.MuiFormControlLabel-root': {
      '&> span:last-child': {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.h3?.fontSize,
        fontWeight: theme.typography.fontWeightLight,
        lineHeight: '1.375rem',
        letterSpacing: '0.009375rem',
        color: theme.palette.text.primary,
      },
    },
    '@media (max-width: 64rem)': {
      '&.MuiFormControlLabel-root': {
        '&> span:last-child': {
          fontSize: theme.typography.h3?.fontSize,
          lineHeight: '1.5rem',
        },
      },
    },
  },

  vendorDetailsContent: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '3.25rem',
  },

  selectTagsHeading: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h1?.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '2rem',
    color: theme.palette.text.primary,
    marginBottom: '2.5rem',
  },
  closeIconStyle: {
    cursor: 'pointer',
    position: 'absolute',
    right: '1.5rem',
    top: '-1.125rem',
  },

  radioButtonField: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3,1fr)',
    gap: '1.5rem',
    '@media (max-width: 64rem)': {
      gridTemplateColumns: 'repeat(2,1fr)',
    },
  },
};

export const BottomBarContainer = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: '100%',
  backgroundColor: theme.palette.common.white,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  zIndex: '3',
  height: '6.5rem',
  paddingRight: '9.75rem',
  [theme.breakpoints.down('laptop')]: {
    height: '5.5rem',
    paddingRight: '1.5rem',
  },
}));

export const BottomBarButtonContainer = styled('div')(({ theme }) => ({
  padding: '1.5rem 9.75rem 1.5rem 4.75rem',
  [theme.breakpoints.down(wrapperWidth)]: {
    padding: '1rem 1.5rem',
  },
}));

export const DatePickerContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  marginBottom: '2.5rem',
  columnGap: '1.5rem',
  width: '100%',
  [theme.breakpoints.down(wrapperWidth)]: {
    flexWrap: 'wrap',
    marginBottom: '0.5rem',
    display: 'grid',
    gridTemplateColumns: 'repeat(2,1fr)',
  },
}));

export const DateFieldWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  '& .MuiTextField-root': {
    width: '100%',
  },
  [theme.breakpoints.down('laptop')]: {
    '&:not(:last-child)': {
      marginBottom: '1.5rem',
    },
  },
}));

export const ErrorMsg = styled('span')(({ theme }) => ({
  color: theme.palette.error.main,
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h5.fontSize,
  fontWeight: theme.typography.fontWeightLight,
  lineHeight: '1.375rem',
  letterSpacing: '0.015625rem',
  paddingLeft: '0.625rem',
}));

export const OneTimeJobContainer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '1rem',
  [theme.breakpoints.down('laptop')]: {
    paddingBottom: '1rem',
  },
}));

export const JobNameContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '1.5rem',
  marginBottom: '2.5rem',
  [theme.breakpoints.down('laptop')]: {
    marginBottom: '2rem',
  },
}));

export const FacilityContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  [theme.breakpoints.down('laptop')]: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2,1fr)',
  },
}));

export const ServiceScheduleContainer = styled('div')(() => ({
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
}));

export const VendorDetailsContainer = styled('div')(() => ({
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
}));

export const JobNameWrapper = styled('div')(() => ({
  width: 'inherit',
  display: 'flex',
  flexDirection: 'column',
}));

export const SearchForVendorsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '2.625rem',
  flexWrap: 'wrap',
  [theme.breakpoints.down('laptop')]: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2,1fr)',
  },
}));

export const ErrorMsgWrapper = styled(ErrorMsg)(() => ({
  position: 'absolute',
  bottom: '-23px',
  left: '0px',
}));

export const ServiceFieldWrapper = styled('div')(() => ({
  position: 'relative',
}));

export const PhotosAndVideosContainer = styled('div')(() => ({}));

export const ContentsContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  boxShadow: '0px 10px 20px 6px rgba(121, 87, 0, 0.10)',
  borderRadius: '1rem',
  backgroundColor: theme.palette.common.white,
  marginBottom: '1.5rem',
  padding: '1rem',
}));

export const ImageContainer = styled('div')(() => ({
  justifyContent: 'flex-start',
  marginRight: '1rem',
  '& img, video': {
    width: '3rem',
    height: '3rem',
    objectFit: 'cover',
  },
  '& video': {
    backgroundColor: theme.palette.common.black,
  },
}));

export const FullScreenContainer = styled('div')(() => ({
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  background: 'rgba(0, 0, 0, 0.8)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: '1000',

  '& img, video': {
    width: '80%',
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain', //'cover',
  },
}));

export const DetailsContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

export const HeadingContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '0.5rem',
}));

export const Heading = styled('span')(() => ({
  color: theme.palette.text.primary,
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h4?.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
  lineheight: '1.5rem',
  letterSpacing: '0.009375rem',
}));

export const DeleteOption = styled('div')(() => ({
  cursor: 'pointer',
}));

export const InstructionsContainer = styled('div')(() => ({
  marginBottom: '1.125rem',
}));

export const InstructionsHeading = styled('span')(() => ({
  color: theme.palette.secondary.customColor6,
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h6?.fontSize,
  fontWeight: theme.typography.fontWeightLight,
  lineHeight: '1.25rem',
  letterSpacing: '0.025rem',
  marginBottom: '0.25rem',
}));

export const AddTagContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '0.5rem',
  cursor: 'pointer',
}));

export const AddButton = styled('div')(() => ({
  padding: '0.5625rem 1rem',
  display: 'flex',
  flexDirection: 'row',
  gap: '0.5rem',
}));

export const AddTagText = styled('span')(() => ({
  color: theme.palette.primary.dark,
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h5?.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.375rem',
  letterSpacing: '0.0125rem',
  whiteSpace: 'nowrap',
}));

export const Tags = styled('div')(() => ({
  border: `0.0625rem solid ${theme.palette.divider}`,
  padding: '0.5rem 0.5rem 0.5rem 1rem',
  borderRadius: '6.25rem',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  // marginBottom: '1.5rem',
}));

export const TagText = styled('span')(() => ({
  color: theme.palette.text.primary,
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h6?.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '1rem',
  letterSpacing: '0.025rem',
  display: 'flex',
  alignItems: 'center',
  marginRight: '0.25rem',
}));

export const DialogWrapper = styled(Dialog)(() => ({
  overflowY: 'scroll',
  '& .MuiPaper-root': {
    borderRadius: '1.5rem',
    overflowY: 'unset',
    padding: '2.5rem',
    minWidth: '41.25rem',
    maxWidth: '41.25rem',
  },
  '& .MuiDialogTitle-root+.MuiDialogContent-root': {
    paddingTop: '0.5rem',
  },
  '& .MuiDialog-container': {
    height: 'auto',
  },
}));

export const DropdownStyle = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '1.5rem',
}));

export const TagDropdownText = styled('span')(() => ({
  color: theme.palette.text.primary,
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h4?.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '1.5rem',
  letterSpacing: '0.009375rem',
}));

export const ServiceScheduleRangeText = styled('div')(() => ({
  fontSize: theme.typography.h3?.fontSize,
  marginBottom: '1em',
  fontFamily: theme.typography.fontFamily,
}));

export const ServiceScheduleDateRangeText = styled('div')(({ theme }) => ({
  fontSize: theme.typography.h3.fontSize,
  marginBottom: '1em',
  fontFamily: theme.typography.fontFamily,
  [theme.breakpoints.down(wrapper)]: {
    marginBottom: '2.8rem',
  },
}));

export const TextFieldAlignment = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  gap: '1%',
  [theme.breakpoints.down('laptop')]: {
    flexDirection: 'column',
  },
}));
