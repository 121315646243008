/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Snackbar from '@mui/material/Snackbar';
import { SnackbarContent } from '@mui/material';

import { Icons } from '../../../Constants/Icons';
import { Msg, MsgBold, MsgWrapper, SnackBarWrapper } from './SnackBar.styles';

interface ISnackBarProps {
  isApproved: boolean;
  taskCount: number;
  handleOnClose: () => void;
}

/**
 * @returns SnackBar
 */
const SnackBar = ({ isApproved, taskCount, handleOnClose }: ISnackBarProps) => {
  const { t } = useTranslation(['altTexts']);
  const [open, setOpen] = useState(true);

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    handleOnClose();
  };

  const MessageContent = () => {
    return (
      <MsgWrapper>
        {isApproved ? (
          <img src={Icons.SuccessIcon} alt={t('altTexts:approve')} />
        ) : (
          <img src={Icons.RejectIcon} alt={t('altTexts:reject')} />
        )}
        <Msg>
          <MsgBold>
            {taskCount} {t('dashboard:tasks')}
          </MsgBold>{' '}
          {t('dashboard:haveBeen')}
          <MsgBold>{`${isApproved ? ' approved' : ' rejected'} !`}</MsgBold>
        </Msg>
      </MsgWrapper>
    );
  };

  const action = <img src={Icons.CancelIcon} alt={t('altTexts:close')} onClick={handleClose} />;

  return (
    <SnackBarWrapper isApproved={isApproved}>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        key={'bottomcenter'}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <SnackbarContent message={<MessageContent />} action={action} />
      </Snackbar>
    </SnackBarWrapper>
  );
};
export default SnackBar;
