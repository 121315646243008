/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React, { useState, useEffect } from 'react';
import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { buttonStyles, ErrorMsg } from '../../../Jobs/Components/AddEditOneTimeJob/AddEditOneTimeJob.Style';
import { AddUserName } from '../../../Jobs/Models/AddEditOneTimeJob.Model';
import { Patterns } from '../../../../Shared/Constants';
import { Icons } from '../../../../Shared/Constants/Icons';
import { Options } from '../../../../Shared/Components/Common/Dropdown/Dropdown.styles';
import { textFieldStyles, FieldWrapper } from './AddUser.Style';

const AddUserFormRenderer = (props: any) => {
  const [countryCode, setCountryCode] = useState('+1');
  const [countryCodeOptions, setCountryCodeOptions] = useState<{ pin: string; country: string }[]>([]);
  const [isRoleOpen, setRoleOpen] = useState(false);

  const { roles, control, setError } = props;
  const { t } = useTranslation(['addUsers']);

  const { phoneNumberField, codeField } = textFieldStyles;
  const { textField } = buttonStyles;

  const validateEmailOrPhone = (value: string, values: any) => {
    const isEmailProvided = !!values[AddUserName.email];
    const isPhoneProvided = !!values[AddUserName.phoneNum];
    if (!isEmailProvided && !isPhoneProvided) {
      return `${t('addUsers:emailOrPhoneReq')}`;
    }
    return true;
  };

  useEffect(() => {
    const options = [
      {
        pin: '+1',
        country: 'USA',
      },
      {
        pin: '+91',
        country: 'India',
      },
    ];
    setCountryCodeOptions(options);
  }, []);

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        <Controller
          control={control}
          name={AddUserName.fullName}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <FieldWrapper>
                <TextField
                  sx={textField}
                  label={
                    <span>
                      {t('addUsers:fullName')} <span className="mandatory-field">*</span>
                    </span>
                  }
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                />
                <ErrorMsg>{error && error?.message}</ErrorMsg>
              </FieldWrapper>
            );
          }}
          rules={{
            required: `${t('addUsers:nameReq')}`,
            pattern: {
              value: Patterns.WordsBetweenSpace,
              message: t('addUsers:invalidName'),
            },
          }}
        />

        <Controller
          control={control}
          name={AddUserName.email}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <FieldWrapper>
                <TextField
                  sx={textField}
                  label={t('addUsers:email')}
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                  onBlur={(e) => {
                    if (e.target.value != '' && !Patterns.Email.test(e.target.value)) {
                      setError(AddUserName.email, {
                        type: 'validate',
                        message: `${t('addUsers:invalidEmailProvided')}`,
                      });
                    } else {
                      setError(AddUserName.email, { type: 'validate', message: '' });
                      onChange(e.target.value);
                    }
                  }}
                />
                <ErrorMsg>{error && error?.message}</ErrorMsg>
              </FieldWrapper>
            );
          }}
          rules={{
            pattern: {
              value: Patterns.Email,
              message: t('addUsers:invalidEmail'),
            },
            validate: validateEmailOrPhone,
          }}
        />

        <Controller
          control={control}
          name={AddUserName.phoneNum}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <FieldWrapper>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <TextField
                    select
                    sx={codeField}
                    value={countryCode}
                    onChange={(e) => setCountryCode(e.target.value)}
                    variant="standard"
                    InputProps={{ disableUnderline: true }}>
                    {countryCodeOptions.map((option) => (
                      <Options key={option.pin} value={option.pin}>
                        {option.pin}
                      </Options>
                    ))}
                  </TextField>
                  <TextField
                    sx={phoneNumberField}
                    label={t('addUsers:phoneNumber')}
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                  />
                </Box>
                <Box sx={{ width: '100%' }}>
                  <ErrorMsg>{error && error?.message}</ErrorMsg>
                </Box>
              </FieldWrapper>
            );
          }}
          rules={{
            pattern: {
              value: Patterns.PhoneNumber,
              message: `${t('addUsers:invalidPhoneNoProvided')}`,
            },
            validate: validateEmailOrPhone,
          }}
        />

        <Controller
          control={control}
          name={AddUserName.roleId}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <FieldWrapper>
                <TextField
                  sx={textField}
                  select
                  label={
                    <span>
                      {t('addUsers:role')} <span className="mandatory-field">*</span>
                    </span>
                  }
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setRoleOpen(!isRoleOpen)}
                          style={{
                            padding: 0,
                          }}>
                          <img
                            src={isRoleOpen ? Icons.DropdownDownIcon : Icons.DropdownUpIcon}
                            alt={isRoleOpen ? 'down' : 'up'}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  SelectProps={{
                    open: isRoleOpen,
                    IconComponent: () => null,
                    onOpen: () => setRoleOpen(true),
                    onClose: () => setRoleOpen(false),
                    MenuProps: {
                      PaperProps: {
                        style: {
                          boxShadow: '0px 16px 20px 0px rgba(58, 42, 0, 0.12)',
                        },
                      },
                    },
                  }}>
                  {roles?.map((item: any) => {
                    return (
                      <Options key={item.label} value={item.value}>
                        {item.label}
                      </Options>
                    );
                  })}
                </TextField>
                <ErrorMsg>{error && error?.message}</ErrorMsg>
              </FieldWrapper>
            );
          }}
        />
      </Box>
    </>
  );
};

export default AddUserFormRenderer;
