/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { ListItemButton, ListItemIcon, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { RootState } from '../../../Store/store';
import { RouteType } from '../../../../routes/config';

import theme from '../../../Themes/theme';

type Props = {
  item: RouteType;
  isSideBarVisible: boolean;
};

const LeftNavBarItem = ({ item, isSideBarVisible }: Props) => {
  const { appState } = useSelector((state: RootState) => state.appState);
  const isTablet = useMediaQuery('(max-width: 64rem)');
  const { t } = useTranslation(['logout', 'homePage']);
  const itemProps = item.sidebarProps;
  const isItemSelected = appState === item.state;

  const listItemButtonStyles = {
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
    },
    backgroundColor: isItemSelected ? theme.palette.primary.main : 'unset',
    padding: isTablet ? '0.5rem' : '1rem',
    borderRadius: isTablet ? '0.5rem' : '1rem',
    marginBottom: '0.5rem',
    alignItems: isSideBarVisible ? 'flexStart' : 'center',
    justifyContent: isSideBarVisible ? 'flexStart' : 'center',
    width: '100%',
  };

  const listItemIconStyles = {
    color: theme.palette.common.black,
    minWidth: '1.25rem',
    fontWeight: isItemSelected ? 'bold' : 'unset',
  };

  const itemTextStyle = {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h3?.fontSize,
    color: theme.palette.text.secondary,
    marginLeft: '0.875rem',
    fontWeight: isItemSelected ? 'bold' : 'unset',
    transition: 'opacity 0.25s',
  };

  return itemProps && item.path ? (
    <ListItemButton component={Link} to={item.path} sx={listItemButtonStyles}>
      {itemProps.icon && <ListItemIcon sx={listItemIconStyles}>{itemProps.icon}</ListItemIcon>}
      {isSideBarVisible && <span style={itemTextStyle}>{t(itemProps.displayText)}</span>}
    </ListItemButton>
  ) : null;
};

export default LeftNavBarItem;
