/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import moment from 'moment';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { Icons } from '../../../Constants/Icons';
import {
  ArrowButton,
  DateLabel,
  DatepickerButton,
  DatepickerButtonWrapper,
  DatePickerWrapper,
  DropdownWrapper,
  Select,
  SliderWrapper,
} from './SimpleDatePicker.styles';
import { YearRange } from '../../../Constants/App';

const range = (start: number, end: number) => {
  return Array(end - start + 1)
    .fill(0)
    .map((_, idx) => start + idx);
};

interface ISimpleDatePickerProps {
  CustomComponent: React.ReactNode;
  defaultValue: Date | null | undefined;
  onChange: (date: any) => void;
  isResponsive: boolean;
  id?: string;
  isExcludePastDate?: boolean;
  isExcludeFutureDate?: boolean;
  showSpecificRangeDates?: { from: Date; to: Date | undefined };
}

const SimpleDatePicker = ({
  CustomComponent,
  defaultValue,
  onChange,
  isResponsive,
  id,
  isExcludePastDate,
  isExcludeFutureDate,
  showSpecificRangeDates,
}: ISimpleDatePickerProps) => {
  const { t } = useTranslation(['altTexts']);
  const [startDate, setStartDate] = useState<Date | null>(new Date(new Date().toISOString()));
  const [selectedDate, setSelectedDate] = useState<Date | null | undefined>(new Date());
  const calRef = useRef<any>(null);

  useEffect(() => {
    if (defaultValue === null || defaultValue === undefined) {
      setStartDate(null);
    } else {
      setStartDate(moment(defaultValue).toDate());
      setSelectedDate(moment(defaultValue).toDate());
    }
  }, [defaultValue]);

  const years = range(YearRange.Start, YearRange.End);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const maxDate = showSpecificRangeDates?.to
    ? moment(showSpecificRangeDates.to).toDate()
    : isExcludeFutureDate
      ? moment().toDate()
      : null;

  return (
    <DatePickerWrapper isResponsive={isResponsive}>
      <DatePicker
        className="parthiban"
        ref={calRef}
        dateFormat="MM-dd-yyyy"
        selected={startDate}
        id={id}
        onChange={(date: any) => {
          // here date variable gives utc format
          setSelectedDate(date);
          if (date) {
            const dateWithTime =
              moment(date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ? moment().toDate() : date;
            setStartDate(dateWithTime);
            onChange?.(moment(date).format('YYYY-MM-DD'));
          }
        }}
        minDate={
          showSpecificRangeDates
            ? moment(showSpecificRangeDates.from).toDate()
            : isExcludePastDate
              ? moment().toDate()
              : null
        }
        maxDate={maxDate}
        customInput={CustomComponent}
        showPopperArrow={false}
        shouldCloseOnSelect={false}
        formatWeekDay={(nameOfDay) => nameOfDay.substring(0, 1)}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          increaseYear,
          decreaseYear,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
          prevYearButtonDisabled,
          nextYearButtonDisabled,
        }) => {
          const isPrevYearDisabled = (date.getFullYear() as YearRange) <= YearRange.Start;
          const isNextYearDisabled = (date.getFullYear() as YearRange) >= YearRange.End;

          return (
            <div>
              <DateLabel>{moment(selectedDate).format('MMM DD, yyyy')}</DateLabel>
              <SliderWrapper>
                <DropdownWrapper>
                  <ArrowButton onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                    <img src={Icons.ChevronSliderLeftIcon} alt={t('altTexts:backArrow')} />
                  </ArrowButton>
                  <Select
                    value={months[date.getMonth()]}
                    onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                    {months.map((option) => (
                      <option key={option} value={option}>
                        {option.substring(0, 3)}
                      </option>
                    ))}
                  </Select>
                  <ArrowButton onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                    <img src={Icons.ChevronSliderRightIcon} alt={t('altTexts:rightArrow')} />
                  </ArrowButton>
                </DropdownWrapper>
                <DropdownWrapper>
                  <ArrowButton onClick={decreaseYear} disabled={isPrevYearDisabled || prevYearButtonDisabled}>
                    <img
                      src={Icons.ChevronSliderLeftIcon}
                      alt={t('altTexts:backArrow')}
                      style={{ opacity: isPrevYearDisabled || prevYearButtonDisabled ? '0.4' : '1' }}
                    />
                  </ArrowButton>
                  <Select value={date.getFullYear()} onChange={({ target: { value } }: any) => changeYear(value)}>
                    {years.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </Select>
                  <ArrowButton onClick={increaseYear} disabled={isNextYearDisabled || nextYearButtonDisabled}>
                    <img
                      src={Icons.ChevronSliderRightIcon}
                      alt={t('altTexts:rightArrow')}
                      style={{ opacity: isNextYearDisabled || nextYearButtonDisabled ? '0.4' : '1' }}
                    />
                  </ArrowButton>
                </DropdownWrapper>
              </SliderWrapper>
            </div>
          );
        }}>
        <DatepickerButtonWrapper>
          <DatepickerButton
            onClick={() => {
              calRef?.current?.setOpen(false);
              setSelectedDate(startDate ?? new Date());
            }}>
            Cancel
          </DatepickerButton>
          <DatepickerButton
            onClick={() => {
              if (selectedDate) {
                const dateWithTime =
                  moment(selectedDate).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')
                    ? moment().toDate()
                    : selectedDate;
                setStartDate(dateWithTime);
                onChange?.(moment(selectedDate).format('YYYY-MM-DD'));
              }
              calRef?.current?.setOpen(false);
            }}>
            Ok
          </DatepickerButton>
        </DatepickerButtonWrapper>
      </DatePicker>
    </DatePickerWrapper>
  );
};

SimpleDatePicker.defaultProps = {
  isExcludePastDate: false,
  isExcludeFutureDate: false,
};

export default SimpleDatePicker;
