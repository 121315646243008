/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React from 'react';

const PageNotFound = () => {
  return <div>Page Not found</div>;
};

export default PageNotFound;
