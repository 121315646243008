/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

/* eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, CircularProgress, Divider, Typography } from '@mui/material';
import { Icons } from '../../../../Shared/Constants/Icons';
import {
  convertToReadableFormat,
  getAcronymName,
  getStoredCustomerDetails,
  AssignmentCardType,
  formatDate,
} from '../../../../Shared/Utilities/utils';
import { SearchFieldContainer, SearchInput } from '../../../../Shared/Components/Layout/styles';
import { ITeamMemberData } from './JobAssignmentCard';
import WorkOrdersApiService from '../../Services/WorkOrdersService';
import { CreateWorkOrderTeam } from '../../../../API';
import SnackbarMessage from '../../../Onboarding/Components/Login/SnackbarMessage';
import {
  AssignTeamDiv,
  buttonStyles,
  CardContainerWrapper,
  CardTitle,
  DatePickerContainer,
  HeadingText,
  LogoWrapper,
  TeamCard,
  TeamContainer,
} from './OneTimeJobDetails.Style';
import theme from '../../../../Shared/Themes/theme';
import DateSelection from '../../../../Shared/Components/Common/DatePicker/DateSelection';
import { FieldNames } from '../../Models/AddEditOneTimeJob.Model';
import { useForm } from 'react-hook-form';

interface AssignTeam {
  teamData: ITeamMemberData[];
  setTeamData: (data: ITeamMemberData[]) => void;
  originalTeamData: ITeamMemberData[];
  setOriginalTeamData: (data: ITeamMemberData[]) => void;
  associateTeamData: ITeamMemberData[];
  setAssociateTeamData: (data: ITeamMemberData[]) => void;
  originalAssociateTeamData: ITeamMemberData[];
  setOriginalAssociateTeamData: (data: ITeamMemberData[]) => void;
  checkedSupervisors: ITeamMemberData[];
  setCheckedSupervisors: (data: ITeamMemberData[]) => void;
  checkedAssociates: ITeamMemberData[];
  setCheckedAssociates: (data: ITeamMemberData[]) => void;
  isTeamDataLoading: boolean;
  fetchAssociateTeam: (workOrderId: string) => void;
  facilityId: string;
  workOrderId: string;
  onClose: (type: AssignmentCardType) => void;
  isViewOnly: boolean;
  fetchVendorTeam: (workOrderId: string) => void;
  assignmentType: AssignmentCardType;
  workOrderData: any;
}

export const checkAlreadySelected = (data: ITeamMemberData[], memberId: string): boolean => {
  return data.some((prevMember) => prevMember.id === memberId);
};

const AssignTeam = ({
  teamData,
  setTeamData,
  originalTeamData,
  setOriginalTeamData,
  associateTeamData,
  setAssociateTeamData,
  originalAssociateTeamData,
  setOriginalAssociateTeamData,
  checkedSupervisors,
  setCheckedSupervisors,
  checkedAssociates,
  setCheckedAssociates,
  isTeamDataLoading,
  fetchAssociateTeam,
  facilityId,
  workOrderId,
  onClose,
  isViewOnly,
  fetchVendorTeam,
  assignmentType,
  workOrderData,
}: AssignTeam) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [isRecommended, setIsRecommended] = useState(true);
  const [teamCardNumber, setTeamCardNumber] = useState<number>(0);
  const [isConfirmButtonLoading, setIsConfirmButtonLoading] = useState<boolean>(false);
  const [isDataSaving, setIsDataSaving] = useState<boolean>(false);

  const { t } = useTranslation(['assignJob', 'oneTimeJob', 'calendar']);
  const { divider, buttonText, createJobButton, cancelButton } = buttonStyles;
  const { control, getValues } = useForm({
    defaultValues: {
      startDate: workOrderData.actualStartDate ? formatDate(workOrderData.actualStartDate) : null,
      endDate: workOrderData.actualEndDate ? formatDate(workOrderData.actualEndDate) : null,
    },
  });

  const handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
    if (type === 'supervisor') {
      if (event.target.value.trim()) {
        const searchResults = originalTeamData.filter((member) =>
          member?.name?.toLowerCase()?.includes(event.target.value.toLowerCase())
        );
        setIsRecommended(false);
        setTeamData(searchResults);
      } else {
        setTeamData(originalTeamData);
        setIsRecommended(true);
      }
    } else {
      if (event.target.value.trim()) {
        const searchResults = originalAssociateTeamData.filter((member) =>
          member?.name?.toLowerCase()?.includes(event.target.value.toLowerCase())
        );
        setIsRecommended(false);
        setAssociateTeamData(searchResults);
      } else {
        setAssociateTeamData(originalAssociateTeamData);
        setIsRecommended(true);
      }
    }
  };

  const handleSelectMember = (memberId: string, memberType: string) => {
    if (memberType === 'supervisor') {
      handleSelectSupervisor(memberId);
    } else {
      handleSelectAssociate(memberId);
    }
  };

  const handleSelectSupervisor = (memberId: string) => {
    const teamMembersData = [...teamData];
    const originalTeamMembersData = [...originalTeamData];
    const selectedMember = teamMembersData.find((member: ITeamMemberData) => member.id === memberId);
    if (selectedMember) {
      selectedMember.isChecked = !selectedMember?.isChecked;
      const selectedMemberindex = teamMembersData.findIndex((member: ITeamMemberData) => member.id === memberId);
      const originalSelectedMemberindex = originalTeamMembersData.findIndex(
        (member: ITeamMemberData) => member.id === memberId
      );

      if (selectedMemberindex !== -1) {
        teamMembersData[selectedMemberindex] = selectedMember;
        setTeamData(teamMembersData);
      }
      if (originalSelectedMemberindex !== -1) {
        originalTeamMembersData[originalSelectedMemberindex] = selectedMember;
        setOriginalTeamData(originalTeamMembersData);
      }
      const checkedSupervisorsList = originalTeamMembersData.filter((member: ITeamMemberData) => member.isChecked);
      setCheckedSupervisors(checkedSupervisorsList);
    }
    // setIsSupervisorSelected(!isSupervisorSelected);
  };

  const handleSelectAssociate = (memberId: string) => {
    const teamMembersData = [...associateTeamData];
    const originalTeamMembersData = [...originalAssociateTeamData];
    const selectedMember = teamMembersData.find((member: ITeamMemberData) => member.id === memberId);
    if (selectedMember) {
      selectedMember.isChecked = !selectedMember?.isChecked;
      const selectedMemberindex = teamMembersData.findIndex((member: ITeamMemberData) => member.id === memberId);
      const originalSelectedMemberindex = originalTeamMembersData.findIndex(
        (member: ITeamMemberData) => member.id === memberId
      );

      if (selectedMemberindex !== -1) {
        teamMembersData[selectedMemberindex] = selectedMember;
        setAssociateTeamData(teamMembersData);
      }
      if (originalSelectedMemberindex !== -1) {
        originalTeamMembersData[originalSelectedMemberindex] = selectedMember;
        setOriginalAssociateTeamData(originalTeamMembersData);
      }
      const checkedAssociatesList = originalTeamMembersData.filter((member: ITeamMemberData) => member.isChecked);
      setCheckedAssociates(checkedAssociatesList);
    }
  };

  const onConfirmSupervisors = async () => {
    try {
      setIsDataSaving(true);
      const customerDetails = getStoredCustomerDetails();
      const vendorId = customerDetails.customerId;
      const createWorkOrderTeamInput: CreateWorkOrderTeam[] = checkedSupervisors.map((item) => {
        if (item.teamId) {
          return {
            userId: item?.id || '',
            vendorId,
            roleId: item?.roleId as string,
            isSupervisor: item?.isSupervisor,
            teamId: item?.teamId,
            facilityId,
          };
        }
        return {
          userId: item?.id || '',
          vendorId,
          roleId: item?.roleId as string,
          isSupervisor: item?.isSupervisor,
          facilityId,
        };
      });
      const confirmedTeamResponse = await WorkOrdersApiService.updateWorkOrderTeam({
        workOrderId,
        createWorkOrderTeamInput,
      });
      if (confirmedTeamResponse.errors?.length) {
        setSnackbarMessage(confirmedTeamResponse.errors[0].message);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        setIsDataSaving(false);
      } else {
        setSnackbarMessage(`${t('messages:successfullyAssignedSupervisor')}`);
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setIsDataSaving(false);
        onClose(assignmentType);
      }
    } catch (e) {
      setIsDataSaving(false);
      console.log('Error:', e);
    }
  };

  const handleNextButton = () => {
    if (!isViewOnly) {
      setTeamCardNumber(teamCardNumber + 1);
      if (teamCardNumber === 0) fetchAssociateTeam(workOrderId);
    } else {
      if (teamCardNumber === 0) {
        onConfirmSupervisors();
      }
    }
  };

  useEffect(() => {
    fetchVendorTeam(workOrderId);
  }, []);

  const renderTeamContent = (teamData: ITeamMemberData[]) => {
    return (
      <>
        {teamData?.length > 0 && (
          <AssignTeamDiv>
            {teamData?.map((member: ITeamMemberData) => {
              return (
                <TeamCard
                  key={member.id}
                  onClick={() =>
                    handleSelectMember(member.id as string, teamCardNumber === 0 ? 'supervisor' : 'associate')
                  }>
                  <div style={{ display: 'flex' }}>
                    <LogoWrapper>
                      <div>{getAcronymName(member.name as string).toUpperCase()}</div>
                    </LogoWrapper>
                    <TeamContainer>
                      <div className="Title">{member.name}</div>
                      <div className="Position">{convertToReadableFormat(member.role)}</div>
                    </TeamContainer>
                  </div>
                  <div>
                    <Box>
                      {member.isChecked ? (
                        <img
                          src={Icons.CheckedIcon}
                          alt={t('altTexts:checked')}
                          style={{ cursor: 'pointer', height: '1.5rem', width: '1.5rem' }}
                        />
                      ) : (
                        <img src={Icons.Unchecked} alt={t('altTexts:unchecked')} style={{ cursor: 'pointer' }} />
                      )}
                    </Box>
                  </div>
                </TeamCard>
              );
            })}
          </AssignTeamDiv>
        )}
      </>
    );
  };
  const parseDateString = (dateString: string | null): Date | null => {
    return dateString ? new Date(dateString) : null;
  };

  const disableNextButton = () => {
    if (teamCardNumber === 0) {
      return checkedSupervisors.length ? false : true;
    } else if (teamCardNumber === 1) {
      return checkedAssociates.length ? false : true;
    }
    return true;
  };

  const shortlistedTeam = (): ITeamMemberData[] => {
    // if (isSkipped) return shortlistedSupervisors;
    // if (isAddingNewAssociate) return [...checkedMembers];
    return [...checkedSupervisors, ...checkedAssociates];
  };

  const toISOStringOrNull = (date: Date | null | undefined): string | null => {
    return date ? new Date(date).toISOString() : null;
  };

  const handleConfirmButton = async () => {
    setIsConfirmButtonLoading(true);
    try {
      const customerDetails = getStoredCustomerDetails();
      const vendorId = customerDetails.customerId;
      const startDate = getValues(FieldNames.startDate);
      const endDate = getValues(FieldNames.endDate);
      let finalMembers = [];
      // if (onBehalfOfAssociate) {
      //   const newlyAddedMembers = shortlistedTeam();
      //   finalMembers = [...newlyAddedMembers, ...originalCheckedMembers];
      // } else {
      //   finalMembers = shortlistedTeam();
      // }
      finalMembers = shortlistedTeam();
      if (finalMembers.length) {
        const createWorkOrderTeamInput: CreateWorkOrderTeam[] = finalMembers.map((item) => {
          const parsedStartDate = parseDateString(startDate);
          const parsedEndDate = parseDateString(endDate);
          if (item.teamId) {
            return {
              userId: item.id || '',
              vendorId,
              roleId: item.roleId as string,
              isSupervisor: item.isSupervisor,
              teamId: item.teamId,
              facilityId,
              associateStartDate: toISOStringOrNull(parsedStartDate),
              associateEndDate: toISOStringOrNull(parsedEndDate),
            };
          }
          const newMemberInput: CreateWorkOrderTeam = {
            userId: item.id || '',
            vendorId,
            roleId: item.roleId as string,
            isSupervisor: item.isSupervisor,
            facilityId,
          };
          if (!item.isSupervisor) {
            newMemberInput.associateStartDate = toISOStringOrNull(parsedStartDate);
            newMemberInput.associateEndDate = toISOStringOrNull(parsedEndDate);
          }
          return newMemberInput;
        });
        const confirmedTeamResponse = await WorkOrdersApiService.updateWorkOrderTeam({
          workOrderId,
          createWorkOrderTeamInput,
        });
        if (confirmedTeamResponse.errors?.length) {
          setSnackbarMessage(confirmedTeamResponse.errors[0].message);
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        } else {
          setSnackbarMessage(`${t('messages:successfullyAssignedTeam')}`);
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
          onClose(assignmentType);
        }
      }
      setIsConfirmButtonLoading(false);
    } catch (e) {
      setIsConfirmButtonLoading(false);
      console.error('Error', e);
    }
  };

  useEffect(() => {
    disableNextButton();
  }, [teamCardNumber, checkedSupervisors, checkedAssociates]);

  return (
    <>
      <HeadingText>
        {teamCardNumber === 2
          ? t('assignJob:assignDuration')
          : teamCardNumber === 0
            ? t('assignJob:assignSupervisors')
            : t('assignJob:assignAssociates')}
      </HeadingText>{' '}
      <Divider sx={divider} />
      {teamCardNumber === 2 ? (
        <Box sx={{ display: 'flex' }}>
          <DatePickerContainer>
            <DateSelection
              requiredText={t('oneTimeJob:startDateFieldReq')}
              control={control}
              fieldName={FieldNames.startDate}
              label={t('calendar:startDate')}
              iconColor="black"
              isExcludePastDate={false}
              showSpecificRangeDates={{ from: workOrderData.actualStartDate, to: workOrderData.actualEndDate }}
            />
            <DateSelection
              requiredText={t('oneTimeJob:endDateFieldReq')}
              control={control}
              fieldName={FieldNames.endDate}
              label={t('calendar:endDate')}
              iconColor="black"
              isExcludePastDate={false}
              showSpecificRangeDates={{ from: workOrderData.actualStartDate, to: workOrderData.actualEndDate }}
            />
          </DatePickerContainer>
        </Box>
      ) : (
        <>
          <CardTitle>
            {teamCardNumber === 0 ? t('assignJob:selectSupervisor') : t('assignJob:selectAssociateBelow')}:
          </CardTitle>
          <SearchFieldContainer
            sx={{
              boxShadow: '0px 10px 20px 6px rgba(121, 87, 0, 0.10)',
              marginBottom: '2em',
            }}>
            <SearchInput
              type="text"
              placeholder={teamCardNumber === 0 ? t('assignJob:searchSupervisor') : t('assignJob:searchAssociate')}
              onChange={(event) => {
                handleSearchInput(event, teamCardNumber === 0 ? 'supervisor' : 'associate');
              }}
            />
            <img src={Icons.SearchIcon} alt={t('altTexts:search')} style={{ width: '1.5rem', height: '1.5rem' }} />
          </SearchFieldContainer>
          {isRecommended ? <CardTitle>{t('assignJob:recommended')}</CardTitle> : null}
          <CardContainerWrapper>
            {isTeamDataLoading ? (
              <CircularProgress size={30} sx={{ color: theme.palette.primary.dark, marginLeft: '45%' }} />
            ) : teamCardNumber === 0 ? (
              teamData.length > 0 ? (
                renderTeamContent(teamData)
              ) : (
                <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
                  {t('assignJob:noResultsFound')}
                </Typography>
              )
            ) : associateTeamData.length > 0 ? (
              renderTeamContent(associateTeamData)
            ) : (
              <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
                {t('assignJob:noResultsFound')}
              </Typography>
            )}
          </CardContainerWrapper>
        </>
      )}
      {teamCardNumber === 2 ? (
        <div style={{ display: 'flex' }}>
          <Button
            // disabled={disableNextButton()}
            sx={{ ...cancelButton, cursor: 'pointer', maxWidth: '100%', marginTop: '2em', width: '100%' }}
            onClick={() => onClose(assignmentType)}>
            <Typography sx={{ ...buttonText, color: theme.palette.primary.dark }}>{t('assignJob:cancel')}</Typography>
          </Button>
          <Button
            // disabled={disableNextButton()}
            sx={{ ...createJobButton, maxWidth: '100%', marginTop: '2em', width: '100%' }}
            onClick={handleConfirmButton}>
            {isConfirmButtonLoading ? (
              <CircularProgress size={30} sx={{ color: theme.palette.common.white }} />
            ) : (
              <Typography sx={{ ...buttonText, color: theme.palette.common.white }}>
                {t('assignJob:confirm')}
              </Typography>
            )}
          </Button>
        </div>
      ) : (
        <Button
          disabled={disableNextButton()}
          sx={{ ...createJobButton, opacity: disableNextButton() ? '0.5' : '1', maxWidth: '100%', marginTop: '2em' }}
          onClick={handleNextButton}>
          {isDataSaving ? (
            <CircularProgress size={30} sx={{ color: theme.palette.common.white }} />
          ) : (
            <Typography sx={{ ...buttonText, color: theme.palette.common.white }}>
              {isViewOnly ? t('assignJob:assignSupervisors') : t('assignJob:next')}
            </Typography>
          )}
        </Button>
      )}
      <SnackbarMessage
        open={snackbarOpen}
        successMessage={snackbarMessage}
        errorMessage={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
      />
    </>
  );
};

export default AssignTeam;
