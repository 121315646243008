/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageTitle from '../../../../Shared/Components/Common/PageTitle/PageTitle';
import { CustomerContainer } from '../../../../Shared/Components/Layout/styles';
import JobCreateApiService from '../../../Jobs/Services/JobCreateService';
import AdditionalExpensesTable from '../BidEstimationTable/AdditionalExpensesTable';
import EstimationTable from '../BidEstimationTable/BidEstimationTable';
import PrimeVendorExpensesTable from '../BidEstimationTable/PrimeVendorEstimationTable';
import BidHeader from '../BidHeader/BidHeader';

import {
  getStoredCustomerDetails,
  removeJobLocalData,
  uploadDocumentToS3Bucket,
} from '../../../../Shared/Utilities/utils';

import { useLocation, useNavigate } from 'react-router-dom';
import {
  BidSubmitStatus,
  FileContextType,
  JobStatus,
  MasterData,
  PricingOptions,
  UserType,
  TagNames,
  ExecutionType,
  EstimationType,
} from '../../../../Shared/Constants/App';

import { isEmpty } from 'lodash';
import {
  BidDraftStep,
  BidStatus,
  Billable,
  BooleanType,
  CreateBidAttachmentInput,
  CreateJobBidAdditionalExpensesInput,
  DraftStatus,
  EntityType,
  Facility,
  generateInvoiceInput,
  JobBidEstimation,
  JobService,
  JobType,
  MdFrequencyType,
  ServiceCategoryV3,
  YesNoType,
} from '../../../../API';
import MasterDataApiService from '../../../../Shared/Services/MasterDataService';
import { RESPONSE_CODES } from '../../../Customer/Models/BidApproval.Model';
import FacilityApiService from '../../../Facility/Services/FacilityApiService';
import { RecommendedJobsResponse } from '../../../Jobs/Models/AddEditOneTimeJob.Model';
import SnackbarMessage from '../../../Onboarding/Components/Login/SnackbarMessage';
import { IAllBidAttachments } from '../../Models/Bid.model';
import CreateBidServices from '../../Services/CreateBidServices';
import VendorApiService from '../../Services/VendorService';
import { ProfitOption } from '../BottomBar/BottomBar.Models';
import { Estimation, JobServices } from '../../../../Shared/Models/Bid';
import AsyncStorageKeys from '../../../../Shared/Constants/StorageKeys';
import BottomBar from '../BottomBar/BottomBar';
import BidRequestRevisionComments from '../BidRevision/BidRequestRevisionComments';

export interface ICurrentBid {
  bidId: string;
  isBiddingFromJobMarketPlace?: boolean;
  jobId: string;
  jobType: JobType;
  mdVendorType: string;
  startDate: string;
  stopDate: string;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const CreateBid = () => {
  const { t } = useTranslation(['assignJob', 'vendor']);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryBidId = queryParams.get('id');
  let bidDetails = location.state?.jobDetails;
  const jobName = bidDetails?.jobName;

  const authValue = localStorage.getItem('auth');
  let role: UserType;
  if (authValue !== null) {
    const authData = JSON.parse(authValue);
    role = authData?.attributes?.['custom:Role'];
  } else {
    // case where 'auth' is not found in localStorage
    role = UserType.NoUser;
  }

  const [storedJob, setStoredJob] = useState(localStorage.getItem(AsyncStorageKeys.currentJob) || '');

  let title: string;
  if (role == UserType.Vendor) {
    if (bidDetails?.jobStatus === 'Awarded') {
      title = `${t('assignJob:modifyQuote')}`;
    } else {
      title = queryBidId ? bidDetails.jobName : `${t('vendor:createBid')}`;
    }
  } else {
    title = jobName;
  }
  const navigate = useNavigate();
  if (!bidDetails && storedJob) {
    bidDetails = JSON.parse(storedJob);
    title = queryBidId ? bidDetails.jobName : `${t('vendor:createBid')}`;
  }

  // const [isPopupOpen, setPopupOpen] = useState(false);

  // Bid/ Vendor table total amount
  const [subVendorTotalEstimation, setSubVendorTotalEstimation] = useState(0);

  // Bid/ Vendor table total amount
  const [primeVendorTotalEstimation, setPrimeVendorTotalEstimation] = useState(0);

  const [additionalExpenseTotal, setAdditionalExpenseTotal] = useState(0);

  // Value of profit margin
  const [profitPercentage, setProfitPercentage] = useState(0);

  // Additional table total
  const [additionalSubTotal, setAdditionalSubTotal] = useState(0);

  // Bid/ Vendor table total amount
  const [totalEstimation, setTotalEstimation] = useState(0);

  // selected $ or %
  const [selectedProfitOption, setSelectedProfitOption] = useState(ProfitOption.Percentage);
  const [bidId, setBidId] = useState('');
  const [subJobBidId, setSubJobBidId] = useState('');
  const [jobId, setJobId] = useState('');
  const [subJobId, setSubJobId] = useState('');

  const [primeVendorEstimations, setPrimeVendorEstimations] = useState<any[]>(() => []);
  const [subVendorEstimations, setSubVendorEstimations] = useState<any[]>(() => []);
  const [currentBidVersion, setCurrentBidVersion] = useState<number>(0);
  const [bidVersion, setBidVersion] = useState<number>(0);
  const [customerComment, setCustomerComment] = useState<string>('');
  const [vendorComment, setVendorComment] = useState<string>('');

  const [bidVersionLoader, setIsBidVersionLoader] = useState(false);

  const [additionalExpenseData, setAdditionalExpenseData] = useState<any[]>(
    location.state &&
      location.state.jobDetails &&
      location.state.jobDetails?.additionalExpenseData &&
      location.state.jobDetails?.additionalExpenseData.length
      ? location.state.jobDetails?.additionalExpenseData
      : storedJob &&
          JSON.parse(storedJob) &&
          JSON.parse(storedJob)?.additionalExpenseData &&
          Array.isArray(JSON.parse(storedJob)?.additionalExpenseData)
        ? JSON.parse(storedJob).additionalExpenseData
        : [
            {
              name: '',
              totalJobEstimation: 0,
              mdServiceId: '',
              jobId: '',
              additionalCategoryId: '',
            },
          ]
  );
  const [additionalExpensesOptions, setAdditionalExpensesOptions] = useState([
    {
      name: '',
      additionalCategoryId: '',
      description: '',
      rate: '',
      bidAdditionalExpenseId: '',
    },
  ]);
  const [totalEstimationWithMargin, setTotalEstimationWithMargin] = useState(0);
  const [uploadedVendorQuotes, setUploadedVendorQuotes] = useState([]);
  const [quoteAttachments, setQuoteAttachments] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [isQuoteUploading, setIsQuoteUploading] = useState<boolean>(false);
  const [facilityDetails, setFacilityDetails] = useState<Facility>();
  const [vendorRatesAndTaxes, setVendorRatesAndTaxes] = useState([]);
  const [unionIndicator, setUnionIndicator] = useState<string>('Non-Union');
  const [isServicesLoading, setIsServicesLoading] = useState<boolean>(false);
  const [isSubJobServicesLoading, setIsSubJobServicesLoading] = useState<boolean>(false);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [ptiType, setPtiType] = useState<string>('NoOverTime');
  const [services, setServices] = useState<JobServices[]>([]);
  const [finalQuote, setFinalQuote] = useState(0);
  const [isFinalQuoteLoading, setIsFinalQuoteLoading] = useState<boolean>(false);

  const [pricingOptionsList, setPricingOptionsList] = useState<any>([{ value: 'Hours', label: 'Hours', id: 1 }]);
  const [selectedPricingOption, setSelectedPricingOption] = useState<PricingOptions>(PricingOptions.Hours);

  useEffect(() => {
    if (location?.state?.jobName) {
      setSnackbarMessage(`${location?.state?.jobName} Job successfully created.`);
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    }
    removeJobLocalData();
    if (storedJob) {
      const currentJob = JSON.parse(storedJob);
      setPrimeVendorTotalEstimation(currentJob.primeVendorTotalEstimation ?? 0);
      setAdditionalExpenseTotal(currentJob.additionalExpenseTotal ?? 0);
      setSubVendorTotalEstimation(currentJob.subVendorTotalEstimation ?? 0);
    }
  }, []);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    const storedJob = localStorage.getItem(AsyncStorageKeys.currentJob);

    if (storedJob) {
      const currentJob = JSON.parse(storedJob);
      const jobData = { ...currentJob };

      if (primeVendorEstimations.some((item: any) => item.numOfHours !== 0 || item.ratePerHour !== 0)) {
        jobData.jobServices = primeVendorEstimations;
      }

      if (additionalExpenseData) {
        jobData.additionalExpenseData = additionalExpenseData;
      }

      if (subVendorEstimations) {
        jobData.subJobServices = subVendorEstimations;
      }

      if (subVendorTotalEstimation) {
        jobData.subVendorTotalEstimation = subVendorTotalEstimation;
      }

      if (selectedProfitOption) {
        jobData.selectedProfitOption = selectedProfitOption;
      }

      if (profitPercentage) {
        jobData.profitMargin = profitPercentage;
      }

      if (primeVendorTotalEstimation) {
        jobData.primeVendorTotalEstimation = primeVendorTotalEstimation;
      }

      if (additionalExpenseTotal) {
        jobData.additionalExpenseTotal = additionalExpenseTotal;
      }

      localStorage.setItem(AsyncStorageKeys.currentJob, JSON.stringify(jobData));
    }
  }, [
    primeVendorEstimations,
    additionalExpenseData,
    subVendorEstimations,
    selectedProfitOption,
    profitPercentage,
    primeVendorTotalEstimation,
    additionalExpenseTotal,
    subVendorTotalEstimation,
    totalEstimationWithMargin,
  ]);

  const getAdditionalExpensesMasterData = async () => {
    const { customerId } = getStoredCustomerDetails();
    const categoriesResponse = await MasterDataApiService.getMasterDataCategories(
      MasterData.AdditionalExpenses,
      customerId
    );
    const finalResponse = categoriesResponse?.data.map((dataItem: any) => {
      return {
        name: dataItem.value,
        additionalCategoryId: dataItem.keyCode,
        description: dataItem.subKey2Value,
        rate: '',
        bidAdditionalExpenseId: '',
      };
    });
    setAdditionalExpensesOptions(finalResponse);
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const updateAndSubmit = async (type: BidSubmitStatus) => {
    try {
      const currentJob = JSON.parse(localStorage.getItem(AsyncStorageKeys.currentJob) || 'null');
      if (subJobBidId) {
        if (
          bidDetails?.executionType === ExecutionType.SubVendor &&
          bidDetails?.estimationType === EstimationType.EstimateWithinPeazy
        ) {
          const { customerId } = await Promise.resolve(getStoredCustomerDetails());
          // if (location.state.jobDetails.jobStatus === JobStatus.RevisionRequested) {
          //   const updateBidResponse = await CreateBidServices.updateBid(customerId, subJobBidId, {
          //     dataStatus: BidStatus.P,
          //     finalQuote: parseFloat(String(subVendorTotalEstimation)),
          //   });
          // } else {
          await CreateBidServices.updateBid(customerId, subJobBidId, {
            dataStatus: BidStatus.P,
          });
          // }
        } else {
          await addSubVendorJobEstimations();
          await updateSubBid();
        }
      }
      const jobEstimationResponse = await addPrimeVendorJobEstimations();
      const additionalExpensesEstimationResponse = await addAdditionalExpensesEstimation();
      const updateBidResponse = await updateBid(type);
      if (
        jobEstimationResponse.errors.length ||
        additionalExpensesEstimationResponse.errors.length ||
        updateBidResponse.errors.length
      ) {
        const errorMessage = updateBidResponse.errors.length
          ? updateBidResponse.errors[0].message
          : jobEstimationResponse.errors.length
            ? jobEstimationResponse.errors[0].message
            : additionalExpensesEstimationResponse.errors[0].message;
        console.error('Bid error', errorMessage);
      } else {
        if (!bidDetails?.isVendorBid) {
          await generateInvoice();
        }
      }

      const jobData = {
        ...currentJob,
        bidId: updateBidResponse?.data?.bidId ?? currentJob?.bidId,
        jobServices: [...primeVendorEstimations],
        additionalExpenseData: additionalExpenseData
          ? [...additionalExpenseData]
          : [
              {
                name: '',
                totalJobEstimation: 0,
                mdServiceId: '',
                jobId: '',
                additionalCategoryId: '',
              },
            ],
        totalEstimation: totalEstimation,
        subVendorTotalEstimation: subVendorTotalEstimation,
        primeVendorTotalEstimation: primeVendorTotalEstimation,
        additionalSubTotal: additionalSubTotal,
        additionalExpenseTotal: additionalExpenseTotal,
        selectedProfitOption: selectedProfitOption,
        profitMargin: profitPercentage,
        finalquote: totalEstimationWithMargin ? totalEstimationWithMargin : totalEstimation,
        ptiType: ptiType,
      };
      if (subJobBidId) {
        jobData.subJobBidId = subJobBidId;
        jobData.subJobServices = [...subVendorEstimations];
      }
      // const modifyQuoteData = {
      //   totalEstimation: totalEstimation,
      //   finalquote: totalEstimationWithMargin ? totalEstimationWithMargin : totalEstimation,
      //   ptiType: ptiType,
      //   profitMargin: profitPercentage,
      //   selectedProfitOption: selectedProfitOption,
      //   additionalSubTotal: additionalSubTotal,
      //   additionalExpenseTotal: additionalExpenseTotal,
      // };

      localStorage.setItem(AsyncStorageKeys.currentJob, JSON.stringify(jobData));
      localStorage.setItem(AsyncStorageKeys.viewJob, JSON.stringify(jobData));

      if (type === BidSubmitStatus.saveAndExit) {
        if (bidDetails?.isVendorBid) {
          navigate(-1);
        } else {
          navigate('/vendor-jobs/self-performed-jobs');
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getFacilityDetails = async (customerId: string, facilityId: string) => {
    const facilityResponse = await FacilityApiService.facility(facilityId, customerId);

    if (facilityResponse.data) {
      setFacilityDetails(facilityResponse.data);
    }
  };

  const createBid = async (parsedJob: any) => {
    const { jobId, subJobId, jobName } = parsedJob;

    try {
      const { customerId = '' } = getStoredCustomerDetails() || {};
      const createBidResponse = await CreateBidServices.addJobBid(jobId, customerId, {
        bidCreatedFromSample: '',
        bidCreationDate: '',
        bidEstimationType: 'Easy',
        dataStatus: BidStatus.A,
        bidVersionCreatedDate: '',
        submittedBy: '',
        bidTemplate: '',
        bidVersion: 0,
        bidSubmissionDate: '',
        bidName: jobName,
        finalQuote: 0,
        profitMargin: 0,
        totalCost: 0,
        isFlatMargin: selectedProfitOption === ProfitOption.Dollar ? YesNoType.Y : YesNoType.N,
        draftStatus: DraftStatus.Y,
      });

      // create one bid for subJob as well.
      if (subJobId) {
        const vendorId = parsedJob.vendorDetails.vendorId[0].vendorId
          ? parsedJob.vendorDetails.vendorId[0].vendorId
          : customerId;
        const subJobBidResponse = await CreateBidServices.addJobBid(subJobId, vendorId, {
          bidCreatedFromSample: '',
          bidCreationDate: '',
          bidEstimationType: 'Easy',
          dataStatus: BidStatus.A,
          bidVersionCreatedDate: '',
          submittedBy: '',
          bidTemplate: '',
          bidVersion: 0,
          bidSubmissionDate: '',
          bidName: jobName,
          finalQuote: 0,
          profitMargin: 0,
          totalCost: 0,
          draftStatus: DraftStatus.Y,
        });
        if (subJobBidResponse.data) {
          if (storedJob) {
            localStorage.setItem(
              AsyncStorageKeys.currentJob,
              JSON.stringify({
                ...JSON.parse(storedJob),
                subJobBidId: subJobBidResponse.data.bidId,
                subJobBids: [{ bidId: subJobBidResponse.data.bidId }],
              })
            );
            setStoredJob(
              JSON.stringify({
                ...JSON.parse(storedJob),
                subJobBidId: subJobBidResponse.data.bidId,
                subJobBids: [{ bidId: subJobBidResponse.data.bidId }],
              })
            );
          }
          setSubJobBidId(subJobBidResponse.data.bidId);
        }
      }

      // eslint-disable-next-line sonarjs/no-all-duplicated-branches
      if (createBidResponse.data) {
        const currentJob = localStorage.getItem(AsyncStorageKeys.currentJob);
        if (currentJob) {
          localStorage.setItem(
            AsyncStorageKeys.currentJob,
            JSON.stringify({
              ...JSON.parse(currentJob),
              jobBids: [{ bidId: createBidResponse.data.bidId }],
            })
          );
        }
        const updatedStoredJob = storedJob && JSON.parse(storedJob);
        setStoredJob(
          JSON.stringify({
            ...updatedStoredJob,
            bidId: createBidResponse.data.bidId,
            jobBids: [{ bidId: createBidResponse.data.bidId }],
          })
        );
        setBidId(createBidResponse?.data?.bidId);
      }
    } catch (e) {
      console.log(JSON.stringify(e));
    }
  };

  const mapServiceData = (data: any, allServiceCategories: any) => {
    return data.map((dataItem: any, index: number) => {
      const selectedService = allServiceCategories.find(
        (serviceItem: any) => serviceItem.serviceCategoryId === dataItem.mdServiceId
      );
      return {
        service: selectedService?.serviceCategoryName,
        id: index,
        quantity: dataItem.serviceMeasure || 0,
        unit: dataItem.mdServiceUnits,
        mdServiceId: dataItem.mdServiceId,
      };
    });
  };

  const getServiceCategories = async (customerId: string) => {
    try {
      const masterData = await MasterDataApiService.getAllServiceCategoryV3(customerId);
      return masterData.data ?? [];
    } catch (e) {
      console.log(e);
      return [];
    }
  };

  const isPricingOptionsHasValue = (serviceMeasure: string) => {
    const pricingOptionsArray = Object.values(PricingOptions);
    for (const option of pricingOptionsArray) {
      if (option === (serviceMeasure as PricingOptions)) {
        return true;
      }
    }
    return false;
  };

  const getServices = async (type: string) => {
    const bidEstimations =
      type === 'services'
        ? await CreateBidServices.allJobBidEstimation(
            location.state?.jobDetails?.jobId,
            location.state?.jobDetails?.bidId ?? ''
          )
        : await CreateBidServices.allJobBidEstimation(
            location.state?.jobDetails?.subJobId,
            location.state?.jobDetails?.subJobBidId ?? ''
          );
    bidEstimations.data = bidEstimations.data && isEmpty(bidEstimations.errors) ? bidEstimations.data : [];
    const serviceUnits = await getServiceUnits(location.state?.jobDetails?.jobId);
    const serviceCategories = await getServiceCategories(location.state?.jobDetails?.customerId);
    return bidEstimations.data.length
      ? bidEstimations.data.map((bidEstimationItem: JobBidEstimation) => {
          const selectedService: any = serviceCategories.find(
            (serviceCategoryItem: ServiceCategoryV3) =>
              serviceCategoryItem.serviceCategoryId === bidEstimationItem.mdCategoryId
          );
          return {
            service: selectedService?.serviceCategoryName ?? '',
            id: 0,
            quantity: bidEstimationItem.quantity,
            unit: serviceUnits ?? bidEstimationItem.mdUnits,
            mdServiceId: bidEstimationItem.mdCategoryId,
            numOfHours: bidEstimationItem.quantity,
            ratePerHour: bidEstimationItem.rate,
            totalJobEstimation: bidEstimationItem.totalCost,
            totalServices: 1,
            serviceMeasure: bidEstimationItem.mdMeasureType,
            estimationId: bidEstimationItem.estimationId,
          };
        })
      : location.state?.jobDetails?.jobServices.map((jobServiceItem: JobService) => {
          const selectedService: any = serviceCategories.find(
            (serviceCategoryItem: ServiceCategoryV3) =>
              serviceCategoryItem.serviceCategoryId === jobServiceItem.mdServiceId
          );
          return {
            service: selectedService?.serviceCategoryName ?? '',
            id: 0,
            quantity: 0,
            unit: serviceUnits ?? jobServiceItem.serviceMeasure,
            mdServiceId: jobServiceItem.mdServiceId,
            numOfHours: 0,
            ratePerHour: 0,
            totalJobEstimation: 0,
            totalServices: 1,
            serviceMeasure: jobServiceItem.actualServiceMeasure,
          };
        });
  };

  const getSelectedServiceCategories = async (jobId: string, customerId: string, subJobId: string) => {
    let services, subJobServices;
    if (
      location.state?.jobDetails?.executionType === ExecutionType.SubVendor &&
      location.state?.jobDetails?.estimationType === EstimationType.EstimateWithinPeazy
    ) {
      subJobServices = await getServices('subServices');
      services = await getServices('services');
      const bidData = location?.state?.jobDetails?.subJobBids?.[0];

      subJobServices = subJobServices.map((service: any) => {
        return { ...service, totalJobEstimation: bidData?.finalQuote };
      });

      setSubVendorTotalEstimation(
        subJobServices.reduce((sum: number, serviceItem: any) => {
          return sum + parseFloat(String(serviceItem.totalJobEstimation));
        }, 0)
      );
    } else {
      const jobSummaryResponse = await JobCreateApiService.getSelectedJobServices(customerId, jobId);
      const subJobSummaryResponse = subJobId
        ? await JobCreateApiService.getSelectedJobServices(customerId, subJobId)
        : null;
      const allServiceCategoryV2Response = await MasterDataApiService.getAllServiceCategoryV3(customerId);
      if (jobSummaryResponse.data) {
        services = mapServiceData(jobSummaryResponse.data, allServiceCategoryV2Response.data);
        subJobServices = subJobSummaryResponse?.data
          ? mapServiceData(subJobSummaryResponse.data, allServiceCategoryV2Response.data)
          : [];
      }
    }
    const currentJob = localStorage.getItem(AsyncStorageKeys.currentJob);

    if (currentJob && JSON.parse(currentJob)) {
      setSelectedPricingOption(
        isPricingOptionsHasValue(services[0].serviceMeasure) ? services[0].serviceMeasure : PricingOptions.Hours
      );
      localStorage.setItem(
        AsyncStorageKeys.currentJob,
        JSON.stringify({ ...JSON.parse(currentJob), jobServices: services, subJobServices: subJobServices })
      );
      setStoredJob(
        JSON.stringify({ ...JSON.parse(currentJob), jobServices: services, subJobServices: subJobServices })
      );
    }
  };

  const updateBid = async (type: BidSubmitStatus) => {
    try {
      const { customerId } = await Promise.resolve(getStoredCustomerDetails());
      const finalQuote: string = totalEstimationWithMargin
        ? String(totalEstimationWithMargin)
        : String(primeVendorTotalEstimation);
      const isFlatMargin = selectedProfitOption === ProfitOption.Percentage ? YesNoType.N : YesNoType.Y;

      const bidPayload = {
        finalQuote: parseFloat(finalQuote),
        profitMargin: profitPercentage && totalEstimation ? parseFloat(String(profitPercentage)) : 0,
        isFlatMargin: isFlatMargin,
        totalCost: primeVendorTotalEstimation,
        mdFrequencyType: MdFrequencyType.Total,
        dataStatus: BidStatus.A,
        draftStatus: DraftStatus.Y,
        draftStep: type === BidSubmitStatus.saveAndExit ? BidDraftStep.TaskEstimation : BidDraftStep.BidProposalSummary,
      };

      const updateBidPayload = { ...bidPayload };

      if (!bidDetails?.isVendorBid) {
        updateBidPayload.draftStatus = type === BidSubmitStatus.saveAndExit ? DraftStatus.Y : DraftStatus.C;
      }

      if (type === BidSubmitStatus.saveAndExit && bidDetails?.isVendorBid && bidDetails?.isRequestRevision) {
        updateBidPayload.dataStatus = BidStatus.V;
      }

      return await CreateBidServices.updateBid(customerId, bidId, updateBidPayload);
    } catch (e) {
      return { data: null, errors: [{ message: e }] };
    }
  };

  const updateSubBid = async () => {
    try {
      const { customerId } = await Promise.resolve(getStoredCustomerDetails());
      const finalQuote = String(subVendorTotalEstimation);
      const updateBidPayload: any = {
        finalQuote: parseFloat(finalQuote),
        profitMargin: 0,
        isFlatMargin: selectedProfitOption === ProfitOption.Percentage ? YesNoType.N : YesNoType.Y,
        totalCost: subVendorTotalEstimation,
        mdFrequencyType: MdFrequencyType.Total,
        dataStatus: BidStatus.A,
        draftStep: BidDraftStep.BidProposalSummary,
        draftStatus: DraftStatus.C,
      };
      return await CreateBidServices.updateBid(customerId, subJobBidId, updateBidPayload);
    } catch (e) {
      return { data: null, errors: [{ message: e }] };
    }
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const addPrimeVendorJobEstimations = async () => {
    try {
      const estimationsPayload = primeVendorEstimations?.map((serviceItem) => {
        const taskEstimation: any = {
          numberOfOccurrences: parseFloat(serviceItem?.totalServices),
          rate: serviceItem?.ratePerHour ? parseFloat(serviceItem?.ratePerHour) : 0,
          mdCategoryId: serviceItem?.mdServiceId,
          mdTaskId: 'All',
          totalCost: parseFloat(serviceItem?.totalJobEstimation) || 0,
          isVendorAdded: YesNoType.Y,
          isBillable: Billable.Y,
          mdMeasureType: selectedPricingOption ?? serviceItem?.serviceMeasure,
          mdUnits: serviceItem?.mdServiceUnits || 0,
          quantity:
            (selectedPricingOption as PricingOptions) === PricingOptions.FlatRate
              ? 1
              : serviceItem?.numOfHours
                ? parseFloat(serviceItem?.numOfHours)
                : 0,
          isOverTime: ptiType === 'NoOverTime' ? YesNoType.N : ptiType === '1.5x' ? YesNoType.O : YesNoType.D,
        };
        if (serviceItem?.estimationId) {
          taskEstimation.estimationId = serviceItem?.estimationId;
        }
        return taskEstimation;
      });
      return await CreateBidServices.addJobBidEstimation(jobId, bidId, estimationsPayload);
    } catch (e) {
      return { data: null, errors: [{ message: e }] };
    }
  };

  const addSubVendorJobEstimations = async () => {
    try {
      const estimationsPayload = subVendorEstimations?.map((serviceItem) => {
        const taskEstimation: any = {
          numberOfOccurrences: parseFloat(serviceItem?.totalServices),
          rate: parseFloat(serviceItem?.totalJobEstimation) || 0,
          mdCategoryId: serviceItem?.mdServiceId,
          mdTaskId: 'All',
          totalCost: parseFloat(serviceItem?.totalJobEstimation) || 0,
          isVendorAdded: YesNoType.Y,
          isBillable: Billable.Y,
          mdMeasureType: selectedPricingOption ?? serviceItem?.serviceMeasure,
          mdUnits: serviceItem?.mdServiceUnits || 0,
          quantity: 1, //parseFloat(serviceItem?.numOfHours),
        };
        if (serviceItem?.estimationId) {
          taskEstimation.estimationId = serviceItem?.estimationId;
        }
        return taskEstimation;
      });

      return await CreateBidServices.addJobBidEstimation(subJobId, subJobBidId, estimationsPayload);
    } catch (e) {
      return { data: null, errors: [{ message: e }] };
    }
  };

  const generateInvoice = async () => {
    try {
      const vendorDetails = await Promise.resolve(getStoredCustomerDetails());
      const currentJob = JSON.parse(localStorage.getItem(AsyncStorageKeys.currentJob) || 'null');
      const generateInvoiceInput: generateInvoiceInput = {
        vendorCompanyName: vendorDetails.organizationName,
        noteToCustomer: '',
      };
      const response = await CreateBidServices.generateInvoice(
        currentJob.jobId,
        currentJob.jobBids[0].bidId,
        generateInvoiceInput
      );
      if (response.data.responseCode !== RESPONSE_CODES.BIDS.SUCCESS) {
        setSnackbarMessage(`${t('vendor:errorOccurredWhileGeneratingInvoice')}`);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        throw new Error(`${t('vendor:errorOccurredWhileGeneratingInvoice')}`);
      }
    } catch (e) {
      return { data: null, errors: [{ message: e }] };
    }
  };

  useEffect(() => {
    if (uploadedVendorQuotes.length) {
      createVendorQuoteAttachments();
    }
  }, [uploadedVendorQuotes]);

  const getServiceUnits = async (jobId: string) => {
    const { customerId = '' } = getStoredCustomerDetails() || {};
    const jobSummaryResponse = await JobCreateApiService.jobSummaryV2(customerId, jobId);
    if (jobSummaryResponse.data && isEmpty(jobSummaryResponse.errors)) {
      return jobSummaryResponse.data.jobServices[0].actualMdServiceUnits;
    }
    return 0;
  };

  const getMultipleServiceUnits = async (jobId: string) => {
    const { customerId = '' } = getStoredCustomerDetails() || {};
    const jobSummaryResponse = await JobCreateApiService.jobSummaryV2(customerId, jobId);

    if (jobSummaryResponse.data && isEmpty(jobSummaryResponse.errors)) {
      const serviceUnitsMap: any = {};
      jobSummaryResponse.data.jobServices.forEach((service: any) => {
        serviceUnitsMap[service.mdServiceId] = service.actualMdServiceUnits;
      });
      return serviceUnitsMap;
    }
    return {};
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const getAllData = async () => {
    setIsServicesLoading(true);
    setIsSubJobServicesLoading(true);
    try {
      await getVendorStandardRatesAndPTITaxes();
      if (location?.state?.jobDetails) {
        const jobId = location?.state?.jobDetails?.jobId;
        const bidId = location?.state?.jobDetails?.bidId ?? location?.state?.jobDetails?.jobBids?.[0]?.bidId;
        const customerId = location?.state?.jobDetails?.customerId;
        const additionalExpensesResponse = await CreateBidServices.allJobBidAdditionalExpenses(jobId, bidId);

        const updatedData = additionalExpensesResponse?.data?.map(
          (item: {
            bidAdditionalExpenseId: any;
            name: any;
            mdServiceCategoryId: any;
            mdAdditionalExpenseCategory: any;
            rate: any;
          }) => {
            return {
              bidAdditionalExpenseId: item.bidAdditionalExpenseId,
              name: item.name,
              mdServiceId: item.mdServiceCategoryId,
              additionalCategoryId: item.mdAdditionalExpenseCategory,
              totalJobEstimation: item.rate,
            };
          }
        );

        setAdditionalExpenseData(updatedData);
        const totalSum = additionalExpenseData?.reduce((sum: number, serviceItem: any) => {
          return sum + Number(serviceItem.totalJobEstimation);
        }, 0);

        setAdditionalExpenseTotal(totalSum ?? 0);
        setAdditionalSubTotal(totalSum ?? 0);

        const bidEstimations = await CreateBidServices.allJobBidEstimation(jobId, bidId);
        const serviceUnitsMap = await getMultipleServiceUnits(jobId);
        const serviceCategories = await getServiceCategories(customerId);
        bidEstimations.data = bidEstimations.data ?? [];

        const jobServices =
          bidEstimations?.data?.length &&
          bidEstimations?.data?.map((bidEstimationItem: JobBidEstimation) => {
            const selectedService: any = serviceCategories.find(
              (serviceCategoryItem: ServiceCategoryV3) =>
                serviceCategoryItem.serviceCategoryId === bidEstimationItem.mdCategoryId
            );
            return {
              service: selectedService?.serviceCategoryName ?? '',
              id: 0,
              quantity: bidEstimationItem.quantity,
              unit: bidEstimationItem.mdUnits ?? serviceUnitsMap[bidEstimationItem.mdCategoryId],
              mdServiceId: bidEstimationItem.mdCategoryId,
              numOfHours: bidEstimationItem.quantity,
              ratePerHour: bidEstimationItem.rate,
              totalJobEstimation: bidEstimationItem.totalCost,
              totalServices: 1,
              serviceMeasure: bidEstimationItem.mdMeasureType,
              estimationId: bidEstimationItem.estimationId,
            };
          });

        let subJobServices;
        if (
          location?.state?.jobDetails?.subJobId &&
          (location?.state?.jobDetails?.subJobBidId ?? location?.state?.jobDetails.subJobBids?.[0].bidId)
        ) {
          const subBidEstimations = await CreateBidServices.allJobBidEstimation(
            location?.state?.jobDetails.subJobId,
            location?.state?.jobDetails?.subJobBidId || location?.state?.jobDetails?.subJobBids?.[0]?.bidId
          );
          subBidEstimations.data = subBidEstimations.data ?? [];

          subJobServices =
            subBidEstimations?.data?.length &&
            subBidEstimations?.data?.map((bidEstimationItem: JobBidEstimation) => {
              const selectedService: any = serviceCategories.find(
                (serviceCategoryItem: ServiceCategoryV3) =>
                  serviceCategoryItem.serviceCategoryId === bidEstimationItem.mdCategoryId
              );
              return {
                service: selectedService?.serviceCategoryName ?? location?.state?.jobDetails?.jobServices?.[0]?.name,
                id: 0,
                quantity: bidEstimationItem.quantity,
                unit: bidEstimationItem.mdUnits,
                mdServiceId: bidEstimationItem.mdCategoryId,
                numOfHours: bidEstimationItem.quantity,
                ratePerHour: bidEstimationItem.rate,
                totalJobEstimation: bidEstimationItem.totalCost,
                totalServices: 1,
                serviceMeasure: bidEstimationItem.mdMeasureType,
                estimationId: bidEstimationItem.estimationId,
              };
            });
        } else if (
          location?.state?.jobDetails.subJobId &&
          !(location?.state?.jobDetails.subJobBidId ?? location?.state?.jobDetails.subJobBids?.[0].bidId)
        ) {
          subJobServices = location?.state?.jobDetails?.subJobServices;
        }
        // if (subJobId) {
        //   const bidSummaryResponse = await VendorApiService.getBidSummary(subJobId);
        // }
        if (
          location.state.jobDetails.executionType === ExecutionType.SubVendor &&
          location.state.jobDetails.estimationType === EstimationType.EstimateOutsidePeazy
        ) {
          const jobData = {
            ...location?.state?.jobDetails,
          };
          jobData.jobServices = jobServices;
          jobData.subJobServices = subJobServices;
          setStoredJob(JSON.stringify({ ...jobData }));

          localStorage.setItem(AsyncStorageKeys.currentJob, JSON.stringify({ ...jobData }));
          // setSubVendorTotalEstimation(
          //   subJobServices.reduce((sum: number, serviceItem: any) => {
          //     return sum + parseFloat(String(serviceItem.totalJobEstimation));
          //   }, 0)
          // );
        }
        if (location?.state?.jobDetails?.jobStatus !== 'Pending Estimation') {
          const jobData = {
            ...location?.state?.jobDetails,
            additionalExpenseData: updatedData,
            jobServices: jobServices,
          };
          if (
            location.state.jobDetails.executionType === ExecutionType.SubVendor &&
            location.state.jobDetails.estimationType === EstimationType.EstimateWithinPeazy &&
            !location.state.jobDetails.intId &&
            location.state.jobDetails.jobStatus !== JobStatus.RevisionRequested
          ) {
            jobData.subJobServices = [];
          } else if (
            location.state.jobDetails.executionType === ExecutionType.SubVendor &&
            location.state.jobDetails.estimationType === EstimationType.EstimateWithinPeazy &&
            (location.state.jobDetails.intId || location.state.jobDetails.jobStatus === JobStatus.RevisionRequested)
          ) {
            setStoredJob(JSON.stringify({ ...jobData, subJobServices: [] }));
            localStorage.setItem(AsyncStorageKeys.currentJob, JSON.stringify({ ...jobData, subJobServices: [] }));
            const subJobFinalQuote = await getJobSummaryResponse(
              location.state.jobDetails.jobId,
              location.state.jobDetails.subJobBidId ?? location.state.jobDetails.subJobEstimations[0].bidId
            );
            if (subJobFinalQuote) {
              jobData.subJobServices = jobData.subJobServices.map((service: any) => {
                return {
                  ...service,
                  totalJobEstimation: subJobFinalQuote,
                };
              });
              setSubVendorTotalEstimation(subJobFinalQuote);
            }
          }
          if (
            location.state.jobDetails.executionType === ExecutionType.SubVendor &&
            location.state.jobDetails.estimationType === EstimationType.EstimateOutsidePeazy
          ) {
            jobData.subJobServices = subJobServices;
          }

          setStoredJob(JSON.stringify({ ...jobData }));

          localStorage.setItem(AsyncStorageKeys.currentJob, JSON.stringify({ ...jobData }));
        }
      } else {
        const parsedJob = storedJob ? JSON.parse(storedJob) : {};
        if (parsedJob && storedJob) {
          setIsSubJobServicesLoading(true);

          const bidId = parsedJob?.bidId ?? parsedJob?.jobBids?.[0]?.bidId;
          const jobId = parsedJob?.jobId;

          const additionalExpensesResponse = await CreateBidServices.allJobBidAdditionalExpenses(jobId, bidId);

          const updatedData = additionalExpensesResponse?.data?.map(
            (item: {
              bidAdditionalExpenseId: any;
              name: any;
              mdServiceCategoryId: any;
              mdAdditionalExpenseCategory: any;
              rate: any;
            }) => {
              return {
                bidAdditionalExpenseId: item.bidAdditionalExpenseId,
                name: item.name,
                mdServiceId: item.mdServiceCategoryId,
                additionalCategoryId: item.mdAdditionalExpenseCategory,
                totalJobEstimation: item.rate,
              };
            }
          );

          setAdditionalExpenseData(updatedData);
          const totalSum = additionalExpenseData?.reduce((sum: number, serviceItem: any) => {
            return sum + Number(serviceItem.totalJobEstimation);
          }, 0);

          setAdditionalExpenseTotal(totalSum ?? 0);
          setAdditionalSubTotal(totalSum ?? 0);
          await getVendorStandardRatesAndPTITaxes();
          const bidEstimations = await CreateBidServices.allJobBidEstimation(jobId, bidId);
          const serviceUnits = await getServiceUnits(jobId);
          const serviceCategories = await getServiceCategories(parsedJob?.customerId);
          bidEstimations.data = bidEstimations.data ?? [];

          const jobServices =
            bidEstimations?.data?.length &&
            bidEstimations?.data?.map((bidEstimationItem: JobBidEstimation) => {
              const selectedService: any = serviceCategories.find(
                (serviceCategoryItem: ServiceCategoryV3) =>
                  serviceCategoryItem.serviceCategoryId === bidEstimationItem.mdCategoryId
              );
              return {
                service: selectedService?.serviceCategoryName ?? parsedJob?.jobServices[0]?.service ?? '',
                id: 0,
                quantity: bidEstimationItem.quantity,
                unit: serviceUnits ?? bidEstimationItem.mdUnits,
                mdServiceId: bidEstimationItem.mdCategoryId,
                numOfHours: bidEstimationItem.quantity,
                ratePerHour: bidEstimationItem.rate,
                totalJobEstimation: bidEstimationItem.totalCost,
                totalServices: 1,
                serviceMeasure: bidEstimationItem.mdMeasureType,
                estimationId: bidEstimationItem.estimationId,
              };
            });
          let subJobServices;
          if (parsedJob.subJobId && (parsedJob.subJobBidId ?? parsedJob.subJobBids?.[0].bidId)) {
            const subBidEstimations = await CreateBidServices.allJobBidEstimation(
              parsedJob.subJobId,
              parsedJob.subJobBidId ?? parsedJob.subJobBids?.[0].bidId
            );
            subBidEstimations.data = subBidEstimations.data ?? [];

            subJobServices =
              subBidEstimations?.data?.length &&
              subBidEstimations?.data?.map((bidEstimationItem: JobBidEstimation) => {
                const selectedService: any = serviceCategories.find(
                  (serviceCategoryItem: ServiceCategoryV3) =>
                    serviceCategoryItem.serviceCategoryId === bidEstimationItem.mdCategoryId
                );
                return {
                  service: selectedService?.serviceCategoryName ?? parsedJob?.jobServices?.[0]?.name,
                  id: 0,
                  quantity: bidEstimationItem?.quantity,
                  unit: serviceUnits ?? bidEstimationItem?.mdUnits,
                  mdServiceId: bidEstimationItem.mdCategoryId,
                  numOfHours: bidEstimationItem.quantity,
                  ratePerHour: bidEstimationItem.rate,
                  totalJobEstimation: bidEstimationItem.totalCost,
                  totalServices: 1,
                  serviceMeasure: bidEstimationItem.mdMeasureType,
                  estimationId: bidEstimationItem.estimationId,
                };
              });
          } else if (parsedJob.subJobId && !(parsedJob.subJobBidId ?? parsedJob.subJobBids?.[0].bidId)) {
            subJobServices = parsedJob.jobServices.map((serviceItem: any) => {
              return {
                ...serviceItem,
                totalJobEstimation: 0,
              };
            });
          }

          const jobData = {
            ...JSON.parse(storedJob),
            additionalExpenseData: updatedData,
            jobServices: jobServices,
          };
          if (subJobServices && subJobServices.length) {
            jobData.subJobServices = subJobServices;
          }
          setStoredJob(
            JSON.stringify({
              ...jobData,
            })
          );

          localStorage.setItem(
            AsyncStorageKeys.currentJob,
            JSON.stringify({
              ...jobData,
            })
          );
          setIsSubJobServicesLoading(false);
        }
      }
    } catch (e) {
      console.log('error', e);
    }
    setIsSubJobServicesLoading(false);
    setIsServicesLoading(false);
  };

  const getJobSummaryResponse = async (jobId: string, subJobBidId: string) => {
    const { customerId = '' } = getStoredCustomerDetails() || {};
    const jobSummaryResponse = await JobCreateApiService.jobSummaryV2(customerId, jobId);
    if (jobSummaryResponse.data && isEmpty(jobSummaryResponse.errors)) {
      const subBidData = jobSummaryResponse.data.subJobs[0].jobBids.find(
        (bidData: any) => bidData.bidId === subJobBidId
      );
      return subBidData?.finalQuote;
    }
    return 0;
  };

  const [bidData, setBidData] = useState({ dataStatus: undefined });
  const getBidData = async () => {
    const bidId =
      location?.state?.jobDetails?.executionType === ExecutionType.SubVendor &&
      location?.state?.jobDetails?.estimationType === EstimationType.EstimateWithinPeazy &&
      location.state.tag === TagNames.RejectOrRequestRevision
        ? location?.state?.jobDetails?.subJobBidId
        : location?.state?.jobDetails?.bidId;
    const bidSummaryResponse = await VendorApiService.getBidSummary(bidId);
    setBidData(bidSummaryResponse.data);
  };

  useEffect(() => {
    const jobDetails = location?.state?.jobDetails;
    const isSubVendorEstimate =
      jobDetails?.executionType === ExecutionType.SubVendor &&
      jobDetails?.estimationType === EstimationType.EstimateWithinPeazy;
    const isRejectOrRequestRevision = location?.state?.tag === TagNames.RejectOrRequestRevision;

    if (isSubVendorEstimate && isRejectOrRequestRevision) {
      getBidData();
    } else if (role !== UserType.Customer) {
      getAllData();
    } else {
      getBidData();
    }
  }, []);

  const getVendorQuoteAttachmnets = async (bidId: string) => {
    try {
      const vendorQuoteAttachments = await CreateBidServices.allBidAttachmentsWithLatestVersion({
        bidIds: [bidId],
        attachmentsBasedOnVersionsIndicator: BooleanType.False,
      });
      const modifiedVendorQuoteAttachments =
        vendorQuoteAttachments?.data?.filter(
          (item: IAllBidAttachments) => item.mdFileContext !== 'Quote' && item.mdFileContext !== 'Invoice'
        ) ?? [];
      setQuoteAttachments(modifiedVendorQuoteAttachments);
    } catch (e) {
      console.log('vendorQuoteAttachments error: ', e);
    }
  };

  const createVendorQuoteAttachments = async () => {
    try {
      setIsQuoteUploading(true);
      let savedS3BucketKeys = await storeAttachmentsToS3Cloud(uploadedVendorQuotes[0]);
      const quote: any = uploadedVendorQuotes?.[0];
      if (savedS3BucketKeys && quote) {
        savedS3BucketKeys = `${savedS3BucketKeys}`; // Foe vendor quote we need to use sub bid version.

        const createBidAttachmentPayload: CreateBidAttachmentInput = {
          mdAttachmentType: quote?.mdAttachmentType ?? '',
          fileName: quote?.fileName + '.' + quote?.fileExtension,
          fileExtension: quote?.fileExtension,
          mdFileContext: FileContextType.VendorQuote,
          url: savedS3BucketKeys,
        };

        const createVendorQuoteAttachmentResponse = await CreateBidServices.createBidAttachments(subJobBidId, [
          createBidAttachmentPayload,
        ]);
        if (createVendorQuoteAttachmentResponse.errors.length !== 0) {
          setIsQuoteUploading(false);
          setSnackbarMessage(`An error occurred while uploading the files.`);
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          setUploadedVendorQuotes([]);
        } else {
          getVendorQuoteAttachmnets(subJobBidId);
          setIsQuoteUploading(false);
          setSnackbarMessage(`Selected file uploaded successfully!`);
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
          setUploadedVendorQuotes([]);
        }
      } else {
        setIsQuoteUploading(false);
        setSnackbarMessage(`An error occurred while uploading the files. Please retry it`);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        setUploadedVendorQuotes([]);
      }
    } catch (error) {
      setIsQuoteUploading(false);
      console.error('createVendorQuoteAttachmentResponse error: ', error);
    }
  };

  const storeAttachmentsToS3Cloud = async (attachment: any = {}) => {
    try {
      const customerDetails = localStorage.getItem('customerDetails');
      const vendorDetails = customerDetails ? JSON.parse(customerDetails).organization : {};
      const uploadDocumentResponse = await uploadDocumentToS3Bucket(
        navigate,
        `vendor/${vendorDetails.organizationId}/bid-attachments/${subJobBidId}/version${currentBidVersion ?? 0}/${attachment.id}.${attachment.fileExtension}`,
        attachment?.url ?? '',
        {
          level: 'public',
        },
        attachment.fileExtension
      );

      return uploadDocumentResponse?.file?.key;
    } catch (error: any) {
      return '';
    }
  };

  const handleDeleteQuoteAttachment = async (attachmentId: string) => {
    try {
      const deleteAttachmentResponse = await CreateBidServices.deleteBidAttachments(subJobBidId, [attachmentId]);
      if (deleteAttachmentResponse.errors.length !== 0) {
        setSnackbarMessage(`An error occurred while deleting the file.`);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      } else {
        getVendorQuoteAttachmnets(subJobBidId);
        setSnackbarMessage(`File deleted successfully!`);
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const addAdditionalExpensesEstimation = async () => {
    try {
      const expensePayload: CreateJobBidAdditionalExpensesInput[] = additionalExpenseData.map((expenseItem) => {
        const additionalExpense: any = {
          mdAdditionalExpenseCategory: expenseItem?.additionalCategoryId,
          mdServiceCategoryId: '', //expenseItem?.additionalCategoryId,
          name: expenseItem?.name,
          rate: expenseItem?.totalJobEstimation ? parseFloat(expenseItem?.totalJobEstimation) : 0,
          quantity: 0,
          totalCost: 0,
          mdUnits: '',
          draftStatus: DraftStatus.Y,
        };
        if (expenseItem?.bidAdditionalExpenseId) {
          additionalExpense.bidAdditionalExpenseId = expenseItem?.bidAdditionalExpenseId;
        }
        return additionalExpense;
      });
      return await CreateBidServices.addJobBidAdditionalExpenses(jobId, bidId, expensePayload);
    } catch (e) {
      return { data: null, errors: [{ message: e }] };
    }
  };

  const handleExpensesError = () => {
    setSnackbarMessage(`Please select the required expense`);
    setSnackbarSeverity('error');
    setSnackbarOpen(true);
  };

  /* eslint-disable sonarjs/cognitive-complexity */

  const fetchAllServicesLinkedToBid = useCallback(async (jobId: string, bidId: string) => {
    setIsServicesLoading(true);

    const allServicesResponse = await CreateBidServices.allJobBidEstimation(jobId, bidId);
    const serviceUnits = await getServiceUnits(jobId);

    const calculateUniqueEstimations = () => {
      const uniqueEstimationsMap: Record<string, { totalCost: number; maxMdMeasureType: number; maxQuantity: number }> =
        {};

      allServicesResponse?.data?.forEach(
        (estimation: { mdCategoryId: any; totalCost: number; mdMeasureType: number; quantity: number }) => {
          const mdCategoryId = estimation.mdCategoryId;
          const totalCost = estimation.totalCost || 0;
          const mdMeasureType = estimation.mdMeasureType || 0;
          const quantity = estimation.quantity || 0;

          if (!uniqueEstimationsMap[mdCategoryId]) {
            uniqueEstimationsMap[mdCategoryId] = {
              totalCost,
              maxMdMeasureType: mdMeasureType,
              maxQuantity: quantity,
            };
          } else {
            uniqueEstimationsMap[mdCategoryId].totalCost += totalCost;

            if (mdMeasureType > uniqueEstimationsMap[mdCategoryId].maxMdMeasureType) {
              uniqueEstimationsMap[mdCategoryId].maxMdMeasureType = mdMeasureType;
            }

            if (quantity > uniqueEstimationsMap[mdCategoryId].maxQuantity) {
              uniqueEstimationsMap[mdCategoryId].maxQuantity = quantity;
            }
          }
        }
      );

      const uniqueEstimationsArray: Estimation[] = Object.keys(uniqueEstimationsMap).map((mdCategoryId) => ({
        mdCategoryId,
        totalCost: uniqueEstimationsMap[mdCategoryId].totalCost,
        mdMeasureType: uniqueEstimationsMap[mdCategoryId].maxMdMeasureType,
        quantity: uniqueEstimationsMap[mdCategoryId].maxQuantity,
      }));
      return uniqueEstimationsArray;
    };

    const response = calculateUniqueEstimations();

    const serviceCategories = await getServiceCategories(location.state?.jobDetails?.customerId);
    const jobServices: JobServices[] = (response || []).map((bidEstimationItem: any, index: number) => {
      const selectedService: any = serviceCategories.find(
        (serviceCategoryItem: ServiceCategoryV3) =>
          serviceCategoryItem?.serviceCategoryId === bidEstimationItem?.mdCategoryId
      );
      return {
        service: selectedService?.serviceCategoryName ?? '',
        id: index + 1,
        quantity: bidEstimationItem?.quantity,
        unit: serviceUnits ?? bidEstimationItem?.mdUnits,
        mdServiceId: bidEstimationItem?.mdCategoryId,
        numOfHours: bidEstimationItem?.quantity,
        ratePerHour: bidEstimationItem?.rate,
        totalJobEstimation: bidEstimationItem?.totalCost,
        totalServices: 1,
        serviceMeasure: bidEstimationItem?.mdMeasureType,
      };
    });
    const jobEstimations =
      role == UserType.Customer
        ? location?.state?.jobDetails?.jobEstimations
        : location?.state?.jobDetails?.subJobEstimations;

    const updatedJobServices = jobServices.map((service) => {
      const matchedEstimation = jobEstimations.find(
        (estimation: { mdCategoryId: any }) => estimation.mdCategoryId === service.mdServiceId
      );
      if (matchedEstimation) {
        return {
          ...service,
          totalJobEstimation:
            role == UserType.Customer
              ? matchedEstimation.quoteAmount
              : location?.state?.jobDetails?.subJobBids[0].finalQuote,
        };
      }
      return service;
    });

    setSelectedPricingOption(
      isPricingOptionsHasValue(jobServices?.[0]?.serviceMeasure)
        ? jobServices?.[0]?.serviceMeasure
        : PricingOptions.Hours
    );
    setServices(updatedJobServices);
    setIsServicesLoading(false);
  }, []);

  const getVendorStandardRatesAndPTITaxes = async () => {
    setIsServicesLoading(true);
    const authValue = localStorage.getItem('auth');
    let userId: string = '';
    if (authValue !== null) {
      const authData = JSON.parse(authValue);
      userId = authData?.username;
    }
    const { customerId } = await Promise.resolve(getStoredCustomerDetails());
    const recommendedJobs = (await JobCreateApiService.getRegionalJobs(customerId, userId)) as RecommendedJobsResponse;
    let facilityId = '';
    if (storedJob) {
      const parsedJob = JSON.parse(storedJob);
      facilityId = parsedJob?.facilityId ? parsedJob?.facilityId : parsedJob?.jobFacilities?.[0]?.facilityId;
    } else {
      facilityId = location?.state?.jobDetails?.facilityId
        ? location?.state?.jobDetails?.facilityId
        : location?.state?.jobDetails?.jobFacilities?.[0]?.facilityId;
    }
    const facility = recommendedJobs?.data.find((job) => job?.facilityId === facilityId);
    setUnionIndicator(facility?.unionIndicator);
    const response = await CreateBidServices.getStandardRatesAndPtiTaxes({
      keyId: customerId,
      stateCode: facility?.stateCode,
    });
    if (response?.errors?.length === 0 && response?.data) {
      setVendorRatesAndTaxes(response?.data);
    }
    setIsServicesLoading(false);
  };

  // const getOverTimeType = () => {
  //   const isOverTime = location.state?.jobDetails.jobEstimations[0].isOverTime;
  //   const overTimeType = isOverTime === YesNoType.N ? 'NoOverTime' : isOverTime === YesNoType.O ? '1.5x' : 'DoublePay';
  //   setPtiType(overTimeType);
  // };

  const fetchAllPricingOptions = async () => {
    try {
      const pricingOptionsList = await MasterDataApiService.getAllPricingOptions();
      const list = pricingOptionsList.data.map((pricingOption: any) => {
        return {
          ...pricingOption,
          label: pricingOption.subKey1Value,
          id: pricingOption.keyCode,
        };
      });
      setPricingOptionsList([...list]);
      setSelectedPricingOption(
        list.find((option: any) => option.value === PricingOptions.Hours).value ?? list[0].value
      );
    } catch (e) {
      console.error('Errors', e);
      setPricingOptionsList([{ value: 'Hours', label: 'Hours', id: 1 }]);
    }
  };

  const fetchData = async () => {
    setIsBidVersionLoader(true);
    setIsFinalQuoteLoading(true);

    const mainBidId =
      (storedJob && (JSON.parse(storedJob)?.bidId || JSON.parse(storedJob)?.jobBids?.[0]?.bidId)) ||
      location?.state?.jobDetails?.bidId ||
      location.state?.jobDetails?.jobBids?.[0]?.bidId;

    if (!isEmpty(mainBidId)) {
      const bidSummaryResponse = await VendorApiService.getBidSummary(mainBidId);
      setProfitPercentage(
        isNaN(parseFloat(bidSummaryResponse?.data?.profitMargin?.toFixed(2)))
          ? 0
          : parseFloat(bidSummaryResponse?.data?.profitMargin?.toFixed(2))
      );
      setSelectedProfitOption(
        bidSummaryResponse?.data?.isFlatMargin === 'Y' ? ProfitOption.Dollar : ProfitOption.Percentage
      );
      if (bidSummaryResponse?.data && bidSummaryResponse?.data?.bidSimpleEstimation?.length) {
        setPtiType(
          bidSummaryResponse?.data?.bidSimpleEstimation[0]?.isOverTime === YesNoType.O ? '1.5x' : 'NoOverTime'
        );
      }
    }
    let msubJobBidId = '';
    let mBidId = '';
    if (location.state?.jobDetails) {
      msubJobBidId =
        location.state?.jobDetails?.subJobBidId || location.state?.jobDetails?.subJobBids?.[0]?.bidId || subJobBidId;

      const currentJob = localStorage.getItem(AsyncStorageKeys.currentJob) ?? '';
      if (!currentJob) {
        localStorage.setItem(AsyncStorageKeys.currentJob, JSON.stringify(location.state?.jobDetails));
      }
      setStoredJob(JSON.stringify(location.state?.jobDetails));

      const mJobId = location?.state?.jobDetails?.jobId;
      mBidId = bidId
        ? bidId
        : location?.state?.jobDetails?.bidId
          ? location?.state?.jobDetails?.bidId
          : location?.state?.jobDetails?.jobBids?.[0]?.bidId;

      const mCustomerId = location?.state?.jobDetails?.customerId;

      const bidEstimations = await CreateBidServices.allJobBidEstimation(mJobId, mBidId);
      const serviceUnitsMap = await getMultipleServiceUnits(mJobId);

      const serviceCategories = await getServiceCategories(mCustomerId);
      bidEstimations.data = bidEstimations.data ?? [];

      if (bidEstimations.data && !isEmpty(bidEstimations.data)) {
        const jobServices =
          bidEstimations?.data?.length &&
          bidEstimations?.data?.map((bidEstimationItem: JobBidEstimation) => {
            const selectedService: any = serviceCategories.find(
              (serviceCategoryItem: ServiceCategoryV3) =>
                serviceCategoryItem.serviceCategoryId === bidEstimationItem.mdCategoryId
            );
            return {
              service: selectedService?.serviceCategoryName ?? '',
              id: 0,
              quantity: bidEstimationItem.quantity,
              unit: bidEstimationItem.mdUnits ?? serviceUnitsMap[bidEstimationItem.mdCategoryId],
              mdServiceId: bidEstimationItem.mdCategoryId,
              numOfHours: bidEstimationItem.quantity,
              ratePerHour: bidEstimationItem.rate,
              totalJobEstimation: bidEstimationItem.totalCost,
              totalServices: 1,
              serviceMeasure: bidEstimationItem.mdMeasureType,
              estimationId: bidEstimationItem.estimationId,
            };
          });

        setSelectedPricingOption(
          isPricingOptionsHasValue(jobServices[0].serviceMeasure) ? jobServices[0].serviceMeasure : PricingOptions.Hours
        );
        setStoredJob(JSON.stringify({ ...location.state?.jobDetails, jobServices: jobServices }));

        setPrimeVendorTotalEstimation(
          jobServices?.reduce((sum: number, expenseItem: any) => {
            return sum + parseFloat(expenseItem.totalJobEstimation);
          }, 0)
        );
      }
      getFacilityDetails(location.state?.jobDetails.customerId, location.state?.jobDetails.facilityId);
      // if (currentJob) {
      //   const parsedCurrentJob = JSON.parse(currentJob);
      //   setPrimeVendorTotalEstimation(
      //     parsedCurrentJob?.jobServices?.reduce((sum: number, expenseItem: any) => {
      //       return sum + Number(expenseItem.totalJobEstimation);
      //     }, 0)
      //   );
      // } else {
      //   setPrimeVendorTotalEstimation(
      //     location.state?.jobDetails?.jobServices?.reduce((sum: number, expenseItem: any) => {
      //       return sum + Number(expenseItem.totalJobEstimation);
      //     }, 0)
      //   );
      // }
      if (location.state.finalQuote) {
        setFinalQuote(location?.state?.finalQuote);
        setIsFinalQuoteLoading(false);
      } else {
        setIsFinalQuoteLoading(false);
      }
      if (location.state?.jobDetails.additionalExpenseData && location.state?.jobDetails.additionalExpenseData.length) {
        const expenseTotal = location.state?.jobDetails.additionalExpenseData.reduce(
          (sum: number, expenseItem: any) => {
            return sum + Number(expenseItem.totalJobEstimation);
          },
          0
        );
        setAdditionalSubTotal(expenseTotal ?? 0);
        setAdditionalExpenseTotal(expenseTotal ?? 0);
      }
      if (location.state?.jobDetails?.jobBids?.length === 0) {
        createBid(location.state?.jobDetails);
      } else {
        if (
          location.state?.jobDetails?.executionType === ExecutionType.SubVendor &&
          location.state?.jobDetails?.estimationType === EstimationType.EstimateWithinPeazy &&
          location.state?.jobDetails?.jobStatus !== JobStatus.RevisionRequested &&
          !location.state?.jobDetails?.intId
        ) {
          setBidId(location.state?.jobDetails?.bidId ?? location.state?.jobDetails?.bidSummaryResponse?.bidId);
          setSubJobBidId(location.state?.jobDetails?.subJobBidId);
          localStorage.setItem(
            AsyncStorageKeys.currentJob,
            JSON.stringify({
              ...location.state?.jobDetails,
              subJobServices: [],
            })
          );
          setStoredJob(
            JSON.stringify({
              ...location.state?.jobDetails,
              subJobServices: [],
            })
          );

          setPrimeVendorTotalEstimation(0);
          getSelectedServiceCategories(
            location.state.jobId,
            location.state.customerId,
            location.state?.jobDetails.subJobId
          );
        } else {
          if (role == UserType.Vendor) {
            setBidId(location.state?.jobDetails?.jobBids?.[0]?.bidId);
          } else {
            setBidId(location?.state?.jobDetails?.bidId);
            // const currentJob = localStorage.getItem('currentJob') ?? '';
            // if (currentJob) {
            //   const storedJob = JSON.parse(currentJob);
            // }
            // if (location?.state?.jobDetails.jobEstimations?.length) getOverTimeType();
          }
        }
      }
      if (location.state?.jobDetails?.subJobBids && location.state?.jobDetails?.subJobBids?.length !== 0) {
        setSubJobBidId(location.state?.jobDetails?.subJobBids[0]?.bidId);
        getVendorQuoteAttachmnets(location.state?.jobDetails?.subJobBids[0]?.bidId);
        if (
          location?.state?.jobDetails?.subJobs?.length &&
          location.state?.jobDetails?.executionType === ExecutionType.SubVendor &&
          location.state?.jobDetails?.estimationType === EstimationType.EstimateOutsidePeazy &&
          location.state?.jobDetails?.subJobBids[0]?.finalQuote
        ) {
          setSubVendorTotalEstimation(location.state?.jobDetails?.subJobBids[0]?.finalQuote);
        }
      }
      const { jobId } = location.state;
      const subJobId = location.state?.jobDetails.subJobId;
      setJobId(jobId);
      setSubJobId(subJobId);
      // getSelectedServiceCategories(jobId, customerId, subJobId);
      getAdditionalExpensesMasterData();
    } else {
      const parsedJob = storedJob ? JSON.parse(storedJob) : {};
      if (parsedJob && storedJob) {
        msubJobBidId = subJobBidId || parsedJob?.subJobBids?.[0]?.bidId;

        setAdditionalSubTotal(parsedJob.additionalSubTotal ?? 0);
        if (!parsedJob?.jobBids?.length && !parsedJob.bidId) {
          const { customerId = '' } = getStoredCustomerDetails() || {};

          const jobBidSummaryResponse = await JobCreateApiService.jobBidResponse(parsedJob?.jobId, customerId);

          if (
            jobBidSummaryResponse?.data &&
            isEmpty(jobBidSummaryResponse?.errors) &&
            jobBidSummaryResponse?.data?.[0]?.bidId
          ) {
            setBidId(jobBidSummaryResponse?.data?.[0]?.bidId);
            const jobSummaryResponse = await JobCreateApiService.jobSummaryV2(customerId, parsedJob?.jobId);

            if (
              jobSummaryResponse?.data?.subJobs?.length &&
              jobSummaryResponse?.data?.subJobs?.[0]?.jobBids?.[0]?.bidId
            ) {
              setSubJobBidId(jobSummaryResponse?.data?.subJobs?.[0]?.jobBids?.[0]?.bidId);
              getVendorQuoteAttachmnets(jobSummaryResponse?.data?.subJobs?.[0]?.jobBids?.[0]?.bidId);
            }
            if (!parsedJob?.jobServices && jobSummaryResponse?.data?.jobServices?.length) {
              const serviceCategories = await getServiceCategories(jobSummaryResponse?.data?.customerId);

              const newJobServices = jobSummaryResponse?.data?.jobServices?.map((jobServiceItem: JobService) => {
                const selectedService: any = serviceCategories?.find(
                  (serviceCategoryItem: ServiceCategoryV3) =>
                    serviceCategoryItem.serviceCategoryId === jobServiceItem.mdServiceId
                );
                return {
                  service: selectedService?.serviceCategoryName ?? '',
                  id: 0,
                  quantity: 0,
                  unit: jobServiceItem.mdServiceUnits,
                  mdServiceId: jobServiceItem.mdServiceId,
                  numOfHours: 0,
                  ratePerHour: 0,
                  totalJobEstimation: 0,
                  totalServices: 1,
                  serviceMeasure: jobServiceItem.serviceMeasure,
                };
              });
              localStorage.setItem(
                AsyncStorageKeys.currentJob,
                JSON.stringify({ ...parsedJob, jobServices: newJobServices })
              );

              setStoredJob(JSON.stringify({ ...parsedJob, jobServices: newJobServices }));
            }
          } else {
            createBid(parsedJob);
          }
        } else {
          setBidId(parsedJob?.bidId ?? parsedJob?.jobBids?.[0]?.bidId);
          setIsServicesLoading(true);
          try {
            const defaultJobServices = parsedJob.jobServices;
            localStorage.setItem(AsyncStorageKeys.currentJob, JSON.stringify({ ...parsedJob, jobServices: [] }));
            setStoredJob(JSON.stringify({ ...parsedJob, jobServices: [] }));
            const bidEstimations = await CreateBidServices.allJobBidEstimation(
              parsedJob?.jobId,
              parsedJob?.bidId ?? parsedJob?.jobBids?.[0]?.bidId
            );
            const serviceUnits = await getServiceUnits(parsedJob.jobId);
            const serviceCategories = await getServiceCategories(parsedJob.customerId);
            if (bidEstimations.data && isEmpty(bidEstimations.errors)) {
              const services = bidEstimations.data.map((bidEstimationItem: JobBidEstimation) => {
                setPtiType(bidEstimationItem.isOverTime === YesNoType.O ? '1.5x' : 'NoOverTime');
                const selectedService: any = serviceCategories?.find(
                  (serviceCategoryItem: ServiceCategoryV3) =>
                    serviceCategoryItem.serviceCategoryId === bidEstimationItem.mdCategoryId
                );
                return {
                  service: selectedService?.serviceCategoryName ?? defaultJobServices[0].name,
                  id: 0,
                  quantity: bidEstimationItem.quantity,
                  unit: serviceUnits ?? bidEstimationItem.mdUnits,
                  mdServiceId: bidEstimationItem.mdCategoryId,
                  numOfHours: bidEstimationItem.quantity,
                  ratePerHour: bidEstimationItem.rate,
                  totalJobEstimation: bidEstimationItem.totalCost,
                  totalServices: 1,
                  serviceMeasure: bidEstimationItem.mdMeasureType,
                  estimationId: bidEstimationItem.estimationId,
                };
              });
              setSelectedPricingOption(
                isPricingOptionsHasValue(services[0].serviceMeasure) ? services[0].serviceMeasure : PricingOptions.Hours
              );
              localStorage.setItem(
                AsyncStorageKeys.currentJob,
                JSON.stringify({ ...parsedJob, jobServices: services })
              );
              setStoredJob(JSON.stringify({ ...parsedJob, jobServices: services }));
            } else {
              setSelectedPricingOption(
                isPricingOptionsHasValue(defaultJobServices?.[0]?.serviceMeasure)
                  ? defaultJobServices?.[0]?.serviceMeasure
                  : PricingOptions.Hours
              );
              localStorage.setItem(
                AsyncStorageKeys.currentJob,
                JSON.stringify({ ...parsedJob, jobServices: defaultJobServices })
              );
              setStoredJob(JSON.stringify({ ...parsedJob, jobServices: defaultJobServices }));
            }
          } catch (error) {
            console.log('parsed job error: ', error);
          }
          setIsServicesLoading(false);
        }
        if (parsedJob?.subJobBids?.length || parsedJob.subJobBidId) {
          setSubJobBidId(parsedJob.subJobBidId ?? parsedJob.subJobBids[0]?.bidId);
          getVendorQuoteAttachmnets(parsedJob.subJobBidId ?? parsedJob.subJobBids[0]?.bidId);
        }
        const { jobId, subJobId } = parsedJob;
        setJobId(jobId);
        setSubJobId(subJobId);
        // getSelectedServiceCategories(jobId, customerId, subJobId);
        getAdditionalExpensesMasterData();
      }
    }
    const bidSummaryResponse = await VendorApiService.getBidSummary(msubJobBidId);
    setCurrentBidVersion(bidSummaryResponse?.data?.bidVersion ?? 0);
    if (role == UserType.Vendor) {
      const currentJob = localStorage.getItem(AsyncStorageKeys.currentJob) ?? '';
      if (currentJob) {
        const storedJob = JSON.parse(currentJob);
        if (storedJob?.jobServices) {
          setPrimeVendorTotalEstimation(
            storedJob?.jobServices?.reduce((sum: number, expenseItem: any) => {
              return sum + parseFloat(expenseItem.totalJobEstimation);
            }, 0)
          );
        }
      } else {
        setPrimeVendorTotalEstimation(location?.state?.jobDetails?.jobServices[0]?.totalJobEstimation);
      }
      getVendorStandardRatesAndPTITaxes();

      if (
        location?.state?.jobDetails?.subJobs?.length &&
        location.state?.jobDetails?.executionType === ExecutionType.SubVendor &&
        location.state?.jobDetails?.estimationType === EstimationType.EstimateOutsidePeazy &&
        (location?.state?.jobDetails?.subJobBids[0]?.finalQuote ||
          location?.state?.jobDetails?.subJobEstimations[0]?.totalCost)
      ) {
        setSubVendorTotalEstimation(
          location?.state?.jobDetails?.subJobBids[0]?.finalQuote ??
            location?.state?.jobDetails?.subJobEstimations[0]?.totalCost
        );
      }

      // else {
      //   setSubVendorTotalEstimation(0);
      // }
      // const additionalExpensesResponse = await CreateBidServices.allJobBidAdditionalExpenses(
      //   location?.state?.jobDetails.jobId,
      //   location?.state?.jobDetails?.jobBids?.[0]?.bidId
      // );
      // if (additionalExpensesResponse?.data && additionalExpensesResponse?.errors.length === 0) {
      //   const additionalExpenseData = additionalExpensesResponse?.data?.map(
      //     (item: {
      //       bidAdditionalExpenseId: any;
      //       name: any;
      //       mdServiceCategoryId: any;
      //       mdAdditionalExpenseCategory: any;
      //       rate: any;
      //     }) => {
      //       return {
      //         bidAdditionalExpenseId: item.bidAdditionalExpenseId,
      //         name: item.name,
      //         mdServiceId: item.mdServiceCategoryId,
      //         additionalCategoryId: item.mdAdditionalExpenseCategory,
      //         totalJobEstimation: item.rate,
      //       };
      //     }
      //   );
      //   setAdditionalExpenseData(additionalExpenseData);
      //   const totalSum = additionalExpenseData?.reduce((sum: number, serviceItem: any) => {
      //     return sum + Number(serviceItem.totalJobEstimation);
      //   }, 0);

      //   setAdditionalExpenseTotal(totalSum ?? 0);
      //   setAdditionalSubTotal(totalSum ?? 0);
      // }
    }
    if (
      role == UserType.Customer ||
      location?.state?.tag === TagNames.RejectOrRequestRevision ||
      location?.state?.tag === TagNames.SelectBid
    ) {
      if (role == UserType.Customer) {
        setIsFinalQuoteLoading(true);

        fetchAllServicesLinkedToBid(location?.state?.jobId, location?.state?.jobDetails?.bidId);
        setFinalQuote(location?.state?.finalQuote);
      } else if (
        location?.state?.tag === TagNames.RejectOrRequestRevision ||
        location?.state?.tag === TagNames.SelectBid
      ) {
        setIsFinalQuoteLoading(true);
        fetchAllServicesLinkedToBid(location?.state?.jobDetails?.subJobId, location?.state?.jobDetails?.subJobBidId);
        setFinalQuote(location?.state?.jobDetails?.subJobBids[0]?.finalQuote);
      }

      setIsFinalQuoteLoading(false);
    }

    setIsBidVersionLoader(false);
  };

  const handleModifyJobDates = async (startDate: string, stopDate: string) => {
    if (storedJob) {
      const currentJob = JSON.parse(storedJob);
      setStoredJob(JSON.stringify({ ...currentJob, startDate: startDate, stopDate: stopDate }));
      localStorage.setItem(
        AsyncStorageKeys.currentJob,
        JSON.stringify({ ...currentJob, startDate: startDate, stopDate: stopDate })
      );
    }
  };

  useEffect(() => {
    fetchAllPricingOptions();
    fetchData();
  }, []);

  useEffect(() => {
    if (subVendorTotalEstimation >= 0 || primeVendorTotalEstimation >= 0 || additionalExpenseTotal >= 0) {
      setTotalEstimation(
        parseFloat(subVendorTotalEstimation?.toString() ?? 0) +
          parseFloat(primeVendorTotalEstimation?.toString() ?? 0) +
          parseFloat(additionalExpenseTotal?.toString() ?? 0)
      );
    }
  }, [subVendorTotalEstimation, primeVendorTotalEstimation, additionalExpenseTotal]);

  const fetchBidDetails = async () => {
    const bidId = location?.state?.jobDetails?.bidId;
    const bidSummaryResponse = await VendorApiService.getBidSummary(bidId);
    setBidVersion(bidSummaryResponse?.data?.bidVersion);
    const vendorLatestComment = bidSummaryResponse.data?.bidComments?.filter(
      (comment: { entityType: any }) => comment?.entityType === EntityType.VENDOR
    );
    const customerLatestComment = bidSummaryResponse.data?.bidComments?.filter(
      (comment: { entityType: any }) => comment?.entityType === EntityType.CUSTOMER
    );

    setVendorComment(vendorLatestComment?.[0]?.comment);
    setCustomerComment(customerLatestComment?.[0]?.comment);
  };

  useEffect(() => {
    fetchBidDetails();
  }, []);
  return (
    <>
      <PageTitle
        title={title}
        showDeleteIcon={
          role == UserType.Customer
            ? false
            : bidDetails?.dataStatus === 'Awarded'
              ? false
              : title !== `${t('vendor:createBid')}`
        }
        // onDeleteClick={() => setPopupOpen(true)}
      />
      <CustomerContainer>
        <BidHeader
          bidDetails={{ ...bidDetails, ...facilityDetails, bidStatus: bidData?.dataStatus, tag: location?.state?.tag }}
          handleModifyJobDates={handleModifyJobDates}
        />
        {bidDetails?.executionType === 'self' ? (
          <>
            <PrimeVendorExpensesTable
              setTotalEstimation={setPrimeVendorTotalEstimation}
              estimationType={UserType.SubVendor}
              storedJob={storedJob && JSON.parse(storedJob)}
              setCopyTableData={setPrimeVendorEstimations}
              profitPercentage={profitPercentage}
              additionalSubTotal={additionalSubTotal}
              vendorRatesAndTaxes={vendorRatesAndTaxes}
              unionIndicator={unionIndicator}
              isServicesLoading={isServicesLoading}
              ptiType={ptiType}
              setIsServicesLoading={setIsServicesLoading}
              setPtiType={setPtiType}
              isEditable
              isCardView={true}
              serviceData={services}
              pricingOptionsList={pricingOptionsList}
              selectedPricingOption={selectedPricingOption}
              setSelectedPricingOption={setSelectedPricingOption}
              setIsDataLoading={setIsDataLoading}
              isDataLoading={isDataLoading}
            />
            {!isServicesLoading && (
              <AdditionalExpensesTable
                totalEstimation={additionalExpenseTotal}
                setTotalEstimation={setAdditionalExpenseTotal}
                storedJob={storedJob && JSON.parse(storedJob)}
                additionalExpenseData={additionalExpenseData}
                setAdditionalExpenseData={setAdditionalExpenseData}
                additionalSubTotal={additionalSubTotal}
                setAdditionalSubTotal={setAdditionalSubTotal}
                serviceOptions={additionalExpensesOptions}
                handleExpensesError={handleExpensesError}
                isEditable
                isCardView={true}
              />
            )}
          </>
        ) : null}
        {bidDetails?.executionType === ExecutionType.SubVendor &&
          bidDetails?.estimationType === EstimationType.EstimateWithinPeazy &&
          location.state.tag === TagNames.SelectBid && (
            <>
              <EstimationTable
                setTotalEstimation={setSubVendorTotalEstimation}
                estimationType={UserType.SubVendor}
                storedJob={storedJob && JSON.parse(storedJob)}
                setCopyTableData={setSubVendorEstimations}
                profitPercentage={profitPercentage}
                additionalSubTotal={additionalSubTotal}
                uploadedVendorQuotes={uploadedVendorQuotes}
                setUploadedVendorQuotes={setUploadedVendorQuotes}
                vendorQuotesAttachments={quoteAttachments}
                handleDeleteQuoteAttachment={handleDeleteQuoteAttachment}
                isQuoteUploading={isQuoteUploading}
                setIsBidVersionLoader={bidVersionLoader}
                isEditable={false}
                isCardView={true}
                selectedPricingOption={selectedPricingOption}
                isSubJobServicesLoading={isSubJobServicesLoading}
              />
              <PrimeVendorExpensesTable
                setTotalEstimation={setPrimeVendorTotalEstimation}
                estimationType={UserType.SubVendor}
                storedJob={storedJob && JSON.parse(storedJob)}
                setCopyTableData={setPrimeVendorEstimations}
                profitPercentage={profitPercentage}
                additionalSubTotal={additionalSubTotal}
                vendorRatesAndTaxes={vendorRatesAndTaxes}
                unionIndicator={unionIndicator}
                isServicesLoading={isServicesLoading}
                setIsServicesLoading={setIsServicesLoading}
                ptiType={ptiType}
                setPtiType={setPtiType}
                isEditable
                isCardView={true}
                serviceData={services}
                pricingOptionsList={pricingOptionsList}
                selectedPricingOption={selectedPricingOption}
                setSelectedPricingOption={setSelectedPricingOption}
                setIsDataLoading={setIsDataLoading}
                isDataLoading={isDataLoading}
              />
              {!isServicesLoading && (
                <AdditionalExpensesTable
                  totalEstimation={additionalExpenseTotal}
                  setTotalEstimation={setAdditionalExpenseTotal}
                  storedJob={storedJob && JSON.parse(storedJob)}
                  additionalExpenseData={additionalExpenseData}
                  setAdditionalExpenseData={setAdditionalExpenseData}
                  additionalSubTotal={additionalSubTotal}
                  setAdditionalSubTotal={setAdditionalSubTotal}
                  serviceOptions={additionalExpensesOptions}
                  handleExpensesError={handleExpensesError}
                  isEditable
                  isCardView={true}
                />
              )}
            </>
          )}
        {bidDetails?.executionType === ExecutionType.SubVendor &&
          bidDetails?.estimationType === EstimationType.EstimateOutsidePeazy && (
            <>
              <EstimationTable
                setTotalEstimation={setSubVendorTotalEstimation}
                estimationType={UserType.SubVendor}
                storedJob={storedJob && JSON.parse(storedJob)}
                setCopyTableData={setSubVendorEstimations}
                profitPercentage={profitPercentage}
                additionalSubTotal={additionalSubTotal}
                uploadedVendorQuotes={uploadedVendorQuotes}
                setUploadedVendorQuotes={setUploadedVendorQuotes}
                vendorQuotesAttachments={quoteAttachments}
                handleDeleteQuoteAttachment={handleDeleteQuoteAttachment}
                setIsBidVersionLoader={bidVersionLoader}
                isQuoteUploading={isQuoteUploading}
                isEditable
                isCardView={true}
                selectedPricingOption={selectedPricingOption}
                isSubJobServicesLoading={isSubJobServicesLoading || isServicesLoading}
              />
              <PrimeVendorExpensesTable
                setTotalEstimation={setPrimeVendorTotalEstimation}
                estimationType={UserType.SubVendor}
                storedJob={storedJob && JSON.parse(storedJob)}
                setCopyTableData={setPrimeVendorEstimations}
                profitPercentage={profitPercentage}
                additionalSubTotal={additionalSubTotal}
                vendorRatesAndTaxes={vendorRatesAndTaxes}
                unionIndicator={unionIndicator}
                isServicesLoading={isServicesLoading}
                setIsServicesLoading={setIsServicesLoading}
                ptiType={ptiType}
                setPtiType={setPtiType}
                isEditable
                isCardView={true}
                serviceData={services}
                pricingOptionsList={pricingOptionsList}
                selectedPricingOption={selectedPricingOption}
                setSelectedPricingOption={setSelectedPricingOption}
                setIsDataLoading={setIsDataLoading}
                isDataLoading={isDataLoading}
              />
              {!isServicesLoading && (
                <AdditionalExpensesTable
                  totalEstimation={additionalExpenseTotal}
                  setTotalEstimation={setAdditionalExpenseTotal}
                  storedJob={storedJob && JSON.parse(storedJob)}
                  additionalExpenseData={additionalExpenseData}
                  setAdditionalExpenseData={setAdditionalExpenseData}
                  additionalSubTotal={additionalSubTotal}
                  setAdditionalSubTotal={setAdditionalSubTotal}
                  serviceOptions={additionalExpensesOptions}
                  handleExpensesError={handleExpensesError}
                  isEditable
                  isCardView={true}
                />
              )}
            </>
          )}

        {(role == UserType.Customer || location?.state?.tag === TagNames.RejectOrRequestRevision) && (
          <>
            {bidVersion > 0 && vendorComment && (
              <BidRequestRevisionComments
                comment={vendorComment}
                hasJobCustodian={!!location.state?.jobDetails?.jobCustodian}
              />
            )}
            <PrimeVendorExpensesTable
              setTotalEstimation={setPrimeVendorTotalEstimation}
              estimationType={role == UserType.Customer ? UserType.Customer : UserType.SubVendor}
              storedJob={storedJob && JSON.parse(storedJob)}
              setCopyTableData={setPrimeVendorEstimations}
              profitPercentage={profitPercentage}
              additionalSubTotal={additionalSubTotal}
              vendorRatesAndTaxes={vendorRatesAndTaxes}
              unionIndicator={unionIndicator}
              isServicesLoading={isServicesLoading}
              ptiType={ptiType}
              setPtiType={setPtiType}
              isEditable
              isCardView={true}
              serviceData={services}
              pricingOptionsList={pricingOptionsList}
              selectedPricingOption={selectedPricingOption}
              setSelectedPricingOption={setSelectedPricingOption}
              setIsServicesLoading={setIsServicesLoading}
              tag={location.state.tag}
              setIsDataLoading={setIsDataLoading}
              isDataLoading={isDataLoading}
            />
          </>
        )}

        {bidDetails?.isVendorBid && (
          <>
            {bidDetails?.isRequestRevision && customerComment && (
              <BidRequestRevisionComments
                comment={customerComment}
                hasJobCustodian={!!location.state?.jobDetails?.jobCustodian}
              />
            )}
            {bidDetails?.isRequestRevision && vendorComment && (
              <BidRequestRevisionComments
                comment={vendorComment}
                hasJobCustodian={!!location.state?.jobDetails?.jobCustodian}
              />
            )}

            <PrimeVendorExpensesTable
              setTotalEstimation={setPrimeVendorTotalEstimation}
              estimationType={UserType.SubVendor}
              storedJob={storedJob && JSON.parse(storedJob)}
              setCopyTableData={setPrimeVendorEstimations}
              profitPercentage={profitPercentage}
              additionalSubTotal={additionalSubTotal}
              vendorRatesAndTaxes={vendorRatesAndTaxes}
              unionIndicator={unionIndicator}
              isServicesLoading={isServicesLoading}
              ptiType={ptiType}
              setIsServicesLoading={setIsServicesLoading}
              setPtiType={setPtiType}
              isEditable
              isCardView={true}
              serviceData={services}
              pricingOptionsList={pricingOptionsList}
              selectedPricingOption={selectedPricingOption}
              setSelectedPricingOption={setSelectedPricingOption}
              bidDetails={bidDetails}
              setIsDataLoading={setIsDataLoading}
              isDataLoading={isDataLoading}
            />

            <AdditionalExpensesTable
              totalEstimation={additionalExpenseTotal}
              setTotalEstimation={setAdditionalExpenseTotal}
              storedJob={storedJob && JSON.parse(storedJob)}
              additionalExpenseData={additionalExpenseData}
              setAdditionalExpenseData={setAdditionalExpenseData}
              additionalSubTotal={additionalSubTotal}
              setAdditionalSubTotal={setAdditionalSubTotal}
              serviceOptions={additionalExpensesOptions}
              handleExpensesError={handleExpensesError}
              isEditable
              isCardView={true}
            />
          </>
        )}

        <BottomBar
          bidDetails={{ ...bidDetails, bidStatus: bidData?.dataStatus }}
          subVendorTotalEstimation={subVendorTotalEstimation}
          totalEstimation={totalEstimation}
          setTotalEstimation={setTotalEstimation}
          updateAndSubmit={updateAndSubmit}
          profitMargin={profitPercentage}
          setProfitMargin={setProfitPercentage}
          storedJob={storedJob && JSON.parse(storedJob)}
          additionalSubTotal={additionalSubTotal}
          setAdditionalSubTotal={setAdditionalSubTotal}
          selectedProfitOption={selectedProfitOption}
          setSelectedProfitOption={setSelectedProfitOption}
          totalEstimationWithMargin={totalEstimationWithMargin}
          setTotalEstimationWithMargin={setTotalEstimationWithMargin}
          vendorQuotesAttachments={quoteAttachments}
          finalQuote={finalQuote}
          isFinalQuoteLoading={isFinalQuoteLoading}
          stateValue={location.state && location?.state}
          isServicesLoading={isServicesLoading}
          isDataLoading={isDataLoading}
        />
      </CustomerContainer>

      <SnackbarMessage
        open={snackbarOpen}
        successMessage={snackbarMessage}
        errorMessage={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
      />
    </>
  );
};

export default CreateBid;
