/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { Units } from '../../../Shared/Constants/App';

export const getTotalServiceArea = (services: any[]) => {
  let totalArea = 0;
  let hasSqftUnits = false;

  services.forEach((service) => {
    if (service.mdServiceUnits === Units.sqft) {
      totalArea += parseFloat(service?.serviceMeasure?.toString());
      hasSqftUnits = true;
    }
  });

  const units = hasSqftUnits ? Units.sqft : '';
  return totalArea === 0 ? '-' : `${totalArea} ${units}`;
};
