/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React from 'react';

const FacilityPage = () => {
  return <div>Facility Page</div>;
};

export default FacilityPage;
