/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
  ActionItemCard,
  ButtonWrapper,
  CustomerRejectedTag,
  RejectionCardHeader,
  customStyles,
} from './ActionItems.Style';
import { Icons } from '../../../../Shared/Constants/Icons';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Typography } from '@mui/material';
import theme from '../../../../Shared/Themes/theme';
import { formatDate } from '../../../../Shared/Utilities/utils';

interface TaskCompletedCard {
  jobStartDate: string;
  facilityName: string;
  facilityAddress: string;
  handleViewDetails: () => void;
  handleApproveTasks: () => void;
}

const TaskCompletedCard = ({
  jobStartDate,
  facilityName,
  facilityAddress,
  handleViewDetails,
  handleApproveTasks,
}: TaskCompletedCard) => {
  const { t } = useTranslation(['altTexts', 'taskRejectionAndRework']);
  const { divider, viewDetailsButton, scheduleReworkButton, buttonText, cardItem } = customStyles;

  return (
    <ActionItemCard>
      <RejectionCardHeader sx={{ backgroundColor: theme.palette.info.main }}>
        <div>{formatDate(jobStartDate, 'MMM D, YYYY')}</div>
        <CustomerRejectedTag>
          <img src={Icons.WorkCompletedIcon} alt={t('taskRejectionAndRework:workCompleted')} />
          {t('taskRejectionAndRework:workCompleted')}
        </CustomerRejectedTag>
      </RejectionCardHeader>

      <Grid container style={{ padding: '16px', width: '100%' }}>
        <Grid item xs={6} spacing={2} style={{ ...cardItem, marginBottom: '1rem' }}>
          <img src={Icons.FacilityIcon} alt={t('altTexts:facility')} />
          {facilityName}
        </Grid>
        <Grid item xs={6} spacing={2} style={{ ...cardItem, marginBottom: '1rem' }}>
          <img src={Icons.LocationIcon} alt={t('altTexts:address')} />
          {facilityAddress}
        </Grid>
      </Grid>
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'end', width: '100%' }}>
        <Divider sx={{ ...divider }} />
        <ButtonWrapper sx={{ padding: '16px', width: '100%' }}>
          <Button
            sx={{
              ...viewDetailsButton,
              cursor: 'pointer',
              maxWidth: '100%',
              width: '100%',
            }}
            onClick={handleViewDetails}>
            <Typography sx={{ ...buttonText, color: theme.palette.primary.dark }}>
              {t('taskRejectionAndRework:viewDetails')}
            </Typography>
          </Button>
          <Button
            sx={{
              ...scheduleReworkButton,
              maxWidth: '100%',
              width: '100%',
            }}
            onClick={handleApproveTasks}>
            <Typography sx={{ ...buttonText, color: theme.palette.common.white }}>
              {t('taskRejectionAndRework:approveTasks')}
            </Typography>
          </Button>
        </ButtonWrapper>
      </div>
    </ActionItemCard>
  );
};

export default TaskCompletedCard;
