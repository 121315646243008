/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import {
  LabelItemWrapper,
  LabelWrapper,
  ValueWrapper,
} from '../../../Jobs/Components/JobDetails/OneTimeJobDetails.Style';
import React, { ReactNode } from 'react';

const LabelComponent = ({ label, value }: { label: ReactNode; value: ReactNode }) => {
  return (
    <LabelItemWrapper>
      <LabelWrapper>{label}</LabelWrapper>
      <ValueWrapper>{value}</ValueWrapper>
    </LabelItemWrapper>
  );
};

export default LabelComponent;
