/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { ReactNode } from 'react';
import { Box } from '@mui/material';

import {
  ImageContainer,
  LabelItemWrapper,
  LabelWrapper,
  ValueWrapper,
} from '../../../Jobs/Components/JobDetails/OneTimeJobDetails.Style';
import { ImageField } from '../../../Vendor/Components/JobAward/Popup.Styles';

const FacilityNameLabel = ({
  label,
  value,
  icon,
  fallback,
}: {
  label: ReactNode;
  value: ReactNode;
  icon?: string;
  fallback?: ReactNode;
}) => {
  return (
    <LabelItemWrapper sx={{ flexDirection: 'row', gap: '2rem', alignItems: 'center' }}>
      {icon ? (
        <ImageContainer>
          <img src={icon} alt="icon" />
        </ImageContainer>
      ) : (
        <Box>
          <ImageField>{fallback}</ImageField>
        </Box>
      )}
      <Box>
        <LabelWrapper>{label}</LabelWrapper>
        <ValueWrapper>{value}</ValueWrapper>
      </Box>
    </LabelItemWrapper>
  );
};

export default FacilityNameLabel;
