/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { styled } from '@mui/material';
import theme from '../../../../Shared/Themes/theme';
import { TypographyOptions } from '@mui/material/styles/createTypography';
interface CustomTypographyOptions extends TypographyOptions {
  typography: any;
  customSize1?: {
    fontSize: string;
  };
  customSize2?: {
    fontSize: string;
  };
}
export const buttonStyles = {
  textField: {
    minWidth: '18rem',
    '& fieldset': {
      borderRadius: '1rem',
      borderColor: theme.palette.secondary.light,
    },
    '& .MuiInputBase-root': {
      paddingRight: '0.75rem !important',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset,&:hover fieldset,&.Mui-focused fieldset': {
        borderWidth: 1,
        borderColor: theme.palette.secondary.light,
      },
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.text.secondary,
      fontSize: theme.typography.h4?.fontSize,
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightRegular,
      lineHeight: '1.375rem',
      letterSpacing: '0.00375rem',
      '&.Mui-focused': {
        color: theme.palette.text.secondary,
      },
    },
    '@media (max-width: 64rem)': {
      minWidth: '19.125rem',
    },
  },

  privacyAndTCText: {
    color: theme.palette.primary.dark,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.h5?.fontSize,
    fontFamily: theme.typography.fontFamily,
    lineHeight: '1.375rem',
    letterSpacing: '0.0125rem',
    textDecoration: 'none',
    '@media (max-width: 64rem)': {
      fontSize: theme.typography.h6?.fontSize,
      lineHeight: '1.25rem',
      letterSpacing: '0.025rem',
    },
  },

  progressBar: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: '1rem',
    padding: '0.125rem 2.25rem',
  },

  sendCodeButton: {
    background: theme.palette.primary.dark,
    borderRadius: '1rem',
    width: '100%',
    padding: '1.0625rem 2rem',
    alignItems: 'center',
    textTransform: 'none',
    '&:hover': {
      background: theme.palette.primary.dark,
    },
  },
  sendCodeButtonText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h4?.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '1.5rem',
    textTransform: 'none',
    color: theme.palette.common.white,
  },

  phoneNoTextfield: {
    minWidth: '18rem',
    '& fieldset': {
      borderRadius: '1rem',
      borderColor: theme.palette.secondary.light,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset,&:hover fieldset,&.Mui-focused fieldset': {
        borderWidth: 1,
        borderColor: theme.palette.secondary.light,
      },
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.text.secondary,
      fontSize: theme.typography.h4?.fontSize,
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightRegular,
      lineHeight: '1.375rem',
      letterSpacing: '0.00375rem',
      '&.Mui-focused': {
        color: theme.palette.text.secondary,
      },
    },

    '&.phoneno': {
      '& fieldset': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
    },
    '&.countryCode': {
      '& fieldset': {
        borderRight: 0,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
  },

  OtpTextfield: {
    width: '3.125rem',
    height: '3.125rem',
    borderRadius: '0.25rem',
    border: '0.0625rem solid gray',
    textAlign: 'center',
    outline: 'none',
  },
};

////////Login Page/////////
export const SignUpText = styled('span')(({ theme }) => {
  const customTheme = theme as CustomTypographyOptions;
  return {
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: customTheme.typography.customSize1.fontSize,
    lineHeight: '2.25rem',
    color: theme.palette.text.primary,
    display: 'flex',
    width: 'inherit',
    justifyContent: 'center',
    paddingRight: '2.25rem',
    marginBottom: 0,
    [theme.breakpoints.down('laptop')]: {
      fontSize: theme.typography.h1?.fontSize,
      lineHeight: '2rem',
    },
  };
});

export const WelcomeText = styled('span')(({ theme }) => {
  const customTheme = theme as CustomTypographyOptions;
  return {
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: theme.typography.fontWeightLight,
    fontSize: customTheme.typography.customSize1.fontSize,
    lineHeight: '2.25rem',
    color: theme.palette.text.primary,
    display: 'flex',
    width: 'inherit',
    justifyContent: 'center',
    marginBottom: 0,
    [theme.breakpoints.down('laptop')]: {
      fontSize: theme.typography.h1.fontSize,
      lineHeight: '2rem',
    },
  };
});

export const NameText = styled('span')(({ theme }) => {
  const customTheme = theme as CustomTypographyOptions;
  return {
    fontFamily: theme.typography.fontFamily,
    display: 'inline-block',
    fontStyle: 'normal',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: customTheme.typography.customSize1.fontSize,
    lineHeight: '2.25rem',
    color: theme.palette.text.primary,
    [theme.breakpoints.down('laptop')]: {
      fontSize: theme.typography.h1.fontSize,
      lineHeight: '2rem',
    },
  };
});

export const LoginUsingText = styled('span')(({ theme }) => ({
  marginBottom: '1.5rem',
  fontFamily: theme.typography.fontFamily,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.h2.fontSize,
  lineHeight: '1.75rem',
  color: theme.palette.text.disabled,
  letterSpacing: '0.009375rem',
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h4.fontSize,
    lineHeight: '1.5rem',
  },
}));

export const NotYouText = styled('span')(({ theme }) => ({
  display: 'inline-block',
  fontFamily: theme.typography.fontFamily,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.h4.fontSize,
  lineHeight: '2.25rem',
  color: theme.palette.text.disabled,
  paddingBottom: '2.5rem',
  letterSpacing: '0.009375rem',
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h5.fontSize,
    lineHeight: '1.375rem',
    letterSpacing: '0.015625rem',
  },
}));

export const NewUserText = styled('span')(({ theme }) => ({
  display: 'inline-block',
  fontFamily: theme.typography.fontFamily,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.h4.fontSize,
  lineHeight: '1.5rem',
  color: theme.palette.primary.dark,
  cursor: 'pointer',
  letterSpacing: '0.009375rem',
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h5.fontSize,
    lineHeight: '1.375rem',
    letterSpacing: '0.0125rem',
  },
}));

export const ArrowandTextContainer = styled('div')(({ theme }) => ({
  width: '100%',
  paddingBottom: '2.5rem',
  [theme.breakpoints.down('laptop')]: {
    paddingBottom: '2rem',
  },
}));

//////////Login Options///////////
export const SocialLoginOptions = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '2.5rem',
  flexDirection: 'row',
  gap: '1.5rem',
  [theme.breakpoints.down('laptop')]: {
    marginBottom: '2rem',
  },
}));

export const LinearGradientContainer = styled('div')(({ theme }) => ({
  marginBottom: '2.5rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '0.5rem',
  [theme.breakpoints.down('laptop')]: {
    marginBottom: '2rem',
  },
}));

export const OrText = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightLight,
  fontSize: theme.typography.h4.fontSize,
  lineHeight: '.5rem',
  color: theme.palette.info.light,
  letterSpacing: '0.009375',
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h5.fontSize,
    lineHeight: '1.375rem',
    letterSpacing: '0.015625',
  },
}));

export const LoginWithYourText = styled('span')(({ theme }) => ({
  marginBottom: '1.5rem',
  fontFamily: theme.typography.fontFamily,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.h2.fontSize,
  lineHeight: '1.75rem',
  color: theme.palette.text.disabled,
  letterSpacing: '0.009375',
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h4.fontSize,
    lineHeight: '1.5rem',
  },
}));

export const EmailAndPhoneNoContainer = styled('div')(({ theme }) => ({
  marginBottom: '3rem',
  display: 'flex',
  flexDirection: 'row',
  gap: '1.5rem',
  [theme.breakpoints.down('laptop')]: {
    marginBottom: '2.5rem',
  },
}));

export const EmailAndPhoneNoText = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h5.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.375rem',
  textTransform: 'none',
  marginTop: '0.5rem',
  color: theme.palette.primary.dark,
  letterSpacing: '0.0125',
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h5.fontSize,
  },
}));

//////////Sign Up Page//////////
export const LoginContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  background: theme.palette.common.white,
  flexDirection: 'row',
}));

export const NameAndLogoContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'column',
  width: '100%',
  [theme.breakpoints.down('laptop')]: {},
}));

export const LogoContainer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  paddingTop: '2.5rem',
  paddingBottom: '3rem',
  justifyContent: 'center',
  [theme.breakpoints.down('laptop')]: {
    paddingBottom: '2.5rem',
  },
}));

export const SignUpContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  [theme.breakpoints.down('laptop')]: {},
}));

export const EmailAndPasswordContainer = styled('div')(({ theme }) => ({
  width: '100%',
  marginBottom: '2rem',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('laptop')]: {
    marginBottom: '1.5rem',
  },
}));

export const NameAndEmailContainer = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: '1.5rem',
  width: '100%',
  marginBottom: '1.25rem',
}));

export const PasswordContainer = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: '1.5rem',
  width: '100%',
}));

export const ProgressbarContainer = styled('div')(() => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
}));

export const StrengthProgressbar = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '0.5rem',
  position: 'relative',
  left: '-4.125rem',
}));

export const StrengthText = styled('span')(({ theme }) => {
  const customTheme = theme as CustomTypographyOptions;
  return {
    marginRight: '0.5rem',
    fontFamily: theme.typography.fontFamily,
    fontSize: customTheme.typography.customSize2.fontSize,
    fontWeight: theme.typography.fontWeightLight,
    lineHeight: '0.75rem',
    letterSpacing: '0.03125rem',
    color: theme.palette.text.primary,
  };
});

export const PasswordConditionCheck = styled('div')(() => ({
  alignItems: 'flex-start',
  fontFamily: theme.typography.fontFamily,
  marginBottom: '2rem',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

export const ConditionContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '1.5rem',
  width: '100%',
}));

export const ConditionCheckHeading = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h5.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '1.375rem',
  color: theme.palette.text.primary,
  letterSpacing: '0.015625',
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h6.fontSize,
    lineHeight: '1.25rem',
    letterSpacing: '0.025',
  },
}));

export const OptionsContainer = styled('div')(() => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridColumnGap: '1.5rem',
}));

export const ConditionCheckTextContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.75rem',
  marginBottom: '0.5rem',
}));

export const ConditionCheckText = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  color: theme.palette.text.secondary,
  fontWeight: theme.typography.fontWeightLight,
  fontSize: theme.typography.h5.fontSize,
  lineHeight: '1.375rem',
  letterSpacing: '0.015625rem',
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h6.fontSize,
    lineHeight: '1.25rem',
    letterSpacing: '0.025rem',
  },
}));

export const CheckBoxContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  marginBottom: '2.5rem',
  fontFamily: theme.typography.fontFamily,
  width: '100%',
  [theme.breakpoints.down('laptop')]: {
    gap: '0.5rem',
    marginBottom: '2.625rem',
  },
}));

export const CheckBoxText = styled('span')(({ theme }) => ({
  color: theme.palette.text.disabled,
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.h5.fontSize,
  fontFamily: theme.typography.fontFamily,
  lineHeight: '1.375rem',
  letterSpacing: '0.015625rem',
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h6.fontSize,
    lineHeight: '1.25rem',
    letterSpacing: '0.025rem',
  },
}));

export const SignUpButton = styled('div')(({ theme }) => ({
  background: theme.palette.primary.dark,
  width: '100%',
  padding: '1.0625rem 2rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '1rem',
  textTransform: 'none',
  marginBottom: '4.625rem',
  '&:hover': {
    background: theme.palette.primary.dark,
  },
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h4.fontSize,
  lineHeight: '1.5rem',
  letterSpacing: '0.009375rem',
  fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.common.white,
  [theme.breakpoints.down('laptop')]: {
    marginBottom: '1rem',
    fontSize: theme.typography.h5.fontSize,
    lineHeight: '1.375rem',
    letterSpacing: '0.0125rem',
    padding: '0.8125rem 1.5rem',
  },
}));

//////////Login With Email/////////////
export const EmailContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '1.5rem',
  width: '100%',
  marginBottom: '1.5rem',
}));

export const ForgotPasswordContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  marginBottom: '2.5rem',
}));

export const ForgotPasswordText = styled('span')(() => ({
  fontFamily: theme.typography.fontFamily,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.h4?.fontSize,
  color: theme.palette.primary.dark,
  cursor: 'pointer',
  lineHeight: '1.5rem',
}));

//////////LoginWith Phone No////////////
export const PhoneNoTextfieldContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '2.5rem',
  flexDirection: 'column',
}));

export const Wrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '30% 70%',
  [theme.breakpoints.down('laptop')]: {
    gridTemplateColumns: '30% 70%',
  },
}));
