/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React, { useEffect, useState } from 'react';
import { t } from 'i18next';

import FacilityNameLabel from './FacilityNameLabel';

import {
  FacilityContainer,
  FacilityDetailsContainer,
  NoDataContainer,
} from '../../../Jobs/Components/JobDetails/OneTimeJobDetails.Style';
import { NoDataFound } from '../../../../Shared/Components/Common/NoDataFound/NoDataFound';

const FacilityNameComponent = (props: any) => {
  const { facilityDetails } = props;
  console.log('facilityDetails', facilityDetails);
  const [facilityIcon, setFacilityIcon] = useState('');

  useEffect(() => {
    const logoUrl = facilityDetails?.address?.logoUrl;
    if (facilityDetails && logoUrl) {
      const iconUrl = `https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=${logoUrl}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
      setFacilityIcon(iconUrl);
    }
  }, [facilityDetails]);

  const FacilityName = facilityDetails?.buildingName;
  const fallback = FacilityName ? FacilityName.slice(0, 2).toUpperCase() : '';

  const newItems = [
    {
      label: t('viewFacilityDetails:facilityName'),
      value: facilityDetails?.buildingName,
      icon: facilityIcon,
      fallback: fallback,
    },
  ];

  return (
    <FacilityDetailsContainer sx={{ boxShadow: 'none' }}>
      <FacilityContainer>
        {facilityDetails ? (
          newItems.map((item, i) => (
            <FacilityNameLabel
              key={i}
              label={item.label}
              value={item.value}
              icon={item.icon}
              fallback={item.fallback}
            />
          ))
        ) : (
          <NoDataContainer>
            <NoDataFound />
          </NoDataContainer>
        )}
      </FacilityContainer>
    </FacilityDetailsContainer>
  );
};

export default FacilityNameComponent;
