/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import WorkOrdersApiService from '../../Services/WorkOrdersService';
import _, { isEmpty } from 'lodash';
import { BooleanType, JobType, UserForUserManagement, WorkOrder } from '../../../../API';
import theme from '../../../../Shared/Themes/theme';
import { AlertColor, CircularProgress } from '@mui/material';
import { IUser } from '../../../../Shared/Constants/App';
import {
  fetchDataFromS3Bucket,
  formatDate,
  getAuthData,
  getStoredCustomerDetails,
  RolesEnum,
} from '../../../../Shared/Utilities/utils';
import SupervisorPunchIn from '../JobDetails/SupervisorPunchIn';
import { ITeamMemberData } from '../JobDetails/JobAssignmentCard';
import { AuthenticationService } from '../../../../Shared/Services/AuthenticationService';
import CustomerApiService from '../../../Customer/Services/CustomerService';
import SnackbarMessage from '../../../Onboarding/Components/Login/SnackbarMessage';
import { ActionItemCardsContainer, LoaderTag } from '../ActionItems/ActionItems.Style';
import { AssignmentCard, datePickerStyles } from '../JobDetails/OneTimeJobDetails.Style';
import { OrganizationService } from '../../../../Shared/Services/OrganizationService';
import DateSelection from '../../../../Shared/Components/Common/DatePicker/DateSelection';
import { useTranslation } from 'react-i18next';
import { useForm, useWatch } from 'react-hook-form';
import { DatePickerWrapper } from '../../../../Shared/Components/Common/GoogleMap/GoogleMapChips.Style';
import moment from 'moment';
import { FacilityInterface } from '../../../Company/Models/Company.Model';
import { IGraphQLResponse } from '../../Services/JobCreateService';

interface AssociateOnTheJobData {
  jobData?: WorkOrder;
  FacilityData?: FacilityInterface;
  jobId: string;
  jobsLoader?: boolean;
}

const AssociateOnTheJob = ({ jobData, FacilityData, jobId, jobsLoader }: AssociateOnTheJobData) => {
  const startDate = jobData?.actualStartDate;
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>('success');
  const { isDistrictManager } = getAuthData();
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [teamData, setTeamData] = useState<ITeamMemberData[]>([]);
  const [associateTeamData, setAssociateTeamData] = useState<ITeamMemberData[]>([]);
  const [checkedSupervisors, setCheckedSupervisors] = useState<any>([]);
  const [isTeamDataLoading, setIsTeamDataLoading] = useState<boolean>(false);
  const [workOrderId, setWorkOrderId] = useState<string>('');
  const [workOrderData, setWorkOrderData] = useState<any>({});
  const [isAssociatePunchedIn, setIsAssociatePunchedIn] = useState(false);
  const [jobStartDate, setJobStartDate] = useState<string>('');
  const [jobEndDate, setJobEndDate] = useState<string>('');
  const [workDayId, setWorkDayId] = useState('');
  const [isFutureShiftSelected, setIsFutureShiftSelected] = useState<boolean>(false);

  const { t } = useTranslation(['taskRejectionAndRework']);
  const { control, setValue } = useForm({
    defaultValues: {
      specificDate: startDate,
    },
  });

  const { selectDateStyles } = datePickerStyles;

  const specificDate = useWatch({
    control,
    name: 'specificDate',
  });

  const showSnackbar = (severity: AlertColor, message: string) => {
    setSnackbarOpen(true);
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
  };

  const fetchVendorTeam = async (
    workOrderTeamResponse?: IGraphQLResponse,
    pageNumber?: number,
    limit?: number,
    searchString?: string
  ) => {
    const customerDetails: any = getStoredCustomerDetails();
    const vendorId = customerDetails?.customerId;
    const { userId } = getAuthData();

    if (!userId && !vendorId) return;

    setIsTeamDataLoading(true);
    try {
      const userDetails = await AuthenticationService.getUserByCognitoUserId(userId);
      const vendorTeamResponse = await fetchVendorTeamData(userDetails?.data?.userId, pageNumber, limit, searchString);
      if (vendorTeamResponse.errors.length) {
        showSnackbar(vendorTeamResponse.errors[0].message, 'error');
        setIsTeamDataLoading(false);
        return;
      }

      const vendorTeamsUsersExcludingAssociates = getSupervisorsDetails(
        workOrderTeamResponse?.data,
        vendorTeamResponse?.data ?? []
      );
      if (!isEmpty(vendorTeamsUsersExcludingAssociates)) {
        const modifiedData = await getModifiedData(vendorTeamsUsersExcludingAssociates, workOrderTeamResponse?.data);
        setCheckedSupervisors((prevData: any) => _.uniqBy([...prevData, ...modifiedData.checkedSupervisors], 'id'));
        setTeamData(modifiedData.teamData);
      } else {
        setTeamData([]);
      }
      setIsTeamDataLoading(false);
    } catch (e) {
      console.error('Error', e);
      setTeamData([]);
      setIsTeamDataLoading(false);
    }
  };

  const fetchVendorTeamData = async (userId: string, pageNumber?: number, limit?: number, searchString?: string) => {
    if (isDistrictManager) {
      return await WorkOrdersApiService.getVendorTeam(
        userId,
        '',
        BooleanType.False,
        searchString || '',
        pageNumber,
        limit,
        true
      );
    }
    const customerDetails: any = getStoredCustomerDetails();
    const vendorId = customerDetails?.customerId;
    return await CustomerApiService.getAllUsers(vendorId, '', pageNumber, limit, BooleanType.False, true);
  };

  const getSupervisorsDetails = (workOrderTeamData: any[], vendorTeamData: any[]) => {
    const supervisorsDetails = workOrderTeamData.filter((member) => member.isSupervisor);
    return supervisorsDetails.map((supervisor) => {
      const userDetails = vendorTeamData.find((user) => user.userId === supervisor.userId);
      return {
        ...supervisor,
        ...userDetails,
      };
    });
  };

  const getModifiedData = async (vendorTeamsUsersExcludingAssociates: any[], workOrderTeamData: any[]) => {
    const tempCheckedMember: ITeamMemberData[] = [];
    const modifiedData = await Promise.all(
      vendorTeamsUsersExcludingAssociates.map(async (member: UserForUserManagement) => {
        const memberProfileUrl = await fetchDataFromS3Bucket(
          navigate,
          `associate/${member?.userId}/profile-pictures/${member?.userId}`
        );
        const { roleName = '', roleId = '' } = member?.roles?.[0] || {};
        const tempMember = {
          id: member.userId,
          name: member.username,
          distance: '1.2',
          tag: '',
          isChecked: false,
          profileImg: memberProfileUrl,
          skills: [],
          jobConflict: [],
          role: roleName,
          roleId,
          isSupervisor: true,
          teamId: '',
        };
        const workOrderTeamMember = workOrderTeamData.find((teamMember) => teamMember.userId === member.userId);
        if (workOrderTeamMember) {
          if (workOrderTeamMember.isSupervisor) {
            const checkedMember = {
              ...tempMember,
              isChecked: true,
              teamId: workOrderTeamMember.teamId || '',
            };
            tempCheckedMember.push(checkedMember);
            return checkedMember;
          }
          return { ...tempMember, teamId: workOrderTeamMember.teamId || '' };
        }
        return tempMember;
      })
    );
    return { teamData: modifiedData, checkedSupervisors: tempCheckedMember };
  };

  const fetchAssociateTeam = async (workOrderId: string, workOrderTeamResponse?: IGraphQLResponse) => {
    const customerDetails = getStoredCustomerDetails();
    const vendorId = customerDetails?.customerId;
    const { userId } = getAuthData();
    const onBehalfOfAssociate = false;

    if (!userId && !vendorId) return;

    setIsTeamDataLoading(true);
    try {
      const workDayId = workOrderTeamResponse?.data[0]?.workDayId ?? '';
      const associateDetails = getAssociateDetails(workOrderTeamResponse?.data);
      const associateUserIds = associateDetails.map((associate) => associate.userId).join(',');
      const associateTeamResponse = await OrganizationService.getAllUsers(vendorId, associateUserIds);
      if (associateTeamResponse.errors.length) {
        showSnackbar(associateTeamResponse.errors[0].message, 'error');
        setIsTeamDataLoading(false);
        return;
      }

      const { modifiedAssociateTeam } = await processAssociateTeam(
        associateTeamResponse?.data,
        workOrderTeamResponse?.data
      );
      let filteredByRoleData = modifiedAssociateTeam;
      if (!onBehalfOfAssociate) {
        filteredByRoleData = modifiedAssociateTeam?.filter(
          (item: { role: RolesEnum }) => item?.role === RolesEnum.AssociateTechnician
        );
      }
      const filteredByAvailableAssociate = filterAssociates(filteredByRoleData);
      const finalData = await processFinalData(
        filteredByAvailableAssociate,
        workOrderTeamResponse?.data,
        onBehalfOfAssociate,
        workOrderId,
        workDayId as string
      );

      setAssociateTeamData(finalData.modifiedData);
      setIsTeamDataLoading(false);
    } catch (e) {
      console.error('Error', e);
      setAssociateTeamData([]);
      setIsTeamDataLoading(false);
    }
  };

  const filterAssociates = (associateData: ITeamMemberData[]) => {
    return associateData.filter((member: IUser) => {
      const shortlisted = checkedSupervisors.some((supervisor: any) => supervisor.id === member.userId);
      return !shortlisted;
    });
  };

  const getAssociateDetails = (data: any[]) => {
    return data.filter((member) => !member.isSupervisor);
  };

  const processAssociateTeam = async (associateTeamData: any[], workOrderTeamData: any[]) => {
    const tempCheckedMember: ITeamMemberData[] = [];
    const modifiedAssociateTeam = await Promise.all(
      associateTeamData.map(async (user) => {
        const { roleName = '', roleId = '' } = user?.roles?.[0] || {};
        const associateDetails = await CustomerApiService.getAssociateDetailsByUserId(user?.cognitoUserId);
        const associateId = associateDetails?.data?.associateId ?? '';
        const memberProfileUrl = await fetchDataFromS3Bucket(
          navigate,
          `associate/${associateId}/profile-pictures/${associateId}`
        );
        const memberData = workOrderTeamData.find((associate) => associate.userId === user.userId);

        return {
          id: user?.userId,
          userId: user?.userId,
          name: user?.username,
          distance: '1.2',
          tag: '',
          isChecked: false,
          profileImg: memberProfileUrl,
          skills: [],
          jobConflict: [],
          role: roleName,
          roleId,
          isSupervisor: false,
          isDisabled: false,
          isJobNotAccepted: false,
          associateStartDate: memberData?.associateStartDate ?? '',
          associateEndDate: memberData?.associateEndDate ?? '',
        };
      })
    );
    return { modifiedAssociateTeam, tempCheckedMember };
  };

  const processFinalData = async (
    data: any[],
    workOrderTeamData: any[],
    onBehalfOfAssociate: boolean,
    workOrderId: string,
    workDayId: string
  ) => {
    const tempCheckedMember: ITeamMemberData[] = [];
    const modifiedData = await Promise.all(
      data.map(async (member) => {
        const tempMember = { ...member };
        let punchType;
        const workOrderTeamMember = workOrderTeamData.find((teamMember) => teamMember?.userId === member?.userId);
        if (workDayId) {
          const latestPunchInOutByWorkDayResponse = await WorkOrdersApiService.getLatestPunchInOutByWorkDay(
            workDayId,
            member?.userId,
            false
          );
          punchType = latestPunchInOutByWorkDayResponse.data?.punchType;
        }

        let isJobNotAccepted = false;
        let isCannotMakeJob = false;

        const associateInfo = workOrderTeamData.find(
          (associate) => associate.userId === member.userId && !associate.isSupervisor
        );
        if (associateInfo) {
          const acceptedJobResponse = await WorkOrdersApiService.getAllWorkOrders({
            isJobAccepted: true,
            workOrderId,
            userId: member.userId,
          });
          isJobNotAccepted = !acceptedJobResponse.data.length;
          const isAssociateInWorkDayTeam = !isEmpty(associateInfo?.workDayTeam ?? {});
          isCannotMakeJob = !isJobNotAccepted && !isAssociateInWorkDayTeam;
        }

        if (workOrderTeamMember) {
          const checkedMember = {
            ...tempMember,
            isChecked: true,
            isDisabled: onBehalfOfAssociate,
            teamId: workOrderTeamMember.teamId || '',
            isSupervisor: workOrderTeamMember.isSupervisor,
            workDayId: workDayId,
            punchType: punchType,
            isJobNotAccepted: isJobNotAccepted,
            isCannotMakeJob: isCannotMakeJob,
          };
          tempCheckedMember.push(checkedMember);
          return checkedMember;
        }
        return tempMember;
      })
    );
    return { modifiedData, checkedAssociates: tempCheckedMember };
  };

  const getPunchInDetails = async (workOrderId: string) => {
    const workOrderTeamResponse = await WorkOrdersApiService.getWorkOrderTeam(workOrderId);
    const associateDetails = workOrderTeamResponse.data.find((member: any) => !member.isSupervisor);
    const workDayId = associateDetails?.workDayTeam?.workDayId;
    if (workDayId) {
      const latestPunchInOutResponse = await WorkOrdersApiService.getLatestPunchRecords(workDayId);
      if (!isEmpty(latestPunchInOutResponse?.data) && isEmpty(latestPunchInOutResponse?.data?.errorCode)) {
        setIsAssociatePunchedIn(!!latestPunchInOutResponse?.data?.punchType);
      }
    }
  };

  const getWorkOrderDetails = async () => {
    setIsDataLoading(true);
    try {
      if (jobData?.jobId && specificDate) {
        const workOrderResponse = await WorkOrdersApiService.getAllWorkOrders({ jobId: jobData?.jobId });

        if (workOrderResponse?.data && workOrderResponse?.errors?.length === 0) {
          const workOrderData = workOrderResponse?.data[0];
          setWorkOrderId(workOrderData?.workOrderId);

          if (workOrderResponse?.data?.[0]?.jobStartDate) {
            const startDate = new Date(workOrderResponse.data[0].jobStartDate);
            const endDate = new Date(workOrderResponse.data[0].jobEndDate);
            const formattedStartDate = formatDate(startDate.toISOString());
            const formattedEndDate = formatDate(endDate.toISOString());
            setJobStartDate(formattedStartDate);
            setJobEndDate(formattedEndDate);
          }

          await getPunchInDetails(workOrderData?.workOrderId);
          setWorkOrderData(workOrderData);

          const startDate = moment(specificDate).format('YYYY-MM-DD');

          const workOrderTeamResponse = await WorkOrdersApiService.getWorkOrderTeam(
            workOrderData?.workOrderId,
            startDate,
            startDate
          );
          const workDayId = workOrderTeamResponse?.data[0]?.workDayId ?? '';
          setWorkDayId(workDayId);
          await fetchVendorTeam(workOrderTeamResponse);
          await fetchAssociateTeam(workOrderData?.workOrderId, workOrderTeamResponse);
        }
      }
      setIsDataLoading(false);
    } catch (e) {
      console.log('error', e);
      setIsDataLoading(false);
    }
  };

  useEffect(() => {
    const {
      actualStartDate = '',
      actualEndDate = '',
      jobStartDate = '',
      jobEndDate = '',
    } = jobData || { actualStartDate: '', actualEndDate: '', jobStartDate: '', jobEndDate: '' };
    if ((actualStartDate && actualEndDate) || (jobStartDate && jobEndDate)) {
      const startDate = actualStartDate || jobStartDate;
      const endDate = actualEndDate || jobEndDate;
      const isCurrentJobInTheRangeOfJobDurationForRecurring = moment().isBetween(startDate, endDate);
      if (jobData?.jobType === JobType.Recurring && isCurrentJobInTheRangeOfJobDurationForRecurring) {
        let currentDeviceDate = new Date()?.toJSON();
        currentDeviceDate = currentDeviceDate?.slice(0, currentDeviceDate?.length - 1);
        setValue('specificDate', currentDeviceDate);
      } else {
        setValue('specificDate', startDate);
      }
    }
  }, [jobData?.jobType, jobData?.jobStartDate, jobData?.jobEndDate, jobData?.actualStartDate, jobData?.actualEndDate]);

  useEffect(() => {
    const currentTime = moment().toJSON();
    const isCurrentShiftIsInFuture = moment(moment(specificDate).local().format('YYYY-MM-DD'), 'YYYY-MM-DD').isAfter(
      moment(currentTime, 'YYYY-MM-DD')
    );
    if (isCurrentShiftIsInFuture) {
      setIsFutureShiftSelected(true);
    } else {
      setIsFutureShiftSelected(false);
    }
  }, [specificDate]);

  useEffect(() => {
    getWorkOrderDetails();
  }, [specificDate, jobId]);

  return (
    <>
      {jobsLoader ? (
        <LoaderTag>
          <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
        </LoaderTag>
      ) : (
        <>
          <ActionItemCardsContainer>
            <AssignmentCard>
              <DatePickerWrapper
                sx={{
                  ...selectDateStyles,
                }}>
                {isDataLoading ? (
                  <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
                ) : (
                  <DateSelection
                    requiredText={t('supervisorPunchIn:punchInDateFieldReq')}
                    control={control}
                    isExcludePastDate={true}
                    fieldName={'specificDate'}
                    label={t('taskRejectionAndRework:selectDate')}
                    showSpecificRangeDates={{
                      from: new Date(jobStartDate),
                      to: new Date(jobEndDate),
                    }}
                    textFieldStyles={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset,&:hover fieldset,&.Mui-focused fieldset': {
                          borderWidth: 1,
                          borderColor: theme.palette.primary.dark,
                          borderRadius: '1rem',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: theme.palette.primary.dark,
                        '&.Mui-focused': {
                          color: theme.palette.primary.dark,
                        },
                      },
                    }}
                  />
                )}
              </DatePickerWrapper>

              {workOrderId && (
                <SupervisorPunchIn
                  teamData={teamData}
                  associateTeamData={associateTeamData}
                  isTeamDataLoading={isTeamDataLoading || isDataLoading}
                  workOrderId={workOrderId}
                  workOrderData={workOrderData}
                  address={FacilityData?.address}
                  isAssociatePunchedIn={isAssociatePunchedIn}
                  workDayId={workDayId}
                  isFutureShiftSelected={isFutureShiftSelected}
                  specificDate={specificDate}
                  jobEndDate={jobEndDate}
                  getWorkOrderDetails={getWorkOrderDetails}
                />
              )}
            </AssignmentCard>
            <SnackbarMessage
              open={snackbarOpen}
              successMessage={snackbarMessage}
              errorMessage={snackbarMessage}
              severity={snackbarSeverity}
              onClose={() => setSnackbarOpen(false)}
            />
          </ActionItemCardsContainer>
        </>
      )}
    </>
  );
};

export default AssociateOnTheJob;
