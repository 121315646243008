/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertColor, Button, CircularProgress, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { Icons } from '../../../../Shared/Constants/Icons';
import {
  getAcronymName,
  formatDate,
  hours,
  getTimeFormat,
  getTimeFromGivenDate,
  parseTime,
  ErrorCodesForPunChIn,
} from '../../../../Shared/Utilities/utils';
import { ITeamMemberData } from './JobAssignmentCard';
import WorkOrdersApiService from '../../Services/WorkOrdersService';
import { PunchType } from '../../../../API';
import SnackbarMessage from '../../../Onboarding/Components/Login/SnackbarMessage';
import {
  buttonStyles,
  DateRange,
  HeadingText,
  LogoWrapper,
  NewDatePickerContainer,
  PunchCardContainerWrapper,
  PunchInButton,
  PunchinHeading,
  PunchTeamContainer,
  TagWrapper,
  TagWrapperText,
  TeamCard,
  TeamCardOuterDiv,
  TeamDisplayDiv,
} from './OneTimeJobDetails.Style';
import theme from '../../../../Shared/Themes/theme';
import { FieldNames } from '../../Models/AddEditOneTimeJob.Model';
import { Controller, useForm } from 'react-hook-form';
import DateSelection from '../../../../Shared/Components/Common/DatePicker/DateSelection';
import { DateFieldWrapper, ErrorMsg } from '../AddEditOneTimeJob/AddEditOneTimeJob.Style';
import { Options } from '../../../../Shared/Components/Common/Dropdown/Dropdown.styles';
import Modal from '../../Utilities/Shared/Components/Modal';
import moment from 'moment';
import AssociateApiService from '../../../../Shared/Services/AssociateService';
import { isEmpty } from 'lodash';
import { LoaderTag } from '../ActionItems/ActionItems.Style';

interface AssignTeam {
  teamData: ITeamMemberData[];
  associateTeamData: ITeamMemberData[];
  isTeamDataLoading: boolean;
  workOrderId: string;
  workOrderData: any;
  address: any;
  isAssociatePunchedIn: any;
  workDayId: string;
  isFutureShiftSelected: boolean;
  specificDate: any;
  jobEndDate: any;
  getWorkOrderDetails: () => Promise<void>;
}

export const checkAlreadySelected = (data: ITeamMemberData[], memberId: string): boolean => {
  return data.some((prevMember) => prevMember.id === memberId);
};

const SupervisorPunchIn = ({
  teamData,
  associateTeamData,
  isTeamDataLoading,
  address,
  workDayId,
  isFutureShiftSelected,
  specificDate,
  jobEndDate,
  getWorkOrderDetails,
}: AssignTeam) => {
  const [punchInTeamData, setPunchInTeamData] = useState<ITeamMemberData[]>([]);
  const [punchOutTeamData, setPunchOutTeamData] = useState<ITeamMemberData[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>('success');
  const [isStartTimeSelectOpen, setStartTimeSelectOpen] = useState(false);
  const [hoursList, setHoursList] = useState<any[]>([]);
  const [isPunchedInModalOpen, setIsPunchedInModalOpen] = useState<boolean>(false);
  const [isPunchedOutModalOpen, setIsPunchedOutModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [punchedInSuccess, setPunchedInSuccess] = useState(false);

  const { t } = useTranslation(['supervisorPunchIn', 'assignJob', 'oneTimeJob', 'calendar']);
  const { textField, PunchInButtonStyles } = buttonStyles;
  const { control, handleSubmit, watch, getValues } = useForm({
    defaultValues: {
      punchInDate: specificDate ? formatDate(specificDate) : null,
      punchOutDate: null,
      punchInTime: '',
      punchOutTime: '',
    },
  });

  const watchFields = watch();

  const disablePunchInButton = () => {
    const { punchInDate, punchInTime } = getValues();
    return !(punchInDate && punchInTime);
  };

  const disablePunchOutButton = () => {
    const { punchOutDate, punchOutTime } = getValues();
    return !(punchOutDate && punchOutTime);
  };

  useEffect(() => {
    disablePunchInButton();
    disablePunchOutButton();
  }, [watchFields]);

  const handlePunchIn = (id: any) => {
    setIsPunchedInModalOpen(true);
    const updatedTeamData = associateTeamData.filter((member) => member?.id === id);
    setPunchInTeamData(updatedTeamData);
    return true;
  };

  const handlePunchOut = (id: any) => {
    setIsPunchedOutModalOpen(true);
    const updatedTeamData = associateTeamData.filter((member) => member?.id === id);
    setPunchOutTeamData(updatedTeamData);
    return true;
  };

  const showSnackbar = (severity: AlertColor, message: string) => {
    setSnackbarOpen(true);
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
  };

  const renderDuration = (member: ITeamMemberData) => {
    if (!member?.isSupervisor && member?.associateStartDate && member?.associateEndDate) {
      const associateStartDate = new Date(member.associateStartDate);
      const associateEndDate = new Date(member.associateEndDate);
      const formattedStartDate = formatDate(associateStartDate.toISOString());
      const formattedEndDate = formatDate(associateEndDate.toISOString());
      return (
        <PunchInButton>
          {t('dashboard:duration')}
          <DateRange>
            {formattedStartDate} - {formattedEndDate}
          </DateRange>
        </PunchInButton>
      );
    }
  };

  const renderTag = (member: ITeamMemberData) => {
    if (!(!workDayId || isFutureShiftSelected) && !member?.isSupervisor && member.isJobNotAccepted) {
      return (
        <TagWrapper>
          <TagWrapperText>{t('assignJob:jobNotYetAccepted')}</TagWrapperText>
        </TagWrapper>
      );
    }
    if (!member?.isSupervisor && member?.isCannotMakeJob) {
      return (
        <TagWrapper>
          <TagWrapperText>{t('assignJob:canNotMakeIt')}</TagWrapperText>
        </TagWrapper>
      );
    }
  };

  const renderTeamContent = (teamData: ITeamMemberData[]) => {
    return (
      <>
        {teamData?.length > 0 && (
          <TeamDisplayDiv>
            {teamData?.map((member: ITeamMemberData) => {
              return (
                <>
                  <TeamCardOuterDiv>
                    {renderTag(member)}
                    <TeamCard style={{ padding: '20px' }} key={member?.id}>
                      <div style={{ display: 'flex' }}>
                        <LogoWrapper>
                          <div>{getAcronymName(member?.name as string).toUpperCase()}</div>
                        </LogoWrapper>
                        <PunchTeamContainer>
                          <div className="Title">{member?.name}</div>
                          {!member?.isSupervisor &&
                            (isEmpty(member?.punchType) || member?.punchType === PunchType.NoShow ? (
                              <Button
                                sx={{
                                  ...PunchInButtonStyles,
                                }}
                                onClick={() => handlePunchIn(member?.id)}
                                disabled={member?.isJobNotAccepted || !workDayId || isFutureShiftSelected}>
                                <PunchInButton
                                  style={{
                                    opacity: member?.isJobNotAccepted || !workDayId || isFutureShiftSelected ? 0.5 : 1,
                                  }}>
                                  {t('supervisorPunchIn:punchIn')}
                                </PunchInButton>
                              </Button>
                            ) : (
                              <Button
                                sx={{
                                  ...PunchInButtonStyles,
                                }}
                                onClick={() => handlePunchOut(member.id)}
                                disabled={
                                  member?.isJobNotAccepted ||
                                  member?.punchType === PunchType.PunchOut ||
                                  !workDayId ||
                                  isFutureShiftSelected
                                }>
                                <PunchInButton
                                  style={{
                                    opacity:
                                      member?.isJobNotAccepted ||
                                      member?.punchType === PunchType.PunchOut ||
                                      !workDayId ||
                                      isFutureShiftSelected
                                        ? 0.5
                                        : 1,
                                  }}>
                                  {member?.punchType === PunchType.PunchOut
                                    ? t('supervisorPunchIn:punchedOut')
                                    : t('supervisorPunchIn:punchOut')}
                                </PunchInButton>
                              </Button>
                            ))}
                          {renderDuration(member)}
                        </PunchTeamContainer>
                      </div>
                    </TeamCard>
                  </TeamCardOuterDiv>
                </>
              );
            })}
          </TeamDisplayDiv>
        )}
      </>
    );
  };

  const handleClosePunchIn = () => {
    setIsPunchedInModalOpen(false);
  };

  const handleClosePunchOut = () => {
    setIsPunchedOutModalOpen(false);
  };

  const handleOnPressPunchIn = async (dateDetails: any) => {
    const { punchInDate, punchInTime } = dateDetails;

    const parsedTime = parseTime(punchInTime);

    const punchTime = moment(punchInDate).format('YYYY-MM-DDTHH:mm:ss.sss');
    const punchIn = moment(punchTime).set('hours', Number(parsedTime.hours)).set('minutes', Number(parsedTime.minutes));
    try {
      setIsLoading(true);
      const punchInInput = [
        {
          userId: punchInTeamData?.[0]?.id,
          punchTime: `${moment(punchIn)?.format('YYYY-MM-DDTHH:mm:ss.sss')}Z`,
          punchType: PunchType.PunchIn,
          locationLatitude: address?.latitude,
          locationLongitude: address?.longitude,
        },
      ];
      if (workDayId) {
        const punchInResponse = await AssociateApiService.addPunchInOutBySupervisor(workDayId, punchInInput);
        if (punchInResponse?.data) {
          if (punchInResponse?.data[0]?.status === ErrorCodesForPunChIn.ALREADY_EXIST) {
            setIsPunchedInModalOpen(false);
            await getWorkOrderDetails();
          } else {
            if (!isEmpty(punchInResponse.errors)) {
              showSnackbar('error', `${t('supervisorPunchIn:ErrorOccurredWhilePunchIn')}`);
            } else if (punchInResponse?.data?.length) {
              setPunchedInSuccess(true);
              showSnackbar('success', `${t('supervisorPunchIn:PunchedInSuccessfully')}`);
              setIsPunchedInModalOpen(false);
              await getWorkOrderDetails();
            }
          }
        }
      } else {
        showSnackbar('error', `${t('supervisorPunchIn:ErrorOccurredWhilePunchIn')}`);
      }
      setIsLoading(false);
    } catch (error) {
      showSnackbar('error', `${t('supervisorPunchIn:ErrorOccurredWhilePunchIn')}`);
    } finally {
      setIsLoading(false);
      setPunchedInSuccess(false);
    }
  };

  const handleOnPressPunchMeOut = async (dateDetails: any) => {
    const { punchOutDate, punchOutTime } = dateDetails;

    const punchTime = moment(punchOutDate).format('YYYY-MM-DDTHH:mm:ss.sss');
    const parsedTime = parseTime(punchOutTime);

    const punchOut = moment(punchTime)
      .set('hours', Number(parsedTime.hours))
      .set('minutes', Number(parsedTime.minutes));
    try {
      setIsLoading(true);
      if (workDayId) {
        const latestPunchInOutByWorkDayResponse = await WorkOrdersApiService.getLatestPunchInOutByWorkDay(
          workDayId,
          punchOutTeamData?.[0]?.id ?? '',
          false
        );

        const latestPunchTime = latestPunchInOutByWorkDayResponse?.data?.punchTime;

        let punchInTime;
        if (latestPunchTime?.endsWith('Z')) {
          punchInTime = latestPunchTime?.slice(0, latestPunchTime?.length - 1);
        } else {
          punchInTime = latestPunchTime ?? '';
        }

        const punchInType = latestPunchInOutByWorkDayResponse.data.punchType;

        const isPunchInTimeIsBeforePunchOut = moment(`${moment(punchOut)?.format('YYYY-MM-DDTHH:mm:ss.sss')}`).isBefore(
          punchInTime
        );

        if (isPunchInTimeIsBeforePunchOut) {
          const validPunchOutTime = getTimeFromGivenDate(punchInTime, true);
          showSnackbar(
            'error',
            `${t('supervisorPunchIn:punchOutTimeShouldBeGreaterThanPunchTime')} ${validPunchOutTime.hours}:${
              validPunchOutTime.mins
            }`
          );
          return;
        } else if (punchInType === PunchType.PunchOut) {
          setPunchedInSuccess(true);
          showSnackbar('error', `${t('supervisorPunchIn:alreadyPunchedOutTitle')}`);
          setIsPunchedOutModalOpen(false);
          await getWorkOrderDetails();
          return;
        } else {
          await performPunchOut(punchOut);
        }
      } else {
        showSnackbar('error', `${t('supervisorPunchIn:ErrorOccurredWhilePunchOut')}`);
      }
      setIsLoading(false);
    } catch (error) {
      showSnackbar('error', `${t('supervisorPunchIn:ErrorOccurredWhilePunchOut')}`);
    } finally {
      setIsLoading(false);
      setPunchedInSuccess(false);
    }
  };

  const performPunchOut = async (punchOut: moment.Moment) => {
    const punchOutInput = [
      {
        userId: punchOutTeamData?.[0]?.id,
        punchTime: `${moment(punchOut)?.format('YYYY-MM-DDTHH:mm:ss.sss')}Z`,
        punchType: PunchType.PunchOut,
        locationLatitude: address?.latitude,
        locationLongitude: address?.longitude,
      },
    ];

    if (workDayId) {
      const punchInOutResponse = await AssociateApiService.addPunchInOutBySupervisor(workDayId, punchOutInput);

      if (punchInOutResponse?.data) {
        setIsPunchedOutModalOpen(false);
        setPunchedInSuccess(true);
        showSnackbar('success', `${t('supervisorPunchIn:PunchedOutSuccessfully')}`);
        await getWorkOrderDetails();
      } else {
        showSnackbar('error', `${t('supervisorPunchIn:ErrorOccurredWhilePunchOut')}`);
      }
    } else {
      showSnackbar('error', `${t('supervisorPunchIn:ErrorOccurredWhilePunchOut')}`);
    }
  };

  const renderPunchInHeaderContent = () => {
    return <HeadingText>{t('supervisorPunchIn:punchIn')}</HeadingText>;
  };

  const renderPunchOutHeaderContent = () => {
    return <HeadingText>{t('supervisorPunchIn:punchOut')}</HeadingText>;
  };

  const renderPunchInBodyContent = () => {
    return (
      <>
        <NewDatePickerContainer>
          <DateSelection
            requiredText={t('supervisorPunchIn:punchInDateFieldReq')}
            control={control}
            isExcludePastDate={true}
            fieldName={FieldNames.punchInDate ?? null}
            label={t('supervisorPunchIn:Date')}
            iconColor="black"
            showSpecificRangeDates={{
              from: specificDate,
              to: new Date(jobEndDate),
            }}
          />
          <Controller
            control={control}
            name={FieldNames.punchInTime}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <DateFieldWrapper>
                  <TextField
                    select
                    sx={textField}
                    label={t('supervisorPunchIn:Time')}
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setStartTimeSelectOpen(!isStartTimeSelectOpen)}
                            style={{
                              padding: 0,
                            }}>
                            <img src={Icons.DropdownUpIcon} alt={'up'} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    SelectProps={{
                      open: isStartTimeSelectOpen,
                      IconComponent: () => null,
                      onOpen: () => setStartTimeSelectOpen(true),
                      onClose: () => setStartTimeSelectOpen(false),
                      MenuProps: {
                        PaperProps: {
                          style: {
                            boxShadow: '0px 16px 20px 0px rgba(58, 42, 0, 0.12)',
                          },
                        },
                      },
                    }}>
                    {hoursList.map((item) => (
                      <Options key={'startTime' + item.value} value={item.value}>
                        {item.label}
                      </Options>
                    ))}
                  </TextField>

                  <ErrorMsg>{error && error?.message}</ErrorMsg>
                </DateFieldWrapper>
              );
            }}
          />
        </NewDatePickerContainer>
      </>
    );
  };

  const renderPunchOutBodyContent = () => {
    return (
      <>
        <NewDatePickerContainer>
          <DateSelection
            requiredText={t('supervisorPunchIn:punchOutDateFieldReq')}
            control={control}
            isExcludePastDate={true}
            fieldName={FieldNames.punchOutDate ?? null}
            label={t('supervisorPunchIn:Date')}
            iconColor="black"
            showSpecificRangeDates={{
              from: specificDate,
              to: new Date(jobEndDate),
            }}
          />
          <Controller
            control={control}
            name={FieldNames.punchOutTime}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <DateFieldWrapper>
                  <TextField
                    select
                    sx={textField}
                    label={t('supervisorPunchIn:Time')}
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setStartTimeSelectOpen(!isStartTimeSelectOpen)}
                            style={{
                              padding: 0,
                            }}>
                            <img src={Icons.DropdownUpIcon} alt={'up'} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    SelectProps={{
                      open: isStartTimeSelectOpen,
                      IconComponent: () => null,
                      onOpen: () => setStartTimeSelectOpen(true),
                      onClose: () => setStartTimeSelectOpen(false),
                      MenuProps: {
                        PaperProps: {
                          style: {
                            boxShadow: '0px 16px 20px 0px rgba(58, 42, 0, 0.12)',
                          },
                        },
                      },
                    }}>
                    {hoursList.map((item) => (
                      <Options key={'startTime' + item.value} value={item.value}>
                        {item.label}
                      </Options>
                    ))}
                  </TextField>

                  <ErrorMsg>{error && error?.message}</ErrorMsg>
                </DateFieldWrapper>
              );
            }}
          />
        </NewDatePickerContainer>
      </>
    );
  };

  useEffect(() => {
    setHoursList(
      hours.map((hour) => {
        return {
          label: getTimeFormat(hour),
          value: hour,
        };
      })
    );
  }, []);

  return (
    <>
      <PunchCardContainerWrapper>
        {isTeamDataLoading ? (
          <LoaderTag>
            <CircularProgress size={30} sx={{ color: theme.palette.primary.dark, marginTop: '100px' }} />
          </LoaderTag>
        ) : (
          <>
            <PunchinHeading>{t('assignJob:assignedSupervisors')}</PunchinHeading>
            {teamData.length > 0 ? (
              renderTeamContent(teamData)
            ) : (
              <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
                {t('assignJob:noResultsFound')}
              </Typography>
            )}
            <PunchinHeading>{t('assignJob:assignedAssociates')}</PunchinHeading>

            {associateTeamData.length > 0 ? (
              renderTeamContent(associateTeamData)
            ) : (
              <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
                {t('assignJob:noResultsFound')}
              </Typography>
            )}
          </>
        )}
      </PunchCardContainerWrapper>

      <Modal
        open={isPunchedInModalOpen}
        onClose={handleClosePunchIn}
        primaryButtonLoading={isLoading}
        primaryButtonLabel={t('supervisorPunchIn:punchIn')}
        secondaryButtonLabel={t('assignJob:cancel')}
        renderHeader={renderPunchInHeaderContent()}
        renderBody={renderPunchInBodyContent()}
        primaryAction={handleSubmit(handleOnPressPunchIn)}
        secondaryAction={handleClosePunchIn}
        styles={{
          '& .MuiPaper-root': {
            width: '32rem',
          },
        }}
        primaryButtonDisabling={disablePunchInButton()}
      />
      <Modal
        open={isPunchedOutModalOpen}
        onClose={handleClosePunchOut}
        primaryButtonLoading={isLoading}
        primaryButtonLabel={t('supervisorPunchIn:punchOut')}
        secondaryButtonLabel={t('assignJob:cancel')}
        renderHeader={renderPunchOutHeaderContent()}
        renderBody={renderPunchOutBodyContent()}
        primaryAction={handleSubmit(handleOnPressPunchMeOut)}
        secondaryAction={handleClosePunchOut}
        styles={{
          '& .MuiPaper-root': {
            width: '32rem',
          },
        }}
        primaryButtonDisabling={disablePunchOutButton()}
      />
      <SnackbarMessage
        open={snackbarOpen}
        successMessage={snackbarMessage}
        errorMessage={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
        styles={punchedInSuccess && { left: '40%' }}
      />
    </>
  );
};

export default SupervisorPunchIn;
