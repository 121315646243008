/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { Dialog, styled } from '@mui/material';
import theme from '../../../../Shared/Themes/theme';

export const customStyles = {
  divider: {
    border: `00.01 solid ${theme.palette.divider}`,
    width: '100%',
  },
  viewDetailsButton: {
    border: `0.0625rem solid ${theme.palette.primary.dark}`,
    background: theme.palette.common.white,
    borderRadius: '12px',
    textTransform: 'none',
    marginRight: '1.5rem',
    padding: '1.0625rem 2rem',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
    '@media (max-width: 64rem)': {
      padding: '0.75rem 2rem',
      marginRight: '1rem',
    },
  },
  scheduleReworkButton: {
    background: theme.palette.primary.dark,
    borderRadius: '12px',
    maxWidth: '11.25rem',
    padding: '1.0625rem 2.95rem',
    textTransform: 'none',
    '&:hover': {
      background: theme.palette.primary.dark,
    },
    '@media (max-width: 64rem)': {
      padding: '0.75rem 2rem',
    },
  },
  reworkDetailsScheduleButton: {
    background: theme.palette.primary.dark,
    borderRadius: '1.25rem',
    maxWidth: '11.25rem',
    padding: '1.0625rem 2.95rem',
    textTransform: 'none',
    '&:hover': {
      background: theme.palette.primary.dark,
    },
    '@media (max-width: 64rem)': {
      padding: '0.75rem 2rem',
    },
  },
  buttonText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h5?.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: '1.5rem',
    letterSpacing: '0.013rem',
    '@media (max-width: 64rem)': {
      fontSize: theme.typography.h5?.fontSize,
      lineHeight: '1.375rem',
      letterSpacing: '0.0125rem',
    },
  },
  cardItem: {
    display: 'flex',
    gap: '0.5rem',
    alignItems: 'center',
  },
  iconSizes: {
    width: '1.5rem',
    height: '1.5rem',
  },
};
export const ActionItemCardsContainer = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  margin: '1.875rem 0',
}));

export const ActionItemCard = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  boxShadow: '0 0.625rem 1.25rem 0.375rem rgba(121, 87, 0, 0.10);',
  borderRadius: '1rem',
  backgroundColor: theme.palette.common.white,
  marginBottom: '1.5rem',
}));

export const ButtonWrapper = styled('div')(() => ({
  display: 'flex',
}));

export const ReworkTaskDetailsWrapper = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    borderRadius: '1.5rem',
    overflowY: 'unset',
    width: '50%',
  },
  '& .MuiDialogTitle-root+.MuiDialogContent-root': {
    paddingTop: '0.5rem',
  },
}));

export const CloseDialogBoxIcon = styled('span')(() => ({
  cursor: 'pointer',
  position: 'absolute',
  right: '1.5rem',
  top: '-1.125rem',
}));

export const LoaderTag = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '200px',
  margin: 'auto',
}));

export const RejectionCardHeader = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '10px 16px 8px 16px',
  backgroundColor: theme.palette.error.light,
  width: '100%',
  borderTopLeftRadius: '1rem',
  borderTopRightRadius: '1rem',
}));

export const CustomerRejectedTag = styled('div')(() => ({
  display: 'flex',
  gap: '8px',
}));

export const RejectionTimeHeader = styled('div')(() => ({
  padding: '10px 16px 8px 16px',
  backgroundColor: theme.palette.error.light,
  width: '100%',
  borderTopLeftRadius: '1rem',
  borderTopRightRadius: '1rem',
}));

export const RejectionTimeTag = styled('div')(() => ({
  display: 'flex',
  color: theme.palette.error.main,
  gap: '8px',
  textAlign: 'center',
  fontWeight: theme.typography.fontWeightLight,
  fontSize: theme.typography.h5?.fontSize,
  lineHeight: '1.25rem',
}));

export const TaskCompletedByTag = styled('div')(() => ({
  marginBottom: '1rem',
  fontWeight: theme.typography.fontWeightLight,
  fontSize: theme.typography.h4?.fontSize,
  lineHeight: '1.375rem',
  letterSpacing: '0.016rem',
  fontFamily: theme.typography.fontFamily,
}));

export const AttachmnetsContainer = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'nowrap',
  gap: '1rem',
  maxWidth: '100%',
  overflowX: 'auto',
}));

export const AttachmentMediaTag = styled('div')(() => ({
  width: '5rem',
  height: '5rem',
  borderRadius: '1rem',
  overflow: 'hidden',
  backgroundColor: theme.palette.common.black,
}));

export const AttachmentNameTag = styled('div')(() => ({
  margin: '8px 0',
  textAlign: 'center',
  fontWeight: theme.typography.fontWeightLight,
  fontSize: theme.typography.h6 ? theme.typography.h6?.fontSize : '0.75rem',
  lineHeight: '1rem',
  letterSpacing: '0.016rem',
  fontFamily: theme.typography.fontFamily,
}));

export const CommentTitle = styled('div')(() => ({
  marginTop: '1rem',
  fontWeight: theme.typography.fontWeightLight,
  fontSize: theme.typography.h6 ? theme.typography.h6?.fontSize : '0.75rem',
  lineHeight: '1.25rem',
  letterSpacing: '0.016rem',
  fontFamily: theme.typography.fontFamily,
}));

export const CommentTag = styled('div')(() => ({
  fontWeight: theme.typography.fontWeightLight,
  fontSize: theme.typography.h5?.fontSize,
  lineHeight: '1.375rem',
  letterSpacing: '0.016rem',
  fontFamily: theme.typography.fontFamily,
}));

export const CommentsContainer = styled('div')(() => ({
  maxHeight: '10rem',
  overflowY: 'auto',
}));

export const Fields = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
}));

export const MediaContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '0.5rem',
  cursor: 'pointer',
}));

export const MediaText = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h4.fontSize,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.5rem',
  letterSpacing: '0.009375rem',
  color: theme.palette.primary.dark,
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h5.fontSize,
    lineHeight: '1.375rem',
    letterSpacing: '0.015625rem',
  },
}));

export const Divider = styled('div')(() => ({
  borderLeft: `0.0625rem solid ${theme.palette.divider}`,
  height: '1rem',
  margin: '0 1rem',
}));

export const AttachmnetsHeader = styled('div')(() => ({
  marginRight: '1rem',
  fontWeight: theme.typography.fontWeightLight,
  fontSize: theme.typography.h5?.fontSize,
  lineHeight: '1.375rem',
  letterSpacing: '0.016rem',
  fontFamily: theme.typography.fontFamily,
}));

export const NoActionItems = styled('div')(() => ({
  margin: 'auto',
  background: theme.palette.common.white,
  boxShadow: '0 0.625rem 1.25rem 0.375rem rgba(121, 87, 0, 0.10);',
  padding: '3rem',
  width: '100%',
  textAlign: 'center',
  borderRadius: '1rem',
}));

export const ContainerTitle = styled('div')(() => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h3?.fontSize,
  lineHeight: '1.75rem',
  letterSpacing: '0.009375rem',
  fontWeight: theme.typography.fontWeightMedium,
  color: theme.palette.text.primary,
  margin: '1.5rem 0',
}));
