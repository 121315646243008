/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React from 'react';
import { ErrorWrapper } from '../../Common/ErrorMessage/ErrorAndNoData.styles';

import { Icons } from '../../../Constants/Icons';

const MapLoader = () => {
  return (
    <ErrorWrapper>
      <img src={Icons.LoadingImg} />
    </ErrorWrapper>
  );
};

export { MapLoader };
