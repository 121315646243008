/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React, { isValidElement, useCallback, useEffect, useMemo, useState } from 'react';
import { MRT_ColumnDef } from 'material-react-table';
import { Box } from '@mui/system';
import { CircularProgress, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { getStoredCustomerDetails } from '../../../../Shared/Utilities/utils';
import { JobStatus, ServiceCategoryV3, WorkOrder, WorkOrderStatus } from '../../../../API';
import { Job, DATA_STATUS } from '../../../../Shared/Constants/App';
import { isEmpty } from 'lodash';

import { IJobOverview } from '../../../Jobs/Components/JobsListView/JobsListView';
import { TaskData } from '../../../../Shared/Models/WorkTask.model';
import { IFacilityDetails, ISelectedService, IAccessFacility } from '../../../Vendor/Models/ViewFacilityDetails.model';
import { IJobs } from '../../../Jobs/Components/JobView/JobsView';
import { IBidList } from '../../../Jobs/Components/JobDetails/OneTimeJobDetails';
import moment from 'moment';
import { OrganizationService } from '../../../../Shared/Services/OrganizationService';
import FacilityApiService from '../../../Facility/Services/FacilityApiService';
import MasterDataApiService from '../../../../Shared/Services/MasterDataService';
import WorkOrdersApiService from '../../../Jobs/Services/WorkOrdersService';
import JobScheduleServices from '../../../Jobs/Services/JobScheduleService';
import JobCreateApiService from '../../../Jobs/Services/JobCreateService';
import CustomerApiService from '../../Services/CustomerService';

import DataGrid from '../../../../Shared/Components/Common/DataGrid/DataGrid';
import PageTitle from '../../../../Shared/Components/Common/PageTitle/PageTitle';
import SnackbarMessage from '../../../Onboarding/Components/Login/SnackbarMessage';
import LabelWrapperComponent from './LabelWrapperComponent';
import FacilityNameComponent from './FacilityNameComponent';
import FacilityAccessComponent from './FacilityAccessComponent';

import theme from '../../../../Shared/Themes/theme';

import { CustomerContainer } from '../../../../Shared/Components/Layout/styles';
import { FacilityContainer } from '../../../Jobs/Components/JobDetails/OneTimeJobDetails.Style';
import { HeadingText, ModifyField } from './ViewFacilityDetails.Style';
import { Loader } from '../../../Jobs/Components/JobView/JobsView.styles';
import { Linking } from '../../../../Shared/Components/Common/DataGrid/DataGrid.styles';

import { Icons } from '../../../../Shared/Constants/Icons';

export interface IProfileCardProps {
  email: string;
  facilityName: string;
  icon: string;
  id: number;
  phoneNumber: string;
  role: string;
  userName: string;
  approvedByCustomer?: string | number;
  totalTasks?: number;
  jobId?: string;
  executionType?: string;
  customerId?: string;
}

const ViewFacilityDetails = () => {
  const { t } = useTranslation(['viewFacilityDetails', 'tableHeader']);

  const [facilityDetails, setFacilityDetails] = useState<IFacilityDetails[]>([]);
  const [selectedServices, setSelectedServices] = useState<ISelectedService[]>();
  const [allJobs, setAllJobs] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [isAccessLoading, setIsAccessLoading] = useState<boolean>(false);
  const [jobsLoader, setJobsLoader] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  const NUMBER_OF_ITEMS_PER_PAGE = 10;

  const location = useLocation();
  const facilityId = location.state.rowInfo.facilityId;

  const { customerId = '' } = getStoredCustomerDetails() || {};
  const [facilityAccess, setFacilityAccess] = useState<IAccessFacility>();

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const fetchFacilityDetails = useCallback(async () => {
    try {
      setIsLoading(true);
      setLoader(true);
      setIsAccessLoading(true);
      const facilityDetailsResponse = await FacilityApiService.facility(facilityId, customerId);

      setFacilityDetails(facilityDetailsResponse?.data);
      setIsLoading(false);

      const serviceCategories = await MasterDataApiService.getAllServiceCategoryV3(customerId ?? '');
      if (serviceCategories?.data) {
        const facilityServiceSpecificationResponse = await FacilityApiService.facilityServiceSpecification(
          customerId ?? '1234',
          facilityId
        );
        if (facilityServiceSpecificationResponse?.data) {
          const uniqueServiceCategories = facilityServiceSpecificationResponse?.data.map(
            (item: { mdServiceId: any; serviceQuantity: any; mdServiceUnitType: any }) => {
              const findServiceData = serviceCategories?.data?.find(
                (categoryItem: ServiceCategoryV3) => categoryItem.serviceCategoryId === item.mdServiceId
              );
              if (!isEmpty(findServiceData))
                return {
                  categoryId: findServiceData.serviceCategoryId,
                  categoryName: findServiceData.serviceCategoryName,
                  serviceQuantity: item.serviceQuantity,
                  mdServiceUnitType: item.mdServiceUnitType,
                };
              return null;
            }
          );

          setSelectedServices(uniqueServiceCategories.filter((data: any) => !isEmpty(data)));
          setLoader(false);

          const facilityAccessResponse = await CustomerApiService.getFacilityAccess(customerId, facilityId);

          const usersResponse = await OrganizationService.getAllUsers(customerId);
          if (usersResponse.errors.length === 0 && facilityAccessResponse?.data.length) {
            const contactPerson = usersResponse?.data.find(
              (user: any) => user.userId === facilityAccessResponse?.data[0].accessContactPerson
            );
            setFacilityAccess({
              ...facilityAccessResponse?.data[0],
              accessContactPersonName: contactPerson?.username || '',
            });
          }
          setIsAccessLoading(false);
        }
      }
    } catch (e) {
      console.error('Error', e);
      return [];
    }
  }, []);

  const fetchVendorDetails = useCallback(async (vendorId: string) => {
    const vendorResponse = await JobScheduleServices.vendorDetails(vendorId);
    if (vendorResponse?.errors?.length) {
      setSnackbarMessage(`Error occurred`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } else {
      return vendorResponse?.data?.vendorCompanyName;
    }
    return null;
  }, []);

  const fetchJobs = useCallback(async () => {
    try {
      let jobsResponse = await JobCreateApiService.jobsV2({
        customerId,
        facilityId,
      });
      if (jobsResponse?.metaData?.totalCount > NUMBER_OF_ITEMS_PER_PAGE) {
        jobsResponse = await JobCreateApiService.jobsV2({
          customerId,
          facilityId,
          limit: jobsResponse?.metaData?.totalCount,
        });
      }
      if (jobsResponse?.data && jobsResponse?.errors.length === 0) {
        const today = moment().startOf('day');
        const filteredData = jobsResponse?.data
          ? jobsResponse?.data?.filter((jobItem: Job) => {
              const isValidStatus =
                jobItem.dataStatus === JobStatus.OpenToBid || jobItem.dataStatus === JobStatus.Awarded;
              const isFutureDate = moment(jobItem.stopDate).isAfter(today);
              return isValidStatus && isFutureDate;
            })
          : [];
        console.log('Filtered Data:', filteredData);
        return filteredData.map((jobItem: Job) => {
          return {
            startDate: jobItem.startDate,
            stopDate: jobItem.stopDate,
            jobId: jobItem.jobId,
            jobName: jobItem.jobName,
            dataStatus: jobItem.dataStatus,
            bidCount: jobItem?.bidCount,
            createdBy: jobItem?.createdByName,
            jobBids: jobItem.jobId,
            jobDueDates: jobItem.jobDueDates,
            jobType: jobItem.jobType,
          };
        });
      }
      return [];
    } catch (e) {
      console.error('Error fetching jobs:', e);
      return [];
    }
  }, [customerId, facilityId]);

  const fetchOngoingJobs = useCallback(async () => {
    try {
      let allJobResponse = await WorkOrdersApiService.getAllWorkOrders({
        customerId,
        status: WorkOrderStatus.InProgress,
      });
      if (allJobResponse?.metadata?.totalCount > NUMBER_OF_ITEMS_PER_PAGE) {
        allJobResponse = await WorkOrdersApiService.getAllWorkOrders({
          customerId,
          status: WorkOrderStatus.InProgress,
          limit: allJobResponse?.metadata?.totalCount,
        });
        if (allJobResponse?.errors.length) {
          setSnackbarMessage(`Error occured`);
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          return [];
        }
      }
      if (allJobResponse?.data?.length && allJobResponse?.errors?.length === 0) {
        const facilitySpecificJobs = allJobResponse?.data.filter(
          (job: { facilities: { facilityId: any }[] }) => job?.facilities[0]?.facilityId === facilityId
        );
        return await Promise.all(
          facilitySpecificJobs.map(async (job: WorkOrder) => {
            const { name } = job;
            return {
              jobName: name,
              type: job.status,
              startDate: job.jobStartDate,
              stopDate: job.jobEndDate,
              jobSize: 'M',
              isAwardedJob: true,
              jobId: job?.jobId ?? '',
              vendorId: job?.vendorId ?? '',
              vendorName: await fetchVendorDetails(job?.vendorId ?? ''),
              vendorIcon: '',
              jobType: job.jobType,
              dataStatus: job.status,
              createdBy: job.createdBy,
            };
          })
        );
      }
      if (allJobResponse?.errors?.length && allJobResponse?.errors[0]?.message) {
        setSnackbarMessage(`Error occured`);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return [];
      }
      return [];
    } catch (e) {
      console.error('Error', e);
      return [];
    }
  }, []);

  const fetchAllJobs = useCallback(async () => {
    setJobsLoader(true);
    const [upcomingJobs, ongoingJobs] = await Promise.all([fetchJobs(), fetchOngoingJobs()]);
    if (upcomingJobs?.length || ongoingJobs?.length) {
      const filteredUpcomingJobs = upcomingJobs.filter(
        (job: { jobId: any }) => !ongoingJobs.find((ongoingJob) => ongoingJob.jobId === job.jobId)
      );
      setAllJobs([...filteredUpcomingJobs, ...ongoingJobs]);
    }
    setJobsLoader(false);
  }, [fetchJobs, fetchOngoingJobs]);

  useEffect(() => {
    fetchFacilityDetails();
    fetchAllJobs();
  }, []);

  const formatDateCell = (cell: any) => {
    const cellValue = cell.getValue();
    if (cellValue) {
      return moment(cellValue).format('MM/DD/YYYY');
    }
    return '-';
  };

  const renderStatusCell = (renderedCellValue: string | React.ReactNode | undefined) => {
    const selectedColor = theme.palette.text.secondary;

    if (typeof renderedCellValue === 'string') {
      return <span style={{ color: selectedColor }}>{DATA_STATUS[renderedCellValue as keyof typeof DATA_STATUS]}</span>;
    }

    if (isValidElement(renderedCellValue)) {
      return renderedCellValue;
    }

    return '-';
  };

  const jobsColumns = useMemo<MRT_ColumnDef<IJobOverview | TaskData | IJobs | IBidList | IProfileCardProps>[]>(
    () => [
      {
        accessorKey: 'jobName',
        header: t('tableHeader:jobName'),
        enableSorting: false,
        enableColumnFilter: true,
        muiEditTextFieldProps: {
          required: true,
          type: 'number',
          variant: 'outlined',
        },
      },
      {
        accessorKey: 'startDate',
        header: t('tableHeader:startDate'),
        enableSorting: false,
        enableColumnFilter: true,
        size: 178,
        filterVariant: 'date',
        filterFn: (row, _columnIds, filterValue) => {
          const formattedValue = moment(row.getValue('jobStartDate')).format('MM/DD/yyyy');
          const filteredValue = moment(filterValue.$d).format('MM/DD/yyyy');
          return formattedValue.includes(filteredValue);
        },
        Cell: ({ cell }) => formatDateCell(cell),
        enableColumnActions: false,
        enableHiding: false,
      },
      {
        accessorKey: 'stopDate',
        header: t('tableHeader:stopDate'),
        enableSorting: false,
        enableColumnFilter: true,
        size: 178,
        filterFn: (row: any, _columnIds: any, filterValue: any) => {
          const formattedValue = moment(row.getValue('jobEndDate')).format('MM / DD / yyyy');
          return formattedValue.toLowerCase().includes(filterValue.toLowerCase());
        },
        Cell: ({ cell }: any) => formatDateCell(cell),
        enableColumnActions: false,
        enableHiding: false,
      },
      {
        accessorKey: 'dataStatus',
        header: t('tableHeader:dataStatus'),
        enableSorting: false,
        enableColumnFilter: false,
        enableColumnActions: true,
        sortingFn: 'alphanumericCaseSensitive',
        size: 142,
        Cell: ({ renderedCellValue }) => renderStatusCell(renderedCellValue),
      },
      {
        accessorKey: 'createdBy',
        header: t('tableHeader:createdBy'),
        enableSorting: false,
        enableColumnFilter: false,
        enableColumnActions: true,
        sortingFn: 'alphanumericCaseSensitive',
        size: 142,
      },
    ],
    [t]
  );

  return (
    <>
      <PageTitle title={t('viewFacilityDetails:viewFacilityDetails')} />
      <CustomerContainer>
        <Box sx={{ display: 'flex', gap: '1.5rem', flexDirection: 'column' }}>
          <ModifyField>
            <HeadingText sx={{ display: 'flex', flexWrap: 'nowrap', whiteSpace: 'nowrap' }}>
              {t('viewFacilityDetails:facilityName')}
            </HeadingText>
            <Divider orientation="horizontal" sx={{ width: '70%', color: 'red' }} />
            {isLoading ? (
              <Box sx={{ opacity: 0.5 }}>
                <img src={Icons.EditBlackIcon} alt="Edit icon disabled" />
              </Box>
            ) : (
              <Linking to={{ pathname: `/add-facility` }} state={{ ...facilityDetails, addressExists: true }}>
                <Box sx={{ cursor: 'pointer' }}>
                  <img src={Icons.EditBlackIcon} alt="Edit icon" />
                </Box>
              </Linking>
            )}
          </ModifyField>
          {isLoading ? (
            <Loader>
              <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
            </Loader>
          ) : (
            <FacilityNameComponent facilityDetails={facilityDetails} selectedServices=""></FacilityNameComponent>
          )}

          <ModifyField>
            <HeadingText sx={{ display: 'flex', flexWrap: 'nowrap', whiteSpace: 'nowrap' }}>
              {t('viewFacilityDetails:facilityDetails')}
            </HeadingText>
            <Divider orientation="horizontal" sx={{ width: '64%', color: 'red' }} />
            {isLoading ? (
              <Box sx={{ opacity: 0.5 }}>
                <img src={Icons.EditBlackIcon} alt="Edit icon disabled" />
              </Box>
            ) : (
              <Linking to={{ pathname: `/add-facility-details` }} state={{ ...facilityDetails }}>
                <Box sx={{ cursor: 'pointer' }}>
                  <img src={Icons.EditBlackIcon} alt="Edit icon" />
                </Box>
              </Linking>
            )}
          </ModifyField>
          {isLoading ? (
            <Loader>
              <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
            </Loader>
          ) : (
            <LabelWrapperComponent facilityDetails={facilityDetails} selectedServices=""></LabelWrapperComponent>
          )}
          <ModifyField>
            <HeadingText sx={{ display: 'flex', flexWrap: 'nowrap', whiteSpace: 'nowrap' }}>
              {t('viewFacilityDetails:facilityAccess')}
            </HeadingText>
            <Divider orientation="horizontal" sx={{ width: '70%', color: 'red' }} />
            {isAccessLoading ? (
              <Box sx={{ opacity: 0.5 }}>
                <img src={Icons.EditBlackIcon} alt="Edit icon disabled" />
              </Box>
            ) : (
              <Linking to={{ pathname: `/add-access-facility` }} state={{ ...facilityAccess, ...facilityDetails }}>
                <Box sx={{ cursor: 'pointer' }}>
                  <img src={Icons.EditBlackIcon} alt="Edit icon" />
                </Box>
              </Linking>
            )}
          </ModifyField>
          {isAccessLoading ? (
            <Loader>
              <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
            </Loader>
          ) : (
            <FacilityAccessComponent
              facilityAccess={facilityAccess}
              selectedServices=""
              facilityDetails=""></FacilityAccessComponent>
          )}
          <HeadingText>{t('viewFacilityDetails:jobSpecifications')}</HeadingText>
          {loader ? (
            <Loader>
              <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
            </Loader>
          ) : (
            <FacilityContainer sx={{ background: theme.palette.common.white, borderRadius: '1rem', gap: '1rem' }}>
              <HeadingText sx={{ margin: '1.5rem 0 0 1.5rem', fontSize: '18px' }}>
                {t('viewFacilityDetails:servicesAddedTillDate')}
              </HeadingText>
              <LabelWrapperComponent facilityDetails="" selectedServices={selectedServices}></LabelWrapperComponent>
            </FacilityContainer>
          )}
          {/* <HeadingText>{t('viewFacilityDetails:usersAssignedToThisFacility')}</HeadingText>
          {usersLoader ? (
            <Loader>
              <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
            </Loader>
          ) : (
            // <DataGrid
            //   columns={userColumns}
            //   data={users}
            //   enableRowSelect={false}
            //   enableColumnPinning={false}
            //   sortingObj={sorting}
            //   sortingFns={{
            //     myCustomSortingFn: myCustomSortingFn,
            //   }}
            // />
            <UsersList renderCell={renderCell} />
          )} */}
          <HeadingText>{t('viewFacilityDetails:jobsForThisFacility')}</HeadingText>
          {jobsLoader ? (
            <Loader>
              <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
            </Loader>
          ) : (
            <DataGrid
              columns={jobsColumns}
              data={allJobs}
              enableRowSelect={false}
              enableColumnPinning={false}
              errorMessageTitle={t('noData:noDataYet')}
              errorMessageDescription={t('noData:comeBackLater')}
            />
          )}
        </Box>
        <SnackbarMessage
          open={snackbarOpen}
          successMessage={snackbarMessage}
          errorMessage={snackbarMessage}
          severity={snackbarSeverity}
          onClose={() => setSnackbarOpen(false)}
        />
      </CustomerContainer>
    </>
  );
};

export default ViewFacilityDetails;
