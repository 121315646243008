/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

/* eslint-disable sonarjs/cognitive-complexity */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Button, CircularProgress, Divider } from '@mui/material';
import _, { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import {
  AccessOptions,
  AssignmentCardType,
  RolesEnum,
  fetchDataFromS3Bucket,
  formatDate,
  getAccessFacilityLockKeyLabel,
  getAuthData,
  getStoredCustomerDetails,
  getTimeFormat,
  removeNonValueFromStringArray,
} from '../../../../Shared/Utilities/utils';
import {
  JobType,
  BooleanType,
  PreShiftChecklistTemplate,
  PreShiftChecklistTemplateInput,
  PreShiftChecklistTemplateItem,
  PreShiftChecklistTemplateItemActionType,
  PreShiftChecklistTemplateItemInput,
  PreShiftChecklistTemplateItemQuantityType,
  UserForUserManagement,
  WorkOrderService,
  WorkOrderStatus,
  WorkOrderTaskSchedule,
  WorkOrderYesNoType,
} from '../../../../API';
import CustomerApiService from '../../../Customer/Services/CustomerService';
import FacilityApiService from '../../../Facility/Services/FacilityApiService';
import MasterDataApiService from '../../../../Shared/Services/MasterDataService';
import WorkOrdersApiService from '../../Services/WorkOrdersService';

import { IUser, MasterData, UserType } from '../../../../Shared/Constants/App';
import { IAccessFacilityData } from './ShowFacilityDetails';
import { IVendorProofOfCompletion } from './TasksRequireProofs';
import { IDataProps } from './PreShiftCheckList';
import { AuthenticationService } from '../../../../Shared/Services/AuthenticationService';
import JobAssignmentCard, { ITeamMemberData, IWorkOrderTeam } from './JobAssignmentCard';

import AddItem from './AddItem';
import ModifyPopUp from './ModifyPopUp';
import ConfirmPopup from './ConfirmPopup';
import SnackbarMessage from '../../../Onboarding/Components/Login/SnackbarMessage';
import theme from '../../../../Shared/Themes/theme';

import { Icons } from '../../../../Shared/Constants/Icons';

import {
  AssignJobText,
  AwardJobText,
  CardContainer,
  DetailsContainer,
  FacilityDetailsContainer,
  Match,
  MatchText,
  TimeAndInstructions,
  TitleCard,
  buttonStyles,
} from './OneTimeJobDetails.Style';
import WarningMessage from '../../../../Shared/Components/Common/WarningMessage/WarningMessage';
import moment from 'moment';

const behalfOfAssociate = false;
const isJobPunchedOut = false;

interface ShiftDetails {
  shiftDetailId: string;
  startTime: string;
  endTime: string;
  workOrderId?: string;
  punchInGraceMinutes?: number | null;
  punchOutGraceMinutes?: number | null;
}

export type BidSummary = {
  bidVersion: number | null;
  isCustomerApprovalAvailable: boolean;
};

export type ServiceCategoryV3 = {
  taskId: string;
  serviceCategoryId: string;
  serviceCategoryName: string;
  categoryImageUrl?: string | null;
  categoryDescription?: string | null;
  orgId: string;
  mdServiceType: string;
  taskName: string;
  taskImageUrl?: string | null;
  taskDescription?: string | null;
  unit?: string | null;
  mdAreaType?: string | null;
  modifiedBy?: string | null;
  createdBy?: string | null;
};

const AwardJob = ({ jobData }: any) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [startJobOpen, setStartJobOpen] = useState(false);
  const [startJobLoading, setStartJobLoading] = useState(false);

  const { t } = useTranslation(['assignJob', 'vendor']);
  const { divider } = buttonStyles;
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [isCardOpen, setIsCardOpen] = useState<boolean>(false);
  const [assignmentType, setAssignmentType] = useState<AssignmentCardType>();
  const { role, isDistrictManager } = getAuthData();
  const [isOnlyPrimeVendor, setIsOnlyPrimeVendor] = useState<boolean>(false);
  const [isOnlySubVendor, setIsOnlySubVendor] = useState<boolean>(false);
  const [isSelfPerformJob, setIsSelfPerformJob] = useState<boolean>(false);
  const [workOrderId, setWorkOrderId] = useState<string>('');
  const [subWorkOrderId, setSubWorkOrderId] = useState<string>('');
  const [shiftTimings, setShiftTimings] = useState<any[]>([]);
  const [shiftOriginalTimings, setShiftOriginalTimings] = useState<ShiftDetails[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [workOrderData, setWorkOrderData] = useState<any>({});
  const [workOrderStatus, setWorkOrderStatus] = useState<any>();
  const [hasChild, setHasChild] = useState<boolean>(false);
  const [originalTeamData, setOriginalTeamData] = useState<ITeamMemberData[]>([]);
  const [teamData, setTeamData] = useState<ITeamMemberData[]>([]);
  const [checkedSupervisors, setCheckedSupervisors] = useState<any>([]);
  const [isTeamDataLoading, setIsTeamDataLoading] = useState<boolean>(false);
  const [checkedAssociates, setCheckedAssociates] = useState<any>([]);
  const [originalAssociateTeamData, setOriginalAssociateTeamData] = useState<ITeamMemberData[]>([]);
  const [associateTeamData, setAssociateTeamData] = useState<ITeamMemberData[]>([]);
  const [facilityAccessData, setFacilityAccessData] = useState<IAccessFacilityData[] | []>([]);
  const [proofOfServiceAndTaskData, setProofOfServiceAndTaskData] = useState<IVendorProofOfCompletion[]>([]);
  const [checkListData, setCheckListData] = useState<IDataProps[]>([]);
  const [isShiftDetailsLoading, setIsShiftDetailsLoading] = useState<boolean>(false);
  const [isShiftDeleting, setIsShiftDeleting] = useState<boolean>(false);
  const [isAssignTeamLoading, setIsAssignTeamLoading] = useState<boolean>(false);
  const [isFacilityAccessLoading, setIsFacilityAccessLoading] = useState<boolean>(false);
  const [isTaskRequireProofLoading, setIsTasksRequireProofLoading] = useState<boolean>(false);
  const [isPreShiftChecklistLoading, setIsPreShiftChecklistLoading] = useState<boolean>(false);
  const [addItemCardOpen, setAddItemCardOpen] = useState<boolean>(false);
  const [addItemData, setAddItemData] = useState<any>({});
  const [selectedCatalogue, setSelectedCatalogue] = useState('');
  const [isCompleteJobLoading, setIsCompleteJobLoading] = useState<boolean>(false);
  const isAssociate = (role === UserType.Associate || behalfOfAssociate) && !isJobPunchedOut;
  const isVendor = role === UserType.Vendor && !behalfOfAssociate && !isJobPunchedOut;
  const [isAssociatePunchedIn, setIsAssociatePunchedIn] = useState(false);
  const [openShiftTimgsCheck, setOpenShiftTimingsCheck] = useState<boolean>(false);
  const [openDeleteShiftWarning, setOpenDeleteShiftWarning] = useState<boolean>(false);
  const [selectedShiftId, setSelectedShiftId] = useState<string>('');
  const isOtjJob = !!jobData?.jobDetails?.jobCustodian;
  const [disableStartJobResult, setDisableStartJobResult] = useState<boolean>(false);
  const [openStartJobWarning, setOpenStartJobWarning] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleTogglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleStartJobPopup = () => {
    if (disableStartJobResult) {
      setOpenStartJobWarning(true);
    } else {
      setStartJobOpen(!startJobOpen);
    }
  };

  const handleOnClick = async (type: AssignmentCardType) => {
    setAssignmentType(type);
    setIsCardOpen(true);
  };

  const handleOnClose = async (type: AssignmentCardType) => {
    setIsCardOpen(false);
    if (type === AssignmentCardType.ShiftTimings) {
      await getWorOrderDetailsByWorkOrderId(subWorkOrderId ? subWorkOrderId : workOrderId);
      await fetchShiftDetails(workOrderId);
      setOpenShiftTimingsCheck(false);
    } else if (type === AssignmentCardType.AssignTeam) {
      setIsAssignTeamLoading(true);
      await getWorOrderDetailsByWorkOrderId(subWorkOrderId ? subWorkOrderId : workOrderId);
      await fetchVendorTeam(workOrderId);
      await fetchAssociateTeam(workOrderId);
      setIsAssignTeamLoading(false);
    } else if (type === AssignmentCardType.ProofOfCompletionTasks) {
      fetchAllWorkOrderData(workOrderId, subWorkOrderId || '');
    } else if (type === AssignmentCardType.PreShiftChecklist) {
      fetchPreShiftChecklistTemplateForVendor(workOrderId, subWorkOrderId || '');
    }
  };

  const fetchShiftDetails = async (workOrderId: string) => {
    setIsShiftDetailsLoading(true);
    try {
      const shiftDetailsResponse = await WorkOrdersApiService.getallShiftDetails(workOrderId);
      if (shiftDetailsResponse.data.length) {
        setShiftTimings(shiftDetailsResponse.data);
        setShiftOriginalTimings(shiftDetailsResponse.data);
      } else if (shiftDetailsResponse.errors.length) {
        setSnackbarMessage(shiftDetailsResponse.errors[0].message);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
      setIsShiftDetailsLoading(false);
    } catch (e) {
      setIsShiftDetailsLoading(false);
      console.error('Errors', e);
    }
  };

  const handleApiError = () => {
    setSnackbarMessage('Error occurred while updating Shift Timing');
    setSnackbarSeverity('error');
    setSnackbarOpen(true);
  };

  const handleShiftTimings = async (data: any, shiftTimings: ShiftDetails[]) => {
    setIsLoading(true);
    try {
      const { startDate, endDate } = data;
      const shiftStartDate = new Date(data.shiftStartDate).toISOString();
      const shiftEndDate = new Date(data.shiftEndDate).toISOString();
      const combinedShiftTiming = shiftOriginalTimings.concat([...shiftTimings]);
      const uniqueShiftTiming = combinedShiftTiming.reduce((acc: ShiftDetails[], current: ShiftDetails) => {
        const matchedTime = acc.find(
          (item: ShiftDetails) => item?.startTime === current?.startTime && item?.endTime === current?.startTime
        );
        if (!matchedTime) {
          return acc.concat([current]);
        }
        return acc;
      }, []);

      let isFullfilmentDateUpdate = false;
      if (!isOtjJob) {
        const payloadForRecurringJob = {
          workOrderId,
          actualStartDate: startDate,
          actualEndDate: endDate,
          jobStartDate: startDate,
          jobEndDate: endDate,
        };
        const payload = {
          workOrderId,
          actualStartDate: shiftStartDate,
          actualEndDate: shiftEndDate,
          jobStartDate: startDate || shiftStartDate,
          jobEndDate: endDate || shiftEndDate,
        };
        const workOrderResponse = await WorkOrdersApiService.updateWorkOrderStatus(
          jobData.jobType === JobType.Recurring ? payloadForRecurringJob : payload
        );
        if (workOrderResponse?.errors.length) {
          setSnackbarMessage(workOrderResponse?.errors[0].message);
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          setIsLoading(false);
          setIsCardOpen(false);
        } else {
          isFullfilmentDateUpdate = true;
        }
      } else {
        let payload: any;
        if (isDistrictManager && (isOnlyPrimeVendor || isSelfPerformJob)) {
          if (subWorkOrderId) {
            payload = {
              workOrderId: subWorkOrderId,
              actualStartDate: shiftStartDate,
              actualEndDate: shiftEndDate,
              jobStartDate: startDate || shiftStartDate,
              jobEndDate: endDate || shiftEndDate,
            };
            const workOrderResponseForSubJob = await WorkOrdersApiService.updateWorkOrderStatus(payload);
            if (workOrderResponseForSubJob?.errors.length) {
              console.log('workOrderResponseForSubJob?.errors', workOrderResponseForSubJob?.errors);
              setSnackbarMessage(workOrderResponseForSubJob?.errors[0].message);
              setSnackbarSeverity('error');
              setSnackbarOpen(true);
              setIsLoading(false);
              setIsCardOpen(false);
            } else {
              const mainWorkOrderPayload = {
                workOrderId,
                actualStartDate: shiftStartDate,
                actualEndDate: shiftEndDate,
                jobStartDate: startDate || shiftStartDate,
                jobEndDate: endDate || shiftEndDate,
              };
              const workOrderResponse = await WorkOrdersApiService.updateWorkOrderStatus(mainWorkOrderPayload);
              if (workOrderResponse?.errors.length) {
                setSnackbarMessage(workOrderResponse?.errors[0]?.message?.errors[0].message);
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                setIsLoading(false);
                setIsCardOpen(false);
              } else {
                isFullfilmentDateUpdate = true;
              }
            }
          } else {
            payload = {
              workOrderId,
              actualStartDate: shiftStartDate,
              actualEndDate: shiftEndDate,
              jobStartDate: startDate || shiftStartDate,
              jobEndDate: endDate || shiftEndDate,
            };

            const workOrderResponse = await WorkOrdersApiService.updateWorkOrderStatus(payload);
            if (workOrderResponse.errors.length) {
              isFullfilmentDateUpdate = false;
              console.log('workOrderResponse.errors', workOrderResponse.errors);
              setSnackbarMessage(workOrderResponse?.errors[0].message);
              setSnackbarSeverity('error');
              setSnackbarOpen(true);
              setIsLoading(false);
              setIsCardOpen(false);
            } else {
              isFullfilmentDateUpdate = true;
            }
          }
        }
      }
      if (subWorkOrderId) {
        const addedShiftTimingResponseForSubJob = await WorkOrdersApiService.addShiftDetails(
          subWorkOrderId,
          uniqueShiftTiming
        );
        if (!isEmpty(addedShiftTimingResponseForSubJob?.data) && isEmpty(addedShiftTimingResponseForSubJob?.errors)) {
          const addedShiftTimingResponse = await WorkOrdersApiService.addShiftDetails(workOrderId, uniqueShiftTiming);
          if (!isEmpty(addedShiftTimingResponse?.data) && isEmpty(addedShiftTimingResponse?.errors)) {
            getWorOrderDetailsByWorkOrderId(subWorkOrderId);

            setSnackbarMessage(`Successfully added shift timings`);
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            setIsLoading(false);
            setIsCardOpen(false);
            fetchShiftDetails(subWorkOrderId);
          } else {
            setSnackbarMessage(addedShiftTimingResponse?.errors[0]?.message);
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            setIsLoading(false);
            setIsCardOpen(false);
          }
        } else {
          setSnackbarMessage(addedShiftTimingResponseForSubJob?.errors[0]?.message);
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          setIsLoading(false);
          setIsCardOpen(false);
        }
      } else {
        const addedShiftTimingResponse = await WorkOrdersApiService.addShiftDetails(workOrderId, uniqueShiftTiming);
        if (!isEmpty(addedShiftTimingResponse.data) && isFullfilmentDateUpdate) {
          getWorOrderDetailsByWorkOrderId(workOrderId);
          setSnackbarMessage(`Successfully added shift timings`);
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
          setIsLoading(false);
          setIsCardOpen(false);
          fetchShiftDetails(workOrderId);
        } else if (addedShiftTimingResponse.errors.length) {
          setSnackbarMessage(addedShiftTimingResponse?.errors[0]?.message);
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          setIsLoading(false);
          setIsCardOpen(false);
        }
      }
    } catch (e: any) {
      console.log('Error:', e);
      handleApiError();
      setIsLoading(false);
      setIsCardOpen(false);
    }
  };

  const deleteShift = async (id: string) => {
    setIsShiftDeleting(true);
    const deleteShiftTimings = shiftTimings.filter((shift) => shift.shiftDetailId !== id);
    const payload = subWorkOrderId || workOrderId;
    const deletedShiftResponse = await WorkOrdersApiService.addShiftDetails(payload, deleteShiftTimings);
    if (isEmpty(deletedShiftResponse.errors)) {
      const shiftDetailsResponse = await WorkOrdersApiService.getallShiftDetails(subWorkOrderId || workOrderId);
      getWorOrderDetailsByWorkOrderId(subWorkOrderId || workOrderId);
      fetchShiftDetails(subWorkOrderId || workOrderId);
      setShiftTimings(shiftDetailsResponse.data);
      setShiftOriginalTimings(shiftDetailsResponse.data);
      setIsShiftDeleting(false);
    } else {
      setSnackbarMessage(deletedShiftResponse?.errors[0]?.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      setIsShiftDeleting(false);
    }
  };

  const getWorOrderDetailsByWorkOrderId = async (workOrderId: string) => {
    const payload = { workOrderId: workOrderId };
    const workOrderResponseByWorkOrderId = await WorkOrdersApiService.getAllWorkOrders(payload);
    if (workOrderResponseByWorkOrderId.data && workOrderResponseByWorkOrderId.errors.length === 0) {
      setWorkOrderData(workOrderResponseByWorkOrderId.data[0]);
      setWorkOrderStatus(workOrderResponseByWorkOrderId?.data[0]?.status);
      return true;
    } else {
      return false;
    }
  };

  const getPunchInDetails = async (workOrderId: string) => {
    const workOrderTeamResponse = await WorkOrdersApiService.getWorkOrderTeam(workOrderId);
    const associateDetails = workOrderTeamResponse.data.find((member: any) => !member.isSupervisor);
    const workDayId = associateDetails?.workDayTeam?.workDayId;
    if (workDayId) {
      const latestPunchInOutResponse = await WorkOrdersApiService.getLatestPunchRecords(workDayId);
      if (!isEmpty(latestPunchInOutResponse?.data) && isEmpty(latestPunchInOutResponse?.data?.errorCode)) {
        setIsAssociatePunchedIn(!!latestPunchInOutResponse?.data?.punchType);
      }
    }
  };

  const getWorkOrderDetails = async () => {
    const workorderResponse = await WorkOrdersApiService.getAllWorkOrders({ jobId: jobData.jobDetails.jobId });
    if (workorderResponse?.data && workorderResponse?.errors?.length === 0) {
      const workOrderData = workorderResponse?.data[0];
      setWorkOrderId(workOrderData?.workOrderId);
      if (workOrderData?.subWorkOrders?.length) {
        setSubWorkOrderId(workOrderData?.subWorkOrders[0]?.workOrderId || '');
      }
      await getPunchInDetails(workOrderData?.workOrderId);
      await getWorOrderDetailsByWorkOrderId(
        workOrderData?.subWorkOrders?.length ? workOrderData?.subWorkOrders[0]?.workOrderId : workOrderData?.workOrderId
      );
      fetchShiftDetails(
        workOrderData?.subWorkOrders?.length ? workOrderData?.subWorkOrders[0]?.workOrderId : workOrderData?.workOrderId
      );
      const isMainJob = workOrderData?.hasChild;
      setHasChild(workOrderData?.hasChild);
      if (!isEmpty(workOrderData?.jobCustodian)) {
        if (isMainJob && !workOrderData?.parentWorkOrderId) {
          setIsOnlyPrimeVendor(true);
        } else if (!isMainJob && workOrderData?.parentWorkOrderId) {
          setIsOnlySubVendor(true);
        } else {
          setIsSelfPerformJob(true);
        }
      }
      fetchWorkOrderFacilityAccessInfo(workOrderData?.workOrderId);
      fetchAllWorkOrderData(workOrderData?.workOrderId, workOrderData?.subWorkOrders[0]?.workOrderId || '');
      fetchPreShiftChecklistTemplateForVendor(
        workOrderData?.workOrderId,
        workOrderData?.subWorkOrders[0]?.workOrderId || ''
      );
      setIsAssignTeamLoading(true);
      await fetchVendorTeam(workOrderData?.workOrderId);
      await fetchAssociateTeam(workOrderData?.workOrderId);
      setIsAssignTeamLoading(false);
    }
  };

  useEffect(() => {
    getWorkOrderDetails();
  }, []);

  const disableStartJob = async () => {
    try {
      const workOrderTeamResponse = await WorkOrdersApiService.getWorkOrderTeam(workOrderId);
      if (workOrderTeamResponse.data && isEmpty(workOrderTeamResponse.errors) && !isEmpty(shiftTimings)) {
        if (jobData.jobDetails.jobType === JobType.Recurring) {
          return false;
        } else {
          const actualStartDate = new Date(shiftTimings[0].workOrder.actualStartDate);
          const actualEndDate = new Date(shiftTimings[0].workOrder.actualEndDate);
          return !workOrderTeamResponse.data
            .filter((data: any) => !data.isSupervisor)
            .every((data: any) => {
              const associateStartDate = new Date(data.associateStartDate);
              const associateEndDate = new Date(data.associateEndDate);
              const formattedAssociateStartDate = formatDate(associateStartDate.toISOString());
              const formattedAssociateEndDate = formatDate(associateEndDate.toISOString());
              return (
                moment(formattedAssociateStartDate).isSameOrAfter(actualStartDate) &&
                moment(formattedAssociateEndDate).isSameOrBefore(actualEndDate)
              );
            });
        }
      }
      return false;
    } catch (error) {
      console.error('Error disabling start job:', error);
      return false;
    }
  };

  const handleStartJob = async () => {
    try {
      setStartJobLoading(true);
      const workOrderResponse = await WorkOrdersApiService.updateWorkOrderStatus({
        workOrderId: workOrderId,
        status: WorkOrderStatus.InProgress,
      });
      let subWorkOrderResponse = { data: {}, errors: [] };
      if (subWorkOrderId) {
        subWorkOrderResponse = await WorkOrdersApiService.updateWorkOrderStatus({
          workOrderId: subWorkOrderId,
          status: WorkOrderStatus.InProgress,
        });
      }
      if (
        subWorkOrderId
          ? !isEmpty(workOrderResponse?.data) &&
            isEmpty(workOrderResponse?.errors) &&
            !isEmpty(subWorkOrderResponse?.data) &&
            isEmpty(subWorkOrderResponse?.errors)
          : !isEmpty(workOrderResponse?.data) && isEmpty(workOrderResponse?.errors)
      ) {
        setWorkOrderStatus(workOrderResponse?.data?.updateWorkOrderStatus?.status);
        setStartJobLoading(false);
        setSnackbarMessage(t('assignJob:confirmStartJobMessage'));
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setStartJobOpen(!startJobOpen);
      } else {
        setStartJobLoading(false);
        setSnackbarMessage(t('Error occurred'));
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (e) {
      setStartJobLoading(false);
      setSnackbarMessage('Error occurred');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const renderStartJobButton = () => {
    const currentDeviceTime = new Date();
    const targetDate = new Date(workOrderData?.jobStartDate);
    const checkData = teamData?.map((team) => team.isChecked);
    return (
      <>
        {(isSelfPerformJob || (role === UserType.Vendor && !isDistrictManager)) &&
        currentDeviceTime >= targetDate &&
        shiftTimings.length > 0 &&
        teamData.length &&
        checkData?.includes(true) &&
        workOrderStatus === WorkOrderStatus.Accepted ? (
          <>
            <Divider sx={divider} />
            <TitleCard onClick={handleStartJobPopup}>{t('assignJob:startJob')}</TitleCard>
          </>
        ) : null}
      </>
    );
  };

  const fetchVendorTeam = async (
    workOrderId: string,
    pageNumber?: number,
    limit?: number,
    isPaginationDataLoader?: boolean,
    searchString?: string
  ) => {
    const customerDetails: any = getStoredCustomerDetails();
    const vendorId = customerDetails?.customerId;
    const { userId } = getAuthData();
    if (userId || vendorId) {
      setIsTeamDataLoading(true);
      try {
        const userDetails = await AuthenticationService.getUserByCognitoUserId(userId);

        const vendorTeamResponse = isDistrictManager
          ? await WorkOrdersApiService.getVendorTeam(
              userDetails?.data?.userId,
              '',
              BooleanType.False,
              searchString || '',
              pageNumber,
              limit,
              true
            )
          : await CustomerApiService.getAllUsers(vendorId, '', pageNumber, limit, BooleanType.False, true);
        if (vendorTeamResponse.errors.length) {
          setSnackbarMessage(vendorTeamResponse?.errors[0]?.message);
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          setIsTeamDataLoading(false);
          return;
        }
        const workOrderTeamResponse = await WorkOrdersApiService.getWorkOrderTeam(workOrderId);
        if (workOrderTeamResponse.errors.length) {
          setSnackbarMessage(workOrderTeamResponse?.errors[0]?.message);
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          return;
        }
        const vendorTeam = vendorTeamResponse?.data ?? [];
        const vendorTeamsUsersExcludingAssociates: UserForUserManagement[] = isDistrictManager
          ? vendorTeam
          : vendorTeam.filter((user: any) => {
              return user?.roles[0]?.roleName !== RolesEnum.AssociateTechnician;
            });
        if (!isEmpty(vendorTeamsUsersExcludingAssociates)) {
          const tempCheckedMember: ITeamMemberData[] = [];
          const modifiedData = await Promise.all(
            vendorTeamsUsersExcludingAssociates?.map(async (member: UserForUserManagement) => {
              const memberProfileUrl = await fetchDataFromS3Bucket(
                navigate,
                `associate/${member?.userId}/profile-pictures/${member?.userId}`
              );
              const { roleName = '', roleId = '' } =
                member && !isEmpty(member?.roles) && member?.roles?.length ? member?.roles[0] || {} : {};
              const tempMember = {
                id: member.userId,
                name: member.username,
                distance: '1.2',
                tag: '',
                isChecked: false,
                profileImg: memberProfileUrl,
                skills: [],
                jobConflict: [],
                role: roleName,
                roleId,
                isSupervisor: true,
                teamId: '',
              };
              const workOrderTeamMember = workOrderTeamResponse?.data?.find(
                (teamMember: IWorkOrderTeam) => teamMember.userId === member.userId
              );
              if (workOrderTeamMember && Object.keys(workOrderTeamMember).length !== 0) {
                if (workOrderTeamMember.isSupervisor) {
                  tempCheckedMember.push({
                    ...tempMember,
                    isChecked: workOrderTeamMember.isSupervisor as boolean,
                    teamId: workOrderTeamMember.teamId || '',
                  });
                  return {
                    ...tempMember,
                    isChecked: workOrderTeamMember.isSupervisor,
                    teamId: workOrderTeamMember.teamId || '',
                  };
                }
                return { ...tempMember, teamId: workOrderTeamMember.teamId || '' };
              }
              return tempMember;
            })
          );
          setCheckedSupervisors((prevData: any) => {
            const combinedArray = [...prevData, ...tempCheckedMember];
            return _.uniqBy(combinedArray, 'id');
          });
          setTeamData([...modifiedData]);
          setOriginalTeamData([...modifiedData]);

          // setTeamData((prevState) => [...prevState, ...modifiedData]);
          // setOriginalTeamData((prevState) => [...prevState, ...modifiedData]);
          setIsTeamDataLoading(false);
        } else {
          setTeamData([]);
          setOriginalTeamData([]);
          setIsTeamDataLoading(false);
        }
      } catch (e) {
        console.error('Error', e);
        setTeamData([]);
        setOriginalTeamData([]);
        setIsTeamDataLoading(false);
      }
    }
  };

  // filters associate with shortlisted supervisor
  const filterAssociates = (associateData: ITeamMemberData[]) => {
    return associateData.filter((member: IUser) => {
      const shortlisted = checkedSupervisors.some((supervisor: any) => supervisor.id === member.userId);
      return !shortlisted;
    });
  };

  const fetchAssociateTeam = async (
    workOrderId: string,
    pageNumber?: number,
    limit?: number,
    isPaginationDataLoader?: boolean,
    searchString?: string
  ) => {
    const customerDetails = getStoredCustomerDetails();
    const vendorId = customerDetails?.customerId;
    const { userId } = getAuthData();
    const onBehalfOfAssociate = false;
    if (userId || vendorId) {
      setIsTeamDataLoading(true);
      try {
        const userDetails = await AuthenticationService.getUserByCognitoUserId(userId);
        const vendorTeamResponse = isDistrictManager
          ? await WorkOrdersApiService.getVendorTeam(
              userDetails?.data?.userId,
              '',
              BooleanType.True,
              searchString || '',
              pageNumber,
              limit,
              true
            )
          : await CustomerApiService.getAllUsers(vendorId, '', pageNumber, limit, BooleanType.True, false);
        if (vendorTeamResponse.errors.length) {
          setSnackbarMessage(vendorTeamResponse?.errors[0]?.message);
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          setIsTeamDataLoading(false);
        }
        const workOrderTeamResponse = await WorkOrdersApiService.getWorkOrderTeam(workOrderId);
        if (workOrderTeamResponse.errors.length) {
          setSnackbarMessage(workOrderTeamResponse?.errors[0]?.message);
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          setIsTeamDataLoading(false);
          return;
        }
        if (vendorTeamResponse.data) {
          const tempCheckedMember: ITeamMemberData[] = [];
          const modifiedVendorTeam = await Promise.all(
            vendorTeamResponse?.data?.map(async (user: UserForUserManagement) => {
              const { roleName = '', roleId = '' } = !isEmpty(user?.roles) ? user?.roles?.[0] || {} : {};
              const associateDetails = await CustomerApiService.getAssociateDetailsByUserId(
                user?.cognitoUserId as string
              );
              const associateId = associateDetails?.data?.associateId ?? '';
              const memberProfileUrl = await fetchDataFromS3Bucket(
                navigate,
                `associate/${associateId}/profile-pictures/${associateId}`
              );

              return {
                id: user?.userId,
                userId: user?.userId,
                name: user?.username,
                distance: '1.2',
                tag: '',
                isChecked: false,
                profileImg: memberProfileUrl,
                skills: [],
                jobConflict: [],
                role: roleName,
                roleId,
                isSupervisor: false,
                isDisabled: false,
                isJobNotAccepted: false,
              };
            })
          );
          let filteredByRoleData = modifiedVendorTeam;
          if (!onBehalfOfAssociate) {
            filteredByRoleData = modifiedVendorTeam?.filter((item) => item?.role === RolesEnum.AssociateTechnician);
          }
          const filteredByAvailableAssociate = filterAssociates(filteredByRoleData);
          const modifiedData = await Promise.all(
            filteredByAvailableAssociate.map(async (member: any) => {
              const tempMember = { ...member };
              const workOrderTeamMember = workOrderTeamResponse?.data?.find(
                (teamMember: IWorkOrderTeam) => teamMember?.userId === member?.userId
              );
              if (workOrderTeamMember && Object.keys(workOrderTeamMember).length !== 0) {
                // If onBehalfOfAssociate is true, we need already selected supervisor also in disabled state (Associates on the job flow)
                if (onBehalfOfAssociate) {
                  tempCheckedMember.push({
                    ...tempMember,
                    isChecked: true,
                    isDisabled: onBehalfOfAssociate,
                    teamId: workOrderTeamMember.teamId || '',
                    isSupervisor: workOrderTeamMember.isSupervisor,
                  });
                  return {
                    ...tempMember,
                    isChecked: true,
                    isDisabled: onBehalfOfAssociate,
                    teamId: workOrderTeamMember.teamId || '',
                    isSupervisor: workOrderTeamMember.isSupervisor,
                  };
                }
                // If onBehalfOfAssociate is false, then we will filter out supervisor (Assign team flow)
                if (!workOrderTeamMember.isSupervisor) {
                  tempCheckedMember.push({
                    ...tempMember,
                    isChecked: true,
                    isDisabled: onBehalfOfAssociate,
                    teamId: workOrderTeamMember.teamId || '',
                  });
                  return {
                    ...tempMember,
                    isChecked: true,
                    isDisabled: onBehalfOfAssociate,
                    teamId: workOrderTeamMember.teamId || '',
                  };
                }
                return { ...tempMember, teamId: workOrderTeamMember.teamId || '' };
              }
              return tempMember;
            })
          );
          if (!onBehalfOfAssociate) {
            setCheckedAssociates([...tempCheckedMember]);
          }
          setAssociateTeamData([...modifiedData]);
          setOriginalAssociateTeamData([...modifiedData]);
          setIsTeamDataLoading(false);
        } else {
          setAssociateTeamData([]);
          setOriginalAssociateTeamData([]);
          setIsTeamDataLoading(false);
        }
      } catch (e) {
        console.error('Error', e);
        setAssociateTeamData([]);
        setOriginalAssociateTeamData([]);
        setIsTeamDataLoading(false);
      }
    }
  };

  const fetchWorkOrderFacilityAccessInfo = async (workOrderId: string) => {
    setIsFacilityAccessLoading(true);
    try {
      const customerDetails = getStoredCustomerDetails();
      const vendorId = customerDetails?.customerId;
      if (vendorId) {
        setIsLoading(true);
        const workOrderFacilityResponse = await WorkOrdersApiService.getAllWorkOrders({
          vendorId,
          isJobAccepted: true,
        });
        if (workOrderFacilityResponse.data.length) {
          const fetchFacility = workOrderFacilityResponse.data.find((value: any) => value.workOrderId === workOrderId);
          if (fetchFacility?.customerId && fetchFacility.facilities[0] && fetchFacility.facilities[0].facilityId) {
            const usersResponse = await CustomerApiService.getAllUsers(fetchFacility?.customerId);
            const facilityAccess = await CustomerApiService.getFacilityAccess(
              fetchFacility?.customerId,
              fetchFacility.facilities[0].facilityId
            );
            const facilityResponse = await FacilityApiService.facility(
              fetchFacility.facilities[0].facilityId,
              fetchFacility?.customerId
            );
            if (facilityResponse?.errors?.length) {
              setSnackbarMessage(facilityResponse?.errors[0]?.message);
              setSnackbarSeverity('error');
              setSnackbarOpen(true);
            }
            if (facilityAccess?.errors?.length) {
              setSnackbarMessage(facilityAccess?.errors[0]?.message);
              setSnackbarSeverity('error');
              setSnackbarOpen(true);
            }
            let contactPersonInfo: any = {};
            if (usersResponse?.data && facilityAccess?.data) {
              contactPersonInfo = usersResponse.data.find(
                (member: any) => member?.userId === facilityAccess.data[0]?.accessContactPerson
              );
            } else if (usersResponse.errors.length) {
              setSnackbarMessage(usersResponse?.errors[0]?.message);
              setSnackbarSeverity('error');
              setSnackbarOpen(true);
            }
            const tempFacilityInfo = !isEmpty(facilityAccess?.data)
              ? facilityAccess?.data.map((facility: any) => {
                  if (facility.accessInfo === AccessOptions.KeyCard) {
                    return {
                      location: returnCompleteAddress(facilityResponse?.data?.address ?? ''),
                      code: t('facility:keyCard'),
                      parking: facility.otherInformation || t('vendor:noParkingInformationFound'),
                      contactPerson: contactPersonInfo?.username || t('vendor:noContactPersonFound'),
                      contactPersonNumber: contactPersonInfo?.phoneNumber || '',
                      accessInfo: t('facility:keyCard'),
                    };
                  }
                  return {
                    location: returnCompleteAddress(facilityResponse?.data?.address ?? ''),
                    code: facility.accessCode || t('vendor:noAccessCode'),
                    parking: facility.otherInformation || t('vendor:noParkingInformationFound'),
                    contactPerson: contactPersonInfo?.username || t('vendor:noContactPersonFound'),
                    contactPersonNumber: contactPersonInfo?.phoneNumber || '',
                    accessInfo: getAccessFacilityLockKeyLabel(facility.accessInfo) || t('vendor:noAccessInfoFound'),
                  };
                })
              : [];
            setFacilityAccessData(tempFacilityInfo || []);
          }
        } else if (workOrderFacilityResponse?.errors?.length && workOrderFacilityResponse?.errors[0]?.message) {
          setSnackbarMessage(workOrderFacilityResponse?.errors[0]?.message);
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        }
      }
    } catch (e) {
      console.error('Errors', e);
    }
    setIsLoading(false);
    setIsFacilityAccessLoading(false);
  };

  const returnCompleteAddress = (address: any) => {
    // TODO: need to append just addressLine 1(facility unit) and addressLine 3(addressLine1)
    const { addressLine1, addressLine2 } = address;
    const addressLines = removeNonValueFromStringArray([addressLine1, addressLine2]);
    if (addressLines.some((line: string) => line)) {
      return addressLines.join(', ').replace(/,\s*$/, '');
    }
    return t('vendor:noLocationFound');
  };

  const renderFacilityAccessData = () => {
    return (
      <>
        <CardContainer>
          {facilityAccessData.map((data: any) => {
            const contactPersonName = `${data.contactPerson} ${
              data.contactPersonType ? `(${data.contactPersonType})` : ''
            }`;
            // const contactPersonCallName =
            //   data.contactPerson !== t('vendor:noContactPersonFound')
            //     ? `${t('common:call')} ${data.contactPerson}`
            //     : data.contactPerson;
            return (
              <>
                <AssignJobText className="TitleContainer">{t('assignJob:location')}</AssignJobText>
                <div className="contentFlex">
                  <img src={Icons.LocationIcon} />
                  <AssignJobText className="Name">{data?.location}</AssignJobText>
                </div>
                <AssignJobText className="TitleContainer">{data.accessInfo}:</AssignJobText>
                <div className="contentFlex">
                  <img src={Icons.OtpIcon} />
                  <AssignJobText className="Name">{data.code}</AssignJobText>
                </div>
                <AssignJobText className="TitleContainer">{t('assignJob:parkingAt')}</AssignJobText>
                <div className="contentFlex">
                  <img src={Icons.ParkingIcon} />
                  <AssignJobText className="Name">{data.parking}</AssignJobText>
                </div>
                <AssignJobText className="TitleContainer">{t('assignJob:contactPerson')}</AssignJobText>
                <div className="contentFlex">
                  <img src={Icons.AccountIcon} />
                  <AssignJobText className="Name">{contactPersonName}</AssignJobText>
                </div>
              </>
            );
          })}
        </CardContainer>
      </>
    );
  };

  // const handleContactPerson = (data: string) => {
  //   window.open(`tel:${data}`, '_self');
  // };

  const getTransformedServiceAndTasksData = (
    services: WorkOrderService[],
    allCategoryServices: ServiceCategoryV3[]
  ) => {
    const proofOfServiceAndTaskData = services?.map((service: WorkOrderService) => {
      const currentServiceDetails: ServiceCategoryV3 =
        allCategoryServices?.find(
          (allServiceCategory) =>
            allServiceCategory?.serviceCategoryId?.toUpperCase() === service?.mdServiceId?.toUpperCase()
        ) || ({} as ServiceCategoryV3);

      const totalProofRequiredTasks = (service?.taskSchedule ?? []).filter(
        (task: WorkOrderTaskSchedule | null): task is WorkOrderTaskSchedule =>
          task !== null && task.isProofOfCompletionRequired === WorkOrderYesNoType.Yes
      );

      const tasks =
        service?.taskSchedule?.map((task): any => {
          const serviceTaskDetails: ServiceCategoryV3 =
            allCategoryServices?.find(
              (allServiceCategory) =>
                task?.mdTaskId?.toUpperCase() === allServiceCategory.taskId?.toUpperCase() &&
                allServiceCategory?.serviceCategoryId?.toUpperCase() === service?.mdServiceId?.toUpperCase()
            ) || ({} as ServiceCategoryV3);

          return {
            id: serviceTaskDetails?.taskId,
            title: serviceTaskDetails.taskName,
            floorNumber: 'All', // TODO
            isProofOfCompletionRequired: task?.isProofOfCompletionRequired,
            taskScheduleId: task?.taskScheduleId,
          };
        }) || [];
      const allAreChecked: boolean = tasks.every(
        (task: any) => task.isProofOfCompletionRequired === WorkOrderYesNoType.Yes
      ) as boolean;
      return {
        id: service?.mdServiceId,
        category: 'floors',
        categoryTitle: currentServiceDetails?.serviceCategoryName || '',
        totalTasks: service?.taskSchedule?.length || 0,
        totalProofRequiredTasks: totalProofRequiredTasks?.length,
        options: tasks || [],
        isSelectAll: allAreChecked,
        serviceImage: currentServiceDetails?.categoryImageUrl || '',
      };
    });
    return (proofOfServiceAndTaskData as IVendorProofOfCompletion[]) || ([] as IVendorProofOfCompletion[]);
  };

  const fetchAllWorkOrderData = async (workOrderId: string, subWorkOrderId: string) => {
    setIsTasksRequireProofLoading(true);
    try {
      if (subWorkOrderId) {
        const allUpcomingJobsForSubJobResponse = await WorkOrdersApiService.getAllWorkOrders({
          workOrderId: subWorkOrderId,
        });
        if (!isEmpty(allUpcomingJobsForSubJobResponse.data) && isEmpty(allUpcomingJobsForSubJobResponse.errors)) {
          const allServiceCategoriesResponse = await MasterDataApiService.getAllServiceCategoryV3(
            allUpcomingJobsForSubJobResponse?.data[0]?.customerId
          );
          const serviceCategories =
            allServiceCategoriesResponse?.data && allServiceCategoriesResponse?.data?.length
              ? [...allServiceCategoriesResponse.data]
              : [];
          if (!isEmpty(allUpcomingJobsForSubJobResponse?.data[0]?.services) && !isEmpty(serviceCategories)) {
            const transformedServiceAndTaskData: IVendorProofOfCompletion[] = getTransformedServiceAndTasksData(
              allUpcomingJobsForSubJobResponse?.data[0]?.services || [],
              serviceCategories
            );
            setProofOfServiceAndTaskData(transformedServiceAndTaskData || []);
          }
          if (allUpcomingJobsForSubJobResponse?.errors?.length) {
            setSnackbarMessage(allUpcomingJobsForSubJobResponse?.errors[0]?.message);
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
          }
        }
      } else {
        const allUpcomingJobsResponse = await WorkOrdersApiService.getAllWorkOrders({
          workOrderId, // TODO
        });
        const allServiceCategoriesResponse = await MasterDataApiService.getAllServiceCategoryV3(
          allUpcomingJobsResponse?.data[0]?.customerId
        );
        const serviceCategories =
          allServiceCategoriesResponse?.data && allServiceCategoriesResponse?.data?.length
            ? [...allServiceCategoriesResponse.data]
            : [];

        if (!isEmpty(allUpcomingJobsResponse?.data[0]?.services) && !isEmpty(serviceCategories)) {
          const transformedServiceAndTaskData: IVendorProofOfCompletion[] = getTransformedServiceAndTasksData(
            allUpcomingJobsResponse?.data[0]?.services || [],
            serviceCategories
          );
          setProofOfServiceAndTaskData(transformedServiceAndTaskData || []);
        }
        if (allUpcomingJobsResponse?.errors?.length) {
          setSnackbarMessage(allUpcomingJobsResponse?.errors[0]?.message);
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        }
      }
    } catch (e) {
      setIsTasksRequireProofLoading(false);
      console.error('all upcoming jobs fetch error', e);
    } finally {
      setIsTasksRequireProofLoading(false);
    }
  };

  const renderTasksRequireProofData = () => {
    return (
      <>
        {proofOfServiceAndTaskData.length > 0 ? (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {proofOfServiceAndTaskData.map((service: any, index: number) => {
              const tasksWithProofRequired = service.options
                .filter((task: any) => task.isProofOfCompletionRequired === 'Yes')
                .map((task: any) => task.title)
                .join(', ');
              return (
                <AssignJobText key={index}>
                  {service.categoryTitle} -{' '}
                  {tasksWithProofRequired ? tasksWithProofRequired : t('assignJob:noTasksSelectedYet')}
                </AssignJobText>
              );
            })}
          </Box>
        ) : (
          <AssignJobText>{t('assignJob:noDataYet')}</AssignJobText>
        )}
      </>
    );
  };

  const fetchPreShiftChecklistTemplateForVendor = async (workOrderId: string, subWorkOrderId: string) => {
    setIsPreShiftChecklistLoading(true);
    try {
      const preShiftChecklistServicesResponse = await MasterDataApiService.getMasterDataCategories(
        MasterData.PreShiftCheckList
      );
      if (preShiftChecklistServicesResponse.data?.length) {
        const allPreShiftChecklistTemplateResponse = await WorkOrdersApiService.getAllPreShiftChecklistTemplate(
          isOnlyPrimeVendor ? subWorkOrderId : workOrderId
        );
        let modifiedData: IDataProps[] = preShiftChecklistServicesResponse.data.map(
          (preShiftChecklistServicesCategory: IDataProps) => {
            const selectedPreShiftCheckListTemplateItems = allPreShiftChecklistTemplateResponse.data?.find(
              (preShiftChecklistTemplateItem: PreShiftChecklistTemplate) =>
                preShiftChecklistServicesCategory.keyCode ===
                preShiftChecklistTemplateItem.mdPreShiftCheckListCategoryId
            );
            let serviceOptions = [];
            if (
              selectedPreShiftCheckListTemplateItems &&
              Object.keys(selectedPreShiftCheckListTemplateItems).length !== 0
            ) {
              serviceOptions = selectedPreShiftCheckListTemplateItems.preShiftChecklistTemplateItems.map(
                (preShiftChecklistTemplateItem: PreShiftChecklistTemplateItem) => {
                  const id = uuidv4();
                  return {
                    quantity: preShiftChecklistTemplateItem.quantity,
                    title: preShiftChecklistTemplateItem.itemName,
                    quantityName: getQuantityName(preShiftChecklistTemplateItem.mdQuantityType, 'query'),
                    id,
                    status: getStatus(preShiftChecklistTemplateItem.mdItemActionType, 'query'),
                    preShiftChecklistTemplateItemId:
                      preShiftChecklistTemplateItem?.preShiftChecklistTemplateItemId || '',
                  };
                }
              );
            }
            return {
              id: preShiftChecklistServicesCategory.keyCode,
              category: preShiftChecklistServicesCategory.keyCode,
              categoryTitle: preShiftChecklistServicesCategory.value,
              options: serviceOptions,
              headerIcon: preShiftChecklistServicesCategory.subKey1Value,
              preShiftChecklistTemplateId: selectedPreShiftCheckListTemplateItems?.preShiftChecklistTemplateId || '',
            };
          }
        );
        if (isOnlySubVendor) {
          modifiedData = modifiedData.filter((checkListItem) => !isEmpty(checkListItem?.options));
        }
        setCheckListData(modifiedData);
      } else {
        setSnackbarMessage(preShiftChecklistServicesResponse.errors[0]?.message);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (e) {
      console.error('Errors', e);
    } finally {
      setIsPreShiftChecklistLoading(false);
    }
  };

  const getStatus = useCallback(
    (status: PreShiftChecklistTemplateItemActionType, type = '') => {
      if (type === 'query') {
        switch (status) {
          case PreShiftChecklistTemplateItemActionType.Brought:
            return t('vendor:toBeBrought');
          case PreShiftChecklistTemplateItemActionType.PickedUp:
            return t('vendor:buyFromBestBuy');
          case PreShiftChecklistTemplateItemActionType.Provided:
            return t('vendor:provided');
          default:
            return t('vendor:provided');
        }
      } else {
        switch (t(status)) {
          case t('vendor:toBeBrought'):
            return PreShiftChecklistTemplateItemActionType.Brought;
          case t('vendor:buyFromBestBuy'):
            return PreShiftChecklistTemplateItemActionType.PickedUp;
          case t('vendor:provided'):
            return PreShiftChecklistTemplateItemActionType.Provided;
          default:
            return PreShiftChecklistTemplateItemActionType.Provided;
        }
      }
    },
    [t]
  );
  const getQuantityName = useCallback(
    (quantityName: string, type = '') => {
      if (type === 'query') {
        switch (quantityName) {
          case PreShiftChecklistTemplateItemQuantityType.Unit.toString():
            return t('common:unit');
          case PreShiftChecklistTemplateItemQuantityType.KG.toString():
            return t('common:kg');
          case PreShiftChecklistTemplateItemQuantityType.Litre.toString():
            return t('common:litre');
          case PreShiftChecklistTemplateItemQuantityType.Pair.toString():
            return t('common:pair');
          case PreShiftChecklistTemplateItemQuantityType.Rolls.toString():
            return t('common:rolls');
          default:
            return undefined;
        }
      } else {
        switch (quantityName) {
          case t('common:unit'):
            return PreShiftChecklistTemplateItemQuantityType.Unit;
          case t('common:kg'):
            return PreShiftChecklistTemplateItemQuantityType.KG;
          case t('common:litre'):
            return PreShiftChecklistTemplateItemQuantityType.Litre;
          case t('common:pair'):
            return PreShiftChecklistTemplateItemQuantityType.Pair;
          case t('common:rolls'):
            return PreShiftChecklistTemplateItemQuantityType.Rolls;
          default:
            return undefined;
        }
      }
    },
    [t]
  );

  const renderAddedItem = (catalogueId: string, items: any[]) => {
    return (
      <>
        {items.map((item: any, index: number) => {
          return (
            <>
              <AssignJobText key={index} sx={{ display: 'flex', gap: '5em', marginBottom: '0.5em' }}>
                <li style={{ maxWidth: '20em', width: '20em', marginLeft: '1em' }}>
                  {item.title} - {item.quantity} {item.quantityName} - {t(`assignJob:${item.status}`)}
                </li>
                {workOrderStatus === WorkOrderStatus.Accepted &&
                  ((isOtjJob && isDistrictManager) || (!isOtjJob && role === UserType.Vendor)) &&
                  !isAssociatePunchedIn && (
                    <div onClick={() => handleAddItemEdit(catalogueId, item)} style={{ cursor: 'pointer' }}>
                      <img
                        src={Icons.EditBlackIcon}
                        alt="Edit"
                        style={{ width: '1.5rem', height: '1.5rem', marginRight: '2em' }}
                      />
                    </div>
                  )}
              </AssignJobText>
            </>
          );
        })}
      </>
    );
  };

  const renderAssignedTeamData = () => {
    const assignedSupervisors = teamData
      .filter((member: any) => member.isChecked)
      .map((member: any) => member.name)
      .join(', ');
    const assignedAssociates = associateTeamData
      .filter((member: any) => member.isChecked)
      .map((member: any) => member.name)
      .join(', ');
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <AssignJobText>
          {t('assignJob:assignedSupervisors')}{' '}
          {assignedSupervisors ? assignedSupervisors : t('assignJob:supervisorsAreNotAssignedYet')}
        </AssignJobText>
        {(isSelfPerformJob || (role === UserType.Vendor && !isDistrictManager)) && (
          <AssignJobText>
            {t('assignJob:assignedAssociates')}{' '}
            {assignedAssociates ? assignedAssociates : t('assignJob:associatesAreNotAssignedYet')}
          </AssignJobText>
        )}
      </div>
    );
  };

  const renderShiftDetailsData = () => {
    return (
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '0.5em' }}>
        {shiftTimings.length > 0 ? (
          <>
            {shiftTimings.map((shiftTiming: ShiftDetails, index: number) => {
              return (
                <div key={index} style={{ display: 'flex', gap: '4em' }}>
                  {!(workOrderData.jobType === JobType.Recurring) && (
                    <AssignJobText>
                      {t('assignJob:shiftDate')}:{' '}
                      {formatDate(
                        workOrderData.actualStartDate ? workOrderData.actualStartDate : workOrderData.jobStartDate,
                        'MM/DD/YYYY'
                      )}{' '}
                      -{' '}
                      {formatDate(
                        workOrderData.actualEndDate ? workOrderData.actualEndDate : workOrderData.jobEndDate,
                        'MM/DD/YYYY'
                      )}
                    </AssignJobText>
                  )}

                  <AssignJobText>
                    {t('assignJob:shiftTime')}: {getTimeFormat(shiftTiming.startTime)} -{' '}
                    {getTimeFormat(shiftTiming.endTime)}
                  </AssignJobText>
                  {workOrderStatus === WorkOrderStatus.Accepted &&
                    ((isOtjJob && isDistrictManager) || (!isOtjJob && role === UserType.Customer)) &&
                    !isAssociatePunchedIn && (
                      <Button
                        onClick={() => {
                          if (workOrderData.workOrderTeams && !isEmpty(workOrderData.workOrderTeams)) {
                            setSelectedShiftId(shiftTiming.shiftDetailId);
                            setOpenDeleteShiftWarning(true);
                          } else {
                            deleteShift(shiftTiming.shiftDetailId);
                          }
                        }}
                        sx={{
                          backgroundColor: 'transparent',
                          padding: 0,
                          margin: 0,
                          minWidth: 0,
                        }}>
                        {isShiftDeleting ? (
                          <CircularProgress size={30} sx={{ color: theme.palette.common.black }} />
                        ) : (
                          <img src={Icons.DeleteIcon} alt={t('altTexts:delete')} />
                        )}
                      </Button>
                    )}
                </div>
              );
            })}
          </>
        ) : (
          <AssignJobText>{t('assignJob:noDataAddedYet')}</AssignJobText>
        )}
      </Box>
    );
  };

  const handleAddItemClose = () => {
    setAddItemCardOpen(false);
    setAddItemData({});
  };

  const handleAddItem = (addedItem: any, isEdit: boolean) => {
    if (!isEdit) {
      let alreadyExist = false;
      const updatedChecklistData = checkListData.map((catalogue: any) => {
        const tempCatalogue = { ...catalogue };
        if (catalogue.id === selectedCatalogue) {
          const tempOptions = [];
          catalogue.options?.forEach((item: any) => {
            if (item?.title?.toLowerCase() === addedItem?.title?.toLowerCase()) {
              alreadyExist = true;
              tempOptions.push(addedItem);
            } else {
              tempOptions.push(item);
            }
          });
          if (!alreadyExist) {
            tempOptions.push(addedItem);
          }
          tempCatalogue.options = tempOptions;
          return tempCatalogue;
        }
        return tempCatalogue;
      });
      setCheckListData(updatedChecklistData);
      handleSaveChecklist(updatedChecklistData);
    } else {
      const updatedChecklistData = checkListData.map((catalogue: any) => {
        const tempCatalogue = { ...catalogue };
        if (catalogue.id === selectedCatalogue) {
          const tempOptions: any[] = [];
          catalogue?.options?.forEach((item: any) => {
            if (item.id === addedItem.id) {
              return tempOptions.push({ ...addedItem });
            }

            return tempOptions.push(item);
          });
          tempCatalogue.options = tempOptions;
          return tempCatalogue;
        }

        return tempCatalogue;
      });
      setCheckListData(updatedChecklistData);
      handleSaveChecklist(updatedChecklistData);
    }
    handleAddItemClose();
  };

  const handleAddItemDelete = (itemForDeletion: any) => {
    const updatedAfterDeletion: IDataProps[] = checkListData.map((catalogue: any) => {
      const tempOptions = { ...catalogue };
      if (catalogue.id === selectedCatalogue) {
        tempOptions.options = catalogue.options.filter((item: any) => {
          return !(item.id === itemForDeletion.id);
        });
        return tempOptions;
      }
      return catalogue;
    });
    setCheckListData(updatedAfterDeletion);
    setSelectedCatalogue('');
    handleAddItemClose();
    handleSaveChecklist(updatedAfterDeletion);
  };

  const handleAddItemEdit = (catalogueId: string, item: any) => {
    setSelectedCatalogue(catalogueId || '');
    setAddItemData(item);
    setAddItemCardOpen(true);
  };

  useEffect(() => {
    if (isEmpty(addItemData)) {
      setSelectedCatalogue(checkListData[0]?.id as string);
    }
  }, [checkListData, addItemData]);

  const handleAddItemButton = () => {
    setAddItemCardOpen(true);
  };

  const handleSaveChecklist = (checkListData: IDataProps[]) => {
    if (isAssociate) {
      // submitChecklist();
    } else if (isVendor) {
      savePreShiftChecklist(checkListData);
    }
  };

  const savePreShiftChecklist = async (checkListData: IDataProps[]) => {
    const updateChecklistData =
      checkListData
        ?.filter((checkList) => !isEmpty(checkList?.preShiftChecklistTemplateId))
        ?.map((item) => {
          const items: PreShiftChecklistTemplateItemInput[] = [];
          item.options?.forEach((optionItem: any) => {
            const serviceTask: any = {
              itemName: optionItem.title,
              mdItemActionType: getStatus(optionItem.status),
              quantity: optionItem.quantity,
              mdQuantityType: getQuantityName(optionItem.quantityName as string),
            };
            if (!isEmpty(optionItem?.preShiftChecklistTemplateItemId)) {
              serviceTask.preShiftChecklistTemplateItemId = optionItem?.preShiftChecklistTemplateItemId;
            }
            items.push(serviceTask);
          });
          return {
            checkListName: item.categoryTitle,
            checkListIconUrl: item.headerIcon,
            mdPreShiftCheckListCategoryId: item.id,
            buildingAccessConfirmation: WorkOrderYesNoType.Yes,
            securityAccessConfirmation: WorkOrderYesNoType.Yes,
            safetyConfirmation: WorkOrderYesNoType.Yes,
            msdsChemicalSafety: WorkOrderYesNoType.Yes,
            workStatus: WorkOrderStatus.Created,
            preShiftChecklistTemplateItems: items?.length ? items : [],
            preShiftChecklistTemplateId: item?.preShiftChecklistTemplateId,
          };
        }) || [];

    const addNewChecklistData =
      checkListData
        ?.filter((checkList) => isEmpty(checkList?.preShiftChecklistTemplateId || ''))
        ?.map((item) => {
          const items: PreShiftChecklistTemplateItemInput[] = [];
          item.options?.forEach((optionItem: any) => {
            items.push({
              itemName: optionItem.title,
              mdItemActionType: getStatus(optionItem.status) as PreShiftChecklistTemplateItemActionType,
              quantity: optionItem.quantity as number,
              mdQuantityType: getQuantityName(
                optionItem.quantityName as string
              ) as PreShiftChecklistTemplateItemQuantityType,
            });
          });
          return {
            checkListName: item.categoryTitle,
            checkListIconUrl: item.headerIcon,
            mdPreShiftCheckListCategoryId: item.id,
            buildingAccessConfirmation: WorkOrderYesNoType.Yes,
            securityAccessConfirmation: WorkOrderYesNoType.Yes,
            safetyConfirmation: WorkOrderYesNoType.Yes,
            msdsChemicalSafety: WorkOrderYesNoType.Yes,
            workStatus: WorkOrderStatus.Created,
            preShiftChecklistTemplateItems: items?.length ? items : [],
          };
        }) || [];

    try {
      setIsPreShiftChecklistLoading(true);
      if (!isEmpty(addNewChecklistData)) {
        if (subWorkOrderId) {
          await Promise.all(
            addNewChecklistData.map(async (checkListItem) => {
              return await WorkOrdersApiService.addPreShiftChecklistTemplate(
                subWorkOrderId,
                checkListItem as PreShiftChecklistTemplateInput
              );
            })
          )
            .then((data) => {
              setSnackbarMessage(t('assignJob:successfullyUpdatedPreShiftChecklist'));
              setSnackbarSeverity('success');
              setSnackbarOpen(true);
              return data;
            })
            .catch((e) => {
              setSnackbarMessage(e);
              setSnackbarSeverity('error');
              setSnackbarOpen(true);
            });
        } else {
          await Promise.all(
            addNewChecklistData.map((checkListItem) => {
              return WorkOrdersApiService.addPreShiftChecklistTemplate(
                workOrderId,
                checkListItem as PreShiftChecklistTemplateInput
              ).then((data) => data);
            })
          )
            .then((data) => {
              setSnackbarMessage(t('assignJob:successfullyUpdatedPreShiftChecklist'));
              setSnackbarSeverity('success');
              setSnackbarOpen(true);
              return data;
            })
            .catch((e) => {
              setSnackbarMessage(e);
              setSnackbarSeverity('error');
              setSnackbarOpen(true);
            });
        }
      }
      if (!isEmpty(updateChecklistData)) {
        await Promise.all(
          updateChecklistData.map((checkListItem) => {
            return WorkOrdersApiService.updatePreShiftChecklistTemplate(
              checkListItem?.preShiftChecklistTemplateId as string,
              checkListItem as PreShiftChecklistTemplateInput
            ).then((data) => data);
          })
        )
          .then((data) => {
            setSnackbarMessage(t('assignJob:successfullyUpdatedPreShiftChecklist'));
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            return data;
          })
          .catch((e) => {
            setSnackbarMessage(e);
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
          });
      }
    } catch (e) {
      console.error('Error', e);
    } finally {
      setIsPreShiftChecklistLoading(false);
    }
  };

  const handleOnCloseJob = async () => {
    setIsCompleteJobLoading(true);
    try {
      const updateWorkOrderStatusResponse = await WorkOrdersApiService.updateWorkOrderStatus({
        workOrderId: workOrderId ?? '',
        status: WorkOrderStatus.Completed,
      });
      if (!isEmpty(updateWorkOrderStatusResponse.errors)) {
        setSnackbarMessage(`${t('vendor:errorOccurred')}`);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      } else if (
        isEmpty(updateWorkOrderStatusResponse?.errors ?? []) &&
        !isEmpty(updateWorkOrderStatusResponse?.data ?? [])
      ) {
        fetchAllWorkOrderData(subWorkOrderId, workOrderId);
        setSnackbarMessage(`${t('assignJob:markedAsComplete')}`);
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setIsPopupOpen(false);
        setTimeout(() => {
          setSnackbarOpen(false);
          navigate('/vendor-jobs/self-performed-jobs');
        }, 2000);
      }
    } catch (e: any) {
      setSnackbarMessage(e);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setIsCompleteJobLoading(false);
    }
  };

  const handleAddOrEditAssignTeam = () => {
    if (shiftTimings.length === 0) {
      setOpenShiftTimingsCheck(true);
    } else {
      handleOnClick(AssignmentCardType.AssignTeam);
    }
  };

  useEffect(() => {
    const fetchDisableStartJobResult = async () => {
      const result = await disableStartJob();
      setDisableStartJobResult(result);
    };

    fetchDisableStartJobResult();
  }, [workOrderData, shiftTimings]);

  return (
    <>
      <FacilityDetailsContainer>
        <DetailsContainer>
          <AwardJobText>{t('assignJob:awardJob')}</AwardJobText>
        </DetailsContainer>
        <Divider sx={divider} />
        <TimeAndInstructions>
          <Match>
            <MatchText>
              {t('assignJob:shiftTimingsText')} <span style={{ color: '#E51717' }}>&nbsp;*</span>
            </MatchText>
          </Match>
          <div style={{ width: '78em' }}>
            {isShiftDetailsLoading ? (
              <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
            ) : (
              renderShiftDetailsData()
            )}
          </div>
          {shiftTimings.length === 0 &&
            workOrderStatus === WorkOrderStatus.Accepted &&
            ((isOtjJob && isDistrictManager) || (!isOtjJob && role === UserType.Vendor)) &&
            !isAssociatePunchedIn && (
              <Button
                onClick={() => handleOnClick(AssignmentCardType.ShiftTimings)}
                sx={{
                  backgroundColor: 'transparent',
                  padding: 0,
                  margin: 0,
                  minWidth: 0,
                }}>
                <img
                  src={Icons.AddTagIcon}
                  alt={t('altTexts:add')}
                  style={{ width: '1.75rem', height: '1.75rem', marginRight: '2em' }}
                />
              </Button>
            )}
        </TimeAndInstructions>
        <Divider sx={divider} />
        <TimeAndInstructions>
          <Match>
            <MatchText>
              {t('assignJob:assignTeam')}
              <span style={{ color: '#E51717' }}>&nbsp;*</span>
            </MatchText>
          </Match>
          <div style={{ width: '78em' }}>
            {isAssignTeamLoading ? (
              <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
            ) : (
              <>
                {teamData.length > 0 ? (
                  renderAssignedTeamData()
                ) : (
                  <AssignJobText>{t('assignJob:teamIsNotAssignedYet')}</AssignJobText>
                )}
              </>
            )}
          </div>
          {workOrderStatus === WorkOrderStatus.Accepted && !isAssociatePunchedIn && (
            <Button
              onClick={() => handleAddOrEditAssignTeam()}
              sx={{
                backgroundColor: 'transparent',
                padding: 0,
                margin: 0,
                minWidth: 0,
              }}>
              {teamData.some((item) => item.isChecked) ? (
                <img
                  src={Icons.EditBlack}
                  alt={t('altTexts:edit')}
                  style={{ marginRight: '2em', width: '1.5rem', height: '1.5rem' }}
                />
              ) : (
                <img
                  src={Icons.AddTagIcon}
                  alt={t('altTexts:add')}
                  style={{ width: '1.75rem', height: '1.75rem', marginRight: '2em' }}
                />
              )}
            </Button>
          )}
        </TimeAndInstructions>
        <Divider sx={divider} />
        <TimeAndInstructions>
          <Match>
            <MatchText>{t('assignJob:facilityAccessText')}</MatchText>
          </Match>
          <Box>
            {isFacilityAccessLoading ? (
              <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
            ) : (
              <>
                {facilityAccessData.length > 0 ? (
                  renderFacilityAccessData()
                ) : (
                  <AssignJobText>{t('assignJob:noFacilityAccessDataFound')}</AssignJobText>
                )}
              </>
            )}
          </Box>
        </TimeAndInstructions>
        <Divider sx={divider} />
        <TimeAndInstructions>
          <Match>
            <MatchText>{t('assignJob:tasksRequiringProofOfCompletion')}</MatchText>
          </Match>
          <div style={{ width: '68em' }}>
            {isTaskRequireProofLoading ? (
              <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
            ) : (
              renderTasksRequireProofData()
            )}
          </div>
          {workOrderStatus === WorkOrderStatus.Accepted &&
            ((isOtjJob && isDistrictManager) || (!isOtjJob && role === UserType.Vendor)) &&
            !isAssociatePunchedIn && (
              <Button
                onClick={() => handleOnClick(AssignmentCardType.ProofOfCompletionTasks)}
                sx={{
                  backgroundColor: 'transparent',
                  padding: 0,
                  margin: 0,
                  minWidth: 0,
                }}>
                {proofOfServiceAndTaskData.some((item) =>
                  item.options.some((option: any) => option.isProofOfCompletionRequired === WorkOrderYesNoType.Yes)
                ) ? (
                  <img
                    src={Icons.EditBlack}
                    alt={t('altTexts:edit')}
                    style={{ marginRight: '2em', width: '1.5rem', height: '1.5rem' }}
                  />
                ) : (
                  <img
                    src={Icons.AddTagIcon}
                    alt={t('altTexts:add')}
                    style={{ width: '1.75rem', height: '1.75rem', marginRight: '2em' }}
                  />
                )}
              </Button>
            )}
        </TimeAndInstructions>
        <Divider sx={divider} />
        <TimeAndInstructions>
          <Match>
            <MatchText>{t('assignJob:PreShiftChecklist')}</MatchText>
          </Match>
          <div style={{ width: '75.5em' }}>
            {isPreShiftChecklistLoading ? (
              <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
            ) : (
              <Box>
                {checkListData.length > 0 ? (
                  <>
                    {checkListData.map((item: any) => {
                      return (
                        <>
                          <AssignJobText>{item.categoryTitle}:</AssignJobText>
                          {item.options.length > 0 ? (
                            renderAddedItem(item.id, item.options)
                          ) : (
                            <AssignJobText style={{ marginLeft: '1em' }}>
                              {t('assignJob:noItemsAddedYet')}
                            </AssignJobText>
                          )}
                        </>
                      );
                    })}
                  </>
                ) : (
                  <AssignJobText>{t('assignJob:noDataYet')}</AssignJobText>
                )}
              </Box>
            )}
          </div>
          {workOrderStatus === WorkOrderStatus.Accepted &&
            ((isOtjJob && isDistrictManager) || (!isOtjJob && role === UserType.Vendor)) &&
            !isAssociatePunchedIn && (
              <Button
                onClick={handleAddItemButton}
                sx={{
                  backgroundColor: 'transparent',
                  padding: 0,
                  margin: 0,
                  minWidth: 0,
                }}>
                <img
                  src={Icons.AddTagIcon}
                  alt={t('altTexts:add')}
                  style={{ width: '1.75rem', height: '1.75rem', marginRight: '2em' }}
                />
              </Button>
            )}
        </TimeAndInstructions>
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1em', width: '100%' }}>
          {startJobOpen && (
            <ModifyPopUp
              open={startJobOpen}
              handleClose={handleStartJobPopup}
              handleOnCloseJob={handleStartJob}
              loading={startJobLoading}
              heading={t('assignJob:startJob')}
              text={t('assignJob:confirmStartJob')}
            />
          )}
          {isPopupOpen && (
            <ConfirmPopup
              open={isPopupOpen}
              handleClose={handleTogglePopup}
              handleOnCloseJob={handleOnCloseJob}
              isCompleteJobLoading={isCompleteJobLoading}
            />
          )}

          {renderStartJobButton()}
        </div>
      </FacilityDetailsContainer>
      <JobAssignmentCard
        open={isCardOpen}
        onClose={handleOnClose}
        assignmentType={assignmentType}
        jobData={jobData}
        handleShiftTimings={handleShiftTimings}
        shiftTimings={shiftTimings}
        isLoading={isLoading}
        isShiftDeleting={isShiftDeleting}
        workOrderData={workOrderData}
        deleteShift={deleteShift}
        workOrderId={workOrderId}
        subWorkOrderId={subWorkOrderId}
        isOnlySubVendor={isOnlySubVendor}
        isOnlyPrimeVendor={isOnlyPrimeVendor}
        hasChild={hasChild}
        fetchVendorTeam={fetchVendorTeam}
        teamData={teamData}
        setTeamData={setTeamData}
        originalTeamData={originalTeamData}
        setOriginalTeamData={setOriginalTeamData}
        associateTeamData={associateTeamData}
        setAssociateTeamData={setAssociateTeamData}
        originalAssociateTeamData={originalAssociateTeamData}
        setOriginalAssociateTeamData={setOriginalAssociateTeamData}
        checkedSupervisors={checkedSupervisors}
        setCheckedSupervisors={setCheckedSupervisors}
        checkedAssociates={checkedAssociates}
        setCheckedAssociates={setCheckedAssociates}
        isTeamDataLoading={isTeamDataLoading}
        fetchAssociateTeam={fetchAssociateTeam}
        fetchWorkOrderFacilityAccessInfo={fetchWorkOrderFacilityAccessInfo}
        facilityAccessData={facilityAccessData}
        isFacilityAccessLoading={isFacilityAccessLoading}
        fetchAllWorkOrderData={fetchAllWorkOrderData}
        proofOfServiceAndTaskData={proofOfServiceAndTaskData}
        setProofOfServiceAndTaskData={setProofOfServiceAndTaskData}
        isTaskRequireProofLoading={isTaskRequireProofLoading}
        fetchPreShiftChecklistTemplateForVendor={fetchPreShiftChecklistTemplateForVendor}
        isPreShiftChecklistLoading={isPreShiftChecklistLoading}
        checkListData={checkListData}
        setCheckListData={setCheckListData}
      />
      <AddItem
        addItemOpen={addItemCardOpen}
        handleAddItemCardClose={handleAddItemClose}
        handleAddItem={handleAddItem}
        addItemData={addItemData}
        handleAddItemDelete={handleAddItemDelete}
        checkListData={checkListData}
        selectedCatalogue={selectedCatalogue}
        setSelectedCatalogue={setSelectedCatalogue}
      />
      <SnackbarMessage
        open={snackbarOpen}
        successMessage={snackbarMessage}
        errorMessage={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
      />
      <WarningMessage
        open={openShiftTimgsCheck}
        onClose={() => setOpenShiftTimingsCheck(false)}
        messageTitle={t('assignJob:shiftTimingsAreNotAddedYet')}
        messageContent={t('assignJob:pleaseAddShiftTimingsBeforeAssigningTheTeam')}
        onButtonClick={() => {
          handleOnClick(AssignmentCardType.ShiftTimings);
          setOpenShiftTimingsCheck(false);
        }}
        showLoader={false}
        buttonText={t('assignJob:addShiftTiming')}
      />
      <WarningMessage
        open={openDeleteShiftWarning}
        onClose={() => setOpenDeleteShiftWarning(false)}
        messageTitle={t('assignJob:deleteShiftTiming')}
        messageContent={t('assignJob:deleteShiftTimingWarningMsg')}
        onButtonClick={() => {
          deleteShift(selectedShiftId);
          setOpenDeleteShiftWarning(false);
        }}
        showLoader={isShiftDeleting}
        buttonText={t('assignJob:deleteShift')}
      />
      <WarningMessage
        open={openStartJobWarning}
        onClose={() => setOpenStartJobWarning(false)}
        messageTitle={t('assignJob:teamAssignment')}
        messageContent={t('assignJob:startJobWarningMsg')}
        onButtonClick={() => setOpenStartJobWarning(false)}
        showLoader={isShiftDeleting}
        buttonText={t('assignJob:okay')}
      />
    </>
  );
};

export default AwardJob;
