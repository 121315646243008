/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { API } from 'aws-amplify';

import { createUser, deleteUsers } from '../../graphql/mutations';
import { isEmpty } from 'lodash';
import { getOrgRoleAndPermissions, getAllUsers, getUserManagers } from '../../graphql/queries';
import { BooleanType, CreateUser, DeleteUserInput } from '../../API';
import { CustomHeaders, AWSAuthMode, NUMBER_OF_ITEMS_PER_PAGE_IN_FACILITY_TYPES } from '../Constants/App';
import { IGraphQLResponse } from '../Models';

export class OrganizationService {
  static async getOrganizationRoles(orgId: string) {
    try {
      const usersResponse: IGraphQLResponse = await API.graphql(
        {
          query: getOrgRoleAndPermissions,
          variables: { orgId },
          authMode: AWSAuthMode.COGNITO,
        },
        CustomHeaders
      );
      return { data: usersResponse?.data?.getOrgRoleAndPermissions ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }
  static async getAllUsers(
    organizationId: string,
    userId?: string,
    roleName?: string,
    pageNumber?: number,
    limit?: number,
    isPaginationRequired?: BooleanType,
    sortBy?: string,
    sortOrder?: string
  ) {
    try {
      const variables: {
        limit?: number;
        organizationId: string;
        pageNumber?: number;
        roleName?: string;
        sortBy?: string;
        sortOrder?: string;
        userId?: string;
      } = {
        organizationId: organizationId || '',
        roleName: roleName || '',
        limit: limit || 10,
        pageNumber: pageNumber || 1,
      };
      if (userId) {
        variables.userId = userId;
      }
      const isPaginationRequire = isPaginationRequired || false;
      if (sortBy) {
        variables.sortBy = sortBy;
      }
      if (sortOrder) {
        variables.sortOrder = sortOrder;
      }
      let usersResponse: IGraphQLResponse = await API.graphql(
        {
          query: getAllUsers,
          variables: { ...variables },
          authMode: AWSAuthMode.COGNITO,
        },
        CustomHeaders
      );
      if (!isPaginationRequire) {
        usersResponse = await API.graphql(
          {
            query: getAllUsers,
            variables: {
              ...variables,
              limit:
                usersResponse?.data?.getAllUsers?.metadata?.totalCount ?? NUMBER_OF_ITEMS_PER_PAGE_IN_FACILITY_TYPES,
            },
            authMode: AWSAuthMode.COGNITO,
          },
          CustomHeaders
        );
      }
      return {
        data: usersResponse?.data?.getAllUsers?.data ?? [],
        metadata: usersResponse?.data?.getAllUsers?.metadata ?? {},
        errors: [],
      };
    } catch (e: any) {
      return { data: [], errors: e?.errors ?? [], metadata: {} };
    }
  }

  static async createUser(userInput: CreateUser) {
    try {
      const createUserResponse: IGraphQLResponse = await API.graphql(
        {
          query: createUser,
          variables: { userInput },
          authMode: AWSAuthMode.COGNITO,
        },
        CustomHeaders
      );
      return { data: createUserResponse?.data?.createUser ?? {}, errors: [] };
    } catch (e: any) {
      return { data: {}, errors: e.errors };
    }
  }

  static async getUserManagers(
    managerId: string,
    userId?: string,
    isAssociate?: BooleanType,
    searchString?: string,
    pageNumber: number = 0,
    limit: number = 0,
    sortBy?: string,
    sortOrder?: string,
    isPaginationRequired = false
  ) {
    try {
      const variables: {
        isAssociate?: string;
        limit?: number;
        managerId: string;
        pageNumber?: number;
        searchString?: string;
        userId?: string;
        sortBy?: string;
        sortOrder?: string;
      } = {
        managerId,
      };
      if (!isEmpty(userId)) {
        variables.userId = userId;
      }
      if (!isEmpty(isAssociate)) {
        variables.isAssociate = isAssociate;
      }
      if (!isEmpty(searchString)) {
        variables.searchString = searchString;
      }
      if (pageNumber > 0) {
        variables.pageNumber = pageNumber;
      }
      if (limit > 0) {
        variables.limit = limit;
      }
      if (sortBy) {
        variables.sortBy = sortBy;
      }
      if (sortOrder) {
        variables.sortOrder = sortOrder;
      }
      if (managerId) {
        let userManagersResponse: IGraphQLResponse = await API.graphql(
          {
            query: getUserManagers,
            variables,
            authMode: AWSAuthMode.COGNITO,
          },
          CustomHeaders
        );
        if (!isPaginationRequired) {
          userManagersResponse = await API.graphql(
            {
              query: getUserManagers,
              variables: {
                ...variables,
                limit: userManagersResponse?.data?.getUserManagers?.metadata?.totalCount ?? 20,
              },
              authMode: AWSAuthMode.COGNITO,
            },
            CustomHeaders
          );
        }
        return {
          data: userManagersResponse?.data?.getUserManagers?.data ?? [],
          metadata: userManagersResponse?.data?.getUserManagers?.metadata,
          errors: [],
        };
      }
      return { data: [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async deleteUsers(deleteUserInput: DeleteUserInput) {
    try {
      const deleteUserResponse: IGraphQLResponse = await API.graphql(
        {
          query: deleteUsers,
          variables: { deleteUserInput },
          authMode: AWSAuthMode.COGNITO,
        },
        CustomHeaders
      );
      return { data: deleteUserResponse?.data?.deleteUsers ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }
}
