/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IconButton, CircularProgress } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import moment from 'moment';
import { MRT_ColumnDef } from 'material-react-table';
import { useForm } from 'react-hook-form';
import {
  Linking,
  ActionIcon,
  ActionsColumn,
  Menus,
  MenuWrapper,
  ReadMore,
} from '../../../../Shared/Components/Common/DataGrid/DataGrid.styles';
import { v4 as uuidv4 } from 'uuid';

import Accordion, { ScreenName } from '../../../../Shared/Components/Common/Accordion/Accordion';
import Dropdown from '../../../../Shared/Components/Common/Dropdown/Dropdown';
import PageTitle from '../../../../Shared/Components/Common/PageTitle/PageTitle';
import { CustomerContainer, NoDataWrapper } from '../../../../Shared/Components/Layout/styles';
import { TaskData } from '../../../../Shared/Models/WorkTask.model';
import { servicesData } from './MockData';

import { Icons } from '../../../../Shared/Constants/Icons';
import {
  getStoredCustomerDetails,
  isDateSameOrAfter,
  isDateSameOrBefore,
  isMock,
} from '../../../../Shared/Utilities/utils';
import {
  ApplyButton,
  DateInputWrapper,
  DateWrapper,
  DropdownWrapper,
  JobsTitle,
  SelectedTitle,
} from './TasksReview.styles';
import { IJobOverview } from '../JobsListView/JobsListView';
import { IJobs } from '../JobView/JobsView';
import ApproveDialog from '../TaskDetails/ApproveDialog';
import RejectDialog from '../TaskDetails/RejectDialog';
import SnackBar from '../../../../Shared/Components/Common/SnackBar/SnackBar';
import DateSelection from './DateSelection';
import JobCreateApiService from '../../Services/JobCreateService';
import FacilityApiService from '../../../Facility/Services/FacilityApiService';
import { Job, JobStatus, WorkOrderStatus } from '../../../../API';
import WorkOrdersApiService from '../../Services/WorkOrdersService';
import { useAuth } from '../../../../Configuration/AuthContext';
import { ErrorMessage } from '../../../../Shared/Components/Common/ErrorMessage/ErrorMessage';
import theme from '../../../../Shared/Themes/theme';
import { IBidList } from '../JobDetails/OneTimeJobDetails';
import { IProfileCardProps } from '../../../Customer/Components/ViewFacilityDetails/ViewFacilityDetails';

enum DateSelectionType {
  All = 'All till date',
  Range = 'Custom date range',
  Specific = 'Specific date',
}

export const dateSelectionTypes = [
  { id: 1, label: DateSelectionType.All, value: DateSelectionType.All },
  { id: 2, label: DateSelectionType.Range, value: DateSelectionType.Range },
  { id: 3, label: DateSelectionType.Specific, value: DateSelectionType.Specific },
];

interface IServices {
  isShowButton?: boolean;
  selectedRowCount?: number;
  id: number;
  jobId?: string;
  serviceName: string;
  taskTotal: number;
  taskCompleted: number;
  iconUrl: string;
  facilityName: string;
  Jobname: string;
  facilityId: string;
  tableData: TaskData[];
  totalTasks: number;
}

export interface FormValues {
  specificDate: null | string;
  toDate: null | string;
  fromDate: null | string;
}

enum FieldNames {
  specificDate = 'specificDate',
  toDate = 'toDate',
  fromDate = 'fromDate',
}

/**
 * @returns TasksReview screen
 */
const TasksReview = () => {
  const { t } = useTranslation(['dashboard', 'calendar']);
  const [expanded, setExpanded] = useState<number | false>(1);
  const [dateType, setDateType] = useState<string>(DateSelectionType.All);
  const [serviceList, setServiceList] = useState<IServices[]>([]);
  const [originalServices, setOriginalServices] = useState<IServices[]>([]);
  const { handleSubmit, control, getValues, reset, setError } = useForm({
    defaultValues: {
      specificDate: null,
      fromDate: null,
      toDate: null,
    },
  });
  const [isOpenApprove, setIsOpenApprove] = useState(false);
  const [isOpenReject, setIsOpenReject] = useState(false);
  const [isOpenApproveAll, setIsOpenApproveAll] = useState(false);
  const [isOpenRejectAll, setIsOpenRejectAll] = useState(false);
  const [rowInfo, setRowInfo] = useState<any>({});
  const [selectedJob, setSelectedJob] = useState('');
  const [facilities, setFacilities] = useState([]);
  const [allJobs, setAllJobs] = useState<any>([]);
  const [facilityId, setFacilityId] = useState('');
  const [facilityLoading, setFacilityLoading] = useState<boolean>(false);
  const [jobsLoading, setJobsLoading] = useState<boolean>(false);
  const [isJobsQueryError, setIsJobsQueryError] = useState<boolean>(false);
  const [isFacilityQueryError, setFacilityQueryError] = useState<boolean>(false);

  const {
    handleApprovalAll,
    handleRejectAll,
    handleReject,
    handleApprove,
    setSelectedTaskId,
    selectedTaskId,
    setSnackBarStatus,
    snackBarStatus,
  } = useAuth();

  const dataFiltering = useCallback(
    (expanded: number | boolean) => {
      let filteredFacility: IServices[] = [];
      const { fromDate, toDate, specificDate } = getValues();
      const deepCloned = [...originalServices].map((data) => {
        return { ...data };
      });
      //facility filtering
      if (facilityId === 'all' && selectedJob === 'all') {
        filteredFacility = deepCloned;
      } else if (facilityId !== 'all') {
        filteredFacility = deepCloned.filter((data) => data.facilityId === facilityId);
        //if facility has no jobs
        if (filteredFacility.length === 0) {
          return filteredFacility;
        }
      } else if (selectedJob === 'all') {
        filteredFacility = deepCloned;
      } else {
        filteredFacility = deepCloned.filter((data) => data.jobId === selectedJob);
      }
      //Date filtering
      if (typeof expanded === 'number') {
        const activeData = filteredFacility[expanded - 1];
        let filteredData: TaskData[] = [];
        if ((dateType as DateSelectionType) === DateSelectionType.Specific && specificDate) {
          filteredData = activeData.tableData.filter((dataItem) => moment(specificDate).isSame(dataItem.date, 'day'));
        } else if ((dateType as DateSelectionType) === DateSelectionType.Range && fromDate && toDate) {
          filteredData = activeData.tableData.filter(
            (dataItem) => isDateSameOrAfter(fromDate, dataItem.date) && isDateSameOrBefore(toDate, dataItem.date)
          );
        } else {
          filteredData = activeData.tableData;
        }
        filteredFacility[expanded - 1].tableData = filteredData;
      }
      return filteredFacility;
    },
    [dateType, serviceList, facilityId, getValues, setError, selectedJob]
  );

  useEffect(() => {
    if (originalServices.length && selectedJob && facilityId) {
      const filtered = dataFiltering(expanded);
      setServiceList(filtered);
    }
  }, [originalServices, expanded, facilityId, dateType, selectedJob]);

  const fetchFacilities = async () => {
    setFacilityLoading(true);
    setJobsLoading(true);
    const { customerId = '' } = getStoredCustomerDetails() || {};
    try {
      if (customerId) {
        const allFacilitiesResponse = await FacilityApiService.getAllFacilities(customerId);
        if (allFacilitiesResponse.errors.length === 0 && allFacilitiesResponse.data.length) {
          const filteredJobs: any = [];
          const filteredFacility: any = await Promise.all(
            allFacilitiesResponse.data.map(async (facility: any) => {
              const facilities = await Promise.resolve(fetchAllJobs(facility.facilityId));
              facilities && filteredJobs.push(...facilities);
              return {
                id: facility.facilityId,
                label: facility.buildingName,
                value: facility.facilityId,
              };
            })
          );
          filteredFacility.unshift({ id: uuidv4(), label: 'All facilities', value: 'all' });
          const shallowServices = filteredJobs.map((data: any, index: number) => {
            return {
              id: index + 1,
              jobId: data.jobId,
              serviceName: 'Furniture',
              taskTotal: 4,
              taskCompleted: 1,
              facilityId: data.facilityId,
              iconUrl: data.icon,
              facilityName: data.facilityName,
              Jobname: data.label,
              tableData: [
                {
                  no: 1,
                  taskName: 'Sweeping',
                  floor: '1',
                  timeTaken: '35 min',
                  status: 'Issue raised',
                  comments: 'Lorem ipsum dolar sit',
                  proof: '12',
                  date: '2023-07-04T07:15:07.135Z',
                  taskId: '12345678',
                },
                {
                  no: 2,
                  taskName: 'Vacuuming',
                  floor: '1',
                  timeTaken: '',
                  status: 'Approved',
                  comments: 'Lorem ipsum dolar sit s',
                  proof: '12',
                  date: '2023-06-14T07:15:07.135Z',
                  taskId: '12345678',
                },
                {
                  no: 3,
                  taskName: 'Sweeping',
                  floor: '1',
                  timeTaken: '35 min',
                  status: 'Issue raised',
                  comments: '',
                  proof: '12',
                  date: '2023-06-16T07:15:07.135Z',
                  taskId: '12345678',
                },
                {
                  no: 4,
                  taskName: 'Sweeping',
                  floor: '1',
                  timeTaken: '35 min',
                  status: 'Complete',
                  comments: '',
                  proof: '12',
                  date: '2023-06-02T07:15:07.135Z',
                  taskId: '12345678',
                },
              ],
              isShowButton: false,
            };
          });
          if (filteredJobs.length > 0) {
            // if there is no jobs then to show no records added this condition
            filteredJobs.unshift({ id: uuidv4(), label: 'All Jobs', value: 'all' });
            setSelectedJob('all');
          }
          setFacilityId('all');
          setAllJobs(filteredJobs);
          setFacilities(filteredFacility);
          setServiceList(shallowServices);
          setOriginalServices(shallowServices);
          setFacilityQueryError(false);
        } else {
          setFacilityQueryError(true);
          console.error('facilities query : ', allFacilitiesResponse, customerId);
        }
      }
      setFacilityLoading(false);
      setJobsLoading(false);
    } catch (e) {
      setFacilityQueryError(true);
      console.error('Error', e);
    }
  };

  const fetchJobs = useCallback(async (facilityId: string) => {
    try {
      const { customerId = '' } = getStoredCustomerDetails() || {};

      const jobsResponse = await JobCreateApiService.jobsV2({ customerId, facilityId }, isMock);
      const facilityDetailsResponse = await FacilityApiService.facility(facilityId, customerId, isMock);

      let facilityIcon: string | undefined = '';
      const logoUrl: string = facilityDetailsResponse?.data?.address?.logoUrl || '';
      const googleMapsApiKey: string | undefined = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';

      if (facilityDetailsResponse?.data && logoUrl && typeof googleMapsApiKey === 'string') {
        facilityIcon = `https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=${logoUrl}&key=${googleMapsApiKey}`;
      }

      if (jobsResponse?.data && jobsResponse?.errors.length === 0) {
        const filteredData = jobsResponse?.data?.filter(
          (jobItem: Job) =>
            jobItem.dataStatus === JobStatus.OpenToBid ||
            jobItem.dataStatus === JobStatus.Awarded ||
            jobItem.dataStatus === JobStatus.Incomplete
        );

        return filteredData.map((jobItem: Job) => ({
          startDate: jobItem.startDate,
          stopDate: jobItem.stopDate,
          value: jobItem.jobId,
          facilityId: facilityId ?? '',
          jobId: jobItem?.jobId ?? '',
          id: uuidv4(),
          label: jobItem.jobName,
          facilityName: facilityDetailsResponse?.data?.buildingName || '',
          icon: facilityIcon,
        }));
      }

      if (jobsResponse?.errors.length) {
        setIsJobsQueryError(true);
        console.error('Error', jobsResponse?.errors);
      }

      return [];
    } catch (e) {
      setIsJobsQueryError(true);
      console.error('Error', e);
      return [];
    }
  }, []);

  const fetchFacilityIcon = (logoUrl: any, googleMapsApiKey: string | undefined) => {
    if (typeof logoUrl === 'string' && typeof googleMapsApiKey === 'string') {
      return `https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=${logoUrl}&key=${googleMapsApiKey}`;
    }
    return undefined;
  };

  const mapJobToData = async (job: {
    customerId?: any;
    jobStartDate?: string;
    jobEndDate?: string;
    jobId?: string;
    name?: string;
    facilities?: any;
  }) => {
    const { name, facilities } = job;
    let facilityIcon = undefined;
    let facilityId = '';

    if (facilities) {
      facilityId = facilities[0]?.facilityId ?? '';
    }

    const facilityAddressResponse = await FacilityApiService.facility(facilityId, job.customerId ?? '', isMock);

    if (facilityAddressResponse?.data) {
      const logoUrl = facilityAddressResponse.data.address?.logoUrl;
      const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

      facilityIcon = fetchFacilityIcon(logoUrl, googleMapsApiKey);
    }

    return {
      label: name,
      startDate: job.jobStartDate,
      stopDate: job.jobEndDate,
      facilityName: facilityAddressResponse?.data?.buildingName || '',
      value: job.jobId ?? '',
      facilityId: facilityId ?? '',
      jobId: job.jobId ?? '',
      id: uuidv4(),
      icon: facilityIcon,
    };
  };

  const fetchOngoingJobs = useCallback(async () => {
    try {
      const { customerId = '' } = getStoredCustomerDetails() || {};
      const allJobResponse = await WorkOrdersApiService.getAllWorkOrders({
        customerId,
        status: WorkOrderStatus.InProgress,
      });

      if (allJobResponse?.data?.length && !allJobResponse?.errors?.length) {
        const jobDataPromises = allJobResponse.data.map(mapJobToData);
        return Promise.all(jobDataPromises);
      }

      if (allJobResponse?.errors?.length && allJobResponse.errors[0]?.message) {
        console.error('Error', allJobResponse.errors);
      }

      return [];
    } catch (e) {
      setIsJobsQueryError(true);
      console.error('Error', e);
      return [];
    }
  }, []);

  const fetchAllJobs = useCallback(
    async (facilityId: string) => {
      const [upcomingJobs, ongoingJobs] = await Promise.all([fetchJobs(facilityId), fetchOngoingJobs()]);
      if (upcomingJobs?.length || ongoingJobs?.length) {
        const filteredUpcomingJobs = upcomingJobs.filter((job: any) =>
          ongoingJobs.find((ongoingJob) => ongoingJob.jobId !== job.jobId && ongoingJob.facilityId === job.facilityId)
        );
        return [...upcomingJobs, ...filteredUpcomingJobs];
      }
    },
    [fetchJobs, fetchOngoingJobs]
  );

  useEffect(() => {
    fetchFacilities();
  }, [servicesData]);

  useEffect(() => {
    const fetchJobs = async () => {
      if (facilityId === 'all') {
        fetchFacilities();
      } else {
        setJobsLoading(true);
        const jobs: any = await Promise.resolve(fetchAllJobs(facilityId));
        if (jobs && jobs.length > 0) {
          // whenever we changing facility we are adding all jobs object if jobs are more than one
          jobs.unshift({ id: uuidv4(), label: 'All Jobs', value: 'all' });
          setSelectedJob('all');
        } else {
          setSelectedJob('');
        }
        setAllJobs(jobs);
        setJobsLoading(false);
      }
    };
    fetchJobs();
  }, [facilityId]);

  const onSubmit = useCallback(() => {
    const fromDate = getValues('fromDate');
    const toDate = getValues('toDate');
    if (moment(fromDate).diff(toDate, 'days') > 0) {
      setError('toDate', { type: 'validate', message: `${t('dashboard:toDateCannotBeInPastDate')}` });
    } else {
      setServiceList(dataFiltering(expanded));
    }
  }, [expanded, dataFiltering]);

  const handleAccordion = useCallback(
    (panel: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    },
    []
  );

  const onChangeRow = useCallback(
    (rowSelection: any, data: TaskData[], service: IServices) => {
      const allRowSelected = Object.keys(rowSelection);
      const taskIds: any = data.filter(
        (dataItem, index) => allRowSelected?.includes(index.toString()) && dataItem.taskId
      );
      //Adding timeout to avoid re rendering because fraction of second allRowSelected and taskIds become empty
      // and because of that approve and reject button gives jerk
      setTimeout(() => {
        setSelectedTaskId(taskIds);
        const isShow = allRowSelected.length && data.length && allRowSelected.length === data.length ? true : false;
        const temp = serviceList.map((dataItem) => {
          if (service.id === dataItem.id) {
            return {
              ...dataItem,
              isShowButton: isShow,
              selectedRowCount: taskIds.length,
            };
          }
          return dataItem;
        });
        setServiceList(temp);
      }, 0);
    },
    [serviceList]
  );

  // material UI Logic (table menu handler)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderTaskNameCell = ({ renderedCellValue }: RenderCellProps) => (renderedCellValue ? renderedCellValue : '-');

  type RenderCellProps = {
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    renderedCellValue: React.ReactNode | string | number | boolean;
    row: any;
  };

  const renderStatusCell = ({ renderedCellValue }: RenderCellProps) => {
    const statusColors = {
      'Issue raised': theme.palette.error.main,
      Rejected: theme.palette.error.main,
      Approved: theme.palette.success.main,
    };

    // Assert that renderedCellValue is a string
    const statusKey = renderedCellValue as keyof typeof statusColors;

    // Use the statusKey to get the color from the statusColors object
    const selectedColor = statusColors[statusKey] || theme.palette.text.secondary;

    return (
      <span style={{ color: selectedColor }}>
        {renderedCellValue !== undefined && renderedCellValue !== null ? renderedCellValue : '-'}
      </span>
    );
  };

  const renderCommentsCell = ({ renderedCellValue, row }: RenderCellProps) => {
    if (typeof renderedCellValue === 'string') {
      const truncatedValue =
        renderedCellValue.length > 21 ? `${renderedCellValue.substring(0, 21)}...` : renderedCellValue;

      return (
        <div>
          {truncatedValue}
          <ReadMore to="/task-details" state={{ taskRecord: row.original }}>
            {t('dashboard:readMore')}
          </ReadMore>
        </div>
      );
    }
    return '-';
  };

  const renderProofCell = ({ renderedCellValue }: RenderCellProps) =>
    renderedCellValue ? <span style={{ color: theme.palette.primary.dark }}>{renderedCellValue}</span> : '-';

  const renderActionsCell = ({ row }: any) => {
    const { original } = row;

    const handleApproveClick = () => {
      setIsOpenApprove(true);
      setRowInfo(original);
    };

    const handleRejectClick = () => {
      setIsOpenReject(true);
      setRowInfo(original);
    };

    return (
      <ActionsColumn>
        <ActionIcon onClick={handleApproveClick} src={Icons.TickIcon} alt={t('altTexts:approve')} />
        <ActionIcon onClick={handleRejectClick} src={Icons.CancelIcon} alt={t('altTexts:reject')} />
        <IconButton
          onClick={(e) => {
            handleClick(e);
            setRowInfo(row.original);
          }}
          size="large"
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}>
          <MoreVertIcon></MoreVertIcon>
        </IconButton>
        <MenuWrapper anchorEl={anchorEl} id="account-menu" open={open} onClose={handleClose} onClick={handleClose}>
          <Linking to="/task-details" state={{ taskRecord: rowInfo }}>
            <Menus>
              <img src={Icons.EyeIcon} alt={t('altTexts:viewDetails')} /> {t('dashboard:viewDetails')}
            </Menus>
          </Linking>
          <Menus onClick={() => setIsOpenApprove(true)}>
            <img src={Icons.TickIcon} alt={t('altTexts:approve')} /> {t('dashboard:approveButtonText')}
          </Menus>
          <Menus onClick={() => setIsOpenReject(true)}>
            <img src={Icons.CancelIcon} alt={t('altTexts:reject')} /> {t('dashboard:rejectButtonText')}
          </Menus>
        </MenuWrapper>
      </ActionsColumn>
    );
  };

  const columns = useMemo<MRT_ColumnDef<TaskData | IJobOverview | IJobs | IBidList | IProfileCardProps>[]>(
    () => [
      {
        accessorKey: 'no',
        header: 'No.',
        enablePinning: false,
        enableSorting: false,
        size: 10,
        enableColumnFilter: false,
        enableColumnActions: false,
      },
      {
        accessorKey: 'taskName',
        header: 'Task',
        enableSorting: true,
        enableColumnFilter: true,
        size: 140,
        Cell: renderTaskNameCell,
        enableColumnActions: true,
      },
      {
        accessorKey: 'floor',
        header: 'Floor',
        enableSorting: false,
        enableColumnFilter: true,
        size: 64,
        Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : '-'),
        enableColumnActions: false,
      },
      {
        accessorKey: 'timeTaken',
        header: 'Time taken',
        enableSorting: false,
        enableColumnFilter: false,
        size: 113,
        Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : '-'),
        enableColumnActions: false,
      },
      {
        accessorKey: 'status',
        header: 'Status',
        enableSorting: true,
        enableColumnFilter: false,
        size: 162,
        Cell: renderStatusCell,
      },
      {
        accessorKey: 'comments',
        header: 'Comments',
        enableSorting: false,
        enableColumnFilter: false,
        size: 294,
        Cell: renderCommentsCell,
      },
      {
        accessorKey: 'proof',
        header: 'Proof',
        enableSorting: false,
        enableColumnFilter: false,
        size: 100,
        Cell: renderProofCell,
      },
      {
        header: 'Actions',
        enableColumnActions: false,
        enablePinning: false,
        size: 130,
        Cell: renderActionsCell,
      },
    ],
    [handleClick, handleClose, setIsOpenApprove, setIsOpenReject, setRowInfo]
  );

  return (
    <>
      <PageTitle title={t('dashboard:tasksToBeReviewed')} />
      <CustomerContainer>
        <DropdownWrapper>
          <Dropdown
            isLoading={facilityLoading}
            dropdownData={facilities}
            defaultValue={facilityId}
            onChange={(value) => {
              setFacilityId(value);
              setExpanded(1); // expanded set to 1 because if we were open other than first accordion, then select specific facility then it throws error
            }}
          />
          <Dropdown
            isLoading={jobsLoading}
            dropdownData={allJobs}
            defaultValue={selectedJob}
            onChange={(value) => setSelectedJob(value)}
          />
          <Dropdown
            dropdownData={dateSelectionTypes}
            defaultValue={dateType}
            onChange={(value) => {
              setDateType(value);
              reset({
                specificDate: null,
                toDate: null,
                fromDate: null,
              });
            }}
          />
        </DropdownWrapper>
        {(dateType as DateSelectionType) !== DateSelectionType.All ? (
          <DateWrapper>
            <SelectedTitle>{dateType}</SelectedTitle>
            <DateInputWrapper>
              {(dateType as DateSelectionType) === DateSelectionType.Specific ? (
                <DateSelection
                  label={t('calendar:specificDate')}
                  control={control}
                  fieldName={FieldNames.specificDate}
                  requiredText={t('dashboard:specificDateFieldIsRequired')}
                />
              ) : null}
              {(dateType as DateSelectionType) === DateSelectionType.Range ? (
                <>
                  <DateSelection
                    label="From date"
                    control={control}
                    fieldName={FieldNames.fromDate}
                    requiredText={t('dashboard:fromDateFieldIsRequired')}
                  />
                  <DateSelection
                    label="To date"
                    control={control}
                    fieldName={FieldNames.toDate}
                    requiredText={t('dashboard:toDateFieldIsRequired')}
                  />
                </>
              ) : null}
              <ApplyButton onClick={handleSubmit(onSubmit)}>{t('dashboard:apply')}</ApplyButton>
            </DateInputWrapper>
          </DateWrapper>
        ) : null}
        <JobsTitle>{t('dashboard:jobs')}</JobsTitle>
        {serviceList.length === 0 && !isFacilityQueryError && !isJobsQueryError ? (
          <NoDataWrapper>
            <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
          </NoDataWrapper>
        ) : null}
        {!isFacilityQueryError || !isJobsQueryError ? (
          serviceList.map((dataItem) => {
            return (
              <Accordion
                serviceList={serviceList}
                key={dataItem.id}
                columns={columns}
                expanded={expanded}
                handleAccordion={handleAccordion}
                onChangeRow={onChangeRow}
                accordionObj={dataItem}
                screenName={ScreenName.TasksReviewed}
                handleApproveAll={() => {
                  setIsOpenApproveAll(true);
                  setSnackBarStatus({
                    ...snackBarStatus,
                    isOpen: false,
                  });
                }}
                handleRejectAll={() => {
                  setIsOpenRejectAll(true);
                  setSnackBarStatus({
                    ...snackBarStatus,
                    isOpen: false,
                  });
                }}
              />
            );
          })
        ) : (
          <ErrorMessage />
        )}
        <ApproveDialog
          isApproveWindowOpen={isOpenApprove || isOpenApproveAll}
          handleCloseApproveWindow={() => {
            setIsOpenApprove(false);
            setIsOpenApproveAll(false);
          }}
          handleApprove={() => {
            if (isOpenApproveAll) {
              handleApprovalAll();
            } else {
              if (Object.keys(rowInfo).length > 0) handleApprove(rowInfo?.taskId);
            }
            setIsOpenApproveAll(false);
            setIsOpenApprove(false);
            // setSnackBarStatus({
            //   isOpen: true,
            //   isApproved: true,
            // });
          }}
          rowDetails={rowInfo}
          isShowApproveAll={isOpenApproveAll && selectedTaskId.length > 1}
        />
        <RejectDialog
          isRejectWindowOpen={isOpenReject || isOpenRejectAll}
          handleCloseRejectWindow={() => {
            setIsOpenReject(false);
            setIsOpenRejectAll(false);
          }}
          handleReject={(vendorMsg: string) => {
            if (isOpenRejectAll) {
              handleRejectAll(vendorMsg);
            } else {
              if (Object.keys(rowInfo).length > 0) handleReject(rowInfo?.taskId, vendorMsg);
            }
            setIsOpenRejectAll(false);
            setIsOpenReject(false);
            // setSnackBarStatus({
            //   isOpen: true,
            //   isApproved: false,
            // });
          }}
          isShowRejectAll={isOpenRejectAll && selectedTaskId.length > 1}
        />
        {snackBarStatus.isOpen ? (
          <SnackBar
            isApproved={snackBarStatus.isApproved}
            taskCount={selectedTaskId.length ? selectedTaskId.length : 1}
            handleOnClose={() =>
              setSnackBarStatus({
                isOpen: false,
                isApproved: false,
              })
            }
          />
        ) : null}
      </CustomerContainer>
    </>
  );
};

export default TasksReview;
