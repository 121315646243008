/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import React from 'react';
import { ConversationContainer, LogoWrapper } from './ConversationsPage.Style';
import { getAcronymName, getStoredCustomerDetails } from '../../../../Shared/Utilities/utils';
import theme from '../../../../Shared/Themes/theme';
import { Divider } from '@mui/material';

interface ThreadCard {
  selectedConversation: any;
}

const ThreadCard = ({ selectedConversation }: ThreadCard) => {
  const hasSameOrganizationId = (organizationId: string) => {
    const { customerId = '' } = getStoredCustomerDetails();
    return customerId === organizationId;
  };

  return (
    <ConversationContainer sx={{ width: '100%', padding: '0', overflow: 'hidden' }}>
      {selectedConversation?.messages.map((message: any, index: number) => {
        return (
          <>
            <div
              key={index}
              style={{
                backgroundColor: hasSameOrganizationId(message.organizationId)
                  ? theme.palette.warning.contrastText
                  : theme.palette.common.white,
                padding: '1.5rem',
              }}>
              <div
                style={{
                  display: 'flex',
                  gap: '1rem',
                  alignItems: 'center',
                }}>
                <LogoWrapper
                  sx={{
                    backgroundColor: `${
                      hasSameOrganizationId(message.organizationId)
                        ? theme.palette.common.white
                        : theme.palette.primary.main
                    } !important`,
                    borderRadius: '50%',
                  }}>
                  <div>{getAcronymName(message.userName as string).toUpperCase()}</div>
                </LogoWrapper>
                <div>{message.userName}</div>
              </div>
              <div style={{ margin: '1rem 0 0 3.75rem', whiteSpace: 'pre-wrap' }}>{message.message}</div>
            </div>
            {index < selectedConversation?.messages.length - 1 && <Divider />}
          </>
        );
      })}
    </ConversationContainer>
  );
};

export default ThreadCard;
