/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';

import FacilityDetailsCard from './FacilityDetailsCard';
import Button, { ButtonType } from '../../../../Shared/Components/Common/Buttons/Button';
import SuccessMessagePopup from '../../../../Shared/Components/Common/SuccessMessagePopup/SuccessMessagePopup';
import { CurrentLocation, CurrentLocationText, AddressText } from './AddFacility.Style';
import { Icons } from '../../../../Shared/Constants/Icons';
import { Loader } from '../../../Jobs/Components/JobView/JobsView.styles';
import theme from '../../../../Shared/Themes/theme';

const CurrentLocationCard = (props: any) => {
  const [showFacilityDetails, setShowFacilityDetails] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { control, onSubmit, onClose, isSuccess, currentAddress, loading, getValues, SearchField, setValue } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation(['addFacility']);

  const handleUseLocationClick = () => {
    setValue('facilityName', location?.state?.buildingName);
    setValue('facilityType', location?.state?.mdFacilityType);
    setShowFacilityDetails(true);
  };

  const onPrimaryButtonClick = () => {
    navigate('/company');
  };

  const onSecondaryButtonClick = () => {
    if (window.location.pathname !== '/add-facility') {
      navigate('/add-facility');
    } else {
      window.location.reload();
    }
    onClose(true);
  };

  const handleSubmitWithLoading = async () => {
    setIsLoading(true);
    await onSubmit();
    setIsLoading(false);
  };

  const onCloseSuccessPopup = () => {
    navigate('/company/facilities');
    onClose(true);
  };

  return (
    <>
      <CurrentLocation sx={{ padding: '1.5rem', width: '22.5rem' }}>
        {showFacilityDetails ? <FacilityDetailsCard control={control} getValues={getValues} /> : null}
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          {loading ? (
            <Loader sx={{ width: '100%' }}>
              <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
            </Loader>
          ) : (
            <>
              <CurrentLocationText>{t('addFacility:currentLocation')}</CurrentLocationText>
              <img
                src={Icons.EditBlack}
                style={{ width: '1.5rem', height: '1.5rem', cursor: 'pointer' }}
                onClick={SearchField}
              />
            </>
          )}
        </Box>
        {loading ? null : (
          <>
            <AddressText>{currentAddress} </AddressText>
            {showFacilityDetails ? (
              <Button
                type={ButtonType.Primary}
                label={t('addFacility:confirm')}
                width="100%"
                onClick={handleSubmitWithLoading}
                isLoading={isLoading}
              />
            ) : (
              <Button
                type={ButtonType.Primary}
                label={t('addFacility:useThisLocation')}
                width="100%"
                onClick={handleUseLocationClick}
                disabled={!currentAddress}
              />
            )}
          </>
        )}
      </CurrentLocation>
      <SuccessMessagePopup
        open={isSuccess}
        onClose={onCloseSuccessPopup}
        message={`${t('addFacility:your')} ${getValues('facilityName')} ${t('addFacility:hasBeenAdded')}`}
        primaryButtonLabel={t('addFacility:goToMyCompany')}
        primaryAction={onPrimaryButtonClick}
        secondaryButtonLabel={t('addFacility:addAnotherFacility')}
        secondaryAction={onSecondaryButtonClick}
      />
    </>
  );
};

export default CurrentLocationCard;
