/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

export const Email = /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z-]+(?:\.[a-zA-Z0-9-]{2,})+$/;
export const VelocitiEmail =
  /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@(?!velocitiservices.com)[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{2,})+$/;
export const MultipleEmail =
  /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/;
export const WordsBetweenSpace = /^[^-\s][A-Za-z. -]*( [A-Za-z]+)*$/;
export const Instructions = /^[A-Za-z0-9,.&' "()-@]+$/;
export const NoSpecialCharactersAndNumbers = /^[A-Za-z\s.-]*$/;
export const NoSpecialCharacters = /^[A-Za-z\s.\d-]*$/;
export const PhoneNumber = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[\s.]?[0-9]{4,6}$/;
export const OnlyInteger = /^[0-9]+$/;
export const TitleWithDigitsAndSpecialCharacters = /^(?=[a-zA-Z0-9!@#$&'"\n-_*(){}~|`.•+,/ ”’‘“₹€£]*$)(?!.*[%])/;
export const TextOnly = /^[A-Z- a-z,.' 0-9 \n ]+$/;
