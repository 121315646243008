/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PageTitle from '../../../../Shared/Components/Common/PageTitle/PageTitle';
import { ConversationsPageContainer, ErrorMessage } from './ConversationsPage.Style';
import { getAuthData, getStoredCustomerDetails } from '../../../../Shared/Utilities/utils';
import { isEmpty } from 'lodash';
import theme from '../../../../Shared/Themes/theme';
import { CircularProgress } from '@mui/material';
import JobCreateApiService from '../../Services/JobCreateService';
import { ConversationInput, MessageType } from '../../../../API';
import { SubmittedBidStatus, UserType } from '../../../../Shared/Constants/App';
import MessageCard from './MessageCard';
import ThreadCard from './ThreadCard';
import ReplyBar from './ReplyBar';

const ConversationsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedConversationId, setSelectedConversationId] = useState<string>('');
  const [selectedConversation, setSelectedConversation] = useState<any>({});
  const [replyMsgContent, setReplyMsgContent] = useState<string>('');
  const [conversationsData, setConversationsData] = useState<any>([]);
  const [isConversationsDataLoading, setIsConversationsDataLoading] = useState<boolean>(false);
  const [bidSubmittedVendors, setBidSubmittedVendors] = useState<string[]>([]);
  const { role } = getAuthData();
  const { customerId = '' } = getStoredCustomerDetails();

  const handleReplyOnClick = (conversationId: string) => {
    const queryParams = new URLSearchParams({
      jobId: location.state?.jobId,
      conversationId: conversationId,
    });
    navigate(`/conversations?${queryParams.toString()}`, { state: { ...location.state } });
  };

  const getVendorIdForConversationPayload = () => {
    if (role !== UserType.Customer && customerId !== location.state?.jobCustodian) {
      return customerId;
    } else {
      return location.state.vendorId;
    }
  };

  const getConversationsData = async () => {
    setIsConversationsDataLoading(true);
    try {
      const payload: any = {};
      payload.organizationId = customerId;
      if (!location.state?.jobCustodian) {
        payload.jobId = location.state.jobId;
        payload.customerId = location.state?.customerId;
      } else if (location.state?.jobCustodian) {
        payload.customerId = location.state?.jobCustodian;
        if (customerId === location.state?.jobCustodian) {
          payload.jobId = location.state?.subJobId;
        } else {
          payload.jobId = location.state?.jobId;
        }
      }
      if (role !== UserType.Customer && customerId !== location.state?.jobCustodian) {
        payload.vendorId = customerId;
      }

      if (location.state?.tag && location.state?.tag === 'BID_RELATED' && location.state?.bidId) {
        if (customerId === location.state?.jobCustodian) {
          payload.bidId = location.state?.subJobBidId;
          // payload.bidVersion = location.state?.subJobBidVersion;
        } else {
          payload.bidId = location.state?.bidId;
          // payload.bidVersion = location.state?.bidVersion;
        }
        payload.vendorId = getVendorIdForConversationPayload();
      }
      const conversationsResponse = await JobCreateApiService.getConversations(payload);
      if (conversationsResponse.data && isEmpty(conversationsResponse.errors)) {
        await getBidsData();
        setConversationsData(conversationsResponse.data);
        getSelectedConversation(conversationsResponse.data);
      }
    } catch (error: any) {
      console.log('get conversations error: ', error);
    }
    setIsConversationsDataLoading(false);
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const getCreateConversationPayload = () => {
    const { customerId = '' } = getStoredCustomerDetails();

    const payLoad: ConversationInput = {
      message: replyMsgContent,
      messageType: MessageType.InApp,
      organizationId: customerId,
    };
    if (!location.state?.jobCustodian) {
      payLoad.customerId = location.state.customerId;
      payLoad.jobId = location.state.jobId;
    } else {
      payLoad.customerId = location.state?.jobCustodian;
      if (customerId === location.state?.jobCustodian) {
        payLoad.jobId = location.state?.subJobId;
      } else {
        payLoad.jobId = location.state?.jobId;
      }
    }
    if (role !== UserType.Customer && customerId !== location.state?.jobCustodian) {
      payLoad.vendorId = customerId;
    } else {
      payLoad.vendorId = selectedConversation.vendorId ?? location.state?.vendorId;
    }
    if (selectedConversationId && !isEmpty(selectedConversationId)) {
      payLoad.conversationId = selectedConversationId;
    }
    if (location.state?.tag && location.state?.tag === 'BID_RELATED') {
      if (customerId === location.state?.jobCustodian) {
        payLoad.bidId = location.state?.subJobBidId;
        // payLoad.bidVersion = location.state?.subJobBidVersion;
      } else {
        payLoad.bidId = location.state?.bidId;
        // payLoad.bidVersion = location.state?.bidVersion;
      }
    }

    return payLoad;
  };

  const handleSendButton = async () => {
    setIsConversationsDataLoading(true);
    try {
      const payload = getCreateConversationPayload();
      const response = await JobCreateApiService.createConversation(payload);
      if (response.data && isEmpty(response.errors)) {
        const conversationResponse = await JobCreateApiService.getConversations({
          conversationId: selectedConversationId,
        });
        if (conversationResponse.data && isEmpty(conversationResponse.errors) && conversationsData.length > 0) {
          setSelectedConversation(conversationResponse.data[0]);
        }
        setReplyMsgContent('');
        await getConversationsData();
      } else {
        console.log('response error', response.errors);
      }
    } catch (error: any) {
      console.log('send reply error: ', error);
    }
    setIsConversationsDataLoading(false);
  };

  const getSelectedConversation = (conversationsDetails: any) => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('conversationId')) {
      const conversationId: string = queryParams.get('conversationId')
        ? (queryParams.get('conversationId') as string)
        : '';
      setSelectedConversationId(conversationId);
      setSelectedConversation(
        conversationsDetails.find((conversation: any) => conversation.conversationId === conversationId)
      );
    } else {
      setSelectedConversationId('');
      setSelectedConversation({});
    }
  };

  const renderReplyField = () => {
    return (
      ((role !== UserType.Customer &&
        ((location.state.jobCustodian &&
          (customerId !== location.state.jobCustodian ||
            (customerId === location.state.jobCustodian &&
              location.state?.tag &&
              location.state?.tag === 'BID_RELATED'))) ||
          !location.state.jobCustodian)) ||
        (role === UserType.Customer && location.state?.tag && location.state?.tag === 'BID_RELATED')) &&
      ((!bidSubmittedVendors.includes(customerId) && !location.state.tag) ||
        (location.state.tag && location.state.tag === 'BID_RELATED')) &&
      location.state?.bidStatus !== SubmittedBidStatus.Rejected
    );
  };

  const getBidsData = async () => {
    const jobBidsResponse = await JobCreateApiService.getJobBids(location.state?.jobId);
    if (jobBidsResponse.data && jobBidsResponse.data.length > 0 && isEmpty(jobBidsResponse.errors)) {
      const vendorsList = jobBidsResponse.data.map((bid: any) => {
        return bid?.vendorId;
      });
      setBidSubmittedVendors(vendorsList);
    }
  };

  const showReplyBar = (conversation: any) => {
    return (
      ((!bidSubmittedVendors.includes(conversation?.vendorId) &&
        !(location.state?.tag && location.state?.tag === 'BID_RELATED')) ||
        (location.state?.tag && location.state?.tag === 'BID_RELATED')) &&
      location.state?.bidStatus !== SubmittedBidStatus.Rejected
    );
  };

  useEffect(() => {
    getConversationsData();
  }, []);

  useEffect(() => {
    getSelectedConversation(conversationsData);
  }, [location.search]);

  return (
    <>
      <PageTitle title={location.state?.jobName} />
      {!selectedConversationId && isEmpty(selectedConversation) ? (
        <>
          {isConversationsDataLoading ? (
            <CircularProgress size={30} sx={{ color: theme.palette.primary.dark, margin: '20% 45%' }} />
          ) : (
            <ConversationsPageContainer>
              {conversationsData && conversationsData.length > 0 ? (
                <>
                  {conversationsData.map((conversation: any, index: number) => {
                    return (
                      <MessageCard
                        conversation={conversation}
                        handleReplyOnClick={handleReplyOnClick}
                        enableReply={showReplyBar(conversation)}
                        key={index}
                      />
                    );
                  })}
                </>
              ) : (
                <>
                  <ErrorMessage>
                    There are no messages available from{' '}
                    {role === UserType.Customer || customerId === location.state?.jobCustodian ? 'vendor' : 'customer'}.
                  </ErrorMessage>
                  {renderReplyField() && (
                    <ReplyBar
                      replyMsgContent={replyMsgContent}
                      setReplyMsgContent={setReplyMsgContent}
                      isConversationsDataLoading={isConversationsDataLoading}
                      handleSendButton={handleSendButton}
                    />
                  )}
                </>
              )}
            </ConversationsPageContainer>
          )}
        </>
      ) : (
        <>
          <ConversationsPageContainer>
            <ThreadCard selectedConversation={selectedConversation} />
          </ConversationsPageContainer>
          {showReplyBar(selectedConversation) && (
            <ReplyBar
              replyMsgContent={replyMsgContent}
              setReplyMsgContent={setReplyMsgContent}
              isConversationsDataLoading={isConversationsDataLoading}
              handleSendButton={handleSendButton}
            />
          )}
        </>
      )}
    </>
  );
};

export default ConversationsPage;
