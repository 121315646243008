/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import React, { useCallback, useRef, useState } from 'react';
import {
  ButtonWrapper,
  CardSubTitle,
  CardTitle,
  CloseDialogBoxIcon,
  ProofsWrapper,
  RejectAndReworkCardWrapper,
  TextFieldLabel,
  buttonStyles,
} from './TaskRejectionAndRework.Style';
import { useTranslation } from 'react-i18next';
import { Icons } from '../../../../Shared/Constants/Icons';
import { Box, Button, Chip, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import theme from '../../../../Shared/Themes/theme';
import { DocumentType, IProofsProps, StorageKeys, UserType } from '../../../../Shared/Constants/App';
import { v4 as uuidv4 } from 'uuid';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import ApproveDialog from '../TaskDetails/logoutDialog';
import { getAuthData } from '../../../../Shared/Utilities/utils';

interface RejectWorkTask {
  openRejectTask: boolean;
  handleRejectTaskClose: () => void;
  taskName: string;
  rejectionComment: string;
  setRejectionComment: (comment: string) => void;
  isPartiallyCompleted: boolean;
  setIsPartiallyCompleted: (data: boolean) => void;
  proofs: IProofsProps[];
  setProofs: (proofs: IProofsProps[]) => void;
  handleDeleteProofs: (proofId: string) => void;
  handleRequestRework: () => void;
}

const RejectWorkTask = ({
  openRejectTask,
  handleRejectTaskClose,
  taskName,
  rejectionComment,
  setRejectionComment,
  isPartiallyCompleted,
  setIsPartiallyCompleted,
  proofs,
  setProofs,
  handleDeleteProofs,
  handleRequestRework,
}: RejectWorkTask) => {
  const { t } = useTranslation(['altTexts', 'logout', 'taskRejectionAndRework']);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const navigate = useNavigate();
  const { textField, requestReworkButton, cancelButton, buttonText, radioButtonText } = buttonStyles;
  const [isShowSessionExpirePopUp, setIsShowSessionExpirePopUp] = useState(false);
  const [isShowSessionExpirePopUpLoading, setIsShowSessionExpirePopUpLoading] = useState(false);
  const { role } = getAuthData();

  const handleDialogClose = (event: React.SyntheticEvent, reason?: string) => {
    if (reason && reason === 'backdropClick') return;
    handleRejectTaskClose();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles) {
      const fileDetails = Array.from(selectedFiles).map((file) => {
        const attachmentUniqueId = uuidv4();
        const fileNameSplits = file.name.split('.');
        return {
          url: URL.createObjectURL(file),
          fileName: fileNameSplits[0],
          fileExtension: fileNameSplits[fileNameSplits.length - 1],
          mediaType: file.type.includes('image') ? DocumentType.Image : DocumentType.Video,
          id: attachmentUniqueId,
          comment: '',
        };
      });
      setProofs([...proofs, ...fileDetails]);
    }
  };

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handlePartiallyCompleteCheckbox = () => {
    setIsPartiallyCompleted(!isPartiallyCompleted);
  };

  const signOut = useCallback(async () => {
    try {
      setIsShowSessionExpirePopUpLoading(true);
      await Auth.signOut();
      localStorage.removeItem(StorageKeys.Auth);
      localStorage.removeItem(StorageKeys.customerDetails);
      navigate('/login');
    } catch (error) {
      console.error('An error occurred during sign out:', error);
    } finally {
      setIsShowSessionExpirePopUpLoading(false);
    }
  }, []);

  const LogoutUI = () => {
    return (
      <div>
        {t('logout:logoutConfirm')} <br /> <strong>{t('logout:peazyApplication')}</strong>
      </div>
    );
  };

  return (
    <>
      <RejectAndReworkCardWrapper open={openRejectTask} onClose={handleDialogClose}>
        <CloseDialogBoxIcon onClick={handleRejectTaskClose}>
          <img src={Icons.InfoWindowCloseIcon} alt={t('altTexts:closeIcon')} />
        </CloseDialogBoxIcon>
        <CardTitle>{t('taskRejectionAndRework:rejectWorkTask')}</CardTitle>
        <CardSubTitle>
          {t('taskRejectionAndRework:areYouSureYouWantTo')}
          <span style={{ fontWeight: theme.typography.fontWeightBold }}>
            {t('taskRejectionAndRework:rejectWorkFor')}
            {taskName}
          </span>
          {t('taskRejectionAndRework:andRequestRework')}
        </CardSubTitle>
        <TextFieldLabel>{t('taskRejectionAndRework:reasonForRejecting')}</TextFieldLabel>
        <TextField
          sx={{ ...textField, marginBottom: '1em' }}
          value={rejectionComment}
          onChange={(e) => setRejectionComment(e.target.value)}
          multiline
          rows={3}
          maxRows={3}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton
                  sx={{ alignSelf: 'baseline', marginTop: '0.4rem' }}
                  onClick={() => {
                    const authInfo = localStorage.getItem('auth');
                    const { signedInTime } = JSON.parse(authInfo as string);
                    const isPreviousSignedSessionMoreThan_55_minutes =
                      new Date().getTime() - signedInTime > 1000 * 60 * 55; //checking 55 minutes difference from signed in time.
                    if (isPreviousSignedSessionMoreThan_55_minutes) {
                      setIsShowSessionExpirePopUp(true);
                    } else {
                      handleUploadClick();
                    }
                  }}>
                  <img src={Icons.AddProofIcon} alt={t('altTexts:upload')} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          accept="image/*,video/*"
          multiple
          onChange={handleFileChange}
        />
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '1rem' }}>
          {isPartiallyCompleted ? (
            <img
              src={Icons.CheckedIcon}
              alt={t('altTexts:checked')}
              onClick={handlePartiallyCompleteCheckbox}
              style={{ cursor: 'pointer', height: '1.5rem', width: '1.5rem' }}
            />
          ) : (
            <img
              src={Icons.Unchecked}
              alt={t('altTexts:unchecked')}
              onClick={handlePartiallyCompleteCheckbox}
              style={{ cursor: 'pointer' }}
            />
          )}
          <Typography
            sx={{
              ...radioButtonText,
              marginLeft: '0.5rem',
            }}>
            {t('taskRejectionAndRework:partiallyComplete')}
          </Typography>
        </Box>
        {proofs.length > 0 ? (
          <ProofsWrapper>
            {proofs.map((proof: IProofsProps, index: number) => {
              return (
                <Chip
                  key={index}
                  label={`${proof.fileName}.${proof.fileExtension}`}
                  onDelete={() => {
                    if (fileInputRef.current) {
                      fileInputRef.current.value = '';
                    }
                    handleDeleteProofs(proof.id as string);
                  }}
                  deleteIcon={<img src={Icons.DeleteIcon} alt={t('altTexts:delete')} />}
                  variant="outlined"
                />
              );
            })}
          </ProofsWrapper>
        ) : null}
        <ButtonWrapper>
          <Button
            sx={{
              ...cancelButton,
              cursor: 'pointer',
              maxWidth: '100%',
              marginTop: '2em',
              width: '100%',
            }}
            onClick={handleRejectTaskClose}>
            <Typography sx={{ ...buttonText, color: theme.palette.primary.dark }}>
              {t('taskRejectionAndRework:cancel')}
            </Typography>
          </Button>
          <Button
            sx={{
              ...requestReworkButton,
              maxWidth: '100%',
              marginTop: '2em',
              width: '100%',
            }}
            onClick={handleRequestRework}>
            <Typography sx={{ ...buttonText, color: theme.palette.common.white }}>
              {(role as UserType) === UserType.Customer
                ? t('taskRejectionAndRework:requestRework')
                : t('taskRejectionAndRework:scheduleRework')}
            </Typography>
          </Button>
        </ButtonWrapper>
      </RejectAndReworkCardWrapper>
      <ApproveDialog
        title={t('logout:sessionTimedOutTitle')}
        loading={isShowSessionExpirePopUpLoading}
        ChildComponent={<LogoutUI />}
        isApproveWindowOpen={isShowSessionExpirePopUp}
        showCancelButton={false}
        isShowCloseIcon={false}
        handleCloseApproveWindow={() => setIsShowSessionExpirePopUp(false)}
        handleApprove={() => signOut()}
      />
    </>
  );
};

export default RejectWorkTask;
