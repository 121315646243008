/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Button, CircularProgress, IconButton, Typography } from '@mui/material';
import { MRT_ColumnDef } from 'material-react-table';
import { useForm, useWatch } from 'react-hook-form';
import { isEmpty } from 'lodash';

import Accordion, { ScreenName } from '../../../../Shared/Components/Common/Accordion/Accordion';
import {
  convertToReadableFormat,
  uploadDocumentToS3Bucket,
  getAuthData,
  getStoredCustomerDetails,
  getRescheduleShiftErrorMessage,
  formatDate,
} from '../../../../Shared/Utilities/utils';
// import { UserType } from '../../../../Shared/Services/AnalyticsService';

import { IJobOverview } from '../JobsListView/JobsListView';
import { IJobs } from '../JobView/JobsView';
import ApproveDialog from '../TaskDetails/ApproveDialog';
import RejectDialog from '../TaskDetails/RejectDialog';
import SnackBar from '../../../../Shared/Components/Common/SnackBar/SnackBar';
import { useAuth } from '../../../../Configuration/AuthContext';
import { IServices, Task } from '../../../../Shared/Models/WorkTask.model';
import WorkOrdersApiService from '../../Services/WorkOrdersService';
import { ErrorMessage } from '../../../../Shared/Components/Common/ErrorMessage/ErrorMessage';
import DateSelection from '../../../../Shared/Components/Common/DatePicker/DateSelection';
import {
  AttachmentStatus,
  CreateTaskAttachmentInput,
  mdAttachmentType,
  mdFileContext,
  UpdateTaskInput,
  WorkOrderYesNoType,
  TaskStatus,
  ApprovalType,
  CreateTaskApprovalInput,
  ApprovalStatus,
  TaskScheduleType,
  RescheduleWorkDayInput,
} from '../../../../API';

import { DatePickerWrapper } from '../../../../Shared/Components/Common/GoogleMap/GoogleMapChips.Style';
import {
  ActionIcon,
  ActionsColumn,
  Linking,
  Menus,
  MenuWrapper,
} from '../../../../Shared/Components/Common/DataGrid/DataGrid.styles';
import {
  buttonStyles,
  DropdownWrapper,
  NoDataTag,
  NoPendingTaskContainer,
  Services,
  TitleWrapper,
} from './JobDetailsTasks.styles';
import { Loader } from '../JobView/JobsView.styles';

import { Icons } from '../../../../Shared/Constants/Icons';
import theme from '../../../../Shared/Themes/theme';
import { ApproverType } from '../../../../Shared/Models';
import { IBidList } from '../JobDetails/OneTimeJobDetails';
import { IProfileCardProps } from '../../../Customer/Components/ViewFacilityDetails/ViewFacilityDetails';
import { VendorType } from '../../../../Shared/Models/Vendors.Models';
import { IProofsProps, DocumentType, UserType } from '../../../../Shared/Constants/App';
import RejectWorkTask from '../TaskRejectionAndRework/RejectWorkTask';
import RequestOrScheduleRework from '../TaskRejectionAndRework/RequestOrScheduleRework';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import SnackbarMessage from '../../../Onboarding/Components/Login/SnackbarMessage';
import UpdateShiftDateCard from '../UpdateShiftDate/UpdateShiftDate';

export interface IEachOptionsProps {
  completedBy?: string;
  floorNumber?: number;
  id: string;
  isProofOfCompletionRequired?: WorkOrderYesNoType;
  isTaskCompleted?: boolean;
  proof?: boolean;
  quantity?: number;
  quantityName?: string;
  status?: string;
  taskId: string;
  taskScheduleId?: string;
  timeTaken?: string;
  title: string;
  workDayId?: string;
}

export interface IDataProps {
  category?: string;
  categoryTitle?: string;
  id?: string;
  options?: IEachOptionsProps[];
  serviceImage?: string;
  totalCompletedTasks?: number;
  totalTasks?: number;
}

export interface TaskData {
  no: number;
  taskName: string;
  floor: string;
  timeTaken: string;
  status: string;
  comments: string;
  proof: string;
  date: string;
  taskId: string;
  totalTasks?: number;
  approvedByCustomer?: number;
  proofTypes: {
    photo: number;
    audio: number;
    video: number;
  };
  sortingFn?: any;
  jobId?: string;
  executionType?: string;
  customerId?: string;
}

interface ICategoryList {
  id: number;
  serviceName: string;
  mdServiceId: string;
  iconUrl: string;
  shiftType: string;
  shiftTiming: string;
}
[];

interface IJobData {
  address: any;
  id: number;
  facilityName: string;
  facilityId: string;
  jobId: string;
  location: string;
  FacilityIcon: string;
  buildingName: string;
  Jobname: string;
  startTime: string;
  endTime: string;
  vendorId: string;
}

interface IJobDetailsTasksProps {
  servicesList: ICategoryList[];
  selectedJob: string;
  selectedFacility: IJobData;
  vendorTypes: any;
  newDate: any;
}

enum ShiftType {
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
}

enum TaskType {
  ReworkTask = 'ReworkTask',
  SagaTask = 'SagaTask',
}

function getNextDay(dateString: string): string {
  // Parse the input date string
  const date = new Date(dateString);

  // Add one day (24 hours) to the date
  date.setDate(date.getDate() + 1);

  // Format the new date to the desired string format (ISO 8601)
  return date.toISOString();
}

const getTaskDuration = (durationInMinutes: number) => {
  if (durationInMinutes < 60) {
    return `${durationInMinutes} min`;
  } else {
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = durationInMinutes % 60;
    return minutes > 0 ? `${hours} ${hours > 1 ? 'hrs' : 'hr'} ${minutes} min` : `${hours} ${hours > 1 ? 'hrs' : 'hr'}`;
  }
};

/**
 * @returns JobDetailsTasks Page
 */
const JobDetailsTasks = ({ selectedJob, selectedFacility, newDate, vendorTypes }: IJobDetailsTasksProps) => {
  const { t } = useTranslation(['altTexts', 'taskRejectionAndRework', 'rescheduleShiftDate']);
  const { updateShiftDateButton, buttonText } = buttonStyles;
  const [expanded, setExpanded] = useState<number | false>(0);
  const schedule = 'all';
  const [serviceList, setServiceList] = useState<IServices[]>([]);
  const originalServices: IServices[] = [];
  const [isOpenApprove, setIsOpenApprove] = useState(false);
  const [isOpenReject, setIsOpenReject] = useState(false);
  const [isOpenApproveAll, setIsOpenApproveAll] = useState(false);
  const [isOpenRejectAll, setIsOpenRejectAll] = useState(false);
  const [rowInfo, setRowInfo] = useState<any>({});
  const [isAPIError, setIsAPIError] = useState<boolean>(false);
  const [dailyTaskData, setDailyTaskData] = useState<any[]>([]);
  const [reworkTaskData, setReworkTaskData] = useState<any[]>([]);
  const [isViewLoading, setIsViewLoading] = useState<boolean>(true);
  const [approveByRole, setApproveByRole] = useState(ApproverType.Supervisor);
  const [accordionID, setSelectedAccordionID] = useState('');
  const [isApproving, setIsApproving] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const [isOtjJob, setIsOtjJob] = useState<boolean>(false);
  const [rejectionComment, setRejectionComment] = useState<string>('');
  const [isPartiallyCompleted, setIsPartiallyCompleted] = useState<boolean>(false);
  const [openRejectTask, setOpenRejectTask] = useState<boolean>(false);
  const [proofs, setProofs] = useState<IProofsProps[]>([]);
  const [openRequestRework, setOpenRequestRework] = useState<boolean>(false);
  const [reworkShift, setReworkShift] = useState<TaskScheduleType | null>(null);
  const [reworkScheduleDate, setReworkScheduleDate] = useState<string>('');
  const [workOrderEndDate, setWorkOrderEndDate] = useState<any>(null);
  const [isReworkLoading, setIsReworkLoading] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [selectedTaskType, setSelectedTaskType] = useState<TaskType>(TaskType.SagaTask);
  const [jobStartDate, setJobStartDate] = useState<string>('');
  const [jobEndDate, setJobEndDate] = useState<string>('');
  const [sendingForApproval, setSendingForApproval] = useState<boolean>(false);
  const [showUpdateShiftDate, setShowUpdateShiftDate] = useState<boolean>(false);
  const [openUpdateShiftDate, setOpenUpdateShiftDate] = useState<boolean>(false);
  const [isShowUpdateShiftDateLoading, setIsShowUpdateShiftDateLoading] = useState<boolean>(false);
  const [isViewOnly, setIsViewOnly] = useState<boolean>(false);
  const [workDayId, setWorkDayId] = useState<string | null>(null);
  const [subWorkOrderId, setSubWorkOrderId] = useState<string | null>(null);
  const [workOrderId, setWorkOrderId] = useState<string | null>(null);
  const [disableUpdateShiftDate, setDisableUpdateShiftDate] = useState<boolean>(false);
  const [isShiftDetailsLoading, setIsShiftDetailsLoading] = useState<boolean>(false);
  const [rescheduledShiftDate, setRescheduledShiftDate] = useState<string | null>(null);
  const [jobCustodian, setJobCustodian] = useState<string | null>(null);

  const currentDate = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(currentDate.getDate() + 1);
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const authValue = localStorage.getItem('auth');
  let role: UserType;
  if (authValue !== null) {
    const authData = JSON.parse(authValue);
    role = authData?.attributes?.['custom:Role'];
  } else {
    role = UserType.NoUser;
  }
  const { isDistrictManager } = getAuthData();

  const { control, setValue } = useForm({
    defaultValues: {
      specificDate: new Date().toISOString(),
    },
  });

  const specificDate = useWatch({
    control,
    name: 'specificDate',
  });

  const {
    handleApprovalAll,
    handleRejectAll,
    handleReject,
    handleApprove,
    setSelectedTaskId,
    selectedTaskId,
    setSnackBarStatus,
    snackBarStatus,
    isApproveLoading,
  } = useAuth();

  const noDataImageStyles: React.CSSProperties = {
    width: '20rem',
  };

  const getTypeCount = (proofs: any) => {
    let photo = 0,
      video = 0,
      audio = 0;
    proofs.forEach((dataItem: any) => {
      if (
        dataItem.mdAttachmentType === mdAttachmentType.Photo ||
        dataItem.mdAttachmentType === mdAttachmentType.Image
      ) {
        photo++;
      } else if (dataItem.mdAttachmentType === mdAttachmentType.Video) {
        video++;
      } else if (dataItem.mdAttachmentType === mdAttachmentType.Audio) {
        audio++;
      }
    });
    return {
      photo: photo,
      audio: audio,
      video: video,
    };
  };

  const getOtjJobTaskStatus = (status: TaskStatus) => {
    if (vendorTypes[0]?.mdVendorType === VendorType.SelfPerform && status === TaskStatus.Complete) {
      return TaskStatus.PendingPrimeVendorApproval;
    } else if (vendorTypes[0]?.mdVendorType === VendorType.SelfPerform && status === TaskStatus.ApprovedByPrimeVendor) {
      return TaskStatus.ApprovedByPrimeVendor;
    } else if (vendorTypes[0]?.mdVendorType !== VendorType.SelfPerform && status === TaskStatus.ApprovedByPrimeVendor) {
      return TaskStatus.ApprovedByPrimeVendor;
    } else if (vendorTypes[0]?.mdVendorType !== VendorType.SelfPerform && status === TaskStatus.Complete) {
      return 'PendingSupervisorApproval'; //TaskStatus.PendingPrimeVendorApproval;
    } else if (
      vendorTypes[0]?.mdVendorType !== VendorType.SelfPerform &&
      status === TaskStatus.PendingPrimeVendorApproval
    ) {
      return TaskStatus.PendingPrimeVendorApproval;
    }
    return status;
  };

  const getTaskStatus = (status: TaskStatus, jobCustodian: string | null) => {
    if (isOtjJob || jobCustodian) {
      return getOtjJobTaskStatus(status);
    } else {
      if (status === TaskStatus.Complete) {
        return 'PendingSupervisorApproval'; // TaskStatus.PendingSupervisorApproval;
      }
    }
    return status;
  };

  const getCompletedTasksCount = (tasks: any, reworkTaskIdCheck: boolean) => {
    return tasks.filter(
      (task: any) =>
        task.markedAsDone === WorkOrderYesNoType.Yes &&
        task.status !== TaskStatus.ReworkRequestedByCustomer &&
        task.status !== TaskStatus.Rework &&
        task.status !== TaskStatus.Scheduled &&
        task.status !== TaskStatus.InProgress &&
        task.status !== TaskStatus.OnHold &&
        task.status !== TaskStatus.Rejected &&
        task.status !== TaskStatus.RejectedByCustomer &&
        task.status !== TaskStatus.RejectedByPrimeVendor &&
        task.status !== TaskStatus.RejectedBySupervisor &&
        (reworkTaskIdCheck ? !!task.reworkTaskId : !task.reworkTaskId)
    ).length;
  };

  const getTotalTasksPerService = (tasks: any, reworkTaskCheck: boolean) => {
    return tasks.filter(
      (task: any) => task.status !== TaskStatus.Rework && (reworkTaskCheck ? !!task.reworkTaskId : !task.reworkTaskId)
    ).length;
  };

  const getTasksDetails = (tasksV2Response: any, reworkTaskCheck: boolean, jobCustodian: string | null) => {
    const taskDetails = tasksV2Response.map((service: any) => {
      return {
        category: service.categoryDescription,
        categoryTitle: service.serviceCategoryName,
        id: service.mdServiceId,
        serviceImage: service.categoryImageUrl,
        taskCompleted: reworkTaskCheck
          ? getCompletedTasksCount(service.tasks, true)
          : getCompletedTasksCount(service.tasks, false),
        totalScheduledTasks: service.tasks.length,
        totalTasks: getTotalTasksPerService(service.tasks, reworkTaskCheck),
        options: service.tasks
          .filter((task: any) =>
            reworkTaskCheck
              ? !!task.reworkTaskId && task.status !== TaskStatus.Rework
              : !task.reworkTaskId && task.status !== TaskStatus.Rework
          )
          .map((task: any, taskIndex: number) => {
            return {
              ...task,
              actualEndTime: task.actualEndTime,
              actualStartTime: task.actualStartTime,
              associateId: task.associateId,
              comments: '',
              floor: '',
              isTaskCompleted:
                task.markedAsDone === WorkOrderYesNoType.Yes &&
                task.status !== TaskStatus.ReworkRequestedByCustomer &&
                task.status !== TaskStatus.Rework &&
                (reworkTaskCheck ? !!task.reworkTaskId : !task.reworkTaskId),
              no: taskIndex + 1,
              proof: task.taskAttachments.length,
              proofTypes: task.taskAttachments.length
                ? getTypeCount(task.taskAttachments)
                : { photo: 0, audio: 0, video: 0 },
              proofattachments: task.taskAttachments,
              status: getTaskStatus(task.status, jobCustodian),
              taskId: task.taskId,
              taskName: task.taskName,
              timeTaken: getTaskDuration(task.duration ?? 0),
              workDayId: task.workDayId,
            };
          }),
      };
    });
    return taskDetails.filter((service: any) => !isEmpty(service.options));
  };

  const getTasksV2Records = (tasksV2Response: any, jobCustodian: string | null) => {
    const taskDetails = getTasksDetails(tasksV2Response, false, jobCustodian);
    setDailyTaskData([...taskDetails]);
    if (taskDetails.length) {
      setSelectedAccordionID(taskDetails[0].id);
    }

    const reworkTaskDetails = getTasksDetails(tasksV2Response, true, jobCustodian);
    setReworkTaskData([...reworkTaskDetails]);
    if (reworkTaskDetails.length && isEmpty(taskDetails)) {
      setSelectedAccordionID(reworkTaskDetails[0].id);
    }
  };

  const getRoletoApprove = (parentId: string, hasChild: boolean, jobCustodian: string | null) => {
    const { customerId } = getStoredCustomerDetails();
    // if (parentId || hasChild) {
    //   setApproveByRole(ApproverType.PrimeVendor);
    // }
    if (jobCustodian && jobCustodian === customerId) {
      setApproveByRole(ApproverType.PrimeVendor);
    }

    return null;
  };

  const fetchTasks = useCallback(async (date: any) => {
    try {
      setIsViewLoading(true);
      const workorderResponse = await WorkOrdersApiService.getAllWorkOrders({ jobId: selectedJob });
      if (workorderResponse?.data?.[0]?.jobStartDate) {
        const startDate = new Date(workorderResponse.data[0].jobStartDate);
        const endDate = new Date(workorderResponse.data[0].jobEndDate);
        const formattedStartDate = formatDate(startDate.toISOString());
        const formattedEndDate = formatDate(endDate.toISOString());
        setJobStartDate(formattedStartDate);
        setJobEndDate(formattedEndDate);
      }
      if (workorderResponse.data?.length) {
        setWorkOrderEndDate(new Date(workorderResponse?.data?.[0].actualEndDate));
        const workOrderId = workorderResponse?.data?.[0].workOrderId;
        const subWorkOrderId = workorderResponse?.data?.[0].subWorkOrders.length
          ? workorderResponse?.data?.[0].subWorkOrders[0].workOrderId
          : null;
        const { parentWorkOrderId, hasChild } = workorderResponse?.data?.[0] || {};
        setIsOtjJob(!!workorderResponse?.data?.[0].jobCustodian);
        setJobCustodian(workorderResponse?.data?.[0].jobCustodian);
        getRoletoApprove(parentWorkOrderId, hasChild, workorderResponse?.data?.[0].jobCustodian);

        const tasksV2Response = await WorkOrdersApiService.getTasksV2(
          { workOrderId: subWorkOrderId || workOrderId, fromDate: date, toDate: date },
          false
        );
        if (tasksV2Response.data && isEmpty(tasksV2Response.errors)) {
          getTasksV2Records(tasksV2Response.data, workorderResponse?.data?.[0].jobCustodian);
        }
      }
      setIsViewLoading(false);
    } catch (error) {
      console.error('Error', error);
      setIsAPIError(true);
      setIsViewLoading(false);
    }
  }, []);

  const handleUpdateShiftDetails = async (workOrderId: string, fromDate: string) => {
    const workOrderTeamResponse = await WorkOrdersApiService.getWorkOrderTeam(
      workOrderId,
      fromDate,
      fromDate // To get workDayId we need to pass fromDate & toDate as same day.
    );
    if (!isEmpty(workOrderTeamResponse?.data)) {
      setWorkDayId(workOrderTeamResponse?.data[0]?.workDayId);
      if (workOrderTeamResponse?.data[0]?.workDayId && !isEmpty(workOrderTeamResponse?.data[0]?.workDayId ?? '')) {
        const workDayPunchInRecords = await WorkOrdersApiService.getAllPunchInOutByWorkDay({
          userId: 'all',
          workDayId: workOrderTeamResponse?.data[0]?.workDayId ?? '',
        });
        if (isEmpty(workDayPunchInRecords?.data ?? []) && isEmpty(workDayPunchInRecords?.errors ?? [])) {
          setShowUpdateShiftDate(true);
        } else {
          setShowUpdateShiftDate(false);
        }
      } else {
        setShowUpdateShiftDate(false);
      }
    } else if (!isEmpty(workOrderTeamResponse.errors)) {
      setShowUpdateShiftDate(false);
      setSnackbarMessage(workOrderTeamResponse.errors[0].message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const getWorkOrderIdForFindWorkDay = (subWorkOrderId: string | null, workOrderId: string) => {
    return subWorkOrderId ? subWorkOrderId : workOrderId;
  };

  const updateShiftDetailsOptions = async (fromDate: string) => {
    setIsShiftDetailsLoading(true);
    try {
      const workorderResponse = await WorkOrdersApiService.getAllWorkOrders({ jobId: selectedJob });
      if (workorderResponse.data && !isEmpty(workorderResponse.data)) {
        const workOrderId = workorderResponse?.data?.[0].workOrderId;
        const subWorkOrderId = workorderResponse?.data?.[0].subWorkOrders.length
          ? workorderResponse?.data?.[0].subWorkOrders[0].workOrderId
          : null;
        setWorkOrderId(workOrderId);
        setSubWorkOrderId(subWorkOrderId);
        const { hasChild } = workorderResponse?.data?.[0] || {};
        setIsViewOnly(hasChild);

        const findWorkDayResponse = await WorkOrdersApiService.findWorkDay(
          getWorkOrderIdForFindWorkDay(subWorkOrderId, workOrderId),
          fromDate
        );
        if (!isEmpty(findWorkDayResponse.data) && isEmpty(findWorkDayResponse.errors)) {
          setDisableUpdateShiftDate(findWorkDayResponse.data.isShiftRescheduled);
          setRescheduledShiftDate(findWorkDayResponse.data.workDate);
          setShowUpdateShiftDate(findWorkDayResponse.data.isShiftRescheduled);
        }
        if (!findWorkDayResponse.data.isShiftRescheduled) {
          await handleUpdateShiftDetails(hasChild ? subWorkOrderId : workOrderId || '', fromDate);
        }
      }
    } catch (e: any) {
      console.log(e);
    }
    setIsShiftDetailsLoading(false);
  };

  const handleRescheduleWorkDayResponse = (rescheduleWorkDaysResponse: any) => {
    if (
      !isEmpty(rescheduleWorkDaysResponse?.data?.rescheduleFailure ?? []) &&
      isEmpty(rescheduleWorkDaysResponse?.errors ?? [])
    ) {
      const errorMsg = `${t('rescheduleShiftDate:failedToRescheduleShift')}. ${t(getRescheduleShiftErrorMessage(rescheduleWorkDaysResponse?.data?.rescheduleFailure?.[0]?.errorCode ?? ''))}`;
      setSnackbarMessage(errorMsg);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } else if (
      !isEmpty(rescheduleWorkDaysResponse?.data?.rescheduleSuccess ?? []) &&
      isEmpty(rescheduleWorkDaysResponse?.data?.rescheduleFailure ?? []) &&
      isEmpty(rescheduleWorkDaysResponse?.errors ?? [])
    ) {
      setSnackbarMessage(t('rescheduleShiftDate:shiftReschedulingSuccessMsg'));
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      const formattedDate = new Date(specificDate).toISOString().split('T')[0];
      updateShiftDetailsOptions(formattedDate);
    } else if (!isEmpty(rescheduleWorkDaysResponse?.errors ?? [])) {
      setSnackbarMessage(rescheduleWorkDaysResponse?.errors[0]?.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleOnUpdateShiftDate = async (startDate: string) => {
    setIsShowUpdateShiftDateLoading(true);
    try {
      const rescheduleWorkDayInput: RescheduleWorkDayInput = {
        newDate: startDate,
        workDayId: workDayId ? workDayId : '',
      };

      const mSubWorkOrderId = subWorkOrderId ?? '';
      const mWorkOrderId = workOrderId ?? '';
      const rescheduleWorkDaysResponse = await WorkOrdersApiService.rescheduleWorkDays(
        isViewOnly ? mSubWorkOrderId : mWorkOrderId,
        [rescheduleWorkDayInput]
      );
      handleRescheduleWorkDayResponse(rescheduleWorkDaysResponse);
      setOpenUpdateShiftDate(false);
    } catch (e: any) {
      console.log(e);
      setOpenUpdateShiftDate(false);
    } finally {
      setIsShowUpdateShiftDateLoading(false);
      setOpenUpdateShiftDate(false);
    }
  };

  const dataFiltering = useCallback(() => {
    let filteredData: IServices[] = [];
    if (schedule === 'all') {
      filteredData = originalServices;
    } else {
      filteredData = originalServices.filter((dataItem) => {
        if (schedule === 'daily') {
          return (dataItem?.shiftTiming as ShiftType) === ShiftType.Daily;
        } else if (schedule === 'weekly') {
          return (dataItem?.shiftTiming as ShiftType) === ShiftType.Weekly;
        } else if (schedule === 'monthly') {
          return (dataItem?.shiftTiming as ShiftType) === ShiftType.Monthly;
        }
        return false;
      });
    }

    return filteredData;
  }, [schedule, originalServices, expanded]);

  const handleAccordion = (panel: number, id: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setSelectedAccordionID(id);
    setExpanded(newExpanded ? panel : false);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const showApproveOrRejectButtons = (status: string) => {
    return (
      (role === UserType.Customer && (status as TaskStatus) === TaskStatus.PendingCustomerReview) ||
      (role === UserType.Vendor &&
        isDistrictManager &&
        (status as TaskStatus) === TaskStatus.PendingPrimeVendorApproval) ||
      (role === UserType.Vendor && !isDistrictManager && status === 'PendingSupervisorApproval') //TaskStatus.PendingSupervisorApproval))
    );
  };

  const getTaskType = (reworkTaskId: any) => {
    return reworkTaskId ? TaskType.ReworkTask : TaskType.SagaTask;
  };

  const handleOpenRejectTask = () => {
    if (isOtjJob && vendorTypes[0]?.mdVendorType === VendorType.SelfPerform) {
      setIsOpenReject(true);
    } else {
      setOpenRejectTask(true);
    }
  };

  const isOtjSelfPerformJob = () => {
    return isOtjJob && vendorTypes[0]?.mdVendorType === VendorType.SelfPerform;
  };

  const renderRejectButton = (taskId: string, row: any) => {
    return (
      <>
        {!isOtjSelfPerformJob() ? (
          <>
            {taskId === rowInfo.taskId && isRejecting ? (
              <Loader>
                <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
              </Loader>
            ) : (
              <ActionIcon
                onClick={() => {
                  handleOpenRejectTask();
                  setRowInfo(row.original);
                }}
                src={Icons.CancelIcon}
                alt={t('altTexts:reject')}
              />
            )}
          </>
        ) : null}
      </>
    );
  };

  const renderActionsCellContent = (row: any) => {
    const status = row.original.status;
    const taskId = row.original.taskId;

    return (
      <ActionsColumn>
        {showApproveOrRejectButtons(status) ? (
          <>
            {taskId === rowInfo.taskId && isApproving ? (
              <Loader>
                <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
              </Loader>
            ) : (
              <ActionIcon
                onClick={() => {
                  setIsOpenApprove(true);
                  setRowInfo(row.original);
                  const taskType = getTaskType(row.original.reworkTaskId);
                  setSelectedTaskType(taskType);
                }}
                src={Icons.TickIcon}
                alt={t('altTexts:approve')}
              />
            )}
            {renderRejectButton(taskId, row)}
          </>
        ) : null}
        {role === UserType.Vendor || role === UserType.Customer ? (
          <>
            <IconButton
              sx={{ padding: '0px' }}
              onClick={(e) => {
                handleClick(e);
                setRowInfo(row.original);
              }}
              size="large"
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}>
              <MoreVertIcon></MoreVertIcon>
            </IconButton>
            <MenuWrapper anchorEl={anchorEl} id="account-menu" open={open} onClose={handleClose} onClick={handleClose}>
              <Linking to="/task-details" state={{ taskRecord: rowInfo, vendorTypes, approveByRole }}>
                <Menus>
                  <img src={Icons.EyeIcon} alt={t('altTexts:viewDetails')} /> View details
                </Menus>
              </Linking>
              {/* <Menus onClick={() => setIsOpenApprove(true)}>
                <img src={Icons.TickIcon} alt={t('altTexts:approve')} /> Approve
              </Menus>
              <Menus onClick={() => setIsOpenReject(true)}>
                <img src={Icons.CancelIcon} alt={t('altTexts:reject')} /> Reject
              </Menus> */}
            </MenuWrapper>
          </>
        ) : null}
      </ActionsColumn>
    );
  };

  const columns: MRT_ColumnDef<TaskData | IJobOverview | IJobs | IBidList | IProfileCardProps>[] = [
    {
      accessorKey: 'no',
      header: 'No.',
      enablePinning: false, //disable column pinning for this column
      enableSorting: false,
      size: 10,
      enableColumnFilter: false,
      enableColumnActions: false,
    },
    {
      accessorKey: 'taskName',
      header: 'Task',
      enableSorting: true,
      enableColumnFilter: false,
      size: 140,
      Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : '-'),
      enableColumnActions: true,
    },
    {
      accessorKey: 'status',
      header: 'Status',
      enableSorting: true,
      enableColumnFilter: false,
      size: 162,
      Cell: ({ renderedCellValue }) => {
        let selectedColor = theme.palette.text.secondary;
        if (
          renderedCellValue === 'Issue raised' ||
          renderedCellValue === 'RejectedBySupervisor' ||
          renderedCellValue === 'RejectedByCustomer'
        ) {
          selectedColor = theme.palette.error.main;
        } else if (renderedCellValue === 'ApprovedByCustomer') {
          selectedColor = theme.palette.success.main;
        }
        if (renderedCellValue) {
          return <span style={{ color: selectedColor }}>{convertToReadableFormat(renderedCellValue)}</span>;
        } else {
          return '-';
        }
      },
    },
    {
      accessorKey: 'proof',
      header: 'Proof',
      enableSorting: false,
      enableColumnFilter: false,
      size: 100,
      Cell: ({ renderedCellValue }) =>
        renderedCellValue || renderedCellValue === 0 ? (
          <span style={{ color: theme.palette.primary.dark }}>{renderedCellValue}</span>
        ) : (
          '-'
        ),
      enableColumnActions: false,
    },
    {
      header: 'Actions',
      enableColumnActions: false,
      enablePinning: false,
      size: 130,
      Cell: ({ row }) => renderActionsCellContent(row),
    },
  ];

  const onChangeRow = useCallback(
    (rowSelection: any, data: TaskData[], service: IServices) => {
      const allRowSelected = Object.keys(rowSelection);
      const taskIds: any = data.filter(
        (dataItem, index) => allRowSelected?.includes(index.toString()) && dataItem.taskId
      );
      //Adding timeout to avoid re rendering because fraction of second allRowSelected and taskIds become empty
      // and because of that approve and reject button gives jerk
      setTimeout(() => {
        setSelectedTaskId(taskIds);
        const isShow = allRowSelected.length && data.length && allRowSelected.length === data.length ? true : false;
        const updatedServiceList = serviceList.map((dataItem) => {
          if (service.id === dataItem.id) {
            return {
              ...dataItem,
              isShowButton: isShow,
              selectedRowCount: taskIds.length,
            };
          }
          return dataItem;
        });
        setServiceList(updatedServiceList);
      }, 0);
    },
    [serviceList]
  );

  const getApproveStatusByRole = (status: TaskStatus) => {
    if (status === TaskStatus.PendingPrimeVendorApproval) {
      return TaskStatus.ApprovedByPrimeVendor;
    } else if (status === TaskStatus.PendingCustomerReview) {
      return TaskStatus.ApprovedByCustomer;
    } else {
      return TaskStatus.ApprovedBySupervisor;
    }
  };

  const getRejectStatusByRole = (status: TaskStatus) => {
    if (status === TaskStatus.PendingPrimeVendorApproval) {
      return TaskStatus.RejectedByPrimeVendor;
    } else if (status === TaskStatus.PendingCustomerReview) {
      return TaskStatus.RejectedByCustomer;
    } else {
      return TaskStatus.RejectedBySupervisor;
    }
  };

  const handleRejectTaskClose = () => {
    setOpenRejectTask(false);
    setRejectionComment('');
    setProofs([]);
    setIsPartiallyCompleted(false);
  };

  const handleRejectTask = () => {
    setOpenRejectTask(false);
    if (rowInfo.isCurrentShiftAvailable) {
      setReworkShift(TaskScheduleType.CurrentShift);
      setReworkScheduleDate(new Date().toISOString());
    } else if (rowInfo.nextAvailableWorkDay && !rowInfo.isCurrentShiftAvailable) {
      setReworkShift(TaskScheduleType.NextWorkDay);
      setReworkScheduleDate(rowInfo?.nextAvailableWorkDay?.workDate);
    } else {
      setReworkShift(TaskScheduleType.SpecificDay);
      setReworkScheduleDate(tomorrow.toISOString());
    }
    setOpenRequestRework(true);
  };

  const handleDeleteProofs = (proofId: string) => {
    setProofs(proofs.filter((proof: IProofsProps) => proof.id !== proofId));
  };

  const handleRequestReworkClose = () => {
    setOpenRequestRework(false);
    setRejectionComment('');
    setProofs([]);
    setIsPartiallyCompleted(false);
  };

  const storeAttachmentsToS3Cloud = async (proofs: IProofsProps[] = []) => {
    try {
      const failedAttachmentIds: any = [];
      const successAttachments: any = [];
      await Promise.allSettled(
        proofs.map(async (fileItem: IProofsProps, index: number) => {
          const uniqueAttachmentId = uuidv4();
          const uploadDocumentResponse = await uploadDocumentToS3Bucket(
            navigate,
            `fulfillment/workOrders/${rowInfo?.workOrderId}/${rowInfo?.workDayId}/${rowInfo?.taskId}/attachment-${
              index + 1
            }-${uniqueAttachmentId}.${fileItem?.fileExtension}`,
            fileItem.url ?? '',
            {
              level: 'public',
            },
            fileItem?.fileExtension
          );
          successAttachments.push({ key: uploadDocumentResponse?.file?.key || '', id: fileItem?.id });
        })
      );
      return { successAttachments, failedAttachmentIds };
    } catch (e) {
      console.log('upload attachments error: ', e);
      return { successAttachments: [], failedAttachmentIds: [] };
    }
  };

  const getProofAttachmnetFiles = (successAttachments: any) => {
    let files: CreateTaskAttachmentInput[] = proofs.map((fileItem: IProofsProps) => {
      const s3BucketUrl = successAttachments?.find((file: any) => file?.id === fileItem?.id) ?? '';

      return {
        attachmentStatus: role === UserType.Customer ? AttachmentStatus.ReworkRequested : AttachmentStatus.Rework,
        mdAttachmentType: fileItem.mediaType === DocumentType.Video ? mdAttachmentType.Video : mdAttachmentType.Image,
        fileName: fileItem?.fileName ?? '',
        mdFileContext: role === UserType.Customer ? mdFileContext.ProofOfReworkRequested : mdFileContext.ProofOfRework,
        fileExtension: fileItem?.fileExtension as string,
        url: s3BucketUrl?.key ?? '',
        comments: fileItem?.comment,
      };
    });
    files = files.filter((attachment) => !isEmpty(attachment?.url));
    return files;
  };

  const handleUpdateTaskResponse = (updateTasksResposne: { data: { errorCode: string }[] }) => {
    if (
      updateTasksResposne.data.some(
        (task: { errorCode: string }) => task.errorCode && task.errorCode === 'TASK_ALREADY_RESCHEDULED'
      )
    ) {
      setSnackbarMessage('selected task is already rescheduled for rework');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } else {
      const successMsg =
        role === UserType.Customer
          ? t('taskRejectionAndRework:reworkRequestedSuccessMsg')
          : t('taskRejectionAndRework:reworkScheduledSuccessMsg');
      setSnackbarMessage(successMsg);
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    }
  };

  const handleRequestRework = async () => {
    setIsReworkLoading(true);
    try {
      if (proofs.length) {
        const s3BucketUploadedResponse = await storeAttachmentsToS3Cloud(proofs);
        const { successAttachments = [] } = s3BucketUploadedResponse || {
          successAttachments: [],
          failedAttachmentIds: [],
        };

        const files = getProofAttachmnetFiles(successAttachments);

        if (!isEmpty(rowInfo.taskAttachments)) {
          rowInfo.taskAttachments.forEach((attachmet: any) => {
            files.push({
              attachmentStatus: attachmet.attachmentStatus,
              mdAttachmentType: attachmet.mdAttachmentType,
              fileName: attachmet.fileName,
              mdFileContext: attachmet.mdFileContext,
              fileExtension: attachmet.fileExtension,
              url: attachmet.url,
              comments: attachmet.comments,
            });
          });
        }

        const addTaskAttachmentsResponse = await WorkOrdersApiService.addTaskAttachments(rowInfo.taskId, files);
        if (!isEmpty(addTaskAttachmentsResponse.errors)) {
          console.log('error while uploading the attachments: ', addTaskAttachmentsResponse.errors);
          const errorMsg = t('taskRejectionAndRework:addTaskAttachmentsErrorMsg');
          setSnackbarMessage(errorMsg);
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        }
      }

      const updateTaskPayload: UpdateTaskInput = getUpdateTaskPayload();

      const updateTasksResposne = await WorkOrdersApiService.updateMultipleTasksAtOnce([{ ...updateTaskPayload }]);
      if (updateTasksResposne.data && isEmpty(updateTasksResposne.errors)) {
        handleUpdateTaskResponse(updateTasksResposne);
      }
    } catch (error) {
      console.log('error occured while requesting or scheduling task for rework: ', error);
      const errorMsg =
        role === UserType.Customer
          ? t('taskRejectionAndRework:reworkRequestedErrorMsg')
          : t('taskRejectionAndRework:reworkScheduledErrorMsg');
      setSnackbarMessage(errorMsg);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
    setOpenRequestRework(false);
    setRejectionComment('');
    setProofs([]);
    setIsPartiallyCompleted(false);
    setIsReworkLoading(false);
    const formattedDate = new Date(specificDate).toISOString().split('T')[0];
    fetchTasks(formattedDate);
  };

  const getUpdateTaskPayload = () => {
    const taskApprovalpayload: CreateTaskApprovalInput = {
      approvalName: ApprovalType.TaskApproval,
      status: role === UserType.Customer ? TaskStatus.ReworkRequestedByCustomer : TaskStatus.Rework,
      approvalStatus: role === UserType.Customer ? ApprovalStatus.ReworkRequested : ApprovalStatus.Rework,
      mdApproverType: role === UserType.Customer ? ApproverType.Customer : ApproverType.Vendor,
    };
    if (!isEmpty(rejectionComment)) {
      taskApprovalpayload.reason = rejectionComment;
    }

    const updateTaskPayload: UpdateTaskInput = {
      taskId: rowInfo.taskId,
      associateId: rowInfo.associateId,
      isPartiallyCompleted: isPartiallyCompleted,
      taskApprovalInput: [{ ...taskApprovalpayload }],
    };
    if (role === UserType.Customer) {
      updateTaskPayload.suggestedReworkDate = reworkScheduleDate;
      updateTaskPayload.taskStatus = TaskStatus.ReworkRequestedByCustomer;
      updateTaskPayload.suggestedScheduleType = reworkShift;
    } else {
      updateTaskPayload.reworkDate = reworkScheduleDate;
      updateTaskPayload.taskStatus = TaskStatus.Rework;
    }

    return updateTaskPayload;
  };

  const handleCustomerApproval = async (tasks: Task[]) => {
    setSendingForApproval(true);
    try {
      const updateTaskInputPayload: UpdateTaskInput[] = [];
      tasks?.forEach((task: Task) => {
        updateTaskInputPayload.push({
          taskId: task.taskId,
          taskStatus: isOtjJob ? TaskStatus.PendingPrimeVendorApproval : TaskStatus.PendingCustomerReview,
        });
      });

      const updateTasksResponse = await WorkOrdersApiService.updateMultipleTasksAtOnce(updateTaskInputPayload);
      if (isEmpty(updateTasksResponse.errors)) {
        const successMsg = isOtjJob
          ? t('dashboard:sendPrimeVendorApprovalSuccessMsg')
          : t('dashboard:sendCustomerApprovalSuccessMsg');
        setSnackbarMessage(successMsg);
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } else {
        const errorMsg = isOtjJob
          ? t('dashboard:sendPrimeVendorApprovalErrorMsg')
          : t('dashboard:sendCustomerApprovalErrorMsg');
        setSnackbarMessage(errorMsg);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error(error);
    }
    setSendingForApproval(false);
    const formattedDate = new Date(specificDate).toISOString().split('T')[0];
    fetchTasks(formattedDate);
  };

  const renderTasksData = (reworkTaskCheck: boolean) => {
    const taskData = reworkTaskCheck ? reworkTaskData : dailyTaskData;
    return (
      <>
        <TitleWrapper sx={{ marginTop: reworkTaskCheck ? '2rem' : '0rem' }}>
          <Services>
            {!reworkTaskCheck ? t('taskRejectionAndRework:services') : t('taskRejectionAndRework:reworkTasks')}
          </Services>
        </TitleWrapper>
        {isViewLoading ? (
          <Loader>
            <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
          </Loader>
        ) : taskData.length ? (
          taskData.map((dataItem) => {
            return (
              <Accordion
                vendorTypes={vendorTypes}
                serviceList={serviceList}
                key={dataItem.id}
                columns={columns}
                expanded={accordionID === dataItem.id && expanded}
                dailyTaskData={taskData}
                handleAccordion={(e) => handleAccordion(e, dataItem.id)}
                onChangeRow={onChangeRow}
                accordionObj={dataItem}
                screenName={ScreenName.JobDetails}
                handleApproveAll={() => {
                  setIsOpenApproveAll(true);
                  setSnackBarStatus({
                    ...snackBarStatus,
                    isOpen: false,
                  });
                }}
                handleRejectAll={() => {
                  setIsOpenRejectAll(true);
                  setSnackBarStatus({
                    ...snackBarStatus,
                    isOpen: false,
                  });
                }}
                handleCustomerApproval={handleCustomerApproval}
                sendingForApproval={sendingForApproval}
                isOtjJob={isOtjJob}
              />
            );
          })
        ) : isAPIError ? (
          <ErrorMessage />
        ) : (
          <NoDataTag>{t('taskRejectionAndRework:thereAreNoPendingTasks')}</NoDataTag>
        )}
      </>
    );
  };

  const getUpatedTaskData = (allTasks: any[]) => {
    if (selectedTaskType === TaskType.SagaTask) {
      setDailyTaskData(allTasks);
    } else {
      setReworkTaskData(allTasks);
    }
  };

  const renderUpdateShiftDateButton = () => {
    return (
      <>
        {isShiftDetailsLoading ? (
          <Loader sx={{ marginRight: '5rem' }}>
            <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
          </Loader>
        ) : (
          <>
            {showUpdateShiftDate && (
              <Button
                sx={{ ...updateShiftDateButton, opacity: disableUpdateShiftDate ? 0.5 : 1 }}
                disabled={disableUpdateShiftDate}
                onClick={() => setOpenUpdateShiftDate(true)}>
                <Typography sx={{ ...buttonText }}>
                  {disableUpdateShiftDate
                    ? `${t('rescheduleShiftDate:shiftAlreadyRescheduled')} ${
                        rescheduledShiftDate ? formatDate(rescheduledShiftDate) : ''
                      }`
                    : t('rescheduleShiftDate:updateShiftDate')}
                </Typography>
              </Button>
            )}
          </>
        )}
      </>
    );
  };

  useEffect(() => {
    if (reworkShift === TaskScheduleType.SpecificDay) {
      if (rowInfo.isCurrentShiftAvailable && !rowInfo.nextAvailableWorkDay) {
        setReworkScheduleDate(new Date(tomorrow).toISOString());
      } else if (rowInfo.nextAvailableWorkDay && rowInfo.nextAvailableWorkDay.workDate) {
        setReworkScheduleDate(getNextDay(rowInfo.nextAvailableWorkDay.workDate));
      } else if (!rowInfo.isCurrentShiftAvailable && !rowInfo.nextAvailableWorkDay) {
        setReworkScheduleDate(new Date(tomorrow).toISOString());
      }
    }
  }, [reworkShift]);

  useEffect(() => {
    if (originalServices.length) {
      const filteredData = dataFiltering();
      setServiceList(filteredData);
    }
  }, [schedule, originalServices, expanded]);

  useEffect(() => {
    setValue('specificDate', newDate ?? new Date().toISOString());
  }, []);

  useEffect(() => {
    const formattedDate = new Date(specificDate).toISOString().split('T')[0];
    updateShiftDetailsOptions(formattedDate);
    fetchTasks(formattedDate);
  }, [specificDate, selectedJob, selectedFacility, isOpenRejectAll, isOpenApproveAll]);

  useEffect(() => {
    if (snackBarStatus.isOpen) {
      const taskData = selectedTaskType === TaskType.SagaTask ? [...dailyTaskData] : [...reworkTaskData];
      const allTasks = [...taskData].map((serviceCategory) => {
        const allOptions = serviceCategory.options.map((optionItem: any) => {
          if (optionItem.taskId === rowInfo.taskId && snackBarStatus.isApproved) {
            return {
              ...optionItem,
              status: getApproveStatusByRole(optionItem.status),
            };
          }
          if (optionItem.taskId === rowInfo.taskId && !snackBarStatus.isApproved) {
            return {
              ...optionItem,
              status: getRejectStatusByRole(optionItem.status),
            };
          }
          return {
            ...optionItem,
          };
        });
        return {
          ...serviceCategory,
          options: allOptions,
        };
      });
      setIsApproving(false);
      setIsRejecting(false);
      getUpatedTaskData(allTasks);
    }
  }, [snackBarStatus.isOpen]);

  const showUpdateShiftDateButton = () => {
    const { customerId } = getStoredCustomerDetails();
    const otjSecondFlowCheck =
      isOtjJob &&
      jobCustodian &&
      (vendorTypes[0]?.mdVendorType === VendorType.All || vendorTypes[0]?.mdVendorType === VendorType.SearchVendors) &&
      jobCustodian !== customerId;
    const otjThirdFlowCheck =
      isOtjJob && jobCustodian && vendorTypes[0]?.mdVendorType === VendorType.SubVendor && jobCustodian === customerId;
    return (
      role !== UserType.Customer && (!isOtjJob || (!isOtjSelfPerformJob() && (otjSecondFlowCheck || otjThirdFlowCheck)))
    );
  };

  return (
    <div>
      <DropdownWrapper sx={{ justifyContent: 'flex-end', marginTop: '2rem' }}>
        {showUpdateShiftDateButton() ? renderUpdateShiftDateButton() : null}
        <DatePickerWrapper
          sx={{
            maxWidth: '13rem',
            '& .MuiOutlinedInput-root': {
              '& .MuiInputBase-input': {
                padding: '0.5625rem 0 0.5625rem 1rem',
                color: theme.palette.primary.dark,
              },
            },
          }}>
          <DateSelection
            requiredText="Date is mandatory"
            control={control}
            fieldName={'specificDate'}
            label={t('taskRejectionAndRework:selectDate')}
            isExcludePastDate={false}
            showSpecificRangeDates={{
              from: new Date(jobStartDate),
              to: new Date(jobEndDate),
            }}
            textFieldStyles={{
              '& .MuiOutlinedInput-root': {
                '& fieldset,&:hover fieldset,&.Mui-focused fieldset': {
                  borderWidth: 1,
                  borderColor: theme.palette.primary.dark,
                  borderRadius: '1rem',
                },
              },
              '& .MuiInputLabel-root': {
                color: theme.palette.primary.dark,
                '&.Mui-focused': {
                  color: theme.palette.primary.dark,
                },
              },
            }}
          />
        </DatePickerWrapper>
      </DropdownWrapper>
      {isViewLoading ? (
        <Loader>
          <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
        </Loader>
      ) : (
        <>
          {!(isEmpty(dailyTaskData) && isEmpty(reworkTaskData)) ? (
            <>
              {!isEmpty(dailyTaskData) && renderTasksData(false)}
              {!(isOtjJob && vendorTypes[0]?.mdVendorType === VendorType.SelfPerform) &&
                !isEmpty(reworkTaskData) &&
                renderTasksData(true)}
            </>
          ) : (
            <NoPendingTaskContainer>
              <img src={Icons.ErrorCautionImg} style={{ ...noDataImageStyles }} />
              <NoDataTag>{t('taskRejectionAndRework:thereAreNoPendingTasks')}</NoDataTag>
            </NoPendingTaskContainer>
          )}
        </>
      )}
      <ApproveDialog
        isApproveWindowOpen={isOpenApprove || isOpenApproveAll}
        isApproveLoading={isApproveLoading}
        handleCloseApproveWindow={() => {
          setIsOpenApprove(false);
          setIsOpenApproveAll(false);
          setIsApproving(false);
        }}
        handleApprove={() => {
          if (isOpenApproveAll) {
            handleApprovalAll();
          } else {
            if (Object.keys(rowInfo).length > 0) handleApprove(rowInfo?.taskId, approveByRole, vendorTypes);
          }
          setIsOpenApprove(false);
          setIsOpenApproveAll(false);
          setIsApproving(true);
        }}
        rowDetails={rowInfo}
        isShowApproveAll={isOpenApproveAll && selectedTaskId.length > 1}
      />
      <RejectDialog
        isRejectWindowOpen={isOpenReject || isOpenRejectAll}
        handleCloseRejectWindow={() => {
          setIsOpenReject(false);
          setIsOpenRejectAll(false);
          setIsRejecting(false);
        }}
        handleReject={(vendorMsg: string) => {
          if (isOpenRejectAll) {
            handleRejectAll(vendorMsg);
          } else {
            if (Object.keys(rowInfo).length > 0) handleReject(rowInfo?.taskId, vendorMsg, approveByRole);
          }
          setIsOpenReject(false);
          setIsOpenRejectAll(false);
          setIsRejecting(true);
        }}
        isShowRejectAll={isOpenRejectAll && selectedTaskId.length > 1}
      />
      {openRejectTask && (
        <RejectWorkTask
          openRejectTask={openRejectTask}
          handleRejectTaskClose={handleRejectTaskClose}
          taskName={`${rowInfo.taskName}-Floor ${rowInfo.floor ? rowInfo.floor : 0}`}
          rejectionComment={rejectionComment}
          setRejectionComment={setRejectionComment}
          isPartiallyCompleted={isPartiallyCompleted}
          setIsPartiallyCompleted={setIsPartiallyCompleted}
          proofs={proofs}
          setProofs={setProofs}
          handleDeleteProofs={handleDeleteProofs}
          handleRequestRework={handleRejectTask}
        />
      )}
      {openRequestRework && (
        <RequestOrScheduleRework
          openRequestRework={openRequestRework}
          handleRequestReworkClose={handleRequestReworkClose}
          handleRequestRework={handleRequestRework}
          reworkShift={reworkShift}
          setReworkShift={setReworkShift}
          isCurrentShiftAvailable={rowInfo.isCurrentShiftAvailable}
          nextAvailableWorkDay={rowInfo.nextAvailableWorkDay}
          tomorrowDate={tomorrow}
          workOrderEndDate={workOrderEndDate}
          reworkScheduleDate={reworkScheduleDate}
          setReworkScheduleDate={setReworkScheduleDate}
          isDataLoading={isReworkLoading}
          proofs={proofs}
        />
      )}
      {snackBarStatus.isOpen ? (
        <SnackBar
          isApproved={snackBarStatus.isApproved}
          taskCount={selectedTaskId.length ? selectedTaskId.length : 1}
          handleOnClose={() =>
            setSnackBarStatus({
              isOpen: false,
              isApproved: false,
            })
          }
        />
      ) : null}
      <SnackbarMessage
        open={snackbarOpen}
        successMessage={snackbarMessage}
        errorMessage={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
      />
      <UpdateShiftDateCard
        openUpdateShift={openUpdateShiftDate}
        handleShiftDateClose={() => setOpenUpdateShiftDate(false)}
        isDataLoading={isShowUpdateShiftDateLoading}
        handleOnConfirm={handleOnUpdateShiftDate}
      />
    </div>
  );
};

export default JobDetailsTasks;
