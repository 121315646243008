/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Box, useMediaQuery } from '@mui/material';

import colorConfigs from '../../Configs/colorConfigs';
import sizeConfigs from '../../Configs/sizeConfigs';
import { isTopNavBarVisible } from '../../Configs/appConfig';
import TopNavBar from '../Common/TopNavBar/TopNavBar';
import LeftNavBar from '../Common/LeftNavBar/LeftNavBar';

const MainLayout = () => {
  const [isSideBarVisible, setSideBarVisibility] = useState(false);

  const onSideBarVisibilityChange = (value: boolean) => {
    setSideBarVisibility(value);
  };

  const {
    sidebar: { openWidth, closedWidth },
  } = sizeConfigs;

  const isTablet = useMediaQuery('(max-width: 64rem)');

  useEffect(() => {
    if (isSideBarVisible && isTablet) {
      document.body.style.position = 'relative';
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.position = 'static';
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.position = 'static';
      document.body.style.overflow = 'auto';
    };
  }, [isSideBarVisible, isTablet]);

  return (
    <>
      {/* <TopBar /> */}
      <Box sx={{ display: 'flex' }}>
        <Box
          component="nav"
          sx={{
            width:
              !isTopNavBarVisible && isSideBarVisible ? sizeConfigs.sidebar.openWidth : sizeConfigs.sidebar.closedWidth,
            flexShrink: 0,
            position: isSideBarVisible && isTablet ? 'absolute' : 'static',
          }}>
          {isTopNavBarVisible && (
            <TopNavBar isSideBarVisible={isSideBarVisible} onSideBarVisibilityChange={onSideBarVisibilityChange} />
          )}
          {!isTopNavBarVisible && (
            <LeftNavBar isSideBarVisible={isSideBarVisible} onSideBarVisibilityChange={onSideBarVisibilityChange} />
          )}
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            minHeight: '100vh',
            width: `calc(100% - ${isSideBarVisible ? openWidth : closedWidth})`,
            backgroundColor: colorConfigs.mainBg,
            position: 'relative',
          }}>
          {isSideBarVisible && isTablet && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                zIndex: 2,
              }}
            />
          )}
          <Outlet />
        </Box>
      </Box>
    </>
  );
};

export default MainLayout;
