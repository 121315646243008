/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { useTranslation } from 'react-i18next';
import { InputAdornment, TextField } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import SimpleDatePicker from '../SimpleDatePicker/SimpleDatePicker';
import {
  buttonStyles,
  DateFieldWrapper,
  ErrorMsg,
} from '../../../../Modules/Jobs/Components/AddEditOneTimeJob/AddEditOneTimeJob.Style';

import { Icons } from '../../../Constants/Icons';
import theme from '../../../Themes/theme';

interface IDateSelectionProps {
  control: Control<any>;
  requiredText: string;
  fieldName: any;
  label: string;
  onDateSelect?: (date: Date | null) => void;
  isExcludePastDate?: boolean;
  isExcludeFutureDate?: boolean;
  iconColor?: string;
  textFieldStyles?: any;
  showSpecificRangeDates?: { from: Date; to: Date | undefined };
}

const mandatoryField = {
  color: theme.palette.error.main,
};

const DateSelection = ({
  control,
  fieldName,
  label,
  isExcludePastDate = true,
  isExcludeFutureDate = false,
  iconColor,
  textFieldStyles,
  showSpecificRangeDates,
}: IDateSelectionProps) => {
  const { t } = useTranslation(['altTexts']);

  return (
    <>
      <Controller
        control={control}
        name={fieldName}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <DateFieldWrapper>
              <SimpleDatePicker
                isResponsive
                id={fieldName}
                defaultValue={value}
                onChange={(e) => {
                  onChange(e);
                  localStorage.setItem(fieldName, e);
                }}
                isExcludePastDate={isExcludePastDate}
                isExcludeFutureDate={isExcludeFutureDate}
                showSpecificRangeDates={showSpecificRangeDates}
                CustomComponent={
                  <TextField
                    sx={{ ...buttonStyles.textField, ...textFieldStyles }}
                    id={fieldName}
                    label={
                      <span>
                        {label} <span style={mandatoryField}>*</span>
                      </span>
                    }
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <img
                            src={Icons.CalendarIcon}
                            alt={t('altTexts:calendar')}
                            style={{
                              width: '1.5rem',
                              height: '1.5rem',
                              filter: `brightness(${iconColor === 'black' ? 0 : 1})`,
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                }
              />
              <ErrorMsg>{error && error?.message}</ErrorMsg>
            </DateFieldWrapper>
          );
        }}
      />
    </>
  );
};

export default DateSelection;
