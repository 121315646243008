/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';
import { fromLatLng, setKey } from 'react-geocode';
import { useForm } from 'react-hook-form';
import { Box } from '@mui/material';

import FacilityApiService from '../../Services/FacilityApiService';
import { AddressData, FacilityData, FacilityInput } from '../../../../API';
import { getStoredCustomerDetails } from '../../../../Shared/Utilities/utils';

import PageTitle from '../../../../Shared/Components/Common/PageTitle/PageTitle';
import CurrentLocationCard from './CurrentLocationCard';
import { ControlPosition, ZoomControlStyle } from '../../Models/AddFacility.Model';
import { MapLoader } from '../../../../Shared/Components/Common/MapLoader/MapLoader';
import GoogleAutoComplete from './GoogleAutoComplete';
import theme from '../../../../Shared/Themes/theme';

const AddFacilityPage = () => {
  const location = useLocation();
  const [currentLocation, setCurrentLocation] = useState({ lat: 0, lng: 0 });
  const [loading, setLoading] = useState(false);
  const [isSubmissionSuccess, setIsSubmissionSuccess] = useState(false);
  const [currentAddress, setCurrentAddress] = useState<any>();
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [addressExists, setAddressExists] = useState(location.state && location.state.addressExists);

  const { handleSubmit, control, getValues, setValue } = useForm({
    defaultValues: {
      facilityName: '',
      suitNumber: '',
      facilityType: '',
    },
  });

  const { t } = useTranslation(['addFacility']);

  const { customerId = '' } = getStoredCustomerDetails() || {};
  const facilityAddress = location?.state?.address?.addressLine2 || location?.state?.address?.addressLine1;

  const onSubmit = async (facilityDetails: any) => {
    try {
      // setIsFacilitySaving(true);
      if (location.state) {
        const facilityPayload = {
          facilityId: location.state.facilityId,
          customerId: location.state.customerId,
          buildingName: facilityDetails.facilityName,
          noOfFloors: 0,
          sqFootage: 0,
          contactId: '',
          mdFacilityType: facilityDetails.facilityType,
        } as FacilityData;
        const addressPayload = {
          addressId: location.state.address.addressId,
          customerId: customerId,
          noOfBuildings: 1,
          addressName: '',
          addressLine1: currentAddress.addressLine1,
          addressLine2: currentAddress.addressLine2,
          city: currentAddress.city,
          stateCode: currentAddress.stateCode,
          county: currentAddress.country,
          postalCode: currentAddress.postalCode,
          mdCountryCode: 'US',
          landmark: '',
          googlePlaceId: currentAddress.googlePlaceId,
          latitude: currentLocation.lat,
          longitude: currentLocation.lng,
        } as AddressData;

        // const facilityResponse = await FacilityApiService.addFacility(payload);
        const updateFacilityRes = await FacilityApiService.updateFacility(facilityPayload);
        const updateAddressRes = await FacilityApiService.updateAddress(addressPayload);

        if (updateFacilityRes.errors.length === 0 && updateAddressRes.errors.length === 0) {
          setIsSubmissionSuccess(true);
        } else {
          console.log('Error: failed to update facility or address', updateFacilityRes.errors, updateAddressRes.errors);
        }
      } else {
        const payload: FacilityInput = {
          customerId: customerId,
          buildingName: facilityDetails.facilityName,
          noOfFloors: 0,
          sqFootage: 0,
          contactId: '',
          mdFacilityType: facilityDetails.facilityType,
          address: {
            customerId: customerId,
            noOfBuildings: 1,
            addressName: '',
            addressLine1: currentAddress.addressLine1,
            addressLine2: currentAddress.addressLine2,
            city: currentAddress.city,
            stateCode: currentAddress.stateCode,
            county: currentAddress.country,
            postalCode: currentAddress.postalCode,
            mdCountryCode: 'US',
            landmark: '',
            googlePlaceId: currentAddress.googlePlaceId,
            latitude: currentLocation.lat,
            longitude: currentLocation.lng,
          },
        } as FacilityInput;

        // setIsFacilitySaving(true);
        const facilityResponse = await FacilityApiService.addFacility(payload);

        if (facilityResponse.errors.length === 0) {
          setIsSubmissionSuccess(true);
        } else {
          console.log('Error: failed to add facility', facilityResponse.errors);
        }
      }
    } catch (e) {
      console.log('Error: failed to add/edit facility', e);
      // setIsFacilitySaving(false);
    }
  };

  const createMapOptions = (maps: { ControlPosition: ControlPosition; ZoomControlStyle: ZoomControlStyle }) => {
    return {
      zoomControlOptions: {
        position: maps.ControlPosition.RIGHT_CENTER,
        style: maps.ZoomControlStyle.SMALL,
      },
      mapTypeControlOptions: {
        position: maps.ControlPosition.TOP_RIGHT,
      },
      mapTypeControl: true,
    };
  };

  const getAddressComponents = (addressComponentsResults: any) => {
    const address = {} as any;
    const premise = addressComponentsResults.filter((addressItem: any) => addressItem.types.includes('premise'));

    const country = premise[0].address_components.filter((item: any) => item.types.includes('country'))[0].long_name;
    const state = premise[0].address_components.filter((item: any) =>
      item.types.includes('administrative_area_level_1')
    )[0].long_name;
    const city = premise[0].address_components.filter((item: any) => item.types.includes('locality'))[0].long_name;
    const postalCode = premise[0].address_components.filter((item: any) => item.types.includes('postal_code'))[0]
      .long_name;
    const address1 = premise[0].address_components.filter((item: any) => item.types.includes('street_number'))[0]
      .long_name;
    const address2 = premise[0].address_components.filter((item: any) => item.types.includes('route'))[0].long_name;
    const address3 = premise[0].address_components.filter((item: any) => item.types.includes('sublocality_level_2'))[0]
      .long_name;
    const address4 = premise[0].address_components.filter((item: any) => item.types.includes('sublocality_level_1'))[0]
      .long_name;
    const stateCode = premise[0].address_components.filter((item: any) =>
      item.types.includes('administrative_area_level_1')
    )[0].short_name;

    address.googlePlaceId = premise[0].place_id;
    address.formattedAddress = premise[0].formatted_address;
    address.latitude = premise[0].geometry.location.lat;
    address.longitude = premise[0].geometry.location.lng;
    address.addressLine1 = address1 + ' ' + address2;
    address.addressLine2 = address3 + ' ' + address4;
    address.country = country;
    address.state = state;
    address.stateCode = stateCode;
    address.city = city;
    address.postalCode = postalCode;
    return address;
  };

  const getNameForAddressComponent = (premise: any, type: string) => {
    const foundResult = premise?.length
      ? premise[0].address_components.filter((item: any) => item.types.includes(type))
      : [];
    if (foundResult?.length) {
      return foundResult[0].long_name;
    }
    return '';
  };

  const getShortNameForAddressComponent = (premise: any, type: string) => {
    const foundResult = premise?.length
      ? premise[0].address_components.filter((item: any) => item.types.includes(type))
      : [];
    if (foundResult?.length) {
      return foundResult[0].short_name;
    }
    return '';
  };

  const getSearchAddressComponents = (searchAddressComponentsResults: any) => {
    const address = {} as any;
    const premise = searchAddressComponentsResults.filter(
      (addressItem: any) =>
        addressItem.types.includes('premise') ||
        addressItem.types.includes('establishment') ||
        addressItem.types.includes('street_address')
    );

    const country = getNameForAddressComponent(premise, 'country');
    const state = getNameForAddressComponent(premise, 'administrative_area_level_1');
    const city = getNameForAddressComponent(premise, 'locality');
    const postalCode = getNameForAddressComponent(premise, 'postal_code');
    const address1 = getNameForAddressComponent(premise, 'street_number');
    const address2 = getNameForAddressComponent(premise, 'route');
    const address3 = getNameForAddressComponent(premise, 'sublocality_level_3');
    const address4 = getNameForAddressComponent(premise, 'sublocality_level_2');
    const stateCode = getShortNameForAddressComponent(premise, 'administrative_area_level_1');

    address.formattedAddress = premise.length ? premise[0].formatted_address : null;
    address.googlePlaceId = premise.length ? premise[0].place_id : null;
    address.country = country;
    address.state = state;
    address.stateCode = stateCode;
    address.city = city;
    address.postalCode = postalCode;
    address.latitude = premise.length ? premise[0].geometry.location.lat : null;
    address.longitude = premise.length ? premise[0].geometry.location.lng : null;
    address.addressLine1 = address1 + address2;
    address.addressLine2 = address3 + address4;

    return address;
  };

  useEffect(() => {
    setLoading(true);
    setKey('AIzaSyC2t6qfdqtIXTZzPGJkFbFE3YQKTxLo614');
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCurrentLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          setLoading(false);
        },
        (error) => {
          console.error('Error getting the current location:', error);
          setLoading(false);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fromLatLng(currentLocation.lat, currentLocation.lng, process.env.REACT_APP_GOOGLE_MAPS_API_KEY)
      .then(({ results }: any) => {
        const { lat, lng } = results[0].geometry.location;
        const addressComponents = getAddressComponents(results);
        setCurrentAddress(addressComponents);
        return { lat, lng };
      })
      .catch(console.error);
  }, [currentLocation]);

  return (
    <>
      <PageTitle title={t('addFacility:facilityAddress')} />
      {loading ? (
        <MapLoader />
      ) : (
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string, libraries: ['places'] }}
          defaultCenter={{
            lat: currentLocation.lat,
            lng: currentLocation.lng,
          }}
          defaultZoom={4}
          options={createMapOptions}
          yesIWantToUseGoogleMapApiInternals></GoogleMapReact>
      )}

      <Box sx={{ position: 'absolute', top: '20%', right: '15%' }}>
        <CurrentLocationCard
          control={control}
          setValue={setValue}
          onSubmit={handleSubmit(onSubmit)}
          onClose={() => setIsSubmissionSuccess(false)}
          isSuccess={isSubmissionSuccess}
          currentAddress={addressExists ? facilityAddress : (currentAddress && currentAddress.formattedAddress) || ''}
          loading={loading}
          getValues={getValues}
          SearchField={() => setShowSearchInput(!showSearchInput)}
        />
      </Box>

      {showSearchInput && (
        <Box
          sx={{
            position: 'absolute',
            top: '20%',
            left: '5%',
            borderRadius: '1rem',
            background: theme.palette.common.white,
          }}>
          <GoogleAutoComplete
            onLocationChange={(location: any) => {
              return fromLatLng(location.lat, location.lng, process.env.REACT_APP_GOOGLE_MAPS_API_KEY)
                .then(({ results }) => {
                  const addressComponents = getSearchAddressComponents(results);
                  if (addressExists) {
                    setAddressExists(false);
                  }
                  setCurrentAddress(addressComponents);
                  return results;
                })
                .catch((error) => {
                  console.error('Error converting lat lng to address:', error);
                  throw error;
                });
            }}
            initialCenter={{
              lat: currentLocation.lat,
              lng: currentLocation.lng,
            }}
          />
        </Box>
      )}
    </>
  );
};

export default AddFacilityPage;
