/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { useMediaQuery, useTheme } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Text } from 'recharts';

import { GraphWrapper, CustomTooltipWrapper } from '../style';
import { IDataV2 } from '../models';
import { formatAxisValue } from '../graphUtils';
import theme from '../../../../Themes/theme';

const CustomTooltip = ({ payload, label }: any) => {
  const { value } = (payload && payload[0]) ?? '';
  return (
    <CustomTooltipWrapper>
      <p>{value}</p>
      <p>{label}</p>
    </CustomTooltipWrapper>
  );
};

const CustomXAxisTick = ({ x, y, payload }: any) => {
  if (payload && payload.value) {
    return (
      <Text
        fill={`${theme.palette.secondary.contrastText}`}
        width={100}
        x={x}
        y={y}
        textAnchor="middle"
        verticalAnchor="start">
        {payload.value}
      </Text>
    );
  }
  return null;
};

interface ILineGraphProps {
  LineColor: string;
  graphData: IDataV2[];
}

/**
 * @param {String} LineColor
 * @param {IDataV2} graphData
 * @returns It will return LineGraph
 */
const LineGraph = ({ graphData, LineColor }: ILineGraphProps) => {
  const theming = useTheme();
  const matches = useMediaQuery(theming.breakpoints.down('laptop'));

  return (
    <GraphWrapper>
      <ResponsiveContainer width="99%" height="100%">
        <LineChart
          data={graphData}
          margin={{
            top: matches ? 16 : 24,
            right: matches ? 24 : 32,
            left: matches ? 10 : 24,
            bottom: matches ? 16 : 24,
          }}>
          <CartesianGrid vertical={false} strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            tickLine={false}
            axisLine={{ stroke: `${theme.palette.divider}` }}
            padding={{ left: matches ? 20 : 40, right: matches ? 10 : 20 }}
            tick={<CustomXAxisTick />}
            width={30}
          />
          <YAxis
            tickLine={false}
            tick={{ fill: `${theme.palette.secondary.contrastText}` }}
            axisLine={{ stroke: `${theme.palette.divider}` }}
            tickFormatter={formatAxisValue}
            allowDecimals={false}
            scale="linear"
          />
          <Tooltip content={<CustomTooltip />} cursor={{ stroke: '#7B7359' }} />
          <Line
            dot={{ stroke: LineColor, strokeWidth: 2, r: 4 }}
            dataKey="amount"
            stroke={LineColor}
            activeDot={{ r: 6, strokeWidth: 5, stroke: LineColor, fill: theme.palette.common.white }}
            fill={LineColor}
            strokeWidth={2}
          />
        </LineChart>
      </ResponsiveContainer>
    </GraphWrapper>
  );
};

export default LineGraph;
