/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { withTranslation } from 'react-i18next';
import { Button } from '@mui/material';

import { Icons } from '../../../../Shared/Constants/Icons';

import { LoginWithYourText, EmailAndPhoneNoContainer, EmailAndPhoneNoText } from './LoginPage.Style';
import theme from '../../../../Shared/Themes/theme';

const LoginOptions = (props) => {
  const { onClick, t } = props;

  const EmailButtonStyles = {
    background: theme.palette.common.white,
    boxShadow: ' 0px 10px 20px 6px rgba(0, 107, 107, 0.10)',
    borderRadius: '1.25rem',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1.375rem 2.9375rem',
    minWidth: '8.3rem',
    '& img': {
      width: '2rem',
      height: '2rem',
    },
    '&:hover': {
      background: 'none',
    },
    '@media (max-width: 64rem)': {
      borderRadius: '1rem',
      padding: '1.3125rem 3.3125rem',
      '& img': {
        width: '1.5rem',
        height: '1.5rem',
      },
    },
  };

  const PhoneNumberButtonStyles = {
    background: theme.palette.common.white,
    boxShadow: ' 0px 10px 20px 6px rgba(0, 107, 107, 0.10)',
    borderRadius: '1.25rem',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1.375rem 1.9375rem',
    minWidth: '8.3rem',
    '& img': {
      width: '2rem',
      height: '2rem',
    },
    '&:hover': {
      background: 'none',
    },
    '@media (max-width: 64rem)': {
      borderRadius: '1rem',
      padding: '1.375rem 2.2125rem',
      '& img': {
        width: '1.5rem',
        height: '1.5rem',
      },
    },
  };

  return (
    <>
      <LoginWithYourText>{t('signIn:signInWith')}</LoginWithYourText>
      <EmailAndPhoneNoContainer>
        <Button
          sx={{
            ...EmailButtonStyles,
          }}
          onClick={() => onClick(true, 'email')}>
          <img src={Icons.EmailIcon} alt={t('altTexts:emailLogo')} />
          <EmailAndPhoneNoText>{t('signIn:emailLabel')}</EmailAndPhoneNoText>
        </Button>
        <Button
          sx={{
            ...PhoneNumberButtonStyles,
          }}
          onClick={() => onClick(true, 'phoneNumber')}>
          <img src={Icons.PhoneNoIcon} alt={t('altTexts:phoneNumber')} />
          <EmailAndPhoneNoText>{t('signIn:phoneNumberLabel')}</EmailAndPhoneNoText>
        </Button>
      </EmailAndPhoneNoContainer>
    </>
  );
};

export default withTranslation(['signIn', 'altTexts'])(LoginOptions);
