/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import TaskDetailsBottomBar from './TaskDetailsBottombar';
import RejectDialog from './RejectDialog';
import ApproveDialog from './ApproveDialog';
// import ChatMessages from './ChatMessages';
import ApproveOrRejectCard from './ApproveOrRejectCard';
import ApproveOrRejectCardButtons from './ApproveOrRejectButtons';
import PageTitle from '../../../../Shared/Components/Common/PageTitle/PageTitle';
import { CustomerContainer } from '../../../../Shared/Components/Layout/styles';
import {
  ChatContainer,
  ChatMessageContainer,
  ApproveRejectContainer,
  ApproveRejectButtonContainer,
  ProofHeading,
  AttachmentsContainer,
  AttachmentTag,
  AttachmentsTitle,
  NoProofsContainer,
  NoProofsTag,
} from './TaskDetailsPage.Style';
import SnackBar from '../../../../Shared/Components/Common/SnackBar/SnackBar';
import { useAuth } from '../../../../Configuration/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { Storage } from 'aws-amplify';
import {
  ApprovalStatus,
  ApprovalType,
  ApproverType,
  AttachmentStatus,
  CreateTaskApprovalInput,
  CreateTaskAttachmentInput,
  TaskScheduleType,
  TaskStatus,
  UpdateTaskInput,
  mdAttachmentType,
  mdFileContext,
} from '../../../../API';
import { fetchDataFromS3Bucket, getAuthData, uploadDocumentToS3Bucket } from '../../../../Shared/Utilities/utils';
import { CircularProgress } from '@mui/material';
import theme from '../../../../Shared/Themes/theme';
import { FullScreenContainer } from '../AddEditOneTimeJob/AddEditOneTimeJob.Style';
import { Icons } from '../../../../Shared/Constants/Icons';
import RejectWorkTask from '../TaskRejectionAndRework/RejectWorkTask';
import { IProofsProps, UserType, DocumentType } from '../../../../Shared/Constants/App';
import RequestOrScheduleRework from '../TaskRejectionAndRework/RequestOrScheduleRework';
import { v4 as uuidv4 } from 'uuid';
import { isEmpty } from 'lodash';
import WorkOrdersApiService from '../../Services/WorkOrdersService';
import SnackbarMessage from '../../../Onboarding/Components/Login/SnackbarMessage';
import { VendorType } from '../../../../Shared/Models/Vendors.Models';

const FullScreenView = ({ url, onClose, mediaType }: any) => {
  return (
    <FullScreenContainer onClick={onClose}>
      {mediaType === mdAttachmentType.Photo || mediaType === mdAttachmentType.Image ? (
        <img src={url} alt="full-screen" />
      ) : (
        <video controls src={url} />
      )}
    </FullScreenContainer>
  );
};

const TaskDetailsPage = () => {
  const { t } = useTranslation(['dashboard', 'taskRejectionAndRework']);
  const [isRejectWindowOpen, setRejectWindowOpen] = useState(false);
  const [isApproveWindowOpen, setApproveWindowOpen] = useState(false);
  const location = useLocation();
  const [associateDetails, setAssociateDetails] = useState({
    name: '',
    logoUrl: '',
  });
  const [proofAttachments, setProofAttachments] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [selectedMediaType, setSelectedMediaType] = useState<string>('image');
  const [openRejectTask, setOpenRejectTask] = useState<boolean>(false);
  const [rejectionComment, setRejectionComment] = useState<string>('');
  const [isPartiallyCompleted, setIsPartiallyCompleted] = useState<boolean>(false);
  const [proofs, setProofs] = useState<IProofsProps[]>([]);
  const [reworkShift, setReworkShift] = useState<TaskScheduleType | null>(null);
  const [reworkScheduleDate, setReworkScheduleDate] = useState<string>('');
  const [openRequestRework, setOpenRequestRework] = useState<boolean>(false);
  const [isReworkLoading, setIsReworkLoading] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [workOrderEndDate, setWorkOrderEndDate] = useState<any>(null);

  const { role, isDistrictManager } = getAuthData();

  const {
    taskId: taskNumber,
    timeTaken: timeTaken,
    status,
    floor: address,
    taskName: service,
    proofTypes,
  } = location?.state?.taskRecord || {};
  const navigate = useNavigate();
  const [taskStatus, setTaskStatus] = useState<string>(status);
  const { handleReject, handleApprove, setSnackBarStatus, snackBarStatus } = useAuth();
  const currentDate = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(currentDate.getDate() + 1);

  console.log('task details location.state', location.state);

  const mediaStyles: React.CSSProperties = {
    width: '15em',
    height: '10em',
    objectFit: 'contain',
    backgroundColor: 'black',
    borderRadius: '1em',
  };

  const noDataImageStyles: React.CSSProperties = {
    width: '15rem',
  };

  const handleMediaClick = (detail: any) => {
    setSelectedMedia(detail.url);
    setSelectedMediaType(detail.mdAttachmentType);
  };

  const handleCloseFullScreen = () => {
    setSelectedMedia(null);
  };

  useEffect(() => {
    const getAssociateInfo = async () => {
      const associateId: string = location?.state?.taskRecord?.associateId;
      if (associateId) {
        const result = await Storage.get(`associate/${associateId}/profile-pictures/${associateId}`, {
          level: 'public',
        });
        setAssociateDetails({ name: location?.state?.taskRecord?.associate, logoUrl: result });
      }
    };
    getAssociateInfo();
  }, [location]);

  function handleRejectButtonClick() {
    // setRejectWindowOpen(true);
    setOpenRejectTask(true);
  }

  function handleCloseRejectWindow() {
    setRejectWindowOpen(false);
  }

  function handleApproveButtonClick() {
    setApproveWindowOpen(true);
  }

  function handleCloseApproveWindow() {
    setApproveWindowOpen(false);
  }

  const getProofOfCompletion = async (attachments: any) => {
    setIsLoading(true);
    const attachmentsList = await Promise.all(
      attachments.map(async (attachment: any) => {
        if (!attachment.url.includes('https')) {
          attachment.url = await fetchDataFromS3Bucket(navigate, attachment?.url ?? '');
        }
        return attachment;
      })
    );
    setProofAttachments(attachmentsList);
    setIsLoading(false);
  };

  const renderProofs = (title: string, proofs: any) => {
    return (
      <>
        <AttachmentsTitle>{title}:</AttachmentsTitle>
        <AttachmentTag>
          {proofs.map((attachment: any) => (
            <>
              {(attachment.mdAttachmentType === mdAttachmentType.Photo ||
                attachment.mdAttachmentType === mdAttachmentType.Image) && (
                <img
                  style={{ ...mediaStyles }}
                  src={attachment.url}
                  alt={attachment.fileName}
                  onClick={() => handleMediaClick(attachment)}
                />
              )}
              {attachment.mdAttachmentType === mdAttachmentType.Video && (
                <video src={attachment.url} style={{ ...mediaStyles }} onClick={() => handleMediaClick(attachment)} />
              )}
            </>
          ))}
        </AttachmentTag>
      </>
    );
  };

  const handleRejectTaskClose = () => {
    setOpenRejectTask(false);
    setRejectionComment('');
    setProofs([]);
    setIsPartiallyCompleted(false);
  };

  const handleDeleteProofs = (proofId: string) => {
    setProofs(proofs.filter((proof: IProofsProps) => proof.id !== proofId));
  };

  const handleRejectTask = () => {
    setOpenRejectTask(false);
    if (location.state.taskRecord.isCurrentShiftAvailable) {
      setReworkShift(TaskScheduleType.CurrentShift);
      setReworkScheduleDate(new Date().toISOString());
    } else if (location.state.taskRecord.nextAvailableWorkDay && !location.state.taskRecord.isCurrentShiftAvailable) {
      setReworkShift(TaskScheduleType.NextWorkDay);
      setReworkScheduleDate(location.state.taskRecord?.nextAvailableWorkDay?.workDate);
    } else {
      setReworkShift(TaskScheduleType.SpecificDay);
      setReworkScheduleDate(tomorrow.toISOString());
    }
    setOpenRequestRework(true);
  };

  const handleRequestReworkClose = () => {
    setOpenRequestRework(false);
    setRejectionComment('');
    setProofs([]);
    setIsPartiallyCompleted(false);
  };

  const storeAttachmentsToS3Cloud = async (proofs: IProofsProps[] = []) => {
    try {
      const failedAttachmentIds: any = [];
      const successAttachments: any = [];
      await Promise.allSettled(
        proofs.map(async (fileItem: IProofsProps, index: number) => {
          const uniqueAttachmentId = uuidv4();
          const uploadDocumentResponse = await uploadDocumentToS3Bucket(
            navigate,
            `fulfillment/workOrders/${location.state.taskRecord?.workOrderId}/${location.state.taskRecord?.workDayId}/${location.state.taskRecord?.taskId}/attachment-${
              index + 1
            }-${uniqueAttachmentId}.${fileItem?.fileExtension}`,
            fileItem.url ?? '',
            {
              level: 'public',
            },
            fileItem?.fileExtension
          );
          successAttachments.push({ key: uploadDocumentResponse?.file?.key || '', id: fileItem?.id });
        })
      );
      return { successAttachments, failedAttachmentIds };
    } catch (e) {
      console.log('upload attachments error: ', e);
      return { successAttachments: [], failedAttachmentIds: [] };
    }
  };

  const getProofAttachmnetFiles = (successAttachments: any) => {
    let files: CreateTaskAttachmentInput[] = proofs.map((fileItem: IProofsProps) => {
      const s3BucketUrl = successAttachments?.find((file: any) => file?.id === fileItem?.id) ?? '';

      return {
        attachmentStatus: role === UserType.Customer ? AttachmentStatus.ReworkRequested : AttachmentStatus.Rework,
        mdAttachmentType: fileItem.mediaType === DocumentType.Video ? mdAttachmentType.Video : mdAttachmentType.Image,
        fileName: fileItem?.fileName ?? '',
        mdFileContext: role === UserType.Customer ? mdFileContext.ProofOfReworkRequested : mdFileContext.ProofOfRework,
        fileExtension: fileItem?.fileExtension as string,
        url: s3BucketUrl?.key ?? '',
        comments: fileItem?.comment,
      };
    });
    files = files.filter((attachment) => !isEmpty(attachment?.url));
    return files;
  };

  const getUpdateTaskPayload = () => {
    const taskApprovalpayload: CreateTaskApprovalInput = {
      approvalName: ApprovalType.TaskApproval,
      status: role === UserType.Customer ? TaskStatus.ReworkRequestedByCustomer : TaskStatus.Rework,
      approvalStatus: role === UserType.Customer ? ApprovalStatus.ReworkRequested : ApprovalStatus.Rework,
      mdApproverType:
        role === UserType.Customer
          ? ApproverType.Customer
          : isDistrictManager
            ? ApproverType.PrimeVendor
            : ApproverType.Supervisor,
    };
    if (!isEmpty(rejectionComment)) {
      taskApprovalpayload.reason = rejectionComment;
    }

    const updateTaskPayload: UpdateTaskInput = {
      taskId: location.state.taskRecord.taskId,
      associateId: location.state.taskRecord.associateId,
      isPartiallyCompleted: isPartiallyCompleted,
      taskApprovalInput: [{ ...taskApprovalpayload }],
    };
    if (role === UserType.Customer) {
      updateTaskPayload.suggestedReworkDate = reworkScheduleDate;
      updateTaskPayload.taskStatus = TaskStatus.ReworkRequestedByCustomer;
      updateTaskPayload.suggestedScheduleType = reworkShift;
    } else {
      updateTaskPayload.reworkDate = reworkScheduleDate;
      updateTaskPayload.taskStatus = TaskStatus.Rework;
    }

    return updateTaskPayload;
  };

  const handleRequestRework = async () => {
    setIsReworkLoading(true);
    try {
      if (proofs.length) {
        const s3BucketUploadedResponse = await storeAttachmentsToS3Cloud(proofs);
        const { successAttachments = [] } = s3BucketUploadedResponse || {
          successAttachments: [],
          failedAttachmentIds: [],
        };

        const files = getProofAttachmnetFiles(successAttachments);

        if (!isEmpty(location.state.taskRecord.taskAttachments)) {
          location.state.taskRecord.taskAttachments.forEach((attachmet: any) => {
            files.push({
              attachmentStatus: attachmet.attachmentStatus,
              mdAttachmentType: attachmet.mdAttachmentType,
              fileName: attachmet.fileName,
              mdFileContext: attachmet.mdFileContext,
              fileExtension: attachmet.fileExtension,
              url: attachmet.url,
              comments: attachmet.comments,
            });
          });
        }

        const addTaskAttachmentsResponse = await WorkOrdersApiService.addTaskAttachments(
          location.state.taskRecord.taskId,
          files
        );
        if (!isEmpty(addTaskAttachmentsResponse.errors)) {
          console.log('error while uploading the attachments: ', addTaskAttachmentsResponse.errors);
          const errorMsg = t('taskRejectionAndRework:addTaskAttachmentsErrorMsg');
          setSnackbarMessage(errorMsg);
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        }
      }

      const updateTaskPayload: UpdateTaskInput = getUpdateTaskPayload();

      const updateTasksResposne = await WorkOrdersApiService.updateMultipleTasksAtOnce([{ ...updateTaskPayload }]);
      if (updateTasksResposne.data && isEmpty(updateTasksResposne.errors)) {
        setTaskStatus(role === UserType.Customer ? TaskStatus.ReworkRequestedByCustomer : TaskStatus.Rework);
        const successMsg =
          role === UserType.Customer
            ? t('taskRejectionAndRework:reworkRequestedSuccessMsg')
            : t('taskRejectionAndRework:reworkScheduledSuccessMsg');
        setSnackbarMessage(successMsg);
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.log('error occured while requesting or scheduling task for rework: ', error);
      const errorMsg =
        role === UserType.Customer
          ? t('taskRejectionAndRework:reworkRequestedErrorMsg')
          : t('taskRejectionAndRework:reworkScheduledErrorMsg');
      setSnackbarMessage(errorMsg);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
    setOpenRequestRework(false);
    setIsReworkLoading(false);
  };

  const getWorkOrderDetails = async () => {
    const workOrderResponse = await WorkOrdersApiService.getAllWorkOrders({
      workOrderId: location.state.taskRecord.workOrderId,
    });
    if (workOrderResponse.data?.length) {
      setWorkOrderEndDate(new Date(workOrderResponse?.data?.[0].actualEndDate));
    }
  };

  useEffect(() => {
    const attachments = location.state.taskRecord.proofattachments;
    if (attachments.length) {
      getProofOfCompletion(attachments);
    }
    getWorkOrderDetails();
  }, []);

  useEffect(() => {
    if (snackBarStatus.isOpen && snackBarStatus.isApproved) {
      if (role === UserType.Customer) {
        setTaskStatus(TaskStatus.ApprovedByCustomer);
      } else if (role === UserType.Vendor && isDistrictManager) {
        setTaskStatus(TaskStatus.ApprovedByPrimeVendor);
      } else {
        setTaskStatus(TaskStatus.ApprovedBySupervisor);
      }
    }
  }, [snackBarStatus.isOpen]);

  return (
    <>
      <PageTitle title={t('dashboard:workTaskDetails')} showButton={true} />
      <CustomerContainer>
        <ChatContainer>
          <ChatMessageContainer>
            <ProofHeading sx={{ marginBottom: '1rem' }}>{t('taskRejectionAndRework:proofs')}</ProofHeading>
            <AttachmentsContainer>
              {isLoading ? (
                <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
              ) : (
                <>
                  {proofAttachments.length > 0 ? (
                    <>
                      {proofAttachments.filter((proof: any) => proof.mdFileContext === mdFileContext.ProofOfCompletion)
                        .length > 0 && (
                        <>
                          {renderProofs(
                            t('taskRejectionAndRework:proofsSubmittedByAssociate'),
                            proofAttachments.filter(
                              (proof: any) => proof.mdFileContext === mdFileContext.ProofOfCompletion
                            )
                          )}
                        </>
                      )}
                      {proofAttachments.filter((proof: any) => proof.mdFileContext === mdFileContext.ProofOfRework)
                        .length > 0 && (
                        <>
                          {renderProofs(
                            t('taskRejectionAndRework:proofsSubmittedBySupervisor'),
                            proofAttachments.filter((proof: any) => proof.mdFileContext === mdFileContext.ProofOfRework)
                          )}
                        </>
                      )}
                      {proofAttachments.filter(
                        (proof: any) => proof.mdFileContext === mdFileContext.ProofOfReworkRequested
                      ).length > 0 && (
                        <>
                          {renderProofs(
                            t('taskRejectionAndRework:proofsSubmittedByCustomer'),
                            proofAttachments.filter(
                              (proof: any) => proof.mdFileContext === mdFileContext.ProofOfReworkRequested
                            )
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <NoProofsContainer>
                      <img src={Icons.ErrorCautionImg} style={{ ...noDataImageStyles }} />
                      <NoProofsTag>{t('taskRejectionAndRework:noProofsErrorMessage')}</NoProofsTag>
                    </NoProofsContainer>
                  )}
                </>
              )}
            </AttachmentsContainer>
          </ChatMessageContainer>

          <ApproveRejectContainer>
            <ApproveOrRejectCard
              service={service}
              address={address}
              status={taskStatus}
              name={associateDetails.name}
              timeTaken={timeTaken}
              photos={proofTypes?.photo}
              videos={proofTypes?.video}
              associateLogo={associateDetails.logoUrl}
            />

            <ApproveRejectButtonContainer>
              {((taskStatus as TaskStatus) === TaskStatus.PendingCustomerReview && role === UserType.Customer) ||
              (taskStatus === 'PendingSupervisorApproval' && role === UserType.Vendor && !isDistrictManager) ||
              ((taskStatus as TaskStatus) === TaskStatus.PendingPrimeVendorApproval &&
                role === UserType.Vendor &&
                isDistrictManager) ? (
                <>
                  <ApproveOrRejectCardButtons
                    handleRejectButtonClick={handleRejectButtonClick}
                    handleApproveButtonClick={handleApproveButtonClick}
                    isSelfPerformJob={location.state.vendorTypes[0].mdVendorType === VendorType.SelfPerform}
                  />
                </>
              ) : null}

              <RejectDialog
                isRejectWindowOpen={isRejectWindowOpen}
                handleCloseRejectWindow={handleCloseRejectWindow}
                handleReject={(vendorMsg: string) => {
                  handleReject(taskNumber, vendorMsg);
                  setRejectWindowOpen(false);
                }}
              />

              <ApproveDialog
                isApproveWindowOpen={isApproveWindowOpen}
                handleCloseApproveWindow={handleCloseApproveWindow}
                handleApprove={() => {
                  handleApprove(taskNumber, location.state.approveByRole, location.state.vendorTypes);
                  setApproveWindowOpen(false);
                }}
                rowDetails={{ taskName: service }}
              />
            </ApproveRejectButtonContainer>
          </ApproveRejectContainer>
        </ChatContainer>
        {snackBarStatus.isOpen ? (
          <SnackBar
            isApproved={snackBarStatus.isApproved}
            taskCount={1}
            handleOnClose={() =>
              setSnackBarStatus({
                isOpen: false,
                isApproved: false,
              })
            }
          />
        ) : null}
      </CustomerContainer>
      {/* <TaskDetailsBottomBar /> */}
      {selectedMedia && (
        <FullScreenView url={selectedMedia} onClose={handleCloseFullScreen} mediaType={selectedMediaType} />
      )}
      {openRejectTask && (
        <RejectWorkTask
          openRejectTask={openRejectTask}
          handleRejectTaskClose={handleRejectTaskClose}
          taskName={`${location?.state?.taskRecord?.taskName}-Floor ${location?.state?.taskRecord?.floor ? location?.state?.taskRecord?.floor : 0}`}
          rejectionComment={rejectionComment}
          setRejectionComment={setRejectionComment}
          isPartiallyCompleted={isPartiallyCompleted}
          setIsPartiallyCompleted={setIsPartiallyCompleted}
          proofs={proofs}
          setProofs={setProofs}
          handleDeleteProofs={handleDeleteProofs}
          handleRequestRework={handleRejectTask}
        />
      )}
      {openRequestRework && (
        <RequestOrScheduleRework
          openRequestRework={openRequestRework}
          handleRequestReworkClose={handleRequestReworkClose}
          handleRequestRework={handleRequestRework}
          reworkShift={reworkShift}
          setReworkShift={setReworkShift}
          isCurrentShiftAvailable={location.state.taskRecord.isCurrentShiftAvailable}
          nextAvailableWorkDay={location.state.taskRecord.nextAvailableWorkDay}
          tomorrowDate={tomorrow}
          workOrderEndDate={workOrderEndDate}
          reworkScheduleDate={reworkScheduleDate}
          setReworkScheduleDate={setReworkScheduleDate}
          isDataLoading={isReworkLoading}
          proofs={proofs}
        />
      )}
      <SnackbarMessage
        open={snackbarOpen}
        successMessage={snackbarMessage}
        errorMessage={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
      />
    </>
  );
};

export default TaskDetailsPage;
