/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { useTranslation } from 'react-i18next';
import React, { useEffect, useMemo, useState, useRef, useCallback } from 'react';
import { MRT_ColumnDef } from 'material-react-table';
import { styled, Button, Typography, Box, Stack, CircularProgress } from '@mui/material';

import DataGrid from '../../../../Shared/Components/Common/DataGrid/DataGrid';
import { TaskData } from '../../../Jobs/Components/WorkTasks/WorkTasks';
import { IJobOverview } from '../../../Jobs/Components/JobsListView/JobsListView';
import { IJobs } from '../../../Jobs/Components/JobView/JobsView';
import { HeaderContainer, EstimationTableInput } from '../CreateBid.Style';
import { buttonStyles } from '../../../Jobs/Components/AddEditOneTimeJob/AddEditOneTimeJob.Style';

import { Icons } from '../../../../Shared/Constants/Icons';
import { calculateTotalEstimationWithProfit, fetchDataFromS3Bucket } from '../../../../Shared/Utilities/utils';
import { UserType, DocumentType, PricingOptions } from '../../../../Shared/Constants/App';
import { v4 as uuidv4 } from 'uuid';
import theme from '../../../../Shared/Themes/theme';
import { BidAttachment } from '../../../../API';
import { IBidList } from '../../../Jobs/Components/JobDetails/OneTimeJobDetails';
import { IProfileCardProps } from '../../../Customer/Components/ViewFacilityDetails/ViewFacilityDetails';
import ApproveDialog from '../../../Jobs/Components/TaskDetails/logoutDialog';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';

export const Wrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
}));

export const HeadingText = styled('div')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: '1.255rem',
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.375rem',
  letterSpacing: '0.009375rem',
  color: theme.palette.text.primary,
  paddingBottom: '1rem',
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h4.fontSize,
    lineHeight: '1.5rem',
  },
}));

interface EstimationTable {
  frequency: string;
  name: any;
  facilityStatus: string;
  serviceMeasure: string;
  numOfHours: number;
  ratePerHour: number;
  costPerService: number;
  servicesPerWeek: number;
  weeklyCost: number;
  profitPerWeek: number;
  weeklyBid: number;
  numOfWeeks: number;
  totalServices: number;
  totalJobEstimation: number;
}

interface SubVendorEstimationTable {
  storedJob: any;
  estimationType: UserType;
  setTotalEstimation: (data: number) => void;
  setCopyTableData: (data: any) => void;
  profitPercentage: number;
  additionalSubTotal: number;
  uploadedVendorQuotes: any;
  setUploadedVendorQuotes: (data: any) => void;
  vendorQuotesAttachments: any;
  handleDeleteQuoteAttachment: (data: string) => void;
  isQuoteUploading: boolean;
  isEditable: boolean;
  isCardView: boolean;
  isSubJobServicesLoading?: boolean;
  setIsBidVersionLoader: any;
  selectedPricingOption: string;
}

const SubVendorEstimationTable = ({
  storedJob,
  estimationType = UserType.SubVendor,
  setTotalEstimation,
  setCopyTableData,
  uploadedVendorQuotes,
  setUploadedVendorQuotes,
  vendorQuotesAttachments,
  handleDeleteQuoteAttachment,
  isQuoteUploading,
  isEditable,
  isCardView,
  isSubJobServicesLoading,
  setIsBidVersionLoader,
  selectedPricingOption,
}: SubVendorEstimationTable) => {
  const { t } = useTranslation(['vendor']);

  const [isShowSessionExpirePopUp, setIsShowSessionExpirePopUp] = useState(false);
  const [isShowSessionExpirePopUpLoading, setIsShowSessionExpirePopUpLoading] = useState(false);

  const [tableData, setTableData] = useState<any[]>(() => []);
  const [subTotal, setSubTotal] = useState(0);
  const { addButton, addButtonText } = buttonStyles;
  const isThirdFlow = storedJob?.executionType === 'subVendor' && storedJob?.estimationType === 'estimateOutsidePeazy';
  const navigate = useNavigate();

  const renderCell = (renderedCellValue: React.ReactNode) => {
    // If renderedCellValue is a string or number, return it. Otherwise, return a fallback '-'
    if (typeof renderedCellValue === 'string' || typeof renderedCellValue === 'number') {
      return renderedCellValue ? renderedCellValue : '-';
    }
    return '-';
  };

  const renderDecimalCell = (renderedCellValue: React.ReactNode) => {
    // If renderedCellValue is a string or number, return it. Otherwise, return a fallback '-'
    if (typeof renderedCellValue === 'string' || typeof renderedCellValue === 'number') {
      return renderedCellValue ? Number(renderedCellValue).toFixed(2) : '0.00';
    }
    return '0.00';
  };

  const renderTotalCell = (cellValue: string) => {
    let totalServicesCount = 0;

    tableData.forEach((service) => {
      const cellVal = service[`${cellValue}`];
      if (cellVal) {
        totalServicesCount += parseFloat(cellVal);
      }
    });

    return (
      <Box>
        <Stack>
          <Box>
            {cellValue !== 'numOfHours' && cellValue !== 'totalServices'
              ? isNaN(totalServicesCount)
                ? '$0.00'
                : totalServicesCount?.toLocaleString?.('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
              : isNaN(totalServicesCount)
                ? 0
                : totalServicesCount}
          </Box>
        </Stack>
      </Box>
    );
  };

  const handleInputValue = (cell: any, value: any) => {
    const tableCopy = JSON.parse(JSON.stringify(tableData));
    if (value.includes('.')) {
      const splicedInput = value.split('.');
      if (splicedInput?.[1]?.length <= 2) {
        tableCopy[cell.row.index][cell.column.id as keyof EstimationTable] = value;
      }
    } else {
      tableCopy[cell.row.index][cell.column.id as keyof EstimationTable] = value;
    }
    setTableData([...tableCopy]);
    setCopyTableData([...tableCopy]);
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const handleSaveCell = (cell: any, value: any) => {
    const tableCopy = JSON.parse(JSON.stringify(tableData));
    let allowDecimalValue = false;
    if (value && value.includes('.')) {
      const splicedInput = value.split('.');
      if (splicedInput?.[1]?.length <= 2) {
        allowDecimalValue = true;
      } else {
        allowDecimalValue = false;
      }
    } else if (value && !value.includes('.')) {
      allowDecimalValue = true;
    }
    if (allowDecimalValue) {
      tableCopy[cell.row.index][cell.column.id as keyof EstimationTable] = value;
      const rowIndex = cell.row.index;
      const cellValue = parseFloat(value);

      if (cell.column.id !== 'totalJobEstimation') {
        if (cell.column.id === 'numOfHours') {
          const ratePerHour = tableCopy[rowIndex]['ratePerHour'] || 0;
          tableCopy[rowIndex]['totalJobEstimation'] = cellValue * ratePerHour;
        } else {
          const numOfHours = tableCopy[rowIndex]['numOfHours'] || 0;
          tableCopy[rowIndex]['totalJobEstimation'] = cellValue * numOfHours;
        }
      }

      const finalTotalEstimation = tableCopy.reduce(
        (total: number, item: any) => total + parseFloat(item.totalJobEstimation),
        0
      );
      const totalWithProfilPercent = calculateTotalEstimationWithProfit(parseFloat(finalTotalEstimation || 0), 0);
      setTotalEstimation(totalWithProfilPercent);
      setSubTotal(finalTotalEstimation);
      setTableData([...tableCopy]);
      setCopyTableData([...tableCopy]);
    }
  };

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileView = async (key: string) => {
    const link = await fetchDataFromS3Bucket(navigate, key);
    window.open(link);
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files;
    if (selectedFiles) {
      const fileDetails = Array.from(selectedFiles).map((file) => {
        const attachmentUniqueId = uuidv4();
        const fileNameSplits = file.name.split('.');
        return {
          url: URL.createObjectURL(file),
          fileName: fileNameSplits[0],
          fileExtension: fileNameSplits[fileNameSplits.length - 1],
          mdAttachmentType: file.type.includes('image') ? DocumentType.Image : DocumentType.PDF,
          attachmentId: '',
          id: attachmentUniqueId,
        };
      });
      setUploadedVendorQuotes([...uploadedVendorQuotes, ...fileDetails]);
    }
  };

  useEffect(() => {
    if (uploadedVendorQuotes.length && fileInputRef.current) fileInputRef.current.value = '';
  }, [uploadedVendorQuotes]);

  const vendorsQuoteColumns = useMemo<MRT_ColumnDef<TaskData | IJobOverview | IJobs | IBidList | IProfileCardProps>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Service Category',
        enablePinning: true,
        enableSorting: false,
        enableColumnFilter: false,
        enableColumnActions: false,
        enableEditing: false,
        Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
        Footer: () => t('vendor:subTotal'),
      },
      {
        accessorKey: 'mdServiceUnits',
        header: 'Serviceable Area',
        enablePinning: true,
        enableSorting: false,
        enableColumnFilter: false,
        enableColumnActions: false,
        enableEditing: false,
        Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
        Footer: () => '-',
      },
      {
        accessorKey: 'totalJobEstimation',
        header: 'Amount',
        size: 100,
        enablePinning: true,
        enableSorting: false,
        enableColumnFilter: false,
        enableColumnActions: false,
        enableEditing: false,
        Cell: ({ cell, renderedCellValue }: any) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.2em',
            }}>
            <span>$</span>
            <span>{!isEditable ? renderDecimalCell(renderedCellValue) : null}</span>
            {isEditable && (
              <EstimationTableInput
                type="text"
                value={renderedCellValue}
                placeholder="Amount"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleInputValue(cell, event.target.value);
                  handleSaveCell(cell, event.target.value);
                }}
                // onBlur={(event: React.FocusEvent<HTMLInputElement>) => handleSaveCell(cell, event.target.value)}
                onInput={(event: React.FormEvent<HTMLInputElement>) => {
                  const inputElement = event.target as HTMLInputElement;
                  const inputValue = inputElement.value;
                  const numericInput = inputValue.replace(/[^0-9.]/, '');
                  inputElement.value = numericInput;
                }}
              />
            )}
          </Box>
        ),
        Footer: () => renderTotalCell('totalJobEstimation'),
      },
    ],
    [tableData, subTotal]
  );

  const getServiceMeasure = (item: any) => {
    return item.serviceMeasure
      ? item.serviceMeasure
      : selectedPricingOption
        ? selectedPricingOption
        : PricingOptions.Hours;
  };

  const getServicesData = () => {
    return storedJob?.subJobServices?.map((item: any) => {
      return {
        name: item.name ?? item.service,
        serviceMeasure: getServiceMeasure(item),
        mdServiceUnits: item.unit ?? item.mdServiceUnits,
        numOfHours: item.numOfHours ? item.numOfHours : 0,
        ratePerHour: item.ratePerHour ? item.ratePerHour : 0,
        totalServices: item.totalServices ? item.totalServices : 1,
        totalJobEstimation: item.totalJobEstimation ? item.totalJobEstimation : 0,
        mdServiceId: item.mdServiceId,
        jobId: item.jobId,
        estimationId: item.estimationId ?? '',
      };
    });
  };

  const fetchData = () => {
    try {
      const servicesData = getServicesData();

      if (estimationType === UserType.SubVendor) {
        setTableData(servicesData ? [...servicesData] : []);
        setCopyTableData(servicesData ? [...servicesData] : []);
      } else {
        setTableData(servicesData ? [...servicesData] : []);
      }
    } catch (error) {
      // Handle any errors that may occur during fetching the data
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    setTotalEstimation(tableData.reduce((total, item) => total + item.totalJobEstimation, 0));
    setSubTotal(tableData.reduce((total, item) => total + item.totalJobEstimation, 0));
  }, [tableData]);

  useEffect(() => {
    if (storedJob?.subJobServices?.length) {
      fetchData();
    }
  }, [storedJob?.subJobServices?.length, isSubJobServicesLoading]);

  const signOut = useCallback(async () => {
    try {
      setIsShowSessionExpirePopUpLoading(true);
      await Auth.signOut();
      localStorage.removeItem('auth');
      localStorage.removeItem('customerDetails');
      navigate('/login');
    } catch (error) {
      console.error('An error occurred during sign out:', error);
    } finally {
      setIsShowSessionExpirePopUpLoading(false);
    }
  }, []);

  const LogoutUI = () => {
    return (
      <div>
        {t('logout:logoutConfirm')} <br /> <strong>{t('logout:peazyApplication')}</strong>
      </div>
    );
  };

  return (
    <>
      <HeaderContainer
        sx={
          isCardView
            ? { display: 'flex', flex: 1, justifyContent: 'space-between', flexDirection: 'row', flexWrap: 'wrap' }
            : {
                display: 'flex',
                flex: 1,
                justifyContent: 'space-between',
                flexDirection: 'row',
                flexWrap: 'wrap',
                boxShadow: 'unset',
                padding: 0,
              }
        }>
        <div style={{ width: '100%' }}>
          <HeadingText sx={{ color: theme.palette.text.secondary }}>{t('vendor:vendorsQuote')}</HeadingText>
          {isSubJobServicesLoading ? (
            <CircularProgress size={30} sx={{ color: theme.palette.primary.dark, marginLeft: '45%' }} />
          ) : (
            <DataGrid
              columns={vendorsQuoteColumns}
              data={tableData}
              enableColumnPinning={true}
              enableRowSelect={false}
              enableEditing={false}
              rightPinArr={['weeklyBid']}
              leftPinArr={['name', 'serviceMeasure']}
              handleSaveCell={handleSaveCell}
            />
          )}
          {vendorQuotesAttachments.length === 0 && isThirdFlow && !isEditable ? (
            <Typography sx={{ fontSize: '0.8rem' }}>{t('vendor:noDocumentsFound')}.</Typography>
          ) : null}
          {vendorQuotesAttachments.length !== 0
            ? vendorQuotesAttachments.map((quoteItem: BidAttachment) => {
                return (
                  <div style={{ display: 'flex', marginTop: '1.5rem' }}>
                    <Button sx={{ padding: '0' }} onClick={() => handleFileView(quoteItem?.url ?? '')}>
                      <Typography
                        sx={{
                          color: theme.palette.primary.dark,
                          textTransform: 'capitalize',
                        }}>{`${quoteItem?.fileName}`}</Typography>
                    </Button>
                    {isEditable && (
                      <Button
                        onClick={() => handleDeleteQuoteAttachment(quoteItem.attachmentId ?? '')}
                        sx={{
                          backgroundColor: 'transparent',
                          padding: 0,
                          margin: 0,
                          minWidth: 0,
                          paddingLeft: '1em',
                        }}>
                        <img src={Icons.DeleteIcon} alt={t('altTexts:delete')} />
                      </Button>
                    )}
                  </div>
                );
              })
            : null}
          {isEditable && vendorQuotesAttachments.length === 0 && (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {isThirdFlow && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-betwen',
                    width: '100%',
                    alignItems: 'center',
                    paddingTop: '1rem',
                  }}>
                  {isQuoteUploading ? (
                    <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
                  ) : (
                    <Box sx={{ marginTop: '1.5rem' }}>
                      <Button
                        variant="outlined"
                        sx={{ ...addButton, margin: 0, marginBottom: 0, opacity: setIsBidVersionLoader ? '0.7' : '1' }}
                        disabled={setIsBidVersionLoader}
                        onClick={() => {
                          const authInfo = localStorage.getItem('auth');
                          const { signedInTime } = JSON.parse(authInfo as string);
                          const isPreviousSignedSessionMoreThan_55_minutes =
                            new Date().getTime() - signedInTime > 1000 * 60 * 55; //checking 55 minutes difference from singed in time.
                          if (isPreviousSignedSessionMoreThan_55_minutes) {
                            setIsShowSessionExpirePopUp(true);
                          } else {
                            handleFileUpload();
                          }
                        }}>
                        <img src={Icons.UploadIcon} alt={t('altTexts:addIcon')} />
                        <Typography sx={{ ...addButtonText, textTransform: 'none' }}>
                          {t('vendor:uploadQuoteButtonText')}
                        </Typography>
                      </Button>
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        accept=".jpg, .jpeg, .png, .pdf"
                        onChange={handleFileSelect}
                      />
                    </Box>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        <ApproveDialog
          title={t('logout:sessionTimedOutTitle')}
          loading={isShowSessionExpirePopUpLoading}
          ChildComponent={<LogoutUI />}
          isApproveWindowOpen={isShowSessionExpirePopUp}
          showCancelButton={false}
          isShowCloseIcon={false}
          handleCloseApproveWindow={() => setIsShowSessionExpirePopUp(false)}
          handleApprove={() => signOut()}
        />
      </HeaderContainer>
    </>
  );
};

export default SubVendorEstimationTable;
