/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { CardTitle, CloseDialogBoxIcon, UpdateShiftDateWrapper, buttonStyles } from './UpdateShiftDate.Style';
import { Icons } from '../../../../Shared/Constants/Icons';
import { useForm, useWatch } from 'react-hook-form';
import theme from '../../../../Shared/Themes/theme';
import { DatePickerWrapper } from '../../../../Shared/Components/Common/GoogleMap/GoogleMapChips.Style';
import DateSelection from '../../../../Shared/Components/Common/DatePicker/DateSelection';
import { Button, CircularProgress, Typography } from '@mui/material';

interface UpdateShiftDate {
  openUpdateShift: boolean;
  handleShiftDateClose: () => void;
  isDataLoading: boolean;
  handleOnConfirm: (date: string) => void;
}

const UpdateShiftDateCard = ({
  openUpdateShift,
  handleShiftDateClose,
  isDataLoading,
  handleOnConfirm,
}: UpdateShiftDate) => {
  const { t } = useTranslation(['altTexts', 'taskRejectionAndRework', 'rescheduleShiftDate']);
  const { confirmButton, buttonText } = buttonStyles;

  const { control } = useForm({
    defaultValues: {
      shiftDate: new Date().toISOString(),
    },
  });

  const shiftDate = useWatch({
    control,
    name: 'shiftDate',
  });

  const handleDialogClose = (event: React.SyntheticEvent, reason?: string) => {
    if (reason && reason === 'backdropClick') return;
    handleShiftDateClose();
  };

  return (
    <UpdateShiftDateWrapper open={openUpdateShift} onClose={handleDialogClose}>
      <CloseDialogBoxIcon onClick={handleShiftDateClose}>
        <img src={Icons.InfoWindowCloseIcon} alt={t('altTexts:closeIcon')} />
      </CloseDialogBoxIcon>
      <CardTitle>{t('rescheduleShiftDate:updateShiftDate')}</CardTitle>
      <DatePickerWrapper
        sx={{
          maxWidth: '100%',
          '& .MuiOutlinedInput-root': {
            '& .MuiInputBase-input': {
              padding: '0.5625rem 0 0.5625rem 1rem',
              color: theme.palette.primary.dark,
            },
          },
          '& .react-datepicker-popper': {
            zIndex: 3,
            transform: 'translate(50%, -50%) !important',
          },
          pointerEvents: isDataLoading ? 'none' : 'auto',
        }}>
        <DateSelection
          requiredText="Date is mandatory"
          control={control}
          fieldName={'shiftDate'}
          label={t('taskRejectionAndRework:selectDate')}
          isExcludePastDate={true}
          isExcludeFutureDate={false}
          textFieldStyles={{
            '& .MuiOutlinedInput-root': {
              '& fieldset,&:hover fieldset,&.Mui-focused fieldset': {
                borderWidth: 1,
                borderColor: theme.palette.primary.dark,
                borderRadius: '1rem',
              },
            },
            '& .MuiInputLabel-root': {
              color: theme.palette.primary.dark,
              '&.Mui-focused': {
                color: theme.palette.primary.dark,
              },
            },
          }}
        />
      </DatePickerWrapper>
      <Button
        sx={{
          ...confirmButton,
          opacity: isDataLoading ? 0.5 : 1,
        }}
        disabled={isDataLoading}
        onClick={() => handleOnConfirm(shiftDate)}>
        {isDataLoading ? (
          <CircularProgress size={30} sx={{ color: theme.palette.common.white }} />
        ) : (
          <Typography sx={{ ...buttonText, color: theme.palette.common.white }}>
            {t('rescheduleShiftDate:confirm')}
          </Typography>
        )}
      </Button>
    </UpdateShiftDateWrapper>
  );
};

export default UpdateShiftDateCard;
