/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Tabs, Divider, Stack, CircularProgress } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useLocation, useNavigate } from 'react-router-dom';
import { MRT_ColumnDef, MRT_SortingState } from 'material-react-table';

import { getMarketplaceTabs } from '../../Models/constants';
import { isEmpty } from 'lodash';
import {
  UserRoles,
  calculateWorkVolume,
  deepClone,
  fetchDataFromS3Bucket,
  getAuthData,
  getDurationInDaysOrMonths,
  getFacilityIcon,
  getStoredCustomerDetails,
} from '../../../../Shared/Utilities/utils';
import moment, { utc } from 'moment';
import {
  Facility,
  JobBid,
  JobRecommendation,
  JobDueDate,
  JobInterest,
  JobInterestsActionType,
  UpdateWorkOrderInput,
  UserRolesAndScopes,
  WorkOrderStatus,
  JobStatus,
  BidStatus,
  ServiceCategoryV3,
  UpdateJobBidInput,
  DraftStatus,
} from '../../../../API';
import {
  JobType,
  ModifiedJobData,
  JobItem,
  ITabPanelProps,
  IJobRecommendationsType,
  IVendorJobAwardData,
  IFacilityType,
  IFacilityTypeItem,
} from '../../Models/Marketplace.model';
import { IJobType, SubmittedBidStatus } from '../../../../Shared/Constants/App';
import AsyncStorageKeys from '../../../../Shared/Constants/StorageKeys';

import MarketplaceApiService from '../../Services/marketplaceServices';
import FacilityApiService from '../../../Facility/Services/FacilityApiService';
import BidCreationApiService from '../../Services/BidCreationApiService';
import MasterDataApiService from '../../../../Shared/Services/MasterDataService';
import WorkOrdersApiService from '../../../Jobs/Services/WorkOrdersService';
import CustomerApiService from '../../../Customer/Services/CustomerService';
import JobCreateApiService from '../../../Jobs/Services/JobCreateService';

import { IJobOverview } from '../../../Jobs/Components/JobsListView/JobsListView';
import { TaskData } from '../../../Jobs/Components/WorkTasks/WorkTasks';
import { IJobRow, IJobs } from '../../../Jobs/Models/JobsView.Model';
import { JobServices } from '../../../../Shared/Models/Bid';
import { IBidList } from '../../../Jobs/Components/JobDetails/OneTimeJobDetails';
import { IProfileCardProps } from '../../../Customer/Components/ViewFacilityDetails/ViewFacilityDetails';
import DataGrid from '../../../../Shared/Components/Common/DataGrid/DataGrid';
import PageTitle from '../../../../Shared/Components/Common/PageTitle/PageTitle';
import SnackbarMessage from '../../../Onboarding/Components/Login/SnackbarMessage';
import VendorJobAward from './AwardJobCard';
import ModifyPopUp from '../../../Jobs/Components/JobDetails/ModifyPopUp';

import { Icons } from '../../../../Shared/Constants/Icons';

import theme from '../../../../Shared/Themes/theme';

import {
  ActionsColumn,
  Linking,
  MenuWrapper,
  Menus,
} from '../../../../Shared/Components/Common/DataGrid/DataGrid.styles';
import { CustomerContainer } from '../../../../Shared/Components/Layout/styles';
import { AwardCardContainer, LoaderTag, NoDataContainer, NoDataTag } from './Marketplace.Style';
import { TabSection, TabTitle } from '../../../Jobs/Components/JobDetailsOverView/JobDetailsOverview.styles';
import { TabsWrapper } from '../../../Jobs/Components/JobView/JobsView.styles';
import VendorApiService from '../../../../Shared/Services/VendorService';
import CreateBidServices from '../../Services/CreateBidServices';
import { getTotalServiceArea } from '../../Utilities/MarketplacePage.Utils';
import { StyledPagination } from '../../../../Shared/Components/Common/CommonStyle/Pagination.Style';
import WhiteTooltip from '../../../../Shared/Components/Common/CommonStyle/Tooltip.Style';

function a11yProps(index: number) {
  return {
    id: `marketplace-tab-${index}`,
    'aria-controls': `marketplace-tabpanel-${index}`,
  };
}

function TabPanel(props: ITabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`marketplace-tabpanel-${index}`}
      aria-labelledby={`marketplace-tab-${index}`}
      {...other}>
      {value === index && children}
    </div>
  );
}

const MarketplacePage = () => {
  const marketplaceTabs = getMarketplaceTabs();
  const { t } = useTranslation(['vendor', 'tableHeader', 'altTexts', 'vendorBidCreation', 'jobAward']);
  const location = useLocation();
  const pathVariables = location?.pathname.split('/');
  const tabIndex = marketplaceTabs.findIndex((tab) => tab.route === pathVariables[pathVariables.length - 1]);
  const [tabSelected, setTabSelected] = useState(tabIndex);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [jobs, setJobs] = useState<any[]>([]);
  const [bidsInProgressJobs, setBidsInProgressJobs] = useState<any[]>([]);
  const [submittedBids, setSubmittedBids] = useState<any[]>([]);
  const [jobInterests, setJobInterests] = useState<any[]>([]);

  const [clickedRow, setClickedRow] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [upcomingJob, setUpcomingJob] = useState<IVendorJobAwardData[]>([]);
  const [isPrimaryLoading, setIsPrimaryLoading] = useState<boolean>(false);
  const [viewDetailsLoading, setViewDetailsLoading] = useState<boolean>(false);
  const [loadingCardId, setLoadingCardId] = useState<string>('');
  const [rowInfo, setRowInfo] = useState<any>({});

  const [sortBy, setSortBy] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [newSort, setNewSort] = useState<boolean>();
  const [newSortOrder, setNewSortOrder] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState<any>(0);
  const [isModifyBidPopupOpen, setIsModifyBidPopupOpen] = useState(false);
  const [isRequestRevisionBidPopupOpen, setIsRequestRevisionBidPopupOpen] = useState(false);

  const [InProgressBidDetails, setInProgressBidDetails] = useState<any>({});
  const [RequestRevisionBidDetails, setRequestRevisionBidDetails] = useState<any>({});
  const [isResubmitLoader, setIsResubmitLoading] = useState<boolean>(false);

  const itemsPerPage = 10;

  const noDataImageStyles: React.CSSProperties = {
    width: '20rem',
  };

  const handleChange = (event: any, value: number) => {
    setCurrentPage(value);
  };

  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchFacilityTypes = async (keyCode: string) => {
    try {
      const facilityTypesResponse = await MasterDataApiService.getAllFacilityTypes();

      if (!facilityTypesResponse.errors?.length) {
        const limit = facilityTypesResponse?.metaData?.totalCount;
        const facilityTypesResponseNew = await MasterDataApiService.getAllFacilityTypes(limit);
        const modifiedFacilityTypes = facilityTypesResponseNew.data.map((dataItem: IFacilityTypeItem) => {
          return {
            keyCode: dataItem.keyCode,
            name: dataItem.value,
          };
        }) as IFacilityType[];
        const facilityType = modifiedFacilityTypes.find((ft) => ft.keyCode === keyCode);
        return facilityType ? facilityType.name : '';
      }
    } catch (e) {
      console.log('Error', e);
    }
  };

  const renderCell = (renderedCellValue: React.ReactNode) => {
    // If renderedCellValue is a string or number, return it. Otherwise, return a fallback '-'
    if (typeof renderedCellValue === 'string' || typeof renderedCellValue === 'number') {
      return renderedCellValue ? renderedCellValue : '-';
    }
    return '-';
  };

  const renderPercentageMatchCell = (renderedCellValue: React.ReactNode) => {
    if (!renderedCellValue) {
      return 0;
    }
    const numericalValue = parseFloat(String(renderedCellValue)) * 100;
    if (String(numericalValue).includes('.')) {
      return numericalValue.toFixed(2);
    } else {
      return numericalValue;
    }
  };

  const renderFinalQuoteCell = (renderedCellValue: React.ReactNode) => {
    if (typeof renderedCellValue === 'string' || typeof renderedCellValue === 'number') {
      return `$${parseFloat(String(renderedCellValue)).toFixed(2)}`;
    }
    return '-';
  };

  const formatDateCell = (cell: any) => {
    const cellValue = cell.getValue();
    if (cellValue) {
      return utc(cellValue).format('MM/DD/YYYY');
    }
    return '-';
  };

  // const getStatusText = (status: DraftStatus): string => {
  //   switch (status) {
  //     case DraftStatus.Y:
  //       return 'Yet to be completed';
  //     case DraftStatus.C:
  //       return 'Completed';
  //     default:
  //       return '-';
  //   }
  // };

  const renderActionsCell = (row: any) => {
    // const iconSrc = [Icons.EyeIcon, Icons.BidIcon, Icons.ShortlistIcon, Icons.VectorIcon];

    // const actionDefinitions = [
    //   {
    //     path: `/create-bid`,
    //     text: `${t('vendor:bidNow')}`,
    //     altText: 'bid-now-icon',
    //     iconIndex: 1,
    //   },
    //   {
    //     text: `${t('vendor:shortlist')}`,
    //     altText: 'shortlist-icon',
    //     iconIndex: 2,
    //     action: () => handleShortList(rowNo),
    //   },
    //   {
    //     text: `${t('vendor:notInterested')}`,
    //     altText: 'not-interested-icon',
    //     iconIndex: 3,
    //     action: () => handleNotInterested(rowNo),
    //   },
    // ];

    return (
      <ActionsColumn>
        {(tabSelected === 0 || tabSelected === 2) && (
          <Linking
            to={{ pathname: `/create-bid` }}
            state={{
              jobDetails: { ...jobs[row.index], isVendorBid: true },
              jobId: jobs[row.index].jobId,

              //  subVendor: true
            }}>
            <IconButton
              size="large"
              sx={{
                '&:hover': {
                  background: theme.palette.primary.main,
                },
              }}
              onClick={() => {
                localStorage.removeItem(AsyncStorageKeys.currentJob);
              }}>
              <WhiteTooltip title={t('vendor:bid')}>
                <img src={Icons.BidIcon} alt={t('altTexts:bidnow')} />
              </WhiteTooltip>
            </IconButton>
          </Linking>
        )}
        <WhiteTooltip title={t('vendor:more')}>
          <IconButton
            key={row.index}
            sx={{
              backgroundColor: clickedRow === row.index && open ? theme.palette.primary.main : 'unset',
              '&:hover': {
                background: theme.palette.primary.main,
              },
            }}
            onClick={(e) => {
              handleClick(e);
              setClickedRow(row.index);
              setRowInfo(row.original);
            }}
            size="large"
            aria-haspopup="true"
            {...(open && { 'aria-expanded': 'true', 'aria-controls': 'account-menu' })}>
            <MoreVertIcon sx={{ color: theme.palette.common.black }}></MoreVertIcon>
          </IconButton>
        </WhiteTooltip>
        <MenuWrapper anchorEl={anchorEl} id="account-menu" open={open} onClose={handleClose} onClick={handleClose}>
          <Linking
            to={{ pathname: `/onetimejobdetails`, search: `?id=${rowInfo.jobId}` }}
            state={{
              facilityItem: { ...rowInfo },
              facility: { ...rowInfo.jobFacility, address: { addressName: rowInfo.address } },
              job: rowInfo,
            }}>
            <Menus>
              <img src={Icons.EyeIcon} alt={t('altTexts:viewDetails')} /> {t('dashboard:viewJobDetails')}
            </Menus>
            {/* <Divider /> */}
          </Linking>
          {/* {actionDefinitions.map((action, index) => (
            <Linking to={{ pathname: action.path }} state={jobs[row.index]} key={index}>
              {createMenu(action.iconIndex, action.text, action.altText, action.action)}
            </Linking>
          ))} */}
          {(tabSelected === 0 || tabSelected === 2) && (
            <>
              <Divider />
              <Menus
                onClick={() => handleOnClickInterestedOrNot(rowInfo.jobId, JobInterestsActionType.NotInterested)}
                style={{ paddingLeft: '23px' }}>
                <img src={Icons.VectorIcon} alt={t('altTexts:notInterested')} /> {t('vendor:notInterested')}
              </Menus>
            </>
          )}
          {tabSelected === 3 && (
            <>
              <Divider />
              <Menus onClick={() => handleOnClickInterestedOrNot(rowInfo.jobId, JobInterestsActionType.Interested)}>
                <img src={Icons.ShortlistIcon} alt={t('altTexts:shortlist')} /> {t('vendor:shortlist')}
              </Menus>
            </>
          )}
        </MenuWrapper>
      </ActionsColumn>
    );
  };

  const renderSubmittedBidsActionsCell = (row: any) => {
    const { jobId, jobCustodian } = row.original as unknown as IJobRow;
    const { customerId } = getStoredCustomerDetails();
    return (
      <ActionsColumn>
        {row.original?.bidStatus === SubmittedBidStatus.RevisionRequested && (
          <IconButton
            size="large"
            sx={{
              '&:hover': {
                background: theme.palette.primary.main,
              },
            }}
            onClick={() => {
              setIsRequestRevisionBidPopupOpen(true);
              setRequestRevisionBidDetails({
                jobDetails: { ...submittedBids[row.index], isVendorBid: true, isRequestRevision: true },
                jobId: submittedBids[row.index].jobId,
              });
            }}>
            <WhiteTooltip title={t('vendor:reviseBid')}>
              <img src={Icons.EditBlackIcon} alt={t('altTexts:modify')} />
            </WhiteTooltip>
          </IconButton>
        )}
        {(!jobCustodian || (jobCustodian && customerId !== jobCustodian)) && (
          <Linking
            to={{ pathname: `/conversations`, search: `?jobId=${jobId}` }}
            state={{ ...row.original, tag: 'BID_RELATED' }}
            key={0}>
            <IconButton
              size="large"
              sx={{
                '&:hover': {
                  background: theme.palette.primary.main,
                },
              }}>
              <WhiteTooltip title={t('vendor:message')}>
                <img
                  src={Icons.ConversationIcon}
                  alt={t('altTexts:conversation')}
                  style={{ width: '1.3rem', marginTop: '2px' }}
                />
              </WhiteTooltip>
            </IconButton>
          </Linking>
        )}
        <Linking
          to={{
            pathname: '/onetimejobdetails',
            search: `?id=${jobId}`,
          }}
          state={{
            facilityItem: { ...row.original },
            facility: {
              ...row.original.jobFacility,
              address: { addressName: row.original?.jobFacility?.facilityAddress },
              facilityType: row.original?.jobFacility?.mdFacilityType,
            },
            job: { ...row.original },
            isRequestRevision: true,
          }}
          key={2}>
          <IconButton
            size="large"
            sx={{
              '&:hover': {
                background: theme.palette.primary.main,
              },
            }}>
            {' '}
            <WhiteTooltip title={t('homePage:viewDetails')}>
              <img src={Icons.EyeIcon} alt={t('altTexts:viewDetails')} />
            </WhiteTooltip>
          </IconButton>
        </Linking>
      </ActionsColumn>
    );
  };

  const renderBidsInProgressActionsCell = (row: any) => {
    const { jobId } = row.original as unknown as IJobRow;
    return (
      <ActionsColumn>
        {tabSelected === 1 && (
          <IconButton
            size="large"
            sx={{
              '&:hover': {
                background: theme.palette.primary.main,
              },
            }}
            onClick={() => {
              setIsModifyBidPopupOpen(true);
              setInProgressBidDetails({
                jobDetails: { ...bidsInProgressJobs[row.index], isVendorBid: true },
                jobId: bidsInProgressJobs[row.index].jobId,
              });
            }}>
            <WhiteTooltip title={t('vendor:continueBid')}>
              <img src={Icons.EditBlackIcon} alt={t('altTexts:modify')} />
            </WhiteTooltip>
          </IconButton>
        )}
        <Linking
          to={{
            pathname: `/onetimejobdetails`,
            search: `?id=${jobId}`,
          }}
          state={{
            facilityItem: {
              ...row.original,
              address: row.original?.jobFacility?.facilityAddress || {},
            },
            facility: { ...row.original.jobFacility, address: row.original?.jobFacility?.facilityAddress || {} },
            job: {
              ...row.original,
              address: row.original?.jobFacility?.facilityAddress || {},
            },
          }}
          key={2}>
          <IconButton
            size="large"
            sx={{
              '&:hover': {
                background: theme.palette.primary.main,
              },
            }}>
            {' '}
            <WhiteTooltip title={t('homePage:viewDetails')}>
              <img src={Icons.EyeIcon} alt={t('altTexts:viewDetails')} />
            </WhiteTooltip>
          </IconButton>
        </Linking>
      </ActionsColumn>
    );
  };

  const handleOnClickInterestedOrNot = async (jobId: string, actionType: JobInterestsActionType) => {
    setIsLoading(true);
    const { customerId } = getStoredCustomerDetails();
    let addedJobInterestResponse;
    if (jobInterests.findIndex((value) => value.jobId === jobId) === -1) {
      const payload = {
        customerId: customerId || '1234',
        vendorId: customerId,
        createJobInterestInput: [
          {
            jobId,
            actionType,
          },
        ],
      };
      addedJobInterestResponse = await BidCreationApiService.addJobInterest(
        payload.customerId,
        payload.vendorId,
        payload.createJobInterestInput
      );
    } else {
      const payload = {
        customerId: customerId || '1234',
        vendorId: customerId,
        createJobInterestInput: {
          jobId,
          actionType,
        },
      };
      addedJobInterestResponse = await BidCreationApiService.updateJobInterest(
        payload.customerId,
        payload.vendorId,
        payload.createJobInterestInput
      );
    }
    if (addedJobInterestResponse.data && addedJobInterestResponse.errors.length === 0) {
      getJobsData(tabSelected);
    } else {
      setIsLoading(false);
      setSnackbarMessage(addedJobInterestResponse.errors[0]?.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  /* eslint-disable sonarjs/cognitive-complexity */
  const fetchVendorUpcomingJob = async () => {
    const customerDetails = getStoredCustomerDetails();
    const vendorId = customerDetails.customerId;
    const vendorCompanyName = customerDetails.organizationName;
    const { userId } = getAuthData();
    setIsLoading(true);
    try {
      // const jobStartDate = getDateString(new Date());  // Will remove jobStartDate value once QA verify the change.
      const userScopeResponse = await MasterDataApiService.getUserScopes(userId);
      let jobCreatorRoleName = '';
      const userScopeData = userScopeResponse?.data ?? [];
      if (!isEmpty(userScopeData)) {
        userScopeData?.forEach((userScope: UserRolesAndScopes) => {
          if (
            !isEmpty(userScope?.roles || []) &&
            userScope?.roles?.some((role: any) => role.roleName === UserRoles.AccountHolder)
          ) {
            jobCreatorRoleName = UserRoles.AccountHolder;
          } else if (
            !isEmpty(userScope?.roles || []) &&
            (userScope?.roles?.some((role: any) => role.roleName === UserRoles.AccountHolder) ||
              userScope?.roles?.some((role: any) => role.roleName === UserRoles.Admin))
          ) {
            jobCreatorRoleName = UserRoles.Admin;
          }
        });
      }

      if (isEmpty(jobCreatorRoleName) && !isEmpty(userScopeData) && !isEmpty(userScopeData[0]?.roles)) {
        // If user role name neither AccountHolder nor Admin
        jobCreatorRoleName = userScopeData[0]?.roles[0]?.roleName || '';
      }

      let upcomingJobResponse = await WorkOrdersApiService.getAllWorkOrders({
        vendorId,
        status: `${WorkOrderStatus.Created},${WorkOrderStatus.Accepted}`,
        jobCreatedBy: userId,
        jobCreatorRoleName,
      });

      if (upcomingJobResponse.metadata.totalCount > itemsPerPage) {
        upcomingJobResponse = await WorkOrdersApiService.getAllWorkOrders({
          vendorId,
          status: `${WorkOrderStatus.Created},${WorkOrderStatus.Accepted}`,
          jobCreatedBy: userId,
          jobCreatorRoleName,
          pageNumber: 1,
          limit: upcomingJobResponse.metadata.totalCount,
        });
      }

      if (!isEmpty(upcomingJobResponse?.data)) {
        const selectedWorkOrder = upcomingJobResponse?.data ?? [];
        if (!isEmpty(selectedWorkOrder)) {
          const upcomingJobsWithFacilityInfo = await Promise.all(
            selectedWorkOrder.map(async (job: any) => {
              const { facilityId } = job?.facilities ? job.facilities[0] : { facilityId: '' };
              const facilityAddressResponse = await FacilityApiService.facility(facilityId, job.customerId as string);
              const customerDetailsResponse = await CustomerApiService.getCustomerDetailsResponse(
                job.customerId as string
              );
              const vendorLogoResponse = await fetchDataFromS3Bucket(
                navigate,
                `vendor/${vendorId}/company-logos/${vendorId}`
              );
              const customerLogoResponse = await fetchDataFromS3Bucket(
                navigate,
                `customer/${job.customerId}/company-logos/${job.customerId}`
              );
              let facilityIcon = '';
              if (
                facilityAddressResponse &&
                facilityAddressResponse?.errors?.length === 0 &&
                facilityAddressResponse?.data &&
                facilityAddressResponse?.data?.address?.logoUrl
              ) {
                facilityIcon = (await getFacilityIcon(facilityAddressResponse?.data?.address?.logoUrl)) as string;
              }
              if (customerDetailsResponse.errors.length) {
                setSnackbarMessage(customerDetailsResponse?.errors[0]?.message);
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
              }
              const { legalName } = customerDetailsResponse?.data?.customer ?? '';

              return {
                vendorIcon: vendorLogoResponse,
                customerIcon: customerLogoResponse,
                jobType: job.jobType,
                customerCompanyName: legalName,
                customerFacilityName: facilityAddressResponse?.data?.buildingName,
                vendorCompanyName,
                vendorFacilityName: vendorCompanyName,
                jobName: job.name,
                customerFacilityAddress:
                  (facilityAddressResponse.data?.address?.addressLine2 ||
                    facilityAddressResponse.data?.address?.addressName) ??
                  '',
                jobStartDate: job?.jobStartDate ?? '',
                jobEndDate: job?.jobEndDate ?? '',
                totalAmount: job?.totalAmount,
                workVolume: calculateWorkVolume(job?.services),
                workOrderId: job?.workOrderId,
                workOrderStatus: job?.status,
                vendorId,
                customerId: job.customerId,
                bidId: job?.bidId ?? '',
                jobId: job?.jobId ?? '',
                facilityId: facilityId ?? '',
                logo: facilityIcon,
              };
            })
          );
          setUpcomingJob(upcomingJobsWithFacilityInfo);
        } else {
          setUpcomingJob([]);
        }
      } else if (isEmpty(upcomingJobResponse?.data) && isEmpty(upcomingJobResponse?.errors)) {
        setUpcomingJob([]);
      } else if (upcomingJobResponse?.errors?.length && upcomingJobResponse?.errors[0]?.message) {
        setSnackbarMessage(upcomingJobResponse?.errors[0]?.message);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.error('Error', e);
    }
  };

  const updateWorkOrderStatus = async (payload: UpdateWorkOrderInput, name: string, customerId = '', jobId = '') => {
    setLoadingCardId(payload.workOrderId);
    setIsPrimaryLoading(true);
    try {
      const workOrderResponse = await WorkOrdersApiService.updateWorkOrderStatus(payload);
      if (workOrderResponse?.data && workOrderResponse?.errors?.length === 0) {
        navigateJobDetailsPage(customerId, jobId);
      } else {
        setSnackbarMessage(workOrderResponse?.errors[0]?.message);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (e) {
      console.error('Error', e);
    }
    setIsPrimaryLoading(false);
  };

  const navigateJobDetailsPage = async (customerId: string, jobId: string) => {
    const jobSummaryResponse = await JobCreateApiService.jobSummaryV2(customerId, jobId);
    if (jobSummaryResponse.data && jobSummaryResponse.errors.length === 0) {
      const jobData = jobSummaryResponse.data;
      const facilityResponse = await FacilityApiService.facility(
        jobData.jobFacilities[0].facilityId,
        jobData.customerId
      );
      navigate(`/onetimejobdetails?id=${jobId}`, {
        state: {
          facilityItem: {
            ...jobSummaryResponse.data,
            address: facilityResponse.data.address || {},
            jobStatus: jobSummaryResponse.data.dataStatus,
          },
          facility: { ...jobData.jobFacilities[0], address: facilityResponse.data.address || {} },
          job: {
            ...jobSummaryResponse.data,
            address: facilityResponse.data.address || {},
            jobStatus: jobSummaryResponse.data.dataStatus,
          },
        },
      });
    } else {
      setSnackbarMessage(jobSummaryResponse?.errors[0]?.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleViewDetails = async (jobId: string, customerId: string, workOrderId: string) => {
    setLoadingCardId(workOrderId);
    setViewDetailsLoading(true);
    await navigateJobDetailsPage(customerId, jobId);
    setViewDetailsLoading(false);
  };

  const handleChangeTabs = useCallback(async (event: React.SyntheticEvent, newValue: number) => {
    setIsLoading(true);
    setTabSelected(newValue);
    window.history.pushState(null, 'Peazy', `/marketplace/${marketplaceTabs[newValue].route}`);
    setTotalCount(0);
    setCurrentPage(1);
    if (newValue === 1) {
      await getBidsInProgressData();
    } else if (newValue === 4) {
      await fetchVendorUpcomingJob();
    } else if (newValue === 5) {
      await getSubmittedBidsData();
    } else {
      await getJobsData(newValue);
    }
  }, []);

  const getServiceVolume = (area: number) => {
    if (area < 5000) {
      return 'Small';
    } else if (area >= 5000 && area < 10000) {
      return 'Medium';
    } else if (area >= 10000) {
      return 'Large';
    }
  };

  const getSubmittedBidsData = async (pageNumber?: number) => {
    const { customerId = '' } = getStoredCustomerDetails();
    setIsLoading(true);
    try {
      const response = await MarketplaceApiService.submittedBids(customerId, pageNumber, itemsPerPage);
      if (response.data && isEmpty(response.errors)) {
        setTotalCount(response.data?.matchedBidsMetaData?.totalCount);
        const modifiedData =
          !isEmpty(response?.data?.matchedBids) && !isEmpty(response?.data?.matchedJobs)
            ? await Promise.all(
                response?.data?.matchedBids?.map(async (bidItem: JobBid) => {
                  const selectedJob = !isEmpty(response?.data?.matchedJobs)
                    ? response?.data?.matchedJobs?.find(
                        (jobItem: JobRecommendation) => bidItem?.jobId === jobItem?.jobId
                      )
                    : {};
                  if (!selectedJob) return null;
                  const jobDueDate = selectedJob?.jobDueDates?.find((item: any) => item.mdDueType === 'Bid');
                  const facilityAddress = !isEmpty(response?.data?.matchedFacilities)
                    ? response?.data?.matchedFacilities?.find(
                        (facilityItem: Facility) => facilityItem.facilityId === selectedJob?.jobFacility?.facilityId
                      )
                    : {};

                  const jobSummaryResponse = await JobCreateApiService.jobSummaryV2('123', selectedJob?.jobId, false);

                  const serviceCategories = await getServiceCategories(jobSummaryResponse?.data?.customerId);

                  const jobServices: JobServices[] = (selectedJob?.jobServices || []).map(
                    (bidEstimationItem: any, index: number) => {
                      const selectedService: any = serviceCategories.find(
                        (serviceCategoryItem: ServiceCategoryV3) =>
                          serviceCategoryItem?.serviceCategoryId === bidEstimationItem?.mdServiceId
                      );
                      return {
                        service: selectedService?.serviceCategoryName ?? '',
                        id: index + 1,
                        mdServiceId: bidEstimationItem?.mdServiceId,
                        unit: bidEstimationItem?.actualMdServiceUnits,
                        totalJobEstimation: bidEstimationItem?.totalCost,
                        totalServices: 1,
                        serviceMeasure: bidEstimationItem?.actualServiceMeasure,
                      };
                    }
                  );

                  const jobBidSummaryResponse = await JobCreateApiService.jobBidResponse(selectedJob.jobId, customerId);

                  let jobBids = [];
                  if (
                    jobBidSummaryResponse?.data &&
                    isEmpty(jobBidSummaryResponse?.errors) &&
                    jobBidSummaryResponse?.data?.[0]?.bidId
                  ) {
                    jobBids = jobBidSummaryResponse?.data;
                  }

                  const jobNewServices = jobSummaryResponse?.data?.jobServices ?? [];
                  const area = getTotalServiceArea(jobNewServices);

                  return {
                    ...selectedJob,
                    ...jobSummaryResponse.data,
                    id: bidItem.bidId,
                    bidId: bidItem.bidId,
                    bidVersion: bidItem?.bidVersion,
                    bidDueDate: jobDueDate?.dueDate,
                    createdOn: bidItem?.bidCreationDate,
                    address: facilityAddress?.address ?? '',
                    location: (facilityAddress?.address?.addressLine2 || facilityAddress?.address?.addressLine1) ?? '',
                    area: area ?? '',
                    facilityName: facilityAddress?.buildingName ?? '',
                    finalQuote: bidItem?.finalQuote,
                    type: JobType.SubmittedBids,
                    Rejected: bidItem?.dataStatus === ('R' as BidStatus) ? 'Rejected' : null,
                    bidStatus:
                      bidItem?.dataStatus === BidStatus.S
                        ? SubmittedBidStatus.PendingForCustomerApproval
                        : bidItem?.dataStatus === BidStatus.V
                          ? SubmittedBidStatus.RevisionRequested
                          : bidItem.dataStatus === BidStatus.R
                            ? SubmittedBidStatus.Rejected
                            : null,
                    jobServices: jobServices,
                    jobBids: jobBids,
                    customerId: jobSummaryResponse.data?.customerId ?? '',
                  };
                })
              )
            : [];
        const filterSubmittedBids = modifiedData.filter(
          (_data) => _data?.dataStatus !== JobStatus.Awarded && !isEmpty(_data)
        );
        const removeDuplicates = (array: JobBid[]) => {
          const seenJobIds: { [key: string]: boolean } = {};
          return array.filter((item) => {
            const jobId = item?.jobId;
            if (jobId && seenJobIds[jobId]) {
              return false;
            } else if (jobId) {
              seenJobIds[jobId] = true;
              return true;
            }
            return false;
          });
        };
        const filteredResponse = removeDuplicates(filterSubmittedBids);

        setSubmittedBids(filteredResponse);
      }
    } catch (error: any) {
      console.log('fetch submitted bids error: ', error);
    }
    setIsLoading(false);
  };

  const getServiceCategories = async (customerId: string) => {
    try {
      const masterData = await MasterDataApiService.getAllServiceCategoryV3(customerId);
      return masterData.data ?? [];
    } catch (e) {
      console.log(e);
      return [];
    }
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const getJobsData = async (tabs: any, pageNumber?: number, sortBy?: any, sortOrder?: any) => {
    try {
      setIsLoading(true);
      let sortOrderString = sortOrder;
      if (sortOrder) {
        sortOrderString = 'desc';
      } else {
        if (sortBy) sortOrderString = 'asc';
      }
      const { customerId } = getStoredCustomerDetails();
      if (tabs === 0) {
        setIsLoading(true);
        const jobsData: IJobRecommendationsType = await MarketplaceApiService.getRecommendedJobs(
          customerId,
          new Date(moment().utc().format('YYYY-MM-DDT00:00:00.000Z')).toISOString(),
          itemsPerPage,
          pageNumber ? pageNumber : currentPage,
          sortBy,
          sortOrderString
        );

        const allJobsInterestedResponse = await BidCreationApiService.getJobInterests('1234', customerId);
        if (jobsData?.data) {
          setTotalCount(jobsData?.metadata?.totalCount);
          const modifiedData = !isEmpty(jobsData?.data?.matchedJobs)
            ? await Promise.all(
                jobsData?.data?.matchedJobs?.map(async (jobItem: JobRecommendation) => {
                  const jobDueDate = jobItem?.jobDueDates?.find((item: JobDueDate | null) => item?.mdDueType === 'Bid');

                  const facilityAddress = !isEmpty(jobsData?.data?.matchedFacilities)
                    ? jobsData?.data?.matchedFacilities?.find(
                        (facilityItem: Facility) => facilityItem.facilityId === jobItem?.jobFacility?.facilityId
                      )
                    : {};
                  const jobSummaryResponse = await JobCreateApiService.jobSummaryV2('123', jobItem.jobId, false);

                  const serviceCategories = await getServiceCategories(jobSummaryResponse?.data?.customerId);

                  const jobServices: JobServices[] = (jobItem.jobServices || []).map(
                    (bidEstimationItem: any, index: number) => {
                      const selectedService: any = serviceCategories.find(
                        (serviceCategoryItem: ServiceCategoryV3) =>
                          serviceCategoryItem?.serviceCategoryId === bidEstimationItem?.mdServiceId
                      );
                      return {
                        service: selectedService?.serviceCategoryName ?? '',
                        id: index + 1,
                        mdServiceId: bidEstimationItem?.mdServiceId,
                        unit: bidEstimationItem?.actualMdServiceUnits,
                        totalJobEstimation: bidEstimationItem?.totalCost,
                        totalServices: 1,
                        serviceMeasure: bidEstimationItem?.actualServiceMeasure,
                      };
                    }
                  );

                  const jobNewServices = jobSummaryResponse?.data?.jobServices ?? [];
                  const area = getTotalServiceArea(jobNewServices);

                  const jobBids: any = [];
                  return {
                    ...jobItem,
                    id: jobItem.jobId,
                    bidDueDate: jobDueDate?.dueDate,
                    createdOn: jobItem?.createdOn,
                    address: facilityAddress?.address ?? '',
                    location: (facilityAddress?.address?.addressLine2 || facilityAddress?.address?.addressLine1) ?? '',
                    area: area ?? '',
                    duration: getDurationInDaysOrMonths(jobItem?.startDate || '', jobItem?.stopDate || ''),
                    noOfDaysLeft: getDurationInDaysOrMonths(jobDueDate?.dueDate || '', jobItem?.startDate || ''),
                    volume: getServiceVolume(facilityAddress?.sqFootage),
                    facilityType: await fetchFacilityTypes(facilityAddress?.mdFacilityType),
                    facilityName: facilityAddress?.buildingName ?? '',
                    type: JobType.RecommendedJob,
                    jobServices: jobServices,
                    jobBids: jobBids,
                    customerId: jobSummaryResponse.data?.customerId ?? '',
                  };
                })
              )
            : [];

          if (allJobsInterestedResponse.data && allJobsInterestedResponse.errors.length === 0) {
            allJobsInterestedResponse.data.forEach((interest: JobInterest) => {
              const foundIndex = modifiedData.findIndex(
                (_data) =>
                  _data.jobId === interest.jobId &&
                  (interest.actionType as JobInterestsActionType) === JobInterestsActionType.NotInterested
              );

              if (foundIndex !== -1) {
                modifiedData.splice(foundIndex, 1);
              }
            });
          }
          setJobs(modifiedData ?? []);
          if (allJobsInterestedResponse.data) {
            setJobInterests(allJobsInterestedResponse.data);
          }
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } else if (tabs === 2) {
        setIsLoading(true);
        const jobsData = await MarketplaceApiService.getAllJobs(
          customerId,
          sortBy,
          sortOrderString,
          pageNumber ? pageNumber : currentPage,
          itemsPerPage
          // new Date(moment().utc().format('YYYY-MM-DDT00:00:00.000Z')).toISOString()
        );
        const allJobsInterestedResponse = await BidCreationApiService.getJobInterests('1234', customerId);
        if (jobsData?.data && jobsData.errors.length === 0) {
          setTotalCount(jobsData.metadata?.totalCount);
          const modifiedData = await Promise.all(
            jobsData?.data?.matchedJobs?.map(async (jobItem: JobRecommendation) => {
              const jobDueDate = jobItem?.jobDueDates?.find((item: JobDueDate | null) => item?.mdDueType === 'Bid');
              const facilityAddress = jobsData?.data.matchedFacilities?.find(
                (facilityItem: Facility) => facilityItem.facilityId === jobItem?.jobFacility?.facilityId
              );

              const jobSummaryResponse = await JobCreateApiService.jobSummaryV2('123', jobItem.jobId, false);

              const serviceCategories = await getServiceCategories(jobSummaryResponse?.data?.customerId);

              const jobServices: JobServices[] = (jobItem.jobServices || []).map(
                (bidEstimationItem: any, index: number) => {
                  const selectedService: any = serviceCategories.find(
                    (serviceCategoryItem: ServiceCategoryV3) =>
                      serviceCategoryItem?.serviceCategoryId === bidEstimationItem?.mdServiceId
                  );
                  return {
                    service: selectedService?.serviceCategoryName ?? '',
                    id: index + 1,
                    mdServiceId: bidEstimationItem?.mdServiceId,
                    unit: bidEstimationItem?.actualMdServiceUnits,
                    totalJobEstimation: bidEstimationItem?.totalCost,
                    totalServices: 1,
                    serviceMeasure: bidEstimationItem?.actualServiceMeasure,
                  };
                }
              );

              const jobNewServices = jobSummaryResponse?.data?.jobServices ?? [];
              const area = getTotalServiceArea(jobNewServices);

              const jobBids: any = [];
              return {
                ...jobItem,
                id: jobItem.jobId,
                bidDueDate: jobDueDate?.dueDate,
                createdOn: jobItem?.createdOn,
                address: facilityAddress?.address ?? '',
                location: (facilityAddress?.address?.addressLine2 || facilityAddress?.address?.addressLine1) ?? '',
                facilityName: facilityAddress?.buildingName ?? '',
                facilityType: await fetchFacilityTypes(facilityAddress?.mdFacilityType),
                area: area ?? '',
                startDate: jobItem?.startDate,
                stopDate: jobItem?.stopDate,
                duration:
                  jobItem.jobType === IJobType.Recurring
                    ? getDurationInDaysOrMonths(jobItem?.startDate || '', jobItem?.stopDate || '')
                    : t('vendorBidCreation:oneTimeJob'),
                noOfDaysLeft:
                  getDurationInDaysOrMonths(new Date().toJSON(), jobDueDate?.dueDate || '') === 'Negative'
                    ? t('vendorBidCreation:bidsClosed')
                    : getDurationInDaysOrMonths(new Date().toJSON(), jobDueDate?.dueDate || ''),
                volume: getServiceVolume(facilityAddress?.sqFootage),
                type: JobType.All,
                sortBy: sortBy,
                sortOrder: sortOrderString,
                customerId: jobSummaryResponse.data?.customerId ?? '',
                jobCustodian: jobSummaryResponse.data?.jobCustodian,
                jobServices: jobServices,
                jobBids: jobBids,
              };
            })
          );
          if (allJobsInterestedResponse.data && allJobsInterestedResponse.errors.length === 0) {
            allJobsInterestedResponse.data.forEach((interest: any) => {
              const foundIndex = modifiedData.findIndex(
                (_data) =>
                  _data.jobId === interest.jobId && interest.actionType === JobInterestsActionType.NotInterested
              );
              if (foundIndex !== -1) {
                modifiedData.splice(foundIndex, 1);
              }
            });
            setJobInterests(allJobsInterestedResponse?.data || []);
          } else {
            console.log('allJobsInterestedResponse.errors', allJobsInterestedResponse.errors[0].message);
          }
          setJobs(modifiedData);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } else if (tabs === 3) {
        setIsLoading(true);
        let modifiedData: ModifiedJobData[] = [];
        const response = await MarketplaceApiService.getNotInterestedJobs('1234', customerId, sortBy, sortOrderString);
        let jobsData = await MarketplaceApiService.getAllJobs(
          customerId,
          sortBy,
          sortOrderString,
          pageNumber ? pageNumber : currentPage,
          itemsPerPage
        );
        if (jobsData.data && jobsData.metadata && jobsData.metadata.totalCount > itemsPerPage) {
          jobsData = await MarketplaceApiService.getAllJobs(
            customerId,
            sortBy,
            sortOrderString,
            pageNumber ? pageNumber : currentPage,
            jobsData.metadata.totalCount
          );
        }
        if (!isEmpty(response.data) && jobsData?.data && jobsData.errors.length === 0) {
          modifiedData = await Promise.all(
            jobsData?.data?.matchedJobs?.map(async (jobItem: JobRecommendation) => {
              const jobDueDate = jobItem?.jobDueDates?.find((item: JobDueDate | null) => item?.mdDueType === 'Bid');
              const facilityAddress = jobsData?.data
                ? jobsData?.data.matchedFacilities?.find(
                    (facilityItem: Facility) => facilityItem.facilityId === jobItem?.jobFacility?.facilityId
                  )
                : {};
              const jobSummaryResponse = await JobCreateApiService.jobSummaryV2('123', jobItem.jobId, false);

              const jobNewServices = jobSummaryResponse?.data?.jobServices ?? [];
              const area = getTotalServiceArea(jobNewServices);
              return {
                ...jobItem,
                id: jobItem.jobId,
                bidDueDate: jobDueDate?.dueDate,
                createdOn: jobItem?.createdOn,
                address: (facilityAddress?.address?.addressLine2 || facilityAddress?.address?.addressLine1) ?? '',
                location: (facilityAddress?.address?.addressLine2 || facilityAddress?.address?.addressLine1) ?? '',
                facilityName: facilityAddress?.buildingName ?? '',
                facilityType: await fetchFacilityTypes(facilityAddress?.mdFacilityType),
                area: area ?? '',
                startDate: jobItem?.startDate,
                stopDate: jobItem?.stopDate,
                duration: getDurationInDaysOrMonths(jobItem?.startDate || '', jobItem?.stopDate || ''),
                noOfDaysLeft: getDurationInDaysOrMonths(jobDueDate?.dueDate || '', jobItem?.startDate || ''),
                volume: getServiceVolume(facilityAddress?.sqFootage),
                type: JobType.All,
              };
            })
          );
          const filteredData = modifiedData.filter((_data) =>
            response.data.some(
              (interest: JobItem) =>
                _data.jobId === interest.jobId && interest.actionType === JobInterestsActionType.NotInterested
            )
          );
          setJobInterests(response.data || []);
          setJobs(filteredData);
          setIsLoading(false);
        } else {
          setJobs([]);
          setIsLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error:', error);
    }
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const getBidsInProgressData = async (pageNumber?: number, sortBy?: any, sortOrder?: any) => {
    setIsLoading(true);
    const { customerId } = getStoredCustomerDetails();

    let sortOrderString = sortOrder;
    if (sortOrder) {
      sortOrderString = 'desc';
    } else {
      if (sortBy) sortOrderString = 'asc';
    }

    const bidsInProgressResponse = await MarketplaceApiService.getBidsInProgress(
      customerId,
      pageNumber,
      itemsPerPage,
      sortBy,
      sortOrderString
    );

    if (bidsInProgressResponse?.data && !isEmpty(bidsInProgressResponse?.data?.matchedJobs)) {
      const modifiedData: ModifiedJobData[] = await Promise.all(
        bidsInProgressResponse?.data?.matchedJobs?.data?.map(async (selectedJob: JobRecommendation) => {
          const selectedBid = bidsInProgressResponse?.data?.matchedBids?.find((bidItem: JobBid) => {
            return selectedJob.jobId === bidItem.jobId;
          });
          if (selectedJob && selectedBid && Object.keys(selectedBid).length) {
            const jobDueDate = selectedJob?.jobDueDates?.find((item: any) => item.mdDueType === 'Bid');

            if (jobDueDate?.dueDate) {
              const facilityAddress = bidsInProgressResponse?.data.matchedFacilities?.find(
                (facilityItem: Facility) => facilityItem.facilityId === selectedJob?.jobFacility?.facilityId
              );
              const jobSummaryResponse = await JobCreateApiService.jobSummaryV2('123', selectedJob?.jobId, false);

              const serviceCategories = await getServiceCategories(jobSummaryResponse?.data?.customerId);

              const jobServices: JobServices[] = (selectedJob?.jobServices || []).map(
                (bidEstimationItem: any, index: number) => {
                  const selectedService: any = serviceCategories.find(
                    (serviceCategoryItem: ServiceCategoryV3) =>
                      serviceCategoryItem?.serviceCategoryId === bidEstimationItem?.mdServiceId
                  );
                  return {
                    service: selectedService?.serviceCategoryName ?? '',
                    id: index + 1,
                    mdServiceId: bidEstimationItem?.mdServiceId,
                    unit: bidEstimationItem?.actualMdServiceUnits,
                    totalJobEstimation: bidEstimationItem?.totalCost,
                    totalServices: 1,
                    serviceMeasure: bidEstimationItem?.actualServiceMeasure,
                  };
                }
              );

              const jobBidSummaryResponse = await JobCreateApiService.jobBidResponse(selectedJob.jobId, customerId);

              let jobBids = [];
              if (
                jobBidSummaryResponse?.data &&
                isEmpty(jobBidSummaryResponse?.errors) &&
                jobBidSummaryResponse?.data?.[0]?.bidId
              ) {
                jobBids = jobBidSummaryResponse?.data;
              }

              const jobNewServices = jobSummaryResponse?.data?.jobServices ?? [];
              const area = getTotalServiceArea(jobNewServices);

              return {
                ...selectedJob,
                ...jobSummaryResponse.data,
                id: selectedBid?.bidId ?? '',
                bidDueDate: jobDueDate?.dueDate,
                createdOn: selectedBid?.bidCreationDate,
                area: area ?? '',
                address: facilityAddress?.address ?? '',
                location: (facilityAddress?.address?.addressLine2 || facilityAddress?.address?.addressLine1) ?? '',
                facilityName: facilityAddress?.buildingName ?? '',
                type: JobType.BidsInProgress,
                bidEstimate: selectedBid?.bidEstimationType,
                bidStatus: selectedBid?.draftStatus,
                bidId: selectedBid?.bidId,
                bidVersion: selectedBid?.bidVersion,
                vendorId: selectedBid?.vendorId,
                jobServices: jobServices,
                jobBids: jobBids,
                customerId: jobSummaryResponse.data?.customerId ?? '',
              };
            }
          }
          return null;
        })
      );
      const filteredModifiedData = modifiedData.filter(Boolean);
      if (!isEmpty(filteredModifiedData)) {
        setBidsInProgressJobs(filteredModifiedData);
        setTotalCount(bidsInProgressResponse?.data?.matchedJobs?.metadata?.totalCount);
        setIsLoading(false);
      } else {
        setBidsInProgressJobs([]);
        setIsLoading(false);
      }
    }
    setIsLoading(false);
  };

  const columns = useMemo<MRT_ColumnDef<IJobOverview | TaskData | IJobs | IBidList | IProfileCardProps>[]>(
    () => [
      {
        accessorKey: 'jobName',
        header: t('tableHeader:jobName'),
        enableSorting: true,
        state: { sortBy },
        onSortingChange: setSortBy,
        enableColumnFilter: false,
        size: 180,
        Cell: ({ renderedCellValue }) => (
          <div style={{ color: theme.palette.primary.dark }}>{renderCell(renderedCellValue)}</div>
        ),
        enableColumnActions: false,
        sortingFn: 'alphanumericCaseSensitive',
        enableHiding: false,
      },
      {
        accessorKey: 'location',
        header: t('tableHeader:location'),
        enableSorting: false,
        enableColumnFilter: false,
        size: 180,
        Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
        enableColumnActions: false,
        sortingFn: 'alphanumericCaseSensitive',
        enableHiding: false,
      },
      {
        accessorKey: 'facilityType',
        header: t('tableHeader:facilityType'),
        enableSorting: false,
        enableColumnFilter: false,
        size: 170,
        Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
        enableColumnActions: false,
        sortingFn: 'alphanumericCaseSensitive',
        enableHiding: false,
      },
      {
        accessorKey: 'createdOn',
        header: t('tableHeader:createdOn'),
        enableSorting: true,
        state: { sortBy },
        onSortingChange: setSortBy,
        enableColumnFilter: false,
        size: 100,
        filterFn: (row, _columnIds, filterValue) => {
          const formattedValue = moment(row.getValue('createdOn')).format('MM / DD / yyyy');
          return formattedValue.toLowerCase().includes(filterValue.toLowerCase());
        },
        Cell: ({ cell }) => formatDateCell(cell),
        enableColumnActions: false,
        enableHiding: false,
      },
      {
        accessorKey: 'area',
        header: t('tableHeader:totalArea'),
        enableSorting: false,
        enableColumnFilter: false,
        size: 150,
        Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
        enableColumnActions: false,
        enableHiding: false,
      },
      {
        accessorKey: 'duration',
        header: t('tableHeader:duration'),
        enableSorting: false,
        enableColumnFilter: false,
        enableColumnActions: false,
        size: 142,
        Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
        enableHiding: false,
      },
      {
        accessorKey: 'volume',
        header: t('tableHeader:volume'),
        enableSorting: false,
        enableColumnFilter: false,
        size: 150,
        Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
        enableColumnActions: false,
        enableHiding: false,
      },
      {
        accessorKey: 'percentageMatch',
        header: t('tableHeader:matchPercentage'),
        enableSorting: false,
        enableColumnFilter: false,
        size: 100,
        Cell: ({ renderedCellValue }) => renderPercentageMatchCell(renderedCellValue),
        enableColumnActions: false,
        enableHiding: false,
      },
      {
        accessorKey: 'noOfDaysLeft',
        header: t('tableHeader:daysLeft'),
        enableSorting: false,
        enableColumnFilter: false,
        size: 150,
        Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
        enableColumnActions: false,
        enableHiding: false,
      },
      {
        header: t('tableHeader:actions'),
        enableColumnActions: false,
        enablePinning: false,
        size: 90,
        Cell: ({ row }) => renderActionsCell(row),
      },
    ],
    [handleClick, handleClose]
  );

  const bidsInProgressColumns = useMemo(() => {
    const jobColumns = deepClone(columns);
    jobColumns.splice(5, 5, {
      header: 'Actions',
      enableColumnActions: false,
      enablePinning: false,
      size: 70,
      Cell: ({ row }: any) => renderBidsInProgressActionsCell(row),
    });
    jobColumns.splice(2, 1);
    return jobColumns;
  }, [columns]);

  const submittedBidsColumns = useMemo<MRT_ColumnDef<IJobOverview | TaskData | IJobs | IBidList | IProfileCardProps>[]>(
    () => [
      {
        accessorKey: 'jobName',
        header: t('tableHeader:jobName'),
        enableSorting: false,
        state: { sortBy },
        onSortingChange: setSortBy,
        enableColumnFilter: false,
        size: 180,
        Cell: ({ renderedCellValue }) => (
          <div style={{ color: theme.palette.primary.dark }}>{renderCell(renderedCellValue)}</div>
        ),
        enableColumnActions: false,
        sortingFn: 'alphanumericCaseSensitive',
        enableHiding: false,
      },
      {
        accessorKey: 'location',
        header: t('tableHeader:location'),
        enableSorting: false,
        enableColumnFilter: false,
        size: 180,
        Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
        enableColumnActions: false,
        sortingFn: 'alphanumericCaseSensitive',
        enableHiding: false,
      },
      {
        accessorKey: 'createdOn',
        header: t('tableHeader:createdOn'),
        enableSorting: false,
        enableColumnFilter: false,
        size: 100,
        filterFn: (row, _columnIds, filterValue) => {
          const formattedValue = moment(row.getValue('createdOn')).format('MM / DD / yyyy');
          return formattedValue.toLowerCase().includes(filterValue.toLowerCase());
        },
        Cell: ({ cell }) => formatDateCell(cell),
        enableColumnActions: false,
        enableHiding: false,
      },
      {
        accessorKey: 'area',
        header: t('tableHeader:totalArea'),
        enableSorting: false,
        enableColumnFilter: false,
        size: 150,
        Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
        enableColumnActions: false,
        enableHiding: false,
      },
      {
        accessorKey: 'finalQuote',
        header: t('tableHeader:finalQuote'),
        enableSorting: false,
        enableColumnFilter: false,
        size: 178,
        Cell: ({ renderedCellValue }) => renderFinalQuoteCell(renderedCellValue),
        enableColumnActions: true,
      },
      {
        accessorKey: 'bidStatus',
        header: t('tableHeader:Status'),
        enableSorting: false,
        enableColumnFilter: false,
        size: 170,
        Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
        enableColumnActions: false,
        enableHiding: false,
      },
      {
        header: t('tableHeader:actions'),
        enableColumnActions: false,
        enablePinning: false,
        size: 90,
        Cell: ({ row }) => renderSubmittedBidsActionsCell(row),
      },
    ],
    [submittedBids]
  );

  const myCustomSortingFn = () => {
    setSortBy('DESC');
  };

  const handleSort = async (sortingObj: any) => {
    setSorting(sortingObj);
    try {
      if (sortingObj.length) {
        setNewSortOrder(sortingObj[0].id);
        setNewSort(sortingObj[0].desc);
        if (tabSelected === 1) {
          await getBidsInProgressData(currentPage, sortingObj[0].id, sortingObj[0].desc);
        } else {
          await getJobsData(tabSelected, currentPage, sortingObj[0].id, sortingObj[0].desc);
        }
      } else {
        await getJobsData(currentPage);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const handleModifyBidPopup = () => {
    setIsModifyBidPopupOpen(!isModifyBidPopupOpen);
  };

  const handleRequestRevisionBidPopup = () => {
    setIsRequestRevisionBidPopupOpen(!isRequestRevisionBidPopupOpen);
  };

  const navigateToCreateBid = (bidDetails: any) => {
    setTimeout(() => {
      navigate('/create-bid', { state: { ...bidDetails } });
    }, 500);
  };

  const resubmitBid = async (bidDetails: any) => {
    try {
      setIsResubmitLoading(true);
      const bidId = bidDetails?.jobDetails?.bidId;
      const bidSummaryResponse = await VendorApiService.getBidSummary(bidId);

      const bidVersion = (bidSummaryResponse?.data?.bidVersion ?? 0) + 1;
      const bidDraftStatus = bidSummaryResponse?.data?.draftStatus;
      const updateBidInput: UpdateJobBidInput = {
        dataStatus: BidStatus.V,
        draftStatus: DraftStatus.Y, // Bid draft status should be Y to handle bid version update.
        bidVersion,
      };
      const response =
        bidDraftStatus === DraftStatus.C
          ? await CreateBidServices.updateBid('1234', bidId, updateBidInput)
          : { data: [], errors: [] }; // Don't update bidVersion if bid is already in in-progress.;
      if ((!isEmpty(response?.data?.bidId) && isEmpty(response?.errors ?? [])) || bidDraftStatus === DraftStatus.Y) {
        navigateToCreateBid(bidDetails);
      } else {
        setSnackbarMessage(`${t('dashboard:bidUpdateError')}`);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
      setIsResubmitLoading(false);
    } catch (error) {
      console.error('Error while updating the bid:', error);
    }
  };

  useEffect(() => {
    if (tabSelected === 1) {
      getBidsInProgressData();
    } else if (tabSelected === 4) {
      fetchVendorUpcomingJob();
    } else if (tabSelected === 5) {
      getSubmittedBidsData(1);
    } else {
      getJobsData(tabSelected);
    }
  }, []);

  useEffect(() => {
    setTabSelected(tabIndex);
  }, [location.pathname]);

  return (
    <>
      <PageTitle title={t('vendor:marketplace')} />
      <CustomerContainer>
        <TabSection>
          <TabsWrapper>
            <Tabs value={tabSelected} onChange={handleChangeTabs} aria-label="basic tabs">
              {marketplaceTabs.map((tab, index) => (
                <TabTitle key={tab.label + index} label={tab.label} {...a11yProps(index)} disabled={isLoading} />
              ))}
            </Tabs>
          </TabsWrapper>
          {marketplaceTabs.map((tab, index) => (
            <TabPanel value={tabSelected} index={index} key={tab.label}>
              {tab.label === t('vendor:bidsIProgress') || tab.label === 'Sub-contractor bids' ? (
                <>
                  <DataGrid
                    columns={bidsInProgressColumns}
                    data={bidsInProgressJobs}
                    enableRowSelect={false}
                    enableColumnPinning={false}
                    sortingObj={sorting}
                    sort={handleSort}
                    loader={isLoading}
                    sortingFns={{
                      myCustomSortingFn: myCustomSortingFn,
                    }}
                    errorMessageTitle={t('noData:noDataYet')}
                    errorMessageDescription={t('noData:comeBackLater')}
                  />
                  {!isLoading && totalCount > 10 && (
                    <Stack spacing={2} justifyContent="center" style={{ marginTop: '20px' }}>
                      <StyledPagination
                        count={Math.ceil(totalCount / itemsPerPage)}
                        page={currentPage}
                        onChange={(event, value) => {
                          handleChange(event, value);
                          if (newSortOrder) {
                            getBidsInProgressData(value, newSortOrder, newSort);
                          } else {
                            getBidsInProgressData(value, '', '');
                          }
                        }}
                        color="primary"
                      />
                    </Stack>
                  )}
                </>
              ) : tab.label === t('vendor:awardedJobs') || tab.label === 'Awarded Jobs' ? (
                <>
                  <AwardCardContainer>
                    {isLoading ? (
                      <LoaderTag>
                        <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
                      </LoaderTag>
                    ) : (
                      <>
                        {upcomingJob.length > 0 ? (
                          <>
                            {upcomingJob.map((job: IVendorJobAwardData) => {
                              return (
                                <VendorJobAward
                                  data={job}
                                  isPrimaryButtonLoading={isPrimaryLoading}
                                  viewDetailsLoading={viewDetailsLoading}
                                  loadingCardId={loadingCardId}
                                  handleViewDetails={handleViewDetails}
                                  handleStart={updateWorkOrderStatus}
                                />
                              );
                            })}
                          </>
                        ) : (
                          <NoDataContainer>
                            <img src={Icons.ErrorCautionImg} style={{ ...noDataImageStyles }} />
                            <NoDataTag>{t('vendor:noAwardedJobsMsg')}</NoDataTag>
                          </NoDataContainer>
                        )}
                      </>
                    )}
                  </AwardCardContainer>
                </>
              ) : tab.label === t('vendor:submittedBids') || tab.label === 'Submitted Bids' ? (
                <>
                  <DataGrid
                    columns={submittedBidsColumns}
                    data={submittedBids}
                    enableRowSelect={false}
                    enableColumnPinning={false}
                    sortingObj={sorting}
                    loader={isLoading}
                    sort={handleSort}
                    sortingFns={{
                      myCustomSortingFn: myCustomSortingFn,
                    }}
                    errorMessageTitle={t('noData:noDataYet')}
                    errorMessageDescription={t('noData:comeBackLater')}
                  />
                  {!isLoading && totalCount > 10 ? (
                    <Stack spacing={2} justifyContent="center" style={{ marginTop: '20px' }}>
                      <StyledPagination
                        count={Math.ceil(totalCount / itemsPerPage)}
                        page={currentPage}
                        onChange={(event, value) => {
                          handleChange(event, value);
                          getSubmittedBidsData(value);
                        }}
                        color="primary"
                      />
                    </Stack>
                  ) : null}
                </>
              ) : (
                <>
                  <DataGrid
                    columns={columns}
                    data={jobs}
                    enableRowSelect={false}
                    enableColumnPinning={false}
                    sortingObj={sorting}
                    loader={isLoading}
                    sort={handleSort}
                    sortingFns={{
                      myCustomSortingFn: myCustomSortingFn,
                    }}
                    errorMessageTitle={t('noData:noDataYet')}
                    errorMessageDescription={t('noData:comeBackLater')}
                  />
                  {!isLoading && totalCount > 10 ? (
                    <Stack spacing={2} justifyContent="center" style={{ marginTop: '20px' }}>
                      <StyledPagination
                        count={Math.ceil(totalCount / itemsPerPage)}
                        page={currentPage}
                        onChange={(event, value) => {
                          handleChange(event, value);
                          if (newSortOrder) {
                            getJobsData(tabSelected, value, newSortOrder, newSort);
                          } else {
                            getJobsData(tabSelected, value, '', '');
                          }
                        }}
                        color="primary"
                      />
                    </Stack>
                  ) : null}
                </>
              )}
            </TabPanel>
          ))}
        </TabSection>
      </CustomerContainer>
      <ModifyPopUp
        open={isModifyBidPopupOpen}
        handleClose={handleModifyBidPopup}
        handleOnCloseJob={() => navigateToCreateBid(InProgressBidDetails)}
        heading={t('assignJob:continueBid')}
        text={t('assignJob:confirmContinueBid')}
      />
      <ModifyPopUp
        open={isRequestRevisionBidPopupOpen}
        handleClose={handleRequestRevisionBidPopup}
        handleOnCloseJob={() => resubmitBid(RequestRevisionBidDetails)}
        heading={t('assignJob:bidRevision')}
        text={t('assignJob:confirmBidRevision')}
        loading={isResubmitLoader}
      />
      <SnackbarMessage
        open={snackbarOpen}
        successMessage={snackbarMessage}
        errorMessage={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
      />
    </>
  );
};

export default MarketplacePage;
