/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { createContext, useContext, useMemo, useState } from 'react';
import {
  ApprovalStatus,
  ApprovalType,
  ApproverType,
  CreateTaskApprovalInput,
  TaskStatus,
  UpdateTaskInput,
} from '../API';
import WorkOrdersApiService from '../Modules/Jobs/Services/WorkOrdersService';
import { UserType, ApproverType as ConstApproverType } from '../Shared/Constants/App';

export interface ICognitoUserAttributes {
  'custom:Region': string;
  'custom:Role': UserType;
  email?: string;
  email_verified?: boolean;
  name: string;
  phone_number?: string;
  sub: string;
}

export interface ICognitoUser {
  attributes: ICognitoUserAttributes;
  id: string;
  username: string;
}

export type UserContextType = {
  setUser: React.Dispatch<React.SetStateAction<null>> | null;
  user: ICognitoUser | null;
  selectedTaskId: [];
  handleApprovalAll: () => void;
  handleRejectAll: (vendorMsg: string) => void;
  handleReject: (taskId: string, vendorMsg: string, rejectByRole?: string) => void;
  handleApprove: (taskId: string, approveByRole?: string, vendorTypes?: any) => void;
  setSelectedTaskId: (values: any) => void;
  setSnackBarStatus: (values: any) => void;
  snackBarStatus: {
    isOpen: boolean;
    isApproved: boolean;
  };
  isApproveLoading: boolean;
  isRejectLoading: boolean;
};

const AuthContext = createContext<UserContextType>({
  user: null,
  setUser: () => null,
  handleApprovalAll: () => null,
  handleRejectAll: () => null,
  handleReject: () => null,
  handleApprove: () => null,
  setSelectedTaskId: () => null,
  selectedTaskId: [],
  setSnackBarStatus: () => null,
  snackBarStatus: {
    isOpen: false,
    isApproved: false,
  },
  isApproveLoading: false,
  isRejectLoading: false,
});

export const useAuth = () => useContext(AuthContext);

interface IAuthProviderProps {
  children: JSX.Element;
}

export interface IApproveTaskPayload {
  createTaskApproval: CreateTaskApprovalInput;
  taskId: string;
}

export interface IUpdateTaskPayload {
  taskId: string;
  updateTaskInput: UpdateTaskInput;
}

const AuthProvider = ({ children }: IAuthProviderProps) => {
  const [user, setUser] = useState<any>({});
  const [selectedTaskId, setSelectedTaskId] = useState<any>([]);
  const [snackBarStatus, setSnackBarStatus] = useState({
    isOpen: false,
    isApproved: false,
  });
  const [isApproveLoading, setIsApproveLoading] = useState(false);
  const [isRejectLoading, setIsRejectLoading] = useState(false);

  const userProfile: any = localStorage.getItem('auth');
  const userType = JSON.parse(userProfile)?.attributes?.['custom:Role'] ?? '';

  const getApproveStatus = (approveByRole?: string) => {
    let taskApproveStatus: TaskStatus = TaskStatus.ApprovedByCustomer;
    if (userType === UserType.Vendor) {
      if (approveByRole === ConstApproverType.PrimeVendor) {
        taskApproveStatus = TaskStatus.ApprovedByPrimeVendor;
      } else {
        taskApproveStatus = TaskStatus.ApprovedBySupervisor;
      }
    }

    return taskApproveStatus;
  };

  const getRejectStatus = (rejectByRole?: string) => {
    let taskApproveStatus: TaskStatus = TaskStatus.RejectedByCustomer;
    if (userType === UserType.Vendor) {
      if (rejectByRole === ConstApproverType.PrimeVendor) {
        taskApproveStatus = TaskStatus.RejectedByPrimeVendor;
      } else {
        taskApproveStatus = TaskStatus.RejectedBySupervisor;
      }
    }

    return taskApproveStatus;
  };

  const handleOnSubmitTasks = async (taskPayload: any = [], updateTaskInputPayload: any = [], isApproval = false) => {
    try {
      const addTaskApprovalResponse = !isEmpty(taskPayload)
        ? await Promise.all(
            taskPayload.map((task: IApproveTaskPayload) => {
              return WorkOrdersApiService.addTaskApproval(task.taskId, task.createTaskApproval);
            })
          )
        : [];
      const updateTasksResponse = !isEmpty(updateTaskInputPayload)
        ? await Promise.all(
            updateTaskInputPayload.map((task: IUpdateTaskPayload) => {
              return WorkOrdersApiService.updateTasks(task.taskId, task.updateTaskInput);
            })
          )
        : [];
      const isTaskApprovalSuccess = addTaskApprovalResponse?.every((taskApprove) => !isEmpty(taskApprove?.data ?? []));
      const isTaskUpdateSuccess = updateTasksResponse?.every((updateTask) => !isEmpty(updateTask?.data ?? []));
      if (isTaskApprovalSuccess && isTaskUpdateSuccess) {
        if (isApproval) {
          setSnackBarStatus({
            //approval snack bar
            isOpen: true,
            isApproved: true,
          });
        } else {
          setSnackBarStatus({
            //Reject snack bar
            isOpen: true,
            isApproved: false,
          });
        }
      } else {
        const taskApproveError = addTaskApprovalResponse?.find((taskApproval) => !isEmpty(taskApproval?.errors ?? []));
        const taskUpdateError = updateTasksResponse?.find((updateTask) => !isEmpty(updateTask?.errors ?? []));
        console.error(
          'addTaskApproval or updateTasks query : ',
          taskApproveError?.errors[0]?.message || taskUpdateError?.errors[0]?.message
        );
      }
    } catch (error) {
      console.log('error', error);
    }
    setIsApproveLoading(false);
    setIsRejectLoading(false);
  };

  const handleApprove = (taskId: string, approveByRole?: string, vendorTypes?: any) => {
    setIsApproveLoading(true);
    const startDate = moment().toJSON();

    const mdVendorType = vendorTypes[0]?.mdVendorType;
    const status = mdVendorType === 'SelfPerform' ? TaskStatus.ApprovedByPrimeVendor : getApproveStatus(approveByRole);

    const taskApprovePayload = [
      {
        taskId: taskId,
        createTaskApproval: {
          status: status,
          startDate,
          mdApproverType: userType === UserType.Customer ? ApproverType.Customer : ApproverType.Vendor,
          dueDate: startDate,
          completionDate: startDate,
          approvalStatus: ApprovalStatus.Approved,
          approvalName: ApprovalType.TaskApproval,
        },
      },
    ];

    const updateApproveTaskInputPayload = [
      {
        taskId: taskId,
        updateTaskInput: {
          taskStatus:
            mdVendorType === 'SelfPerform' ? TaskStatus.ApprovedByPrimeVendor : getApproveStatus(approveByRole),
        },
      },
    ];

    handleOnSubmitTasks(taskApprovePayload, updateApproveTaskInputPayload, true);
  };

  const handleReject = (taskId: string, vendorMsg: string, rejectedByrole: string = ApproverType.Vendor) => {
    setIsRejectLoading(true);
    const startDate = moment().toJSON();
    const taskRejectPayload = [
      {
        taskId: taskId,
        createTaskApproval: {
          status: getRejectStatus(rejectedByrole),
          startDate,
          mdApproverType: userType === UserType.Customer ? ApproverType.Customer : rejectedByrole,
          dueDate: startDate,
          completionDate: startDate,
          approvalStatus: ApprovalStatus.Rejected,
          approvalName: ApprovalType.TaskApproval,
          reason: vendorMsg,
        },
      },
    ];
    const updateRejectTaskInputPayload = [
      {
        taskId: taskId,
        updateTaskInput: {
          taskStatus: userType === UserType.Customer ? TaskStatus.RejectedByCustomer : TaskStatus.RejectedBySupervisor,
        },
      },
    ];
    handleOnSubmitTasks(taskRejectPayload, updateRejectTaskInputPayload, false);
  };

  const handleApprovalAll = () => {
    setIsApproveLoading(true);
    const taskApprovePayload: IApproveTaskPayload[] = [];
    const updateTaskInputPayload: IUpdateTaskPayload[] = [];
    const startDate = moment().toJSON();
    const taskIds = selectedTaskId.map((data: any) => data.taskId);
    taskIds.forEach(async (taskId: string) => {
      taskApprovePayload.push({
        taskId,
        createTaskApproval: {
          status: getApproveStatus(),
          startDate,
          mdApproverType: userType === UserType.Customer ? ApproverType.Customer : ApproverType.Vendor,
          dueDate: startDate,
          completionDate: startDate,
          approvalStatus: ApprovalStatus.Approved,
          approvalName: ApprovalType.TaskApproval,
        },
      });
      updateTaskInputPayload.push({
        taskId,
        updateTaskInput: {
          taskStatus: getApproveStatus(),
        },
      });
    });
    if (!isEmpty(taskApprovePayload)) {
      handleOnSubmitTasks(taskApprovePayload, updateTaskInputPayload, true);
    }
  };

  const handleRejectAll = (vendorMsg: string) => {
    setIsRejectLoading(true);
    const taskApprovePayload: IApproveTaskPayload[] = [];
    const updateTaskInputPayload: IUpdateTaskPayload[] = [];
    const startDate = moment().toJSON();
    const taskIds = selectedTaskId.map((data: any) => data.taskId);
    taskIds.forEach(async (taskId: string) => {
      taskApprovePayload.push({
        taskId,
        createTaskApproval: {
          status: userType === UserType.Customer ? TaskStatus.RejectedByCustomer : TaskStatus.RejectedBySupervisor,
          startDate,
          mdApproverType: userType === UserType.Customer ? ApproverType.Customer : ApproverType.Vendor,
          dueDate: startDate,
          completionDate: startDate,
          approvalStatus: ApprovalStatus.Rejected,
          approvalName: ApprovalType.TaskApproval,
          reason: vendorMsg,
        },
      });
      updateTaskInputPayload.push({
        taskId,
        updateTaskInput: {
          taskStatus: userType === UserType.Customer ? TaskStatus.RejectedByCustomer : TaskStatus.RejectedBySupervisor,
        },
      });
    });
    if (!isEmpty(taskApprovePayload)) {
      handleOnSubmitTasks(taskApprovePayload, updateTaskInputPayload, false);
    }
  };

  const value = useMemo(
    () => ({
      user,
      setUser,
      handleOnSubmitTasks,
      handleApprovalAll,
      handleRejectAll,
      handleReject,
      handleApprove,
      setSelectedTaskId,
      selectedTaskId,
      setSnackBarStatus,
      snackBarStatus,
      isApproveLoading,
      isRejectLoading,
    }),
    [user, selectedTaskId, snackBarStatus, isApproveLoading, isRejectLoading]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
