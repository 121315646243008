/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type UserInput = {
  name: string,
  email?: string | null,
};

export type User = {
  __typename: "User",
  id: string,
  name: string,
  email?: string | null,
};

export type UserData = {
  username: string,
  roleId?: string | null,
  roleName?: string | null,
  organizationId?: string | null,
};

export type MasterDataInput = {
  keyCode: string,
  code: Code,
  value: string,
  subKey1Value?: string | null,
  subKey2Value?: string | null,
};

export enum Code {
  FACILITY_TYPE = "FACILITY_TYPE",
  WEEK_DAY = "WEEK_DAY",
  NAICS_CODE = "NAICS_CODE",
  ADDITIONAL_EXPENSES = "ADDITIONAL_EXPENSES",
  VENDOR_TYPE = "VENDOR_TYPE",
  VISA_TYPE = "VISA_TYPE",
  UNIT_TYPE = "UNIT_TYPE",
  MSA = "MSA",
  PRESHIFT_CHECKLIST = "PRESHIFT_CHECKLIST",
  DEFAULT_ROLE = "DEFAULT_ROLE",
}


export type MasterData = {
  __typename: "MasterData",
  code?: string | null,
  keyCode?: string | null,
  value?: string | null,
  subKey1Value?: string | null,
  subKey2Value?: string | null,
  canCustomize?: string | null,
  userOrg?: number | null,
  processId?: number | null,
  defaultCode?: string | null,
  keyId?: string | null,
  keyType?: string | null,
  displayName?: string | null,
};

export type OrgMasterDataInput = {
  keyId: string,
  keyType: string,
  code: Code,
  keyCode: string,
  displayName: string,
  value: string,
};

export type OrgMasterData = {
  __typename: "OrgMasterData",
  keyId: string,
  keyType: string,
  code: string,
  keyCode: string,
  displayName: string,
  value: string,
};

export type UpdateMasterDataInput = {
  keyCode: string,
  value?: string | null,
  subKey1Value?: string | null,
  subKey2Value?: string | null,
};

export type CustomerInput = {
  legalName: string,
  doingBusinessAs: string,
  websiteUrl?: string | null,
  federalEmployerId?: string | null,
  SICCode?: string | null,
  taxId?: string | null,
  logo?: string | null,
  organizationId?: string | null,
};

export type Customer = {
  __typename: "Customer",
  customerId: string,
  legalName: string,
  doingBusinessAs: string,
  websiteUrl?: string | null,
  federalEmployerId?: string | null,
  SICCode?: string | null,
  taxId?: string | null,
  logo?: string | null,
};

export type CustomerData = {
  legalName: string,
  doingBusinessAs: string,
  websiteUrl?: string | null,
  federalEmployerId?: string | null,
  SICCode?: string | null,
  taxId?: string | null,
  logo?: string | null,
};

export type CustomerDetailsInput = {
  customerId: string,
  noOfEmployees?: number | null,
  annualRevenueEstimate?: string | null,
  mdCustomerType?: string | null,
  referralSource?: string | null,
  phone?: string | null,
  fax?: string | null,
  email?: string | null,
};

export type CustomerDetails = {
  __typename: "CustomerDetails",
  customerDetailsId?: string | null,
  customerId?: string | null,
  noOfEmployees?: number | null,
  annualRevenueEstimate?: string | null,
  mdCustomerType?: string | null,
  referralSource?: string | null,
  phone?: string | null,
  fax?: string | null,
  email?: string | null,
};

export type CustomerDetailsData = {
  customerDetailsId: string,
  customerId: string,
  noOfEmployees?: number | null,
  annualRevenueEstimate?: string | null,
  mdCustomerType?: string | null,
  referralSource?: string | null,
  phone?: string | null,
  fax?: string | null,
  email?: string | null,
};

export type AddressInput = {
  customerId: string,
  addressName: string,
  noOfBuildings: number,
  addressLine1: string,
  addressLine2?: string | null,
  addressLine3?: string | null,
  city: string,
  stateCode: string,
  county?: string | null,
  postalCode: string,
  mdCountryCode: string,
  landmark: string,
  googlePlaceId?: string | null,
  latitude?: number | null,
  longitude?: number | null,
  mdMsaId?: string | null,
  logoUrl?: string | null,
};

export type Address = {
  __typename: "Address",
  addressId?: string | null,
  customerId?: string | null,
  addressName?: string | null,
  noOfBuildings?: number | null,
  addressLine1?: string | null,
  addressLine2?: string | null,
  addressLine3?: string | null,
  city?: string | null,
  stateCode?: string | null,
  county?: string | null,
  postalCode?: string | null,
  mdCountryCode?: string | null,
  landmark?: string | null,
  googlePlaceId?: string | null,
  latitude?: string | null,
  longitude?: string | null,
  mdMsaId?: string | null,
  logoUrl?: string | null,
  intId?: string | null,
};

export type AddressData = {
  addressId: string,
  customerId: string,
  addressName: string,
  noOfBuildings: number,
  addressLine1: string,
  addressLine2?: string | null,
  addressLine3?: string | null,
  city: string,
  stateCode: string,
  county?: string | null,
  postalCode: string,
  mdCountryCode: string,
  landmark: string,
  googlePlaceId?: string | null,
  latitude?: number | null,
  longitude?: number | null,
  mdMsaId?: string | null,
  logoUrl?: string | null,
};

export type FacilityInput = {
  callHours?: CallHours | null,
  transportationName?: string | null,
  storageName?: string | null,
  callStartTime?: string | null,
  callEndTime?: string | null,
  customerId: string,
  buildingName: string,
  buildingMakeup?: string | null,
  noOfFloors?: number | null,
  sqFootage: number,
  mdFacilityType: string,
  serviceableSqFootage?: number | null,
  occupancy?: number | null,
  supplyStorageAvailability?: boolean | null,
  transportRequiredWithinLocation?: boolean | null,
  contactId: string,
  facilityTrafficLevel?: string | null,
  emergencyContactName?: string | null,
  emergencyContactPhone?: string | null,
  cleaningInstructions?: string | null,
  address: AddressInput,
};

export enum CallHours {
  BusinessHours = "BusinessHours",
  AnyTime = "AnyTime",
  SpecificHours = "SpecificHours",
}


export type Facility = {
  __typename: "Facility",
  callHours?: CallHours | null,
  transportationName?: string | null,
  storageName?: string | null,
  callStartTime?: string | null,
  callEndTime?: string | null,
  facilityId?: string | null,
  customerId?: string | null,
  buildingName?: string | null,
  buildingMakeup?: string | null,
  noOfFloors?: number | null,
  sqFootage?: number | null,
  mdFacilityType?: string | null,
  serviceableSqFootage?: number | null,
  occupancy?: number | null,
  supplyStorageAvailability?: boolean | null,
  transportRequiredWithinLocation?: boolean | null,
  contactId?: string | null,
  facilityTrafficLevel?: string | null,
  emergencyContactName?: string | null,
  emergencyContactPhone?: string | null,
  cleaningInstructions?: string | null,
  geoFenceRadius?: number | null,
  addressId?: string | null,
  address?: Address | null,
};

export type FacilityData = {
  callHours?: CallHours | null,
  transportationName?: string | null,
  storageName?: string | null,
  callStartTime?: string | null,
  callEndTime?: string | null,
  facilityId: string,
  customerId: string,
  buildingName?: string | null,
  buildingMakeup?: string | null,
  noOfFloors?: number | null,
  sqFootage?: number | null,
  mdFacilityType?: string | null,
  serviceableSqFootage?: number | null,
  occupancy?: number | null,
  supplyStorageAvailability?: boolean | null,
  transportRequiredWithinLocation?: boolean | null,
  contactId?: string | null,
  facilityTrafficLevel?: string | null,
  emergencyContactName?: string | null,
  emergencyContactPhone?: string | null,
  cleaningInstructions?: string | null,
  addressId?: string | null,
};

export type FacilityServicesInput = {
  facilityId: string,
  facilityDetailsId?: string | null,
  floorNumber?: string | null,
  mdAreaType?: string | null,
  mdFlooringType?: string | null,
  quantity?: number | null,
  mdUnitType?: string | null,
};

export type FacilityServiceDetails = {
  __typename: "FacilityServiceDetails",
  facilityDetailsId: string,
  facilityId: string,
  floorNumber?: string | null,
  mdAreaType?: string | null,
  mdFlooringType?: string | null,
  quantity?: number | null,
  mdUnitType?: string | null,
};

export type UpdateFacilityServicesInput = {
  facilityId: string,
  facilityDetailsId: string,
  floorNumber?: string | null,
  mdAreaType?: string | null,
  mdFlooringType?: string | null,
  quantity?: number | null,
  mdUnitType?: string | null,
};

export type VendorInput = {
  vendorCompanyName: string,
  vendorCompanyType: string,
  doingBusinessAs: string,
  SSID: string,
  taxId: string,
  websiteUrl: string,
  logoUrl: string,
  referralSource?: string | null,
  phone: string,
  email: string,
  organizationId?: string | null,
  dataStatus?: dataStatus | null,
};

export enum dataStatus {
  A = "A",
  I = "I",
  D = "D",
  Y = "Y",
  N = "N",
  X = "X",
  U = "U",
  B = "B",
}


export type Vendor = {
  __typename: "Vendor",
  vendorId: string,
  vendorCompanyName?: string | null,
  vendorCompanyType?: string | null,
  doingBusinessAs?: string | null,
  SSID?: string | null,
  taxId?: string | null,
  websiteUrl?: string | null,
  logoUrl?: string | null,
  referralSource?: string | null,
  phone?: string | null,
  email?: string | null,
  dataStatus: string,
  modifiedOn?: string | null,
};

export type VendorProfileStatusUpdateInput = {
  vendorId: string,
  dataStatus: dataStatus,
};

export type VendorUpdate = {
  vendorId: string,
  vendorCompanyName: string,
  vendorCompanyType: string,
  doingBusinessAs: string,
  SSID?: string | null,
  taxId?: string | null,
  websiteUrl?: string | null,
  logoUrl?: string | null,
  referralSource?: string | null,
  phone: string,
  email: string,
};

export type VendorAddressInput = {
  vendorId: string,
  addressName: string,
  addressLine1: string,
  city: string,
  stateCode: string,
  postalCode: string,
  mdCountryCode: string,
  landmark: string,
  addressLine2?: string | null,
  addressLine3?: string | null,
  county?: string | null,
  googlePlaceId?: string | null,
  latitude?: number | null,
  longitude?: number | null,
  regionId?: string | null,
};

export type VendorAddress = {
  __typename: "VendorAddress",
  addressId?: string | null,
  vendorId?: string | null,
  addressName?: string | null,
  addressLine1?: string | null,
  addressLine2?: string | null,
  addressLine3?: string | null,
  city?: string | null,
  stateCode?: string | null,
  county?: string | null,
  postalCode?: string | null,
  mdCountryCode?: string | null,
  landmark?: string | null,
  googlePlaceId?: string | null,
  latitude?: string | null,
  longitude?: string | null,
  mdMsaId?: string | null,
  modifiedOn?: string | null,
  regionId?: string | null,
};

export type VendorDetailsInput = {
  annualRevenueEstimate: number,
  fax?: string | null,
  noOfEmployees?: number | null,
  coi?: string | null,
  workWeekDefinition?: string | null,
  w9FormUrl?: string | null,
};

export type VendorDetails = {
  __typename: "VendorDetails",
  vendorId?: string | null,
  annualRevenueEstimate?: number | null,
  fax?: string | null,
  coi?: string | null,
  noOfEmployees?: number | null,
  workWeekDefinition?: string | null,
  w9FormUrl?: string | null,
};

export type VendorAttachmentInput = {
  attachmentId?: string | null,
  refId?: string | null,
  refType?: RefType | null,
  fileName: string,
  mdFileContext: mdFileContext,
  fileExtension: string,
  url: string,
  fileHash?: string | null,
  date?: string | null,
  dataStatus: DataStatus,
};

export enum RefType {
  W9Form = "W9Form",
  COI = "COI",
  DUNS = "DUNS",
  TIN = "TIN",
  POA = "POA",
  DOC = "DOC",
  ContractOrMSA = "ContractOrMSA",
  Diversity = "Diversity",
  SiteSafety = "SiteSafety",
  License = "License",
  Other = "Other",
  CureLetter = "CureLetter",
  PerformanceEval = "PerformanceEval",
  W9FormSigned = "W9FormSigned",
  DiversityClassificationCode = "DiversityClassificationCode",
  DiversityEthnicityClassification = "DiversityEthnicityClassification",
}


export enum mdFileContext {
  TaskAttachment = "TaskAttachment",
  IssueAttachment = "IssueAttachment",
  PreShiftChecklistAttachment = "PreShiftChecklistAttachment",
  InsuranceAttachment = "InsuranceAttachment",
  VendorAttachment = "VendorAttachment",
  ProofOfCompletion = "ProofOfCompletion",
  ProofOfRework = "ProofOfRework",
  ProofOfReworkRequested = "ProofOfReworkRequested",
}


export enum DataStatus {
  A = "A",
  P = "P",
  E = "E",
  I = "I",
}


export type VendorAttachment = {
  __typename: "VendorAttachment",
  attachmentId: string,
  refType?: RefType | null,
  refId?: string | null,
  fileName: string,
  mdFileContext: mdFileContext,
  fileExtension: string,
  url: string,
  dataStatus: DataStatus,
  date?: string | null,
  createdOn?: string | null,
  modifiedOn?: string | null,
};

export type DeleteVendorAttachment = {
  __typename: "DeleteVendorAttachment",
  vendorAttachmentId?: string | null,
  vendorId?: string | null,
  attachmentId?: string | null,
  dataStatus?: string | null,
};

export type VendorServiceLocationInput = {
  mdAreaName: string,
  mdAreaType: string,
  mdAreaCategory?: string | null,
  locationId: string,
  country?: string | null,
};

export type UpdatedVendorServiceLocations = {
  __typename: "UpdatedVendorServiceLocations",
  serviceLocationsDeleted?:  Array<VendorServiceLocation | null > | null,
  serviceLocationsAdded?:  Array<VendorServiceLocation | null > | null,
  serviceLocationsReactivated?:  Array<VendorServiceLocation | null > | null,
};

export type VendorServiceLocation = {
  __typename: "VendorServiceLocation",
  vendorServiceLocationId?: string | null,
  vendorId?: string | null,
  mdAreaName?: string | null,
  mdAreaType?: string | null,
  mdAreaCode?: string | null,
  mdAreaCategory?: string | null,
  country?: string | null,
  locationId?: string | null,
};

export type VendorServiceInput = {
  serviceCategoryId: string,
  serviceDescription: string,
  serviceSubCategoryId: string,
};

export type UpdatedVendorServicesOffered = {
  __typename: "UpdatedVendorServicesOffered",
  added?: Array< string | null > | null,
  deleted?: Array< string | null > | null,
  reactivated?: Array< string | null > | null,
};

export type BeneficiaryInput = {
  name: string,
  TIN: string,
  percentageHolding: string,
  addressLine1: string,
  addressLine2?: string | null,
  city: string,
  state: string,
  zipCode: string,
  beneficiaryId?: string | null,
};

export type Beneficiary = {
  __typename: "Beneficiary",
  dataStatus: DataState,
  beneficiaryId: string,
  name: string,
  TIN: string,
  percentageHolding: string,
  addressLine1: string,
  addressLine2?: string | null,
  city: string,
  state: string,
  zipCode: string,
  vendorId: string,
  modifiedOn?: string | null,
};

export enum DataState {
  Active = "Active",
  InActive = "InActive",
  Pending = "Pending",
  A = "A",
  I = "I",
  P = "P",
  E = "E",
}


export type BeneficiaryUpdate = {
  name: string,
  TIN: string,
  percentageHolding: string,
  addressLine1: string,
  addressLine2?: string | null,
  city: string,
  state: string,
  zipCode: string,
  beneficiaryId: string,
  dataStatus: DataState,
};

export type InsuranceInput = {
  fileName?: string | null,
  insuranceName?: string | null,
  producer?: string | null,
  insured?: string | null,
  contactName?: string | null,
  contactPhone?: string | null,
  address?: string | null,
  fax?: string | null,
  insuranceId?: string | null,
  validTill?: string | null,
};

export type Insurance = {
  __typename: "Insurance",
  fileName?: string | null,
  insuranceName?: string | null,
  validTill?: string | null,
  producer?: string | null,
  insured?: string | null,
  contactName?: string | null,
  contactPhone?: string | null,
  address?: string | null,
  fax?: string | null,
  insuranceId?: string | null,
  dataStatus?: DataState | null,
  vendorId?: string | null,
  createdOn?: string | null,
};

export type InsuranceUpdate = {
  fileName?: string | null,
  insuranceName?: string | null,
  validTill?: string | null,
  producer?: string | null,
  insured?: string | null,
  contactName?: string | null,
  contactPhone?: string | null,
  address?: string | null,
  fax?: string | null,
  insuranceId: string,
  dataStatus: DataState,
};

export type CreateVendorAccreditationInput = {
  certificateId?: string | null,
  accreditationType: string,
  labels?: string | null,
  issuingAuthority?: string | null,
  effectiveFromDate?: string | null,
  effectiveEndDate?: string | null,
  diversityClassificationCodes?: Array< string | null > | null,
  ethnicityClassificationCodes?: Array< string | null > | null,
  msaCustomer?: string | null,
  type?: string | null,
  status?: string | null,
  attachmentInput: VendorAttachmentInput,
};

export type CreateVendorAccreditation = {
  __typename: "CreateVendorAccreditation",
  recordId?: string | null,
  recStatus?: string | null,
  certificateId?: string | null,
  accreditationType?: string | null,
  labels?: string | null,
  issuingAuthority?: string | null,
  effectiveFromDate?: string | null,
  effectiveEndDate?: string | null,
  subAccreditationTypes?: Array< string | null > | null,
  accreditationSubType?:  Array<AccreditationSubTypes | null > | null,
  msaCustomer?: string | null,
  type?: string | null,
  status?: string | null,
  dataStatus?: string | null,
  vendorId?: string | null,
  attachmentId?: string | null,
  fromDate?: string | null,
  endDate?: string | null,
  createdBy?: string | null,
  createdOn?: string | null,
  modifiedBy?: string | null,
  modifiedOn?: string | null,
};

export type AccreditationSubTypes = {
  __typename: "AccreditationSubTypes",
  accreditationSubTypeId?: string | null,
  recSeq?: number | null,
  recStatus?: string | null,
  subType?: string | null,
  value?: string | null,
  dataStatus?: string | null,
  vendorAccreditationId?: string | null,
  vendorAccreditationRecSeq?: number | null,
  fromDate?: string | null,
  endDate?: string | null,
  createdBy?: string | null,
  createdOn?: string | null,
  modifiedBy?: string | null,
  modifiedOn?: string | null,
};

export type VendorAccreditationDetail = {
  __typename: "VendorAccreditationDetail",
  recordId?: string | null,
  attachmentId?: string | null,
  certificateId?: string | null,
  accreditationType?: string | null,
  labels?: string | null,
  issuingAuthority?: string | null,
  effectiveFromDate?: string | null,
  effectiveEndDate?: string | null,
  accreditationSubType?:  Array<AccreditationSubTypes | null > | null,
  msaCustomer?: string | null,
  type?: string | null,
  status?: string | null,
  dataStatus?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  createdOn?: string | null,
  modifiedOn?: string | null,
  fromDate?: string | null,
  vendorId?: string | null,
  attachments?: VendorAttachment | null,
};

export type AssociateSkillsInput = {
  skillCategoryId: string,
  skillDescription: string,
  skillSubCategoryId: string,
  skillId: string,
};

export type UpdatedAssociateSkills = {
  __typename: "UpdatedAssociateSkills",
  added?: Array< string | null > | null,
  deleted?: Array< string | null > | null,
};

export type AssociateServiceLocationInput = {
  mdAreaName: string,
  mdAreaType: string,
  mdAreaCategory: string,
  locationId: string,
  country?: string | null,
};

export type UpdatedAssociateServiceLocations = {
  __typename: "UpdatedAssociateServiceLocations",
  serviceLocationsDeleted?:  Array<AssociateServiceLocation | null > | null,
  serviceLocationsAdded?:  Array<AssociateServiceLocation | null > | null,
  serviceLocationsReactivated?:  Array<AssociateServiceLocation | null > | null,
};

export type AssociateServiceLocation = {
  __typename: "AssociateServiceLocation",
  associateServiceLocationId?: string | null,
  associateId?: string | null,
  mdAreaName?: string | null,
  mdAreaType?: string | null,
  mdAreaCategory?: string | null,
  locationId?: string | null,
  country?: string | null,
};

export type AssociateShiftPreferenceInput = {
  shiftPreferenceId: number,
  day: string,
  fromTime: string,
  toTime: string,
  isFlexibleShiftPreference?: boolean | null,
};

export type AssociateShiftPreferences = {
  __typename: "AssociateShiftPreferences",
  shiftPreferenceId?: number | null,
  day?: string | null,
  fromTime?: string | null,
  toTime?: string | null,
  isFlexibleShiftPreference?: boolean | null,
};

export type AssociateProfileInput = {
  title: string,
  linkedInURL?: string | null,
  dateOfBirth?: string | null,
  name: string,
  emergencyContactName?: string | null,
  emergencyContactNumber?: string | null,
  eeoInfo?: string | null,
  primaryLanguage?: string | null,
  languagesSpoken?: string | null,
  source?: string | null,
  logoUrl?: string | null,
  emailId?: string | null,
};

export type AssociateProfile = {
  __typename: "AssociateProfile",
  associateProfileRecId?: string | null,
  associateId?: string | null,
  cognitoUserId?: string | null,
  title: string,
  linkedInURL?: string | null,
  dateOfBirth?: string | null,
  name?: string | null,
  emergencyContactName?: string | null,
  emergencyContactNumber?: string | null,
  eeoInfo?: string | null,
  primaryLanguage?: string | null,
  languagesSpoken?: string | null,
  source?: string | null,
  logoUrl?: string | null,
  emailId?: string | null,
};

export type AssociateProfileUpdate = {
  title?: string | null,
  linkedInURL?: string | null,
  dateOfBirth?: string | null,
  name?: string | null,
  emergencyContactName?: string | null,
  emergencyContactNumber?: string | null,
  eeoInfo?: string | null,
  primaryLanguage?: string | null,
  languagesSpoken?: string | null,
  source?: string | null,
  logoUrl?: string | null,
  emailId?: string | null,
};

export type AssociateAddressInput = {
  addressName: string,
  addressLine1: string,
  addressLine2?: string | null,
  addressLine3?: string | null,
  city: string,
  stateCode: string,
  county?: string | null,
  postalCode: string,
  mdCountryCode: string,
  landmark: string,
  googlePlaceId: string,
  latitude: number,
  longitude: number,
  regionId?: string | null,
};

export type AssociateAddress = {
  __typename: "AssociateAddress",
  addressId?: string | null,
  associateId?: string | null,
  addressName?: string | null,
  addressLine1: string,
  addressLine2?: string | null,
  addressLine3?: string | null,
  city: string,
  stateCode: string,
  county?: string | null,
  postalCode: string,
  mdCountryCode: string,
  landmark?: string | null,
  googlePlaceId: string,
  latitude: number,
  longitude: number,
  regionId?: string | null,
};

export type AssociateAddressUpdate = {
  addressName?: string | null,
  addressLine1?: string | null,
  addressLine2?: string | null,
  addressLine3?: string | null,
  city?: string | null,
  stateCode?: string | null,
  county?: string | null,
  postalCode?: string | null,
  mdCountryCode?: string | null,
  landmark?: string | null,
  googlePlaceId?: string | null,
  latitude?: number | null,
  longitude?: number | null,
  regionId?: string | null,
};

export type CreateJobInput = {
  jobName?: string | null,
  startDate?: string | null,
  stopDate?: string | null,
  jobType?: JobType | null,
  intReferenceId?: string | null,
  allowVendorToReschedule?: boolean | null,
};

export enum JobType {
  OneTime = "OneTime",
  Emergency = "Emergency",
  Recurring = "Recurring",
}


export type Job = {
  __typename: "Job",
  jobId: string,
  jobName: string,
  startDate?: string | null,
  stopDate?: string | null,
  dataStatus?: string | null,
  specialInstructions?: string | null,
  draftStep?: DraftStep | null,
  draftStatus?: DraftStatus | null,
  jobType?: JobType | null,
};

export enum DraftStep {
  SelectFacility = "SelectFacility",
  AddServices = "AddServices",
  ServicesDetails = "ServicesDetails",
  FacilitySpecifics = "FacilitySpecifics",
  Emergency = "Emergency",
  JobSummary = "JobSummary",
  Frequency = "Frequency",
  Vendors = "Vendors",
  DurationAndDetails = "DurationAndDetails",
  SearchForVendor = "SearchForVendor",
  ServicePhotosAndVideos = "ServicePhotosAndVideos",
  SelectCustomer = "SelectCustomer",
  JobExecution = "JobExecution",
}


export enum DraftStatus {
  C = "C",
  Y = "Y",
}


export type DeleteJobBidInput = {
  bidIds: Array< string >,
  jobId?: string | null,
  deleteType?: DeleteType | null,
};

export enum DeleteType {
  Soft = "Soft",
  Hard = "Hard",
  Discard = "Discard",
}


export type DeleteJobInput = {
  jobIds: Array< string >,
  deleteType?: DeleteType | null,
};

export type DeleteFacilityInput = {
  facilityIds: Array< string >,
  deleteType?: DeleteType | null,
  jobId?: string | null,
};

export type DeleteWorkOrderInput = {
  workOrderIds: Array< string | null >,
  deleteType?: DeleteType | null,
};

export type CreateJobFacilityInput = {
  facilityId: string,
  mdFacilityType: string,
  remarks?: string | null,
  status?: string | null,
  facilityAddress?: string | null,
  jobStartDate?: string | null,
  jobEndDate?: string | null,
  latitude?: number | null,
  longitude?: number | null,
};

export type JobFacilityUpdate = {
  __typename: "JobFacilityUpdate",
  added?:  Array<JobFacility | null > | null,
  deleted?:  Array<JobFacility | null > | null,
  reactivated?:  Array<JobFacility | null > | null,
};

export type JobFacility = {
  __typename: "JobFacility",
  jobId: string,
  facilityId: string,
  mdFacilityType: string,
  facilityAddress?: string | null,
  remarks?: string | null,
  status?: string | null,
  jobStartDate?: string | null,
  jobEndData?: string | null,
};

export type CreateJobServiceInput = {
  serviceMeasure: number,
  mdServiceId: string,
  mdServiceUnits: string,
  tasks?: Array< JobServiceTasksInput | null > | null,
  actualServiceMeasure?: string | null,
  actualMdServiceUnits?: number | null,
};

export type JobServiceTasksInput = {
  taskId: string,
  taskName: string,
};

export type JobServiceUpdate = {
  __typename: "JobServiceUpdate",
  added?:  Array<JobService | null > | null,
  deleted?:  Array<JobService | null > | null,
  reactivated?:  Array<JobService | null > | null,
  modified?:  Array<JobService | null > | null,
};

export type JobService = {
  __typename: "JobService",
  jobId: string,
  mdServiceId: string,
  serviceMeasure: number,
  mdServiceUnits: string,
  tasks?:  Array<JobServiceTasks | null > | null,
  actualMdServiceUnits?: number | null,
  actualServiceMeasure?: string | null,
};

export type JobServiceTasks = {
  __typename: "JobServiceTasks",
  taskId: string,
  taskName: string,
};

export type CreateJobFrequencyInput = {
  mdShiftType: string,
  mdScheduleType: string,
  mdServiceIds: Array< string | null >,
  scheduleDays: string,
  fromTime?: string | null,
  toTime?: string | null,
};

export type JobFrequencyUpdate = {
  __typename: "JobFrequencyUpdate",
  added?:  Array<JobFrequency | null > | null,
  deleted?:  Array<JobFrequency | null > | null,
  reactivated?:  Array<JobFrequency | null > | null,
  modified?:  Array<JobFrequency | null > | null,
};

export type JobFrequency = {
  __typename: "JobFrequency",
  mdShiftType?: string | null,
  mdScheduleType?: string | null,
  mdServiceId?: string | null,
  scheduleDays?: string | null,
  fromTime?: string | null,
  toTime?: string | null,
  cronExpression?: string | null,
  repeatitions?: number | null,
  repetition?: number | null,
};

export type UpdateJobInput = {
  jobName?: string | null,
  startDate?: string | null,
  stopDate?: string | null,
  specialInstructions?: string | null,
  draftStatus?: DraftStatus | null,
  draftStep?: DraftStep | null,
  jobType?: JobType | null,
  allowVendorToReschedule?: boolean | null,
  intReferenceId?: string | null,
  intId?: string | null,
};

export type JobCustomerInput = {
  customerName: string,
  customerEmail: string,
};

export type facilityAddressInput = {
  facilityAddress: string,
};

export type JobSummary = {
  __typename: "JobSummary",
  jobId: string,
  jobName: string,
  specialInstructions?: string | null,
  startDate?: string | null,
  stopDate?: string | null,
  jobType?: string | null,
  customerId?: string | null,
  jobCustodian?: string | null,
  dataStatus?: JobStatus | null,
  jobFacilities?:  Array<JobFacility | null > | null,
  jobServices?:  Array<JobService | null > | null,
  jobFrequencies?:  Array<JobFrequency | null > | null,
  jobVendors?:  Array<JobVendor | null > | null,
  jobDueDates?:  Array<JobDueDate | null > | null,
  jobAttachments?:  Array<JobAttachment | null > | null,
};

export enum JobStatus {
  Draft = "Draft",
  Awarded = "Awarded",
  OpenToBid = "OpenToBid",
  Completed = "Completed",
  Incomplete = "Incomplete",
  InProgress = "InProgress",
  Cancelled = "Cancelled",
  Expired = "Expired",
  Rejected = "Rejected",
}


export type JobVendor = {
  __typename: "JobVendor",
  jobId: string,
  vendorId: string,
  mdVendorType: string,
};

export type JobDueDate = {
  __typename: "JobDueDate",
  jobId?: string | null,
  mdDueType?: string | null,
  dueDescription?: string | null,
  dueDate?: string | null,
};

export type JobAttachment = {
  __typename: "JobAttachment",
  attachmentId?: string | null,
  mdAttachmentType?: string | null,
  fileName?: string | null,
  mdFileContext?: string | null,
  fileExtension?: string | null,
  url?: string | null,
  dataStatus?: string | null,
  comments?: string | null,
  jobId?: string | null,
  facilityId?: string | null,
  tags?: string | null,
};

export type CreateJobVendorInput = {
  vendorId?: string | null,
  mdVendorType: string,
};

export type JobVendorUpdate = {
  __typename: "JobVendorUpdate",
  added?:  Array<JobVendor | null > | null,
  deleted?:  Array<JobVendor | null > | null,
  reactivated?:  Array<JobVendor | null > | null,
};

export type CreateJobDueDateInput = {
  mdDueType?: MdDueType | null,
  dueDescription?: string | null,
  dueDate?: string | null,
};

export enum MdDueType {
  Bid = "Bid",
  Question = "Question",
  Approval = "Approval",
}


export type JobDueDateUpdate = {
  __typename: "JobDueDateUpdate",
  added?:  Array<JobDueDate | null > | null,
  deleted?:  Array<JobDueDate | null > | null,
  reactivated?:  Array<JobDueDate | null > | null,
  modified?:  Array<JobDueDate | null > | null,
};

export type JobBidInput = {
  bidName: string,
  totalCost: number,
  profitMargin: number,
  finalQuote: number,
  bidEstimationType: string,
  bidCreationDate?: string | null,
  draftStep?: BidDraftStep | null,
  isFlatMargin?: YesNoType | null,
  draftStatus?: DraftStatus | null,
  dataStatus?: BidStatus | null,
  bidTemplate?: string | null,
  bidCreatedFromSample?: string | null,
  bidVersion?: number | null,
  bidVersionCreatedDate?: string | null,
  submittedBy?: string | null,
  bidSubmissionDate?: string | null,
  mdFrequencyType?: MdFrequencyType | null,
};

export enum BidDraftStep {
  CreateNewBid = "CreateNewBid",
  ServiceEstimation = "ServiceEstimation",
  TaskEstimation = "TaskEstimation",
  BidProposal = "BidProposal",
  BidProposalSummary = "BidProposalSummary",
}


export enum YesNoType {
  Y = "Y",
  N = "N",
  O = "O",
  D = "D",
}


export enum BidStatus {
  A = "A",
  I = "I",
  C = "C",
  S = "S",
  D = "D",
  R = "R",
  P = "P",
  V = "V",
}


export enum MdFrequencyType {
  Daily = "Daily",
  Weekly = "Weekly",
  BiWeekly = "BiWeekly",
  Monthly = "Monthly",
  Total = "Total",
}


export type JobBid = {
  __typename: "JobBid",
  bidId?: string | null,
  jobId?: string | null,
  bidName?: string | null,
  totalCost?: number | null,
  profitMargin?: number | null,
  finalQuote?: number | null,
  bidEstimationType?: BidType | null,
  bidCreationDate?: string | null,
  draftStep?: BidDraftStep | null,
  isFlatMargin?: YesNoType | null,
  draftStatus?: DraftStatus | null,
  dataStatus?: BidStatus | null,
  bidTemplate?: string | null,
  bidVersion?: number | null,
  bidVersionCreatedDate?: string | null,
  submittedBy?: string | null,
  bidSubmissionDate?: string | null,
  mdFrequencyType?: MdFrequencyType | null,
  comments?: string | null,
  bidCreatedFromSample?: string | null,
  errorCode?: string | null,
  vendorId?: string | null,
  vendorName?: string | null,
  vendorEmail?: string | null,
};

export enum BidType {
  Easy = "Easy",
  Advanced = "Advanced",
}


export type BidVendorInput = {
  vendorName: string,
  vendorEmail: string,
  comments?: string | null,
  emailCC?: string | null,
  emailBcc?: string | null,
  customerEmail?: string | null,
  isCustomerApproved?: BooleanType | null,
};

export enum BooleanType {
  True = "True",
  False = "False",
}


export type UpdateJobBidInput = {
  bidName?: string | null,
  totalCost?: number | null,
  profitMargin?: number | null,
  finalQuote?: number | null,
  bidEstimationType?: string | null,
  bidCreationDate?: string | null,
  draftStep?: BidDraftStep | null,
  draftStatus?: DraftStatus | null,
  dataStatus: BidStatus,
  bidTemplate?: string | null,
  isFlatMargin?: YesNoType | null,
  bidCreatedFromSample?: string | null,
  bidVersion?: number | null,
  bidVersionCreatedDate?: string | null,
  submittedBy?: string | null,
  bidSubmissionDate?: string | null,
  mdFrequencyType?: MdFrequencyType | null,
  comments?: string | null,
};

export type CreateJobBidEstimationInput = {
  mdMeasureType: string,
  mdCategoryId: string,
  mdTaskId?: string | null,
  rate: number,
  quantity: number,
  isOverTime?: YesNoType | null,
  totalCost: number,
  isVendorAdded: YesNoType,
  isBillable: Billable,
  numberOfOccurrences?: number | null,
  mdUnits?: string | null,
  estimationId?: string | null,
};

export enum Billable {
  Y = "Y",
  N = "N",
}


export type JobBidEstimationUpdate = {
  __typename: "JobBidEstimationUpdate",
  added?:  Array<JobBidEstimation | null > | null,
  deleted?:  Array<JobBidEstimation | null > | null,
  modified?:  Array<JobBidEstimation | null > | null,
};

export type JobBidEstimation = {
  __typename: "JobBidEstimation",
  jobId: string,
  estimationId: string,
  bidId: string,
  mdCategoryId: string,
  mdTaskId?: string | null,
  mdMeasureType: string,
  rate: number,
  quantity: number,
  isOverTime?: YesNoType | null,
  totalCost: number,
  isVendorAdded: YesNoType,
  isBillable: Billable,
  numberOfOccurrences?: number | null,
  mdUnits?: string | null,
  tasks?:  Array<JobServiceTasks | null > | null,
};

export type UpdateJobBidEstimationInput = {
  rate: number,
  quantity: number,
  isOverTime?: YesNoType | null,
  totalCost: number,
  isBillable: Billable,
  numberOfOccurrences?: number | null,
  mdCategoryId?: string | null,
};

export type CreateJobBidAdditionalExpensesInput = {
  mdAdditionalExpenseCategory: string,
  name?: string | null,
  description?: string | null,
  bidSimpleEstimationEstimationId?: string | null,
  rate: number,
  quantity?: number | null,
  totalCost?: number | null,
  mdUnits?: string | null,
  mdServiceCategoryId?: string | null,
  bidAdditionalExpenseId?: string | null,
  draftStatus: DraftStatus,
};

export type CreateOrUpdateJobBidAdditionalExpense = {
  __typename: "CreateOrUpdateJobBidAdditionalExpense",
  added?:  Array<JobBidAdditionalExpenses | null > | null,
  deleted?:  Array<JobBidAdditionalExpenses | null > | null,
  modified?:  Array<JobBidAdditionalExpenses | null > | null,
};

export type JobBidAdditionalExpenses = {
  __typename: "JobBidAdditionalExpenses",
  bidId: string,
  bidAdditionalExpenseId?: string | null,
  name?: string | null,
  description?: string | null,
  bidSimpleEstimationEstimationId?: string | null,
  rate: number,
  quantity?: number | null,
  totalCost?: number | null,
  mdUnits?: string | null,
  mdServiceCategoryId?: string | null,
  mdAdditionalExpenseCategory: string,
  draftStatus?: DraftStatus | null,
};

export type CreateFacilityServiceSpecificationInput = {
  floorNumber?: string | null,
  mdServiceId: string,
  serviceQuantity: number,
  mdServiceUnitType: string,
};

export type FacilityServiceSpecificationUpdate = {
  __typename: "FacilityServiceSpecificationUpdate",
  added?:  Array<FacilityServiceSpecification | null > | null,
  deleted?:  Array<FacilityServiceSpecification | null > | null,
  reactivated?:  Array<FacilityServiceSpecification | null > | null,
  modified?:  Array<FacilityServiceSpecification | null > | null,
};

export type FacilityServiceSpecification = {
  __typename: "FacilityServiceSpecification",
  facilityId: string,
  floorNumber?: string | null,
  mdServiceId: string,
  serviceQuantity: number,
  mdServiceUnitType: string,
};

export type PreShiftChecklistTemplateInput = {
  preShiftChecklistTemplateId?: string | null,
  checkListName: string,
  checkListIconUrl: string,
  mdPreShiftCheckListCategoryId: string,
  buildingAccessConfirmation: WorkOrderYesNoType,
  securityAccessConfirmation: WorkOrderYesNoType,
  safetyConfirmation: WorkOrderYesNoType,
  msdsChemicalSafety: WorkOrderYesNoType,
  workStatus: WorkOrderStatus,
  preShiftChecklistTemplateItems?: Array< PreShiftChecklistTemplateItemInput | null > | null,
  isPreShiftChecklistItemRequired?: boolean | null,
};

export enum WorkOrderYesNoType {
  Yes = "Yes",
  No = "No",
}


export enum WorkOrderStatus {
  Created = "Created",
  Accepted = "Accepted",
  Started = "Started",
  InProgress = "InProgress",
  OnHold = "OnHold",
  Completed = "Completed",
  Rejected = "Rejected",
  InComplete = "InComplete",
  Expired = "Expired",
  Cancelled = "Cancelled",
}


export type PreShiftChecklistTemplateItemInput = {
  preShiftChecklistTemplateItemId?: string | null,
  itemName: string,
  mdItemActionType: PreShiftChecklistTemplateItemActionType,
  quantity: number,
  mdQuantityType: PreShiftChecklistTemplateItemQuantityType,
  workOrderServiceId?: string | null,
};

export enum PreShiftChecklistTemplateItemActionType {
  Brought = "Brought",
  Provided = "Provided",
  PickedUp = "PickedUp",
}


export enum PreShiftChecklistTemplateItemQuantityType {
  Unit = "Unit",
  Pair = "Pair",
  Rolls = "Rolls",
  Litre = "Litre",
  KG = "KG",
}


export type PreShiftChecklistTemplate = {
  __typename: "PreShiftChecklistTemplate",
  preShiftChecklistTemplateId: string,
  checkListName: string,
  checkListIconUrl: string,
  mdPreShiftCheckListCategoryId: string,
  buildingAccessConfirmation: WorkOrderYesNoType,
  securityAccessConfirmation: WorkOrderYesNoType,
  safetyConfirmation: WorkOrderYesNoType,
  msdsChemicalSafety: WorkOrderYesNoType,
  workStatus: WorkOrderStatus,
  workOrderId: string,
  workOrderRecSeq: number,
  preShiftChecklistTemplateItems?:  Array<PreShiftChecklistTemplateItem | null > | null,
};

export type PreShiftChecklistTemplateItem = {
  __typename: "PreShiftChecklistTemplateItem",
  itemName: string,
  preShiftChecklistTemplateItemId: string,
  mdItemActionType: PreShiftChecklistTemplateItemActionType,
  quantity: number,
  mdQuantityType: PreShiftChecklistTemplateItemQuantityType,
};

export type PreShiftChecklistTemplateUpdate = {
  __typename: "PreShiftChecklistTemplateUpdate",
  preShiftChecklistTemplateId: string,
  checkListName: string,
  checkListIconUrl: string,
  mdPreShiftCheckListCategoryId: string,
  buildingAccessConfirmation: WorkOrderYesNoType,
  securityAccessConfirmation: WorkOrderYesNoType,
  safetyConfirmation: WorkOrderYesNoType,
  msdsChemicalSafety: WorkOrderYesNoType,
  workStatus: WorkOrderStatus,
  workOrderId: string,
  workOrderRecSeq: number,
  updatedPreShiftChecklistTemplateItems?: PreShiftChecklistTemplateItemUpdate | null,
};

export type PreShiftChecklistTemplateItemUpdate = {
  __typename: "PreShiftChecklistTemplateItemUpdate",
  added?:  Array<PreShiftChecklistTemplateItem | null > | null,
  deleted?:  Array<PreShiftChecklistTemplateItem | null > | null,
  modified?:  Array<PreShiftChecklistTemplateItem | null > | null,
};

export type PreShiftChecklistInput = {
  executionDate: string,
  workDayId?: string | null,
  preShiftChecklistTemplateId: string,
  preShiftChecklistItems?: Array< PreShiftChecklistItemInput | null > | null,
};

export type PreShiftChecklistItemInput = {
  markedAsDone: string,
  markedBy: string,
  markedOn: string,
  preShiftChecklistId?: string | null,
  preShiftChecklistItemId?: string | null,
  preShiftChecklistTemplateItemId: string,
  itemAttachmentList?: Array< ItemAttachmentListInput | null > | null,
};

export type ItemAttachmentListInput = {
  attachmentStatus: AttachmentStatus,
  mdAttachmentType: mdAttachmentType,
  fileName: string,
  mdFileContext: mdFileContext,
  fileExtension: string,
  url: string,
  fileHash?: string | null,
  comments?: string | null,
};

export enum AttachmentStatus {
  ReviewPending = "ReviewPending",
  Blocked = "Blocked",
  Approved = "Approved",
  Rejected = "Rejected",
  Rework = "Rework",
  ReworkRequested = "ReworkRequested",
}


export enum mdAttachmentType {
  Audio = "Audio",
  Video = "Video",
  Document = "Document",
  Photo = "Photo",
  Image = "Image",
}


export type PreShiftChecklist = {
  __typename: "PreShiftChecklist",
  executionDate: string,
  workDayId?: string | null,
  preShiftChecklistId?: string | null,
  checkListName?: string | null,
  checkListIconUrl?: string | null,
  mdPreShiftCheckListCategoryId?: string | null,
  preShiftChecklistTemplateId: string,
  preShiftChecklistItems?:  Array<PreShiftChecklistItem | null > | null,
};

export type PreShiftChecklistItem = {
  __typename: "PreShiftChecklistItem",
  itemName?: string | null,
  mdItemActionType?: PreShiftChecklistTemplateItemActionType | null,
  quantity?: number | null,
  mdQuantityType?: PreShiftChecklistTemplateItemQuantityType | null,
  workOrderServiceId?: string | null,
  markedAsDone?: WorkOrderYesNoType | null,
  markedBy?: string | null,
  markedOn?: string | null,
  preShiftChecklistId?: string | null,
  preShiftChecklistItemId?: string | null,
  preShiftChecklistTemplateItemId: string,
  preShiftChecklistIssueId?: string | null,
};

export type ShiftDetailsInput = {
  shiftDetailId?: string | null,
  startTime: string,
  endTime: string,
  punchInGraceMinutes?: number | null,
  punchOutGraceMinutes?: number | null,
};

export type ShiftDetailsUpdate = {
  __typename: "ShiftDetailsUpdate",
  added?:  Array<ShiftDetails | null > | null,
  deleted?:  Array<ShiftDetails | null > | null,
  modified?:  Array<ShiftDetails | null > | null,
};

export type ShiftDetails = {
  __typename: "ShiftDetails",
  shiftDetailId: string,
  startTime: string,
  endTime: string,
  workOrderId: string,
  punchInGraceMinutes?: number | null,
  punchOutGraceMinutes?: number | null,
};

export type CreateInputJobInterest = {
  jobId: string,
  actionType: JobInterestsActionType,
};

export enum JobInterestsActionType {
  Interested = "Interested",
  NotInterested = "NotInterested",
  BlockNotInterested = "BlockNotInterested",
}


export type JobInterest = {
  __typename: "JobInterest",
  vendorId: string,
  jobId: string,
  actionType: string,
  actionTime?: string | null,
  actionUser?: string | null,
};

export type UpdateWorkOrderInput = {
  workOrderId: string,
  name?: string | null,
  status?: WorkOrderStatus | null,
  workOrderAcceptedOn?: string | null,
  workOrderAcceptedBy?: string | null,
  actualStartDate?: string | null,
  actualEndDate?: string | null,
  jobStartDate?: string | null,
  jobEndDate?: string | null,
  allowVendorToReschedule?: boolean | null,
  workOrderRating?: number | null,
};

export type WorkOrder = {
  __typename: "WorkOrder",
  workOrderId?: string | null,
  jobId?: string | null,
  bidId?: string | null,
  vendorId?: string | null,
  customerId?: string | null,
  name?: string | null,
  status?: WorkOrderStatus | null,
  totalAmount?: number | null,
  createdBy?: string | null,
  createdOn?: string | null,
  modifiedBy?: string | null,
  modifiedOn?: string | null,
  jobStartDate?: string | null,
  jobType?: JobType | null,
  jobEndDate?: string | null,
  actualStartDate?: string | null,
  actualEndDate?: string | null,
  jobCustodian?: string | null,
  jobOrderAcceptedOn?: string | null,
  jobOrderAcceptedBy?: string | null,
  jobOrderFilePath?: string | null,
  paymentMethod?: PaymentMethod | null,
  paymentFrequency?: PaymentFrequency | null,
  paymentDay?: string | null,
  facilities?:  Array<WorkOrderFacilityAccess | null > | null,
  services?:  Array<WorkOrderService | null > | null,
  jobBidSummary?: WorkOrderJobBidSummary | null,
  workOrderTeams?:  Array<WorkOrderTeam | null > | null,
  shiftDetails?:  Array<ShiftDetailsOfWorkOrder | null > | null,
  subWorkOrders?:  Array<SubWorkOrder | null > | null,
  hasChild?: boolean | null,
  parentWorkOrderId?: string | null,
  jobCreator?: string | null,
  bidApprovalStatus?: string | null,
  allowVendorToReschedule?: boolean | null,
  isPaid?: boolean | null,
  workOrderRating?: number | null,
};

export enum PaymentMethod {
  Cash = "Cash",
  CreditCard = "CreditCard",
  DebitCard = "DebitCard",
  BankTransfer = "BankTransfer",
  Wallet = "Wallet",
  ACH = "ACH",
}


export enum PaymentFrequency {
  Daily = "Daily",
  Weekly = "Weekly",
  Monthly = "Monthly",
}


export type WorkOrderFacilityAccess = {
  __typename: "WorkOrderFacilityAccess",
  facilityId?: string | null,
  facilityName?: string | null,
  facilityAddress?: string | null,
  accessContactPerson?: string | null,
  accessContactDetails?: string | null,
  otherInformation?: string | null,
  accessInfo?: string | null,
  accessCode?: string | null,
  day?: string | null,
  accessFrom?: string | null,
  accessTo?: string | null,
  from?: string | null,
  to?: string | null,
  workOrderFacilityAccessId?: string | null,
};

export type WorkOrderService = {
  __typename: "WorkOrderService",
  workOrderServiceId?: string | null,
  mdServiceId?: string | null,
  serviceMeasure?: number | null,
  mdServiceUnits?: string | null,
  taskSchedule?:  Array<WorkOrderTaskSchedule | null > | null,
};

export type WorkOrderTaskSchedule = {
  __typename: "WorkOrderTaskSchedule",
  taskScheduleId?: string | null,
  mdTaskId?: string | null,
  isProofOfCompletionRequired?: WorkOrderYesNoType | null,
};

export type WorkOrderJobBidSummary = {
  __typename: "WorkOrderJobBidSummary",
  jobBidSummaryId?: string | null,
  jobDetails?: string | null,
  bidDetails?: string | null,
};

export type WorkOrderTeam = {
  __typename: "WorkOrderTeam",
  teamId: string,
  vendorId?: string | null,
  userId?: string | null,
  roleId?: string | null,
  isSupervisor?: boolean | null,
  workOrderId?: string | null,
  jobAccepted?: boolean | null,
  associateStartDate?: string | null,
  associateEndDate?: string | null,
};

export type ShiftDetailsOfWorkOrder = {
  __typename: "ShiftDetailsOfWorkOrder",
  shiftDetailId?: string | null,
  startTime?: string | null,
  endTime?: string | null,
  punchInGraceMinutes?: number | null,
  punchOutGraceMinutes?: number | null,
};

export type SubWorkOrder = {
  __typename: "SubWorkOrder",
  workOrderId?: string | null,
  jobId?: string | null,
  bidId?: string | null,
  vendorId?: string | null,
  customerId?: string | null,
  name?: string | null,
  status?: WorkOrderStatus | null,
  totalAmount?: number | null,
  createdBy?: string | null,
  createdOn?: string | null,
  modifiedBy?: string | null,
  modifiedOn?: string | null,
  jobStartDate?: string | null,
  jobType?: JobType | null,
  jobEndDate?: string | null,
  actualStartDate?: string | null,
  actualEndDate?: string | null,
  jobCustodian?: string | null,
  jobOrderAcceptedOn?: string | null,
  jobOrderAcceptedBy?: string | null,
  jobOrderFilePath?: string | null,
  paymentMethod?: PaymentMethod | null,
  paymentFrequency?: PaymentFrequency | null,
  paymentDay?: string | null,
  hasChild?: boolean | null,
  parentWorkOrderId?: string | null,
  services?:  Array<WorkOrderService | null > | null,
  shiftDetails?:  Array<ShiftDetailsOfWorkOrder | null > | null,
  jobBidSummary?: WorkOrderJobBidSummary | null,
  bidApprovalStatus?: string | null,
};

export type CreateWorkOrderTeam = {
  vendorId: string,
  userId: string,
  roleId: string,
  isSupervisor: boolean,
  teamId?: string | null,
  facilityId: string,
  associateStartDate?: string | null,
  associateEndDate?: string | null,
};

export type WorkOrderTeamUpdate = {
  __typename: "WorkOrderTeamUpdate",
  added?:  Array<WorkOrderTeam | null > | null,
  deleted?:  Array<WorkOrderTeam | null > | null,
  reactivated?:  Array<WorkOrderTeam | null > | null,
  modified?:  Array<WorkOrderTeam | null > | null,
};

export type UpdateWorkOrderTeam = {
  vendorId?: string | null,
  userId?: string | null,
  roleId?: string | null,
  isSupervisor: boolean,
  teamId: string,
  facilityId: string,
  associateStartDate?: string | null,
  associateEndDate?: string | null,
  jobAccepted?: boolean | null,
};

export type CreateWorkOrderFacilityAccess = {
  facilityId?: string | null,
  accessContactPerson?: string | null,
  accessContactDetails?: string | null,
  otherInformation?: string | null,
  accessInfo?: string | null,
  accessCode?: string | null,
  accessCodeEncrypted?: boolean | null,
  day?: string | null,
  accessFrom?: string | null,
  accessTo?: string | null,
  from?: string | null,
  to?: string | null,
};

export type UpdateTaskInput = {
  plannedStartTime?: string | null,
  plannedEndTime?: string | null,
  plannedEffort?: string | null,
  actualEffort?: number | null,
  actualStartTime?: string | null,
  actualEndTime?: string | null,
  mdServiceDescription?: string | null,
  associateId?: string | null,
  occurrenceDate?: string | null,
  suggestedReworkDate?: string | null,
  suggestedScheduleType?: TaskScheduleType | null,
  reworkDate?: string | null,
  markedAsDone?: WorkOrderYesNoType | null,
  markedBy?: string | null,
  markedOn?: string | null,
  taskStatus?: TaskStatus | null,
  taskId?: string | null,
  isPartiallyCompleted?: boolean | null,
  taskApprovalInput?: Array< CreateTaskApprovalInput | null > | null,
};

export enum TaskScheduleType {
  CurrentShift = "CurrentShift",
  NextWorkDay = "NextWorkDay",
  SpecificDay = "SpecificDay",
}


export enum TaskStatus {
  Scheduled = "Scheduled",
  InProgress = "InProgress",
  Complete = "Complete",
  Rejected = "Rejected",
  Rework = "Rework",
  OnHold = "OnHold",
  ApprovedBySupervisor = "ApprovedBySupervisor",
  ApprovedByCustomer = "ApprovedByCustomer",
  RejectedBySupervisor = "RejectedBySupervisor",
  RejectedByCustomer = "RejectedByCustomer",
  PendingCustomerReview = "PendingCustomerReview",
  ApprovedByPrimeVendor = "ApprovedByPrimeVendor",
  RejectedByPrimeVendor = "RejectedByPrimeVendor",
  PendingPrimeVendorApproval = "PendingPrimeVendorApproval",
  ReworkRequestedByCustomer = "ReworkRequestedByCustomer",
}


export type CreateTaskApprovalInput = {
  approvalName: ApprovalType,
  dueDate?: string | null,
  startDate?: string | null,
  completionDate?: string | null,
  reason?: string | null,
  status: TaskStatus,
  rankingRate?: number | null,
  approvalStatus: ApprovalStatus,
  mdApproverType: ApproverType,
};

export enum ApprovalType {
  TaskApproval = "TaskApproval",
  IssueApproval = "IssueApproval",
}


export enum ApprovalStatus {
  ReviewPending = "ReviewPending",
  Blocked = "Blocked",
  Approved = "Approved",
  Rejected = "Rejected",
  Rework = "Rework",
  ReworkRequested = "ReworkRequested",
}


export enum ApproverType {
  Vendor = "Vendor",
  Customer = "Customer",
  PrimeVendor = "PrimeVendor",
  Supervisor = "Supervisor",
}


export type Task = {
  __typename: "Task",
  taskId?: string | null,
  occurrenceDate?: string | null,
  suggestedReworkDate?: string | null,
  suggestedScheduleType?: TaskScheduleType | null,
  taskNumber?: number | null,
  markedAsDone?: string | null,
  markedBy?: string | null,
  markedOn?: string | null,
  taskStatus?: TaskStatus | null,
  workOrderId?: string | null,
  workDayId?: string | null,
  taskScheduleId?: string | null,
  mdServiceDescription?: string | null,
  plannedStartTime?: string | null,
  plannedEndTime?: string | null,
  plannedEffort?: string | null,
  actualEffort?: string | null,
  actualStartTime?: string | null,
  actualEndTime?: string | null,
  associateId?: string | null,
  taskSchedule?: TaskSchedule | null,
  taskAssignment?:  Array<TaskAssignment | null > | null,
  reworkTaskId?: string | null,
  errorCode?: string | null,
};

export type TaskSchedule = {
  __typename: "TaskSchedule",
  taskScheduleId: string,
  mdShiftType?: string | null,
  isProofOfCompletionRequired: WorkOrderYesNoType,
  schedule?: string | null,
  duration?: string | null,
  mdTaskId: string,
  noOfOccurrences?: number | null,
  noOfOccurrencesCompleted?: number | null,
};

export type TaskAssignment = {
  __typename: "TaskAssignment",
  taskId?: string | null,
  taskAssignmentId?: string | null,
  assignedBy?: string | null,
  assignedOn?: string | null,
  associateId?: string | null,
};

export type CreateFacilityAccessDetails = {
  jobId?: string | null,
  accessContactPerson: string,
  roleName: string,
  accessContactDetails: string,
  otherInformation?: string | null,
  accessInfo?: string | null,
  accessCode?: string | null,
  accessCodeEncrypted: boolean,
  day?: string | null,
  from?: string | null,
  to?: string | null,
};

export type CustomerFacilityAccessDetails = {
  __typename: "CustomerFacilityAccessDetails",
  facilityAccessDetailId: string,
  facilityId: string,
  jobId?: string | null,
  accessContactPerson: string,
  roleName: string,
  accessContactDetails: string,
  otherInformation?: string | null,
  accessInfo?: string | null,
  accessCode?: string | null,
  day?: string | null,
  from?: string | null,
  to?: string | null,
};

export type UpdateFacilityAccessDetails = {
  jobId?: string | null,
  facilityAccessDetailId: string,
  accessContactPerson?: string | null,
  roleName?: string | null,
  accessContactDetails?: string | null,
  otherInformation?: string | null,
  accessInfo?: string | null,
  accessCode?: string | null,
  accessCodeEncrypted?: boolean | null,
  day?: string | null,
  from?: string | null,
  to?: string | null,
};

export type UpdateTaskScheduleInput = {
  taskScheduleId: string,
  mdShiftType?: string | null,
  isProofOfCompletionRequired?: WorkOrderYesNoType | null,
  duration?: string | null,
  schedule?: string | null,
};

export type AssignTeamInput = {
  toDate?: string | null,
  fromDate?: string | null,
  serviceId?: string | null,
  associateId?: string | null,
  facilityId?: string | null,
  Status?: TaskStatus | null,
  isJobAccepted: string,
};

export type CreateTaskAttachmentInput = {
  attachmentStatus: AttachmentStatus,
  mdAttachmentType: mdAttachmentType,
  fileName: string,
  mdFileContext: mdFileContext,
  fileExtension: string,
  url: string,
  fileHash?: string | null,
  comments?: string | null,
  dataStatus?: string | null,
};

export type TaskAttachmentUpdate = {
  __typename: "TaskAttachmentUpdate",
  added?:  Array<TaskAttachment | null > | null,
  deleted?:  Array<TaskAttachment | null > | null,
};

export type TaskAttachment = {
  __typename: "TaskAttachment",
  attachmentId?: string | null,
  attachmentStatus?: AttachmentStatus | null,
  mdAttachmentType?: string | null,
  fileName?: string | null,
  mdFileContext?: string | null,
  fileExtension?: string | null,
  url?: string | null,
  fileHash?: string | null,
  comments?: string | null,
  createdById?: string | null,
  createdByName?: string | null,
};

export type TaskApprovalUpdate = {
  __typename: "TaskApprovalUpdate",
  added?:  Array<TaskApproval | null > | null,
  modified?:  Array<TaskApproval | null > | null,
};

export type TaskApproval = {
  __typename: "TaskApproval",
  approvalId: string,
  approvalName?: ApprovalType | null,
  dueDate?: string | null,
  startDate?: string | null,
  completionDate?: string | null,
  reason?: string | null,
  status?: string | null,
  rankingRate?: number | null,
  approvalStatus?: string | null,
  mdApproverType?: string | null,
};

export type W9Input = {
  vendorId: string,
  name: string,
  businessName: string,
  recipient_phonenumber?: string | null,
  recipient_countrycode?: string | null,
  isIndividual?: boolean | null,
  isCCorporation?: boolean | null,
  isSCorporation?: boolean | null,
  isPartnetship?: boolean | null,
  isTrustEstate?: boolean | null,
  isLLC?: boolean | null,
  isOtherInstructions?: boolean | null,
  taxClassification?: string | null,
  otherInstructions?: string | null,
  exemptPayeeCode?: string | null,
  exemptFATCACode?: string | null,
  address?: string | null,
  cityStateZip?: string | null,
  accountNumbers?: string | null,
  requesterDetails?: string | null,
  ssnPart1?: string | null,
  ssnPart2?: string | null,
  ssnPart3?: string | null,
  einPart1: string,
  einPart2: string,
};

export type w9Response = {
  __typename: "w9Response",
  embedURL?: embeddedSignURL | null,
  requestId?: string | null,
};

export type embeddedSignURL = {
  __typename: "embeddedSignURL",
  embeddedSigningURL: string,
};

export type PunchCardInput = {
  punchType: PunchType,
  locationLatitude: number,
  locationLongitude: number,
  punchComments?: string | null,
  facilityId?: string | null,
  punchTime?: string | null,
  userId?: string | null,
};

export enum PunchType {
  PunchIn = "PunchIn",
  PunchOut = "PunchOut",
  MealStart = "MealStart",
  MealEnd = "MealEnd",
  BreakStart = "BreakStart",
  BreakEnd = "BreakEnd",
  NoShow = "NoShow",
}


export type PunchCardItem = {
  __typename: "PunchCardItem",
  punchCardId?: string | null,
  workDayId?: string | null,
  userId?: string | null,
  punchTime?: string | null,
  punchType?: PunchType | null,
  locationLatitude?: number | null,
  locationLongitude?: number | null,
  punchComments?: string | null,
  status?: string | null,
  errorCode?: string | null,
  punchNotifications?: string | null,
  punchMealPeriodAttestation?: string | null,
  punchWorkersCompConfirmation?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  punchInTime?: string | null,
  punchOutTime?: string | null,
  totalWorkMinutes?: number | null,
  totalMinutesOnSite?: number | null,
  facilityDistance?: number | null,
};

export type ShiftBreakInput = {
  punchType: ShiftBreakType,
  facilityId: string,
  userId: string,
  startTime: string,
  endTime?: string | null,
  comments?: string | null,
};

export enum ShiftBreakType {
  Break = "Break",
  Lunch = "Lunch",
}


export type ShiftBreakItem = {
  __typename: "ShiftBreakItem",
  recordId: string,
  punchType: ShiftBreakType,
  facilityId: string,
  userId: string,
  startTime: string,
  endTime?: string | null,
  comments?: string | null,
};

export type UpdatePunchCardInput = {
  punchTime?: string | null,
  punchType?: PunchType | null,
  locationLatitude?: number | null,
  locationLongitude?: number | null,
  punchComments?: string | null,
  punchCardId: string,
  userId?: string | null,
  punchInTime?: string | null,
  punchOutTime?: string | null,
};

export type UpdateTaskAttachmentInput = {
  attachmentStatus: string,
  attachmentId: string,
};

export type VendorDetailsUpdate = {
  w9FormUrl?: string | null,
  noOfEmployees?: number | null,
  annualRevenueEstimate?: number | null,
  coi?: string | null,
};

export type CreateIssueInput = {
  title: string,
  shortDescription: string,
  issueRequestor?: string | null,
  issueResolver?: string | null,
  issuePriority?: IssuePriority | null,
  templateId?: string | null,
  entityId?: string | null,
  templateValue?: string | null,
  attachments?: Array< AttachmentInput | null > | null,
};

export enum IssuePriority {
  Low = "Low",
  Medium = "Medium",
  High = "High",
}


export type AttachmentInput = {
  comments?: string | null,
  attachmentStatus?: string | null,
  mdAttachmentType?: string | null,
  fileName?: string | null,
  mdFileContext?: string | null,
  fileExtension?: string | null,
  url?: string | null,
  fileHash?: string | null,
  dataStatus?: string | null,
};

export type Issue = {
  __typename: "Issue",
  issueId: string,
  title: string,
  shortDescription: string,
  issueRequestor?: string | null,
  issueResolver?: string | null,
  issuePriority?: IssuePriority | null,
  templateId?: string | null,
  entityId?: string | null,
  templateValue?: string | null,
  attachments?:  Array<Attachment | null > | null,
};

export type Attachment = {
  __typename: "Attachment",
  comments?: string | null,
  attachmentId?: string | null,
  attachmentStatus?: string | null,
  mdAttachmentType?: string | null,
  fileName?: string | null,
  mdFileContext?: string | null,
  fileExtension?: string | null,
  url?: string | null,
  fileHash?: string | null,
  dataStatus?: string | null,
};

export type AssignTaskInput = {
  workOrderId?: string | null,
  Status?: string | null,
  facilityId?: string | null,
  serviceId?: string | null,
  fromDate?: string | null,
  toDate?: string | null,
  fromAssociateId?: string | null,
  toAssociateId?: string | null,
  workDayId?: string | null,
};

export type UpdatedTaskAssignment = {
  __typename: "UpdatedTaskAssignment",
  associateId?: string | null,
  assignedBy?: string | null,
  assignedOn?: string | null,
  taskId?: string | null,
};

export type UpdateVendorAttachmentInput = {
  attachmentId: string,
  refType?: string | null,
  fileName?: string | null,
  mdFileContext?: string | null,
  fileExtension?: string | null,
  url: string,
  refId?: string | null,
  dataStatus: string,
};

export type VendorAddressUpdate = {
  vendorId: string,
  addressId: string,
  addressName: string,
  addressLine1: string,
  city: string,
  stateCode: string,
  postalCode: string,
  mdCountryCode: string,
  landmark: string,
  addressLine2?: string | null,
  addressLine3?: string | null,
  county?: string | null,
  googlePlaceId?: string | null,
  latitude?: number | null,
  longitude?: number | null,
  regionId?: string | null,
};

export type UpdateUserInput = {
  organizationId: string,
  salutation?: string | null,
  userId: string,
  email?: string | null,
  phoneNumber?: string | null,
  dataStatus?: string | null,
  username?: string | null,
};

export type UpdateUserDetails = {
  __typename: "UpdateUserDetails",
  userId?: string | null,
  salutation?: string | null,
  email?: string | null,
  phoneNumber?: string | null,
  dataStatus?: string | null,
  username?: string | null,
};

export type AddedUserToTeam = {
  __typename: "AddedUserToTeam",
  userId?: string | null,
  teamId?: string | null,
};

export type AssignRoleToUserInput = {
  roleId: string,
  userId: string,
  organizationId: string,
};

export type AddedRoleToUser = {
  __typename: "AddedRoleToUser",
  roleId?: string | null,
  userId?: string | null,
};

export type RemoveUserFromTeam = {
  __typename: "RemoveUserFromTeam",
  userId?: string | null,
  teamId?: string | null,
  message?: string | null,
};

export type CreateOrganizationInput = {
  organizationName: string,
  team?: TeamDto | null,
  user?: Array< UserDto | null > | null,
  entityType: EntityType,
};

export type TeamDto = {
  name: string,
};

export type UserDto = {
  phoneNumber?: string | null,
  email?: string | null,
  username: string,
  cognitoUserId?: string | null,
};

export enum EntityType {
  CUSTOMER = "CUSTOMER",
  VENDOR = "VENDOR",
  ASSOCIATE = "ASSOCIATE",
}


export type Organization = {
  __typename: "Organization",
  organizationId?: string | null,
  organizationName?: string | null,
  dataStatus?: string | null,
  teams?:  Array<OrganizationTeam | null > | null,
  users?:  Array<OrganizationUser | null > | null,
  errorCode?: string | null,
};

export type OrganizationTeam = {
  __typename: "OrganizationTeam",
  teamId?: string | null,
  name?: string | null,
};

export type OrganizationUser = {
  __typename: "OrganizationUser",
  userId?: string | null,
};

export type CreateRole = {
  entityType: EntityType,
  roleName: string,
  organizationId: string,
};

export type RoleResponse = {
  __typename: "RoleResponse",
  roleName?: string | null,
  roleId?: string | null,
  organizationId?: string | null,
};

export type CreateUser = {
  username: string,
  organizationId: string,
  phoneNumber?: string | null,
  email?: string | null,
  roleId: string,
  teamId?: string | null,
  dataStatus?: string | null,
  managerId?: string | null,
  salutation?: string | null,
};

export type UserResponse = {
  __typename: "UserResponse",
  salutation?: string | null,
  username?: string | null,
  organizationId?: string | null,
  userId?: string | null,
  errorCode?: string | null,
};

export type RemoveUser = {
  userIds: Array< string | null >,
  organizationId: string,
};

export type RemoveUserResponse = {
  __typename: "RemoveUserResponse",
  roleId?: string | null,
  featureId?: string | null,
};

export type ServiceCategoryV3Input = {
  serviceCategoryName?: string | null,
  categoryDescription?: string | null,
  categoryImageUrl?: string | null,
  mdServiceType?: string | null,
  orgId?: string | null,
  taskName?: string | null,
  taskDescription?: string | null,
  taskImageUrl?: string | null,
  ISSACode?: string | null,
  rate?: number | null,
  unit?: string | null,
  mdAreaType?: string | null,
};

export type ServiceTask = {
  __typename: "ServiceTask",
  serviceCategoryId?: string | null,
  taskId?: string | null,
  mdAreaType?: string | null,
};

export type AddOrganizationUserInput = {
  salutation?: string | null,
  username?: string | null,
  organizationId: string,
  phoneNumber?: string | null,
  email?: string | null,
  userId: string,
  dataStatus?: string | null,
  roleId: string,
  teamId?: string | null,
};

export type AddOrUpdateUserForOrganization = {
  __typename: "AddOrUpdateUserForOrganization",
  userId?: string | null,
  salutation?: string | null,
  username?: string | null,
  email?: string | null,
  phoneNumber?: string | null,
  dataStatus?: string | null,
  teamId?: string | null,
  organizationId?: string | null,
};

export type AddVendorTeamRoleInput = {
  roleId?: string | null,
  roleName?: string | null,
  vendorId?: string | null,
};

export type VendorRoleOutput = {
  __typename: "VendorRoleOutput",
  roleId?: string | null,
  roleName?: string | null,
  vendorId?: string | null,
};

export type CreateOrUpdateJobAttachmentInput = {
  mdAttachmentType: string,
  attachmentId?: string | null,
  fileName: string,
  mdFileContext?: string | null,
  fileExtension?: string | null,
  url: string,
  fileHash?: string | null,
  tags?: string | null,
  comments?: string | null,
};

export type RemoveAssociateInput = {
  workOrderId: string,
  userInput?: Array< UserInputForRemovalInput | null > | null,
};

export type UserInputForRemovalInput = {
  userId?: string | null,
  comments?: string | null,
};

export type RemoveAssociateOutput = {
  __typename: "RemoveAssociateOutput",
  userId?: string | null,
  comments?: string | null,
};

export type RemoveOrUpdatePunchCardInput = {
  punchCardId: string,
  userId: string,
  punchType?: PunchType | null,
  punchTime?: string | null,
  punchComments?: string | null,
};

export type RegionInput = {
  regionName: string,
  organizationId: string,
  parentRegionId?: string | null,
};

export type Region = {
  __typename: "Region",
  regionId: string,
  regionName: string,
  organizationId: string,
  parentRegionId?: string | null,
};

export type CreateBidAttachmentInput = {
  mdAttachmentType: string,
  attachmentId?: string | null,
  fileName: string,
  mdFileContext?: string | null,
  fileExtension?: string | null,
  url: string,
  fileHash?: string | null,
  tags?: string | null,
  comments?: string | null,
};

export type BidAttachment = {
  __typename: "BidAttachment",
  attachmentId?: string | null,
  mdAttachmentType?: string | null,
  fileName?: string | null,
  mdFileContext?: string | null,
  fileExtension?: string | null,
  url?: string | null,
  dataStatus?: string | null,
  comments?: string | null,
  tags?: string | null,
};

export type CreateSubJobInput = {
  parentJobId: string,
  jobFacilityDto?: CreateJobFacilityInput | null,
  jobServiceInput?: CreateJobServiceInput | null,
  jobVendorsInput?: CreateJobVendorInput | null,
  jobFrequencies?: CreateJobFrequencyInput | null,
  jobUsers?: CreateJobUserInput | null,
  jobDiscussions?: CreateJobDiscussionInput | null,
  jobDueDates?: CreateJobDueDateInput | null,
  jobAttachments?: CreateOrUpdateJobAttachmentInput | null,
  jobFAQ?: CreateJobFAQInput | null,
  jobBid?: JobBidInput | null,
  allowVendorToReschedule?: boolean | null,
};

export type CreateJobUserInput = {
  userId: string,
  comments?: string | null,
};

export type CreateJobDiscussionInput = {
  author?: string | null,
  mdDiscussionType?: string | null,
  postedDate?: string | null,
  repliedToThread?: string | null,
  threadId?: string | null,
  tags?: string | null,
};

export type CreateJobFAQInput = {
  mdFAQId: string,
  FAQResponse?: string | null,
};

export type UserRoleScopeInput = {
  keyId: string,
  keyType: string,
  userId: string,
  organizationId: string,
  roleId: string,
  customerId?: string | null,
  mdCountryCode?: string | null,
  regionId?: string | null,
  subRegionId?: string | null,
  facilityId?: string | null,
};

export type UserRoleScope = {
  __typename: "UserRoleScope",
  userRoleScopeId?: string | null,
  keyId?: string | null,
  keyType?: string | null,
  userId?: string | null,
  organizationId?: string | null,
  roleId?: string | null,
  customerId?: string | null,
  mdCountryCode?: string | null,
  regionId?: string | null,
  subRegionId?: string | null,
  facilityId?: string | null,
  userRoleId?: string | null,
  keyName?: string | null,
  userName?: string | null,
  organizationName?: string | null,
  roleName?: string | null,
  customerName?: string | null,
  regionName?: string | null,
  subRegionName?: string | null,
  facilityName?: string | null,
  createdByName?: string | null,
  modifiedByName?: string | null,
  intAddressId?: string | null,
  jobDescription?: string | null,
  jobNumber?: string | null,
  customerNumber?: string | null,
};

export type GenerateTaskInput = {
  workOrderId: string,
  fromDate: string,
  toDate: string,
  associateId?: string | null,
  isJobAccepted?: string | null,
};

export type GenerateTaskOutput = {
  __typename: "GenerateTaskOutput",
  errorCode?: string | null,
};

export type processBidReviewInput = {
  comments?: string | null,
  IPAddress: string,
};

export type processBidReviewOutput = {
  __typename: "processBidReviewOutput",
  responseCode?: string | null,
};

export type generateInvoiceInput = {
  vendorName?: string | null,
  customerAddress?: string | null,
  vendorCompanyName: string,
  noteToCustomer?: string | null,
};

export type generateInvoiceOutput = {
  __typename: "generateInvoiceOutput",
  responseCode?: string | null,
};

export type CommentsInput = {
  createdBy: string,
  keyType: KeyType,
  comment?: string | null,
  commenterType?: CommenterType | null,
  dataStatus?: DataStatus | null,
};

export enum KeyType {
  JobBid = "JobBid",
  Jobs = "Jobs",
  JobAttachments = "JobAttachments",
  JobFacility = "JobFacility",
  JobServices = "JobServices",
  JobFrequency = "JobFrequency",
  BidAdditionalExpenses = "BidAdditionalExpenses",
}


export enum CommenterType {
  Customer = "Customer",
  Vendor = "Vendor",
  Associate = "Associate",
  DistrictManager = "DistrictManager",
  RegionalManager = "RegionalManager",
  Admin = "Admin",
  Supervisor = "Supervisor",
}


export type Comments = {
  __typename: "Comments",
  commentId?: string | null,
  keyId?: string | null,
  keyType?: string | null,
  comment?: string | null,
  commenterType?: string | null,
  dataStatus?: DataStatus | null,
};

export type DeleteUserInput = {
  organizationId: string,
  userIds?: Array< string | null > | null,
  shouldDeleteFromAuthService?: boolean | null,
};

export type DeleteUser = {
  __typename: "DeleteUser",
  userId?: string | null,
  username?: string | null,
  email?: string | null,
  signedUp?: boolean | null,
  phoneNumber?: string | null,
  dataStatus?: string | null,
  organizationId?: string | null,
  teamId?: string | null,
  errorCode?: string | null,
};

export type RescheduleWorkDayInput = {
  workDayId: string,
  newDate: string,
  startTime?: string | null,
  endTime?: string | null,
};

export type RescheduleWorkDayResponse = {
  __typename: "RescheduleWorkDayResponse",
  rescheduleSuccess?:  Array<RescheduleWorkDayEntity | null > | null,
  rescheduleFailure?:  Array<RescheduleFailure | null > | null,
};

export type RescheduleWorkDayEntity = {
  __typename: "RescheduleWorkDayEntity",
  facilityId?: string | null,
  workDayId?: string | null,
  workWeeks?: string | null,
  proofOfCompletion?: string | null,
  timeSheets?: string | null,
  workDate?: string | null,
  geolocation?: string | null,
  geofencing?: string | null,
  mandatoryTimeOfPresence?: string | null,
  calculatedTimeToComplete?: string | null,
  actualTimeToComplete?: string | null,
  routeTracking?: string | null,
  taskRemindersAndAlerts?: string | null,
  issuePrepaidCard?: string | null,
  communication?: string | null,
  reworkTasks?: string | null,
  ratings?: number | null,
  mdRatingTypes?: string | null,
  preShiftChecklistId?: string | null,
  workOrderId?: string | null,
};

export type RescheduleFailure = {
  __typename: "RescheduleFailure",
  workDayId: string,
  newDate: string,
  errorCode?: string | null,
};

export type ConversationInput = {
  conversationId?: string | null,
  jobId?: string | null,
  bidId?: string | null,
  bidVersion?: number | null,
  customerId?: string | null,
  vendorId?: string | null,
  organizationId?: string | null,
  message?: string | null,
  messageType?: MessageType | null,
};

export enum MessageType {
  Rejection = "Rejection",
  RevisionRequested = "RevisionRequested",
  Submitted = "Submitted",
  InApp = "InApp",
}


export type Conversations = {
  __typename: "Conversations",
  data?:  Array<ConversationsWithMessages | null > | null,
  metadata?: PaginationMetaData | null,
};

export type ConversationsWithMessages = {
  __typename: "ConversationsWithMessages",
  conversationId?: string | null,
  customerId?: string | null,
  vendorId?: string | null,
  bidId?: string | null,
  bidVersion?: number | null,
  jobId?: string | null,
  jobName?: string | null,
  customerName?: string | null,
  vendorName?: string | null,
  countOfMessages?: number | null,
  messages?:  Array<Messages | null > | null,
};

export type Messages = {
  __typename: "Messages",
  messageId?: string | null,
  message?: string | null,
  messageType?: MessageType | null,
  isRead?: boolean | null,
  organizationId?: string | null,
  userName?: string | null,
  userRoleId?: string | null,
  userRoleName?: string | null,
  conversationContext?: ConversationContext | null,
};

export enum ConversationContext {
  Job = "Job",
  Bid = "Bid",
}


export type PaginationMetaData = {
  __typename: "PaginationMetaData",
  totalCount?: number | null,
  pageIndex?: number | null,
  limit?: number | null,
};

export type DeleteTaskAttachmentInput = {
  deleteType?: DeleteType | null,
  attachmentIds: Array< string | null >,
};

export type UserAuthEventInput = {
  authEventType?: string | null,
  authEventTimeStamp?: string | null,
  userId?: string | null,
};

export type UserAuthEvent = {
  __typename: "UserAuthEvent",
  userAuthEventId?: string | null,
};

export type UserExist = {
  __typename: "UserExist",
  user?: CognitoUser | null,
};

export type CognitoUser = {
  __typename: "CognitoUser",
  status?: CognitoUserStatus | null,
  userId?: string | null,
  isEnabled?: boolean | null,
  isEmailVerified?: boolean | null,
};

export enum CognitoUserStatus {
  CONFIRMED = "CONFIRMED",
  UNCONFIRMED = "UNCONFIRMED",
  EXTERNAL_PROVIDER = "EXTERNAL_PROVIDER",
  FORCE_CHANGE_PASSWORD = "FORCE_CHANGE_PASSWORD",
  NEW_PASSWORD_REQUIRED = "NEW_PASSWORD_REQUIRED",
  RESET_REQUIRED = "RESET_REQUIRED",
}


export type Country = {
  __typename: "Country",
  countryName: string,
  countryCode: string,
  countryLegalName: string,
  currencyCode: string,
  currencySymbol: string,
  callingCode: string,
  capitalCity: string,
  UTCTimezone: string,
  localeCode: string,
  dayLightSavings: string,
  displaySeq: number,
};

export type State = {
  __typename: "State",
  stateName: string,
  stateCode: string,
  countryCode: string,
  capitalCity: string,
  UTCTimezone: string,
};

export type MasterDataWithCount = {
  __typename: "MasterDataWithCount",
  data?:  Array<MasterData | null > | null,
  metadata?: MetaDataForMasterData | null,
};

export type MetaDataForMasterData = {
  __typename: "MetaDataForMasterData",
  totalCount?: number | null,
  pageIndex?: number | null,
  limit?: number | null,
};

export type StandardRatesFilterInput = {
  keyId: string,
  customerId?: string | null,
  serviceCategoryId?: string | null,
  serviceCategoryName?: string | null,
  regionId?: string | null,
  subregionId?: string | null,
  stateCode?: string | null,
};

export type StandardRates = {
  __typename: "StandardRates",
  standardRateId: string,
  keyId: string,
  keyType: string,
  customerId?: string | null,
  serviceCategoryId?: string | null,
  serviceCategoryName?: string | null,
  mdCountryCode?: string | null,
  regionId?: string | null,
  subregionId?: string | null,
  stateCode?: string | null,
  city?: string | null,
  postalCode?: string | null,
  currency?: string | null,
  unionBlendedRate?: string | null,
  nonUnionBlendedRate?: string | null,
};

export type ServiceCategoryV3WithCount = {
  __typename: "ServiceCategoryV3WithCount",
  data?:  Array<ServiceCategoryV3 | null > | null,
  metadata?: MetaDataForMasterData | null,
};

export type ServiceCategoryV3 = {
  __typename: "ServiceCategoryV3",
  taskId: string,
  serviceCategoryId: string,
  serviceCategoryName: string,
  categoryImageUrl?: string | null,
  categoryDescription?: string | null,
  orgId: string,
  mdServiceType: string,
  taskName: string,
  taskImageUrl?: string | null,
  taskDescription?: string | null,
  unit?: string | null,
  mdAreaType?: string | null,
  modifiedBy?: string | null,
  createdBy?: string | null,
};

export type CustomerWithCount = {
  __typename: "CustomerWithCount",
  data?:  Array<Customer | null > | null,
  metadata?: PaginationMetaData | null,
};

export type FacilitiesWithCount = {
  __typename: "FacilitiesWithCount",
  data?:  Array<Facility | null > | null,
  metadata?: PaginationMetaData | null,
};

export type OtherVendorSearchInput = {
  phone?: string | null,
  taxId?: string | null,
  doingBusinessAs?: string | null,
};

export type VendorWithAddress = {
  __typename: "VendorWithAddress",
  vendorId: string,
  vendorCompanyName?: string | null,
  vendorCompanyType?: string | null,
  doingBusinessAs?: string | null,
  SSID?: string | null,
  taxId?: string | null,
  websiteUrl?: string | null,
  logoUrl?: string | null,
  referralSource?: string | null,
  phone?: string | null,
  email?: string | null,
  dataStatus: string,
  modifiedOn?: string | null,
  vendorRating?: number | null,
  vendorAddress?:  Array<VendorAddress | null > | null,
};

export type VendorsWithCount = {
  __typename: "VendorsWithCount",
  data?:  Array<Vendor | null > | null,
  metadata?: PaginationMetaData | null,
};

export type VendorServiceLocationWithCount = {
  __typename: "VendorServiceLocationWithCount",
  data?:  Array<VendorServiceLocation | null > | null,
  metadata?: PaginationMetaData | null,
};

export type VendorTeamInterestedAssociateList = {
  __typename: "VendorTeamInterestedAssociateList",
  associateId: string,
  title?: string | null,
  name?: string | null,
  emergencyContactNumber?: string | null,
  logoUrl?: string | null,
  availableForNightShifts?: boolean | null,
  isFavorite?: boolean | null,
  transportationType?: boolean | null,
  priorExperience?: boolean | null,
  supervisorExperience?: boolean | null,
  age?: boolean | null,
  availableForWeekends?: boolean | null,
  emailId?: string | null,
  associateAddress?:  Array<AssociatePostalCode | null > | null,
};

export type AssociatePostalCode = {
  __typename: "AssociatePostalCode",
  postalCode?: string | null,
};

export type VendorServiceOfferedWithCount = {
  __typename: "VendorServiceOfferedWithCount",
  data?:  Array<VendorService | null > | null,
  metadata?: PaginationMetaData | null,
};

export type VendorService = {
  __typename: "VendorService",
  vendorServiceOfferedId: string,
  serviceCategoryId: string,
  serviceDescription: string,
  serviceSubCategoryId: string,
};

export type VendorServiceSummary = {
  __typename: "VendorServiceSummary",
  imageUrl?: string | null,
  serviceCategoryName?: string | null,
  subCategoryCount: number,
  mdServiceType?: string | null,
};

export type AssociateSkillsWithCount = {
  __typename: "AssociateSkillsWithCount",
  data?:  Array<AssociateSkillWithID | null > | null,
  metadata?: PaginationMetaData | null,
};

export type AssociateSkillWithID = {
  __typename: "AssociateSkillWithID",
  skillId?: string | null,
  skillCategoryId?: string | null,
  skillSubCategoryId?: string | null,
  skillDescription?: string | null,
  associateSkillsId?: string | null,
};

export type AssociateShiftPreferencesWithCount = {
  __typename: "AssociateShiftPreferencesWithCount",
  data?:  Array<AssociateShiftPreferences | null > | null,
  metadata?: PaginationMetaData | null,
};

export type AssociateSkillsSummaryWithCount = {
  __typename: "AssociateSkillsSummaryWithCount",
  data?:  Array<AssociateSkillsSummary | null > | null,
  metadata?: PaginationMetaData | null,
};

export type AssociateSkillsSummary = {
  __typename: "AssociateSkillsSummary",
  skillCategoryId?: string | null,
  imageUrl?: string | null,
  skillCategoryName?: string | null,
  subCategoryCount: number,
};

export type AvailableWorkDayDates = {
  __typename: "AvailableWorkDayDates",
  workDayId?: string | null,
  workDate?: string | null,
  availableDates?: Array< string | null > | null,
  associates?:  Array<AvailableAssociates | null > | null,
  errorCode?: string | null,
  isReschedulable?: boolean | null,
};

export type AvailableAssociates = {
  __typename: "AvailableAssociates",
  associateId?: string | null,
  availableDates?: Array< string | null > | null,
};

export type WorkDay = {
  __typename: "WorkDay",
  isReworkDay?: boolean | null,
  isShiftRescheduled?: boolean | null,
  facilityId?: string | null,
  workDayId?: string | null,
  workWeeks?: string | null,
  proofOfCompletion?: string | null,
  timeSheets?: string | null,
  workDate?: string | null,
  geolocation?: string | null,
  geofencing?: string | null,
  mandatoryTimeOfPresence?: string | null,
  calculatedTimeToComplete?: string | null,
  actualTimeToComplete?: string | null,
  routeTracking?: string | null,
  taskRemindersAndAlerts?: string | null,
  issuePrepaidCard?: string | null,
  communication?: string | null,
  reworkTasks?: string | null,
  ratings?: number | null,
  mdRatingTypes?: string | null,
  preShiftChecklistId?: string | null,
  workOrderId?: string | null,
};

export type JobSummaryV2 = {
  __typename: "JobSummaryV2",
  jobId: string,
  actualId?: string | null,
  jobName: string,
  specialInstructions?: string | null,
  startDate?: string | null,
  stopDate?: string | null,
  jobType?: string | null,
  customerId?: string | null,
  jobCustodian?: string | null,
  dataStatus?: JobStatus | null,
  jobFacilities?:  Array<JobFacility | null > | null,
  jobServices?:  Array<JobService | null > | null,
  jobFrequencies?:  Array<JobFrequencyForJobSummary | null > | null,
  jobVendors?:  Array<JobVendor | null > | null,
  jobDueDates?:  Array<JobDueDate | null > | null,
  jobAttachments?:  Array<JobAttachment | null > | null,
  subJobs?:  Array<SubJobsForJobSummary | null > | null,
  isPaid?: boolean | null,
  jobRating?: number | null,
};

export type JobFrequencyForJobSummary = {
  __typename: "JobFrequencyForJobSummary",
  mdShiftType?: string | null,
  mdScheduleType?: string | null,
  mdServiceId?: Array< string | null > | null,
  scheduleDays?: string | null,
  fromTime?: string | null,
  toTime?: string | null,
  cronExpression?: string | null,
  repeatitions?: number | null,
  repetition?: number | null,
};

export type SubJobsForJobSummary = {
  __typename: "SubJobsForJobSummary",
  jobId?: string | null,
  jobName?: string | null,
  jobType?: string | null,
  jobServices?:  Array<JobService | null > | null,
  jobVendors?:  Array<JobVendor | null > | null,
  jobBids?:  Array<JobBidsForPrimeVendor | null > | null,
};

export type JobBidsForPrimeVendor = {
  __typename: "JobBidsForPrimeVendor",
  bidId?: string | null,
  vendorId?: string | null,
  vendorName?: string | null,
  vendorEmail?: string | null,
  mdFrequencyType?: MdFrequencyType | null,
  bidName?: string | null,
  totalCost?: number | null,
  profitMargin?: number | null,
  isFlatMargin?: YesNoType | null,
  finalQuote?: number | null,
  comments?: string | null,
  bidEstimationType?: BidType | null,
  bidCreationDate?: string | null,
  draftStep?: BidDraftStep | null,
  draftStatus?: DraftStatus | null,
  dataStatus?: BidStatus | null,
  bidSubmissionDate?: string | null,
  bidVersionCreatedDate?: string | null,
  bidVersion?: number | null,
};

export type JobsV2WithCount = {
  __typename: "JobsV2WithCount",
  data?:  Array<JobWithDueDates | null > | null,
  metadata?: PaginationMetaData | null,
};

export type JobWithDueDates = {
  __typename: "JobWithDueDates",
  jobId: string,
  actualId?: string | null,
  jobName: string,
  startDate?: string | null,
  stopDate?: string | null,
  dataStatus?: string | null,
  jobType?: string | null,
  specialInstructions?: string | null,
  draftStep?: DraftStep | null,
  draftStatus?: DraftStatus | null,
  createdOn?: string | null,
  createdBy?: string | null,
  modifiedOn?: string | null,
  modifiedBy?: string | null,
  createdByName?: string | null,
  modifiedByName?: string | null,
  bidCount?: number | null,
  jobCustodian?: string | null,
  intId?: string | null,
  allowVendorToReschedule?: boolean | null,
  jobFacilities?:  Array<JobFacilityWithOnlyId | null > | null,
  jobDueDates?:  Array<JobDueDate | null > | null,
  jobBids?:  Array<JobBidVendor | null > | null,
  jobFrequencies?:  Array<JobFrequency | null > | null,
  jobAttachments?:  Array<JobAttachment | null > | null,
};

export type JobFacilityWithOnlyId = {
  __typename: "JobFacilityWithOnlyId",
  facilityId: string,
};

export type JobBidVendor = {
  __typename: "JobBidVendor",
  vendorId?: string | null,
  vendorName?: string | null,
};

export enum ServiceMeasure {
  S = "S",
  M = "M",
  L = "L",
}


export type RangeInput = {
  fromPercentage?: number | null,
  toPercentage?: number | null,
};

export enum ScheduleType {
  Daily = "Daily",
  Weekly = "Weekly",
  Monthly = "Monthly",
}


export enum JobDuration {
  LT_1_MONTH = "LT_1_MONTH",
  LT_3_MONTH = "LT_3_MONTH",
  LT_6_MONTH = "LT_6_MONTH",
  LT_12_MONTH = "LT_12_MONTH",
  GT_1_YEAR = "GT_1_YEAR",
}


export type JobRecommendations = {
  __typename: "JobRecommendations",
  serviceLocations?:  Array<VendorServiceLocation | null > | null,
  servicesOffered?:  Array<VendorService | null > | null,
  jobsWithinServiceLocations?:  Array<JobId | null > | null,
  matchedJobs?: JobRecommendationWithCount | null,
  matchedBids?:  Array<JobBid | null > | null,
  matchedFacilities?:  Array<Facility | null > | null,
};

export type JobId = {
  __typename: "JobId",
  id?: string | null,
};

export type JobRecommendationWithCount = {
  __typename: "JobRecommendationWithCount",
  data?:  Array<JobRecommendation | null > | null,
  metadata?: PaginationMetaData | null,
};

export type JobRecommendation = {
  __typename: "JobRecommendation",
  jobId: string,
  jobName: string,
  specialInstructions?: string | null,
  startDate?: string | null,
  stopDate?: string | null,
  jobFacility?: JobFacility | null,
  jobSize?: string | null,
  dataStatus?: string | null,
  jobType?: string | null,
  createdOn?: string | null,
  jobServices?:  Array<JobService | null > | null,
  jobFrequencies?:  Array<JobFrequency | null > | null,
  jobVendors?:  Array<JobVendor | null > | null,
  jobDueDates?:  Array<JobDueDate | null > | null,
  percentageMatch?: number | null,
};

export type JobBids = {
  __typename: "JobBids",
  jobBidId?: string | null,
  jobId?: string | null,
  vendorId?: string | null,
  vendorName?: string | null,
  bidName?: string | null,
  vendorEmail?: string | null,
  totalCost?: number | null,
  mdFrequencyType?: MdFrequencyType | null,
  comments?: string | null,
  submittedBy?: string | null,
  bidSubmissionDate?: string | null,
  vendorBidId?: string | null,
  vendorBidIdRecSeq?: number | null,
  jobEstimations?:  Array<JobEstimations | null > | null,
  dataStatus?: string | null,
  bidVersion?: number | null,
  revisedJobBids?:  Array<RevisedJobBids | null > | null,
  bidComments?:  Array<BidComments | null > | null,
};

export type JobEstimations = {
  __typename: "JobEstimations",
  jobEstimationId?: string | null,
  vendorEstimationId?: string | null,
  jobId?: string | null,
  jobBidId?: string | null,
  mdCategoryId?: string | null,
  quantity?: number | null,
  rate?: number | null,
  quoteAmount?: number | null,
  dataStatus?: string | null,
  bidVersion?: number | null,
};

export type RevisedJobBids = {
  __typename: "RevisedJobBids",
  jobBidId?: string | null,
  jobId?: string | null,
  vendorId?: string | null,
  vendorName?: string | null,
  bidName?: string | null,
  vendorEmail?: string | null,
  totalCost?: number | null,
  mdFrequencyType?: MdFrequencyType | null,
  comments?: string | null,
  submittedBy?: string | null,
  bidSubmissionDate?: string | null,
  vendorBidId?: string | null,
  vendorBidIdRecSeq?: number | null,
  jobEstimations?:  Array<JobEstimations | null > | null,
  dataStatus?: string | null,
  bidVersion?: number | null,
};

export type BidComments = {
  __typename: "BidComments",
  commentId?: string | null,
  comment?: string | null,
  commentBy?: string | null,
  commentByName?: string | null,
  commenterRoleId?: string | null,
  commenterRoleName?: string | null,
  entityType?: EntityType | null,
  organizationId?: string | null,
  commentDate?: string | null,
  vendorBidId?: string | null,
  jobId?: string | null,
  bidVersion?: number | null,
  actionType?: ActionType | null,
};

export enum ActionType {
  Rejection = "Rejection",
  RevisionRequested = "RevisionRequested",
  Submitted = "Submitted",
}


export type JobBidSummary = {
  __typename: "JobBidSummary",
  vendorId: string,
  vendorName?: string | null,
  vendorEmail?: string | null,
  bidId: string,
  jobId: string,
  bidName: string,
  totalCost: number,
  comments?: string | null,
  // this is not using, the key being used is bidComments
  profitMargin: number,
  isFlatMargin?: YesNoType | null,
  finalQuote: number,
  bidEstimationType?: BidType | null,
  bidCreationDate: string,
  draftStep?: BidDraftStep | null,
  draftStatus?: DraftStatus | null,
  dataStatus: BidStatus,
  bidTemplate: string,
  bidCreatedFromSample: string,
  bidVersion: number,
  bidVersionCreatedDate: string,
  submittedBy: string,
  bidSubmissionDate: string,
  bidSimpleEstimation?:  Array<JobBidEstimation | null > | null,
  bidAdditionalExpenses?:  Array<JobBidAdditionalExpenses | null > | null,
  mdFrequencyType?: MdFrequencyType | null,
  customerId?: string | null,
  customerName?: string | null,
  isCustomerApprovalAvailable?: boolean | null,
  transactionStatus?: string | null,
  bidComments?:  Array<BidComments | null > | null,
};

export type WorkOrderTeamWithAssignment = {
  __typename: "WorkOrderTeamWithAssignment",
  teamId: string,
  vendorId?: string | null,
  userId?: string | null,
  roleId?: string | null,
  isSupervisor?: boolean | null,
  workOrderId?: string | null,
  taskAssignments?:  Array<TaskAssignment | null > | null,
  associateStartDate?: string | null,
  associateEndDate?: string | null,
  cognitoUserId?: string | null,
  workDayId?: string | null,
  workDayTeam?: WorkDayTeamForWorkOrderTeamWithAssignment | null,
};

export type WorkDayTeamForWorkOrderTeamWithAssignment = {
  __typename: "WorkDayTeamForWorkOrderTeamWithAssignment",
  workOrderId?: string | null,
  workDayId?: string | null,
  facilityId?: string | null,
  associateId?: string | null,
};

export type WorkOrderWithCount = {
  __typename: "WorkOrderWithCount",
  data?:  Array<WorkOrder | null > | null,
  metadata?: PaginationMetaData | null,
};

export type ShiftDetailsWithWorkOrder = {
  __typename: "ShiftDetailsWithWorkOrder",
  shiftDetailId: string,
  startTime: string,
  endTime: string,
  workOrderId: string,
  punchInGraceMinutes?: number | null,
  punchOutGraceMinutes?: number | null,
  workOrder?: ShiftDetailsWorkOrder | null,
};

export type ShiftDetailsWorkOrder = {
  __typename: "ShiftDetailsWorkOrder",
  jobStartDate?: string | null,
  jobEndDate?: string | null,
  actualStartDate?: string | null,
  actualEndDate?: string | null,
};

export type TasksV2 = {
  __typename: "TasksV2",
  mdServiceType?: string | null,
  serviceCategoryName?: string | null,
  categoryDescription?: string | null,
  createdBy?: string | null,
  mdAreaType?: string | null,
  orgId?: string | null,
  mdServiceId?: string | null,
  categoryImageUrl?: string | null,
  tasks?:  Array<TaskV2Details | null > | null,
};

export type TaskV2Details = {
  __typename: "TaskV2Details",
  taskDescription?: string | null,
  taskId?: string | null,
  taskNumber?: number | null,
  mdTaskId?: string | null,
  taskName?: string | null,
  unit?: string | null,
  isProofOfCompletionRequired?: string | null,
  status?: string | null,
  actualStartTime?: string | null,
  actualEndTime?: string | null,
  duration?: number | null,
  associate?: string | null,
  salutation?: string | null,
  associateId?: string | null,
  workDayId?: string | null,
  workOrderId?: string | null,
  markedAsDone?: string | null,
  markedBy?: string | null,
  markedOn?: string | null,
  reworkTaskId?: string | null,
  occurrenceDate?: string | null,
  suggestedReworkDate?: string | null,
  suggestedScheduleType?: TaskScheduleType | null,
  taskAttachments?:  Array<TaskV2Attachment | null > | null,
  nextAvailableFiveWorkdays?:  Array<WorkDayDetails | null > | null,
  nextAvailableWorkDay?: WorkDayDetails | null,
  isPartiallyCompleted?: boolean | null,
  isCurrentShiftAvailable?: boolean | null,
  reworkTasks?:  Array<ReworkTasksV2Details | null > | null,
  createdOn?: string | null,
  modifiedOn?: string | null,
  approvalSaga?:  Array<ApprovalSagaData | null > | null,
  taskAttachmentSaga?:  Array<TaskV2Attachment | null > | null,
};

export type TaskV2Attachment = {
  __typename: "TaskV2Attachment",
  attachmentId?: string | null,
  attachmentStatus?: AttachmentStatus | null,
  mdAttachmentType?: string | null,
  fileName?: string | null,
  mdFileContext?: string | null,
  fileExtension?: string | null,
  url?: string | null,
  fileHash?: string | null,
  comments?: string | null,
  taskId?: string | null,
};

export type WorkDayDetails = {
  __typename: "WorkDayDetails",
  workDate?: string | null,
  workDayId?: string | null,
};

export type ReworkTasksV2Details = {
  __typename: "ReworkTasksV2Details",
  taskId?: string | null,
  taskNumber?: number | null,
  occurrenceDate?: string | null,
  suggestedReworkDate?: string | null,
  suggestedScheduleType?: TaskScheduleType | null,
  markedAsDone?: string | null,
  markedBy?: string | null,
  reworkTaskId?: string | null,
  markedOn?: string | null,
  facilityId?: string | null,
  facilityAreaServiceId?: string | null,
  taskStatus?: string | null,
  mdServiceDescription?: string | null,
  plannedStartTime?: string | null,
  plannedEndTime?: string | null,
  actualEffort?: string | null,
  actualStartTime?: string | null,
  actualEndTime?: string | null,
  associateId?: string | null,
  workOrderId?: string | null,
  workDayId?: string | null,
  taskScheduleId?: string | null,
  taskSagaId?: string | null,
  mdTaskId?: string | null,
  taskName?: string | null,
  taskDescription?: string | null,
  taskImageUrl?: string | null,
  ISSACode?: string | null,
  rate?: string | null,
  unit?: string | null,
  isPartiallyCompleted?: boolean | null,
  taskSchedule?: TaskScheduleForTasksV2 | null,
  taskAssignment?:  Array<TaskAssignmentForTasksV2 | null > | null,
  taskAttachment?:  Array<Attachment | null > | null,
  approvalSaga?:  Array<ApprovalSagaData | null > | null,
  attachmentLinks?:  Array<ApprovalAttachmentsLink | null > | null,
};

export type TaskScheduleForTasksV2 = {
  __typename: "TaskScheduleForTasksV2",
  mdTaskId?: string | null,
  noOfOccurrences?: string | null,
  taskScheduleId?: string | null,
  isProofOfCompletionRequired?: string | null,
  duration?: string | null,
};

export type TaskAssignmentForTasksV2 = {
  __typename: "TaskAssignmentForTasksV2",
  taskId?: string | null,
  assignedBy?: string | null,
  associateId?: string | null,
  assignedOn?: string | null,
};

export type ApprovalSagaData = {
  __typename: "ApprovalSagaData",
  approvalType?: ApprovalStatus | null,
  actionedOn?: string | null,
  actionedById?: string | null,
  actionedByName?: string | null,
  salutation?: string | null,
  actionedByRole?: RoleDetails | null,
  comment?: string | null,
  taskId?: string | null,
  approverType?: ApproverType | null,
};

export type RoleDetails = {
  __typename: "RoleDetails",
  roleId?: string | null,
  roleName?: string | null,
};

export type ApprovalAttachmentsLink = {
  __typename: "ApprovalAttachmentsLink",
  attachmentId?: string | null,
  link?: string | null,
};

export type VendorJobScheduleItem = {
  __typename: "VendorJobScheduleItem",
  vendorId: string,
  teamId: string,
  userId: string,
  workOrder: VendorJobScheduleWorkOrder,
};

export type VendorJobScheduleWorkOrder = {
  __typename: "VendorJobScheduleWorkOrder",
  workOrderId: string,
  customerId: string,
  vendorId: string,
  jobId: string,
  status: WorkOrderStatus,
  jobBidSummary: VendorWorkOrderJobBidSummary,
  shiftDetails:  Array<VendorWorkOrderShiftDetails | null >,
  facilities:  Array<VendorWorkOrderFacilities | null >,
  workDay:  Array<VendorWorkOrderWorkDay | null >,
};

export type VendorWorkOrderJobBidSummary = {
  __typename: "VendorWorkOrderJobBidSummary",
  jobName: string,
  startDate: string,
  stopDate: string,
};

export type VendorWorkOrderShiftDetails = {
  __typename: "VendorWorkOrderShiftDetails",
  startTime: string,
  endTime: string,
};

export type VendorWorkOrderFacilities = {
  __typename: "VendorWorkOrderFacilities",
  facilityId: string,
};

export type VendorWorkOrderWorkDay = {
  __typename: "VendorWorkOrderWorkDay",
  workDate: string,
  workDayId: string,
  tasks:  Array<VendorWorkOrderWorkDayTask | null >,
  punchCard:  Array<VendorWorkOrderWorkDayPunchCard | null >,
};

export type VendorWorkOrderWorkDayTask = {
  __typename: "VendorWorkOrderWorkDayTask",
  taskId: string,
  taskScheduleId: string,
  occurrenceDate: string,
  markedAsDone: string,
  taskStatus: string,
};

export type VendorWorkOrderWorkDayPunchCard = {
  __typename: "VendorWorkOrderWorkDayPunchCard",
  punchTime: string,
  punchType: string,
};

export type CustomerJobScheduleItem = {
  __typename: "CustomerJobScheduleItem",
  workOrderId: string,
  customerId: string,
  vendorId: string,
  jobId: string,
  status: WorkOrderStatus,
  workOrderTeams:  Array<CustomerJobScheduleWorkOrderTeams | null >,
  jobBidSummary: VendorWorkOrderJobBidSummary,
  shiftDetails:  Array<VendorWorkOrderShiftDetails | null >,
  facilities:  Array<VendorWorkOrderFacilities | null >,
  workDay:  Array<VendorWorkOrderWorkDay | null >,
};

export type CustomerJobScheduleWorkOrderTeams = {
  __typename: "CustomerJobScheduleWorkOrderTeams",
  vendorId: string,
  teamId: string,
  userId: string,
};

export type VendorCompletedJobShiftItem = {
  __typename: "VendorCompletedJobShiftItem",
  vendorId: string,
  userId: string,
  roleId: string,
  workOrder:  Array<VendorCompletedShiftWorkOrder | null >,
};

export type VendorCompletedShiftWorkOrder = {
  __typename: "VendorCompletedShiftWorkOrder",
  workOrderId: string,
  customerId: string,
  shiftDetails:  Array<VendorWorkOrderShiftDetails | null >,
  facilities:  Array<VendorWorkOrderFacilities | null >,
};

export type MatchedVendorsWithCount = {
  __typename: "MatchedVendorsWithCount",
  data?:  Array<MatchedVendor | null > | null,
  metadata?: PaginationMetaData | null,
};

export type MatchedVendor = {
  __typename: "MatchedVendor",
  vendorId?: string | null,
  vendorCompanyName?: string | null,
  doingBusinessAs?: string | null,
  email?: string | null,
  phone?: string | null,
  dataStatus?: string | null,
  address?:  Array<MatchedVendorAddress | null > | null,
  serviceLocations?:  Array<MatchedVendorServiceLocation | null > | null,
  servicesOffered?:  Array<MatchedVendorServicesOffered | null > | null,
};

export type MatchedVendorAddress = {
  __typename: "MatchedVendorAddress",
  addressName?: string | null,
  addressLine1?: string | null,
  addressLine2?: string | null,
  addressLine3?: string | null,
  city?: string | null,
  stateCode?: string | null,
  postalCode?: string | null,
  mdCountryCode?: string | null,
};

export type MatchedVendorServiceLocation = {
  __typename: "MatchedVendorServiceLocation",
  areaName?: string | null,
  locationId?: string | null,
};

export type MatchedVendorServicesOffered = {
  __typename: "MatchedVendorServicesOffered",
  serviceCategoryId?: string | null,
  serviceSubCategoryId?: string | null,
  serviceDescription?: string | null,
};

export enum IssueType {
  PreShiftChecklist = "PreShiftChecklist",
  WorkDay = "WorkDay",
  PunchCard = "PunchCard",
  FacilityAccess = "FacilityAccess",
}


export type IssueTemplate = {
  __typename: "IssueTemplate",
  templateId: string,
  name?: string | null,
  templateType: IssueType,
  fieldConfiguration?: string | null,
  version?: string | null,
};

export type DocumentStatus = {
  __typename: "DocumentStatus",
  request_status: string,
  action_status: string,
};

export type UserForUserManagementForPagination = {
  __typename: "UserForUserManagementForPagination",
  data?:  Array<UserForUserManagement | null > | null,
  metadata?: PaginationMetaData | null,
};

export type UserForUserManagement = {
  __typename: "UserForUserManagement",
  userId?: string | null,
  salutation?: string | null,
  username?: string | null,
  email?: string | null,
  signedUp?: boolean | null,
  phoneNumber?: string | null,
  dataStatus?: string | null,
  organizationId?: string | null,
  teamId?: string | null,
  cognitoUserId?: string | null,
  intId?: string | null,
  roles?:  Array<Roles | null > | null,
};

export type Roles = {
  __typename: "Roles",
  roleId?: string | null,
  roleName?: string | null,
  permissions?:  Array<Permissions | null > | null,
};

export type Permissions = {
  __typename: "Permissions",
  permissionId?: string | null,
  permissionName?: string | null,
  permissionKey?: string | null,
  channel?: string | null,
};

export type UserOrgDetails = {
  __typename: "UserOrgDetails",
  userId?: string | null,
  salutation?: string | null,
  username?: string | null,
  organization?: UserOrganization | null,
};

export type UserOrganization = {
  __typename: "UserOrganization",
  organizationName?: string | null,
  recStatus?: string | null,
  dataStatus?: string | null,
  organizationId?: string | null,
};

export type UserPermissions = {
  __typename: "UserPermissions",
  userId?: string | null,
  roles?:  Array<Roles | null > | null,
};

export type OrgRolePermissions = {
  __typename: "OrgRolePermissions",
  organizationId?: string | null,
  roles?:  Array<Roles | null > | null,
};

export type VendorTeamJoiningStatusResponse = {
  __typename: "VendorTeamJoiningStatusResponse",
  dataStatus?: string | null,
  organization?: OrganizationData | null,
};

export type OrganizationData = {
  __typename: "OrganizationData",
  organizationId?: string | null,
  organizationName?: string | null,
};

export type VendorsId = {
  __typename: "VendorsId",
  vendorId?: string | null,
  vendorEmail?: string | null,
};

export type AllJobAttachmentInput = {
  jobIds?: Array< string | null > | null,
  facilityId?: string | null,
  tags?: string | null,
};

export type AllBidAttachmentsInput = {
  bidIds?: Array< string | null > | null,
  vendorId?: string | null,
  tags?: string | null,
  attachmentsBasedOnVersionsIndicator?: BooleanType | null,
};

export enum Family {
  JobAttachments = "JobAttachments",
  BidAttachments = "BidAttachments",
}


export type Tags = {
  __typename: "Tags",
  owner?: string | null,
  type?: string | null,
  value?: string | null,
  id?: string | null,
  family?: string | null,
};

export type UserManagersWithCount = {
  __typename: "UserManagersWithCount",
  data?:  Array<UserManagers | null > | null,
  metadata?: PaginationMetaData | null,
};

export type UserManagers = {
  __typename: "UserManagers",
  userId?: string | null,
  managerId?: string | null,
  dataStatus?: string | null,
  regionId?: string | null,
  manager?: Manager | null,
  user?: UserForUserManagers | null,
};

export type Manager = {
  __typename: "Manager",
  managerName?: string | null,
  email?: string | null,
  phoneNumber?: string | null,
  roles?:  Array<RolesForUserManagers | null > | null,
  cognitoUserId?: string | null,
  signedUp?: boolean | null,
};

export type RolesForUserManagers = {
  __typename: "RolesForUserManagers",
  roleId?: string | null,
  roleName?: string | null,
};

export type UserForUserManagers = {
  __typename: "UserForUserManagers",
  userName?: string | null,
  email?: string | null,
  phoneNumber?: string | null,
  roles?:  Array<RolesForUserManagers | null > | null,
  cognitoUserId?: string | null,
  signedUp?: boolean | null,
};

export type UserRolesAndScopes = {
  __typename: "UserRolesAndScopes",
  userId?: string | null,
  errorCode?: string | null,
  canCreateOneTimeJob?: boolean | null,
  roles?:  Array<Roles | null > | null,
  userScopes?:  Array<UserScopes | null > | null,
};

export type UserScopes = {
  __typename: "UserScopes",
  roleId: string,
  customerId: string,
  mdCountryCode?: string | null,
  regionId?: string | null,
  subregionId?: string | null,
  facilityId?: string | null,
};

export type ReferenceJobs = {
  __typename: "ReferenceJobs",
  addressId?: string | null,
  addressLine1?: string | null,
  addressLine2?: string | null,
  addressLine3?: string | null,
  addressName?: string | null,
  latitude?: number | null,
  longitude?: number | null,
  city?: string | null,
  county?: string | null,
  customerId?: string | null,
  facilityId?: string | null,
  intCustomerNumber?: string | null,
  jobDescription?: string | null,
  jobNumber?: string | null,
  mdCountryCode?: string | null,
  postalCode?: string | null,
  regionId?: string | null,
  stateCode?: string | null,
  subRegionId?: string | null,
  userId?: string | null,
  vendorId?: string | null,
  roleId?: string | null,
  unionIndicator?: string | null,
  pti?: string | null,
};

export type JobsForPrimeVendorInput = {
  userId?: string | null,
  facilityId?: string | null,
  mdDueType?: string | null,
  dueDate?: string | null,
  dataStatus?: string | null,
  limit?: number | null,
  pageNumber?: number | null,
  isDraft?: BooleanType | null,
  isOneTime?: BooleanType | null,
  bidStatus?: string | null,
  searchString?: string | null,
  jobIds?: Array< string | null > | null,
  mdVendorType?: string | null,
  sortBy?: string | null,
  sortOrder?: string | null,
};

export type JobsForPrimeVendorWithCount = {
  __typename: "JobsForPrimeVendorWithCount",
  data?:  Array<JobsForPrimeVendor | null > | null,
  metadata?: PaginationMetaData | null,
};

export type JobsForPrimeVendor = {
  __typename: "JobsForPrimeVendor",
  jobId: string,
  jobName: string,
  startDate?: string | null,
  stopDate?: string | null,
  dataStatus?: string | null,
  jobType?: string | null,
  specialInstructions?: string | null,
  draftStep?: DraftStep | null,
  draftStatus?: DraftStatus | null,
  createdOn?: string | null,
  createdBy?: string | null,
  modifiedOn?: string | null,
  modifiedBy?: string | null,
  bidCount?: number | null,
  customerId?: string | null,
  customerName?: string | null,
  customerEmail?: string | null,
  parentJobId?: string | null,
  jobCustodian?: string | null,
  intId?: string | null,
  jobFacilities?:  Array<JobFacilitiesForPrimeVendor | null > | null,
  jobDueDates?:  Array<JobDueDate | null > | null,
  jobBids?:  Array<JobBidsForPrimeVendor | null > | null,
  jobVendors?:  Array<JobVendorsForPrimeVendor | null > | null,
  jobAttachments?:  Array<JobAttachment | null > | null,
  jobServices?:  Array<JobServicesForPrimeVendor | null > | null,
  jobFrequencies?:  Array<JobFrequency | null > | null,
  subJobs?:  Array<SubJobsForPrimeVendor | null > | null,
};

export type JobFacilitiesForPrimeVendor = {
  __typename: "JobFacilitiesForPrimeVendor",
  facilityId?: string | null,
  facilityAddress?: string | null,
};

export type JobVendorsForPrimeVendor = {
  __typename: "JobVendorsForPrimeVendor",
  vendorId?: string | null,
  mdVendorType?: string | null,
  hasSignedUp?: boolean | null,
};

export type JobServicesForPrimeVendor = {
  __typename: "JobServicesForPrimeVendor",
  mdServiceId?: string | null,
  mdServiceUnits?: string | null,
  serviceMeasure?: number | null,
  actualMdServiceUnits?: number | null,
  actualServiceMeasure?: string | null,
};

export type SubJobsForPrimeVendor = {
  __typename: "SubJobsForPrimeVendor",
  jobId?: string | null,
  jobName?: string | null,
  startDate?: string | null,
  stopDate?: string | null,
  dataStatus?: string | null,
  jobType?: string | null,
  specialInstructions?: string | null,
  draftStep?: DraftStep | null,
  draftStatus?: DraftStatus | null,
  createdOn?: string | null,
  createdBy?: string | null,
  modifiedOn?: string | null,
  modifiedBy?: string | null,
  bidCount?: number | null,
  customerId?: string | null,
  customerName?: string | null,
  customerEmail?: string | null,
  parentJobId?: string | null,
  jobFacilities?:  Array<JobFacilitiesForPrimeVendor | null > | null,
  jobDueDates?:  Array<JobDueDate | null > | null,
  jobBids?:  Array<JobBidsForPrimeVendor | null > | null,
  jobFrequencies?:  Array<JobFrequency | null > | null,
  jobVendors?:  Array<JobVendorsForPrimeVendor | null > | null,
  jobAttachments?:  Array<JobAttachment | null > | null,
  jobServices?:  Array<JobServicesForPrimeVendor | null > | null,
};

export type GetUserRoleScopesInput = {
  userName?: string | null,
  roleName?: string | null,
  facilityName?: string | null,
  regionName?: string | null,
  subRegionName?: string | null,
  customerName?: string | null,
  organizationName?: string | null,
  userId?: string | null,
  roleId?: string | null,
  pageNumber?: number | null,
  limit?: number | null,
};

export type UserRoleScopeWithCount = {
  __typename: "UserRoleScopeWithCount",
  data?:  Array<UserRoleScope | null > | null,
  metadata?: PaginationMetaData | null,
};

export type CustomerByRegionInput = {
  searchText?: string | null,
};

export type CustomerByRegion = {
  __typename: "CustomerByRegion",
  customerId: string,
  customerName: string,
  intId?: string | null,
  customerNumber?: string | null,
  regionId: string,
  regionName: string,
};

export type FilterCustomerInput = {
  customerIds?: Array< string | null > | null,
  customerName?: string | null,
  email?: string | null,
  phoneNumber?: string | null,
};

export type FilterCustomerOutput = {
  __typename: "FilterCustomerOutput",
  customerId?: string | null,
  legalName?: string | null,
  email?: string | null,
  intId?: string | null,
  address?:  Array<Address | null > | null,
};

export type BidProposalSummary = {
  __typename: "BidProposalSummary",
  vendorId: string,
  vendorName?: string | null,
  vendorEmail?: string | null,
  bidId: string,
  jobId: string,
  bidName: string,
  totalCost: number,
  profitMargin: number,
  comments?: string | null,
  isFlatMargin?: YesNoType | null,
  finalQuote: number,
  bidEstimationType?: BidType | null,
  bidCreationDate: string,
  draftStep?: BidDraftStep | null,
  draftStatus?: DraftStatus | null,
  dataStatus: BidStatus,
  bidTemplate: string,
  bidCreatedFromSample: string,
  bidVersion: number,
  bidVersionCreatedDate: string,
  submittedBy: string,
  bidSubmissionDate: string,
  mdFrequencyType?: MdFrequencyType | null,
  jobCustodianAddress?: string | null,
  jobCustodianAddressName?: string | null,
  customerAddress?: string | null,
  customerAddressName?: string | null,
  jobs?: JobForBidProposal | null,
  bidSimpleEstimation?:  Array<JobBidEstimationForBidProposal | null > | null,
  bidAdditionalExpenses?:  Array<JobBidAdditionalExpensesForBidProposal | null > | null,
};

export type JobForBidProposal = {
  __typename: "JobForBidProposal",
  intReferenceId?: string | null,
  customerEmail?: string | null,
  customerName?: string | null,
  customerId?: string | null,
  createdBy?: string | null,
  startDate?: string | null,
  stopDate?: string | null,
  jobDueDates?:  Array<JobDueDatesForBidProposal | null > | null,
  jobFrequencies?:  Array<JobFrequenciesForBidProposal | null > | null,
  jobServices?:  Array<JobServicesForBidProposal | null > | null,
  jobFacilities?:  Array<JobFacilitiesForBidProposal | null > | null,
};

export type JobDueDatesForBidProposal = {
  __typename: "JobDueDatesForBidProposal",
  mdDueType?: string | null,
  dueDate?: string | null,
};

export type JobFrequenciesForBidProposal = {
  __typename: "JobFrequenciesForBidProposal",
  fromTime?: string | null,
  toTime?: string | null,
};

export type JobServicesForBidProposal = {
  __typename: "JobServicesForBidProposal",
  mdServiceId?: string | null,
};

export type JobFacilitiesForBidProposal = {
  __typename: "JobFacilitiesForBidProposal",
  facilityAddress?: string | null,
  customerName?: string | null,
};

export type JobBidEstimationForBidProposal = {
  __typename: "JobBidEstimationForBidProposal",
  jobId: string,
  estimationId: string,
  bidId: string,
  mdCategoryId: string,
  mdTaskId?: string | null,
  mdMeasureType: string,
  rate: number,
  quantity: number,
  totalCost: number,
  isVendorAdded: YesNoType,
  isBillable: Billable,
  numberOfOccurrences?: number | null,
  mdUnits?: string | null,
};

export type JobBidAdditionalExpensesForBidProposal = {
  __typename: "JobBidAdditionalExpensesForBidProposal",
  bidId: string,
  bidAdditionalExpenseId?: string | null,
  name?: string | null,
  description?: string | null,
  bidSimpleEstimationEstimationId?: string | null,
  rate: number,
  quantity?: number | null,
  totalCost?: number | null,
  mdUnits?: string | null,
  mdServiceCategoryId?: string | null,
  mdAdditionalExpenseCategory: string,
  draftStatus?: DraftStatus | null,
};

export type EmailTransaction = {
  __typename: "EmailTransaction",
  emailTransactionId?: string | null,
  processName?: string | null,
  IPAddress?: string | null,
  transactionStatus?: string | null,
  createdBy?: string | null,
  jobId?: string | null,
  bidId?: string | null,
  mdVendorType?: string | null,
  isReadOnly?: boolean | null,
};

export type JobAttachmentsInput = {
  jobIds?: Array< string | null > | null,
  facilityId?: string | null,
  tags?: string | null,
};

export type JobAttachmentsForCustomer = {
  __typename: "JobAttachmentsForCustomer",
  attachmentId?: string | null,
  mdAttachmentType?: string | null,
  fileName?: string | null,
  mdFileContext?: string | null,
  fileExtension?: string | null,
  url?: string | null,
  dataStatus?: string | null,
  comments?: string | null,
  jobId?: string | null,
  facilityId?: string | null,
  tags?: string | null,
};

export type PaginatedMatchedBid = {
  __typename: "PaginatedMatchedBid",
  serviceLocations?:  Array<VendorServiceLocation | null > | null,
  servicesOffered?:  Array<VendorService | null > | null,
  jobsWithinServiceLocations?:  Array<JobId | null > | null,
  matchedJobs?: JobRecommendationWithCount | null,
  matchedBids?: JobBidWithCount | null,
  matchedFacilities?:  Array<Facility | null > | null,
};

export type JobBidWithCount = {
  __typename: "JobBidWithCount",
  data?:  Array<JobBid | null > | null,
  metadata?: PaginationMetaData | null,
};

export type StandardRatesAndPtiTaxes = {
  __typename: "StandardRatesAndPtiTaxes",
  standardRates?:  Array<StandardRates | null > | null,
  ptiTaxes?:  Array<TaxOutput | null > | null,
};

export type TaxOutput = {
  __typename: "TaxOutput",
  recordId?: string | null,
  keyId?: string | null,
  keyType?: string | null,
  ptiPercentage?: string | null,
  stateCode?: string | null,
  nonUnionPtiPercentage?: string | null,
  unionPtiPercentage?: string | null,
};

export type UserByCognitoUserId = {
  __typename: "UserByCognitoUserId",
  userId?: string | null,
  username?: string | null,
};

export enum WorkDayTeamStatus {
  A = "A",
  P = "P",
  S = "S",
}


export type UpcomingJobsWithCount = {
  __typename: "UpcomingJobsWithCount",
  data?:  Array<UpcomingJob | null > | null,
  metadata?: PaginationMetaData | null,
};

export type UpcomingJob = {
  __typename: "UpcomingJob",
  workDayId?: string | null,
  workOrderName?: string | null,
  vendorId?: string | null,
  vendorName?: string | null,
  facilityId?: string | null,
  facilityName?: string | null,
  facilityAddress?: string | null,
  customerId?: string | null,
  jobStartTime?: string | null,
  jobEndTime?: string | null,
  jobStartDate?: string | null,
  parentWorkOrderId?: string | null,
  hasChild?: string | null,
  jobEndDate?: string | null,
  workOrderId?: string | null,
  workDate?: string | null,
  plannedStartTime?: string | null,
  plannedEndTime?: string | null,
  supervisorIds?: Array< string | null > | null,
  jobId?: string | null,
  latestPunchRecord?: PunchRecord | null,
  shiftDetails?:  Array<ShiftDetailsOfWorkOrder | null > | null,
  workDayTeam?:  Array<UpcomingJobWorkDayTeam | null > | null,
  jobType?: JobType | null,
  hasReworkTasks?: boolean | null,
  jobCustodian?: string | null,
  bidId?: string | null,
  services?: WorkOrderService | null,
  isReworkDay?: boolean | null,
  isShiftRescheduled?: boolean | null,
  shiftStartDateTime?: string | null,
  shiftEndDateTime?: string | null,
};

export type PunchRecord = {
  __typename: "PunchRecord",
  punchTime?: string | null,
  punchType?: string | null,
};

export type UpcomingJobWorkDayTeam = {
  __typename: "UpcomingJobWorkDayTeam",
  workDayId?: string | null,
  dataStatus?: WorkDayTeamStatus | null,
  associateId?: string | null,
  completedOn?: string | null,
};

export type ReferenceJobMasterInput = {
  customerNumber?: string | null,
  jobNumber?: string | null,
  jobDescription?: string | null,
};

export type ReferenceJobMaster = {
  __typename: "ReferenceJobMaster",
  jobNumber?: string | null,
  jobDescription?: string | null,
  customerNumber?: string | null,
  intAddressId?: string | null,
};

export type FoundUserResponse = {
  __typename: "FoundUserResponse",
  userId?: string | null,
  salutation?: string | null,
  username?: string | null,
  email?: string | null,
  signedUp?: boolean | null,
  phoneNumber?: string | null,
  dataStatus?: string | null,
  organizationId?: string | null,
  teamId?: string | null,
  cognitoUserId?: string | null,
  intId?: string | null,
  errorCode?: string | null,
  roles?:  Array<Roles | null > | null,
};

export enum QueryType {
  All = "All",
  BidsInProgress = "BidsInProgress",
  RecommendedJobs = "RecommendedJobs",
  SubmittedBids = "SubmittedBids",
}


export type GetJobsByQueryTypeWithCount = {
  __typename: "GetJobsByQueryTypeWithCount",
  data?:  Array<GetJobsByQueryTypeOutput | null > | null,
  metadata?: PaginationMetaData | null,
};

export type GetJobsByQueryTypeOutput = {
  __typename: "GetJobsByQueryTypeOutput",
  bidId?: string | null,
  bidDueDate?: string | null,
  logoUrl?: string | null,
  address?: FacilityAddressForJobs | null,
  facilityName?: string | null,
  finalQuote?: number | null,
  jobId: string,
  jobName: string,
  type?: QueryType | null,
  specialInstructions?: string | null,
  startDate?: string | null,
  stopDate?: string | null,
  jobFacility?: JobFacility | null,
  jobSize?: string | null,
  dataStatus?: string | null,
  jobType?: string | null,
  createdOn?: string | null,
  jobServices?:  Array<JobService | null > | null,
  jobFrequencies?:  Array<JobFrequency | null > | null,
  jobVendors?:  Array<JobVendor | null > | null,
  jobDueDates?:  Array<JobDueDate | null > | null,
  percentageMatch?: number | null,
  bidVersion?: number | null,
  bidStatus?: string | null,
  bidComments?:  Array<BidComments | null > | null,
};

export type FacilityAddressForJobs = {
  __typename: "FacilityAddressForJobs",
  callHours?: CallHours | null,
  transportationName?: string | null,
  storageName?: string | null,
  callStartTime?: string | null,
  callEndTime?: string | null,
  customerId?: string | null,
  addressName?: string | null,
  noOfBuildings?: number | null,
  addressLine1?: string | null,
  addressLine2?: string | null,
  addressLine3?: string | null,
  city?: string | null,
  stateCode?: string | null,
  county?: string | null,
  postalCode?: string | null,
  mdCountryCode?: string | null,
  landmark?: string | null,
  googlePlaceId?: string | null,
  latitude?: string | null,
  longitude?: string | null,
  mdMsaId?: string | null,
  logoUrl?: string | null,
  intId?: string | null,
};

export type UserDetailsInput = {
  cognitoUserId?: string | null,
  userIds?: Array< string | null > | null,
  roleName?: string | null,
  userName?: string | null,
  organizationIds?: Array< string | null > | null,
  isStrictSearch?: boolean | null,
};

export type AddUserMutationVariables = {
  userInput: UserInput,
};

export type AddUserMutation = {
  addUser?:  {
    __typename: "User",
    id: string,
    name: string,
    email?: string | null,
  } | null,
};

export type DeleteCognitoUserMutationVariables = {
  userData: UserData,
};

export type DeleteCognitoUserMutation = {
  deleteCognitoUser?: string | null,
};

export type UpdateCognitoUserMutationVariables = {
  userData: UserData,
};

export type UpdateCognitoUserMutation = {
  updateCognitoUser?: string | null,
};

export type AddMasterDataMutationVariables = {
  masterDataInput: MasterDataInput,
};

export type AddMasterDataMutation = {
  addMasterData?:  {
    __typename: "MasterData",
    code?: string | null,
    keyCode?: string | null,
    value?: string | null,
    subKey1Value?: string | null,
    subKey2Value?: string | null,
    canCustomize?: string | null,
    userOrg?: number | null,
    processId?: number | null,
    defaultCode?: string | null,
    keyId?: string | null,
    keyType?: string | null,
    displayName?: string | null,
  } | null,
};

export type AddOrgMasterDataMutationVariables = {
  orgMasterDataInput: OrgMasterDataInput,
};

export type AddOrgMasterDataMutation = {
  addOrgMasterData?:  {
    __typename: "OrgMasterData",
    keyId: string,
    keyType: string,
    code: string,
    keyCode: string,
    displayName: string,
    value: string,
  } | null,
};

export type UpdateMasterDataMutationVariables = {
  updateMasterDataInput: UpdateMasterDataInput,
};

export type UpdateMasterDataMutation = {
  updateMasterData?:  {
    __typename: "MasterData",
    code?: string | null,
    keyCode?: string | null,
    value?: string | null,
    subKey1Value?: string | null,
    subKey2Value?: string | null,
    canCustomize?: string | null,
    userOrg?: number | null,
    processId?: number | null,
    defaultCode?: string | null,
    keyId?: string | null,
    keyType?: string | null,
    displayName?: string | null,
  } | null,
};

export type AddCustomerMutationVariables = {
  customerInput: CustomerInput,
};

export type AddCustomerMutation = {
  addCustomer?:  {
    __typename: "Customer",
    customerId: string,
    legalName: string,
    doingBusinessAs: string,
    websiteUrl?: string | null,
    federalEmployerId?: string | null,
    SICCode?: string | null,
    taxId?: string | null,
    logo?: string | null,
  } | null,
};

export type UpdateCustomerMutationVariables = {
  customerId: string,
  customerData: CustomerData,
};

export type UpdateCustomerMutation = {
  updateCustomer?:  {
    __typename: "Customer",
    customerId: string,
    legalName: string,
    doingBusinessAs: string,
    websiteUrl?: string | null,
    federalEmployerId?: string | null,
    SICCode?: string | null,
    taxId?: string | null,
    logo?: string | null,
  } | null,
};

export type AddCustomerDetailsMutationVariables = {
  customerDetailsInput: CustomerDetailsInput,
};

export type AddCustomerDetailsMutation = {
  // addFeatureFlag(featureFlagInput: FeatureFlagInput!): FeatureFlag
  // updateFeatureFlag(
  // recordId: String!
  // updateFeatureFlagInput: UpdateFeatureFlagInput!
  // ): FeatureFlag
  // deleteFeatureFlag(recordId: String!): FeatureFlag
  addCustomerDetails?:  {
    __typename: "CustomerDetails",
    customerDetailsId?: string | null,
    customerId?: string | null,
    noOfEmployees?: number | null,
    annualRevenueEstimate?: string | null,
    mdCustomerType?: string | null,
    referralSource?: string | null,
    phone?: string | null,
    fax?: string | null,
    email?: string | null,
  } | null,
};

export type UpdateCustomerDetailsMutationVariables = {
  customerDetailsData: CustomerDetailsData,
};

export type UpdateCustomerDetailsMutation = {
  updateCustomerDetails?:  Array< {
    __typename: "CustomerDetails",
    customerDetailsId?: string | null,
    customerId?: string | null,
    noOfEmployees?: number | null,
    annualRevenueEstimate?: string | null,
    mdCustomerType?: string | null,
    referralSource?: string | null,
    phone?: string | null,
    fax?: string | null,
    email?: string | null,
  } | null > | null,
};

export type AddAddressMutationVariables = {
  addressInput: AddressInput,
};

export type AddAddressMutation = {
  addAddress?:  {
    __typename: "Address",
    addressId?: string | null,
    customerId?: string | null,
    addressName?: string | null,
    noOfBuildings?: number | null,
    addressLine1?: string | null,
    addressLine2?: string | null,
    addressLine3?: string | null,
    city?: string | null,
    stateCode?: string | null,
    county?: string | null,
    postalCode?: string | null,
    mdCountryCode?: string | null,
    landmark?: string | null,
    googlePlaceId?: string | null,
    latitude?: string | null,
    longitude?: string | null,
    mdMsaId?: string | null,
    logoUrl?: string | null,
    intId?: string | null,
  } | null,
};

export type UpdateAddressMutationVariables = {
  addressData: AddressData,
};

export type UpdateAddressMutation = {
  updateAddress?:  {
    __typename: "Address",
    addressId?: string | null,
    customerId?: string | null,
    addressName?: string | null,
    noOfBuildings?: number | null,
    addressLine1?: string | null,
    addressLine2?: string | null,
    addressLine3?: string | null,
    city?: string | null,
    stateCode?: string | null,
    county?: string | null,
    postalCode?: string | null,
    mdCountryCode?: string | null,
    landmark?: string | null,
    googlePlaceId?: string | null,
    latitude?: string | null,
    longitude?: string | null,
    mdMsaId?: string | null,
    logoUrl?: string | null,
    intId?: string | null,
  } | null,
};

export type AddFacilityMutationVariables = {
  facilityInput: FacilityInput,
};

export type AddFacilityMutation = {
  addFacility?:  {
    __typename: "Facility",
    callHours?: CallHours | null,
    transportationName?: string | null,
    storageName?: string | null,
    callStartTime?: string | null,
    callEndTime?: string | null,
    facilityId?: string | null,
    customerId?: string | null,
    buildingName?: string | null,
    buildingMakeup?: string | null,
    noOfFloors?: number | null,
    sqFootage?: number | null,
    mdFacilityType?: string | null,
    serviceableSqFootage?: number | null,
    occupancy?: number | null,
    supplyStorageAvailability?: boolean | null,
    transportRequiredWithinLocation?: boolean | null,
    contactId?: string | null,
    facilityTrafficLevel?: string | null,
    emergencyContactName?: string | null,
    emergencyContactPhone?: string | null,
    cleaningInstructions?: string | null,
    geoFenceRadius?: number | null,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      addressId?: string | null,
      customerId?: string | null,
      addressName?: string | null,
      noOfBuildings?: number | null,
      addressLine1?: string | null,
      addressLine2?: string | null,
      addressLine3?: string | null,
      city?: string | null,
      stateCode?: string | null,
      county?: string | null,
      postalCode?: string | null,
      mdCountryCode?: string | null,
      landmark?: string | null,
      googlePlaceId?: string | null,
      latitude?: string | null,
      longitude?: string | null,
      mdMsaId?: string | null,
      logoUrl?: string | null,
      intId?: string | null,
    } | null,
  } | null,
};

export type UpdateFacilityMutationVariables = {
  facilityData: FacilityData,
};

export type UpdateFacilityMutation = {
  updateFacility?:  {
    __typename: "Facility",
    callHours?: CallHours | null,
    transportationName?: string | null,
    storageName?: string | null,
    callStartTime?: string | null,
    callEndTime?: string | null,
    facilityId?: string | null,
    customerId?: string | null,
    buildingName?: string | null,
    buildingMakeup?: string | null,
    noOfFloors?: number | null,
    sqFootage?: number | null,
    mdFacilityType?: string | null,
    serviceableSqFootage?: number | null,
    occupancy?: number | null,
    supplyStorageAvailability?: boolean | null,
    transportRequiredWithinLocation?: boolean | null,
    contactId?: string | null,
    facilityTrafficLevel?: string | null,
    emergencyContactName?: string | null,
    emergencyContactPhone?: string | null,
    cleaningInstructions?: string | null,
    geoFenceRadius?: number | null,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      addressId?: string | null,
      customerId?: string | null,
      addressName?: string | null,
      noOfBuildings?: number | null,
      addressLine1?: string | null,
      addressLine2?: string | null,
      addressLine3?: string | null,
      city?: string | null,
      stateCode?: string | null,
      county?: string | null,
      postalCode?: string | null,
      mdCountryCode?: string | null,
      landmark?: string | null,
      googlePlaceId?: string | null,
      latitude?: string | null,
      longitude?: string | null,
      mdMsaId?: string | null,
      logoUrl?: string | null,
      intId?: string | null,
    } | null,
  } | null,
};

export type AddFacilityServicesDetailsMutationVariables = {
  facilityServicesInput: FacilityServicesInput,
};

export type AddFacilityServicesDetailsMutation = {
  addFacilityServicesDetails?:  {
    __typename: "FacilityServiceDetails",
    facilityDetailsId: string,
    facilityId: string,
    floorNumber?: string | null,
    mdAreaType?: string | null,
    mdFlooringType?: string | null,
    quantity?: number | null,
    mdUnitType?: string | null,
  } | null,
};

export type UpdateFacilityServicesDetailsMutationVariables = {
  facilityServicesInput: UpdateFacilityServicesInput,
};

export type UpdateFacilityServicesDetailsMutation = {
  updateFacilityServicesDetails?:  Array< {
    __typename: "FacilityServiceDetails",
    facilityDetailsId: string,
    facilityId: string,
    floorNumber?: string | null,
    mdAreaType?: string | null,
    mdFlooringType?: string | null,
    quantity?: number | null,
    mdUnitType?: string | null,
  } | null > | null,
};

export type AddVendorMutationVariables = {
  vendorInput: VendorInput,
};

export type AddVendorMutation = {
  addVendor?:  {
    __typename: "Vendor",
    vendorId: string,
    vendorCompanyName?: string | null,
    vendorCompanyType?: string | null,
    doingBusinessAs?: string | null,
    SSID?: string | null,
    taxId?: string | null,
    websiteUrl?: string | null,
    logoUrl?: string | null,
    referralSource?: string | null,
    phone?: string | null,
    email?: string | null,
    dataStatus: string,
    modifiedOn?: string | null,
  } | null,
};

export type UpdateVendorStatusMutationVariables = {
  vendorProfileStatusUpdateInput: VendorProfileStatusUpdateInput,
};

export type UpdateVendorStatusMutation = {
  updateVendorStatus?:  {
    __typename: "Vendor",
    vendorId: string,
    vendorCompanyName?: string | null,
    vendorCompanyType?: string | null,
    doingBusinessAs?: string | null,
    SSID?: string | null,
    taxId?: string | null,
    websiteUrl?: string | null,
    logoUrl?: string | null,
    referralSource?: string | null,
    phone?: string | null,
    email?: string | null,
    dataStatus: string,
    modifiedOn?: string | null,
  } | null,
};

export type UpdateVendorMutationVariables = {
  vendorId: string,
  vendorUpdate?: VendorUpdate | null,
};

export type UpdateVendorMutation = {
  updateVendor?:  {
    __typename: "Vendor",
    vendorId: string,
    vendorCompanyName?: string | null,
    vendorCompanyType?: string | null,
    doingBusinessAs?: string | null,
    SSID?: string | null,
    taxId?: string | null,
    websiteUrl?: string | null,
    logoUrl?: string | null,
    referralSource?: string | null,
    phone?: string | null,
    email?: string | null,
    dataStatus: string,
    modifiedOn?: string | null,
  } | null,
};

export type AddVendorAddressMutationVariables = {
  vendorAddressInput?: VendorAddressInput | null,
  vendorId?: string | null,
};

export type AddVendorAddressMutation = {
  addVendorAddress?:  {
    __typename: "VendorAddress",
    addressId?: string | null,
    vendorId?: string | null,
    addressName?: string | null,
    addressLine1?: string | null,
    addressLine2?: string | null,
    addressLine3?: string | null,
    city?: string | null,
    stateCode?: string | null,
    county?: string | null,
    postalCode?: string | null,
    mdCountryCode?: string | null,
    landmark?: string | null,
    googlePlaceId?: string | null,
    latitude?: string | null,
    longitude?: string | null,
    mdMsaId?: string | null,
    modifiedOn?: string | null,
    regionId?: string | null,
  } | null,
};

export type AddVendorDetailsMutationVariables = {
  vendorDetailsInput?: VendorDetailsInput | null,
  vendorId?: string | null,
};

export type AddVendorDetailsMutation = {
  addVendorDetails?:  {
    __typename: "VendorDetails",
    vendorId?: string | null,
    annualRevenueEstimate?: number | null,
    fax?: string | null,
    coi?: string | null,
    noOfEmployees?: number | null,
    workWeekDefinition?: string | null,
    w9FormUrl?: string | null,
  } | null,
};

export type CreateManyVendorAttachmentsMutationVariables = {
  vendorId: string,
  vendorAttachmentInput?: Array< VendorAttachmentInput > | null,
};

export type CreateManyVendorAttachmentsMutation = {
  createManyVendorAttachments?:  Array< {
    __typename: "VendorAttachment",
    attachmentId: string,
    refType?: RefType | null,
    refId?: string | null,
    fileName: string,
    mdFileContext: mdFileContext,
    fileExtension: string,
    url: string,
    dataStatus: DataStatus,
    date?: string | null,
    createdOn?: string | null,
    modifiedOn?: string | null,
  } | null > | null,
};

export type UpdateManyVendorAttachmentsMutationVariables = {
  vendorId: string,
  vendorAttachmentInput?: Array< VendorAttachmentInput > | null,
};

export type UpdateManyVendorAttachmentsMutation = {
  updateManyVendorAttachments?:  Array< {
    __typename: "VendorAttachment",
    attachmentId: string,
    refType?: RefType | null,
    refId?: string | null,
    fileName: string,
    mdFileContext: mdFileContext,
    fileExtension: string,
    url: string,
    dataStatus: DataStatus,
    date?: string | null,
    createdOn?: string | null,
    modifiedOn?: string | null,
  } | null > | null,
};

export type DeleteManyVendorAttachmentsMutationVariables = {
  vendorId: string,
  attachmentIds?: Array< string > | null,
};

export type DeleteManyVendorAttachmentsMutation = {
  deleteManyVendorAttachments?:  Array< {
    __typename: "DeleteVendorAttachment",
    vendorAttachmentId?: string | null,
    vendorId?: string | null,
    attachmentId?: string | null,
    dataStatus?: string | null,
  } | null > | null,
};

export type UpdateVendorServiceLocationMutationVariables = {
  vendorId?: string | null,
  vendorServiceLocationInput?: Array< VendorServiceLocationInput | null > | null,
};

export type UpdateVendorServiceLocationMutation = {
  updateVendorServiceLocation?:  {
    __typename: "UpdatedVendorServiceLocations",
    serviceLocationsDeleted?:  Array< {
      __typename: "VendorServiceLocation",
      vendorServiceLocationId?: string | null,
      vendorId?: string | null,
      mdAreaName?: string | null,
      mdAreaType?: string | null,
      mdAreaCode?: string | null,
      mdAreaCategory?: string | null,
      country?: string | null,
      locationId?: string | null,
    } | null > | null,
    serviceLocationsAdded?:  Array< {
      __typename: "VendorServiceLocation",
      vendorServiceLocationId?: string | null,
      vendorId?: string | null,
      mdAreaName?: string | null,
      mdAreaType?: string | null,
      mdAreaCode?: string | null,
      mdAreaCategory?: string | null,
      country?: string | null,
      locationId?: string | null,
    } | null > | null,
    serviceLocationsReactivated?:  Array< {
      __typename: "VendorServiceLocation",
      vendorServiceLocationId?: string | null,
      vendorId?: string | null,
      mdAreaName?: string | null,
      mdAreaType?: string | null,
      mdAreaCode?: string | null,
      mdAreaCategory?: string | null,
      country?: string | null,
      locationId?: string | null,
    } | null > | null,
  } | null,
};

export type UpdateVendorServicesOfferedMutationVariables = {
  vendorServicesOfferedInput?: Array< VendorServiceInput | null > | null,
  vendorId?: string | null,
};

export type UpdateVendorServicesOfferedMutation = {
  updateVendorServicesOffered?:  {
    __typename: "UpdatedVendorServicesOffered",
    added?: Array< string | null > | null,
    deleted?: Array< string | null > | null,
    reactivated?: Array< string | null > | null,
  } | null,
};

export type AddBeneficiariesMutationVariables = {
  vendorId?: string | null,
  beneficiaryInput?: Array< BeneficiaryInput | null > | null,
};

export type AddBeneficiariesMutation = {
  addBeneficiaries?:  Array< {
    __typename: "Beneficiary",
    dataStatus: DataState,
    beneficiaryId: string,
    name: string,
    TIN: string,
    percentageHolding: string,
    addressLine1: string,
    addressLine2?: string | null,
    city: string,
    state: string,
    zipCode: string,
    vendorId: string,
    modifiedOn?: string | null,
  } | null > | null,
};

export type UpdateBeneficiariesMutationVariables = {
  beneficiaryUpdate?: Array< BeneficiaryUpdate | null > | null,
};

export type UpdateBeneficiariesMutation = {
  updateBeneficiaries?:  Array< {
    __typename: "Beneficiary",
    dataStatus: DataState,
    beneficiaryId: string,
    name: string,
    TIN: string,
    percentageHolding: string,
    addressLine1: string,
    addressLine2?: string | null,
    city: string,
    state: string,
    zipCode: string,
    vendorId: string,
    modifiedOn?: string | null,
  } | null > | null,
};

export type RemoveBeneficiariesMutationVariables = {
  beneficiaryIds?: Array< string | null > | null,
};

export type RemoveBeneficiariesMutation = {
  removeBeneficiaries?:  Array< {
    __typename: "Beneficiary",
    dataStatus: DataState,
    beneficiaryId: string,
    name: string,
    TIN: string,
    percentageHolding: string,
    addressLine1: string,
    addressLine2?: string | null,
    city: string,
    state: string,
    zipCode: string,
    vendorId: string,
    modifiedOn?: string | null,
  } | null > | null,
};

export type AddInsurancesMutationVariables = {
  vendorId?: string | null,
  insuranceInput?: Array< InsuranceInput | null > | null,
};

export type AddInsurancesMutation = {
  addInsurances?:  Array< {
    __typename: "Insurance",
    fileName?: string | null,
    insuranceName?: string | null,
    validTill?: string | null,
    producer?: string | null,
    insured?: string | null,
    contactName?: string | null,
    contactPhone?: string | null,
    address?: string | null,
    fax?: string | null,
    insuranceId?: string | null,
    dataStatus?: DataState | null,
    vendorId?: string | null,
    createdOn?: string | null,
  } | null > | null,
};

export type UpdateInsurancesMutationVariables = {
  insuranceUpdate?: Array< InsuranceUpdate | null > | null,
};

export type UpdateInsurancesMutation = {
  updateInsurances?:  Array< {
    __typename: "Insurance",
    fileName?: string | null,
    insuranceName?: string | null,
    validTill?: string | null,
    producer?: string | null,
    insured?: string | null,
    contactName?: string | null,
    contactPhone?: string | null,
    address?: string | null,
    fax?: string | null,
    insuranceId?: string | null,
    dataStatus?: DataState | null,
    vendorId?: string | null,
    createdOn?: string | null,
  } | null > | null,
};

export type RemoveInsurancesMutationVariables = {
  insuranceIds?: Array< string | null > | null,
};

export type RemoveInsurancesMutation = {
  removeInsurances?:  Array< {
    __typename: "Insurance",
    fileName?: string | null,
    insuranceName?: string | null,
    validTill?: string | null,
    producer?: string | null,
    insured?: string | null,
    contactName?: string | null,
    contactPhone?: string | null,
    address?: string | null,
    fax?: string | null,
    insuranceId?: string | null,
    dataStatus?: DataState | null,
    vendorId?: string | null,
    createdOn?: string | null,
  } | null > | null,
};

export type CreateManyVendorAccreditationMutationVariables = {
  vendorId: string,
  createVendorAccreditationInput?: Array< CreateVendorAccreditationInput | null > | null,
};

export type CreateManyVendorAccreditationMutation = {
  createManyVendorAccreditation?:  Array< {
    __typename: "CreateVendorAccreditation",
    recordId?: string | null,
    recStatus?: string | null,
    certificateId?: string | null,
    accreditationType?: string | null,
    labels?: string | null,
    issuingAuthority?: string | null,
    effectiveFromDate?: string | null,
    effectiveEndDate?: string | null,
    subAccreditationTypes?: Array< string | null > | null,
    accreditationSubType?:  Array< {
      __typename: "AccreditationSubTypes",
      accreditationSubTypeId?: string | null,
      recSeq?: number | null,
      recStatus?: string | null,
      subType?: string | null,
      value?: string | null,
      dataStatus?: string | null,
      vendorAccreditationId?: string | null,
      vendorAccreditationRecSeq?: number | null,
      fromDate?: string | null,
      endDate?: string | null,
      createdBy?: string | null,
      createdOn?: string | null,
      modifiedBy?: string | null,
      modifiedOn?: string | null,
    } | null > | null,
    msaCustomer?: string | null,
    type?: string | null,
    status?: string | null,
    dataStatus?: string | null,
    vendorId?: string | null,
    attachmentId?: string | null,
    fromDate?: string | null,
    endDate?: string | null,
    createdBy?: string | null,
    createdOn?: string | null,
    modifiedBy?: string | null,
    modifiedOn?: string | null,
  } | null > | null,
};

export type DeleteManyVendorAccreditationMutationVariables = {
  recordIds: Array< string | null >,
};

export type DeleteManyVendorAccreditationMutation = {
  deleteManyVendorAccreditation?:  Array< {
    __typename: "VendorAccreditationDetail",
    recordId?: string | null,
    attachmentId?: string | null,
    certificateId?: string | null,
    accreditationType?: string | null,
    labels?: string | null,
    issuingAuthority?: string | null,
    effectiveFromDate?: string | null,
    effectiveEndDate?: string | null,
    accreditationSubType?:  Array< {
      __typename: "AccreditationSubTypes",
      accreditationSubTypeId?: string | null,
      recSeq?: number | null,
      recStatus?: string | null,
      subType?: string | null,
      value?: string | null,
      dataStatus?: string | null,
      vendorAccreditationId?: string | null,
      vendorAccreditationRecSeq?: number | null,
      fromDate?: string | null,
      endDate?: string | null,
      createdBy?: string | null,
      createdOn?: string | null,
      modifiedBy?: string | null,
      modifiedOn?: string | null,
    } | null > | null,
    msaCustomer?: string | null,
    type?: string | null,
    status?: string | null,
    dataStatus?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    createdOn?: string | null,
    modifiedOn?: string | null,
    fromDate?: string | null,
    vendorId?: string | null,
    attachments?:  {
      __typename: "VendorAttachment",
      attachmentId: string,
      refType?: RefType | null,
      refId?: string | null,
      fileName: string,
      mdFileContext: mdFileContext,
      fileExtension: string,
      url: string,
      dataStatus: DataStatus,
      date?: string | null,
      createdOn?: string | null,
      modifiedOn?: string | null,
    } | null,
  } | null > | null,
};

export type UpdateAssociateSkillsMutationVariables = {
  associateSkillsInput?: Array< AssociateSkillsInput | null > | null,
  associateId?: string | null,
};

export type UpdateAssociateSkillsMutation = {
  updateAssociateSkills?:  {
    __typename: "UpdatedAssociateSkills",
    added?: Array< string | null > | null,
    deleted?: Array< string | null > | null,
  } | null,
};

export type UpdateAssociateServiceLocationMutationVariables = {
  associateServiceLocationInput?: Array< AssociateServiceLocationInput | null > | null,
  associateId?: string | null,
};

export type UpdateAssociateServiceLocationMutation = {
  updateAssociateServiceLocation?:  {
    __typename: "UpdatedAssociateServiceLocations",
    serviceLocationsDeleted?:  Array< {
      __typename: "AssociateServiceLocation",
      associateServiceLocationId?: string | null,
      associateId?: string | null,
      mdAreaName?: string | null,
      mdAreaType?: string | null,
      mdAreaCategory?: string | null,
      locationId?: string | null,
      country?: string | null,
    } | null > | null,
    serviceLocationsAdded?:  Array< {
      __typename: "AssociateServiceLocation",
      associateServiceLocationId?: string | null,
      associateId?: string | null,
      mdAreaName?: string | null,
      mdAreaType?: string | null,
      mdAreaCategory?: string | null,
      locationId?: string | null,
      country?: string | null,
    } | null > | null,
    serviceLocationsReactivated?:  Array< {
      __typename: "AssociateServiceLocation",
      associateServiceLocationId?: string | null,
      associateId?: string | null,
      mdAreaName?: string | null,
      mdAreaType?: string | null,
      mdAreaCategory?: string | null,
      locationId?: string | null,
      country?: string | null,
    } | null > | null,
  } | null,
};

export type UpdateAssociateShiftPreferencesMutationVariables = {
  associateShiftPreferencesInput?: Array< AssociateShiftPreferenceInput | null > | null,
  associateId?: string | null,
};

export type UpdateAssociateShiftPreferencesMutation = {
  updateAssociateShiftPreferences?:  Array< {
    __typename: "AssociateShiftPreferences",
    shiftPreferenceId?: number | null,
    day?: string | null,
    fromTime?: string | null,
    toTime?: string | null,
    isFlexibleShiftPreference?: boolean | null,
  } | null > | null,
};

export type CreateAssociateProfileMutationVariables = {
  associateProfile?: AssociateProfileInput | null,
  associateId?: string | null,
};

export type CreateAssociateProfileMutation = {
  createAssociateProfile?:  {
    __typename: "AssociateProfile",
    associateProfileRecId?: string | null,
    associateId?: string | null,
    cognitoUserId?: string | null,
    title: string,
    linkedInURL?: string | null,
    dateOfBirth?: string | null,
    name?: string | null,
    emergencyContactName?: string | null,
    emergencyContactNumber?: string | null,
    eeoInfo?: string | null,
    primaryLanguage?: string | null,
    languagesSpoken?: string | null,
    source?: string | null,
    logoUrl?: string | null,
    emailId?: string | null,
  } | null,
};

export type UpdateAssociateProfileMutationVariables = {
  associateProfile?: AssociateProfileUpdate | null,
  associateId?: string | null,
};

export type UpdateAssociateProfileMutation = {
  updateAssociateProfile?:  {
    __typename: "AssociateProfile",
    associateProfileRecId?: string | null,
    associateId?: string | null,
    cognitoUserId?: string | null,
    title: string,
    linkedInURL?: string | null,
    dateOfBirth?: string | null,
    name?: string | null,
    emergencyContactName?: string | null,
    emergencyContactNumber?: string | null,
    eeoInfo?: string | null,
    primaryLanguage?: string | null,
    languagesSpoken?: string | null,
    source?: string | null,
    logoUrl?: string | null,
    emailId?: string | null,
  } | null,
};

export type CreateAssociateAddressMutationVariables = {
  associateAddress?: AssociateAddressInput | null,
  associateId?: string | null,
};

export type CreateAssociateAddressMutation = {
  createAssociateAddress?:  {
    __typename: "AssociateAddress",
    addressId?: string | null,
    associateId?: string | null,
    addressName?: string | null,
    addressLine1: string,
    addressLine2?: string | null,
    addressLine3?: string | null,
    city: string,
    stateCode: string,
    county?: string | null,
    postalCode: string,
    mdCountryCode: string,
    landmark?: string | null,
    googlePlaceId: string,
    latitude: number,
    longitude: number,
    regionId?: string | null,
  } | null,
};

export type UpdateAssociateAddressMutationVariables = {
  associateAddress?: AssociateAddressUpdate | null,
  associateId?: string | null,
};

export type UpdateAssociateAddressMutation = {
  updateAssociateAddress?:  {
    __typename: "AssociateAddress",
    addressId?: string | null,
    associateId?: string | null,
    addressName?: string | null,
    addressLine1: string,
    addressLine2?: string | null,
    addressLine3?: string | null,
    city: string,
    stateCode: string,
    county?: string | null,
    postalCode: string,
    mdCountryCode: string,
    landmark?: string | null,
    googlePlaceId: string,
    latitude: number,
    longitude: number,
    regionId?: string | null,
  } | null,
};

export type AddJobMutationVariables = {
  customerId: string,
  jobsInput?: CreateJobInput | null,
};

export type AddJobMutation = {
  addJob?:  {
    __typename: "Job",
    jobId: string,
    jobName: string,
    startDate?: string | null,
    stopDate?: string | null,
    dataStatus?: string | null,
    specialInstructions?: string | null,
    draftStep?: DraftStep | null,
    draftStatus?: DraftStatus | null,
    jobType?: JobType | null,
  } | null,
};

export type DeleteJobBidsMutationVariables = {
  deleteJobBidsInput: DeleteJobBidInput,
};

export type DeleteJobBidsMutation = {
  deleteJobBids?: Array< string | null > | null,
};

export type DeleteJobsMutationVariables = {
  deleteJobsInput: DeleteJobInput,
};

export type DeleteJobsMutation = {
  deleteJobs?: Array< string | null > | null,
};

export type DeleteFacilityMutationVariables = {
  deleteFacilityInput: DeleteFacilityInput,
};

export type DeleteFacilityMutation = {
  deleteFacility?: Array< string | null > | null,
};

export type DeleteWorkOrdersMutationVariables = {
  deleteWorkOrdersInput: DeleteWorkOrderInput,
};

export type DeleteWorkOrdersMutation = {
  deleteWorkOrders?: Array< string | null > | null,
};

export type AddJobFacilityMutationVariables = {
  customerId: string,
  jobId: string,
  jobFacilityInput?: Array< CreateJobFacilityInput | null > | null,
};

export type AddJobFacilityMutation = {
  addJobFacility?:  {
    __typename: "JobFacilityUpdate",
    added?:  Array< {
      __typename: "JobFacility",
      jobId: string,
      facilityId: string,
      mdFacilityType: string,
      facilityAddress?: string | null,
      remarks?: string | null,
      status?: string | null,
      jobStartDate?: string | null,
      jobEndData?: string | null,
    } | null > | null,
    deleted?:  Array< {
      __typename: "JobFacility",
      jobId: string,
      facilityId: string,
      mdFacilityType: string,
      facilityAddress?: string | null,
      remarks?: string | null,
      status?: string | null,
      jobStartDate?: string | null,
      jobEndData?: string | null,
    } | null > | null,
    reactivated?:  Array< {
      __typename: "JobFacility",
      jobId: string,
      facilityId: string,
      mdFacilityType: string,
      facilityAddress?: string | null,
      remarks?: string | null,
      status?: string | null,
      jobStartDate?: string | null,
      jobEndData?: string | null,
    } | null > | null,
  } | null,
};

export type AddJobServicesMutationVariables = {
  customerId: string,
  jobId: string,
  jobServicesInput?: Array< CreateJobServiceInput > | null,
};

export type AddJobServicesMutation = {
  addJobServices?:  {
    __typename: "JobServiceUpdate",
    added?:  Array< {
      __typename: "JobService",
      jobId: string,
      mdServiceId: string,
      serviceMeasure: number,
      mdServiceUnits: string,
      tasks?:  Array< {
        __typename: "JobServiceTasks",
        taskId: string,
        taskName: string,
      } | null > | null,
      actualMdServiceUnits?: number | null,
      actualServiceMeasure?: string | null,
    } | null > | null,
    deleted?:  Array< {
      __typename: "JobService",
      jobId: string,
      mdServiceId: string,
      serviceMeasure: number,
      mdServiceUnits: string,
      tasks?:  Array< {
        __typename: "JobServiceTasks",
        taskId: string,
        taskName: string,
      } | null > | null,
      actualMdServiceUnits?: number | null,
      actualServiceMeasure?: string | null,
    } | null > | null,
    reactivated?:  Array< {
      __typename: "JobService",
      jobId: string,
      mdServiceId: string,
      serviceMeasure: number,
      mdServiceUnits: string,
      tasks?:  Array< {
        __typename: "JobServiceTasks",
        taskId: string,
        taskName: string,
      } | null > | null,
      actualMdServiceUnits?: number | null,
      actualServiceMeasure?: string | null,
    } | null > | null,
    modified?:  Array< {
      __typename: "JobService",
      jobId: string,
      mdServiceId: string,
      serviceMeasure: number,
      mdServiceUnits: string,
      tasks?:  Array< {
        __typename: "JobServiceTasks",
        taskId: string,
        taskName: string,
      } | null > | null,
      actualMdServiceUnits?: number | null,
      actualServiceMeasure?: string | null,
    } | null > | null,
  } | null,
};

export type AddJobFrequencyMutationVariables = {
  customerId: string,
  jobId: string,
  jobFrequencyInput: Array< CreateJobFrequencyInput | null >,
};

export type AddJobFrequencyMutation = {
  addJobFrequency?:  {
    __typename: "JobFrequencyUpdate",
    added?:  Array< {
      __typename: "JobFrequency",
      mdShiftType?: string | null,
      mdScheduleType?: string | null,
      mdServiceId?: string | null,
      scheduleDays?: string | null,
      fromTime?: string | null,
      toTime?: string | null,
      cronExpression?: string | null,
      repeatitions?: number | null,
      repetition?: number | null,
    } | null > | null,
    deleted?:  Array< {
      __typename: "JobFrequency",
      mdShiftType?: string | null,
      mdScheduleType?: string | null,
      mdServiceId?: string | null,
      scheduleDays?: string | null,
      fromTime?: string | null,
      toTime?: string | null,
      cronExpression?: string | null,
      repeatitions?: number | null,
      repetition?: number | null,
    } | null > | null,
    reactivated?:  Array< {
      __typename: "JobFrequency",
      mdShiftType?: string | null,
      mdScheduleType?: string | null,
      mdServiceId?: string | null,
      scheduleDays?: string | null,
      fromTime?: string | null,
      toTime?: string | null,
      cronExpression?: string | null,
      repeatitions?: number | null,
      repetition?: number | null,
    } | null > | null,
    modified?:  Array< {
      __typename: "JobFrequency",
      mdShiftType?: string | null,
      mdScheduleType?: string | null,
      mdServiceId?: string | null,
      scheduleDays?: string | null,
      fromTime?: string | null,
      toTime?: string | null,
      cronExpression?: string | null,
      repeatitions?: number | null,
      repetition?: number | null,
    } | null > | null,
  } | null,
};

export type UpdateJobMutationVariables = {
  customerId: string,
  jobId: string,
  jobsInput?: UpdateJobInput | null,
};

export type UpdateJobMutation = {
  updateJob?:  {
    __typename: "Job",
    jobId: string,
    jobName: string,
    startDate?: string | null,
    stopDate?: string | null,
    dataStatus?: string | null,
    specialInstructions?: string | null,
    draftStep?: DraftStep | null,
    draftStatus?: DraftStatus | null,
    jobType?: JobType | null,
  } | null,
};

export type OpenJobForBidMutationVariables = {
  customerId: string,
  jobId: string,
  jobCustomerInput?: JobCustomerInput | null,
};

export type OpenJobForBidMutation = {
  openJobForBid?:  {
    __typename: "Job",
    jobId: string,
    jobName: string,
    startDate?: string | null,
    stopDate?: string | null,
    dataStatus?: string | null,
    specialInstructions?: string | null,
    draftStep?: DraftStep | null,
    draftStatus?: DraftStatus | null,
    jobType?: JobType | null,
  } | null,
};

export type MarkJobAsAwardedMutationVariables = {
  jobId: string,
  bidId: string,
  facilityAddress?: facilityAddressInput | null,
};

export type MarkJobAsAwardedMutation = {
  markJobAsAwarded?:  {
    __typename: "JobSummary",
    jobId: string,
    jobName: string,
    specialInstructions?: string | null,
    startDate?: string | null,
    stopDate?: string | null,
    jobType?: string | null,
    customerId?: string | null,
    jobCustodian?: string | null,
    dataStatus?: JobStatus | null,
    jobFacilities?:  Array< {
      __typename: "JobFacility",
      jobId: string,
      facilityId: string,
      mdFacilityType: string,
      facilityAddress?: string | null,
      remarks?: string | null,
      status?: string | null,
      jobStartDate?: string | null,
      jobEndData?: string | null,
    } | null > | null,
    jobServices?:  Array< {
      __typename: "JobService",
      jobId: string,
      mdServiceId: string,
      serviceMeasure: number,
      mdServiceUnits: string,
      tasks?:  Array< {
        __typename: "JobServiceTasks",
        taskId: string,
        taskName: string,
      } | null > | null,
      actualMdServiceUnits?: number | null,
      actualServiceMeasure?: string | null,
    } | null > | null,
    jobFrequencies?:  Array< {
      __typename: "JobFrequency",
      mdShiftType?: string | null,
      mdScheduleType?: string | null,
      mdServiceId?: string | null,
      scheduleDays?: string | null,
      fromTime?: string | null,
      toTime?: string | null,
      cronExpression?: string | null,
      repeatitions?: number | null,
      repetition?: number | null,
    } | null > | null,
    jobVendors?:  Array< {
      __typename: "JobVendor",
      jobId: string,
      vendorId: string,
      mdVendorType: string,
    } | null > | null,
    jobDueDates?:  Array< {
      __typename: "JobDueDate",
      jobId?: string | null,
      mdDueType?: string | null,
      dueDescription?: string | null,
      dueDate?: string | null,
    } | null > | null,
    jobAttachments?:  Array< {
      __typename: "JobAttachment",
      attachmentId?: string | null,
      mdAttachmentType?: string | null,
      fileName?: string | null,
      mdFileContext?: string | null,
      fileExtension?: string | null,
      url?: string | null,
      dataStatus?: string | null,
      comments?: string | null,
      jobId?: string | null,
      facilityId?: string | null,
      tags?: string | null,
    } | null > | null,
  } | null,
};

export type AddJobVendorsMutationVariables = {
  customerId: string,
  jobId: string,
  createJobVendorInput: Array< CreateJobVendorInput | null >,
};

export type AddJobVendorsMutation = {
  addJobVendors?:  {
    __typename: "JobVendorUpdate",
    added?:  Array< {
      __typename: "JobVendor",
      jobId: string,
      vendorId: string,
      mdVendorType: string,
    } | null > | null,
    deleted?:  Array< {
      __typename: "JobVendor",
      jobId: string,
      vendorId: string,
      mdVendorType: string,
    } | null > | null,
    reactivated?:  Array< {
      __typename: "JobVendor",
      jobId: string,
      vendorId: string,
      mdVendorType: string,
    } | null > | null,
  } | null,
};

export type AddJobDueDatesMutationVariables = {
  customerId: string,
  jobId: string,
  createJobDueDatesInput: Array< CreateJobDueDateInput | null >,
};

export type AddJobDueDatesMutation = {
  addJobDueDates?:  {
    __typename: "JobDueDateUpdate",
    added?:  Array< {
      __typename: "JobDueDate",
      jobId?: string | null,
      mdDueType?: string | null,
      dueDescription?: string | null,
      dueDate?: string | null,
    } | null > | null,
    deleted?:  Array< {
      __typename: "JobDueDate",
      jobId?: string | null,
      mdDueType?: string | null,
      dueDescription?: string | null,
      dueDate?: string | null,
    } | null > | null,
    reactivated?:  Array< {
      __typename: "JobDueDate",
      jobId?: string | null,
      mdDueType?: string | null,
      dueDescription?: string | null,
      dueDate?: string | null,
    } | null > | null,
    modified?:  Array< {
      __typename: "JobDueDate",
      jobId?: string | null,
      mdDueType?: string | null,
      dueDescription?: string | null,
      dueDate?: string | null,
    } | null > | null,
  } | null,
};

export type AddJobBidMutationVariables = {
  jobId: string,
  vendorId: string,
  createJobBidInput: JobBidInput,
};

export type AddJobBidMutation = {
  addJobBid?:  {
    __typename: "JobBid",
    bidId?: string | null,
    jobId?: string | null,
    bidName?: string | null,
    totalCost?: number | null,
    profitMargin?: number | null,
    finalQuote?: number | null,
    bidEstimationType?: BidType | null,
    bidCreationDate?: string | null,
    draftStep?: BidDraftStep | null,
    isFlatMargin?: YesNoType | null,
    draftStatus?: DraftStatus | null,
    dataStatus?: BidStatus | null,
    bidTemplate?: string | null,
    bidVersion?: number | null,
    bidVersionCreatedDate?: string | null,
    submittedBy?: string | null,
    bidSubmissionDate?: string | null,
    mdFrequencyType?: MdFrequencyType | null,
    comments?: string | null,
    bidCreatedFromSample?: string | null,
    errorCode?: string | null,
    vendorId?: string | null,
    vendorName?: string | null,
    vendorEmail?: string | null,
  } | null,
};

export type SubmitBidMutationVariables = {
  bidId: string,
  bidVendorInput?: BidVendorInput | null,
};

export type SubmitBidMutation = {
  submitBid?:  {
    __typename: "JobBid",
    bidId?: string | null,
    jobId?: string | null,
    bidName?: string | null,
    totalCost?: number | null,
    profitMargin?: number | null,
    finalQuote?: number | null,
    bidEstimationType?: BidType | null,
    bidCreationDate?: string | null,
    draftStep?: BidDraftStep | null,
    isFlatMargin?: YesNoType | null,
    draftStatus?: DraftStatus | null,
    dataStatus?: BidStatus | null,
    bidTemplate?: string | null,
    bidVersion?: number | null,
    bidVersionCreatedDate?: string | null,
    submittedBy?: string | null,
    bidSubmissionDate?: string | null,
    mdFrequencyType?: MdFrequencyType | null,
    comments?: string | null,
    bidCreatedFromSample?: string | null,
    errorCode?: string | null,
    vendorId?: string | null,
    vendorName?: string | null,
    vendorEmail?: string | null,
  } | null,
};

export type UpdateBidMutationVariables = {
  vendorId?: string | null,
  bidId: string,
  updateJobInput?: UpdateJobBidInput | null,
};

export type UpdateBidMutation = {
  updateBid?:  {
    __typename: "JobBid",
    bidId?: string | null,
    jobId?: string | null,
    bidName?: string | null,
    totalCost?: number | null,
    profitMargin?: number | null,
    finalQuote?: number | null,
    bidEstimationType?: BidType | null,
    bidCreationDate?: string | null,
    draftStep?: BidDraftStep | null,
    isFlatMargin?: YesNoType | null,
    draftStatus?: DraftStatus | null,
    dataStatus?: BidStatus | null,
    bidTemplate?: string | null,
    bidVersion?: number | null,
    bidVersionCreatedDate?: string | null,
    submittedBy?: string | null,
    bidSubmissionDate?: string | null,
    mdFrequencyType?: MdFrequencyType | null,
    comments?: string | null,
    bidCreatedFromSample?: string | null,
    errorCode?: string | null,
    vendorId?: string | null,
    vendorName?: string | null,
    vendorEmail?: string | null,
  } | null,
};

export type AddJobBidEstimationMutationVariables = {
  jobId: string,
  bidId: string,
  createJobBidEstimationInput: Array< CreateJobBidEstimationInput | null >,
};

export type AddJobBidEstimationMutation = {
  addJobBidEstimation?:  {
    __typename: "JobBidEstimationUpdate",
    added?:  Array< {
      __typename: "JobBidEstimation",
      jobId: string,
      estimationId: string,
      bidId: string,
      mdCategoryId: string,
      mdTaskId?: string | null,
      mdMeasureType: string,
      rate: number,
      quantity: number,
      isOverTime?: YesNoType | null,
      totalCost: number,
      isVendorAdded: YesNoType,
      isBillable: Billable,
      numberOfOccurrences?: number | null,
      mdUnits?: string | null,
      tasks?:  Array< {
        __typename: "JobServiceTasks",
        taskId: string,
        taskName: string,
      } | null > | null,
    } | null > | null,
    deleted?:  Array< {
      __typename: "JobBidEstimation",
      jobId: string,
      estimationId: string,
      bidId: string,
      mdCategoryId: string,
      mdTaskId?: string | null,
      mdMeasureType: string,
      rate: number,
      quantity: number,
      isOverTime?: YesNoType | null,
      totalCost: number,
      isVendorAdded: YesNoType,
      isBillable: Billable,
      numberOfOccurrences?: number | null,
      mdUnits?: string | null,
      tasks?:  Array< {
        __typename: "JobServiceTasks",
        taskId: string,
        taskName: string,
      } | null > | null,
    } | null > | null,
    modified?:  Array< {
      __typename: "JobBidEstimation",
      jobId: string,
      estimationId: string,
      bidId: string,
      mdCategoryId: string,
      mdTaskId?: string | null,
      mdMeasureType: string,
      rate: number,
      quantity: number,
      isOverTime?: YesNoType | null,
      totalCost: number,
      isVendorAdded: YesNoType,
      isBillable: Billable,
      numberOfOccurrences?: number | null,
      mdUnits?: string | null,
      tasks?:  Array< {
        __typename: "JobServiceTasks",
        taskId: string,
        taskName: string,
      } | null > | null,
    } | null > | null,
  } | null,
};

export type UpdateJobBidEstimationMutationVariables = {
  estimationId: string,
  updateJobBidEstimationInput: UpdateJobBidEstimationInput,
};

export type UpdateJobBidEstimationMutation = {
  updateJobBidEstimation?:  {
    __typename: "JobBidEstimation",
    jobId: string,
    estimationId: string,
    bidId: string,
    mdCategoryId: string,
    mdTaskId?: string | null,
    mdMeasureType: string,
    rate: number,
    quantity: number,
    isOverTime?: YesNoType | null,
    totalCost: number,
    isVendorAdded: YesNoType,
    isBillable: Billable,
    numberOfOccurrences?: number | null,
    mdUnits?: string | null,
    tasks?:  Array< {
      __typename: "JobServiceTasks",
      taskId: string,
      taskName: string,
    } | null > | null,
  } | null,
};

export type AddJobBidAdditionalExpensesMutationVariables = {
  jobId: string,
  bidId: string,
  createJobBidAdditionalExpensesInput: Array< CreateJobBidAdditionalExpensesInput | null >,
};

export type AddJobBidAdditionalExpensesMutation = {
  addJobBidAdditionalExpenses?:  {
    __typename: "CreateOrUpdateJobBidAdditionalExpense",
    added?:  Array< {
      __typename: "JobBidAdditionalExpenses",
      bidId: string,
      bidAdditionalExpenseId?: string | null,
      name?: string | null,
      description?: string | null,
      bidSimpleEstimationEstimationId?: string | null,
      rate: number,
      quantity?: number | null,
      totalCost?: number | null,
      mdUnits?: string | null,
      mdServiceCategoryId?: string | null,
      mdAdditionalExpenseCategory: string,
      draftStatus?: DraftStatus | null,
    } | null > | null,
    deleted?:  Array< {
      __typename: "JobBidAdditionalExpenses",
      bidId: string,
      bidAdditionalExpenseId?: string | null,
      name?: string | null,
      description?: string | null,
      bidSimpleEstimationEstimationId?: string | null,
      rate: number,
      quantity?: number | null,
      totalCost?: number | null,
      mdUnits?: string | null,
      mdServiceCategoryId?: string | null,
      mdAdditionalExpenseCategory: string,
      draftStatus?: DraftStatus | null,
    } | null > | null,
    modified?:  Array< {
      __typename: "JobBidAdditionalExpenses",
      bidId: string,
      bidAdditionalExpenseId?: string | null,
      name?: string | null,
      description?: string | null,
      bidSimpleEstimationEstimationId?: string | null,
      rate: number,
      quantity?: number | null,
      totalCost?: number | null,
      mdUnits?: string | null,
      mdServiceCategoryId?: string | null,
      mdAdditionalExpenseCategory: string,
      draftStatus?: DraftStatus | null,
    } | null > | null,
  } | null,
};

export type AddFacilityServiceSpecificationMutationVariables = {
  customerId: string,
  facilityId: string,
  facilityServiceSpecificationInput?: Array< CreateFacilityServiceSpecificationInput | null > | null,
};

export type AddFacilityServiceSpecificationMutation = {
  addFacilityServiceSpecification?:  {
    __typename: "FacilityServiceSpecificationUpdate",
    added?:  Array< {
      __typename: "FacilityServiceSpecification",
      facilityId: string,
      floorNumber?: string | null,
      mdServiceId: string,
      serviceQuantity: number,
      mdServiceUnitType: string,
    } | null > | null,
    deleted?:  Array< {
      __typename: "FacilityServiceSpecification",
      facilityId: string,
      floorNumber?: string | null,
      mdServiceId: string,
      serviceQuantity: number,
      mdServiceUnitType: string,
    } | null > | null,
    reactivated?:  Array< {
      __typename: "FacilityServiceSpecification",
      facilityId: string,
      floorNumber?: string | null,
      mdServiceId: string,
      serviceQuantity: number,
      mdServiceUnitType: string,
    } | null > | null,
    modified?:  Array< {
      __typename: "FacilityServiceSpecification",
      facilityId: string,
      floorNumber?: string | null,
      mdServiceId: string,
      serviceQuantity: number,
      mdServiceUnitType: string,
    } | null > | null,
  } | null,
};

export type AddPreShiftChecklistTemplateMutationVariables = {
  workOrderId: string,
  createPreShiftChecklistTemplateInput: PreShiftChecklistTemplateInput,
};

export type AddPreShiftChecklistTemplateMutation = {
  addPreShiftChecklistTemplate?:  {
    __typename: "PreShiftChecklistTemplate",
    preShiftChecklistTemplateId: string,
    checkListName: string,
    checkListIconUrl: string,
    mdPreShiftCheckListCategoryId: string,
    buildingAccessConfirmation: WorkOrderYesNoType,
    securityAccessConfirmation: WorkOrderYesNoType,
    safetyConfirmation: WorkOrderYesNoType,
    msdsChemicalSafety: WorkOrderYesNoType,
    workStatus: WorkOrderStatus,
    workOrderId: string,
    workOrderRecSeq: number,
    preShiftChecklistTemplateItems?:  Array< {
      __typename: "PreShiftChecklistTemplateItem",
      itemName: string,
      preShiftChecklistTemplateItemId: string,
      mdItemActionType: PreShiftChecklistTemplateItemActionType,
      quantity: number,
      mdQuantityType: PreShiftChecklistTemplateItemQuantityType,
    } | null > | null,
  } | null,
};

export type UpdatePreShiftChecklistTemplateMutationVariables = {
  preShiftChecklistTemplateId: string,
  createPreShiftChecklistTemplateInput: PreShiftChecklistTemplateInput,
};

export type UpdatePreShiftChecklistTemplateMutation = {
  updatePreShiftChecklistTemplate?:  {
    __typename: "PreShiftChecklistTemplateUpdate",
    preShiftChecklistTemplateId: string,
    checkListName: string,
    checkListIconUrl: string,
    mdPreShiftCheckListCategoryId: string,
    buildingAccessConfirmation: WorkOrderYesNoType,
    securityAccessConfirmation: WorkOrderYesNoType,
    safetyConfirmation: WorkOrderYesNoType,
    msdsChemicalSafety: WorkOrderYesNoType,
    workStatus: WorkOrderStatus,
    workOrderId: string,
    workOrderRecSeq: number,
    updatedPreShiftChecklistTemplateItems?:  {
      __typename: "PreShiftChecklistTemplateItemUpdate",
      added?:  Array< {
        __typename: "PreShiftChecklistTemplateItem",
        itemName: string,
        preShiftChecklistTemplateItemId: string,
        mdItemActionType: PreShiftChecklistTemplateItemActionType,
        quantity: number,
        mdQuantityType: PreShiftChecklistTemplateItemQuantityType,
      } | null > | null,
      deleted?:  Array< {
        __typename: "PreShiftChecklistTemplateItem",
        itemName: string,
        preShiftChecklistTemplateItemId: string,
        mdItemActionType: PreShiftChecklistTemplateItemActionType,
        quantity: number,
        mdQuantityType: PreShiftChecklistTemplateItemQuantityType,
      } | null > | null,
      modified?:  Array< {
        __typename: "PreShiftChecklistTemplateItem",
        itemName: string,
        preShiftChecklistTemplateItemId: string,
        mdItemActionType: PreShiftChecklistTemplateItemActionType,
        quantity: number,
        mdQuantityType: PreShiftChecklistTemplateItemQuantityType,
      } | null > | null,
    } | null,
  } | null,
};

export type AddPreShiftChecklistMutationVariables = {
  preShiftChecklistTemplateId: string,
  createPreShiftChecklistInput: Array< PreShiftChecklistInput | null >,
};

export type AddPreShiftChecklistMutation = {
  addPreShiftChecklist?:  {
    __typename: "PreShiftChecklist",
    executionDate: string,
    workDayId?: string | null,
    preShiftChecklistId?: string | null,
    checkListName?: string | null,
    checkListIconUrl?: string | null,
    mdPreShiftCheckListCategoryId?: string | null,
    preShiftChecklistTemplateId: string,
    preShiftChecklistItems?:  Array< {
      __typename: "PreShiftChecklistItem",
      itemName?: string | null,
      mdItemActionType?: PreShiftChecklistTemplateItemActionType | null,
      quantity?: number | null,
      mdQuantityType?: PreShiftChecklistTemplateItemQuantityType | null,
      workOrderServiceId?: string | null,
      markedAsDone?: WorkOrderYesNoType | null,
      markedBy?: string | null,
      markedOn?: string | null,
      preShiftChecklistId?: string | null,
      preShiftChecklistItemId?: string | null,
      preShiftChecklistTemplateItemId: string,
      preShiftChecklistIssueId?: string | null,
    } | null > | null,
  } | null,
};

export type UpdatePreShiftChecklistMutationVariables = {
  preShiftChecklistId: string,
  createPreShiftChecklistInput: PreShiftChecklistInput,
};

export type UpdatePreShiftChecklistMutation = {
  updatePreShiftChecklist?:  {
    __typename: "PreShiftChecklist",
    executionDate: string,
    workDayId?: string | null,
    preShiftChecklistId?: string | null,
    checkListName?: string | null,
    checkListIconUrl?: string | null,
    mdPreShiftCheckListCategoryId?: string | null,
    preShiftChecklistTemplateId: string,
    preShiftChecklistItems?:  Array< {
      __typename: "PreShiftChecklistItem",
      itemName?: string | null,
      mdItemActionType?: PreShiftChecklistTemplateItemActionType | null,
      quantity?: number | null,
      mdQuantityType?: PreShiftChecklistTemplateItemQuantityType | null,
      workOrderServiceId?: string | null,
      markedAsDone?: WorkOrderYesNoType | null,
      markedBy?: string | null,
      markedOn?: string | null,
      preShiftChecklistId?: string | null,
      preShiftChecklistItemId?: string | null,
      preShiftChecklistTemplateItemId: string,
      preShiftChecklistIssueId?: string | null,
    } | null > | null,
  } | null,
};

export type AddShiftDetailsMutationVariables = {
  workOrderId: string,
  createShiftDetailsInput: Array< ShiftDetailsInput | null >,
};

export type AddShiftDetailsMutation = {
  addShiftDetails?:  {
    __typename: "ShiftDetailsUpdate",
    added?:  Array< {
      __typename: "ShiftDetails",
      shiftDetailId: string,
      startTime: string,
      endTime: string,
      workOrderId: string,
      punchInGraceMinutes?: number | null,
      punchOutGraceMinutes?: number | null,
    } | null > | null,
    deleted?:  Array< {
      __typename: "ShiftDetails",
      shiftDetailId: string,
      startTime: string,
      endTime: string,
      workOrderId: string,
      punchInGraceMinutes?: number | null,
      punchOutGraceMinutes?: number | null,
    } | null > | null,
    modified?:  Array< {
      __typename: "ShiftDetails",
      shiftDetailId: string,
      startTime: string,
      endTime: string,
      workOrderId: string,
      punchInGraceMinutes?: number | null,
      punchOutGraceMinutes?: number | null,
    } | null > | null,
  } | null,
};

export type AddJobInterestMutationVariables = {
  customerId: string,
  vendorId?: string | null,
  createJobInterestInput: Array< CreateInputJobInterest | null >,
};

export type AddJobInterestMutation = {
  addJobInterest?:  Array< {
    __typename: "JobInterest",
    vendorId: string,
    jobId: string,
    actionType: string,
    actionTime?: string | null,
    actionUser?: string | null,
  } | null > | null,
};

export type UpdateJobInterestMutationVariables = {
  customerId: string,
  vendorId?: string | null,
  createJobInterestInput: CreateInputJobInterest,
};

export type UpdateJobInterestMutation = {
  updateJobInterest?:  Array< {
    __typename: "JobInterest",
    vendorId: string,
    jobId: string,
    actionType: string,
    actionTime?: string | null,
    actionUser?: string | null,
  } | null > | null,
};

export type UpdateWorkOrderStatusMutationVariables = {
  updateWorkOrderInput: UpdateWorkOrderInput,
};

export type UpdateWorkOrderStatusMutation = {
  updateWorkOrderStatus?:  {
    __typename: "WorkOrder",
    workOrderId?: string | null,
    jobId?: string | null,
    bidId?: string | null,
    vendorId?: string | null,
    customerId?: string | null,
    name?: string | null,
    status?: WorkOrderStatus | null,
    totalAmount?: number | null,
    createdBy?: string | null,
    createdOn?: string | null,
    modifiedBy?: string | null,
    modifiedOn?: string | null,
    jobStartDate?: string | null,
    jobType?: JobType | null,
    jobEndDate?: string | null,
    actualStartDate?: string | null,
    actualEndDate?: string | null,
    jobCustodian?: string | null,
    jobOrderAcceptedOn?: string | null,
    jobOrderAcceptedBy?: string | null,
    jobOrderFilePath?: string | null,
    paymentMethod?: PaymentMethod | null,
    paymentFrequency?: PaymentFrequency | null,
    paymentDay?: string | null,
    facilities?:  Array< {
      __typename: "WorkOrderFacilityAccess",
      facilityId?: string | null,
      facilityName?: string | null,
      facilityAddress?: string | null,
      accessContactPerson?: string | null,
      accessContactDetails?: string | null,
      otherInformation?: string | null,
      accessInfo?: string | null,
      accessCode?: string | null,
      day?: string | null,
      accessFrom?: string | null,
      accessTo?: string | null,
      from?: string | null,
      to?: string | null,
      workOrderFacilityAccessId?: string | null,
    } | null > | null,
    services?:  Array< {
      __typename: "WorkOrderService",
      workOrderServiceId?: string | null,
      mdServiceId?: string | null,
      serviceMeasure?: number | null,
      mdServiceUnits?: string | null,
      taskSchedule?:  Array< {
        __typename: "WorkOrderTaskSchedule",
        taskScheduleId?: string | null,
        mdTaskId?: string | null,
        isProofOfCompletionRequired?: WorkOrderYesNoType | null,
      } | null > | null,
    } | null > | null,
    jobBidSummary?:  {
      __typename: "WorkOrderJobBidSummary",
      jobBidSummaryId?: string | null,
      jobDetails?: string | null,
      bidDetails?: string | null,
    } | null,
    workOrderTeams?:  Array< {
      __typename: "WorkOrderTeam",
      teamId: string,
      vendorId?: string | null,
      userId?: string | null,
      roleId?: string | null,
      isSupervisor?: boolean | null,
      workOrderId?: string | null,
      jobAccepted?: boolean | null,
      associateStartDate?: string | null,
      associateEndDate?: string | null,
    } | null > | null,
    shiftDetails?:  Array< {
      __typename: "ShiftDetailsOfWorkOrder",
      shiftDetailId?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      punchInGraceMinutes?: number | null,
      punchOutGraceMinutes?: number | null,
    } | null > | null,
    subWorkOrders?:  Array< {
      __typename: "SubWorkOrder",
      workOrderId?: string | null,
      jobId?: string | null,
      bidId?: string | null,
      vendorId?: string | null,
      customerId?: string | null,
      name?: string | null,
      status?: WorkOrderStatus | null,
      totalAmount?: number | null,
      createdBy?: string | null,
      createdOn?: string | null,
      modifiedBy?: string | null,
      modifiedOn?: string | null,
      jobStartDate?: string | null,
      jobType?: JobType | null,
      jobEndDate?: string | null,
      actualStartDate?: string | null,
      actualEndDate?: string | null,
      jobCustodian?: string | null,
      jobOrderAcceptedOn?: string | null,
      jobOrderAcceptedBy?: string | null,
      jobOrderFilePath?: string | null,
      paymentMethod?: PaymentMethod | null,
      paymentFrequency?: PaymentFrequency | null,
      paymentDay?: string | null,
      hasChild?: boolean | null,
      parentWorkOrderId?: string | null,
      services?:  Array< {
        __typename: "WorkOrderService",
        workOrderServiceId?: string | null,
        mdServiceId?: string | null,
        serviceMeasure?: number | null,
        mdServiceUnits?: string | null,
        taskSchedule?:  Array< {
          __typename: "WorkOrderTaskSchedule",
          taskScheduleId?: string | null,
          mdTaskId?: string | null,
          isProofOfCompletionRequired?: WorkOrderYesNoType | null,
        } | null > | null,
      } | null > | null,
      shiftDetails?:  Array< {
        __typename: "ShiftDetailsOfWorkOrder",
        shiftDetailId?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        punchInGraceMinutes?: number | null,
        punchOutGraceMinutes?: number | null,
      } | null > | null,
      jobBidSummary?:  {
        __typename: "WorkOrderJobBidSummary",
        jobBidSummaryId?: string | null,
        jobDetails?: string | null,
        bidDetails?: string | null,
      } | null,
      bidApprovalStatus?: string | null,
    } | null > | null,
    hasChild?: boolean | null,
    parentWorkOrderId?: string | null,
    jobCreator?: string | null,
    bidApprovalStatus?: string | null,
    allowVendorToReschedule?: boolean | null,
    isPaid?: boolean | null,
    workOrderRating?: number | null,
  } | null,
};

export type AddWorkOrderTeamMutationVariables = {
  workOrderId: string,
  createWorkOrderTeamInput: Array< CreateWorkOrderTeam | null >,
};

export type AddWorkOrderTeamMutation = {
  addWorkOrderTeam?:  {
    __typename: "WorkOrderTeamUpdate",
    added?:  Array< {
      __typename: "WorkOrderTeam",
      teamId: string,
      vendorId?: string | null,
      userId?: string | null,
      roleId?: string | null,
      isSupervisor?: boolean | null,
      workOrderId?: string | null,
      jobAccepted?: boolean | null,
      associateStartDate?: string | null,
      associateEndDate?: string | null,
    } | null > | null,
    deleted?:  Array< {
      __typename: "WorkOrderTeam",
      teamId: string,
      vendorId?: string | null,
      userId?: string | null,
      roleId?: string | null,
      isSupervisor?: boolean | null,
      workOrderId?: string | null,
      jobAccepted?: boolean | null,
      associateStartDate?: string | null,
      associateEndDate?: string | null,
    } | null > | null,
    reactivated?:  Array< {
      __typename: "WorkOrderTeam",
      teamId: string,
      vendorId?: string | null,
      userId?: string | null,
      roleId?: string | null,
      isSupervisor?: boolean | null,
      workOrderId?: string | null,
      jobAccepted?: boolean | null,
      associateStartDate?: string | null,
      associateEndDate?: string | null,
    } | null > | null,
    modified?:  Array< {
      __typename: "WorkOrderTeam",
      teamId: string,
      vendorId?: string | null,
      userId?: string | null,
      roleId?: string | null,
      isSupervisor?: boolean | null,
      workOrderId?: string | null,
      jobAccepted?: boolean | null,
      associateStartDate?: string | null,
      associateEndDate?: string | null,
    } | null > | null,
  } | null,
};

export type UpdateWorkOrderTeamMutationVariables = {
  workOrderId: string,
  updateWorkOrderTeamInput: Array< UpdateWorkOrderTeam >,
};

export type UpdateWorkOrderTeamMutation = {
  updateWorkOrderTeam?:  {
    __typename: "WorkOrderTeam",
    teamId: string,
    vendorId?: string | null,
    userId?: string | null,
    roleId?: string | null,
    isSupervisor?: boolean | null,
    workOrderId?: string | null,
    jobAccepted?: boolean | null,
    associateStartDate?: string | null,
    associateEndDate?: string | null,
  } | null,
};

export type AddWorkOrderFacilityAccessMutationVariables = {
  workOrderId: string,
  createWorkOrderFacilityAccessInput: CreateWorkOrderFacilityAccess,
};

export type AddWorkOrderFacilityAccessMutation = {
  addWorkOrderFacilityAccess?:  {
    __typename: "WorkOrderFacilityAccess",
    facilityId?: string | null,
    facilityName?: string | null,
    facilityAddress?: string | null,
    accessContactPerson?: string | null,
    accessContactDetails?: string | null,
    otherInformation?: string | null,
    accessInfo?: string | null,
    accessCode?: string | null,
    day?: string | null,
    accessFrom?: string | null,
    accessTo?: string | null,
    from?: string | null,
    to?: string | null,
    workOrderFacilityAccessId?: string | null,
  } | null,
};

export type UpdateTaskMutationVariables = {
  taskId: string,
  updateTaskInput: UpdateTaskInput,
};

export type UpdateTaskMutation = {
  updateTask?:  {
    __typename: "Task",
    taskId?: string | null,
    occurrenceDate?: string | null,
    suggestedReworkDate?: string | null,
    suggestedScheduleType?: TaskScheduleType | null,
    taskNumber?: number | null,
    markedAsDone?: string | null,
    markedBy?: string | null,
    markedOn?: string | null,
    taskStatus?: TaskStatus | null,
    workOrderId?: string | null,
    workDayId?: string | null,
    taskScheduleId?: string | null,
    mdServiceDescription?: string | null,
    plannedStartTime?: string | null,
    plannedEndTime?: string | null,
    plannedEffort?: string | null,
    actualEffort?: string | null,
    actualStartTime?: string | null,
    actualEndTime?: string | null,
    associateId?: string | null,
    taskSchedule?:  {
      __typename: "TaskSchedule",
      taskScheduleId: string,
      mdShiftType?: string | null,
      isProofOfCompletionRequired: WorkOrderYesNoType,
      schedule?: string | null,
      duration?: string | null,
      mdTaskId: string,
      noOfOccurrences?: number | null,
      noOfOccurrencesCompleted?: number | null,
    } | null,
    taskAssignment?:  Array< {
      __typename: "TaskAssignment",
      taskId?: string | null,
      taskAssignmentId?: string | null,
      assignedBy?: string | null,
      assignedOn?: string | null,
      associateId?: string | null,
    } | null > | null,
    reworkTaskId?: string | null,
    errorCode?: string | null,
  } | null,
};

export type UpdateMultipleTaskMutationVariables = {
  updateTaskInput?: Array< UpdateTaskInput > | null,
};

export type UpdateMultipleTaskMutation = {
  updateMultipleTask?:  Array< {
    __typename: "Task",
    taskId?: string | null,
    occurrenceDate?: string | null,
    suggestedReworkDate?: string | null,
    suggestedScheduleType?: TaskScheduleType | null,
    taskNumber?: number | null,
    markedAsDone?: string | null,
    markedBy?: string | null,
    markedOn?: string | null,
    taskStatus?: TaskStatus | null,
    workOrderId?: string | null,
    workDayId?: string | null,
    taskScheduleId?: string | null,
    mdServiceDescription?: string | null,
    plannedStartTime?: string | null,
    plannedEndTime?: string | null,
    plannedEffort?: string | null,
    actualEffort?: string | null,
    actualStartTime?: string | null,
    actualEndTime?: string | null,
    associateId?: string | null,
    taskSchedule?:  {
      __typename: "TaskSchedule",
      taskScheduleId: string,
      mdShiftType?: string | null,
      isProofOfCompletionRequired: WorkOrderYesNoType,
      schedule?: string | null,
      duration?: string | null,
      mdTaskId: string,
      noOfOccurrences?: number | null,
      noOfOccurrencesCompleted?: number | null,
    } | null,
    taskAssignment?:  Array< {
      __typename: "TaskAssignment",
      taskId?: string | null,
      taskAssignmentId?: string | null,
      assignedBy?: string | null,
      assignedOn?: string | null,
      associateId?: string | null,
    } | null > | null,
    reworkTaskId?: string | null,
    errorCode?: string | null,
  } | null > | null,
};

export type AddCustomerFacilityAccessMutationVariables = {
  customerId: string,
  facilityId: string,
  createCustomerFacilityAccessInput: CreateFacilityAccessDetails,
};

export type AddCustomerFacilityAccessMutation = {
  addCustomerFacilityAccess?:  {
    __typename: "CustomerFacilityAccessDetails",
    facilityAccessDetailId: string,
    facilityId: string,
    jobId?: string | null,
    accessContactPerson: string,
    roleName: string,
    accessContactDetails: string,
    otherInformation?: string | null,
    accessInfo?: string | null,
    accessCode?: string | null,
    day?: string | null,
    from?: string | null,
    to?: string | null,
  } | null,
};

export type UpdateCustomerFacilityAccessMutationVariables = {
  customerId: string,
  facilityId: string,
  updateCustomerFacilityAccessInput: UpdateFacilityAccessDetails,
};

export type UpdateCustomerFacilityAccessMutation = {
  updateCustomerFacilityAccess?:  {
    __typename: "CustomerFacilityAccessDetails",
    facilityAccessDetailId: string,
    facilityId: string,
    jobId?: string | null,
    accessContactPerson: string,
    roleName: string,
    accessContactDetails: string,
    otherInformation?: string | null,
    accessInfo?: string | null,
    accessCode?: string | null,
    day?: string | null,
    from?: string | null,
    to?: string | null,
  } | null,
};

export type UpdateTaskScheduleMutationVariables = {
  updateTaskScheduleInput: Array< UpdateTaskScheduleInput | null >,
};

export type UpdateTaskScheduleMutation = {
  updateTaskSchedule?:  Array< {
    __typename: "TaskSchedule",
    taskScheduleId: string,
    mdShiftType?: string | null,
    isProofOfCompletionRequired: WorkOrderYesNoType,
    schedule?: string | null,
    duration?: string | null,
    mdTaskId: string,
    noOfOccurrences?: number | null,
    noOfOccurrencesCompleted?: number | null,
  } | null > | null,
};

export type AddJobAcceptanceMutationVariables = {
  teamId: string,
  workOrderId: string,
  assignTeamInput: AssignTeamInput,
};

export type AddJobAcceptanceMutation = {
  addJobAcceptance?:  {
    __typename: "WorkOrderTeam",
    teamId: string,
    vendorId?: string | null,
    userId?: string | null,
    roleId?: string | null,
    isSupervisor?: boolean | null,
    workOrderId?: string | null,
    jobAccepted?: boolean | null,
    associateStartDate?: string | null,
    associateEndDate?: string | null,
  } | null,
};

export type AddTaskAttachmentMutationVariables = {
  taskId: string,
  createTaskAttachment: Array< CreateTaskAttachmentInput | null >,
};

export type AddTaskAttachmentMutation = {
  addTaskAttachment?:  {
    __typename: "TaskAttachmentUpdate",
    added?:  Array< {
      __typename: "TaskAttachment",
      attachmentId?: string | null,
      attachmentStatus?: AttachmentStatus | null,
      mdAttachmentType?: string | null,
      fileName?: string | null,
      mdFileContext?: string | null,
      fileExtension?: string | null,
      url?: string | null,
      fileHash?: string | null,
      comments?: string | null,
      createdById?: string | null,
      createdByName?: string | null,
    } | null > | null,
    deleted?:  Array< {
      __typename: "TaskAttachment",
      attachmentId?: string | null,
      attachmentStatus?: AttachmentStatus | null,
      mdAttachmentType?: string | null,
      fileName?: string | null,
      mdFileContext?: string | null,
      fileExtension?: string | null,
      url?: string | null,
      fileHash?: string | null,
      comments?: string | null,
      createdById?: string | null,
      createdByName?: string | null,
    } | null > | null,
  } | null,
};

export type AddTaskApprovalMutationVariables = {
  taskId: string,
  createTaskApproval: Array< CreateTaskApprovalInput | null >,
};

export type AddTaskApprovalMutation = {
  addTaskApproval?:  {
    __typename: "TaskApprovalUpdate",
    added?:  Array< {
      __typename: "TaskApproval",
      approvalId: string,
      approvalName?: ApprovalType | null,
      dueDate?: string | null,
      startDate?: string | null,
      completionDate?: string | null,
      reason?: string | null,
      status?: string | null,
      rankingRate?: number | null,
      approvalStatus?: string | null,
      mdApproverType?: string | null,
    } | null > | null,
    modified?:  Array< {
      __typename: "TaskApproval",
      approvalId: string,
      approvalName?: ApprovalType | null,
      dueDate?: string | null,
      startDate?: string | null,
      completionDate?: string | null,
      reason?: string | null,
      status?: string | null,
      rankingRate?: number | null,
      approvalStatus?: string | null,
      mdApproverType?: string | null,
    } | null > | null,
  } | null,
};

export type GenerateW9MutationVariables = {
  w9Input?: W9Input | null,
};

export type GenerateW9Mutation = {
  generateW9?:  {
    __typename: "w9Response",
    embedURL?:  {
      __typename: "embeddedSignURL",
      embeddedSigningURL: string,
    } | null,
    requestId?: string | null,
  } | null,
};

export type AddPunchInOutMutationVariables = {
  workDayId: string,
  createPunchCard?: PunchCardInput | null,
};

export type AddPunchInOutMutation = {
  addPunchInOut?:  {
    __typename: "PunchCardItem",
    punchCardId?: string | null,
    workDayId?: string | null,
    userId?: string | null,
    punchTime?: string | null,
    punchType?: PunchType | null,
    locationLatitude?: number | null,
    locationLongitude?: number | null,
    punchComments?: string | null,
    status?: string | null,
    errorCode?: string | null,
    punchNotifications?: string | null,
    punchMealPeriodAttestation?: string | null,
    punchWorkersCompConfirmation?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    punchInTime?: string | null,
    punchOutTime?: string | null,
    totalWorkMinutes?: number | null,
    totalMinutesOnSite?: number | null,
    facilityDistance?: number | null,
  } | null,
};

export type AddPunchCardMutationVariables = {
  workDayId: string,
  workOrderId: string,
  createPunchCard?: PunchCardInput | null,
};

export type AddPunchCardMutation = {
  addPunchCard?:  {
    __typename: "PunchCardItem",
    punchCardId?: string | null,
    workDayId?: string | null,
    userId?: string | null,
    punchTime?: string | null,
    punchType?: PunchType | null,
    locationLatitude?: number | null,
    locationLongitude?: number | null,
    punchComments?: string | null,
    status?: string | null,
    errorCode?: string | null,
    punchNotifications?: string | null,
    punchMealPeriodAttestation?: string | null,
    punchWorkersCompConfirmation?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    punchInTime?: string | null,
    punchOutTime?: string | null,
    totalWorkMinutes?: number | null,
    totalMinutesOnSite?: number | null,
    facilityDistance?: number | null,
  } | null,
};

export type AddShiftBreakMutationVariables = {
  workDayId: string,
  createShiftBreak: ShiftBreakInput,
};

export type AddShiftBreakMutation = {
  addShiftBreak?:  {
    __typename: "ShiftBreakItem",
    recordId: string,
    punchType: ShiftBreakType,
    facilityId: string,
    userId: string,
    startTime: string,
    endTime?: string | null,
    comments?: string | null,
  } | null,
};

export type UpdateShiftBreakMutationVariables = {
  workDayId: string,
  updateShiftBreak: ShiftBreakInput,
};

export type UpdateShiftBreakMutation = {
  updateShiftBreak?:  {
    __typename: "ShiftBreakItem",
    recordId: string,
    punchType: ShiftBreakType,
    facilityId: string,
    userId: string,
    startTime: string,
    endTime?: string | null,
    comments?: string | null,
  } | null,
};

export type AddPunchInOutBySupervisorMutationVariables = {
  workDayId: string,
  createPunchCard: Array< PunchCardInput | null >,
};

export type AddPunchInOutBySupervisorMutation = {
  addPunchInOutBySupervisor?:  Array< {
    __typename: "PunchCardItem",
    punchCardId?: string | null,
    workDayId?: string | null,
    userId?: string | null,
    punchTime?: string | null,
    punchType?: PunchType | null,
    locationLatitude?: number | null,
    locationLongitude?: number | null,
    punchComments?: string | null,
    status?: string | null,
    errorCode?: string | null,
    punchNotifications?: string | null,
    punchMealPeriodAttestation?: string | null,
    punchWorkersCompConfirmation?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    punchInTime?: string | null,
    punchOutTime?: string | null,
    totalWorkMinutes?: number | null,
    totalMinutesOnSite?: number | null,
    facilityDistance?: number | null,
  } | null > | null,
};

export type UpdatePunchInOutMutationVariables = {
  updatePunchCard?: Array< UpdatePunchCardInput | null > | null,
};

export type UpdatePunchInOutMutation = {
  updatePunchInOut?:  Array< {
    __typename: "PunchCardItem",
    punchCardId?: string | null,
    workDayId?: string | null,
    userId?: string | null,
    punchTime?: string | null,
    punchType?: PunchType | null,
    locationLatitude?: number | null,
    locationLongitude?: number | null,
    punchComments?: string | null,
    status?: string | null,
    errorCode?: string | null,
    punchNotifications?: string | null,
    punchMealPeriodAttestation?: string | null,
    punchWorkersCompConfirmation?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    punchInTime?: string | null,
    punchOutTime?: string | null,
    totalWorkMinutes?: number | null,
    totalMinutesOnSite?: number | null,
    facilityDistance?: number | null,
  } | null > | null,
};

export type UpdateTaskAttachmentMutationVariables = {
  taskId: string,
  updateTaskAttachmentInput?: Array< UpdateTaskAttachmentInput | null > | null,
};

export type UpdateTaskAttachmentMutation = {
  updateTaskAttachment?:  Array< {
    __typename: "TaskAttachment",
    attachmentId?: string | null,
    attachmentStatus?: AttachmentStatus | null,
    mdAttachmentType?: string | null,
    fileName?: string | null,
    mdFileContext?: string | null,
    fileExtension?: string | null,
    url?: string | null,
    fileHash?: string | null,
    comments?: string | null,
    createdById?: string | null,
    createdByName?: string | null,
  } | null > | null,
};

export type UpdateVendorDetailsMutationVariables = {
  vendorId: string,
  updateVendorDetailsInput?: VendorDetailsUpdate | null,
};

export type UpdateVendorDetailsMutation = {
  updateVendorDetails?:  {
    __typename: "VendorDetails",
    vendorId?: string | null,
    annualRevenueEstimate?: number | null,
    fax?: string | null,
    coi?: string | null,
    noOfEmployees?: number | null,
    workWeekDefinition?: string | null,
    w9FormUrl?: string | null,
  } | null,
};

export type CreateIssueMutationVariables = {
  createIssueInput?: CreateIssueInput | null,
};

export type CreateIssueMutation = {
  // associateVendorAddRequest(addRequestInput: AddRequestInput!): String!
  createIssue?:  {
    __typename: "Issue",
    issueId: string,
    title: string,
    shortDescription: string,
    issueRequestor?: string | null,
    issueResolver?: string | null,
    issuePriority?: IssuePriority | null,
    templateId?: string | null,
    entityId?: string | null,
    templateValue?: string | null,
    attachments?:  Array< {
      __typename: "Attachment",
      comments?: string | null,
      attachmentId?: string | null,
      attachmentStatus?: string | null,
      mdAttachmentType?: string | null,
      fileName?: string | null,
      mdFileContext?: string | null,
      fileExtension?: string | null,
      url?: string | null,
      fileHash?: string | null,
      dataStatus?: string | null,
    } | null > | null,
  } | null,
};

export type UpdateTaskAssignmentMutationVariables = {
  taskAssignmentInput?: AssignTaskInput | null,
};

export type UpdateTaskAssignmentMutation = {
  updateTaskAssignment:  Array< {
    __typename: "UpdatedTaskAssignment",
    associateId?: string | null,
    assignedBy?: string | null,
    assignedOn?: string | null,
    taskId?: string | null,
  } | null >,
};

export type AddInsuranceAttachmentMutationVariables = {
  insuranceId: string,
  insuranceAttachmentInput?: Array< VendorAttachmentInput | null > | null,
};

export type AddInsuranceAttachmentMutation = {
  addInsuranceAttachment?:  Array< {
    __typename: "VendorAttachment",
    attachmentId: string,
    refType?: RefType | null,
    refId?: string | null,
    fileName: string,
    mdFileContext: mdFileContext,
    fileExtension: string,
    url: string,
    dataStatus: DataStatus,
    date?: string | null,
    createdOn?: string | null,
    modifiedOn?: string | null,
  } | null > | null,
};

export type DeleteInsuranceAttachmentMutationVariables = {
  insuranceId: string,
  deleteInsuranceAttachmentInput?: Array< string > | null,
};

export type DeleteInsuranceAttachmentMutation = {
  deleteInsuranceAttachment?:  Array< {
    __typename: "VendorAttachment",
    attachmentId: string,
    refType?: RefType | null,
    refId?: string | null,
    fileName: string,
    mdFileContext: mdFileContext,
    fileExtension: string,
    url: string,
    dataStatus: DataStatus,
    date?: string | null,
    createdOn?: string | null,
    modifiedOn?: string | null,
  } | null > | null,
};

export type UpdateInsuranceAttachmentMutationVariables = {
  insuranceId: string,
  updateInsuranceAttachmentInput?: Array< UpdateVendorAttachmentInput | null > | null,
};

export type UpdateInsuranceAttachmentMutation = {
  updateInsuranceAttachment:  Array< {
    __typename: "VendorAttachment",
    attachmentId: string,
    refType?: RefType | null,
    refId?: string | null,
    fileName: string,
    mdFileContext: mdFileContext,
    fileExtension: string,
    url: string,
    dataStatus: DataStatus,
    date?: string | null,
    createdOn?: string | null,
    modifiedOn?: string | null,
  } | null >,
};

export type UpdateVendorAddressMutationVariables = {
  addressId: string,
  vendorAddressUpdate?: VendorAddressUpdate | null,
};

export type UpdateVendorAddressMutation = {
  updateVendorAddress?:  {
    __typename: "VendorAddress",
    addressId?: string | null,
    vendorId?: string | null,
    addressName?: string | null,
    addressLine1?: string | null,
    addressLine2?: string | null,
    addressLine3?: string | null,
    city?: string | null,
    stateCode?: string | null,
    county?: string | null,
    postalCode?: string | null,
    mdCountryCode?: string | null,
    landmark?: string | null,
    googlePlaceId?: string | null,
    latitude?: string | null,
    longitude?: string | null,
    mdMsaId?: string | null,
    modifiedOn?: string | null,
    regionId?: string | null,
  } | null,
};

export type UpdateUserDetailsMutationVariables = {
  updateUserDetailsInput?: UpdateUserInput | null,
};

export type UpdateUserDetailsMutation = {
  updateUserDetails?:  {
    __typename: "UpdateUserDetails",
    userId?: string | null,
    salutation?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    dataStatus?: string | null,
    username?: string | null,
  } | null,
};

export type AddUserToTeamMutationVariables = {
  userId: string,
  teamId: string,
};

export type AddUserToTeamMutation = {
  addUserToTeam?:  {
    __typename: "AddedUserToTeam",
    userId?: string | null,
    teamId?: string | null,
  } | null,
};

export type AddRoleToUserMutationVariables = {
  assignRoleToUserInput?: AssignRoleToUserInput | null,
};

export type AddRoleToUserMutation = {
  addRoleToUser?:  {
    __typename: "AddedRoleToUser",
    roleId?: string | null,
    userId?: string | null,
  } | null,
};

export type RemoveUserFromTeamMutationVariables = {
  userId: string,
  teamId: string,
};

export type RemoveUserFromTeamMutation = {
  removeUserFromTeam?:  {
    __typename: "RemoveUserFromTeam",
    userId?: string | null,
    teamId?: string | null,
    message?: string | null,
  } | null,
};

export type CreateOrganizationMutationVariables = {
  organizationInput?: CreateOrganizationInput | null,
};

export type CreateOrganizationMutation = {
  createOrganization?:  {
    __typename: "Organization",
    organizationId?: string | null,
    organizationName?: string | null,
    dataStatus?: string | null,
    teams?:  Array< {
      __typename: "OrganizationTeam",
      teamId?: string | null,
      name?: string | null,
    } | null > | null,
    users?:  Array< {
      __typename: "OrganizationUser",
      userId?: string | null,
    } | null > | null,
    errorCode?: string | null,
  } | null,
};

export type CreateRoleMutationVariables = {
  roleInput: CreateRole,
};

export type CreateRoleMutation = {
  createRole?:  {
    __typename: "RoleResponse",
    roleName?: string | null,
    roleId?: string | null,
    organizationId?: string | null,
  } | null,
};

export type CreateUserMutationVariables = {
  userInput: CreateUser,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "UserResponse",
    salutation?: string | null,
    username?: string | null,
    organizationId?: string | null,
    userId?: string | null,
    errorCode?: string | null,
  } | null,
};

export type RemoveUserMutationVariables = {
  removeUserInput: RemoveUser,
};

export type RemoveUserMutation = {
  removeUser?:  {
    __typename: "RemoveUserResponse",
    roleId?: string | null,
    featureId?: string | null,
  } | null,
};

export type AddServiceCategoryV3MutationVariables = {
  createServiceCategory?: ServiceCategoryV3Input | null,
};

export type AddServiceCategoryV3Mutation = {
  addServiceCategoryV3?:  {
    __typename: "ServiceTask",
    serviceCategoryId?: string | null,
    taskId?: string | null,
    mdAreaType?: string | null,
  } | null,
};

export type AddOrUpdateOrganizationUserMutationVariables = {
  addOrUpdateOrganizationUserInput?: AddOrganizationUserInput | null,
};

export type AddOrUpdateOrganizationUserMutation = {
  addOrUpdateOrganizationUser?:  {
    __typename: "AddOrUpdateUserForOrganization",
    userId?: string | null,
    salutation?: string | null,
    username?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    dataStatus?: string | null,
    teamId?: string | null,
    organizationId?: string | null,
  } | null,
};

export type AddRoleToVendorTeamMutationVariables = {
  vendorRoleInput?: AddVendorTeamRoleInput | null,
};

export type AddRoleToVendorTeamMutation = {
  addRoleToVendorTeam?:  {
    __typename: "VendorRoleOutput",
    roleId?: string | null,
    roleName?: string | null,
    vendorId?: string | null,
  } | null,
};

export type CreateJobAttachmentsMutationVariables = {
  jobId: string,
  facilityIds: string,
  createOrUpdateJobAttachmentInput?: Array< CreateOrUpdateJobAttachmentInput | null > | null,
};

export type CreateJobAttachmentsMutation = {
  createJobAttachments?:  Array< {
    __typename: "JobAttachment",
    attachmentId?: string | null,
    mdAttachmentType?: string | null,
    fileName?: string | null,
    mdFileContext?: string | null,
    fileExtension?: string | null,
    url?: string | null,
    dataStatus?: string | null,
    comments?: string | null,
    jobId?: string | null,
    facilityId?: string | null,
    tags?: string | null,
  } | null > | null,
};

export type DeleteJobAttachmentsMutationVariables = {
  jobId: string,
  attachmentIds: Array< string | null >,
};

export type DeleteJobAttachmentsMutation = {
  deleteJobAttachments?:  Array< {
    __typename: "JobAttachment",
    attachmentId?: string | null,
    mdAttachmentType?: string | null,
    fileName?: string | null,
    mdFileContext?: string | null,
    fileExtension?: string | null,
    url?: string | null,
    dataStatus?: string | null,
    comments?: string | null,
    jobId?: string | null,
    facilityId?: string | null,
    tags?: string | null,
  } | null > | null,
};

export type UpdateJobAttachmentsMutationVariables = {
  createOrUpdateJobAttachmentInput?: Array< CreateOrUpdateJobAttachmentInput | null > | null,
};

export type UpdateJobAttachmentsMutation = {
  updateJobAttachments?:  Array< {
    __typename: "JobAttachment",
    attachmentId?: string | null,
    mdAttachmentType?: string | null,
    fileName?: string | null,
    mdFileContext?: string | null,
    fileExtension?: string | null,
    url?: string | null,
    dataStatus?: string | null,
    comments?: string | null,
    jobId?: string | null,
    facilityId?: string | null,
    tags?: string | null,
  } | null > | null,
};

export type RemoveAssociateFromWorkOrderTeamMutationVariables = {
  removeAssociateInput?: RemoveAssociateInput | null,
};

export type RemoveAssociateFromWorkOrderTeamMutation = {
  removeAssociateFromWorkOrderTeam?:  Array< {
    __typename: "RemoveAssociateOutput",
    userId?: string | null,
    comments?: string | null,
  } | null > | null,
};

export type RemoveOrUpdateNoShowPunchCardMutationVariables = {
  workDayId: string,
  removeOrUpdatePunchCardInput?: Array< RemoveOrUpdatePunchCardInput | null > | null,
};

export type RemoveOrUpdateNoShowPunchCardMutation = {
  removeOrUpdateNoShowPunchCard?:  Array< {
    __typename: "PunchCardItem",
    punchCardId?: string | null,
    workDayId?: string | null,
    userId?: string | null,
    punchTime?: string | null,
    punchType?: PunchType | null,
    locationLatitude?: number | null,
    locationLongitude?: number | null,
    punchComments?: string | null,
    status?: string | null,
    errorCode?: string | null,
    punchNotifications?: string | null,
    punchMealPeriodAttestation?: string | null,
    punchWorkersCompConfirmation?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    punchInTime?: string | null,
    punchOutTime?: string | null,
    totalWorkMinutes?: number | null,
    totalMinutesOnSite?: number | null,
    facilityDistance?: number | null,
  } | null > | null,
};

export type CreateRegionMutationVariables = {
  regionInput: RegionInput,
};

export type CreateRegionMutation = {
  createRegion?:  {
    __typename: "Region",
    regionId: string,
    regionName: string,
    organizationId: string,
    parentRegionId?: string | null,
  } | null,
};

export type UpdateRegionMutationVariables = {
  regionId: string,
  regionInput: RegionInput,
};

export type UpdateRegionMutation = {
  updateRegion?:  {
    __typename: "Region",
    regionId: string,
    regionName: string,
    organizationId: string,
    parentRegionId?: string | null,
  } | null,
};

export type CreateBidAttachmentsMutationVariables = {
  bidId: string,
  createBidAttachmentInput?: Array< CreateBidAttachmentInput | null > | null,
};

export type CreateBidAttachmentsMutation = {
  createBidAttachments?:  Array< {
    __typename: "BidAttachment",
    attachmentId?: string | null,
    mdAttachmentType?: string | null,
    fileName?: string | null,
    mdFileContext?: string | null,
    fileExtension?: string | null,
    url?: string | null,
    dataStatus?: string | null,
    comments?: string | null,
    tags?: string | null,
  } | null > | null,
};

export type UpdateBidAttachmentsMutationVariables = {
  updateBidAttachmentInput?: Array< CreateBidAttachmentInput | null > | null,
};

export type UpdateBidAttachmentsMutation = {
  updateBidAttachments?:  Array< {
    __typename: "BidAttachment",
    attachmentId?: string | null,
    mdAttachmentType?: string | null,
    fileName?: string | null,
    mdFileContext?: string | null,
    fileExtension?: string | null,
    url?: string | null,
    dataStatus?: string | null,
    comments?: string | null,
    tags?: string | null,
  } | null > | null,
};

export type DeleteBidAttachmentsMutationVariables = {
  bidId: string,
  attachmentIds?: Array< string | null > | null,
};

export type DeleteBidAttachmentsMutation = {
  deleteBidAttachments?:  Array< {
    __typename: "BidAttachment",
    attachmentId?: string | null,
    mdAttachmentType?: string | null,
    fileName?: string | null,
    mdFileContext?: string | null,
    fileExtension?: string | null,
    url?: string | null,
    dataStatus?: string | null,
    comments?: string | null,
    tags?: string | null,
  } | null > | null,
};

export type CreateSubJobMutationVariables = {
  createSubJobInput?: CreateSubJobInput | null,
};

export type CreateSubJobMutation = {
  createSubJob?:  {
    __typename: "Job",
    jobId: string,
    jobName: string,
    startDate?: string | null,
    stopDate?: string | null,
    dataStatus?: string | null,
    specialInstructions?: string | null,
    draftStep?: DraftStep | null,
    draftStatus?: DraftStatus | null,
    jobType?: JobType | null,
  } | null,
};

export type CreateUserRoleScopeMutationVariables = {
  userRoleId: string,
  userRoleScopeInput: UserRoleScopeInput,
};

export type CreateUserRoleScopeMutation = {
  createUserRoleScope?:  Array< {
    __typename: "UserRoleScope",
    userRoleScopeId?: string | null,
    keyId?: string | null,
    keyType?: string | null,
    userId?: string | null,
    organizationId?: string | null,
    roleId?: string | null,
    customerId?: string | null,
    mdCountryCode?: string | null,
    regionId?: string | null,
    subRegionId?: string | null,
    facilityId?: string | null,
    userRoleId?: string | null,
    keyName?: string | null,
    userName?: string | null,
    organizationName?: string | null,
    roleName?: string | null,
    customerName?: string | null,
    regionName?: string | null,
    subRegionName?: string | null,
    facilityName?: string | null,
    createdByName?: string | null,
    modifiedByName?: string | null,
    intAddressId?: string | null,
    jobDescription?: string | null,
    jobNumber?: string | null,
    customerNumber?: string | null,
  } | null > | null,
};

export type UpdateUserRoleScopeMutationVariables = {
  userRoleScopeId: string,
  userRoleScopeInput: UserRoleScopeInput,
};

export type UpdateUserRoleScopeMutation = {
  updateUserRoleScope?:  {
    __typename: "UserRoleScope",
    userRoleScopeId?: string | null,
    keyId?: string | null,
    keyType?: string | null,
    userId?: string | null,
    organizationId?: string | null,
    roleId?: string | null,
    customerId?: string | null,
    mdCountryCode?: string | null,
    regionId?: string | null,
    subRegionId?: string | null,
    facilityId?: string | null,
    userRoleId?: string | null,
    keyName?: string | null,
    userName?: string | null,
    organizationName?: string | null,
    roleName?: string | null,
    customerName?: string | null,
    regionName?: string | null,
    subRegionName?: string | null,
    facilityName?: string | null,
    createdByName?: string | null,
    modifiedByName?: string | null,
    intAddressId?: string | null,
    jobDescription?: string | null,
    jobNumber?: string | null,
    customerNumber?: string | null,
  } | null,
};

export type DeleteUserRoleScopeMutationVariables = {
  userRoleScopeId: string,
};

export type DeleteUserRoleScopeMutation = {
  deleteUserRoleScope?:  {
    __typename: "UserRoleScope",
    userRoleScopeId?: string | null,
    keyId?: string | null,
    keyType?: string | null,
    userId?: string | null,
    organizationId?: string | null,
    roleId?: string | null,
    customerId?: string | null,
    mdCountryCode?: string | null,
    regionId?: string | null,
    subRegionId?: string | null,
    facilityId?: string | null,
    userRoleId?: string | null,
    keyName?: string | null,
    userName?: string | null,
    organizationName?: string | null,
    roleName?: string | null,
    customerName?: string | null,
    regionName?: string | null,
    subRegionName?: string | null,
    facilityName?: string | null,
    createdByName?: string | null,
    modifiedByName?: string | null,
    intAddressId?: string | null,
    jobDescription?: string | null,
    jobNumber?: string | null,
    customerNumber?: string | null,
  } | null,
};

export type GenerateTaskWithAssignmentsMutationVariables = {
  generateTasksInput?: GenerateTaskInput | null,
};

export type GenerateTaskWithAssignmentsMutation = {
  generateTaskWithAssignments?:  {
    __typename: "GenerateTaskOutput",
    errorCode?: string | null,
  } | null,
};

export type ProcessBidReviewMutationVariables = {
  transactionId: string,
  bidStatus: string,
  processBidReviewInput?: processBidReviewInput | null,
};

export type ProcessBidReviewMutation = {
  processBidReview?:  {
    __typename: "processBidReviewOutput",
    responseCode?: string | null,
  } | null,
};

export type ResendBidEmailMutationVariables = {
  bidId: string,
};

export type ResendBidEmailMutation = {
  resendBidEmail?:  {
    __typename: "JobBid",
    bidId?: string | null,
    jobId?: string | null,
    bidName?: string | null,
    totalCost?: number | null,
    profitMargin?: number | null,
    finalQuote?: number | null,
    bidEstimationType?: BidType | null,
    bidCreationDate?: string | null,
    draftStep?: BidDraftStep | null,
    isFlatMargin?: YesNoType | null,
    draftStatus?: DraftStatus | null,
    dataStatus?: BidStatus | null,
    bidTemplate?: string | null,
    bidVersion?: number | null,
    bidVersionCreatedDate?: string | null,
    submittedBy?: string | null,
    bidSubmissionDate?: string | null,
    mdFrequencyType?: MdFrequencyType | null,
    comments?: string | null,
    bidCreatedFromSample?: string | null,
    errorCode?: string | null,
    vendorId?: string | null,
    vendorName?: string | null,
    vendorEmail?: string | null,
  } | null,
};

export type GenerateInvoiceMutationVariables = {
  bidId: string,
  jobId: string,
  generateInvoiceInput: generateInvoiceInput,
};

export type GenerateInvoiceMutation = {
  generateInvoice?:  {
    __typename: "generateInvoiceOutput",
    responseCode?: string | null,
  } | null,
};

export type AddCommentsMutationVariables = {
  keyId: string,
  commentsInput?: CommentsInput | null,
};

export type AddCommentsMutation = {
  addComments?:  {
    __typename: "Comments",
    commentId?: string | null,
    keyId?: string | null,
    keyType?: string | null,
    comment?: string | null,
    commenterType?: string | null,
    dataStatus?: DataStatus | null,
  } | null,
};

export type DeleteUsersMutationVariables = {
  deleteUserInput?: DeleteUserInput | null,
};

export type DeleteUsersMutation = {
  deleteUsers?:  Array< {
    __typename: "DeleteUser",
    userId?: string | null,
    username?: string | null,
    email?: string | null,
    signedUp?: boolean | null,
    phoneNumber?: string | null,
    dataStatus?: string | null,
    organizationId?: string | null,
    teamId?: string | null,
    errorCode?: string | null,
  } | null > | null,
};

export type SetCognitoUserPasswordMutationVariables = {
  userId?: string | null,
  isPermanentPassword?: boolean | null,
  shouldNotNotifyUser?: boolean | null,
};

export type SetCognitoUserPasswordMutation = {
  setCognitoUserPassword?: string | null,
};

export type RescheduleWorkDaysMutationVariables = {
  workOrderId: string,
  rescheduleWorkDayInput?: Array< RescheduleWorkDayInput > | null,
};

export type RescheduleWorkDaysMutation = {
  rescheduleWorkDays?:  {
    __typename: "RescheduleWorkDayResponse",
    rescheduleSuccess?:  Array< {
      __typename: "RescheduleWorkDayEntity",
      facilityId?: string | null,
      workDayId?: string | null,
      workWeeks?: string | null,
      proofOfCompletion?: string | null,
      timeSheets?: string | null,
      workDate?: string | null,
      geolocation?: string | null,
      geofencing?: string | null,
      mandatoryTimeOfPresence?: string | null,
      calculatedTimeToComplete?: string | null,
      actualTimeToComplete?: string | null,
      routeTracking?: string | null,
      taskRemindersAndAlerts?: string | null,
      issuePrepaidCard?: string | null,
      communication?: string | null,
      reworkTasks?: string | null,
      ratings?: number | null,
      mdRatingTypes?: string | null,
      preShiftChecklistId?: string | null,
      workOrderId?: string | null,
    } | null > | null,
    rescheduleFailure?:  Array< {
      __typename: "RescheduleFailure",
      workDayId: string,
      newDate: string,
      errorCode?: string | null,
    } | null > | null,
  } | null,
};

export type CreateConversationMutationVariables = {
  conversationInput: ConversationInput,
};

export type CreateConversationMutation = {
  createConversation?:  {
    __typename: "Conversations",
    data?:  Array< {
      __typename: "ConversationsWithMessages",
      conversationId?: string | null,
      customerId?: string | null,
      vendorId?: string | null,
      bidId?: string | null,
      bidVersion?: number | null,
      jobId?: string | null,
      jobName?: string | null,
      customerName?: string | null,
      vendorName?: string | null,
      countOfMessages?: number | null,
      messages?:  Array< {
        __typename: "Messages",
        messageId?: string | null,
        message?: string | null,
        messageType?: MessageType | null,
        isRead?: boolean | null,
        organizationId?: string | null,
        userName?: string | null,
        userRoleId?: string | null,
        userRoleName?: string | null,
        conversationContext?: ConversationContext | null,
      } | null > | null,
    } | null > | null,
    metadata?:  {
      __typename: "PaginationMetaData",
      totalCount?: number | null,
      pageIndex?: number | null,
      limit?: number | null,
    } | null,
  } | null,
};

export type DeleteTaskAttachmentsMutationVariables = {
  deleteTaskAttachmentInput: DeleteTaskAttachmentInput,
};

export type DeleteTaskAttachmentsMutation = {
  deleteTaskAttachments?: Array< string | null > | null,
};

export type CreateUserAuthEventMutationVariables = {
  userAuthEventInput?: UserAuthEventInput | null,
};

export type CreateUserAuthEventMutation = {
  createUserAuthEvent?:  {
    __typename: "UserAuthEvent",
    userAuthEventId?: string | null,
  } | null,
};

export type UserQueryVariables = {
  id: string,
};

export type UserQuery = {
  user?:  {
    __typename: "User",
    id: string,
    name: string,
    email?: string | null,
  } | null,
};

export type UserExistQueryVariables = {
  user?: string | null,
};

export type UserExistQuery = {
  userExist?:  {
    __typename: "UserExist",
    user?:  {
      __typename: "CognitoUser",
      status?: CognitoUserStatus | null,
      userId?: string | null,
      isEnabled?: boolean | null,
      isEmailVerified?: boolean | null,
    } | null,
  } | null,
};

export type CountryQuery = {
  country?:  Array< {
    __typename: "Country",
    countryName: string,
    countryCode: string,
    countryLegalName: string,
    currencyCode: string,
    currencySymbol: string,
    callingCode: string,
    capitalCity: string,
    UTCTimezone: string,
    localeCode: string,
    dayLightSavings: string,
    displaySeq: number,
  } | null > | null,
};

export type StateQuery = {
  state?:  Array< {
    __typename: "State",
    stateName: string,
    stateCode: string,
    countryCode: string,
    capitalCity: string,
    UTCTimezone: string,
  } | null > | null,
};

export type MasterDataQueryVariables = {
  id: string,
  keyId?: string | null,
  displayName?: string | null,
  limit?: number | null,
  pageNumber?: number | null,
};

export type MasterDataQuery = {
  masterData?:  {
    __typename: "MasterDataWithCount",
    data?:  Array< {
      __typename: "MasterData",
      code?: string | null,
      keyCode?: string | null,
      value?: string | null,
      subKey1Value?: string | null,
      subKey2Value?: string | null,
      canCustomize?: string | null,
      userOrg?: number | null,
      processId?: number | null,
      defaultCode?: string | null,
      keyId?: string | null,
      keyType?: string | null,
      displayName?: string | null,
    } | null > | null,
    metadata?:  {
      __typename: "MetaDataForMasterData",
      totalCount?: number | null,
      pageIndex?: number | null,
      limit?: number | null,
    } | null,
  } | null,
};

export type MasterDataCategoryQueryVariables = {
  code: string,
  keyCode: string,
};

export type MasterDataCategoryQuery = {
  masterDataCategory?:  {
    __typename: "MasterData",
    code?: string | null,
    keyCode?: string | null,
    value?: string | null,
    subKey1Value?: string | null,
    subKey2Value?: string | null,
    canCustomize?: string | null,
    userOrg?: number | null,
    processId?: number | null,
    defaultCode?: string | null,
    keyId?: string | null,
    keyType?: string | null,
    displayName?: string | null,
  } | null,
};

export type StandardRatesQueryVariables = {
  standardRatesFilterInput: StandardRatesFilterInput,
};

export type StandardRatesQuery = {
  standardRates?:  Array< {
    __typename: "StandardRates",
    standardRateId: string,
    keyId: string,
    keyType: string,
    customerId?: string | null,
    serviceCategoryId?: string | null,
    serviceCategoryName?: string | null,
    mdCountryCode?: string | null,
    regionId?: string | null,
    subregionId?: string | null,
    stateCode?: string | null,
    city?: string | null,
    postalCode?: string | null,
    currency?: string | null,
    unionBlendedRate?: string | null,
    nonUnionBlendedRate?: string | null,
  } | null > | null,
};

export type ServiceCategoryV3QueryVariables = {
  limit?: number | null,
  pageNumber?: number | null,
  orgId?: string | null,
  taskId?: string | null,
  serviceCategoryId?: string | null,
};

export type ServiceCategoryV3Query = {
  serviceCategoryV3?:  {
    __typename: "ServiceCategoryV3WithCount",
    data?:  Array< {
      __typename: "ServiceCategoryV3",
      taskId: string,
      serviceCategoryId: string,
      serviceCategoryName: string,
      categoryImageUrl?: string | null,
      categoryDescription?: string | null,
      orgId: string,
      mdServiceType: string,
      taskName: string,
      taskImageUrl?: string | null,
      taskDescription?: string | null,
      unit?: string | null,
      mdAreaType?: string | null,
      modifiedBy?: string | null,
      createdBy?: string | null,
    } | null > | null,
    metadata?:  {
      __typename: "MetaDataForMasterData",
      totalCount?: number | null,
      pageIndex?: number | null,
      limit?: number | null,
    } | null,
  } | null,
};

export type CustomersQueryVariables = {
  limit?: number | null,
  pageNumber?: number | null,
};

export type CustomersQuery = {
  // featureFlag(recordId: String!): FeatureFlag
  customers?:  {
    __typename: "CustomerWithCount",
    data?:  Array< {
      __typename: "Customer",
      customerId: string,
      legalName: string,
      doingBusinessAs: string,
      websiteUrl?: string | null,
      federalEmployerId?: string | null,
      SICCode?: string | null,
      taxId?: string | null,
      logo?: string | null,
    } | null > | null,
    metadata?:  {
      __typename: "PaginationMetaData",
      totalCount?: number | null,
      pageIndex?: number | null,
      limit?: number | null,
    } | null,
  } | null,
};

export type CustomerQueryVariables = {
  id: string,
};

export type CustomerQuery = {
  customer?:  {
    __typename: "Customer",
    customerId: string,
    legalName: string,
    doingBusinessAs: string,
    websiteUrl?: string | null,
    federalEmployerId?: string | null,
    SICCode?: string | null,
    taxId?: string | null,
    logo?: string | null,
  } | null,
};

export type CustomerDetailsQueryVariables = {
  id: string,
};

export type CustomerDetailsQuery = {
  customerDetails?:  Array< {
    __typename: "CustomerDetails",
    customerDetailsId?: string | null,
    customerId?: string | null,
    noOfEmployees?: number | null,
    annualRevenueEstimate?: string | null,
    mdCustomerType?: string | null,
    referralSource?: string | null,
    phone?: string | null,
    fax?: string | null,
    email?: string | null,
  } | null > | null,
};

export type CustomerAddressesQueryVariables = {
  customerId: string,
};

export type CustomerAddressesQuery = {
  customerAddresses?:  {
    __typename: "Address",
    addressId?: string | null,
    customerId?: string | null,
    addressName?: string | null,
    noOfBuildings?: number | null,
    addressLine1?: string | null,
    addressLine2?: string | null,
    addressLine3?: string | null,
    city?: string | null,
    stateCode?: string | null,
    county?: string | null,
    postalCode?: string | null,
    mdCountryCode?: string | null,
    landmark?: string | null,
    googlePlaceId?: string | null,
    latitude?: string | null,
    longitude?: string | null,
    mdMsaId?: string | null,
    logoUrl?: string | null,
    intId?: string | null,
  } | null,
};

export type CustomerAddressQueryVariables = {
  id: string,
};

export type CustomerAddressQuery = {
  customerAddress?:  {
    __typename: "Address",
    addressId?: string | null,
    customerId?: string | null,
    addressName?: string | null,
    noOfBuildings?: number | null,
    addressLine1?: string | null,
    addressLine2?: string | null,
    addressLine3?: string | null,
    city?: string | null,
    stateCode?: string | null,
    county?: string | null,
    postalCode?: string | null,
    mdCountryCode?: string | null,
    landmark?: string | null,
    googlePlaceId?: string | null,
    latitude?: string | null,
    longitude?: string | null,
    mdMsaId?: string | null,
    logoUrl?: string | null,
    intId?: string | null,
  } | null,
};

export type FacilitiesQueryVariables = {
  customerId: string,
  limit?: number | null,
  pageNumber?: number | null,
  facilityName?: string | null,
  intId?: string | null,
  sortBy?: string | null,
  sortOrder?: string | null,
};

export type FacilitiesQuery = {
  facilities?:  {
    __typename: "FacilitiesWithCount",
    data?:  Array< {
      __typename: "Facility",
      callHours?: CallHours | null,
      transportationName?: string | null,
      storageName?: string | null,
      callStartTime?: string | null,
      callEndTime?: string | null,
      facilityId?: string | null,
      customerId?: string | null,
      buildingName?: string | null,
      buildingMakeup?: string | null,
      noOfFloors?: number | null,
      sqFootage?: number | null,
      mdFacilityType?: string | null,
      serviceableSqFootage?: number | null,
      occupancy?: number | null,
      supplyStorageAvailability?: boolean | null,
      transportRequiredWithinLocation?: boolean | null,
      contactId?: string | null,
      facilityTrafficLevel?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      cleaningInstructions?: string | null,
      geoFenceRadius?: number | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        addressId?: string | null,
        customerId?: string | null,
        addressName?: string | null,
        noOfBuildings?: number | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        stateCode?: string | null,
        county?: string | null,
        postalCode?: string | null,
        mdCountryCode?: string | null,
        landmark?: string | null,
        googlePlaceId?: string | null,
        latitude?: string | null,
        longitude?: string | null,
        mdMsaId?: string | null,
        logoUrl?: string | null,
        intId?: string | null,
      } | null,
    } | null > | null,
    metadata?:  {
      __typename: "PaginationMetaData",
      totalCount?: number | null,
      pageIndex?: number | null,
      limit?: number | null,
    } | null,
  } | null,
};

export type FacilityQueryVariables = {
  id: string,
  customerId: string,
};

export type FacilityQuery = {
  facility?:  {
    __typename: "Facility",
    callHours?: CallHours | null,
    transportationName?: string | null,
    storageName?: string | null,
    callStartTime?: string | null,
    callEndTime?: string | null,
    facilityId?: string | null,
    customerId?: string | null,
    buildingName?: string | null,
    buildingMakeup?: string | null,
    noOfFloors?: number | null,
    sqFootage?: number | null,
    mdFacilityType?: string | null,
    serviceableSqFootage?: number | null,
    occupancy?: number | null,
    supplyStorageAvailability?: boolean | null,
    transportRequiredWithinLocation?: boolean | null,
    contactId?: string | null,
    facilityTrafficLevel?: string | null,
    emergencyContactName?: string | null,
    emergencyContactPhone?: string | null,
    cleaningInstructions?: string | null,
    geoFenceRadius?: number | null,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      addressId?: string | null,
      customerId?: string | null,
      addressName?: string | null,
      noOfBuildings?: number | null,
      addressLine1?: string | null,
      addressLine2?: string | null,
      addressLine3?: string | null,
      city?: string | null,
      stateCode?: string | null,
      county?: string | null,
      postalCode?: string | null,
      mdCountryCode?: string | null,
      landmark?: string | null,
      googlePlaceId?: string | null,
      latitude?: string | null,
      longitude?: string | null,
      mdMsaId?: string | null,
      logoUrl?: string | null,
      intId?: string | null,
    } | null,
  } | null,
};

export type AllFacilitiesServiceDetailsQueryVariables = {
  facilityId: string,
};

export type AllFacilitiesServiceDetailsQuery = {
  allFacilitiesServiceDetails?:  Array< {
    __typename: "FacilityServiceDetails",
    facilityDetailsId: string,
    facilityId: string,
    floorNumber?: string | null,
    mdAreaType?: string | null,
    mdFlooringType?: string | null,
    quantity?: number | null,
    mdUnitType?: string | null,
  } | null > | null,
};

export type FacilityServiceDetailsQueryVariables = {
  facilityDetailsId: string,
};

export type FacilityServiceDetailsQuery = {
  facilityServiceDetails?:  {
    __typename: "FacilityServiceDetails",
    facilityDetailsId: string,
    facilityId: string,
    floorNumber?: string | null,
    mdAreaType?: string | null,
    mdFlooringType?: string | null,
    quantity?: number | null,
    mdUnitType?: string | null,
  } | null,
};

export type AllVendorsOnARKQueryVariables = {
  name?: string | null,
  dataStatus?: string | null,
  email?: string | null,
  otherVendorSearchInput?: OtherVendorSearchInput | null,
};

export type AllVendorsOnARKQuery = {
  allVendorsOnARK:  Array< {
    __typename: "Vendor",
    vendorId: string,
    vendorCompanyName?: string | null,
    vendorCompanyType?: string | null,
    doingBusinessAs?: string | null,
    SSID?: string | null,
    taxId?: string | null,
    websiteUrl?: string | null,
    logoUrl?: string | null,
    referralSource?: string | null,
    phone?: string | null,
    email?: string | null,
    dataStatus: string,
    modifiedOn?: string | null,
  } | null >,
};

export type VendorQueryVariables = {
  id: string,
};

export type VendorQuery = {
  vendor?:  {
    __typename: "VendorWithAddress",
    vendorId: string,
    vendorCompanyName?: string | null,
    vendorCompanyType?: string | null,
    doingBusinessAs?: string | null,
    SSID?: string | null,
    taxId?: string | null,
    websiteUrl?: string | null,
    logoUrl?: string | null,
    referralSource?: string | null,
    phone?: string | null,
    email?: string | null,
    dataStatus: string,
    modifiedOn?: string | null,
    vendorRating?: number | null,
    vendorAddress?:  Array< {
      __typename: "VendorAddress",
      addressId?: string | null,
      vendorId?: string | null,
      addressName?: string | null,
      addressLine1?: string | null,
      addressLine2?: string | null,
      addressLine3?: string | null,
      city?: string | null,
      stateCode?: string | null,
      county?: string | null,
      postalCode?: string | null,
      mdCountryCode?: string | null,
      landmark?: string | null,
      googlePlaceId?: string | null,
      latitude?: string | null,
      longitude?: string | null,
      mdMsaId?: string | null,
      modifiedOn?: string | null,
      regionId?: string | null,
    } | null > | null,
  } | null,
};

export type VendorsQueryVariables = {
  limit?: number | null,
  pageNumber?: number | null,
};

export type VendorsQuery = {
  vendors?:  {
    __typename: "VendorsWithCount",
    data?:  Array< {
      __typename: "Vendor",
      vendorId: string,
      vendorCompanyName?: string | null,
      vendorCompanyType?: string | null,
      doingBusinessAs?: string | null,
      SSID?: string | null,
      taxId?: string | null,
      websiteUrl?: string | null,
      logoUrl?: string | null,
      referralSource?: string | null,
      phone?: string | null,
      email?: string | null,
      dataStatus: string,
      modifiedOn?: string | null,
    } | null > | null,
    metadata?:  {
      __typename: "PaginationMetaData",
      totalCount?: number | null,
      pageIndex?: number | null,
      limit?: number | null,
    } | null,
  } | null,
};

export type VendorAddressesQueryVariables = {
  vendorId: string,
};

export type VendorAddressesQuery = {
  vendorAddresses?:  Array< {
    __typename: "VendorAddress",
    addressId?: string | null,
    vendorId?: string | null,
    addressName?: string | null,
    addressLine1?: string | null,
    addressLine2?: string | null,
    addressLine3?: string | null,
    city?: string | null,
    stateCode?: string | null,
    county?: string | null,
    postalCode?: string | null,
    mdCountryCode?: string | null,
    landmark?: string | null,
    googlePlaceId?: string | null,
    latitude?: string | null,
    longitude?: string | null,
    mdMsaId?: string | null,
    modifiedOn?: string | null,
    regionId?: string | null,
  } | null > | null,
};

export type VendorAddressQueryVariables = {
  id: string,
};

export type VendorAddressQuery = {
  vendorAddress?:  {
    __typename: "VendorAddress",
    addressId?: string | null,
    vendorId?: string | null,
    addressName?: string | null,
    addressLine1?: string | null,
    addressLine2?: string | null,
    addressLine3?: string | null,
    city?: string | null,
    stateCode?: string | null,
    county?: string | null,
    postalCode?: string | null,
    mdCountryCode?: string | null,
    landmark?: string | null,
    googlePlaceId?: string | null,
    latitude?: string | null,
    longitude?: string | null,
    mdMsaId?: string | null,
    modifiedOn?: string | null,
    regionId?: string | null,
  } | null,
};

export type VendorAttachmentsQueryVariables = {
  vendorId: string,
};

export type VendorAttachmentsQuery = {
  vendorAttachments?:  Array< {
    __typename: "VendorAttachment",
    attachmentId: string,
    refType?: RefType | null,
    refId?: string | null,
    fileName: string,
    mdFileContext: mdFileContext,
    fileExtension: string,
    url: string,
    dataStatus: DataStatus,
    date?: string | null,
    createdOn?: string | null,
    modifiedOn?: string | null,
  } | null > | null,
};

export type BeneficiariesQueryVariables = {
  vendorId: string,
  dataStatus?: DataState | null,
};

export type BeneficiariesQuery = {
  beneficiaries?:  Array< {
    __typename: "Beneficiary",
    dataStatus: DataState,
    beneficiaryId: string,
    name: string,
    TIN: string,
    percentageHolding: string,
    addressLine1: string,
    addressLine2?: string | null,
    city: string,
    state: string,
    zipCode: string,
    vendorId: string,
    modifiedOn?: string | null,
  } | null > | null,
};

export type InsurancesQueryVariables = {
  vendorId: string,
  dataStatus?: DataState | null,
};

export type InsurancesQuery = {
  insurances?:  Array< {
    __typename: "Insurance",
    fileName?: string | null,
    insuranceName?: string | null,
    validTill?: string | null,
    producer?: string | null,
    insured?: string | null,
    contactName?: string | null,
    contactPhone?: string | null,
    address?: string | null,
    fax?: string | null,
    insuranceId?: string | null,
    dataStatus?: DataState | null,
    vendorId?: string | null,
    createdOn?: string | null,
  } | null > | null,
};

export type FindManyVendorAttachmentsQueryVariables = {
  vendorId: string,
  dataStatus: string,
};

export type FindManyVendorAttachmentsQuery = {
  findManyVendorAttachments?:  Array< {
    __typename: "VendorAttachment",
    attachmentId: string,
    refType?: RefType | null,
    refId?: string | null,
    fileName: string,
    mdFileContext: mdFileContext,
    fileExtension: string,
    url: string,
    dataStatus: DataStatus,
    date?: string | null,
    createdOn?: string | null,
    modifiedOn?: string | null,
  } | null > | null,
};

export type VendorDetailsQueryVariables = {
  vendorId: string,
};

export type VendorDetailsQuery = {
  vendorDetails?:  {
    __typename: "VendorDetails",
    vendorId?: string | null,
    annualRevenueEstimate?: number | null,
    fax?: string | null,
    coi?: string | null,
    noOfEmployees?: number | null,
    workWeekDefinition?: string | null,
    w9FormUrl?: string | null,
  } | null,
};

export type VendorServiceLocationsQueryVariables = {
  vendorId: string,
  limit?: number | null,
  pageNumber?: number | null,
};

export type VendorServiceLocationsQuery = {
  vendorServiceLocations?:  {
    __typename: "VendorServiceLocationWithCount",
    data?:  Array< {
      __typename: "VendorServiceLocation",
      vendorServiceLocationId?: string | null,
      vendorId?: string | null,
      mdAreaName?: string | null,
      mdAreaType?: string | null,
      mdAreaCode?: string | null,
      mdAreaCategory?: string | null,
      country?: string | null,
      locationId?: string | null,
    } | null > | null,
    metadata?:  {
      __typename: "PaginationMetaData",
      totalCount?: number | null,
      pageIndex?: number | null,
      limit?: number | null,
    } | null,
  } | null,
};

export type VendorServiceLocationQueryVariables = {
  id: string,
};

export type VendorServiceLocationQuery = {
  vendorServiceLocation?:  {
    __typename: "VendorServiceLocation",
    vendorServiceLocationId?: string | null,
    vendorId?: string | null,
    mdAreaName?: string | null,
    mdAreaType?: string | null,
    mdAreaCode?: string | null,
    mdAreaCategory?: string | null,
    country?: string | null,
    locationId?: string | null,
  } | null,
};

export type VendorTeamInterestedAssociateListQueryVariables = {
  organizationId: string,
};

export type VendorTeamInterestedAssociateListQuery = {
  vendorTeamInterestedAssociateList?:  Array< {
    __typename: "VendorTeamInterestedAssociateList",
    associateId: string,
    title?: string | null,
    name?: string | null,
    emergencyContactNumber?: string | null,
    logoUrl?: string | null,
    availableForNightShifts?: boolean | null,
    isFavorite?: boolean | null,
    transportationType?: boolean | null,
    priorExperience?: boolean | null,
    supervisorExperience?: boolean | null,
    age?: boolean | null,
    availableForWeekends?: boolean | null,
    emailId?: string | null,
    associateAddress?:  Array< {
      __typename: "AssociatePostalCode",
      postalCode?: string | null,
    } | null > | null,
  } | null > | null,
};

export type VendorServicesOfferedQueryVariables = {
  vendorId: string,
  pageNumber?: number | null,
  limit?: number | null,
};

export type VendorServicesOfferedQuery = {
  vendorServicesOffered?:  {
    __typename: "VendorServiceOfferedWithCount",
    data?:  Array< {
      __typename: "VendorService",
      vendorServiceOfferedId: string,
      serviceCategoryId: string,
      serviceDescription: string,
      serviceSubCategoryId: string,
    } | null > | null,
    metadata?:  {
      __typename: "PaginationMetaData",
      totalCount?: number | null,
      pageIndex?: number | null,
      limit?: number | null,
    } | null,
  } | null,
};

export type VendorServicesOfferedSummaryQueryVariables = {
  vendorId: string,
};

export type VendorServicesOfferedSummaryQuery = {
  vendorServicesOfferedSummary?:  Array< {
    __typename: "VendorServiceSummary",
    imageUrl?: string | null,
    serviceCategoryName?: string | null,
    subCategoryCount: number,
    mdServiceType?: string | null,
  } | null > | null,
};

export type FindManyVendorAccreditationQueryVariables = {
  vendorId: string,
};

export type FindManyVendorAccreditationQuery = {
  findManyVendorAccreditation?:  Array< {
    __typename: "VendorAccreditationDetail",
    recordId?: string | null,
    attachmentId?: string | null,
    certificateId?: string | null,
    accreditationType?: string | null,
    labels?: string | null,
    issuingAuthority?: string | null,
    effectiveFromDate?: string | null,
    effectiveEndDate?: string | null,
    accreditationSubType?:  Array< {
      __typename: "AccreditationSubTypes",
      accreditationSubTypeId?: string | null,
      recSeq?: number | null,
      recStatus?: string | null,
      subType?: string | null,
      value?: string | null,
      dataStatus?: string | null,
      vendorAccreditationId?: string | null,
      vendorAccreditationRecSeq?: number | null,
      fromDate?: string | null,
      endDate?: string | null,
      createdBy?: string | null,
      createdOn?: string | null,
      modifiedBy?: string | null,
      modifiedOn?: string | null,
    } | null > | null,
    msaCustomer?: string | null,
    type?: string | null,
    status?: string | null,
    dataStatus?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    createdOn?: string | null,
    modifiedOn?: string | null,
    fromDate?: string | null,
    vendorId?: string | null,
    attachments?:  {
      __typename: "VendorAttachment",
      attachmentId: string,
      refType?: RefType | null,
      refId?: string | null,
      fileName: string,
      mdFileContext: mdFileContext,
      fileExtension: string,
      url: string,
      dataStatus: DataStatus,
      date?: string | null,
      createdOn?: string | null,
      modifiedOn?: string | null,
    } | null,
  } | null > | null,
};

export type AssociateSkillsQueryVariables = {
  associateId: string,
  limit?: number | null,
  pageNum?: number | null,
};

export type AssociateSkillsQuery = {
  associateSkills?:  {
    __typename: "AssociateSkillsWithCount",
    data?:  Array< {
      __typename: "AssociateSkillWithID",
      skillId?: string | null,
      skillCategoryId?: string | null,
      skillSubCategoryId?: string | null,
      skillDescription?: string | null,
      associateSkillsId?: string | null,
    } | null > | null,
    metadata?:  {
      __typename: "PaginationMetaData",
      totalCount?: number | null,
      pageIndex?: number | null,
      limit?: number | null,
    } | null,
  } | null,
};

export type AssociateServiceLocationsQueryVariables = {
  associateId: string,
};

export type AssociateServiceLocationsQuery = {
  associateServiceLocations?:  Array< {
    __typename: "AssociateServiceLocation",
    associateServiceLocationId?: string | null,
    associateId?: string | null,
    mdAreaName?: string | null,
    mdAreaType?: string | null,
    mdAreaCategory?: string | null,
    locationId?: string | null,
    country?: string | null,
  } | null > | null,
};

export type AssociateServiceLocationQueryVariables = {
  id: string,
};

export type AssociateServiceLocationQuery = {
  associateServiceLocation?:  {
    __typename: "AssociateServiceLocation",
    associateServiceLocationId?: string | null,
    associateId?: string | null,
    mdAreaName?: string | null,
    mdAreaType?: string | null,
    mdAreaCategory?: string | null,
    locationId?: string | null,
    country?: string | null,
  } | null,
};

export type AssociateShiftPreferencesQueryVariables = {
  id: string,
  limit?: number | null,
  pageNum?: number | null,
};

export type AssociateShiftPreferencesQuery = {
  associateShiftPreferences?:  {
    __typename: "AssociateShiftPreferencesWithCount",
    data?:  Array< {
      __typename: "AssociateShiftPreferences",
      shiftPreferenceId?: number | null,
      day?: string | null,
      fromTime?: string | null,
      toTime?: string | null,
      isFlexibleShiftPreference?: boolean | null,
    } | null > | null,
    metadata?:  {
      __typename: "PaginationMetaData",
      totalCount?: number | null,
      pageIndex?: number | null,
      limit?: number | null,
    } | null,
  } | null,
};

export type AssociateShiftPreferencesByDayQueryVariables = {
  id: string,
  day: string,
  limit?: number | null,
  pageNum?: number | null,
};

export type AssociateShiftPreferencesByDayQuery = {
  associateShiftPreferencesByDay?:  {
    __typename: "AssociateShiftPreferencesWithCount",
    data?:  Array< {
      __typename: "AssociateShiftPreferences",
      shiftPreferenceId?: number | null,
      day?: string | null,
      fromTime?: string | null,
      toTime?: string | null,
      isFlexibleShiftPreference?: boolean | null,
    } | null > | null,
    metadata?:  {
      __typename: "PaginationMetaData",
      totalCount?: number | null,
      pageIndex?: number | null,
      limit?: number | null,
    } | null,
  } | null,
};

export type AssociateProfileQueryVariables = {
  id?: string | null,
};

export type AssociateProfileQuery = {
  associateProfile?:  {
    __typename: "AssociateProfile",
    associateProfileRecId?: string | null,
    associateId?: string | null,
    cognitoUserId?: string | null,
    title: string,
    linkedInURL?: string | null,
    dateOfBirth?: string | null,
    name?: string | null,
    emergencyContactName?: string | null,
    emergencyContactNumber?: string | null,
    eeoInfo?: string | null,
    primaryLanguage?: string | null,
    languagesSpoken?: string | null,
    source?: string | null,
    logoUrl?: string | null,
    emailId?: string | null,
  } | null,
};

export type AssociateAddressQueryVariables = {
  id?: string | null,
};

export type AssociateAddressQuery = {
  associateAddress?:  {
    __typename: "AssociateAddress",
    addressId?: string | null,
    associateId?: string | null,
    addressName?: string | null,
    addressLine1: string,
    addressLine2?: string | null,
    addressLine3?: string | null,
    city: string,
    stateCode: string,
    county?: string | null,
    postalCode: string,
    mdCountryCode: string,
    landmark?: string | null,
    googlePlaceId: string,
    latitude: number,
    longitude: number,
    regionId?: string | null,
  } | null,
};

export type AssociateSkillsSummaryQueryVariables = {
  associateId: string,
  limit?: number | null,
  pageNum?: number | null,
};

export type AssociateSkillsSummaryQuery = {
  associateSkillsSummary?:  {
    __typename: "AssociateSkillsSummaryWithCount",
    data?:  Array< {
      __typename: "AssociateSkillsSummary",
      skillCategoryId?: string | null,
      imageUrl?: string | null,
      skillCategoryName?: string | null,
      subCategoryCount: number,
    } | null > | null,
    metadata?:  {
      __typename: "PaginationMetaData",
      totalCount?: number | null,
      pageIndex?: number | null,
      limit?: number | null,
    } | null,
  } | null,
};

export type JobServicesQueryVariables = {
  customerId: string,
  jobId: string,
};

export type JobServicesQuery = {
  jobServices?:  Array< {
    __typename: "JobService",
    jobId: string,
    mdServiceId: string,
    serviceMeasure: number,
    mdServiceUnits: string,
    tasks?:  Array< {
      __typename: "JobServiceTasks",
      taskId: string,
      taskName: string,
    } | null > | null,
    actualMdServiceUnits?: number | null,
    actualServiceMeasure?: string | null,
  } | null > | null,
};

export type AvailableWorkDayDatesQueryVariables = {
  workOrderId: string,
  workDayId?: string | null,
};

export type AvailableWorkDayDatesQuery = {
  availableWorkDayDates?:  Array< {
    __typename: "AvailableWorkDayDates",
    workDayId?: string | null,
    workDate?: string | null,
    availableDates?: Array< string | null > | null,
    associates?:  Array< {
      __typename: "AvailableAssociates",
      associateId?: string | null,
      availableDates?: Array< string | null > | null,
    } | null > | null,
    errorCode?: string | null,
    isReschedulable?: boolean | null,
  } | null > | null,
};

export type FindWorkDayQueryVariables = {
  workOrderId: string,
  actualWorkDate?: string | null,
  workDate?: string | null,
};

export type FindWorkDayQuery = {
  findWorkDay?:  {
    __typename: "WorkDay",
    isReworkDay?: boolean | null,
    isShiftRescheduled?: boolean | null,
    facilityId?: string | null,
    workDayId?: string | null,
    workWeeks?: string | null,
    proofOfCompletion?: string | null,
    timeSheets?: string | null,
    workDate?: string | null,
    geolocation?: string | null,
    geofencing?: string | null,
    mandatoryTimeOfPresence?: string | null,
    calculatedTimeToComplete?: string | null,
    actualTimeToComplete?: string | null,
    routeTracking?: string | null,
    taskRemindersAndAlerts?: string | null,
    issuePrepaidCard?: string | null,
    communication?: string | null,
    reworkTasks?: string | null,
    ratings?: number | null,
    mdRatingTypes?: string | null,
    preShiftChecklistId?: string | null,
    workOrderId?: string | null,
  } | null,
};

export type JobSummaryQueryVariables = {
  customerId: string,
  jobId: string,
};

export type JobSummaryQuery = {
  jobSummary?:  {
    __typename: "JobSummary",
    jobId: string,
    jobName: string,
    specialInstructions?: string | null,
    startDate?: string | null,
    stopDate?: string | null,
    jobType?: string | null,
    customerId?: string | null,
    jobCustodian?: string | null,
    dataStatus?: JobStatus | null,
    jobFacilities?:  Array< {
      __typename: "JobFacility",
      jobId: string,
      facilityId: string,
      mdFacilityType: string,
      facilityAddress?: string | null,
      remarks?: string | null,
      status?: string | null,
      jobStartDate?: string | null,
      jobEndData?: string | null,
    } | null > | null,
    jobServices?:  Array< {
      __typename: "JobService",
      jobId: string,
      mdServiceId: string,
      serviceMeasure: number,
      mdServiceUnits: string,
      tasks?:  Array< {
        __typename: "JobServiceTasks",
        taskId: string,
        taskName: string,
      } | null > | null,
      actualMdServiceUnits?: number | null,
      actualServiceMeasure?: string | null,
    } | null > | null,
    jobFrequencies?:  Array< {
      __typename: "JobFrequency",
      mdShiftType?: string | null,
      mdScheduleType?: string | null,
      mdServiceId?: string | null,
      scheduleDays?: string | null,
      fromTime?: string | null,
      toTime?: string | null,
      cronExpression?: string | null,
      repeatitions?: number | null,
      repetition?: number | null,
    } | null > | null,
    jobVendors?:  Array< {
      __typename: "JobVendor",
      jobId: string,
      vendorId: string,
      mdVendorType: string,
    } | null > | null,
    jobDueDates?:  Array< {
      __typename: "JobDueDate",
      jobId?: string | null,
      mdDueType?: string | null,
      dueDescription?: string | null,
      dueDate?: string | null,
    } | null > | null,
    jobAttachments?:  Array< {
      __typename: "JobAttachment",
      attachmentId?: string | null,
      mdAttachmentType?: string | null,
      fileName?: string | null,
      mdFileContext?: string | null,
      fileExtension?: string | null,
      url?: string | null,
      dataStatus?: string | null,
      comments?: string | null,
      jobId?: string | null,
      facilityId?: string | null,
      tags?: string | null,
    } | null > | null,
  } | null,
};

export type JobSummaryV2QueryVariables = {
  customerId: string,
  jobId: string,
};

export type JobSummaryV2Query = {
  jobSummaryV2?:  {
    __typename: "JobSummaryV2",
    jobId: string,
    actualId?: string | null,
    jobName: string,
    specialInstructions?: string | null,
    startDate?: string | null,
    stopDate?: string | null,
    jobType?: string | null,
    customerId?: string | null,
    jobCustodian?: string | null,
    dataStatus?: JobStatus | null,
    jobFacilities?:  Array< {
      __typename: "JobFacility",
      jobId: string,
      facilityId: string,
      mdFacilityType: string,
      facilityAddress?: string | null,
      remarks?: string | null,
      status?: string | null,
      jobStartDate?: string | null,
      jobEndData?: string | null,
    } | null > | null,
    jobServices?:  Array< {
      __typename: "JobService",
      jobId: string,
      mdServiceId: string,
      serviceMeasure: number,
      mdServiceUnits: string,
      tasks?:  Array< {
        __typename: "JobServiceTasks",
        taskId: string,
        taskName: string,
      } | null > | null,
      actualMdServiceUnits?: number | null,
      actualServiceMeasure?: string | null,
    } | null > | null,
    jobFrequencies?:  Array< {
      __typename: "JobFrequencyForJobSummary",
      mdShiftType?: string | null,
      mdScheduleType?: string | null,
      mdServiceId?: Array< string | null > | null,
      scheduleDays?: string | null,
      fromTime?: string | null,
      toTime?: string | null,
      cronExpression?: string | null,
      repeatitions?: number | null,
      repetition?: number | null,
    } | null > | null,
    jobVendors?:  Array< {
      __typename: "JobVendor",
      jobId: string,
      vendorId: string,
      mdVendorType: string,
    } | null > | null,
    jobDueDates?:  Array< {
      __typename: "JobDueDate",
      jobId?: string | null,
      mdDueType?: string | null,
      dueDescription?: string | null,
      dueDate?: string | null,
    } | null > | null,
    jobAttachments?:  Array< {
      __typename: "JobAttachment",
      attachmentId?: string | null,
      mdAttachmentType?: string | null,
      fileName?: string | null,
      mdFileContext?: string | null,
      fileExtension?: string | null,
      url?: string | null,
      dataStatus?: string | null,
      comments?: string | null,
      jobId?: string | null,
      facilityId?: string | null,
      tags?: string | null,
    } | null > | null,
    subJobs?:  Array< {
      __typename: "SubJobsForJobSummary",
      jobId?: string | null,
      jobName?: string | null,
      jobType?: string | null,
      jobServices?:  Array< {
        __typename: "JobService",
        jobId: string,
        mdServiceId: string,
        serviceMeasure: number,
        mdServiceUnits: string,
        tasks?:  Array< {
          __typename: "JobServiceTasks",
          taskId: string,
          taskName: string,
        } | null > | null,
        actualMdServiceUnits?: number | null,
        actualServiceMeasure?: string | null,
      } | null > | null,
      jobVendors?:  Array< {
        __typename: "JobVendor",
        jobId: string,
        vendorId: string,
        mdVendorType: string,
      } | null > | null,
      jobBids?:  Array< {
        __typename: "JobBidsForPrimeVendor",
        bidId?: string | null,
        vendorId?: string | null,
        vendorName?: string | null,
        vendorEmail?: string | null,
        mdFrequencyType?: MdFrequencyType | null,
        bidName?: string | null,
        totalCost?: number | null,
        profitMargin?: number | null,
        isFlatMargin?: YesNoType | null,
        finalQuote?: number | null,
        comments?: string | null,
        bidEstimationType?: BidType | null,
        bidCreationDate?: string | null,
        draftStep?: BidDraftStep | null,
        draftStatus?: DraftStatus | null,
        dataStatus?: BidStatus | null,
        bidSubmissionDate?: string | null,
        bidVersionCreatedDate?: string | null,
        bidVersion?: number | null,
      } | null > | null,
    } | null > | null,
    isPaid?: boolean | null,
    jobRating?: number | null,
  } | null,
};

export type JobsV2QueryVariables = {
  customerId: string,
  facilityId?: string | null,
  mdDueType?: string | null,
  dueDate?: string | null,
  startDate?: string | null,
  dataStatus?: string | null,
  limit?: number | null,
  pageNumber?: number | null,
};

export type JobsV2Query = {
  jobsV2?:  {
    __typename: "JobsV2WithCount",
    data?:  Array< {
      __typename: "JobWithDueDates",
      jobId: string,
      actualId?: string | null,
      jobName: string,
      startDate?: string | null,
      stopDate?: string | null,
      dataStatus?: string | null,
      jobType?: string | null,
      specialInstructions?: string | null,
      draftStep?: DraftStep | null,
      draftStatus?: DraftStatus | null,
      createdOn?: string | null,
      createdBy?: string | null,
      modifiedOn?: string | null,
      modifiedBy?: string | null,
      createdByName?: string | null,
      modifiedByName?: string | null,
      bidCount?: number | null,
      jobCustodian?: string | null,
      intId?: string | null,
      allowVendorToReschedule?: boolean | null,
      jobFacilities?:  Array< {
        __typename: "JobFacilityWithOnlyId",
        facilityId: string,
      } | null > | null,
      jobDueDates?:  Array< {
        __typename: "JobDueDate",
        jobId?: string | null,
        mdDueType?: string | null,
        dueDescription?: string | null,
        dueDate?: string | null,
      } | null > | null,
      jobBids?:  Array< {
        __typename: "JobBidVendor",
        vendorId?: string | null,
        vendorName?: string | null,
      } | null > | null,
      jobFrequencies?:  Array< {
        __typename: "JobFrequency",
        mdShiftType?: string | null,
        mdScheduleType?: string | null,
        mdServiceId?: string | null,
        scheduleDays?: string | null,
        fromTime?: string | null,
        toTime?: string | null,
        cronExpression?: string | null,
        repeatitions?: number | null,
        repetition?: number | null,
      } | null > | null,
      jobAttachments?:  Array< {
        __typename: "JobAttachment",
        attachmentId?: string | null,
        mdAttachmentType?: string | null,
        fileName?: string | null,
        mdFileContext?: string | null,
        fileExtension?: string | null,
        url?: string | null,
        dataStatus?: string | null,
        comments?: string | null,
        jobId?: string | null,
        facilityId?: string | null,
        tags?: string | null,
      } | null > | null,
    } | null > | null,
    metadata?:  {
      __typename: "PaginationMetaData",
      totalCount?: number | null,
      pageIndex?: number | null,
      limit?: number | null,
    } | null,
  } | null,
};

export type RecommendedJobsQueryVariables = {
  vendorId: string,
  serviceMeasure?: Array< ServiceMeasure | null > | null,
  startDate?: string | null,
  endDate?: string | null,
  percentageRange?: Array< RangeInput | null > | null,
  jobType?: Array< JobType | null > | null,
  scheduleType?: Array< ScheduleType | null > | null,
  jobDuration?: JobDuration | null,
  facilityType?: Array< string | null > | null,
  dueDate?: string | null,
  includeIgnoredJobs?: boolean | null,
  limit?: number | null,
  pageNumber?: number | null,
  searchText?: string | null,
  actionType?: JobInterestsActionType | null,
  sortBy?: string | null,
  sortOrder?: string | null,
};

export type RecommendedJobsQuery = {
  recommendedJobs?:  {
    __typename: "JobRecommendations",
    serviceLocations?:  Array< {
      __typename: "VendorServiceLocation",
      vendorServiceLocationId?: string | null,
      vendorId?: string | null,
      mdAreaName?: string | null,
      mdAreaType?: string | null,
      mdAreaCode?: string | null,
      mdAreaCategory?: string | null,
      country?: string | null,
      locationId?: string | null,
    } | null > | null,
    servicesOffered?:  Array< {
      __typename: "VendorService",
      vendorServiceOfferedId: string,
      serviceCategoryId: string,
      serviceDescription: string,
      serviceSubCategoryId: string,
    } | null > | null,
    jobsWithinServiceLocations?:  Array< {
      __typename: "JobId",
      id?: string | null,
    } | null > | null,
    matchedJobs?:  {
      __typename: "JobRecommendationWithCount",
      data?:  Array< {
        __typename: "JobRecommendation",
        jobId: string,
        jobName: string,
        specialInstructions?: string | null,
        startDate?: string | null,
        stopDate?: string | null,
        jobFacility?:  {
          __typename: "JobFacility",
          jobId: string,
          facilityId: string,
          mdFacilityType: string,
          facilityAddress?: string | null,
          remarks?: string | null,
          status?: string | null,
          jobStartDate?: string | null,
          jobEndData?: string | null,
        } | null,
        jobSize?: string | null,
        dataStatus?: string | null,
        jobType?: string | null,
        createdOn?: string | null,
        jobServices?:  Array< {
          __typename: "JobService",
          jobId: string,
          mdServiceId: string,
          serviceMeasure: number,
          mdServiceUnits: string,
          tasks?:  Array< {
            __typename: "JobServiceTasks",
            taskId: string,
            taskName: string,
          } | null > | null,
          actualMdServiceUnits?: number | null,
          actualServiceMeasure?: string | null,
        } | null > | null,
        jobFrequencies?:  Array< {
          __typename: "JobFrequency",
          mdShiftType?: string | null,
          mdScheduleType?: string | null,
          mdServiceId?: string | null,
          scheduleDays?: string | null,
          fromTime?: string | null,
          toTime?: string | null,
          cronExpression?: string | null,
          repeatitions?: number | null,
          repetition?: number | null,
        } | null > | null,
        jobVendors?:  Array< {
          __typename: "JobVendor",
          jobId: string,
          vendorId: string,
          mdVendorType: string,
        } | null > | null,
        jobDueDates?:  Array< {
          __typename: "JobDueDate",
          jobId?: string | null,
          mdDueType?: string | null,
          dueDescription?: string | null,
          dueDate?: string | null,
        } | null > | null,
        percentageMatch?: number | null,
      } | null > | null,
      metadata?:  {
        __typename: "PaginationMetaData",
        totalCount?: number | null,
        pageIndex?: number | null,
        limit?: number | null,
      } | null,
    } | null,
    matchedBids?:  Array< {
      __typename: "JobBid",
      bidId?: string | null,
      jobId?: string | null,
      bidName?: string | null,
      totalCost?: number | null,
      profitMargin?: number | null,
      finalQuote?: number | null,
      bidEstimationType?: BidType | null,
      bidCreationDate?: string | null,
      draftStep?: BidDraftStep | null,
      isFlatMargin?: YesNoType | null,
      draftStatus?: DraftStatus | null,
      dataStatus?: BidStatus | null,
      bidTemplate?: string | null,
      bidVersion?: number | null,
      bidVersionCreatedDate?: string | null,
      submittedBy?: string | null,
      bidSubmissionDate?: string | null,
      mdFrequencyType?: MdFrequencyType | null,
      comments?: string | null,
      bidCreatedFromSample?: string | null,
      errorCode?: string | null,
      vendorId?: string | null,
      vendorName?: string | null,
      vendorEmail?: string | null,
    } | null > | null,
    matchedFacilities?:  Array< {
      __typename: "Facility",
      callHours?: CallHours | null,
      transportationName?: string | null,
      storageName?: string | null,
      callStartTime?: string | null,
      callEndTime?: string | null,
      facilityId?: string | null,
      customerId?: string | null,
      buildingName?: string | null,
      buildingMakeup?: string | null,
      noOfFloors?: number | null,
      sqFootage?: number | null,
      mdFacilityType?: string | null,
      serviceableSqFootage?: number | null,
      occupancy?: number | null,
      supplyStorageAvailability?: boolean | null,
      transportRequiredWithinLocation?: boolean | null,
      contactId?: string | null,
      facilityTrafficLevel?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      cleaningInstructions?: string | null,
      geoFenceRadius?: number | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        addressId?: string | null,
        customerId?: string | null,
        addressName?: string | null,
        noOfBuildings?: number | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        stateCode?: string | null,
        county?: string | null,
        postalCode?: string | null,
        mdCountryCode?: string | null,
        landmark?: string | null,
        googlePlaceId?: string | null,
        latitude?: string | null,
        longitude?: string | null,
        mdMsaId?: string | null,
        logoUrl?: string | null,
        intId?: string | null,
      } | null,
    } | null > | null,
  } | null,
};

export type AllJobsQueryVariables = {
  dueDate?: string | null,
  vendorId: string,
  serviceMeasure?: Array< ServiceMeasure | null > | null,
  startDate?: string | null,
  endDate?: string | null,
  percentageRange?: Array< RangeInput | null > | null,
  jobType?: Array< JobType | null > | null,
  scheduleType?: Array< ScheduleType | null > | null,
  jobDuration?: JobDuration | null,
  facilityType?: Array< string | null > | null,
  searchText?: string | null,
  limit?: number | null,
  pageNumber?: number | null,
  actionType?: JobInterestsActionType | null,
  sortBy?: string | null,
  sortOrder?: string | null,
};

export type AllJobsQuery = {
  allJobs?:  {
    __typename: "JobRecommendations",
    serviceLocations?:  Array< {
      __typename: "VendorServiceLocation",
      vendorServiceLocationId?: string | null,
      vendorId?: string | null,
      mdAreaName?: string | null,
      mdAreaType?: string | null,
      mdAreaCode?: string | null,
      mdAreaCategory?: string | null,
      country?: string | null,
      locationId?: string | null,
    } | null > | null,
    servicesOffered?:  Array< {
      __typename: "VendorService",
      vendorServiceOfferedId: string,
      serviceCategoryId: string,
      serviceDescription: string,
      serviceSubCategoryId: string,
    } | null > | null,
    jobsWithinServiceLocations?:  Array< {
      __typename: "JobId",
      id?: string | null,
    } | null > | null,
    matchedJobs?:  {
      __typename: "JobRecommendationWithCount",
      data?:  Array< {
        __typename: "JobRecommendation",
        jobId: string,
        jobName: string,
        specialInstructions?: string | null,
        startDate?: string | null,
        stopDate?: string | null,
        jobFacility?:  {
          __typename: "JobFacility",
          jobId: string,
          facilityId: string,
          mdFacilityType: string,
          facilityAddress?: string | null,
          remarks?: string | null,
          status?: string | null,
          jobStartDate?: string | null,
          jobEndData?: string | null,
        } | null,
        jobSize?: string | null,
        dataStatus?: string | null,
        jobType?: string | null,
        createdOn?: string | null,
        jobServices?:  Array< {
          __typename: "JobService",
          jobId: string,
          mdServiceId: string,
          serviceMeasure: number,
          mdServiceUnits: string,
          tasks?:  Array< {
            __typename: "JobServiceTasks",
            taskId: string,
            taskName: string,
          } | null > | null,
          actualMdServiceUnits?: number | null,
          actualServiceMeasure?: string | null,
        } | null > | null,
        jobFrequencies?:  Array< {
          __typename: "JobFrequency",
          mdShiftType?: string | null,
          mdScheduleType?: string | null,
          mdServiceId?: string | null,
          scheduleDays?: string | null,
          fromTime?: string | null,
          toTime?: string | null,
          cronExpression?: string | null,
          repeatitions?: number | null,
          repetition?: number | null,
        } | null > | null,
        jobVendors?:  Array< {
          __typename: "JobVendor",
          jobId: string,
          vendorId: string,
          mdVendorType: string,
        } | null > | null,
        jobDueDates?:  Array< {
          __typename: "JobDueDate",
          jobId?: string | null,
          mdDueType?: string | null,
          dueDescription?: string | null,
          dueDate?: string | null,
        } | null > | null,
        percentageMatch?: number | null,
      } | null > | null,
      metadata?:  {
        __typename: "PaginationMetaData",
        totalCount?: number | null,
        pageIndex?: number | null,
        limit?: number | null,
      } | null,
    } | null,
    matchedBids?:  Array< {
      __typename: "JobBid",
      bidId?: string | null,
      jobId?: string | null,
      bidName?: string | null,
      totalCost?: number | null,
      profitMargin?: number | null,
      finalQuote?: number | null,
      bidEstimationType?: BidType | null,
      bidCreationDate?: string | null,
      draftStep?: BidDraftStep | null,
      isFlatMargin?: YesNoType | null,
      draftStatus?: DraftStatus | null,
      dataStatus?: BidStatus | null,
      bidTemplate?: string | null,
      bidVersion?: number | null,
      bidVersionCreatedDate?: string | null,
      submittedBy?: string | null,
      bidSubmissionDate?: string | null,
      mdFrequencyType?: MdFrequencyType | null,
      comments?: string | null,
      bidCreatedFromSample?: string | null,
      errorCode?: string | null,
      vendorId?: string | null,
      vendorName?: string | null,
      vendorEmail?: string | null,
    } | null > | null,
    matchedFacilities?:  Array< {
      __typename: "Facility",
      callHours?: CallHours | null,
      transportationName?: string | null,
      storageName?: string | null,
      callStartTime?: string | null,
      callEndTime?: string | null,
      facilityId?: string | null,
      customerId?: string | null,
      buildingName?: string | null,
      buildingMakeup?: string | null,
      noOfFloors?: number | null,
      sqFootage?: number | null,
      mdFacilityType?: string | null,
      serviceableSqFootage?: number | null,
      occupancy?: number | null,
      supplyStorageAvailability?: boolean | null,
      transportRequiredWithinLocation?: boolean | null,
      contactId?: string | null,
      facilityTrafficLevel?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      cleaningInstructions?: string | null,
      geoFenceRadius?: number | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        addressId?: string | null,
        customerId?: string | null,
        addressName?: string | null,
        noOfBuildings?: number | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        stateCode?: string | null,
        county?: string | null,
        postalCode?: string | null,
        mdCountryCode?: string | null,
        landmark?: string | null,
        googlePlaceId?: string | null,
        latitude?: string | null,
        longitude?: string | null,
        mdMsaId?: string | null,
        logoUrl?: string | null,
        intId?: string | null,
      } | null,
    } | null > | null,
  } | null,
};

export type AllJobBidEstimationQueryVariables = {
  jobId: string,
  bidId: string,
};

export type AllJobBidEstimationQuery = {
  allJobBidEstimation?:  Array< {
    __typename: "JobBidEstimation",
    jobId: string,
    estimationId: string,
    bidId: string,
    mdCategoryId: string,
    mdTaskId?: string | null,
    mdMeasureType: string,
    rate: number,
    quantity: number,
    isOverTime?: YesNoType | null,
    totalCost: number,
    isVendorAdded: YesNoType,
    isBillable: Billable,
    numberOfOccurrences?: number | null,
    mdUnits?: string | null,
    tasks?:  Array< {
      __typename: "JobServiceTasks",
      taskId: string,
      taskName: string,
    } | null > | null,
  } | null > | null,
};

export type JobBidEstimationQueryVariables = {
  bidId: string,
  serviceCategoryId: string,
};

export type JobBidEstimationQuery = {
  jobBidEstimation?:  {
    __typename: "JobBidEstimation",
    jobId: string,
    estimationId: string,
    bidId: string,
    mdCategoryId: string,
    mdTaskId?: string | null,
    mdMeasureType: string,
    rate: number,
    quantity: number,
    isOverTime?: YesNoType | null,
    totalCost: number,
    isVendorAdded: YesNoType,
    isBillable: Billable,
    numberOfOccurrences?: number | null,
    mdUnits?: string | null,
    tasks?:  Array< {
      __typename: "JobServiceTasks",
      taskId: string,
      taskName: string,
    } | null > | null,
  } | null,
};

export type AllJobBidAdditionalExpensesQueryVariables = {
  jobId: string,
  bidId: string,
};

export type AllJobBidAdditionalExpensesQuery = {
  allJobBidAdditionalExpenses?:  Array< {
    __typename: "JobBidAdditionalExpenses",
    bidId: string,
    bidAdditionalExpenseId?: string | null,
    name?: string | null,
    description?: string | null,
    bidSimpleEstimationEstimationId?: string | null,
    rate: number,
    quantity?: number | null,
    totalCost?: number | null,
    mdUnits?: string | null,
    mdServiceCategoryId?: string | null,
    mdAdditionalExpenseCategory: string,
    draftStatus?: DraftStatus | null,
  } | null > | null,
};

export type JobBidAdditionalExpensesQueryVariables = {
  bidId: string,
  additionalCategoryId: string,
};

export type JobBidAdditionalExpensesQuery = {
  jobBidAdditionalExpenses?:  {
    __typename: "JobBidAdditionalExpenses",
    bidId: string,
    bidAdditionalExpenseId?: string | null,
    name?: string | null,
    description?: string | null,
    bidSimpleEstimationEstimationId?: string | null,
    rate: number,
    quantity?: number | null,
    totalCost?: number | null,
    mdUnits?: string | null,
    mdServiceCategoryId?: string | null,
    mdAdditionalExpenseCategory: string,
    draftStatus?: DraftStatus | null,
  } | null,
};

export type JobBidQueryVariables = {
  jobId: string,
  vendorId: string,
};

export type JobBidQuery = {
  jobBid?:  Array< {
    __typename: "JobBid",
    bidId?: string | null,
    jobId?: string | null,
    bidName?: string | null,
    totalCost?: number | null,
    profitMargin?: number | null,
    finalQuote?: number | null,
    bidEstimationType?: BidType | null,
    bidCreationDate?: string | null,
    draftStep?: BidDraftStep | null,
    isFlatMargin?: YesNoType | null,
    draftStatus?: DraftStatus | null,
    dataStatus?: BidStatus | null,
    bidTemplate?: string | null,
    bidVersion?: number | null,
    bidVersionCreatedDate?: string | null,
    submittedBy?: string | null,
    bidSubmissionDate?: string | null,
    mdFrequencyType?: MdFrequencyType | null,
    comments?: string | null,
    bidCreatedFromSample?: string | null,
    errorCode?: string | null,
    vendorId?: string | null,
    vendorName?: string | null,
    vendorEmail?: string | null,
  } | null > | null,
};

export type JobBidsQueryVariables = {
  jobId: string,
  includeRejectedBids?: BooleanType | null,
};

export type JobBidsQuery = {
  jobBids?:  Array< {
    __typename: "JobBids",
    jobBidId?: string | null,
    jobId?: string | null,
    vendorId?: string | null,
    vendorName?: string | null,
    bidName?: string | null,
    vendorEmail?: string | null,
    totalCost?: number | null,
    mdFrequencyType?: MdFrequencyType | null,
    comments?: string | null,
    submittedBy?: string | null,
    bidSubmissionDate?: string | null,
    vendorBidId?: string | null,
    vendorBidIdRecSeq?: number | null,
    jobEstimations?:  Array< {
      __typename: "JobEstimations",
      jobEstimationId?: string | null,
      vendorEstimationId?: string | null,
      jobId?: string | null,
      jobBidId?: string | null,
      mdCategoryId?: string | null,
      quantity?: number | null,
      rate?: number | null,
      quoteAmount?: number | null,
      dataStatus?: string | null,
      bidVersion?: number | null,
    } | null > | null,
    dataStatus?: string | null,
    bidVersion?: number | null,
    revisedJobBids?:  Array< {
      __typename: "RevisedJobBids",
      jobBidId?: string | null,
      jobId?: string | null,
      vendorId?: string | null,
      vendorName?: string | null,
      bidName?: string | null,
      vendorEmail?: string | null,
      totalCost?: number | null,
      mdFrequencyType?: MdFrequencyType | null,
      comments?: string | null,
      submittedBy?: string | null,
      bidSubmissionDate?: string | null,
      vendorBidId?: string | null,
      vendorBidIdRecSeq?: number | null,
      jobEstimations?:  Array< {
        __typename: "JobEstimations",
        jobEstimationId?: string | null,
        vendorEstimationId?: string | null,
        jobId?: string | null,
        jobBidId?: string | null,
        mdCategoryId?: string | null,
        quantity?: number | null,
        rate?: number | null,
        quoteAmount?: number | null,
        dataStatus?: string | null,
        bidVersion?: number | null,
      } | null > | null,
      dataStatus?: string | null,
      bidVersion?: number | null,
    } | null > | null,
    bidComments?:  Array< {
      __typename: "BidComments",
      commentId?: string | null,
      comment?: string | null,
      commentBy?: string | null,
      commentByName?: string | null,
      commenterRoleId?: string | null,
      commenterRoleName?: string | null,
      entityType?: EntityType | null,
      organizationId?: string | null,
      commentDate?: string | null,
      vendorBidId?: string | null,
      jobId?: string | null,
      bidVersion?: number | null,
      actionType?: ActionType | null,
    } | null > | null,
  } | null > | null,
};

export type AllBidsByVendorQueryVariables = {
  vendorId: string,
  serviceMeasure?: Array< ServiceMeasure | null > | null,
  startDate?: string | null,
  endDate?: string | null,
  percentageRange?: Array< RangeInput | null > | null,
  jobType?: Array< JobType | null > | null,
  scheduleType?: Array< ScheduleType | null > | null,
  jobDuration?: JobDuration | null,
  facilityType?: Array< string | null > | null,
  dueDate?: string | null,
  limit?: number | null,
  pageNumber?: number | null,
  searchText?: string | null,
  sortBy?: string | null,
  sortOrder?: string | null,
};

export type AllBidsByVendorQuery = {
  allBidsByVendor?:  {
    __typename: "JobRecommendations",
    serviceLocations?:  Array< {
      __typename: "VendorServiceLocation",
      vendorServiceLocationId?: string | null,
      vendorId?: string | null,
      mdAreaName?: string | null,
      mdAreaType?: string | null,
      mdAreaCode?: string | null,
      mdAreaCategory?: string | null,
      country?: string | null,
      locationId?: string | null,
    } | null > | null,
    servicesOffered?:  Array< {
      __typename: "VendorService",
      vendorServiceOfferedId: string,
      serviceCategoryId: string,
      serviceDescription: string,
      serviceSubCategoryId: string,
    } | null > | null,
    jobsWithinServiceLocations?:  Array< {
      __typename: "JobId",
      id?: string | null,
    } | null > | null,
    matchedJobs?:  {
      __typename: "JobRecommendationWithCount",
      data?:  Array< {
        __typename: "JobRecommendation",
        jobId: string,
        jobName: string,
        specialInstructions?: string | null,
        startDate?: string | null,
        stopDate?: string | null,
        jobFacility?:  {
          __typename: "JobFacility",
          jobId: string,
          facilityId: string,
          mdFacilityType: string,
          facilityAddress?: string | null,
          remarks?: string | null,
          status?: string | null,
          jobStartDate?: string | null,
          jobEndData?: string | null,
        } | null,
        jobSize?: string | null,
        dataStatus?: string | null,
        jobType?: string | null,
        createdOn?: string | null,
        jobServices?:  Array< {
          __typename: "JobService",
          jobId: string,
          mdServiceId: string,
          serviceMeasure: number,
          mdServiceUnits: string,
          tasks?:  Array< {
            __typename: "JobServiceTasks",
            taskId: string,
            taskName: string,
          } | null > | null,
          actualMdServiceUnits?: number | null,
          actualServiceMeasure?: string | null,
        } | null > | null,
        jobFrequencies?:  Array< {
          __typename: "JobFrequency",
          mdShiftType?: string | null,
          mdScheduleType?: string | null,
          mdServiceId?: string | null,
          scheduleDays?: string | null,
          fromTime?: string | null,
          toTime?: string | null,
          cronExpression?: string | null,
          repeatitions?: number | null,
          repetition?: number | null,
        } | null > | null,
        jobVendors?:  Array< {
          __typename: "JobVendor",
          jobId: string,
          vendorId: string,
          mdVendorType: string,
        } | null > | null,
        jobDueDates?:  Array< {
          __typename: "JobDueDate",
          jobId?: string | null,
          mdDueType?: string | null,
          dueDescription?: string | null,
          dueDate?: string | null,
        } | null > | null,
        percentageMatch?: number | null,
      } | null > | null,
      metadata?:  {
        __typename: "PaginationMetaData",
        totalCount?: number | null,
        pageIndex?: number | null,
        limit?: number | null,
      } | null,
    } | null,
    matchedBids?:  Array< {
      __typename: "JobBid",
      bidId?: string | null,
      jobId?: string | null,
      bidName?: string | null,
      totalCost?: number | null,
      profitMargin?: number | null,
      finalQuote?: number | null,
      bidEstimationType?: BidType | null,
      bidCreationDate?: string | null,
      draftStep?: BidDraftStep | null,
      isFlatMargin?: YesNoType | null,
      draftStatus?: DraftStatus | null,
      dataStatus?: BidStatus | null,
      bidTemplate?: string | null,
      bidVersion?: number | null,
      bidVersionCreatedDate?: string | null,
      submittedBy?: string | null,
      bidSubmissionDate?: string | null,
      mdFrequencyType?: MdFrequencyType | null,
      comments?: string | null,
      bidCreatedFromSample?: string | null,
      errorCode?: string | null,
      vendorId?: string | null,
      vendorName?: string | null,
      vendorEmail?: string | null,
    } | null > | null,
    matchedFacilities?:  Array< {
      __typename: "Facility",
      callHours?: CallHours | null,
      transportationName?: string | null,
      storageName?: string | null,
      callStartTime?: string | null,
      callEndTime?: string | null,
      facilityId?: string | null,
      customerId?: string | null,
      buildingName?: string | null,
      buildingMakeup?: string | null,
      noOfFloors?: number | null,
      sqFootage?: number | null,
      mdFacilityType?: string | null,
      serviceableSqFootage?: number | null,
      occupancy?: number | null,
      supplyStorageAvailability?: boolean | null,
      transportRequiredWithinLocation?: boolean | null,
      contactId?: string | null,
      facilityTrafficLevel?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      cleaningInstructions?: string | null,
      geoFenceRadius?: number | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        addressId?: string | null,
        customerId?: string | null,
        addressName?: string | null,
        noOfBuildings?: number | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        stateCode?: string | null,
        county?: string | null,
        postalCode?: string | null,
        mdCountryCode?: string | null,
        landmark?: string | null,
        googlePlaceId?: string | null,
        latitude?: string | null,
        longitude?: string | null,
        mdMsaId?: string | null,
        logoUrl?: string | null,
        intId?: string | null,
      } | null,
    } | null > | null,
  } | null,
};

export type AllBidSubmissionsByVendorIdQueryVariables = {
  vendorId: string,
  limit?: number | null,
  pageNumber?: number | null,
};

export type AllBidSubmissionsByVendorIdQuery = {
  allBidSubmissionsByVendorId?:  {
    __typename: "JobRecommendations",
    serviceLocations?:  Array< {
      __typename: "VendorServiceLocation",
      vendorServiceLocationId?: string | null,
      vendorId?: string | null,
      mdAreaName?: string | null,
      mdAreaType?: string | null,
      mdAreaCode?: string | null,
      mdAreaCategory?: string | null,
      country?: string | null,
      locationId?: string | null,
    } | null > | null,
    servicesOffered?:  Array< {
      __typename: "VendorService",
      vendorServiceOfferedId: string,
      serviceCategoryId: string,
      serviceDescription: string,
      serviceSubCategoryId: string,
    } | null > | null,
    jobsWithinServiceLocations?:  Array< {
      __typename: "JobId",
      id?: string | null,
    } | null > | null,
    matchedJobs?:  {
      __typename: "JobRecommendationWithCount",
      data?:  Array< {
        __typename: "JobRecommendation",
        jobId: string,
        jobName: string,
        specialInstructions?: string | null,
        startDate?: string | null,
        stopDate?: string | null,
        jobFacility?:  {
          __typename: "JobFacility",
          jobId: string,
          facilityId: string,
          mdFacilityType: string,
          facilityAddress?: string | null,
          remarks?: string | null,
          status?: string | null,
          jobStartDate?: string | null,
          jobEndData?: string | null,
        } | null,
        jobSize?: string | null,
        dataStatus?: string | null,
        jobType?: string | null,
        createdOn?: string | null,
        jobServices?:  Array< {
          __typename: "JobService",
          jobId: string,
          mdServiceId: string,
          serviceMeasure: number,
          mdServiceUnits: string,
          tasks?:  Array< {
            __typename: "JobServiceTasks",
            taskId: string,
            taskName: string,
          } | null > | null,
          actualMdServiceUnits?: number | null,
          actualServiceMeasure?: string | null,
        } | null > | null,
        jobFrequencies?:  Array< {
          __typename: "JobFrequency",
          mdShiftType?: string | null,
          mdScheduleType?: string | null,
          mdServiceId?: string | null,
          scheduleDays?: string | null,
          fromTime?: string | null,
          toTime?: string | null,
          cronExpression?: string | null,
          repeatitions?: number | null,
          repetition?: number | null,
        } | null > | null,
        jobVendors?:  Array< {
          __typename: "JobVendor",
          jobId: string,
          vendorId: string,
          mdVendorType: string,
        } | null > | null,
        jobDueDates?:  Array< {
          __typename: "JobDueDate",
          jobId?: string | null,
          mdDueType?: string | null,
          dueDescription?: string | null,
          dueDate?: string | null,
        } | null > | null,
        percentageMatch?: number | null,
      } | null > | null,
      metadata?:  {
        __typename: "PaginationMetaData",
        totalCount?: number | null,
        pageIndex?: number | null,
        limit?: number | null,
      } | null,
    } | null,
    matchedBids?:  Array< {
      __typename: "JobBid",
      bidId?: string | null,
      jobId?: string | null,
      bidName?: string | null,
      totalCost?: number | null,
      profitMargin?: number | null,
      finalQuote?: number | null,
      bidEstimationType?: BidType | null,
      bidCreationDate?: string | null,
      draftStep?: BidDraftStep | null,
      isFlatMargin?: YesNoType | null,
      draftStatus?: DraftStatus | null,
      dataStatus?: BidStatus | null,
      bidTemplate?: string | null,
      bidVersion?: number | null,
      bidVersionCreatedDate?: string | null,
      submittedBy?: string | null,
      bidSubmissionDate?: string | null,
      mdFrequencyType?: MdFrequencyType | null,
      comments?: string | null,
      bidCreatedFromSample?: string | null,
      errorCode?: string | null,
      vendorId?: string | null,
      vendorName?: string | null,
      vendorEmail?: string | null,
    } | null > | null,
    matchedFacilities?:  Array< {
      __typename: "Facility",
      callHours?: CallHours | null,
      transportationName?: string | null,
      storageName?: string | null,
      callStartTime?: string | null,
      callEndTime?: string | null,
      facilityId?: string | null,
      customerId?: string | null,
      buildingName?: string | null,
      buildingMakeup?: string | null,
      noOfFloors?: number | null,
      sqFootage?: number | null,
      mdFacilityType?: string | null,
      serviceableSqFootage?: number | null,
      occupancy?: number | null,
      supplyStorageAvailability?: boolean | null,
      transportRequiredWithinLocation?: boolean | null,
      contactId?: string | null,
      facilityTrafficLevel?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      cleaningInstructions?: string | null,
      geoFenceRadius?: number | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        addressId?: string | null,
        customerId?: string | null,
        addressName?: string | null,
        noOfBuildings?: number | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        stateCode?: string | null,
        county?: string | null,
        postalCode?: string | null,
        mdCountryCode?: string | null,
        landmark?: string | null,
        googlePlaceId?: string | null,
        latitude?: string | null,
        longitude?: string | null,
        mdMsaId?: string | null,
        logoUrl?: string | null,
        intId?: string | null,
      } | null,
    } | null > | null,
  } | null,
};

export type AllBidSubmissionsForJobIdQueryVariables = {
  jobId: string,
};

export type AllBidSubmissionsForJobIdQuery = {
  allBidSubmissionsForJobId?:  Array< {
    __typename: "JobBid",
    bidId?: string | null,
    jobId?: string | null,
    bidName?: string | null,
    totalCost?: number | null,
    profitMargin?: number | null,
    finalQuote?: number | null,
    bidEstimationType?: BidType | null,
    bidCreationDate?: string | null,
    draftStep?: BidDraftStep | null,
    isFlatMargin?: YesNoType | null,
    draftStatus?: DraftStatus | null,
    dataStatus?: BidStatus | null,
    bidTemplate?: string | null,
    bidVersion?: number | null,
    bidVersionCreatedDate?: string | null,
    submittedBy?: string | null,
    bidSubmissionDate?: string | null,
    mdFrequencyType?: MdFrequencyType | null,
    comments?: string | null,
    bidCreatedFromSample?: string | null,
    errorCode?: string | null,
    vendorId?: string | null,
    vendorName?: string | null,
    vendorEmail?: string | null,
  } | null > | null,
};

export type JobFacilityQueryVariables = {
  customerId: string,
  jobId: string,
};

export type JobFacilityQuery = {
  jobFacility?:  Array< {
    __typename: "JobFacility",
    jobId: string,
    facilityId: string,
    mdFacilityType: string,
    facilityAddress?: string | null,
    remarks?: string | null,
    status?: string | null,
    jobStartDate?: string | null,
    jobEndData?: string | null,
  } | null > | null,
};

export type JobBidSummaryQueryVariables = {
  bidId: string,
};

export type JobBidSummaryQuery = {
  jobBidSummary?:  {
    __typename: "JobBidSummary",
    vendorId: string,
    vendorName?: string | null,
    vendorEmail?: string | null,
    bidId: string,
    jobId: string,
    bidName: string,
    totalCost: number,
    comments?: string | null,
    // this is not using, the key being used is bidComments
    profitMargin: number,
    isFlatMargin?: YesNoType | null,
    finalQuote: number,
    bidEstimationType?: BidType | null,
    bidCreationDate: string,
    draftStep?: BidDraftStep | null,
    draftStatus?: DraftStatus | null,
    dataStatus: BidStatus,
    bidTemplate: string,
    bidCreatedFromSample: string,
    bidVersion: number,
    bidVersionCreatedDate: string,
    submittedBy: string,
    bidSubmissionDate: string,
    bidSimpleEstimation?:  Array< {
      __typename: "JobBidEstimation",
      jobId: string,
      estimationId: string,
      bidId: string,
      mdCategoryId: string,
      mdTaskId?: string | null,
      mdMeasureType: string,
      rate: number,
      quantity: number,
      isOverTime?: YesNoType | null,
      totalCost: number,
      isVendorAdded: YesNoType,
      isBillable: Billable,
      numberOfOccurrences?: number | null,
      mdUnits?: string | null,
      tasks?:  Array< {
        __typename: "JobServiceTasks",
        taskId: string,
        taskName: string,
      } | null > | null,
    } | null > | null,
    bidAdditionalExpenses?:  Array< {
      __typename: "JobBidAdditionalExpenses",
      bidId: string,
      bidAdditionalExpenseId?: string | null,
      name?: string | null,
      description?: string | null,
      bidSimpleEstimationEstimationId?: string | null,
      rate: number,
      quantity?: number | null,
      totalCost?: number | null,
      mdUnits?: string | null,
      mdServiceCategoryId?: string | null,
      mdAdditionalExpenseCategory: string,
      draftStatus?: DraftStatus | null,
    } | null > | null,
    mdFrequencyType?: MdFrequencyType | null,
    customerId?: string | null,
    customerName?: string | null,
    isCustomerApprovalAvailable?: boolean | null,
    transactionStatus?: string | null,
    bidComments?:  Array< {
      __typename: "BidComments",
      commentId?: string | null,
      comment?: string | null,
      commentBy?: string | null,
      commentByName?: string | null,
      commenterRoleId?: string | null,
      commenterRoleName?: string | null,
      entityType?: EntityType | null,
      organizationId?: string | null,
      commentDate?: string | null,
      vendorBidId?: string | null,
      jobId?: string | null,
      bidVersion?: number | null,
      actionType?: ActionType | null,
    } | null > | null,
  } | null,
};

export type FacilityWithLocationQueryVariables = {
  inputFacilityIds: Array< string | null >,
};

export type FacilityWithLocationQuery = {
  facilityWithLocation?:  Array< {
    __typename: "Facility",
    callHours?: CallHours | null,
    transportationName?: string | null,
    storageName?: string | null,
    callStartTime?: string | null,
    callEndTime?: string | null,
    facilityId?: string | null,
    customerId?: string | null,
    buildingName?: string | null,
    buildingMakeup?: string | null,
    noOfFloors?: number | null,
    sqFootage?: number | null,
    mdFacilityType?: string | null,
    serviceableSqFootage?: number | null,
    occupancy?: number | null,
    supplyStorageAvailability?: boolean | null,
    transportRequiredWithinLocation?: boolean | null,
    contactId?: string | null,
    facilityTrafficLevel?: string | null,
    emergencyContactName?: string | null,
    emergencyContactPhone?: string | null,
    cleaningInstructions?: string | null,
    geoFenceRadius?: number | null,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      addressId?: string | null,
      customerId?: string | null,
      addressName?: string | null,
      noOfBuildings?: number | null,
      addressLine1?: string | null,
      addressLine2?: string | null,
      addressLine3?: string | null,
      city?: string | null,
      stateCode?: string | null,
      county?: string | null,
      postalCode?: string | null,
      mdCountryCode?: string | null,
      landmark?: string | null,
      googlePlaceId?: string | null,
      latitude?: string | null,
      longitude?: string | null,
      mdMsaId?: string | null,
      logoUrl?: string | null,
      intId?: string | null,
    } | null,
  } | null > | null,
};

export type FacilityServiceSpecificationQueryVariables = {
  customerId: string,
  facilityId: string,
};

export type FacilityServiceSpecificationQuery = {
  facilityServiceSpecification?:  Array< {
    __typename: "FacilityServiceSpecification",
    facilityId: string,
    floorNumber?: string | null,
    mdServiceId: string,
    serviceQuantity: number,
    mdServiceUnitType: string,
  } | null > | null,
};

export type JobInterestsQueryVariables = {
  customerId: string,
  vendorId: string,
};

export type JobInterestsQuery = {
  jobInterests?:  Array< {
    __typename: "JobInterest",
    vendorId: string,
    jobId: string,
    actionType: string,
    actionTime?: string | null,
    actionUser?: string | null,
  } | null > | null,
};

export type JobInterestVendorsQueryVariables = {
  jobId: string,
  isInterested: boolean,
};

export type JobInterestVendorsQuery = {
  jobInterestVendors?:  Array< {
    __typename: "JobInterest",
    vendorId: string,
    jobId: string,
    actionType: string,
    actionTime?: string | null,
    actionUser?: string | null,
  } | null > | null,
};

export type WorkOrderTeamQueryVariables = {
  workOrderId: string,
  workDayId?: string | null,
  taskId?: string | null,
  fromDate?: string | null,
  toDate?: string | null,
  punchInGraceMinutes?: number | null,
  punchOutGraceMinutes?: number | null,
};

export type WorkOrderTeamQuery = {
  workOrderTeam?:  Array< {
    __typename: "WorkOrderTeamWithAssignment",
    teamId: string,
    vendorId?: string | null,
    userId?: string | null,
    roleId?: string | null,
    isSupervisor?: boolean | null,
    workOrderId?: string | null,
    taskAssignments?:  Array< {
      __typename: "TaskAssignment",
      taskId?: string | null,
      taskAssignmentId?: string | null,
      assignedBy?: string | null,
      assignedOn?: string | null,
      associateId?: string | null,
    } | null > | null,
    associateStartDate?: string | null,
    associateEndDate?: string | null,
    cognitoUserId?: string | null,
    workDayId?: string | null,
    workDayTeam?:  {
      __typename: "WorkDayTeamForWorkOrderTeamWithAssignment",
      workOrderId?: string | null,
      workDayId?: string | null,
      facilityId?: string | null,
      associateId?: string | null,
    } | null,
  } | null > | null,
};

export type GetWorkOrderTeamQueryVariables = {
  workOrderId: string,
};

export type GetWorkOrderTeamQuery = {
  getWorkOrderTeam?:  Array< {
    __typename: "WorkOrderTeam",
    teamId: string,
    vendorId?: string | null,
    userId?: string | null,
    roleId?: string | null,
    isSupervisor?: boolean | null,
    workOrderId?: string | null,
    jobAccepted?: boolean | null,
    associateStartDate?: string | null,
    associateEndDate?: string | null,
  } | null > | null,
};

export type AllUpcomingJobsByVendorIdQueryVariables = {
  vendorId?: string | null,
  customerId?: string | null,
  status?: string | null,
  isJobAccepted?: boolean | null,
  workOrderId?: string | null,
  userId?: string | null,
  facilityId?: string | null,
  jobStartDate?: string | null,
  jobEndDate?: string | null,
  pageNumber?: number | null,
  jobId?: string | null,
  limit?: number | null,
  punchInGraceMinutes?: number | null,
  punchOutGraceMinutes?: number | null,
  jobCreatedBy?: string | null,
  jobCreatorRoleName?: string | null,
  sortBy?: string | null,
  sortOrder?: string | null,
  searchText?: string | null,
};

export type AllUpcomingJobsByVendorIdQuery = {
  allUpcomingJobsByVendorId?:  {
    __typename: "WorkOrderWithCount",
    data?:  Array< {
      __typename: "WorkOrder",
      workOrderId?: string | null,
      jobId?: string | null,
      bidId?: string | null,
      vendorId?: string | null,
      customerId?: string | null,
      name?: string | null,
      status?: WorkOrderStatus | null,
      totalAmount?: number | null,
      createdBy?: string | null,
      createdOn?: string | null,
      modifiedBy?: string | null,
      modifiedOn?: string | null,
      jobStartDate?: string | null,
      jobType?: JobType | null,
      jobEndDate?: string | null,
      actualStartDate?: string | null,
      actualEndDate?: string | null,
      jobCustodian?: string | null,
      jobOrderAcceptedOn?: string | null,
      jobOrderAcceptedBy?: string | null,
      jobOrderFilePath?: string | null,
      paymentMethod?: PaymentMethod | null,
      paymentFrequency?: PaymentFrequency | null,
      paymentDay?: string | null,
      facilities?:  Array< {
        __typename: "WorkOrderFacilityAccess",
        facilityId?: string | null,
        facilityName?: string | null,
        facilityAddress?: string | null,
        accessContactPerson?: string | null,
        accessContactDetails?: string | null,
        otherInformation?: string | null,
        accessInfo?: string | null,
        accessCode?: string | null,
        day?: string | null,
        accessFrom?: string | null,
        accessTo?: string | null,
        from?: string | null,
        to?: string | null,
        workOrderFacilityAccessId?: string | null,
      } | null > | null,
      services?:  Array< {
        __typename: "WorkOrderService",
        workOrderServiceId?: string | null,
        mdServiceId?: string | null,
        serviceMeasure?: number | null,
        mdServiceUnits?: string | null,
        taskSchedule?:  Array< {
          __typename: "WorkOrderTaskSchedule",
          taskScheduleId?: string | null,
          mdTaskId?: string | null,
          isProofOfCompletionRequired?: WorkOrderYesNoType | null,
        } | null > | null,
      } | null > | null,
      jobBidSummary?:  {
        __typename: "WorkOrderJobBidSummary",
        jobBidSummaryId?: string | null,
        jobDetails?: string | null,
        bidDetails?: string | null,
      } | null,
      workOrderTeams?:  Array< {
        __typename: "WorkOrderTeam",
        teamId: string,
        vendorId?: string | null,
        userId?: string | null,
        roleId?: string | null,
        isSupervisor?: boolean | null,
        workOrderId?: string | null,
        jobAccepted?: boolean | null,
        associateStartDate?: string | null,
        associateEndDate?: string | null,
      } | null > | null,
      shiftDetails?:  Array< {
        __typename: "ShiftDetailsOfWorkOrder",
        shiftDetailId?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        punchInGraceMinutes?: number | null,
        punchOutGraceMinutes?: number | null,
      } | null > | null,
      subWorkOrders?:  Array< {
        __typename: "SubWorkOrder",
        workOrderId?: string | null,
        jobId?: string | null,
        bidId?: string | null,
        vendorId?: string | null,
        customerId?: string | null,
        name?: string | null,
        status?: WorkOrderStatus | null,
        totalAmount?: number | null,
        createdBy?: string | null,
        createdOn?: string | null,
        modifiedBy?: string | null,
        modifiedOn?: string | null,
        jobStartDate?: string | null,
        jobType?: JobType | null,
        jobEndDate?: string | null,
        actualStartDate?: string | null,
        actualEndDate?: string | null,
        jobCustodian?: string | null,
        jobOrderAcceptedOn?: string | null,
        jobOrderAcceptedBy?: string | null,
        jobOrderFilePath?: string | null,
        paymentMethod?: PaymentMethod | null,
        paymentFrequency?: PaymentFrequency | null,
        paymentDay?: string | null,
        hasChild?: boolean | null,
        parentWorkOrderId?: string | null,
        services?:  Array< {
          __typename: "WorkOrderService",
          workOrderServiceId?: string | null,
          mdServiceId?: string | null,
          serviceMeasure?: number | null,
          mdServiceUnits?: string | null,
          taskSchedule?:  Array< {
            __typename: "WorkOrderTaskSchedule",
            taskScheduleId?: string | null,
            mdTaskId?: string | null,
            isProofOfCompletionRequired?: WorkOrderYesNoType | null,
          } | null > | null,
        } | null > | null,
        shiftDetails?:  Array< {
          __typename: "ShiftDetailsOfWorkOrder",
          shiftDetailId?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          punchInGraceMinutes?: number | null,
          punchOutGraceMinutes?: number | null,
        } | null > | null,
        jobBidSummary?:  {
          __typename: "WorkOrderJobBidSummary",
          jobBidSummaryId?: string | null,
          jobDetails?: string | null,
          bidDetails?: string | null,
        } | null,
        bidApprovalStatus?: string | null,
      } | null > | null,
      hasChild?: boolean | null,
      parentWorkOrderId?: string | null,
      jobCreator?: string | null,
      bidApprovalStatus?: string | null,
      allowVendorToReschedule?: boolean | null,
      isPaid?: boolean | null,
      workOrderRating?: number | null,
    } | null > | null,
    metadata?:  {
      __typename: "PaginationMetaData",
      totalCount?: number | null,
      pageIndex?: number | null,
      limit?: number | null,
    } | null,
  } | null,
};

export type AllPreShiftChecklistTemplateQueryVariables = {
  workOrderId: string,
};

export type AllPreShiftChecklistTemplateQuery = {
  allPreShiftChecklistTemplate?:  Array< {
    __typename: "PreShiftChecklistTemplate",
    preShiftChecklistTemplateId: string,
    checkListName: string,
    checkListIconUrl: string,
    mdPreShiftCheckListCategoryId: string,
    buildingAccessConfirmation: WorkOrderYesNoType,
    securityAccessConfirmation: WorkOrderYesNoType,
    safetyConfirmation: WorkOrderYesNoType,
    msdsChemicalSafety: WorkOrderYesNoType,
    workStatus: WorkOrderStatus,
    workOrderId: string,
    workOrderRecSeq: number,
    preShiftChecklistTemplateItems?:  Array< {
      __typename: "PreShiftChecklistTemplateItem",
      itemName: string,
      preShiftChecklistTemplateItemId: string,
      mdItemActionType: PreShiftChecklistTemplateItemActionType,
      quantity: number,
      mdQuantityType: PreShiftChecklistTemplateItemQuantityType,
    } | null > | null,
  } | null > | null,
};

export type PreShiftChecklistTemplateQueryVariables = {
  workOrderId: string,
  preShiftChecklistTemplateId: string,
};

export type PreShiftChecklistTemplateQuery = {
  preShiftChecklistTemplate?:  {
    __typename: "PreShiftChecklistTemplate",
    preShiftChecklistTemplateId: string,
    checkListName: string,
    checkListIconUrl: string,
    mdPreShiftCheckListCategoryId: string,
    buildingAccessConfirmation: WorkOrderYesNoType,
    securityAccessConfirmation: WorkOrderYesNoType,
    safetyConfirmation: WorkOrderYesNoType,
    msdsChemicalSafety: WorkOrderYesNoType,
    workStatus: WorkOrderStatus,
    workOrderId: string,
    workOrderRecSeq: number,
    preShiftChecklistTemplateItems?:  Array< {
      __typename: "PreShiftChecklistTemplateItem",
      itemName: string,
      preShiftChecklistTemplateItemId: string,
      mdItemActionType: PreShiftChecklistTemplateItemActionType,
      quantity: number,
      mdQuantityType: PreShiftChecklistTemplateItemQuantityType,
    } | null > | null,
  } | null,
};

export type AllPreShiftChecklistQueryVariables = {
  workDayId: string,
};

export type AllPreShiftChecklistQuery = {
  allPreShiftChecklist?:  Array< {
    __typename: "PreShiftChecklist",
    executionDate: string,
    workDayId?: string | null,
    preShiftChecklistId?: string | null,
    checkListName?: string | null,
    checkListIconUrl?: string | null,
    mdPreShiftCheckListCategoryId?: string | null,
    preShiftChecklistTemplateId: string,
    preShiftChecklistItems?:  Array< {
      __typename: "PreShiftChecklistItem",
      itemName?: string | null,
      mdItemActionType?: PreShiftChecklistTemplateItemActionType | null,
      quantity?: number | null,
      mdQuantityType?: PreShiftChecklistTemplateItemQuantityType | null,
      workOrderServiceId?: string | null,
      markedAsDone?: WorkOrderYesNoType | null,
      markedBy?: string | null,
      markedOn?: string | null,
      preShiftChecklistId?: string | null,
      preShiftChecklistItemId?: string | null,
      preShiftChecklistTemplateItemId: string,
      preShiftChecklistIssueId?: string | null,
    } | null > | null,
  } | null > | null,
};

export type PreShiftChecklistQueryVariables = {
  preShiftChecklistTemplateId: string,
  preShiftChecklistId: string,
};

export type PreShiftChecklistQuery = {
  preShiftChecklist?:  {
    __typename: "PreShiftChecklist",
    executionDate: string,
    workDayId?: string | null,
    preShiftChecklistId?: string | null,
    checkListName?: string | null,
    checkListIconUrl?: string | null,
    mdPreShiftCheckListCategoryId?: string | null,
    preShiftChecklistTemplateId: string,
    preShiftChecklistItems?:  Array< {
      __typename: "PreShiftChecklistItem",
      itemName?: string | null,
      mdItemActionType?: PreShiftChecklistTemplateItemActionType | null,
      quantity?: number | null,
      mdQuantityType?: PreShiftChecklistTemplateItemQuantityType | null,
      workOrderServiceId?: string | null,
      markedAsDone?: WorkOrderYesNoType | null,
      markedBy?: string | null,
      markedOn?: string | null,
      preShiftChecklistId?: string | null,
      preShiftChecklistItemId?: string | null,
      preShiftChecklistTemplateItemId: string,
      preShiftChecklistIssueId?: string | null,
    } | null > | null,
  } | null,
};

export type AllShiftDetailsQueryVariables = {
  workOrderId: string,
};

export type AllShiftDetailsQuery = {
  allShiftDetails?:  Array< {
    __typename: "ShiftDetailsWithWorkOrder",
    shiftDetailId: string,
    startTime: string,
    endTime: string,
    workOrderId: string,
    punchInGraceMinutes?: number | null,
    punchOutGraceMinutes?: number | null,
    workOrder?:  {
      __typename: "ShiftDetailsWorkOrder",
      jobStartDate?: string | null,
      jobEndDate?: string | null,
      actualStartDate?: string | null,
      actualEndDate?: string | null,
    } | null,
  } | null > | null,
};

export type ShiftDetailsQueryVariables = {
  shiftDetailId: string,
};

export type ShiftDetailsQuery = {
  shiftDetails?:  {
    __typename: "ShiftDetailsWithWorkOrder",
    shiftDetailId: string,
    startTime: string,
    endTime: string,
    workOrderId: string,
    punchInGraceMinutes?: number | null,
    punchOutGraceMinutes?: number | null,
    workOrder?:  {
      __typename: "ShiftDetailsWorkOrder",
      jobStartDate?: string | null,
      jobEndDate?: string | null,
      actualStartDate?: string | null,
      actualEndDate?: string | null,
    } | null,
  } | null,
};

export type TasksV2QueryVariables = {
  workOrderId?: string | null,
  workDayId?: string | null,
  status?: string | null,
  facilityId?: string | null,
  associateId?: string | null,
  workDate?: string | null,
  mdTaskId?: string | null,
  fromDate?: string | null,
  toDate?: string | null,
  punchInGraceMinutes?: number | null,
  punchOutGraceMinutes?: number | null,
};

export type TasksV2Query = {
  tasksV2?:  Array< {
    __typename: "TasksV2",
    mdServiceType?: string | null,
    serviceCategoryName?: string | null,
    categoryDescription?: string | null,
    createdBy?: string | null,
    mdAreaType?: string | null,
    orgId?: string | null,
    mdServiceId?: string | null,
    categoryImageUrl?: string | null,
    tasks?:  Array< {
      __typename: "TaskV2Details",
      taskDescription?: string | null,
      taskId?: string | null,
      taskNumber?: number | null,
      mdTaskId?: string | null,
      taskName?: string | null,
      unit?: string | null,
      isProofOfCompletionRequired?: string | null,
      status?: string | null,
      actualStartTime?: string | null,
      actualEndTime?: string | null,
      duration?: number | null,
      associate?: string | null,
      salutation?: string | null,
      associateId?: string | null,
      workDayId?: string | null,
      workOrderId?: string | null,
      markedAsDone?: string | null,
      markedBy?: string | null,
      markedOn?: string | null,
      reworkTaskId?: string | null,
      occurrenceDate?: string | null,
      suggestedReworkDate?: string | null,
      suggestedScheduleType?: TaskScheduleType | null,
      taskAttachments?:  Array< {
        __typename: "TaskV2Attachment",
        attachmentId?: string | null,
        attachmentStatus?: AttachmentStatus | null,
        mdAttachmentType?: string | null,
        fileName?: string | null,
        mdFileContext?: string | null,
        fileExtension?: string | null,
        url?: string | null,
        fileHash?: string | null,
        comments?: string | null,
        taskId?: string | null,
      } | null > | null,
      nextAvailableFiveWorkdays?:  Array< {
        __typename: "WorkDayDetails",
        workDate?: string | null,
        workDayId?: string | null,
      } | null > | null,
      nextAvailableWorkDay?:  {
        __typename: "WorkDayDetails",
        workDate?: string | null,
        workDayId?: string | null,
      } | null,
      isPartiallyCompleted?: boolean | null,
      isCurrentShiftAvailable?: boolean | null,
      reworkTasks?:  Array< {
        __typename: "ReworkTasksV2Details",
        taskId?: string | null,
        taskNumber?: number | null,
        occurrenceDate?: string | null,
        suggestedReworkDate?: string | null,
        suggestedScheduleType?: TaskScheduleType | null,
        markedAsDone?: string | null,
        markedBy?: string | null,
        reworkTaskId?: string | null,
        markedOn?: string | null,
        facilityId?: string | null,
        facilityAreaServiceId?: string | null,
        taskStatus?: string | null,
        mdServiceDescription?: string | null,
        plannedStartTime?: string | null,
        plannedEndTime?: string | null,
        actualEffort?: string | null,
        actualStartTime?: string | null,
        actualEndTime?: string | null,
        associateId?: string | null,
        workOrderId?: string | null,
        workDayId?: string | null,
        taskScheduleId?: string | null,
        taskSagaId?: string | null,
        mdTaskId?: string | null,
        taskName?: string | null,
        taskDescription?: string | null,
        taskImageUrl?: string | null,
        ISSACode?: string | null,
        rate?: string | null,
        unit?: string | null,
        isPartiallyCompleted?: boolean | null,
        taskSchedule?:  {
          __typename: "TaskScheduleForTasksV2",
          mdTaskId?: string | null,
          noOfOccurrences?: string | null,
          taskScheduleId?: string | null,
          isProofOfCompletionRequired?: string | null,
          duration?: string | null,
        } | null,
        taskAssignment?:  Array< {
          __typename: "TaskAssignmentForTasksV2",
          taskId?: string | null,
          assignedBy?: string | null,
          associateId?: string | null,
          assignedOn?: string | null,
        } | null > | null,
        taskAttachment?:  Array< {
          __typename: "Attachment",
          comments?: string | null,
          attachmentId?: string | null,
          attachmentStatus?: string | null,
          mdAttachmentType?: string | null,
          fileName?: string | null,
          mdFileContext?: string | null,
          fileExtension?: string | null,
          url?: string | null,
          fileHash?: string | null,
          dataStatus?: string | null,
        } | null > | null,
        approvalSaga?:  Array< {
          __typename: "ApprovalSagaData",
          approvalType?: ApprovalStatus | null,
          actionedOn?: string | null,
          actionedById?: string | null,
          actionedByName?: string | null,
          salutation?: string | null,
          actionedByRole?:  {
            __typename: "RoleDetails",
            roleId?: string | null,
            roleName?: string | null,
          } | null,
          comment?: string | null,
          taskId?: string | null,
          approverType?: ApproverType | null,
        } | null > | null,
        attachmentLinks?:  Array< {
          __typename: "ApprovalAttachmentsLink",
          attachmentId?: string | null,
          link?: string | null,
        } | null > | null,
      } | null > | null,
      createdOn?: string | null,
      modifiedOn?: string | null,
      approvalSaga?:  Array< {
        __typename: "ApprovalSagaData",
        approvalType?: ApprovalStatus | null,
        actionedOn?: string | null,
        actionedById?: string | null,
        actionedByName?: string | null,
        salutation?: string | null,
        actionedByRole?:  {
          __typename: "RoleDetails",
          roleId?: string | null,
          roleName?: string | null,
        } | null,
        comment?: string | null,
        taskId?: string | null,
        approverType?: ApproverType | null,
      } | null > | null,
      taskAttachmentSaga?:  Array< {
        __typename: "TaskV2Attachment",
        attachmentId?: string | null,
        attachmentStatus?: AttachmentStatus | null,
        mdAttachmentType?: string | null,
        fileName?: string | null,
        mdFileContext?: string | null,
        fileExtension?: string | null,
        url?: string | null,
        fileHash?: string | null,
        comments?: string | null,
        taskId?: string | null,
      } | null > | null,
    } | null > | null,
  } | null > | null,
};

export type WorkOrderFacilityAccessQueryVariables = {
  workOrderId: string,
  workOrderFacilityAccessId: string,
};

export type WorkOrderFacilityAccessQuery = {
  workOrderFacilityAccess?:  {
    __typename: "WorkOrderFacilityAccess",
    facilityId?: string | null,
    facilityName?: string | null,
    facilityAddress?: string | null,
    accessContactPerson?: string | null,
    accessContactDetails?: string | null,
    otherInformation?: string | null,
    accessInfo?: string | null,
    accessCode?: string | null,
    day?: string | null,
    accessFrom?: string | null,
    accessTo?: string | null,
    from?: string | null,
    to?: string | null,
    workOrderFacilityAccessId?: string | null,
  } | null,
};

export type CustomerFacilityAccessQueryVariables = {
  customerId: string,
  facilityId: string,
};

export type CustomerFacilityAccessQuery = {
  customerFacilityAccess?:  Array< {
    __typename: "CustomerFacilityAccessDetails",
    facilityAccessDetailId: string,
    facilityId: string,
    jobId?: string | null,
    accessContactPerson: string,
    roleName: string,
    accessContactDetails: string,
    otherInformation?: string | null,
    accessInfo?: string | null,
    accessCode?: string | null,
    day?: string | null,
    from?: string | null,
    to?: string | null,
  } | null > | null,
};

export type WorkOrderTaskAttachmentQueryVariables = {
  taskId: string,
};

export type WorkOrderTaskAttachmentQuery = {
  workOrderTaskAttachment?:  Array< {
    __typename: "TaskAttachment",
    attachmentId?: string | null,
    attachmentStatus?: AttachmentStatus | null,
    mdAttachmentType?: string | null,
    fileName?: string | null,
    mdFileContext?: string | null,
    fileExtension?: string | null,
    url?: string | null,
    fileHash?: string | null,
    comments?: string | null,
    createdById?: string | null,
    createdByName?: string | null,
  } | null > | null,
};

export type AllTaskApprovalQueryVariables = {
  taskId: string,
};

export type AllTaskApprovalQuery = {
  allTaskApproval?:  Array< {
    __typename: "TaskApproval",
    approvalId: string,
    approvalName?: ApprovalType | null,
    dueDate?: string | null,
    startDate?: string | null,
    completionDate?: string | null,
    reason?: string | null,
    status?: string | null,
    rankingRate?: number | null,
    approvalStatus?: string | null,
    mdApproverType?: string | null,
  } | null > | null,
};

export type AllPunchInOutByWorkDayQueryVariables = {
  workDayId: string,
  userId?: string | null,
};

export type AllPunchInOutByWorkDayQuery = {
  allPunchInOutByWorkDay?:  Array< {
    __typename: "PunchCardItem",
    punchCardId?: string | null,
    workDayId?: string | null,
    userId?: string | null,
    punchTime?: string | null,
    punchType?: PunchType | null,
    locationLatitude?: number | null,
    locationLongitude?: number | null,
    punchComments?: string | null,
    status?: string | null,
    errorCode?: string | null,
    punchNotifications?: string | null,
    punchMealPeriodAttestation?: string | null,
    punchWorkersCompConfirmation?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    punchInTime?: string | null,
    punchOutTime?: string | null,
    totalWorkMinutes?: number | null,
    totalMinutesOnSite?: number | null,
    facilityDistance?: number | null,
  } | null > | null,
};

export type LatestPunchInOutByWorkDayQueryVariables = {
  workDayId: string,
  userId?: string | null,
  showRemovedAssociatePunches?: boolean | null,
};

export type LatestPunchInOutByWorkDayQuery = {
  latestPunchInOutByWorkDay?:  {
    __typename: "PunchCardItem",
    punchCardId?: string | null,
    workDayId?: string | null,
    userId?: string | null,
    punchTime?: string | null,
    punchType?: PunchType | null,
    locationLatitude?: number | null,
    locationLongitude?: number | null,
    punchComments?: string | null,
    status?: string | null,
    errorCode?: string | null,
    punchNotifications?: string | null,
    punchMealPeriodAttestation?: string | null,
    punchWorkersCompConfirmation?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    punchInTime?: string | null,
    punchOutTime?: string | null,
    totalWorkMinutes?: number | null,
    totalMinutesOnSite?: number | null,
    facilityDistance?: number | null,
  } | null,
};

export type AllJobShiftDetailsByVendorWorkDayQueryVariables = {
  vendorId: string,
  userId?: string | null,
  workDate: string,
  facilityId?: string | null,
  isJobAccepted?: string | null,
  workOrderStatus?: WorkOrderStatus | null,
};

export type AllJobShiftDetailsByVendorWorkDayQuery = {
  allJobShiftDetailsByVendorWorkDay?:  Array< {
    __typename: "VendorJobScheduleItem",
    vendorId: string,
    teamId: string,
    userId: string,
    workOrder:  {
      __typename: "VendorJobScheduleWorkOrder",
      workOrderId: string,
      customerId: string,
      vendorId: string,
      jobId: string,
      status: WorkOrderStatus,
      jobBidSummary:  {
        __typename: "VendorWorkOrderJobBidSummary",
        jobName: string,
        startDate: string,
        stopDate: string,
      },
      shiftDetails:  Array< {
        __typename: "VendorWorkOrderShiftDetails",
        startTime: string,
        endTime: string,
      } | null >,
      facilities:  Array< {
        __typename: "VendorWorkOrderFacilities",
        facilityId: string,
      } | null >,
      workDay:  Array< {
        __typename: "VendorWorkOrderWorkDay",
        workDate: string,
        workDayId: string,
        tasks:  Array< {
          __typename: "VendorWorkOrderWorkDayTask",
          taskId: string,
          taskScheduleId: string,
          occurrenceDate: string,
          markedAsDone: string,
          taskStatus: string,
        } | null >,
        punchCard:  Array< {
          __typename: "VendorWorkOrderWorkDayPunchCard",
          punchTime: string,
          punchType: string,
        } | null >,
      } | null >,
    },
  } | null > | null,
};

export type AllJobShiftDetailsByCustomerWorkDayQueryVariables = {
  workDate: string,
  customerId: string,
  facilityId?: string | null,
  isJobAccepted?: string | null,
  workOrderStatus?: WorkOrderStatus | null,
};

export type AllJobShiftDetailsByCustomerWorkDayQuery = {
  allJobShiftDetailsByCustomerWorkDay?:  Array< {
    __typename: "CustomerJobScheduleItem",
    workOrderId: string,
    customerId: string,
    vendorId: string,
    jobId: string,
    status: WorkOrderStatus,
    workOrderTeams:  Array< {
      __typename: "CustomerJobScheduleWorkOrderTeams",
      vendorId: string,
      teamId: string,
      userId: string,
    } | null >,
    jobBidSummary:  {
      __typename: "VendorWorkOrderJobBidSummary",
      jobName: string,
      startDate: string,
      stopDate: string,
    },
    shiftDetails:  Array< {
      __typename: "VendorWorkOrderShiftDetails",
      startTime: string,
      endTime: string,
    } | null >,
    facilities:  Array< {
      __typename: "VendorWorkOrderFacilities",
      facilityId: string,
    } | null >,
    workDay:  Array< {
      __typename: "VendorWorkOrderWorkDay",
      workDate: string,
      workDayId: string,
      tasks:  Array< {
        __typename: "VendorWorkOrderWorkDayTask",
        taskId: string,
        taskScheduleId: string,
        occurrenceDate: string,
        markedAsDone: string,
        taskStatus: string,
      } | null >,
      punchCard:  Array< {
        __typename: "VendorWorkOrderWorkDayPunchCard",
        punchTime: string,
        punchType: string,
      } | null >,
    } | null >,
  } | null > | null,
};

export type AllJobSCompletedJobShiftByVendorQueryVariables = {
  vendorId: string,
};

export type AllJobSCompletedJobShiftByVendorQuery = {
  allJobSCompletedJobShiftByVendor?:  Array< {
    __typename: "VendorCompletedJobShiftItem",
    vendorId: string,
    userId: string,
    roleId: string,
    workOrder:  Array< {
      __typename: "VendorCompletedShiftWorkOrder",
      workOrderId: string,
      customerId: string,
      shiftDetails:  Array< {
        __typename: "VendorWorkOrderShiftDetails",
        startTime: string,
        endTime: string,
      } | null >,
      facilities:  Array< {
        __typename: "VendorWorkOrderFacilities",
        facilityId: string,
      } | null >,
    } | null >,
  } | null > | null,
};

export type MatchedVendorsQueryVariables = {
  associateId: string,
  dataStatus?: string | null,
  limit?: number | null,
  pageNum?: number | null,
};

export type MatchedVendorsQuery = {
  matchedVendors?:  {
    __typename: "MatchedVendorsWithCount",
    data?:  Array< {
      __typename: "MatchedVendor",
      vendorId?: string | null,
      vendorCompanyName?: string | null,
      doingBusinessAs?: string | null,
      email?: string | null,
      phone?: string | null,
      dataStatus?: string | null,
      address?:  Array< {
        __typename: "MatchedVendorAddress",
        addressName?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        stateCode?: string | null,
        postalCode?: string | null,
        mdCountryCode?: string | null,
      } | null > | null,
      serviceLocations?:  Array< {
        __typename: "MatchedVendorServiceLocation",
        areaName?: string | null,
        locationId?: string | null,
      } | null > | null,
      servicesOffered?:  Array< {
        __typename: "MatchedVendorServicesOffered",
        serviceCategoryId?: string | null,
        serviceSubCategoryId?: string | null,
        serviceDescription?: string | null,
      } | null > | null,
    } | null > | null,
    metadata?:  {
      __typename: "PaginationMetaData",
      totalCount?: number | null,
      pageIndex?: number | null,
      limit?: number | null,
    } | null,
  } | null,
};

export type FetchIssueTemplateQueryVariables = {
  templateType?: IssueType | null,
};

export type FetchIssueTemplateQuery = {
  fetchIssueTemplate?:  {
    __typename: "IssueTemplate",
    templateId: string,
    name?: string | null,
    templateType: IssueType,
    fieldConfiguration?: string | null,
    version?: string | null,
  } | null,
};

export type AllInsuranceAttachmentQueryVariables = {
  insuranceId: string,
  dataStatus?: DataStatus | null,
};

export type AllInsuranceAttachmentQuery = {
  allInsuranceAttachment?:  Array< {
    __typename: "VendorAttachment",
    attachmentId: string,
    refType?: RefType | null,
    refId?: string | null,
    fileName: string,
    mdFileContext: mdFileContext,
    fileExtension: string,
    url: string,
    dataStatus: DataStatus,
    date?: string | null,
    createdOn?: string | null,
    modifiedOn?: string | null,
  } | null > | null,
};

export type ZohoDocumentStatusQueryVariables = {
  requestId: string,
};

export type ZohoDocumentStatusQuery = {
  zohoDocumentStatus?:  {
    __typename: "DocumentStatus",
    request_status: string,
    action_status: string,
  } | null,
};

export type GetOrganizationByUserIdQueryVariables = {
  userId: string,
};

export type GetOrganizationByUserIdQuery = {
  getOrganizationByUserId?:  {
    __typename: "Organization",
    organizationId?: string | null,
    organizationName?: string | null,
    dataStatus?: string | null,
    teams?:  Array< {
      __typename: "OrganizationTeam",
      teamId?: string | null,
      name?: string | null,
    } | null > | null,
    users?:  Array< {
      __typename: "OrganizationUser",
      userId?: string | null,
    } | null > | null,
    errorCode?: string | null,
  } | null,
};

export type GetAllUsersQueryVariables = {
  organizationId: string,
  roleName?: string | null,
  searchString?: string | null,
  isAssociate?: BooleanType | null,
  limit?: number | null,
  pageNumber?: number | null,
  userId?: string | null,
  intId?: string | null,
  sortBy?: string | null,
  sortOrder?: string | null,
};

export type GetAllUsersQuery = {
  getAllUsers?:  {
    __typename: "UserForUserManagementForPagination",
    data?:  Array< {
      __typename: "UserForUserManagement",
      userId?: string | null,
      salutation?: string | null,
      username?: string | null,
      email?: string | null,
      signedUp?: boolean | null,
      phoneNumber?: string | null,
      dataStatus?: string | null,
      organizationId?: string | null,
      teamId?: string | null,
      cognitoUserId?: string | null,
      intId?: string | null,
      roles?:  Array< {
        __typename: "Roles",
        roleId?: string | null,
        roleName?: string | null,
        permissions?:  Array< {
          __typename: "Permissions",
          permissionId?: string | null,
          permissionName?: string | null,
          permissionKey?: string | null,
          channel?: string | null,
        } | null > | null,
      } | null > | null,
    } | null > | null,
    metadata?:  {
      __typename: "PaginationMetaData",
      totalCount?: number | null,
      pageIndex?: number | null,
      limit?: number | null,
    } | null,
  } | null,
};

export type GetOrgDetailsByUserNameQuery = {
  getOrgDetailsByUserName?:  {
    __typename: "UserOrgDetails",
    userId?: string | null,
    salutation?: string | null,
    username?: string | null,
    organization?:  {
      __typename: "UserOrganization",
      organizationName?: string | null,
      recStatus?: string | null,
      dataStatus?: string | null,
      organizationId?: string | null,
    } | null,
  } | null,
};

export type GetUserPermissionsQueryVariables = {
  userId: string,
};

export type GetUserPermissionsQuery = {
  getUserPermissions?:  {
    __typename: "UserPermissions",
    userId?: string | null,
    roles?:  Array< {
      __typename: "Roles",
      roleId?: string | null,
      roleName?: string | null,
      permissions?:  Array< {
        __typename: "Permissions",
        permissionId?: string | null,
        permissionName?: string | null,
        permissionKey?: string | null,
        channel?: string | null,
      } | null > | null,
    } | null > | null,
  } | null,
};

export type GetOrgRoleAndPermissionsQueryVariables = {
  orgId: string,
};

export type GetOrgRoleAndPermissionsQuery = {
  getOrgRoleAndPermissions?:  Array< {
    __typename: "OrgRolePermissions",
    organizationId?: string | null,
    roles?:  Array< {
      __typename: "Roles",
      roleId?: string | null,
      roleName?: string | null,
      permissions?:  Array< {
        __typename: "Permissions",
        permissionId?: string | null,
        permissionName?: string | null,
        permissionKey?: string | null,
        channel?: string | null,
      } | null > | null,
    } | null > | null,
  } | null > | null,
};

export type GetVendorTeamJoinRequestsQueryVariables = {
  associateId: string,
};

export type GetVendorTeamJoinRequestsQuery = {
  getVendorTeamJoinRequests?:  Array< {
    __typename: "VendorTeamJoiningStatusResponse",
    dataStatus?: string | null,
    organization?:  {
      __typename: "OrganizationData",
      organizationId?: string | null,
      organizationName?: string | null,
    } | null,
  } | null > | null,
};

export type GetAllPreviouslyWorkedWithVendorsQueryVariables = {
  customerId: string,
};

export type GetAllPreviouslyWorkedWithVendorsQuery = {
  getAllPreviouslyWorkedWithVendors?:  Array< {
    __typename: "VendorsId",
    vendorId?: string | null,
    vendorEmail?: string | null,
  } | null > | null,
};

export type AllJobAttachmentsQueryVariables = {
  allJobAttachmentInput?: AllJobAttachmentInput | null,
};

export type AllJobAttachmentsQuery = {
  allJobAttachments?:  Array< {
    __typename: "JobAttachment",
    attachmentId?: string | null,
    mdAttachmentType?: string | null,
    fileName?: string | null,
    mdFileContext?: string | null,
    fileExtension?: string | null,
    url?: string | null,
    dataStatus?: string | null,
    comments?: string | null,
    jobId?: string | null,
    facilityId?: string | null,
    tags?: string | null,
  } | null > | null,
};

export type AllBidAttachmentsQueryVariables = {
  allBidAttachmentsInput?: AllBidAttachmentsInput | null,
};

export type AllBidAttachmentsQuery = {
  allBidAttachments?:  Array< {
    __typename: "BidAttachment",
    attachmentId?: string | null,
    mdAttachmentType?: string | null,
    fileName?: string | null,
    mdFileContext?: string | null,
    fileExtension?: string | null,
    url?: string | null,
    dataStatus?: string | null,
    comments?: string | null,
    tags?: string | null,
  } | null > | null,
};

export type GetTagsQueryVariables = {
  owner?: string | null,
  tagsText?: string | null,
  jobId?: string | null,
  family: Family,
  includeSystemTags?: string | null,
};

export type GetTagsQuery = {
  getTags?:  Array< {
    __typename: "Tags",
    owner?: string | null,
    type?: string | null,
    value?: string | null,
    id?: string | null,
    family?: string | null,
  } | null > | null,
};

export type GetUserManagersQueryVariables = {
  userId?: string | null,
  managerId?: string | null,
  dataStatus?: string | null,
  searchString?: string | null,
  isAssociate?: BooleanType | null,
  limit?: number | null,
  pageNumber?: number | null,
  roleName?: string | null,
  regionId?: string | null,
  sortBy?: string | null,
  sortOrder?: string | null,
};

export type GetUserManagersQuery = {
  getUserManagers?:  {
    __typename: "UserManagersWithCount",
    data?:  Array< {
      __typename: "UserManagers",
      userId?: string | null,
      managerId?: string | null,
      dataStatus?: string | null,
      regionId?: string | null,
      manager?:  {
        __typename: "Manager",
        managerName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        roles?:  Array< {
          __typename: "RolesForUserManagers",
          roleId?: string | null,
          roleName?: string | null,
        } | null > | null,
        cognitoUserId?: string | null,
        signedUp?: boolean | null,
      } | null,
      user?:  {
        __typename: "UserForUserManagers",
        userName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        roles?:  Array< {
          __typename: "RolesForUserManagers",
          roleId?: string | null,
          roleName?: string | null,
        } | null > | null,
        cognitoUserId?: string | null,
        signedUp?: boolean | null,
      } | null,
    } | null > | null,
    metadata?:  {
      __typename: "PaginationMetaData",
      totalCount?: number | null,
      pageIndex?: number | null,
      limit?: number | null,
    } | null,
  } | null,
};

export type GetUserScopesQueryVariables = {
  userId?: string | null,
  facilityId?: string | null,
};

export type GetUserScopesQuery = {
  getUserScopes?:  Array< {
    __typename: "UserRolesAndScopes",
    userId?: string | null,
    errorCode?: string | null,
    canCreateOneTimeJob?: boolean | null,
    roles?:  Array< {
      __typename: "Roles",
      roleId?: string | null,
      roleName?: string | null,
      permissions?:  Array< {
        __typename: "Permissions",
        permissionId?: string | null,
        permissionName?: string | null,
        permissionKey?: string | null,
        channel?: string | null,
      } | null > | null,
    } | null > | null,
    userScopes?:  Array< {
      __typename: "UserScopes",
      roleId: string,
      customerId: string,
      mdCountryCode?: string | null,
      regionId?: string | null,
      subregionId?: string | null,
      facilityId?: string | null,
    } | null > | null,
  } | null > | null,
};

export type ReferenceJobsQueryVariables = {
  vendorId: string,
  userId: string,
};

export type ReferenceJobsQuery = {
  referenceJobs?:  Array< {
    __typename: "ReferenceJobs",
    addressId?: string | null,
    addressLine1?: string | null,
    addressLine2?: string | null,
    addressLine3?: string | null,
    addressName?: string | null,
    latitude?: number | null,
    longitude?: number | null,
    city?: string | null,
    county?: string | null,
    customerId?: string | null,
    facilityId?: string | null,
    intCustomerNumber?: string | null,
    jobDescription?: string | null,
    jobNumber?: string | null,
    mdCountryCode?: string | null,
    postalCode?: string | null,
    regionId?: string | null,
    stateCode?: string | null,
    subRegionId?: string | null,
    userId?: string | null,
    vendorId?: string | null,
    roleId?: string | null,
    unionIndicator?: string | null,
    pti?: string | null,
  } | null > | null,
};

export type GetRegionsQueryVariables = {
  organizationId: string,
};

export type GetRegionsQuery = {
  getRegions?:  Array< {
    __typename: "Region",
    regionId: string,
    regionName: string,
    organizationId: string,
    parentRegionId?: string | null,
  } | null > | null,
};

export type GetRegionQueryVariables = {
  regionId: string,
};

export type GetRegionQuery = {
  getRegion?:  {
    __typename: "Region",
    regionId: string,
    regionName: string,
    organizationId: string,
    parentRegionId?: string | null,
  } | null,
};

export type GetJobsForPrimeVendorQueryVariables = {
  organizationId?: string | null,
  jobForDmInput?: JobsForPrimeVendorInput | null,
};

export type GetJobsForPrimeVendorQuery = {
  getJobsForPrimeVendor?:  {
    __typename: "JobsForPrimeVendorWithCount",
    data?:  Array< {
      __typename: "JobsForPrimeVendor",
      jobId: string,
      jobName: string,
      startDate?: string | null,
      stopDate?: string | null,
      dataStatus?: string | null,
      jobType?: string | null,
      specialInstructions?: string | null,
      draftStep?: DraftStep | null,
      draftStatus?: DraftStatus | null,
      createdOn?: string | null,
      createdBy?: string | null,
      modifiedOn?: string | null,
      modifiedBy?: string | null,
      bidCount?: number | null,
      customerId?: string | null,
      customerName?: string | null,
      customerEmail?: string | null,
      parentJobId?: string | null,
      jobCustodian?: string | null,
      intId?: string | null,
      jobFacilities?:  Array< {
        __typename: "JobFacilitiesForPrimeVendor",
        facilityId?: string | null,
        facilityAddress?: string | null,
      } | null > | null,
      jobDueDates?:  Array< {
        __typename: "JobDueDate",
        jobId?: string | null,
        mdDueType?: string | null,
        dueDescription?: string | null,
        dueDate?: string | null,
      } | null > | null,
      jobBids?:  Array< {
        __typename: "JobBidsForPrimeVendor",
        bidId?: string | null,
        vendorId?: string | null,
        vendorName?: string | null,
        vendorEmail?: string | null,
        mdFrequencyType?: MdFrequencyType | null,
        bidName?: string | null,
        totalCost?: number | null,
        profitMargin?: number | null,
        isFlatMargin?: YesNoType | null,
        finalQuote?: number | null,
        comments?: string | null,
        bidEstimationType?: BidType | null,
        bidCreationDate?: string | null,
        draftStep?: BidDraftStep | null,
        draftStatus?: DraftStatus | null,
        dataStatus?: BidStatus | null,
        bidSubmissionDate?: string | null,
        bidVersionCreatedDate?: string | null,
        bidVersion?: number | null,
      } | null > | null,
      jobVendors?:  Array< {
        __typename: "JobVendorsForPrimeVendor",
        vendorId?: string | null,
        mdVendorType?: string | null,
        hasSignedUp?: boolean | null,
      } | null > | null,
      jobAttachments?:  Array< {
        __typename: "JobAttachment",
        attachmentId?: string | null,
        mdAttachmentType?: string | null,
        fileName?: string | null,
        mdFileContext?: string | null,
        fileExtension?: string | null,
        url?: string | null,
        dataStatus?: string | null,
        comments?: string | null,
        jobId?: string | null,
        facilityId?: string | null,
        tags?: string | null,
      } | null > | null,
      jobServices?:  Array< {
        __typename: "JobServicesForPrimeVendor",
        mdServiceId?: string | null,
        mdServiceUnits?: string | null,
        serviceMeasure?: number | null,
        actualMdServiceUnits?: number | null,
        actualServiceMeasure?: string | null,
      } | null > | null,
      jobFrequencies?:  Array< {
        __typename: "JobFrequency",
        mdShiftType?: string | null,
        mdScheduleType?: string | null,
        mdServiceId?: string | null,
        scheduleDays?: string | null,
        fromTime?: string | null,
        toTime?: string | null,
        cronExpression?: string | null,
        repeatitions?: number | null,
        repetition?: number | null,
      } | null > | null,
      subJobs?:  Array< {
        __typename: "SubJobsForPrimeVendor",
        jobId?: string | null,
        jobName?: string | null,
        startDate?: string | null,
        stopDate?: string | null,
        dataStatus?: string | null,
        jobType?: string | null,
        specialInstructions?: string | null,
        draftStep?: DraftStep | null,
        draftStatus?: DraftStatus | null,
        createdOn?: string | null,
        createdBy?: string | null,
        modifiedOn?: string | null,
        modifiedBy?: string | null,
        bidCount?: number | null,
        customerId?: string | null,
        customerName?: string | null,
        customerEmail?: string | null,
        parentJobId?: string | null,
        jobFacilities?:  Array< {
          __typename: "JobFacilitiesForPrimeVendor",
          facilityId?: string | null,
          facilityAddress?: string | null,
        } | null > | null,
        jobDueDates?:  Array< {
          __typename: "JobDueDate",
          jobId?: string | null,
          mdDueType?: string | null,
          dueDescription?: string | null,
          dueDate?: string | null,
        } | null > | null,
        jobBids?:  Array< {
          __typename: "JobBidsForPrimeVendor",
          bidId?: string | null,
          vendorId?: string | null,
          vendorName?: string | null,
          vendorEmail?: string | null,
          mdFrequencyType?: MdFrequencyType | null,
          bidName?: string | null,
          totalCost?: number | null,
          profitMargin?: number | null,
          isFlatMargin?: YesNoType | null,
          finalQuote?: number | null,
          comments?: string | null,
          bidEstimationType?: BidType | null,
          bidCreationDate?: string | null,
          draftStep?: BidDraftStep | null,
          draftStatus?: DraftStatus | null,
          dataStatus?: BidStatus | null,
          bidSubmissionDate?: string | null,
          bidVersionCreatedDate?: string | null,
          bidVersion?: number | null,
        } | null > | null,
        jobFrequencies?:  Array< {
          __typename: "JobFrequency",
          mdShiftType?: string | null,
          mdScheduleType?: string | null,
          mdServiceId?: string | null,
          scheduleDays?: string | null,
          fromTime?: string | null,
          toTime?: string | null,
          cronExpression?: string | null,
          repeatitions?: number | null,
          repetition?: number | null,
        } | null > | null,
        jobVendors?:  Array< {
          __typename: "JobVendorsForPrimeVendor",
          vendorId?: string | null,
          mdVendorType?: string | null,
          hasSignedUp?: boolean | null,
        } | null > | null,
        jobAttachments?:  Array< {
          __typename: "JobAttachment",
          attachmentId?: string | null,
          mdAttachmentType?: string | null,
          fileName?: string | null,
          mdFileContext?: string | null,
          fileExtension?: string | null,
          url?: string | null,
          dataStatus?: string | null,
          comments?: string | null,
          jobId?: string | null,
          facilityId?: string | null,
          tags?: string | null,
        } | null > | null,
        jobServices?:  Array< {
          __typename: "JobServicesForPrimeVendor",
          mdServiceId?: string | null,
          mdServiceUnits?: string | null,
          serviceMeasure?: number | null,
          actualMdServiceUnits?: number | null,
          actualServiceMeasure?: string | null,
        } | null > | null,
      } | null > | null,
    } | null > | null,
    metadata?:  {
      __typename: "PaginationMetaData",
      totalCount?: number | null,
      pageIndex?: number | null,
      limit?: number | null,
    } | null,
  } | null,
};

export type GetUserRoleScopesQueryVariables = {
  getUserRoleScopesInput?: GetUserRoleScopesInput | null,
};

export type GetUserRoleScopesQuery = {
  getUserRoleScopes?:  {
    __typename: "UserRoleScopeWithCount",
    data?:  Array< {
      __typename: "UserRoleScope",
      userRoleScopeId?: string | null,
      keyId?: string | null,
      keyType?: string | null,
      userId?: string | null,
      organizationId?: string | null,
      roleId?: string | null,
      customerId?: string | null,
      mdCountryCode?: string | null,
      regionId?: string | null,
      subRegionId?: string | null,
      facilityId?: string | null,
      userRoleId?: string | null,
      keyName?: string | null,
      userName?: string | null,
      organizationName?: string | null,
      roleName?: string | null,
      customerName?: string | null,
      regionName?: string | null,
      subRegionName?: string | null,
      facilityName?: string | null,
      createdByName?: string | null,
      modifiedByName?: string | null,
      intAddressId?: string | null,
      jobDescription?: string | null,
      jobNumber?: string | null,
      customerNumber?: string | null,
    } | null > | null,
    metadata?:  {
      __typename: "PaginationMetaData",
      totalCount?: number | null,
      pageIndex?: number | null,
      limit?: number | null,
    } | null,
  } | null,
};

export type GetCustomerByRegionQueryVariables = {
  regionId: string,
  customerByRegionInput?: CustomerByRegionInput | null,
};

export type GetCustomerByRegionQuery = {
  getCustomerByRegion?:  Array< {
    __typename: "CustomerByRegion",
    customerId: string,
    customerName: string,
    intId?: string | null,
    customerNumber?: string | null,
    regionId: string,
    regionName: string,
  } | null > | null,
};

export type GetCustomersQueryVariables = {
  filterCustomerInput?: FilterCustomerInput | null,
};

export type GetCustomersQuery = {
  getCustomers?:  Array< {
    __typename: "FilterCustomerOutput",
    customerId?: string | null,
    legalName?: string | null,
    email?: string | null,
    intId?: string | null,
    address?:  Array< {
      __typename: "Address",
      addressId?: string | null,
      customerId?: string | null,
      addressName?: string | null,
      noOfBuildings?: number | null,
      addressLine1?: string | null,
      addressLine2?: string | null,
      addressLine3?: string | null,
      city?: string | null,
      stateCode?: string | null,
      county?: string | null,
      postalCode?: string | null,
      mdCountryCode?: string | null,
      landmark?: string | null,
      googlePlaceId?: string | null,
      latitude?: string | null,
      longitude?: string | null,
      mdMsaId?: string | null,
      logoUrl?: string | null,
      intId?: string | null,
    } | null > | null,
  } | null > | null,
};

export type BidProposalSummaryQueryVariables = {
  bidId: string,
};

export type BidProposalSummaryQuery = {
  bidProposalSummary?:  {
    __typename: "BidProposalSummary",
    vendorId: string,
    vendorName?: string | null,
    vendorEmail?: string | null,
    bidId: string,
    jobId: string,
    bidName: string,
    totalCost: number,
    profitMargin: number,
    comments?: string | null,
    isFlatMargin?: YesNoType | null,
    finalQuote: number,
    bidEstimationType?: BidType | null,
    bidCreationDate: string,
    draftStep?: BidDraftStep | null,
    draftStatus?: DraftStatus | null,
    dataStatus: BidStatus,
    bidTemplate: string,
    bidCreatedFromSample: string,
    bidVersion: number,
    bidVersionCreatedDate: string,
    submittedBy: string,
    bidSubmissionDate: string,
    mdFrequencyType?: MdFrequencyType | null,
    jobCustodianAddress?: string | null,
    jobCustodianAddressName?: string | null,
    customerAddress?: string | null,
    customerAddressName?: string | null,
    jobs?:  {
      __typename: "JobForBidProposal",
      intReferenceId?: string | null,
      customerEmail?: string | null,
      customerName?: string | null,
      customerId?: string | null,
      createdBy?: string | null,
      startDate?: string | null,
      stopDate?: string | null,
      jobDueDates?:  Array< {
        __typename: "JobDueDatesForBidProposal",
        mdDueType?: string | null,
        dueDate?: string | null,
      } | null > | null,
      jobFrequencies?:  Array< {
        __typename: "JobFrequenciesForBidProposal",
        fromTime?: string | null,
        toTime?: string | null,
      } | null > | null,
      jobServices?:  Array< {
        __typename: "JobServicesForBidProposal",
        mdServiceId?: string | null,
      } | null > | null,
      jobFacilities?:  Array< {
        __typename: "JobFacilitiesForBidProposal",
        facilityAddress?: string | null,
        customerName?: string | null,
      } | null > | null,
    } | null,
    bidSimpleEstimation?:  Array< {
      __typename: "JobBidEstimationForBidProposal",
      jobId: string,
      estimationId: string,
      bidId: string,
      mdCategoryId: string,
      mdTaskId?: string | null,
      mdMeasureType: string,
      rate: number,
      quantity: number,
      totalCost: number,
      isVendorAdded: YesNoType,
      isBillable: Billable,
      numberOfOccurrences?: number | null,
      mdUnits?: string | null,
    } | null > | null,
    bidAdditionalExpenses?:  Array< {
      __typename: "JobBidAdditionalExpensesForBidProposal",
      bidId: string,
      bidAdditionalExpenseId?: string | null,
      name?: string | null,
      description?: string | null,
      bidSimpleEstimationEstimationId?: string | null,
      rate: number,
      quantity?: number | null,
      totalCost?: number | null,
      mdUnits?: string | null,
      mdServiceCategoryId?: string | null,
      mdAdditionalExpenseCategory: string,
      draftStatus?: DraftStatus | null,
    } | null > | null,
  } | null,
};

export type EmailTransactionQueryVariables = {
  emailTransactionId: string,
};

export type EmailTransactionQuery = {
  emailTransaction?:  {
    __typename: "EmailTransaction",
    emailTransactionId?: string | null,
    processName?: string | null,
    IPAddress?: string | null,
    transactionStatus?: string | null,
    createdBy?: string | null,
    jobId?: string | null,
    bidId?: string | null,
    mdVendorType?: string | null,
    isReadOnly?: boolean | null,
  } | null,
};

export type AllJobAttachmentsForBidSummaryQueryVariables = {
  allJobAttachmentInput?: JobAttachmentsInput | null,
};

export type AllJobAttachmentsForBidSummaryQuery = {
  allJobAttachmentsForBidSummary?:  Array< {
    __typename: "JobAttachmentsForCustomer",
    attachmentId?: string | null,
    mdAttachmentType?: string | null,
    fileName?: string | null,
    mdFileContext?: string | null,
    fileExtension?: string | null,
    url?: string | null,
    dataStatus?: string | null,
    comments?: string | null,
    jobId?: string | null,
    facilityId?: string | null,
    tags?: string | null,
  } | null > | null,
};

export type GetCommentsQueryVariables = {
  keyId: string,
};

export type GetCommentsQuery = {
  getComments?:  Array< {
    __typename: "Comments",
    commentId?: string | null,
    keyId?: string | null,
    keyType?: string | null,
    comment?: string | null,
    commenterType?: string | null,
    dataStatus?: DataStatus | null,
  } | null > | null,
};

export type BidSubmissionsByVendorIdQueryVariables = {
  vendorId: string,
  limit?: number | null,
  pageNumber?: number | null,
  sortBy?: string | null,
  sortOrder?: string | null,
};

export type BidSubmissionsByVendorIdQuery = {
  bidSubmissionsByVendorId?:  {
    __typename: "PaginatedMatchedBid",
    serviceLocations?:  Array< {
      __typename: "VendorServiceLocation",
      vendorServiceLocationId?: string | null,
      vendorId?: string | null,
      mdAreaName?: string | null,
      mdAreaType?: string | null,
      mdAreaCode?: string | null,
      mdAreaCategory?: string | null,
      country?: string | null,
      locationId?: string | null,
    } | null > | null,
    servicesOffered?:  Array< {
      __typename: "VendorService",
      vendorServiceOfferedId: string,
      serviceCategoryId: string,
      serviceDescription: string,
      serviceSubCategoryId: string,
    } | null > | null,
    jobsWithinServiceLocations?:  Array< {
      __typename: "JobId",
      id?: string | null,
    } | null > | null,
    matchedJobs?:  {
      __typename: "JobRecommendationWithCount",
      data?:  Array< {
        __typename: "JobRecommendation",
        jobId: string,
        jobName: string,
        specialInstructions?: string | null,
        startDate?: string | null,
        stopDate?: string | null,
        jobFacility?:  {
          __typename: "JobFacility",
          jobId: string,
          facilityId: string,
          mdFacilityType: string,
          facilityAddress?: string | null,
          remarks?: string | null,
          status?: string | null,
          jobStartDate?: string | null,
          jobEndData?: string | null,
        } | null,
        jobSize?: string | null,
        dataStatus?: string | null,
        jobType?: string | null,
        createdOn?: string | null,
        jobServices?:  Array< {
          __typename: "JobService",
          jobId: string,
          mdServiceId: string,
          serviceMeasure: number,
          mdServiceUnits: string,
          tasks?:  Array< {
            __typename: "JobServiceTasks",
            taskId: string,
            taskName: string,
          } | null > | null,
          actualMdServiceUnits?: number | null,
          actualServiceMeasure?: string | null,
        } | null > | null,
        jobFrequencies?:  Array< {
          __typename: "JobFrequency",
          mdShiftType?: string | null,
          mdScheduleType?: string | null,
          mdServiceId?: string | null,
          scheduleDays?: string | null,
          fromTime?: string | null,
          toTime?: string | null,
          cronExpression?: string | null,
          repeatitions?: number | null,
          repetition?: number | null,
        } | null > | null,
        jobVendors?:  Array< {
          __typename: "JobVendor",
          jobId: string,
          vendorId: string,
          mdVendorType: string,
        } | null > | null,
        jobDueDates?:  Array< {
          __typename: "JobDueDate",
          jobId?: string | null,
          mdDueType?: string | null,
          dueDescription?: string | null,
          dueDate?: string | null,
        } | null > | null,
        percentageMatch?: number | null,
      } | null > | null,
      metadata?:  {
        __typename: "PaginationMetaData",
        totalCount?: number | null,
        pageIndex?: number | null,
        limit?: number | null,
      } | null,
    } | null,
    matchedBids?:  {
      __typename: "JobBidWithCount",
      data?:  Array< {
        __typename: "JobBid",
        bidId?: string | null,
        jobId?: string | null,
        bidName?: string | null,
        totalCost?: number | null,
        profitMargin?: number | null,
        finalQuote?: number | null,
        bidEstimationType?: BidType | null,
        bidCreationDate?: string | null,
        draftStep?: BidDraftStep | null,
        isFlatMargin?: YesNoType | null,
        draftStatus?: DraftStatus | null,
        dataStatus?: BidStatus | null,
        bidTemplate?: string | null,
        bidVersion?: number | null,
        bidVersionCreatedDate?: string | null,
        submittedBy?: string | null,
        bidSubmissionDate?: string | null,
        mdFrequencyType?: MdFrequencyType | null,
        comments?: string | null,
        bidCreatedFromSample?: string | null,
        errorCode?: string | null,
        vendorId?: string | null,
        vendorName?: string | null,
        vendorEmail?: string | null,
      } | null > | null,
      metadata?:  {
        __typename: "PaginationMetaData",
        totalCount?: number | null,
        pageIndex?: number | null,
        limit?: number | null,
      } | null,
    } | null,
    matchedFacilities?:  Array< {
      __typename: "Facility",
      callHours?: CallHours | null,
      transportationName?: string | null,
      storageName?: string | null,
      callStartTime?: string | null,
      callEndTime?: string | null,
      facilityId?: string | null,
      customerId?: string | null,
      buildingName?: string | null,
      buildingMakeup?: string | null,
      noOfFloors?: number | null,
      sqFootage?: number | null,
      mdFacilityType?: string | null,
      serviceableSqFootage?: number | null,
      occupancy?: number | null,
      supplyStorageAvailability?: boolean | null,
      transportRequiredWithinLocation?: boolean | null,
      contactId?: string | null,
      facilityTrafficLevel?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      cleaningInstructions?: string | null,
      geoFenceRadius?: number | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        addressId?: string | null,
        customerId?: string | null,
        addressName?: string | null,
        noOfBuildings?: number | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        stateCode?: string | null,
        county?: string | null,
        postalCode?: string | null,
        mdCountryCode?: string | null,
        landmark?: string | null,
        googlePlaceId?: string | null,
        latitude?: string | null,
        longitude?: string | null,
        mdMsaId?: string | null,
        logoUrl?: string | null,
        intId?: string | null,
      } | null,
    } | null > | null,
  } | null,
};

export type GetStandardRatesAndPtiTaxesQueryVariables = {
  standardRatesFilterInput: StandardRatesFilterInput,
};

export type GetStandardRatesAndPtiTaxesQuery = {
  getStandardRatesAndPtiTaxes?:  {
    __typename: "StandardRatesAndPtiTaxes",
    standardRates?:  Array< {
      __typename: "StandardRates",
      standardRateId: string,
      keyId: string,
      keyType: string,
      customerId?: string | null,
      serviceCategoryId?: string | null,
      serviceCategoryName?: string | null,
      mdCountryCode?: string | null,
      regionId?: string | null,
      subregionId?: string | null,
      stateCode?: string | null,
      city?: string | null,
      postalCode?: string | null,
      currency?: string | null,
      unionBlendedRate?: string | null,
      nonUnionBlendedRate?: string | null,
    } | null > | null,
    ptiTaxes?:  Array< {
      __typename: "TaxOutput",
      recordId?: string | null,
      keyId?: string | null,
      keyType?: string | null,
      ptiPercentage?: string | null,
      stateCode?: string | null,
      nonUnionPtiPercentage?: string | null,
      unionPtiPercentage?: string | null,
    } | null > | null,
  } | null,
};

export type GetAssociateDetailsByUserIdQueryVariables = {
  cognitoUserId?: string | null,
};

export type GetAssociateDetailsByUserIdQuery = {
  getAssociateDetailsByUserId?:  {
    __typename: "AssociateProfile",
    associateProfileRecId?: string | null,
    associateId?: string | null,
    cognitoUserId?: string | null,
    title: string,
    linkedInURL?: string | null,
    dateOfBirth?: string | null,
    name?: string | null,
    emergencyContactName?: string | null,
    emergencyContactNumber?: string | null,
    eeoInfo?: string | null,
    primaryLanguage?: string | null,
    languagesSpoken?: string | null,
    source?: string | null,
    logoUrl?: string | null,
    emailId?: string | null,
  } | null,
};

export type GetUserByCognitoUserIdQueryVariables = {
  cognitoUserId?: string | null,
};

export type GetUserByCognitoUserIdQuery = {
  getUserByCognitoUserId?:  {
    __typename: "UserByCognitoUserId",
    userId?: string | null,
    username?: string | null,
  } | null,
};

export type AllShiftsQueryVariables = {
  fromDate?: string | null,
  toDate?: string | null,
  status?: string | null,
  punchInGraceMinutes?: number | null,
  punchOutGraceMinutes?: number | null,
  limit?: number | null,
  pageNumber?: number | null,
  workDayTeamStatus?: WorkDayTeamStatus | null,
  searchText?: string | null,
  jobType?: string | null,
  includePastOTJs?: BooleanType | null,
};

export type AllShiftsQuery = {
  allShifts?:  {
    __typename: "UpcomingJobsWithCount",
    data?:  Array< {
      __typename: "UpcomingJob",
      workDayId?: string | null,
      workOrderName?: string | null,
      vendorId?: string | null,
      vendorName?: string | null,
      facilityId?: string | null,
      facilityName?: string | null,
      facilityAddress?: string | null,
      customerId?: string | null,
      jobStartTime?: string | null,
      jobEndTime?: string | null,
      jobStartDate?: string | null,
      parentWorkOrderId?: string | null,
      hasChild?: string | null,
      jobEndDate?: string | null,
      workOrderId?: string | null,
      workDate?: string | null,
      plannedStartTime?: string | null,
      plannedEndTime?: string | null,
      supervisorIds?: Array< string | null > | null,
      jobId?: string | null,
      latestPunchRecord?:  {
        __typename: "PunchRecord",
        punchTime?: string | null,
        punchType?: string | null,
      } | null,
      shiftDetails?:  Array< {
        __typename: "ShiftDetailsOfWorkOrder",
        shiftDetailId?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        punchInGraceMinutes?: number | null,
        punchOutGraceMinutes?: number | null,
      } | null > | null,
      workDayTeam?:  Array< {
        __typename: "UpcomingJobWorkDayTeam",
        workDayId?: string | null,
        dataStatus?: WorkDayTeamStatus | null,
        associateId?: string | null,
        completedOn?: string | null,
      } | null > | null,
      jobType?: JobType | null,
      hasReworkTasks?: boolean | null,
      jobCustodian?: string | null,
      bidId?: string | null,
      services?:  {
        __typename: "WorkOrderService",
        workOrderServiceId?: string | null,
        mdServiceId?: string | null,
        serviceMeasure?: number | null,
        mdServiceUnits?: string | null,
        taskSchedule?:  Array< {
          __typename: "WorkOrderTaskSchedule",
          taskScheduleId?: string | null,
          mdTaskId?: string | null,
          isProofOfCompletionRequired?: WorkOrderYesNoType | null,
        } | null > | null,
      } | null,
      isReworkDay?: boolean | null,
      isShiftRescheduled?: boolean | null,
      shiftStartDateTime?: string | null,
      shiftEndDateTime?: string | null,
    } | null > | null,
    metadata?:  {
      __typename: "PaginationMetaData",
      totalCount?: number | null,
      pageIndex?: number | null,
      limit?: number | null,
    } | null,
  } | null,
};

export type GetReferenceJobMastersQueryVariables = {
  referenceJobMasterInput?: ReferenceJobMasterInput | null,
};

export type GetReferenceJobMastersQuery = {
  getReferenceJobMasters?:  Array< {
    __typename: "ReferenceJobMaster",
    jobNumber?: string | null,
    jobDescription?: string | null,
    customerNumber?: string | null,
    intAddressId?: string | null,
  } | null > | null,
};

export type GetUserQueryVariables = {
  organizationId: string,
  userId: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "FoundUserResponse",
    userId?: string | null,
    salutation?: string | null,
    username?: string | null,
    email?: string | null,
    signedUp?: boolean | null,
    phoneNumber?: string | null,
    dataStatus?: string | null,
    organizationId?: string | null,
    teamId?: string | null,
    cognitoUserId?: string | null,
    intId?: string | null,
    errorCode?: string | null,
    roles?:  Array< {
      __typename: "Roles",
      roleId?: string | null,
      roleName?: string | null,
      permissions?:  Array< {
        __typename: "Permissions",
        permissionId?: string | null,
        permissionName?: string | null,
        permissionKey?: string | null,
        channel?: string | null,
      } | null > | null,
    } | null > | null,
  } | null,
};

export type AllBidAttachmentsWithLatestVersionQueryVariables = {
  allBidAttachmentsInput?: AllBidAttachmentsInput | null,
};

export type AllBidAttachmentsWithLatestVersionQuery = {
  // getCountOfAssignedTasks(userIds: [String]!): [AssignedTasksOutput]
  allBidAttachmentsWithLatestVersion?:  Array< {
    __typename: "BidAttachment",
    attachmentId?: string | null,
    mdAttachmentType?: string | null,
    fileName?: string | null,
    mdFileContext?: string | null,
    fileExtension?: string | null,
    url?: string | null,
    dataStatus?: string | null,
    comments?: string | null,
    tags?: string | null,
  } | null > | null,
};

export type GetJobsByQueryTypeQueryVariables = {
  vendorId: string,
  serviceMeasure?: Array< ServiceMeasure | null > | null,
  startDate?: string | null,
  endDate?: string | null,
  percentageRange?: Array< RangeInput | null > | null,
  jobType?: Array< JobType | null > | null,
  scheduleType?: Array< ScheduleType | null > | null,
  jobDuration?: JobDuration | null,
  facilityType?: Array< string | null > | null,
  dueDate?: string | null,
  includeIgnoredJobs?: boolean | null,
  limit?: number | null,
  pageNumber?: number | null,
  searchText?: string | null,
  actionType?: JobInterestsActionType | null,
  sortBy?: string | null,
  sortOrder?: string | null,
  queryType: QueryType,
};

export type GetJobsByQueryTypeQuery = {
  getJobsByQueryType?:  {
    __typename: "GetJobsByQueryTypeWithCount",
    data?:  Array< {
      __typename: "GetJobsByQueryTypeOutput",
      bidId?: string | null,
      bidDueDate?: string | null,
      logoUrl?: string | null,
      address?:  {
        __typename: "FacilityAddressForJobs",
        callHours?: CallHours | null,
        transportationName?: string | null,
        storageName?: string | null,
        callStartTime?: string | null,
        callEndTime?: string | null,
        customerId?: string | null,
        addressName?: string | null,
        noOfBuildings?: number | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        stateCode?: string | null,
        county?: string | null,
        postalCode?: string | null,
        mdCountryCode?: string | null,
        landmark?: string | null,
        googlePlaceId?: string | null,
        latitude?: string | null,
        longitude?: string | null,
        mdMsaId?: string | null,
        logoUrl?: string | null,
        intId?: string | null,
      } | null,
      facilityName?: string | null,
      finalQuote?: number | null,
      jobId: string,
      jobName: string,
      type?: QueryType | null,
      specialInstructions?: string | null,
      startDate?: string | null,
      stopDate?: string | null,
      jobFacility?:  {
        __typename: "JobFacility",
        jobId: string,
        facilityId: string,
        mdFacilityType: string,
        facilityAddress?: string | null,
        remarks?: string | null,
        status?: string | null,
        jobStartDate?: string | null,
        jobEndData?: string | null,
      } | null,
      jobSize?: string | null,
      dataStatus?: string | null,
      jobType?: string | null,
      createdOn?: string | null,
      jobServices?:  Array< {
        __typename: "JobService",
        jobId: string,
        mdServiceId: string,
        serviceMeasure: number,
        mdServiceUnits: string,
        tasks?:  Array< {
          __typename: "JobServiceTasks",
          taskId: string,
          taskName: string,
        } | null > | null,
        actualMdServiceUnits?: number | null,
        actualServiceMeasure?: string | null,
      } | null > | null,
      jobFrequencies?:  Array< {
        __typename: "JobFrequency",
        mdShiftType?: string | null,
        mdScheduleType?: string | null,
        mdServiceId?: string | null,
        scheduleDays?: string | null,
        fromTime?: string | null,
        toTime?: string | null,
        cronExpression?: string | null,
        repeatitions?: number | null,
        repetition?: number | null,
      } | null > | null,
      jobVendors?:  Array< {
        __typename: "JobVendor",
        jobId: string,
        vendorId: string,
        mdVendorType: string,
      } | null > | null,
      jobDueDates?:  Array< {
        __typename: "JobDueDate",
        jobId?: string | null,
        mdDueType?: string | null,
        dueDescription?: string | null,
        dueDate?: string | null,
      } | null > | null,
      percentageMatch?: number | null,
      bidVersion?: number | null,
      bidStatus?: string | null,
      bidComments?:  Array< {
        __typename: "BidComments",
        commentId?: string | null,
        comment?: string | null,
        commentBy?: string | null,
        commentByName?: string | null,
        commenterRoleId?: string | null,
        commenterRoleName?: string | null,
        entityType?: EntityType | null,
        organizationId?: string | null,
        commentDate?: string | null,
        vendorBidId?: string | null,
        jobId?: string | null,
        bidVersion?: number | null,
        actionType?: ActionType | null,
      } | null > | null,
    } | null > | null,
    metadata?:  {
      __typename: "PaginationMetaData",
      totalCount?: number | null,
      pageIndex?: number | null,
      limit?: number | null,
    } | null,
  } | null,
};

export type ConversationsQueryVariables = {
  customerId?: string | null,
  vendorId?: string | null,
  jobId?: string | null,
  bidId?: string | null,
  bidVersion?: number | null,
  conversationId?: string | null,
  limit?: number | null,
  pageNumber?: number | null,
};

export type ConversationsQuery = {
  conversations?:  {
    __typename: "Conversations",
    data?:  Array< {
      __typename: "ConversationsWithMessages",
      conversationId?: string | null,
      customerId?: string | null,
      vendorId?: string | null,
      bidId?: string | null,
      bidVersion?: number | null,
      jobId?: string | null,
      jobName?: string | null,
      customerName?: string | null,
      vendorName?: string | null,
      countOfMessages?: number | null,
      messages?:  Array< {
        __typename: "Messages",
        messageId?: string | null,
        message?: string | null,
        messageType?: MessageType | null,
        isRead?: boolean | null,
        organizationId?: string | null,
        userName?: string | null,
        userRoleId?: string | null,
        userRoleName?: string | null,
        conversationContext?: ConversationContext | null,
      } | null > | null,
    } | null > | null,
    metadata?:  {
      __typename: "PaginationMetaData",
      totalCount?: number | null,
      pageIndex?: number | null,
      limit?: number | null,
    } | null,
  } | null,
};

export type GetUserDetailsQueryVariables = {
  userDetailsInput?: UserDetailsInput | null,
};

export type GetUserDetailsQuery = {
  getUserDetails?:  Array< {
    __typename: "UserForUserManagement",
    userId?: string | null,
    salutation?: string | null,
    username?: string | null,
    email?: string | null,
    signedUp?: boolean | null,
    phoneNumber?: string | null,
    dataStatus?: string | null,
    organizationId?: string | null,
    teamId?: string | null,
    cognitoUserId?: string | null,
    intId?: string | null,
    roles?:  Array< {
      __typename: "Roles",
      roleId?: string | null,
      roleName?: string | null,
      permissions?:  Array< {
        __typename: "Permissions",
        permissionId?: string | null,
        permissionName?: string | null,
        permissionKey?: string | null,
        channel?: string | null,
      } | null > | null,
    } | null > | null,
  } | null > | null,
};
