/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';

import {
  buttonStyles,
  BottomBarButtonsContainer,
  DisabledButtonContainer,
  ButtonsContainer,
} from './GoogleMapBottomBarOptions.Style';
import { Icons } from '../../../Constants/Icons';
import theme from '../../../Themes/theme';
import { UserType } from '../../../Constants/App';
import { getAuthData } from '../../../Utilities/utils';

interface IGoogleMapBottomBarOptionsProps {
  loading: boolean;
  error: boolean;
  allData: any;
}

const GoogleMapBottomBarOptions = ({ loading, error, allData }: IGoogleMapBottomBarOptionsProps) => {
  const { t } = useTranslation(['homePage', 'altTexts']);
  const { disabledButtonStyle, ButtonStyle, buttonText } = buttonStyles;

  const useStyles = makeStyles(() => ({
    loadingItem: {
      width: '12.25rem',
      height: '3.75rem',
      flexShrink: 0,
      borderRadius: '1rem',
      background: 'rgba(250, 217, 179, 0.3)',
    },
  }));

  const { role } = getAuthData();

  const oneTimeJobsData = allData?.oneTimeJobs;
  const issueOpenCount = allData?.numberOfOpenIssues;
  const completedJobsCount: number = allData.completedJobsPercentage;
  const inspectionScorePercentage = allData?.inspectionScorePercentage;

  const buttonsData = [
    {
      title: `${t('homePage:completedJobs')}`,
      value: `${completedJobsCount ?? 0}%`,
      bgColor: theme.palette.common.white,
      link: '',
    },
    {
      title: `${t('homePage:completeTaskPercent')}`,
      value: `${inspectionScorePercentage ?? 0}%`,
      bgColor: theme.palette.common.white,
      link: '',
    },
    { title: `${t('homePage:issuesOpen')}`, value: issueOpenCount, bgColor: theme.palette.error.light, link: '' },
    {
      title: `${t('homePage:oneTimeJobs')}`,
      value: oneTimeJobsData,
      bgColor: theme.palette.secondary.main,
      link: role === UserType.Customer ? '/jobs-view/onetime' : '/vendor-jobs/self-performed-jobs',
    },
    {
      title: `${t('homePage:toBeReviewed')}`,
      value: allData?.toBeReviewed ?? 0,
      bgColor: theme.palette.warning.light,
      link: '',
    },
  ];

  const classes = useStyles();

  return (
    <BottomBarButtonsContainer>
      <DisabledButtonContainer>
        {buttonsData.slice(0, 2).map((button, index) => {
          const { title, value, bgColor, link } = button;
          const isDisabled = loading || error;

          return (
            <React.Fragment key={index}>
              {isDisabled ? (
                <div>
                  <Button
                    key={index}
                    sx={{
                      ...(index < 2 ? disabledButtonStyle : ButtonStyle),
                      backgroundColor: bgColor,
                      '&:hover': {
                        backgroundColor: bgColor,
                      },
                    }}
                    disabled={true}>
                    <Typography sx={{ ...buttonText, textTransform: 'none' }}>
                      <span style={{ fontSize: 20, fontWeight: theme.typography.fontWeightBold, marginRight: 8 }}>
                        {error ? 0 : value}
                      </span>{' '}
                      {title}
                    </Typography>
                    {index >= 2 && <img src={Icons.ArrowRightIcon} alt={t('altTexts:rightArrow')} />}
                  </Button>
                </div>
              ) : (
                <Link
                  state={link === '/jobs-view' && { isOneTimeJob: true }}
                  to={{
                    pathname: link,
                  }}
                  key={index}>
                  <Button
                    key={index}
                    sx={{
                      ...(index < 2 ? disabledButtonStyle : ButtonStyle),
                      backgroundColor: bgColor,
                      '&:hover': {
                        backgroundColor: bgColor,
                      },
                      cursor: 'default',
                    }}
                    disabled={false}>
                    <Typography sx={{ ...buttonText, textTransform: 'none' }}>
                      <span style={{ fontSize: 20, fontWeight: theme.typography.fontWeightBold, marginRight: 8 }}>
                        {error ? 0 : value}
                      </span>{' '}
                      {title}
                    </Typography>
                    {index >= 2 && <img src={Icons.ArrowRightIcon} alt={t('altTexts:rightArrow')} />}
                  </Button>
                </Link>
              )}
            </React.Fragment>
          );
        })}
      </DisabledButtonContainer>
      <ButtonsContainer>
        {loading ? (
          <Skeleton variant="circle" className={classes.loadingItem} />
        ) : (
          buttonsData.slice(2).map((button, index) => {
            const { title, value, bgColor, link } = button;
            const isDisabled = loading || error;
            const noEvent = ['Issues open', 'To be reviewed'];

            return (
              <React.Fragment key={index}>
                {isDisabled ? (
                  <div>
                    <Button
                      key={index}
                      sx={{
                        ...disabledButtonStyle,
                        backgroundColor: bgColor,
                        '&:hover': {
                          backgroundColor: bgColor,
                        },
                      }}
                      disabled={true}>
                      <Typography sx={{ ...buttonText, textTransform: 'none' }}>
                        <span style={{ fontSize: 20, fontWeight: theme.typography.fontWeightBold, marginRight: 8 }}>
                          {error ? 0 : value}
                        </span>{' '}
                        {title}
                      </Typography>
                      <img src={Icons.ArrowRightIcon} alt={t('altTexts:rightArrow')} />
                    </Button>
                  </div>
                ) : (
                  <Link
                    state={link === '/jobs-view' && { isOneTimeJob: true }}
                    style={{ pointerEvents: noEvent.includes(title) ? 'none' : 'all' }}
                    to={{
                      pathname: link,
                    }}
                    key={index}>
                    <Button
                      key={index}
                      sx={{
                        ...ButtonStyle,
                        backgroundColor: bgColor,
                        '&:hover': {
                          backgroundColor: bgColor,
                        },
                      }}
                      disabled={false}>
                      <Typography sx={{ ...buttonText, textTransform: 'none' }}>
                        <span style={{ fontSize: 20, fontWeight: theme.typography.fontWeightBold, marginRight: 8 }}>
                          {error ? 0 : value}
                        </span>{' '}
                        {title}
                      </Typography>
                      <img src={Icons.ArrowRightIcon} alt={t('altTexts:rightArrow')} />
                    </Button>
                  </Link>
                )}
              </React.Fragment>
            );
          })
        )}
      </ButtonsContainer>
    </BottomBarButtonsContainer>
  );
};

export default GoogleMapBottomBarOptions;
