/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import WorkOrdersApiService from '../../Services/WorkOrdersService';
import { isEmpty } from 'lodash';
import { TaskScheduleType, TaskStatus, TaskV2Details, TasksV2, UpdateTaskInput } from '../../../../API';
import { ActionItemCardsContainer, ContainerTitle, LoaderTag, NoActionItems } from './ActionItems.Style';
import { useTranslation } from 'react-i18next';
import theme from '../../../../Shared/Themes/theme';
import { CircularProgress } from '@mui/material';
import Grid from '@material-ui/core/Grid';
import CustomerRejectedCard from './CustomerRejectedCard';
import RequestOrScheduleRework from '../TaskRejectionAndRework/RequestOrScheduleRework';
import ReworkTaskDetails from './ReworkTaskDetails';
import SnackbarMessage from '../../../Onboarding/Components/Login/SnackbarMessage';
import { UserType } from '../../../../Shared/Constants/App';
import { getAuthData, getStoredCustomerDetails } from '../../../../Shared/Utilities/utils';
import TaskCompletedCard from './TaskCompletedCard';
import { VendorType } from '../../../../Shared/Models/Vendors.Models';

const ActionItems = () => {
  const { t } = useTranslation(['taskRejectionAndRework']);
  const location = useLocation();
  const navigate = useNavigate();
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [actionItemsData, setActionItemsData] = useState<any>([]);
  const [openScheduleRework, setOpenScheduleRework] = useState<boolean>(false);
  const [reworkShift, setReworkShift] = useState<TaskScheduleType | null>(null);
  const [reworkScheduleDate, setReworkScheduleDate] = useState<string>('');
  const [workOrderEndDate, setWorkOrderEndDate] = useState<any>(null);
  const [jobStartDate, setJobStartDate] = useState<string>(new Date().toISOString());
  const [selectedTask, setSelectedTask] = useState<any>({});
  const [openReworkTaskDetails, setOpenReworkTaskDetails] = useState<boolean>(false);
  const [isScheduleReworkLoading, setIsScheduleReworkLoading] = useState<boolean>(false);
  const [allTasks, setAllTasks] = useState({
    tasksReadyToApprove: [],
    tasksRequestForRescheduleByCustomer: [],
  });
  const [facilityName, setFacilityName] = useState<string>(location.state.facilityItem?.facilityName);
  const [facilityAddress, setFacilityAddress] = useState<string>(location.state.facilityItem?.addressLine2);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [jobCustodian, setJobCustodian] = useState<string | null>(null);
  const { role } = getAuthData();
  const { customerId } = getStoredCustomerDetails();
  const currentDate = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(currentDate.getDate() + 1);

  const getActionNeededTaskRecords = (tasksV2Response: any, workOrderData: any) => {
    const taskDetails: any = [];
    tasksV2Response.map((service: any) => {
      const actionNeededTasks = service.tasks.filter(
        (task: any) => task.status === TaskStatus.ReworkRequestedByCustomer
      );
      if (!isEmpty(actionNeededTasks)) {
        actionNeededTasks.map((task: any) => {
          taskDetails.push({
            ...task,
            facilityName: workOrderData?.facilities[0]?.facilityName
              ? workOrderData?.facilities[0]?.facilityName
              : location.state.facilityItem?.facilityName,
            facilityAddress:
              workOrderData?.facilities[0]?.facilityAddress && workOrderData?.facilities[0]?.facilityAddress !== 'null'
                ? workOrderData?.facilities[0]?.facilityAddress
                : location.state.facilityItem?.addressLine2,
            floor: '',
          });
        });
      }
    });
    setActionItemsData([...taskDetails]);
  };

  const getTaskStatusToFilterByRole = (userProfile: UserType, jobCustodian: string | null, vendorType: VendorType) => {
    let taskStatusToFilterCompletedTasks: string;
    if (userProfile === UserType.Customer) {
      // Task status if logged-in user is customer then to get completed tasks.
      taskStatusToFilterCompletedTasks = TaskStatus.PendingCustomerReview;
    } else if (userProfile === UserType.Vendor && jobCustodian && jobCustodian === customerId) {
      taskStatusToFilterCompletedTasks =
        vendorType === VendorType.SelfPerform ? TaskStatus.Complete : TaskStatus.PendingPrimeVendorApproval;
    } else {
      taskStatusToFilterCompletedTasks = `${TaskStatus.Complete}${TaskStatus.ApprovedBySupervisor}Pending Supervisor Approval`;
    }
    return taskStatusToFilterCompletedTasks;
  };

  const getActionItemTasks = (
    serviceLevelTasks: [],
    userProfile: UserType,
    workorderResponse: { jobCustodian: string | null; jobBidSummary: { jobDetails: any } }
  ) => {
    try {
      const jobDetails = JSON.parse(workorderResponse.jobBidSummary.jobDetails);
      const modifiedTasks = { tasksReadyToApprove: [], tasksRequestForRescheduleByCustomer: [] };
      if (
        (userProfile === UserType.Vendor || userProfile === UserType.Customer) &&
        !isEmpty(serviceLevelTasks) &&
        !isEmpty(userProfile)
      ) {
        let tasksCompletedByAssociates: any = [];
        let tasksReworkRequestedByCustomerOrDm: any = [];
        const taskStatusToFilterCompletedTasks: string = getTaskStatusToFilterByRole(
          userProfile,
          workorderResponse.jobCustodian,
          jobDetails.jobVendors[0]?.mdVendorType
        );
        const taskStatusToFilterTasksForRework = `${TaskStatus.ReworkRequestedByCustomer}`;
        serviceLevelTasks?.forEach((eachService: TasksV2) => {
          const eachServiceLevelTasksCompletedByAssociates =
            eachService?.tasks
              ?.filter((task): task is TaskV2Details => task !== null)
              .filter((task: TaskV2Details) => taskStatusToFilterCompletedTasks?.includes(task.status as string)) ?? [];
          const eachServiceLevelTTasksReworkRequestedByCustomerOrDm =
            eachService?.tasks
              ?.filter((task): task is TaskV2Details => task !== null)
              .filter((task: TaskV2Details) => taskStatusToFilterTasksForRework?.includes(task.status as string)) ?? [];
          if (!isEmpty(eachServiceLevelTasksCompletedByAssociates)) {
            tasksCompletedByAssociates = [...tasksCompletedByAssociates, ...eachServiceLevelTasksCompletedByAssociates];
          }
          if (!isEmpty(eachServiceLevelTTasksReworkRequestedByCustomerOrDm)) {
            tasksReworkRequestedByCustomerOrDm = [
              ...tasksReworkRequestedByCustomerOrDm,
              ...eachServiceLevelTTasksReworkRequestedByCustomerOrDm,
            ];
          }
        });
        modifiedTasks.tasksReadyToApprove = tasksCompletedByAssociates;
        modifiedTasks.tasksRequestForRescheduleByCustomer = tasksReworkRequestedByCustomerOrDm;
        return modifiedTasks;
      } else {
        return { tasksReadyToApprove: [], tasksRequestForRescheduleByCustomer: [] };
      }
    } catch (e: any) {
      return { tasksReadyToApprove: [], tasksRequestForRescheduleByCustomer: [] };
    }
  };

  const fetchActionItems = async () => {
    setIsDataLoading(true);
    try {
      const workorderResponse = await WorkOrdersApiService.getAllWorkOrders({
        workOrderId: location.state?.facilityItem?.workOrderId,
      });
      if (!isEmpty(workorderResponse.data) && isEmpty(workorderResponse.errors)) {
        setWorkOrderEndDate(new Date(workorderResponse?.data?.[0].actualEndDate));
        setJobStartDate(workorderResponse?.data[0].jobStartDate);
        setFacilityName(
          workorderResponse?.data[0]?.facilities[0]?.facilityName
            ? workorderResponse?.data[0]?.facilities[0]?.facilityName
            : location.state.facilityItem?.facilityName
        );
        setFacilityAddress(
          workorderResponse?.data[0]?.facilities[0]?.facilityAddress &&
            workorderResponse?.data[0]?.facilities[0]?.facilityAddress !== 'null'
            ? workorderResponse?.data[0]?.facilities[0]?.facilityAddress
            : location.state.facilityItem?.addressLine2
        );
        setJobCustodian(workorderResponse?.data[0]?.jobCustodian);

        const workOrderId = workorderResponse?.data?.[0].workOrderId;
        const subWorkOrderId = workorderResponse?.data?.[0].subWorkOrders.length
          ? workorderResponse?.data?.[0].subWorkOrders[0].workOrderId
          : null;

        const tasksV2Response = await WorkOrdersApiService.getTasksV2(
          { workOrderId: subWorkOrderId || workOrderId },
          false
        );

        if (tasksV2Response.data && isEmpty(tasksV2Response.errors)) {
          getActionNeededTaskRecords(tasksV2Response.data, workorderResponse.data[0]);
          const modifiedServicesAndTasks = getActionItemTasks(
            tasksV2Response.data ?? [],
            role as UserType,
            workorderResponse.data[0]
          );
          setAllTasks(modifiedServicesAndTasks);
        }
      }
    } catch (error) {
      console.log('error while fetching action items: ', error);
      const errorMsg = t('taskRejectionAndRework:fetchActionItemsErrorMsg');
      setSnackbarMessage(errorMsg);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
    setIsDataLoading(false);
  };

  const handleViewDetails = (taskId: string) => {
    const selectedTaskData = actionItemsData.find((task: any) => task.taskId === taskId);
    setSelectedTask({ ...selectedTaskData });
    setOpenReworkTaskDetails(true);
  };

  const getSuggestedReworkDate = (suggestedReworkDate: string, fallbackDate: Date): Date => {
    const suggestedDate = new Date(suggestedReworkDate);
    return suggestedDate.getTime() < fallbackDate.getTime() ? fallbackDate : suggestedDate;
  };

  const setReworkSchedule = (shiftType: TaskScheduleType, scheduleDate: Date): void => {
    setReworkShift(shiftType);
    setReworkScheduleDate(scheduleDate.toISOString());
  };

  const handleReworkSchedule = (selectedTaskData: any) => {
    if (!selectedTaskData.suggestedScheduleType) {
      if (selectedTaskData.isCurrentShiftAvailable) {
        setReworkSchedule(TaskScheduleType.CurrentShift, new Date());
      } else if (selectedTaskData.nextAvailableWorkDay) {
        setReworkSchedule(TaskScheduleType.NextWorkDay, new Date(selectedTaskData.nextAvailableWorkDay.workDate));
      } else {
        setReworkSchedule(TaskScheduleType.SpecificDay, tomorrow);
      }
    } else {
      const { suggestedScheduleType, isCurrentShiftAvailable, nextAvailableWorkDay, suggestedReworkDate } =
        selectedTaskData;

      if (suggestedScheduleType === TaskScheduleType.CurrentShift && isCurrentShiftAvailable) {
        setReworkSchedule(TaskScheduleType.CurrentShift, new Date());
      } else if (suggestedScheduleType === TaskScheduleType.NextWorkDay && nextAvailableWorkDay) {
        setReworkSchedule(TaskScheduleType.NextWorkDay, new Date(nextAvailableWorkDay.workDate));
      } else {
        const reworkDate = getSuggestedReworkDate(suggestedReworkDate, tomorrow);
        setReworkSchedule(TaskScheduleType.SpecificDay, reworkDate);
      }
    }
  };

  const handleScheduleTask = (taskId: string) => {
    const selectedTaskData = actionItemsData.find((task: any) => task.taskId === taskId);
    setSelectedTask({ ...selectedTaskData });
    handleReworkSchedule(selectedTaskData);
    setOpenScheduleRework(true);
  };

  const handleScheduleReworkClose = () => {
    setOpenScheduleRework(false);
  };

  const handleScheduleRework = async () => {
    setIsScheduleReworkLoading(true);
    try {
      const updateTaskPayload: UpdateTaskInput = {
        taskId: selectedTask.taskId,
        associateId: selectedTask.associateId,
        isPartiallyCompleted: selectedTask.isPartiallyCompleted,
        reworkDate: reworkScheduleDate,
        taskStatus: TaskStatus.Rework,
      };

      const updateTasksResposne = await WorkOrdersApiService.updateMultipleTasksAtOnce([{ ...updateTaskPayload }]);
      if (updateTasksResposne.data && isEmpty(updateTasksResposne.errors)) {
        const successMsg = t('taskRejectionAndRework:reworkScheduledSuccessMsg');
        setSnackbarMessage(successMsg);
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.log('error while scheduling task for rework:', error);
      const errorMsg = t('taskRejectionAndRework:reworkScheduledErrorMsg');
      setSnackbarMessage(errorMsg);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
    setIsScheduleReworkLoading(false);
    setOpenScheduleRework(false);
    setOpenReworkTaskDetails(false);
    fetchActionItems();
  };

  const handleReworkTaskDetailsClose = () => {
    setOpenReworkTaskDetails(false);
  };

  const handleTaskViewDetails = () => {
    navigate(`/job-detail/overview`, { state: { ...location.state } });
  };

  const handleApproveTasks = () => {
    navigate(`/job-detail/worktasks`, { state: { ...location.state } });
  };

  useEffect(() => {
    fetchActionItems();
  }, []);

  return (
    <>
      {isDataLoading ? (
        <LoaderTag>
          <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
        </LoaderTag>
      ) : (
        <>
          <ContainerTitle>{t('taskRejectionAndRework:taskCompleted')}:</ContainerTitle>
          <ActionItemCardsContainer>
            {isDataLoading ? (
              <LoaderTag>
                <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
              </LoaderTag>
            ) : (
              <Grid container style={{ columnGap: '1.25rem' }}>
                {!isEmpty(allTasks.tasksReadyToApprove) && (
                  <Grid item xs={6} spacing={2} style={{ maxWidth: '49%', display: 'grid', gridAutoRows: 'auto' }}>
                    <TaskCompletedCard
                      jobStartDate={jobStartDate}
                      facilityName={facilityName}
                      facilityAddress={facilityAddress}
                      handleViewDetails={handleTaskViewDetails}
                      handleApproveTasks={handleApproveTasks}
                    />
                  </Grid>
                )}
                {isEmpty(allTasks.tasksReadyToApprove) && (
                  <NoActionItems>{t('taskRejectionAndRework:noActionItems')}</NoActionItems>
                )}
              </Grid>
            )}
          </ActionItemCardsContainer>
          {role !== UserType.Customer && !jobCustodian && !(jobCustodian && jobCustodian === customerId) && (
            <>
              <ContainerTitle>{t('taskRejectionAndRework:reworkTasksRequestedByTheCustomer')}:</ContainerTitle>
              <ActionItemCardsContainer>
                {isDataLoading ? (
                  <LoaderTag>
                    <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
                  </LoaderTag>
                ) : (
                  <Grid container style={{ columnGap: '1.25rem' }}>
                    {!isEmpty(actionItemsData) &&
                      actionItemsData.map((item: any, index: number) => {
                        return (
                          <Grid
                            item
                            xs={6}
                            spacing={2}
                            key={index}
                            style={{ maxWidth: '49%', display: 'grid', gridAutoRows: 'auto' }}>
                            <CustomerRejectedCard
                              taskData={item}
                              key={index}
                              handleViewDetails={handleViewDetails}
                              handleScheduleTask={handleScheduleTask}
                            />
                          </Grid>
                        );
                      })}
                    {isEmpty(actionItemsData) && (
                      <NoActionItems>{t('taskRejectionAndRework:noActionItems')}</NoActionItems>
                    )}
                  </Grid>
                )}
                {openScheduleRework && (
                  <RequestOrScheduleRework
                    openRequestRework={openScheduleRework}
                    handleRequestReworkClose={handleScheduleReworkClose}
                    handleRequestRework={handleScheduleRework}
                    reworkShift={reworkShift}
                    setReworkShift={setReworkShift}
                    isCurrentShiftAvailable={selectedTask.isCurrentShiftAvailable}
                    nextAvailableWorkDay={selectedTask.nextAvailableWorkDay}
                    tomorrowDate={tomorrow}
                    workOrderEndDate={workOrderEndDate}
                    reworkScheduleDate={reworkScheduleDate}
                    setReworkScheduleDate={setReworkScheduleDate}
                    isDataLoading={isScheduleReworkLoading}
                    proofs={[]}
                  />
                )}
                {openReworkTaskDetails && (
                  <ReworkTaskDetails
                    openReworkTaskDetails={openReworkTaskDetails}
                    handleReworkTaskDetailsClose={handleReworkTaskDetailsClose}
                    taskData={selectedTask}
                    handleScheduleTask={handleScheduleTask}
                  />
                )}
                <SnackbarMessage
                  open={snackbarOpen}
                  successMessage={snackbarMessage}
                  errorMessage={snackbarMessage}
                  severity={snackbarSeverity}
                  onClose={() => setSnackbarOpen(false)}
                />
              </ActionItemCardsContainer>
            </>
          )}
        </>
      )}
    </>
  );
};

export default ActionItems;
