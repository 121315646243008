/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { Dialog, styled } from '@mui/material';
import theme from '../../../../Shared/Themes/theme';

export const buttonStyles = {
  confirmButton: {
    background: theme.palette.primary.dark,
    borderRadius: '1.25rem',
    padding: '1.0625rem 2.95rem',
    textTransform: 'none',
    maxWidth: '100%',
    marginTop: '2em',
    width: '100%',
    '&:hover': {
      background: theme.palette.primary.dark,
    },
    '@media (max-width: 64rem)': {
      padding: '0.75rem 2rem',
    },
  },
  buttonText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h4?.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '1.5rem',
    letterSpacing: '0.009375rem',
    '@media (max-width: 64rem)': {
      fontSize: theme.typography.h5?.fontSize,
      lineHeight: '1.375rem',
      letterSpacing: '0.0125rem',
    },
  },
};

export const UpdateShiftDateWrapper = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    borderRadius: '1.5rem',
    overflowY: 'unset',
    width: '25%',
    padding: '2rem',
  },
  '& .MuiDialogTitle-root+.MuiDialogContent-root': {
    paddingTop: '0.5rem',
  },
}));

export const CloseDialogBoxIcon = styled('span')(() => ({
  cursor: 'pointer',
  position: 'absolute',
  right: '1.5rem',
  top: '-1.125rem',
}));

export const CardTitle = styled('div')(() => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h4?.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.375rem',
  marginBottom: '2rem',
}));
